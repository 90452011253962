import React, {useContext} from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {Route, Switch} from 'react-router-dom'

import {useApiQuery} from 'fairlight'
import qs from 'qs'

import {ConfigContext} from '@d1g1t/config/context'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {history} from '@d1g1t/lib/history'

import {Flex} from '@d1g1t/shared/components/flex'
import {D1G1TLogo} from '@d1g1t/shared/components/logo'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2, Text} from '@d1g1t/shared/components/typography'
import {TokenForm} from '@d1g1t/shared/containers/auth/components/token-form'
import {ForgotPasswordForm} from '@d1g1t/shared/containers/auth/forgot-password'
import {LanguageSelector} from '@d1g1t/shared/containers/auth/language-selector'
import {LoginCredentialsForm} from '@d1g1t/shared/containers/auth/login-credentials'
import {ResetPasswordForm} from '@d1g1t/shared/containers/auth/reset-password'
import {AuthenticationLocations} from '@d1g1t/shared/locations'
import {useGlobalAuthLocalizationSettingsSynchronization} from '@d1g1t/shared/wrappers/localization-settings/hook'
import {
  AVAILABLE_LANGUAGES,
  LANGUAGES_TO_PS_FORMAT
} from '@d1g1t/shared/wrappers/localization-settings/typings'

import d1g1tFrontier from '@d1g1t/shared/assets/d1g1t-frontier.svg'

import * as css from './styles.scss'

export * from './components/logout'
export * from './components/azuread-form'

/**
 * The shared login page.
 */
export const LoginPage: React.FC = () => {
  const {isAdvisorApp} = useContext(ConfigContext)
  const {i18n, t} = useTranslation()

  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  useGlobalAuthLocalizationSettingsSynchronization(
    firmPreConfiguration.data?.investorFrenchEnabled
  )

  if (firmPreConfiguration.loading) {
    return null
  }

  // If firm has SSO enabled, go to the SSO page
  if (isAdvisorApp && firmPreConfiguration.data?.azureAdLoginUrl) {
    const redirectTo = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    }).redirectTo

    // If we have a redirectTo param, we need to pass it along when we go to the SSO page
    if (redirectTo) {
      history.replace(
        `/${AuthenticationLocations.signin()}?redirectTo=${redirectTo}`
      )
    } else {
      history.replace(AuthenticationLocations.signin())
    }

    return null
  }

  return (
    <div className={css.frame}>
      <Helmet title={isAdvisorApp ? 'd1g1t: Log In' : t('Log In')} />
      <Grid container spacing={0}>
        <Grid item xs={6} className={css.leftPanel}>
          <Flex alignCenter justifyCenter fullHeight>
            {firmPreConfiguration.data && (
              <img
                className={css.leftPanelImage}
                src={firmPreConfiguration.data.customLoginLogo || d1g1tFrontier}
              />
            )}
          </Flex>
        </Grid>
        <Grid item xs={6} className={css.formArea}>
          <div className={css.formContainer}>
            <Switch>
              <Route path={AuthenticationLocations.token()}>
                <TokenForm />
              </Route>
              <Route path={AuthenticationLocations.forgotPassword()}>
                <ForgotPasswordForm key={i18n.language} />
              </Route>
              <Route path={AuthenticationLocations.resetPassword()}>
                <ResetPasswordForm key={i18n.language} />
              </Route>
              <Route exact path=''>
                <LoginCredentialsForm
                  key={i18n.language}
                  language={
                    LANGUAGES_TO_PS_FORMAT[
                      i18n.language || AVAILABLE_LANGUAGES.ENGLISH
                    ]
                  }
                  investorApp={!isAdvisorApp}
                />
              </Route>
            </Switch>
          </div>
        </Grid>
      </Grid>
      <Flex column className={css.footer}>
        <Flex row>
          <Text
            className={css.footerLink}
            onClick={() => {
              window.open(firmPreConfiguration.data?.termsOfUse, '_blank')
            }}
          >
            Terms and Conditions
          </Text>
          <div className={css.dot} />
          <Text
            className={css.footerLink}
            onClick={() => {
              window.open(firmPreConfiguration.data?.privacyPolicy, '_blank')
            }}
          >
            Privacy Policy
          </Text>
        </Flex>
        <Flex justifySpaceBetween>
          <Text>
            &copy; 2023 {firmPreConfiguration.data?.displayName}{' '}
            {firmPreConfiguration.data?.footerNote}
          </Text>
        </Flex>
        <Divider style={{marginTop: '1rem'}} />
        <Flex row alignCenter justifySpaceBetween>
          <Text deemphasize normalWeight className={css.footerText}>
            &copy; 2023 d1g1t Inc.
          </Text>
          <Flex alignCenter className={css.footerLogo}>
            <H2 deemphasize normalWeight className={css.footerText}>
              powered by
            </H2>
            <Spacer xxs vertical />
            <D1G1TLogo />
          </Flex>
        </Flex>
      </Flex>
      {firmPreConfiguration.data?.investorFrenchEnabled && !isAdvisorApp && (
        <LanguageSelector />
      )}
    </div>
  )
}
