export enum LOGICAL_OPERATOR {
  AND = 'AND',
  OR = 'OR'
}

export type NumericOperatorSymbol = '>' | '<' | '>=' | '<='
export type DateOperatorSymbol = '>' | '<' | '>=' | '<=' | '='

/**
 * Separates a search string into tokens split by the words
 * and/or (case-insensitive). Returns the expression type
 * and tokens.
 * @param searchString - string to be tokenized eg. `'>1 and <3'`
 * tokens will be `['>1','<3']`
 */
export const andOrStringTokenizer = (
  searchString: string
): {expressionType: LOGICAL_OPERATOR; tokens: string[]} => {
  const logicalOperatorsMatchArray = searchString.match(
    /[0-9%]\s*AND\s*[<>=]|[0-9%]\s*OR\s*[<>=]/gi
  )

  let expressionType: LOGICAL_OPERATOR

  if (logicalOperatorsMatchArray) {
    for (const logicalOperator of logicalOperatorsMatchArray) {
      const upperCaseLogicalOperator = logicalOperator.toUpperCase()
      if (
        expressionType &&
        !upperCaseLogicalOperator.includes(expressionType)
      ) {
        expressionType = null
        break
      }

      if (
        !expressionType &&
        upperCaseLogicalOperator.includes(LOGICAL_OPERATOR.AND)
      ) {
        expressionType = LOGICAL_OPERATOR.AND
      }

      if (
        !expressionType &&
        upperCaseLogicalOperator.includes(LOGICAL_OPERATOR.OR)
      ) {
        expressionType = LOGICAL_OPERATOR.OR
      }
    }
  }

  return {expressionType, tokens: searchString.split(/\s*and\s*|\s*or\s*/i)}
}

interface INumericExpression {
  /**
   * Numeric value of the token
   */
  value: number
  /**
   * Literal numeric operator symbol such as \> or `<`
   */
  operator: NumericOperatorSymbol
}

interface IDateExpression {
  /**
   * Numeric value of the token
   */
  value: string
  /**
   * Literal numeric operator symbol such as \> or `<`
   */
  operator: DateOperatorSymbol
}

/**
 * Parses a numeric token into its operator and value. Returns `null` if token
 * is invalid.
 * @param token - numeric expression token eg: `"<10"`
 */
export const parseNumericExpressionToken = (
  token: string
): INumericExpression => {
  const regExpMatchArray = token.match(/([<>]=?)(\s*-?\d+\.?\d*\s*)(%?)/)

  if (!regExpMatchArray) {
    return null
  }

  const [firstCompleteMatch, operator, numberString, percentage] =
    regExpMatchArray

  if (firstCompleteMatch !== token) {
    return null
  }

  const parsedNumber = percentage
    ? Number(numberString) / 100
    : Number(numberString)

  return {
    operator: operator as NumericOperatorSymbol,
    value: parsedNumber
  }
}

/**
 * Parses a date token into its operator and value. Returns `null` if token
 * is invalid.
 *
 * @remarks gracefully ignores invalid dates
 *
 * @param token - date expression token eg: `"<2020-01-01"`
 */
export const parseDateExpressionToken = (token: string): IDateExpression => {
  const regExpMatchArray = token.match(
    /([<>=]=?)(\s*([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])))/
  )

  if (!regExpMatchArray) {
    return null
  }

  const [firstCompleteMatch, operator, numberString] = regExpMatchArray

  if (firstCompleteMatch.trim() !== token.trim()) {
    return null
  }

  return {
    operator: operator as NumericOperatorSymbol,
    value: numberString.trim()
  }
}

/**
 * Parses a `relationExpressionString` such as `'<1'` into an operator and a
 * number. It returns the evaluation of the expression with the
 * `dataItemValue` being the left operand.
 * Also converts percentages into decimals.
 */
export const relationalNumericalExpressionStringParser = (
  dataItemValue: number,
  relationalExpressionString: string
): boolean => {
  const parsedExpression = parseNumericExpressionToken(
    relationalExpressionString
  )

  if (!parsedExpression) {
    return false
  }

  const {operator, value} = parsedExpression

  switch (operator) {
    case '<':
      return dataItemValue < value
    case '>':
      return dataItemValue > value
    case '<=':
      return dataItemValue <= value
    case '>=':
      return dataItemValue >= value
  }
}

/**
 * Parses a `relationalDateExpressionString` such as `'<2020-01-01'` into an operator and a
 * number. It then returns the evaluation of the expression with the `dataItemValue` being
 * the left operand in the expression.
 */
export const relationalDateExpressionStringParser = (
  dataItemValue: string,
  relationalDateExpressionString: string
): boolean => {
  const parsedExpression = parseDateExpressionToken(
    relationalDateExpressionString
  )

  if (!parsedExpression) {
    return false
  }

  const {operator, value} = parsedExpression
  /**
   * dataItemValue comes in the format
   * YYYY-MM-DDTHH:mm:ss.sssZ
   * but we don't use the time portion of the date in the comparison
   */
  const dateValue = (dataItemValue || '').split('T')[0]

  switch (operator) {
    case '=':
      return dateValue === value
    case '<':
      return dateValue < value
    case '>':
      return dateValue > value
    case '<=':
      return dateValue <= value
    case '>=':
      return dateValue >= value
  }
}
