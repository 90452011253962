import {ApiParam} from 'fairlight'

import {
  ALL_MODELS,
  IContentType,
  IEntitiesRelationsRequest,
  IEntityRelationsResponse
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface IEntityClientRelationshipProperties {
  /**
   * Model name of the entity requested
   */
  model: ALL_MODELS
  /**
   * Reflected entity ID of the entity requested
   */
  entityId: string
  /**
   * Related client entity ID of the entity, or the id of the entity requested
   * if it is a client/household.
   *
   * Only returned for entities with single client relationship.
   */
  clientEntityId?: string
  /**
   * Related household entity ID of the entity, or the id of the entity requested
   * if it is a household.
   *
   * Only returned for entities with a household relationship.
   */
  householdEntityId?: string
  /**
   * Related account entity IDs of the entity, or the single id of the entity
   * requested if it an account.
   *
   * Currently returned for Account and InvestmentMandate models.
   */
  accounts?: string[]
  /**
   * Related position entity IDs of the entity, or the single id of the entity
   * requested if it is a position.
   */
  positions?: string[]
  /**
   * Only owners can make requests to the Sharing API.
   */
  owner?: string
  /**
   *  Provides the model for selected client
   */
  contentType?: IContentType
  /**
   *  True if logged in advisor has edit access to the specifc entity
   */
  canEdit?: boolean
}

export interface IAccountsOrPositionsResult {
  /**
   * Account entity ID of the entity, with stable array position
   */
  accountEntityId: string
  /**
   * Entity ID of the entity passed, with stable array position
   */
  entityId: string
  /**
   * ContentType the entity, such as "account", "cashpostion", with stable array position
   */
  contentType: string
}

interface IAccountsOrPositionsRequest {
  accountsOrPositions: string[]
}

export interface IDefaultReportingCurrencyRequest {
  entities: {
    /**
     * Client entity to which the accounts or positions belong
     */
    entityId: string
    /**
     * List of account entity IDs
     */
    accounts?: string[]
    /**
     * List of mixed accounts and positions entity IDs
     */
    accountsOrPositions?: string[]
  }[]
}

export class EntityEndpoints extends BaseRestEndpoints {
  static basePath = '/entities'

  static findById(id: ApiParam) {
    return super._findById<IEntityClientRelationshipProperties>(id)
  }

  static entityRelationships(
    entityId: string,
    query: {asOfDate?: string} = {}
  ) {
    return super._get<IEntityRelationsResponse>(
      `${super._singlePath(entityId)}/entity-relations`,
      {
        query
      }
    )
  }

  static entitiesRelationships(body: IEntitiesRelationsRequest) {
    return super._post<{[entityId: string]: IEntityRelationsResponse}>(
      '/entities-relations',
      {
        body
      }
    )
  }

  static clientRelationships(entityId: string) {
    return super._get<IEntityClientRelationshipProperties>(
      `${super._singlePath(entityId)}/client-relationships`
    )
  }

  static accountsOrPositions(body: IAccountsOrPositionsRequest) {
    return super._post<IApiListResponse<IAccountsOrPositionsResult>>(
      '/accounts_or_positions',
      {body}
    )
  }

  static defaultReportingCurrency(body: IDefaultReportingCurrencyRequest) {
    return super._post<{currency: string}>('/default_reporting_currency', {
      body,
      extraKey: JSON.stringify(body)
    })
  }
}
