import {ApiParam} from 'fairlight'

import {IRole} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class UserAccountPermissionRoleEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/roles'

  static findById(id: ApiParam) {
    return super._findById<IRole>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IRole>) {
    return super._partialUpdate<IRole>(id, body)
  }
}
