import React from 'react'

import {SeriesOptionsType} from 'highcharts'
import {immerable} from 'immer'
import {isEmpty, isNil} from 'lodash'

import {
  CHART_VALUE_TYPE,
  CHARTTABLEOPTIONS_STATUS,
  IChartTable,
  IChartTableAllowedValue,
  IChartTableCategory,
  IChartTableData,
  IChartTableItem,
  IChartTableOptions,
  IViewMetricItem
} from '@d1g1t/api/models'

import {
  FormattedChartValue,
  IAdditionalChartValueFormatterOptions
} from '@d1g1t/shared/wrappers/formatter'

import {CATEGORY_IDS, CATEGORY_NAMES, MAX_PAGINATED_COUNT} from './constants'
import {
  categoryTextFilter,
  columnFilter,
  emptyRowFilter,
  filterStandardItems,
  fullTextFilter,
  IFilterItemsOptions,
  isAliveFilter,
  isNodeAliveFilter,
  IZeroValueFilterOptions,
  lineNotInSourceFilter,
  removeCancelledItemFilter,
  removeCategories,
  removeItemByIdFilter,
  removeItems,
  removeItemsByIdsFilter,
  removePendingItemFilter,
  StandardResponseItemFilter,
  statusFilter,
  zeroValueFilter
} from './filters'
import {
  changeCategoryOptions,
  changeCategoryValueType,
  consolidateItems,
  dataCategories,
  filterResponseByCategoryIds,
  filterResponseByEmptyCategory,
  filterResponseByMetricOptions,
  findCategoryReference,
  firstNumericCategory,
  IConsolidateItemsOptions,
  IEmptyValueOptions,
  IFilterResponseByCategoryIdsOptions,
  IMapTableToConcentrationOptions,
  IReorderVisibleCategoriesOptions,
  isTotalRow,
  itemLeafIds,
  itemLeafValuesByCategoryId,
  mapTableToConcentration,
  modelIdsByItemIds,
  nonDataCategories,
  recursivelySortTableItems,
  reorderVisibleCategories,
  responseWithFilteredCategories,
  responseWithUpdatedCategoryNames,
  responseWithUpdatedValue,
  updateCategoryNamesByMetricOptions,
  updateCategoryOptions,
  updateResponseWithCategoryNames
} from './lib'

import * as css from './styles.scss'

export * from './lib'
export * from './parser'
export * from './constants'
export * from './filters'

export class StandardResponseItem implements IChartTableItem {
  originalResponse?: StandardResponse

  item: IChartTableItem

  itemParent?: StandardResponseItem

  constructor(
    item: IChartTableItem,
    originalResponse?: StandardResponse,
    itemParent?: StandardResponseItem
  ) {
    if (item instanceof StandardResponseItem) {
      item = item.item
    }

    this.item = item
    this.originalResponse = originalResponse
    this.itemParent = itemParent
  }

  *[Symbol.iterator]() {
    if (!this.isLeafNode) {
      for (const item of this.item.items) {
        yield new StandardResponseItem(item, this.originalResponse, this)
      }
    }
  }

  [immerable] = true

  /**
   * Proxy to the chart table item's id
   */
  get id() {
    return this.item?.id
  }

  /**
   * Proxy to the chart table item's data
   */
  get data() {
    return this.item?.data
  }

  /**
   * Proxy to the chart table item's options
   */
  get options() {
    return this.item?.options || null
  }

  /**
   * Proxy to the chart table item's is empty data
   */
  get isEmptyData() {
    return this.item?.isEmptyData
  }

  /**
   * Proxy to the chart table item's parent path
   */
  get parentPath() {
    return this.item?.parentPath
  }

  /**
   * Proxy to the chart table item's child count
   */
  get childCount() {
    return this.item?.childCount
  }

  get name(): string {
    return this.getValue(CATEGORY_IDS.NAME)
  }

  get modelId(): string {
    return this.getValue(CATEGORY_IDS.MODEL_ID)
  }

  get modelName(): string {
    return this.getValue(CATEGORY_IDS.MODEL_NAME)
  }

  get clientId(): string {
    return this.getValue(CATEGORY_IDS.CLIENT_ID)
  }

  get isTotalItem(): boolean {
    const {id, name} = this

    if (typeof id === 'string' && id.toLowerCase() === CATEGORY_NAMES.TOTAL) {
      return true
    }

    if (name) {
      const nameLowercased = name.toLowerCase()

      return (
        nameLowercased === CATEGORY_NAMES.ALL ||
        nameLowercased === CATEGORY_NAMES.TOTAL
      )
    }

    return false
  }

  /**
   * Checks if this items children are empty or undefined
   */
  get isLeafNode(): boolean {
    return this.item ? isEmpty(this.item.items) : true
  }

  /**
   * Checks if this is a top-level item by checking if parent item exists
   */
  get isTopLevelNode(): boolean {
    return !!this.itemParent
  }

  /**
   * Represent children as `StandardResponseItems`
   * Sets references to parent items
   *
   * @see StandardResponseItem#items
   */
  get children(): Nullable<StandardResponseItem[]> {
    return this.items
  }

  private _items: StandardResponseItem[]

  /**
   * Proxy to the chart table item's data
   */
  get items() {
    if (this.isLeafNode) {
      return null
    }

    if (!this._items) {
      this._items = this.item.items.map(
        (item) => new StandardResponseItem(item, this.originalResponse, this)
      )
    }

    return this._items
  }

  private _benchmarks: StandardResponseItem[]

  /**
   * Proxy to the chart table benchmarks
   */
  get benchmarks() {
    if (!this.item?.benchmarks) {
      return null
    }

    if (!this._benchmarks) {
      this._benchmarks = this.item.benchmarks.map(
        (benchmark) =>
          new StandardResponseItem(benchmark, this.originalResponse, this)
      )
    }

    return this._benchmarks
  }

  /**
   * Returns a flat list of item ids for a decendents of this item
   */
  get descendentIds(): string[] {
    if (this.isLeafNode) {
      return []
    }

    return this.children.reduce((ids, child) => {
      ids.push(child.id)
      if (child.children) {
        return ids.concat(child.descendentIds)
      }

      return ids
    }, [])
  }

  /**
   * Returns a flat list of leaf item ids
   */
  get leafIds(): string[] {
    if (this.isLeafNode) {
      return []
    }

    return itemLeafIds(this.children)
  }

  /**
   * Lazy-created map of category ids to item data.
   */
  private datumByCategoryId(): Map<string, IChartTableData> {
    if (!this._datumByCategoryId) {
      this._datumByCategoryId = new Map()

      for (const datum of this.data || []) {
        this._datumByCategoryId.set(datum.categoryId, datum)
      }
    }

    return this._datumByCategoryId
  }

  private _datumByCategoryId: Map<string, IChartTableData>

  formattedValueWithCategoryId(
    categoryId: string,
    formatOptionsOverride?: IChartTableOptions,
    additionalOptions?: IAdditionalChartValueFormatterOptions
  ): React.ReactNode {
    if (!this.originalResponse) {
      throw new Error('No reference to parent exists in this StandardTableItem')
    }

    const category = this.originalResponse.findCategoryById(categoryId)
    const value = this.getValue(categoryId)

    if (!value || !category) {
      return null
    }

    if (
      ![
        CHART_VALUE_TYPE.DECIMAL,
        CHART_VALUE_TYPE.INTEGER,
        CHART_VALUE_TYPE.DECIMAL_LONG,
        CHART_VALUE_TYPE.DECIMAL_LONG_LONG,
        CHART_VALUE_TYPE.PERCENTAGE,
        CHART_VALUE_TYPE.MONEY_ABBREVIATED,
        CHART_VALUE_TYPE.DATE,
        CHART_VALUE_TYPE.DATETIME
      ].includes(category.valueType as CHART_VALUE_TYPE)
    ) {
      return value
    }

    const formatOptions: IChartTableOptions = {
      ...category.options,
      ...formatOptionsOverride
    }
    return (
      <FormattedChartValue
        value={value}
        valueType={category.valueType}
        valueOptions={formatOptions}
        additionalOptions={additionalOptions}
      />
    )
  }

  /**
   * Like `getValue`, except will walk up the tree to find the value
   * on the parent item
   */
  getClosestValue<T extends string>(id: T) {
    const value = this.getValue(id)

    if (value || value === 0) {
      return value
    }

    if (this.itemParent) {
      return this.itemParent.getClosestValue(id)
    }

    return null
  }

  /**
   * Like `getKey`, except will walk up the tree to find the value
   * on the parent item
   */
  getClosestKey<T extends string>(categoryId: T) {
    const value = this.getKey(categoryId)

    if (
      value ||
      value === 0 ||
      (typeof value === 'boolean' && value === false)
    ) {
      return value
    }

    if (this.itemParent) {
      return this.itemParent.getClosestKey(categoryId)
    }

    return null
  }

  /**
   * Given a list of category IDs returns the first value found for any
   * id in the list.
   */
  firstValueByCategoryIds(categoryIds: string[]) {
    for (const categoryId of categoryIds) {
      const value = this.getValue(categoryId)

      if (value || value === 0) {
        return value
      }
    }
  }

  getDatum(categoryId: string): IChartTableData {
    return this.datumByCategoryId().get(categoryId)
  }

  getValue(categoryId: string) {
    return this.getDatum(categoryId)?.value
  }

  getWarnings(categoryId: string) {
    return this.getDatum(categoryId)?.options?.context?.warnings
  }

  getKey(categoryId: string) {
    return this.getDatum(categoryId)?.key
  }

  getStatus(categoryId: string): CHARTTABLEOPTIONS_STATUS {
    return this.getDatum(categoryId)?.options?.status
  }

  getContext(categoryId: string) {
    return this.getDatum(categoryId)?.options?.context
  }

  getItemContext() {
    return this.item.options?.context
  }

  getAdornment(categoryId: string) {
    return this.getDatum(categoryId)?.options?.adornment
  }

  isEditable(categoryId: string): boolean {
    const itemEditable = this.item?.options?.editable
    const categoryEditable =
      this.originalResponse?.findCategoryById(categoryId)?.options?.editable
    const datumEditable = this.getDatum(categoryId)?.options?.editable

    if ([itemEditable, categoryEditable, datumEditable].includes(false)) {
      return false
    }

    return !!(itemEditable || categoryEditable || datumEditable)
  }

  getAllowedValues(categoryId: string): IChartTableAllowedValue[] {
    return (
      this.getDatum(categoryId)?.options?.allowedValues ||
      this.options?.allowedValues ||
      this.originalResponse?.findCategoryById(categoryId)?.options
        ?.allowedValues ||
      null
    )
  }

  setOptions(options: IChartTableOptions) {
    this.item.options = {...this.item.options, ...options}
  }
}

export class StandardResponse implements IChartTable {
  /**
   * The raw chart table response.
   *
   * @deprecated ⛔️ this should no longer be used directly.
   */
  private response: IChartTable = null

  constructor(response: IChartTable) {
    if (response instanceof StandardResponse) {
      response = response.response
    }

    this.response = {
      categories: response?.categories || [],
      items: response?.items || []
    }

    if (response) {
      if (response.statistics) {
        this.response.statistics = response.statistics
      }

      if (response.count) {
        // paginated response
        this.response.nextOffset = response.nextOffset
        this.response.count = response.count

        // Fill child items array with null values for paginated response
        this.response.items = this.response.items.map((item) => {
          if (!item) {
            return null
          }

          return {
            ...item,
            items:
              item.childCount > 0 && !item.items
                ? new Array(item.childCount || 0).fill(null)
                : item.items
          }
        })

        // Fill items array will null values for paginated responses
        const hasTotalRow = isTotalRow(this.response.items[0])
        const sparsePadding = new Array(
          Math.min(this.count, MAX_PAGINATED_COUNT - 1) -
            (hasTotalRow
              ? this.response.items.length - 1
              : this.response.items.length)
        )
        sparsePadding.fill(null)

        this.response.items = this.response.items.concat(sparsePadding)
      }
    }
  }

  /**
   * Defines iterator for items without total, allowing iteration over
   * StandardResponseItems without intermediate arrays
   */
  *[Symbol.iterator]() {
    for (
      let i = this.hasTotalItem ? 1 : 0;
      i < this.response.items.length;
      i++
    ) {
      yield new StandardResponseItem(this.response.items[i], this)
    }
  }

  [immerable] = true

  public toChartTable(): IChartTable {
    return this.response
  }

  /**
   * Proxy to the raw chart table categories
   */
  get categories() {
    return this.response.categories
  }

  /**
   * Proxy to the raw chart table statistics
   */
  get statistics() {
    return this.response.statistics
  }

  /**
   * Proxy to the raw chart table nextOffset
   */
  get nextOffset() {
    return this.response.nextOffset
  }

  /**
   * Proxy to the raw chart table count
   */
  get count() {
    return this.response.count
  }

  /**
   * Returns a new `StandardResponse` if the `nextResponse` object reference
   * has been updated. Otherwise return self.
   */
  private updateResponse = (nextResponse: IChartTable): StandardResponse => {
    if (nextResponse === this.response) {
      return this
    }

    return new StandardResponse(nextResponse)
  }

  get hasTotalItem(): boolean {
    const firstItem = this.response.items[0]
    if (firstItem && new StandardResponseItem(firstItem, this).isTotalItem) {
      return true
    }

    return false
  }

  get totalItem(): StandardResponseItem {
    if (this.hasTotalItem) {
      return new StandardResponseItem(this.response.items[0], this)
    }

    return null
  }

  /**
   * Returns all child items _except_ the total item.
   */
  get items(): StandardResponseItem[] {
    return [...this]
  }

  /**
   * Creates a iterator of all leaf items, using depth first ordering.
   */
  leafItems(): Generator<StandardResponseItem, never, undefined> {
    let skip = this.hasTotalItem

    function* leafItemGenerator(
      items: IChartTableItem[],
      parentItem?: StandardResponseItem
    ) {
      for (const item of items) {
        if (skip) {
          skip = false
          continue
        }

        const standardItem = new StandardResponseItem(item, this, parentItem)
        if (standardItem.isLeafNode) {
          yield standardItem
        } else {
          yield* leafItemGenerator(standardItem.item.items, standardItem)
        }
      }
    }

    return leafItemGenerator(this.response.items)
  }

  /**
   * Returns items using depth-first traversal.
   */
  itemsDeep(): Generator<StandardResponseItem, never, undefined> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const originalResponse = this
    let skip = this.hasTotalItem

    function* itemDeepGenerator(
      items: IChartTableItem[],
      parentItem?: StandardResponseItem
    ) {
      for (const item of items) {
        if (skip) {
          skip = false
          continue
        }

        const stdItem = new StandardResponseItem(
          item,
          originalResponse,
          parentItem
        )
        yield stdItem

        if (!stdItem.isLeafNode) {
          yield* itemDeepGenerator(stdItem.item.items, stdItem)
        }
      }
    }

    return itemDeepGenerator(this.response.items)
  }

  /**
   * Returns categories using depth first traversal
   */
  categoriesDeep(): Generator<IChartTableCategory, never, undefined> {
    function* categoryDeepGenerator(categories: IChartTableCategory[]) {
      for (const category of categories) {
        yield category

        if (category.categories) {
          yield* categoryDeepGenerator(category.categories)
        }
      }
    }

    return categoryDeepGenerator(this.response.categories)
  }

  /**
   * Returns name and hidden categories
   */
  get nonDataCategories() {
    return nonDataCategories(this.categories)
  }

  /**
   * Returns all categories except name and hidden
   */
  get dataCategories() {
    return dataCategories(this.categories)
  }

  /**
   * Returns first item, could be total or regular data
   */
  get firstItem() {
    const rawItem = this.response.items[0]
    if (!rawItem) {
      return null
    }

    return new StandardResponseItem(rawItem, this)
  }

  /**
   * Returns first item, excluding total row
   */
  get firstNonTotalItem() {
    const rawItem = this.hasTotalItem
      ? this.response.items[1]
      : this.response.items[0]

    if (!rawItem) {
      return null
    }

    return new StandardResponseItem(rawItem, this)
  }

  /**
   * Returns the first non-string category in the response
   */
  get firstNumericCategory() {
    return firstNumericCategory(this.categories)
  }

  /**
   * Returns the first non-string category ID in the response, null safe
   */
  get firstNumericCategoryId(): string {
    const category = this.firstNumericCategory
    if (category) {
      return category.id
    }
  }

  /**
   * Returns the size of the items array
   */
  get size(): number {
    return this.response.items.length
  }

  itemAtIndex(index: number): StandardResponseItem {
    const adjustedIndex = this.hasTotalItem ? index + 1 : index
    const rawItem = this.response.items[adjustedIndex]
    if (!rawItem) {
      return
    }

    return new StandardResponseItem(rawItem, this)
  }

  indexAtItem(itemId: string): number {
    return this.response.items.findIndex((item) => item.id === itemId)
  }

  get lastItem(): StandardResponseItem {
    return new StandardResponseItem(
      this.response.items[this.response.items.length - 1]
    )
  }

  findItemByCategoryIdWithValue(
    categoryId: string,
    value
  ): StandardResponseItem {
    for (const item of this.itemsDeep()) {
      if (item.getValue(categoryId) === value) {
        return item
      }
    }
  }

  findItemByModelId(modelId: string): StandardResponseItem {
    return this.findItemByCategoryIdWithValue(CATEGORY_IDS.MODEL_ID, modelId)
  }

  findCategoryById(categoryId: string): IChartTableCategory {
    const [, category] = findCategoryReference(this.response, categoryId)
    return category
  }

  allItems() {
    return this.response.items.map(
      (item) => new StandardResponseItem(item, this)
    )
  }

  filterResponseByCategoryIds(
    categoryIds: string[],
    options?: IFilterResponseByCategoryIdsOptions
  ): StandardResponse {
    return new StandardResponse(
      filterResponseByCategoryIds(this.response, categoryIds, options)
    )
  }

  /**
   *  @deprecated use `StandardResponse#applyItemFilter` instead
   */
  filter = (
    itemFilter: StandardResponseItemFilter,
    filterOptions?: IFilterItemsOptions
  ): StandardResponse => {
    return this.updateResponse({
      ...this.response,
      items: filterStandardItems(
        this.response.items,
        itemFilter,
        this.categories,
        filterOptions
      )
    })
  }

  /**
   *  @deprecated use `StandardResponse#applyItemFilter` instead
   */
  filterResponse(
    itemFilter: StandardResponseItemFilter,
    filterOptions?: IFilterItemsOptions
  ): IChartTable {
    return {
      ...this.response,
      items: filterStandardItems(
        this.response.items,
        itemFilter,
        this.categories,
        filterOptions
      )
    }
  }

  /**
   * @deprecated ⛔️ Use `StandardResponse#applyFullTextSearchFilter` in a `produce` instead
   * Filters items by searching across all categories for text which matches
   * `searchExpression`
   */
  fullTextSearchFilter = (
    searchExpression: string | RegExp
  ): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(fullTextFilter(searchExpression))
    )
  }

  /**
   * Filters items by searching across all categories for text which matches
   * `searchExpression`. This method mutates the data therefore it should be
   * used inside a `produce`.
   */
  applyFullTextSearchFilter(searchExpression: string | RegExp) {
    this.response.items = filterStandardItems(
      this.response.items,
      fullTextFilter(searchExpression),
      this.categories
    )
  }

  /**
   * Filters items by searching across `categoryIds` for text which matches
   * `searchExpression`
   */
  categoryTextSearchFilter = (
    searchExpression: string | RegExp,
    categoryIds: IChartTableCategory['id'][]
  ): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(categoryTextFilter(searchExpression, categoryIds))
    )
  }

  /**
   * Filters items by checking if all cateogryId search expressions
   * match.
   * @param columnFilterInputState - object containing categoryIds for keys
   * and corresponding column filter input field text as values.
   */
  columnFilter(
    columnFilterInputState: Dictionary<string | any[]>
  ): StandardResponse {
    return new StandardResponse(
      this.filterResponse(columnFilter(columnFilterInputState))
    )
  }

  statusFilter = (
    statusFilterList: CHARTTABLEOPTIONS_STATUS[],
    breachMap: Dictionary<CHARTTABLEOPTIONS_STATUS> = {}
  ): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(statusFilter(statusFilterList, breachMap), {
        keepFamily: true
      })
    )
  }

  findItemById(id: string): StandardResponseItem {
    for (const item of this.itemsDeep()) {
      if (item.id === id) {
        return item
      }
    }

    return null
  }

  // NOTE(Alex.S): we need to find better name for this method
  seriesItems = (series, name, item, itemIndex, onDrilldown) => {
    for (
      let categoryIndex = 1;
      categoryIndex < this.categories.length;
      categoryIndex++
    ) {
      const category = this.categories[categoryIndex]
      const dataItem = item.data[categoryIndex]

      // Time series data
      if (typeof name === 'number') {
        series[categoryIndex - 1].data.push([
          name,
          dataItem ? dataItem.value : 0
        ])

        continue
      }

      const dataPoint = {
        name,
        y: dataItem ? dataItem.value : 0
      }

      if (category.valueType === CHART_VALUE_TYPE.PERCENTAGE) {
        dataPoint.y *= 100
      }

      if (Number.isNaN(dataPoint.y) || isNil(dataPoint.y)) {
        continue
      }

      if (!isEmpty(item.items) && onDrilldown) {
        // @ts-ignore: className does exist on DataPoint
        dataPoint.className = css.pointer
        // @ts-ignore: events does exist on DataPoint
        dataPoint.events = {
          click() {
            onDrilldown(item, itemIndex)

            return true
          }
        }
      }

      series[categoryIndex - 1].data.push(dataPoint)
    }
  }

  asSeries(onDrilldown?: (item: IChartTableItem, index: number) => any) {
    const series: SeriesOptionsType[] = []

    for (const category of this.categories.slice(1)) {
      series.push({
        type: undefined,
        name: category.name,
        data: [],
        yAxis: category.options?.yAxis ?? 0,
        visible: category.options?.highChartsSeriesVisible ?? true
      })
    }

    for (
      let itemIndex = 0;
      itemIndex < this.response.items.length;
      itemIndex++
    ) {
      const item = this.response.items[itemIndex]
      const name = item.data[0].value

      this.seriesItems(series, name, item, itemIndex, onDrilldown)
    }

    return series
  }

  isEmpty = (): boolean => {
    return isEmpty(this.response.items)
  }

  /**
   * @deprecated ⛔️ Use `StandardResponse#setValue` in a produce instead
   * Updates the value for data cell at `categoryId` and `itemId`
   */
  updateValue = (
    categoryId: string,
    itemId: string,
    value: any,
    key?: any
  ): StandardResponse => {
    const nextResponse = responseWithUpdatedValue({
      categoryId,
      itemId,
      value,
      key,
      response: this.response
    })

    return new StandardResponse(nextResponse)
  }

  /**
   * Sets the value for data cell at `categoryId` and `itemId`. This method
   * mutates the data therefore it should be used inside a `produce`.
   */
  setValue(categoryId: string, itemId: string, value: any, key?: any) {
    for (const item of this.itemsDeep()) {
      if (item.id !== itemId) {
        continue
      }

      const data = item.getDatum(categoryId)
      data.value = value

      if (key !== undefined) {
        data.key = key
      }

      break
    }
  }

  /**
   * @deprecated  ⛔️ Use `StandardResponse#applyCategoryNames` in a produce instead
   * Updates category name in response for corresponding categoryId
   */
  updateCategoryNames = (
    updates: Array<{categoryId: string; name: string}>
  ) => {
    const newResponse = responseWithUpdatedCategoryNames(this.response, updates)
    if (newResponse === this.response) {
      return this
    }

    return new StandardResponse(newResponse)
  }

  /**
   * Updates category name in response for corresponding categoryId
   * (Should be used in a `produce`)
   */
  applyCategoryNames(updates: Array<{categoryId: string; name: string}>) {
    return updateResponseWithCategoryNames(this.response, updates)
  }

  concentrationTable(
    options?: IMapTableToConcentrationOptions
  ): StandardResponse {
    return new StandardResponse(mapTableToConcentration(this.response, options))
  }

  /**
   * Used to filter out child categories that have been excluded by `metricItems`
   */
  filterByMetricOption(metricItems: IViewMetricItem[]): StandardResponse {
    const nextResponse = filterResponseByMetricOptions(
      this.response,
      metricItems
    )

    if (nextResponse === this.response) {
      return this
    }

    return new StandardResponse({
      ...this.response,
      ...nextResponse
    })
  }

  pushItem = (item: IChartTableItem): StandardResponse => {
    return new StandardResponse({
      ...this.response,
      items: [...this.response.items, item]
    })
  }

  /**
   * Removes item with id `itemId`
   */
  removeItemById = (itemId: string): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(removeItemByIdFilter(itemId), {keepFamily: false})
    )
  }

  /**
   * @deprecated ⛔️ Use `StandardResponse#applyRemoveItemsByIds` in a produce instead
   * Removes items with ids in `itemIds`
   * @param itemIds - Array of ids to remove from response
   */
  removeItemsByIds = (itemIds: string[]): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(removeItemsByIdsFilter(itemIds))
    )
  }

  /**
   * Removes items with ids in `itemIds`.
   * Should be used in a produce.
   * @param itemIds - Array of ids to remove from response
   */
  applyRemoveItemsByIds(itemIds: string[]): void {
    this.applyItemFilter(removeItemsByIdsFilter(itemIds), this.hasTotalItem)
  }

  /**
   * Returns new "StandardResponse" of leaf nodes
   */
  leafNodes(): StandardResponse {
    const items = itemLeafValuesByCategoryId(
      this.items,
      null,
      (item) => item,
      () => true
    )

    return new StandardResponse({
      ...this.response,
      items: [this.totalItem, ...items]
    })
  }

  /**
   * Removes lines from CPH table which are considered "dead", this definition
   * may change, but roughly it filters positions which have no holdings
   *
   * This only applied to `cph-table` when grouping by security, returns self
   * if preconditions are not met
   */
  filterIsAlive = (): StandardResponse => {
    if (!this.findCategoryById(CATEGORY_IDS.IS_ALIVE)) {
      return this
    }

    return new StandardResponse(
      this.filterResponse(isAliveFilter, {keepFamily: false})
    )
  }

  /**
   * Hide lines and their children from the table where isNodeAlive is false
   *
   * This only applies when keepDeadValues is false
   */
  filterIsNodeAlive = (): StandardResponse => {
    if (!this.findCategoryById(CATEGORY_IDS.IS_NODE_ALIVE)) {
      return this
    }

    return new StandardResponse(
      this.filterResponse(isNodeAliveFilter, {keepFamily: false})
    )
  }

  /**
   * Removes lines from a transaction log which are in the source; IE removes internally
   * generated transactions which are created for transaction codes which do not map
   * exactly to internal d1g1t transactions
   *
   * This only applies to `log-details`
   */
  filterLineNotInSource = (): StandardResponse => {
    return new StandardResponse(this.filterResponse(lineNotInSourceFilter))
  }

  /**
   * Removes lines from a transaction log which are marked as cancelled. (Where
   * IS_TRANSACTION_CANCELLED = false)
   *
   * This only applies to `log-details`
   */
  filterCancelledTransactions = (): StandardResponse => {
    return new StandardResponse(this.filterResponse(removeCancelledItemFilter))
  }

  /**
   * Removes lines from a transaction log which are marked as pending. (Where
   * IS_TRANSACTION_CANCELLED = false)
   */
  filterPendingTransactions = (): StandardResponse => {
    return new StandardResponse(this.filterResponse(removePendingItemFilter))
  }

  /**
   * Remove `categories` and `dataItems` from response where the entire column
   * is considered zero (can be modified by options)
   */
  filterEmptyCategories = (options?: IEmptyValueOptions) => {
    return new StandardResponse(
      filterResponseByEmptyCategory(this.response, options)
    )
  }

  /**
   * Filter categories by passing `filter` predicate to native `.filter` on
   * response categories.
   */
  filterCategories = (
    filter: Parameters<typeof responseWithFilteredCategories>[1]
  ): StandardResponse => {
    return this.updateResponse(
      responseWithFilteredCategories(this.response, filter)
    )
  }

  /**
   * Filters items where the values of all columns passed is falsey, no ids are
   * passed, filters for all visible columns
   */
  filterZeroValues = (
    categoryIds?: string[],
    options?: IZeroValueFilterOptions,
    filterOptions?: IFilterItemsOptions
  ): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(
        zeroValueFilter(
          categoryIds || this.dataCategories.map(({id}) => id),
          options
        ),
        filterOptions
      )
    )
  }

  /**
   * Filters rows where all values are falsey
   */
  filterEmptyRows = (categoryIds?: string[]): StandardResponse => {
    return new StandardResponse(
      this.filterResponse(
        emptyRowFilter(categoryIds || this.dataCategories.map(({id}) => id))
      )
    )
  }

  /**
   * Returns a flat list of values at the leaf nodes, defaults to returning a list
   * of `item.id`s for truthy values
   */
  itemLeafValuesByCategoryId = (
    categoryId: string,
    /**
     * Each leaf item, where `predicate` returns true is passed through `iterateee`,
     * the return result is added to the returned flat list of values
     */
    iteratee?: (item: IChartTableItem) => any,
    /**
     * Each value at category id of leaf items is passed through `predicate`, if
     * it returns true, the value from `iteratee` is added to the returned flat list of values
     */
    predicate?: (value: any) => boolean
  ): any[] => {
    return itemLeafValuesByCategoryId(
      this.response.items,
      categoryId,
      iteratee,
      predicate
    )
  }

  /**
   * Returns a list of model IDs corresponding to a given list of Standard Response Item IDs.
   */
  modelIdsByItemIds = (
    /**
     * A list of Standard Response Item IDs
     */
    ids: string[]
  ): string[] => {
    return modelIdsByItemIds(this.response.items, ids)
  }

  /**
   * Moves items (immutable)
   */
  moveItem = (fromIndex: number, toIndex: number): StandardResponse => {
    const items = [...this.response.items]
    items.splice(toIndex, 0, items.splice(fromIndex, 1)[0])

    return new StandardResponse({
      ...this.response,
      items
    })
  }

  /**
   * @deprecated ⛔️ Use `StandardResponse#applyReorderOfVisibleCategories`
   * in a `produce` instead.
   */
  reorderVisibleCategories = (
    orderIds: string[],
    options?: IReorderVisibleCategoriesOptions
  ): StandardResponse => {
    return new StandardResponse({
      ...this.response,
      categories: reorderVisibleCategories(this.categories, orderIds, options)
    })
  }

  /**
   * Re-oreders the non-hidden categories in this response to match
   * the order of `orderIds`.
   * (Should be used in a `produce`)
   */
  applyReorderOfVisibleCategories(
    orderIds: string[],
    options?: IReorderVisibleCategoriesOptions
  ): void {
    this.response.categories = reorderVisibleCategories(
      this.response.categories,
      orderIds,
      options
    )
  }

  /**
   * Updates the category names displayed from view options
   * @param metricItems - from view options
   */
  updateCategoryNamesByMetricOptions = (
    metricItems: IViewMetricItem[]
  ): StandardResponse => {
    return new StandardResponse({
      ...this.response,
      categories: updateCategoryNamesByMetricOptions(
        this.categories,
        metricItems
      )
    })
  }

  /**
   * Creates a new SORTED array of items, by keeping only the top or bottom items
   * specified by the value at `valueCategoryId` and adds a "remainder" item if
   * number of items exceeds `options.maxItemCount`
   */
  consolidateItems = (
    valueCategoryId: string,
    options?: IConsolidateItemsOptions
  ): StandardResponse => {
    if (!valueCategoryId || !this.findCategoryById(valueCategoryId)) {
      return this
    }

    const nextItems = consolidateItems(
      this.response.items,
      valueCategoryId,
      options
    )

    if (nextItems === this.response.items) {
      return this
    }

    return new StandardResponse({
      ...this.response,
      items: nextItems
    })
  }

  /**
   * @deprecated ⛔️ use `StandardResponse#applySortTableItems` instead
   */
  sortTableItems(sortCategoryId: string, sortOrder: SORT_ORDER) {
    return new StandardResponse({
      ...this.response,
      items: recursivelySortTableItems(
        this.response.items,
        sortCategoryId,
        sortOrder
      )
    })
  }

  /**
   * Updates options of certain categories
   */
  updateCategoryValueType = (
    categoryIdsToChange: string[],
    valueType: CHART_VALUE_TYPE
  ) => {
    return new StandardResponse({
      ...this.response,
      categories: changeCategoryValueType(
        this.categories,
        new Set(categoryIdsToChange),
        valueType
      )
    })
  }

  /**
   * @deprecated ⛔️ use `StandardResponse#applyCategoryOptions` in
   * a produce instead
   */
  updateCategoryOptions = (
    categoryIdsToChange: string[],
    optionsToChange: IChartTableOptions
  ) => {
    return new StandardResponse({
      ...this.response,
      categories: changeCategoryOptions(
        this.categories,
        new Set(categoryIdsToChange),
        optionsToChange
      )
    })
  }

  /**
   * Updates options of certain categories
   * (Should be used in a `produce`)
   */
  applyCategoryOptions(
    categoryIdsToChange: string[],
    optionsToChange: IChartTableOptions
  ): void {
    updateCategoryOptions(
      this.response.categories,
      new Set(categoryIdsToChange),
      optionsToChange
    )
  }

  /**
   * @deprecated ⛔️ use `StandardResponse#applyReplaceOptions` in a produce instead
   */
  replaceItems = (
    nextItems: IChartTableItem[],
    nextCount?: number
  ): StandardResponse => {
    if (nextItems === this.response.items) {
      return this
    }

    return new StandardResponse({
      ...this.response,
      items: nextItems,
      count: Number.isFinite(nextCount) ? nextCount : this.count
    })
  }

  /**
   * Creates a new response by replacing items in the current response with new items
   * (Should be used in a `produce`)
   */
  applyReplaceItems(nextItems: IChartTableItem[], nextCount?: number): void {
    this.response.items = nextItems
    this.response.count = Number.isFinite(nextCount) ? nextCount : this.count
  }

  /**
   * Returns a count of all items which return `true` for the predicate. Defaults
   * to returning count of all items.
   */
  countItemsDeep = (
    predicate: (item: StandardResponseItem) => boolean = () => true
  ): number => {
    let count = 0
    for (const item of this.itemsDeep()) {
      if (predicate(item)) {
        count++
      }
    }

    return count
  }

  /**
   * Returns a list of all the ids in the response, using deep traversal
   */
  allItemIds = () => {
    const ids = []
    for (const item of this.itemsDeep()) {
      if (item) {
        ids.push(item.id)
      }
    }

    return ids
  }

  allCategoryValuesById(categoryId: string): string[] {
    const items = []
    for (const item of this.itemsDeep()) {
      items.push(item.getValue(categoryId))
    }

    return items
  }

  applyItemFilter(
    predicate: StandardResponseItemFilter,
    keepTotal = false
  ): void {
    const itemsToKeep = new Set<string>()

    if (keepTotal) {
      itemsToKeep.add('Total')
    }

    for (const item of this.itemsDeep()) {
      if (predicate(item, this.response.categories)) {
        itemsToKeep.add(item.id)
      }
    }

    this.response.items = removeItems(itemsToKeep, this.response.items)
  }

  applyCategoryFilter(
    predicate: (category?: IChartTableCategory) => boolean
  ): void {
    const categoriesToKeep = new Set<string>()

    for (const category of this.categoriesDeep()) {
      if (predicate(category)) {
        categoriesToKeep.add(category.id)
      }
    }

    this.response.categories = removeCategories(
      categoriesToKeep,
      this.response.categories
    )
  }

  /**
   * Recursively sort rows in table by certain category in descending or ascending order.
   */
  applySortTableItems(sortCategoryId: string, sortOrder: SORT_ORDER): void {
    this.response.items = recursivelySortTableItems(
      this.response.items,
      sortCategoryId,
      sortOrder
    )
  }
}
