import React, {createContext, useState} from 'react'

import {datadogRum} from '@datadog/browser-rum'
import {omit} from 'lodash'

import {CUSTOM_DATADOG_PROPERTIES} from './constants'

export const DatadogCustomPropertyContext = createContext<{
  context: {[key: string]: any}
  addCustomProperty: (id: string, value: any) => void
  removeCustomProperty: (id: string) => void
}>({
  context: {
    [CUSTOM_DATADOG_PROPERTIES.VIEWS]: {}
  },
  addCustomProperty: (id: string, value: any) => {},
  removeCustomProperty: (id: string) => {}
})

/**
 * Context holding all custom property that we want Datadog to track
 */
export const DatadogCustomPropertyContextProvider: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  const [customProperty, setCustomProperty] = useState({})

  /**
   * Sets the custom property locally and also on Datadog
   * @param id - id of the property
   * @param value - value of the property
   */
  const addCustomProperty = (id: string, value: any): void => {
    setCustomProperty({...customProperty, [id]: value})
    datadogRum.setGlobalContextProperty(id, value)
  }

  /**
   * Removes the custom property locally and also on Datadog
   * @param id - id of the property to remove
   */
  const removeCustomProperty = (id: string): void => {
    const removed = omit(customProperty, id)
    setCustomProperty(removed)
    datadogRum.removeGlobalContextProperty(id)
  }

  return (
    <DatadogCustomPropertyContext.Provider
      value={{context: customProperty, addCustomProperty, removeCustomProperty}}
    >
      {children}
    </DatadogCustomPropertyContext.Provider>
  )
}
