import {Formatter} from './base-formatter'

export class StringFormatter extends Formatter {
  format(value) {
    if (value === null || value === undefined) {
      return this.renderEmpty()
    }

    return value.toString()
  }
}
