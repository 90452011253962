import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {compose} from 'redux'

import {IconButton} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import {StyleRulesCallback, Theme, withStyles} from '@material-ui/core/styles'

import {classNames} from '@d1g1t/lib/class-names'
import {useToggleState} from '@d1g1t/lib/hooks'

import {Flex} from '@d1g1t/shared/components/flex'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {UserAvatar} from '@d1g1t/shared/components/user-avatar'
import {AuthenticationLocations} from '@d1g1t/shared/locations'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useGlobalLocalizationSettingsSynchronization} from '@d1g1t/shared/wrappers/localization-settings/hook'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {FirmLogoSvg} from '@d1g1t/investor/containers/firm-logo-svg'
import {OTHER_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'
import {SettingsLocations} from '@d1g1t/investor/locations'

import {PrimaryNavigation} from './components/primary-navigation'
import {SecondaryNavigation} from './components/secondary-navigation'
import {SecondaryMenuSpacing} from './components/secondary-navigation-spacing'
import {IApplicationTopBarComponentProps} from './typings'

import * as css from './style.scss'

const styles: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '0 4px 15px 0 rgba(0,0,0,0.05)'
  },
  colorDefault: {
    backgroundColor: '#FFF'
  }
})

/**
 * This component and application-side-manu will merged into a single `navigation` component.
 */
const ApplicationTopBarComponent: React.FC<IApplicationTopBarComponentProps> = (
  props
) => {
  const menuRef = useRef(null)

  const history = useHistory()

  const {i18n} = useTranslation()
  const [userProfile] = useUserProfile()
  const {firmConfiguration, firmUiConfig} = useFirmConfiguration()
  const [menuOpen, , setMenuOpen, setMenuClosed] = useToggleState(false)

  const [localizationSynced] = useGlobalLocalizationSettingsSynchronization()
  if (!localizationSynced) {
    return null
  }

  const settingsLabel =
    firmUiConfig.data?.investor.settings.pageTitle[i18n.language]

  return (
    <>
      <AppBar
        color='default'
        position='fixed'
        classes={props.classes}
        className={classNames({
          [css.emulatorMode]: props.emulatorMode
        })}
      >
        <Flex justifySpaceBetween alignCenter className={css.topBar}>
          <Flex className={css.topBarLogoContainer}>
            <FirmLogoSvg />
          </Flex>
          <Flex>
            <PrimaryNavigation />
          </Flex>
          <Flex>
            {userProfile.user && (
              <>
                <IconButton
                  data-testid='nav-profile'
                  onClick={setMenuOpen}
                  ref={menuRef}
                >
                  <UserAvatar externalProfile />
                </IconButton>
                <Menu
                  open={menuOpen}
                  onClose={setMenuClosed}
                  anchorEl={menuRef.current}
                  MenuListProps={{
                    style: {
                      width: '270px',
                      minWidth: '270px'
                    }
                  }}
                >
                  <UserAvatar externalProfile menuItem />
                  <MenuItem
                    button
                    data-testid='nav-profile-settings'
                    onClick={() => {
                      setMenuClosed()
                      history.push(SettingsLocations.basePath)
                    }}
                  >
                    {settingsLabel}
                  </MenuItem>
                  {firmConfiguration.data?.privacyPolicy && (
                    <MenuItem
                      button
                      data-testid='nav-profile-privacy-policy'
                      onClick={() => {
                        window.open(
                          firmConfiguration.data.privacyPolicy,
                          '_blank'
                        )
                      }}
                    >
                      {OTHER_TRANSLATION_KEYS.PRIVACY_POLICY}
                    </MenuItem>
                  )}
                  {firmConfiguration.data?.termsOfUse && (
                    <MenuItem
                      button
                      data-testid='nav-profile-terms-of-use'
                      onClick={() => {
                        window.open(firmConfiguration.data.termsOfUse, '_blank')
                      }}
                    >
                      {OTHER_TRANSLATION_KEYS.TERMS_OF_USE}
                    </MenuItem>
                  )}
                  <MenuItem
                    button
                    data-testid='logout-button'
                    onClick={() =>
                      history.push(AuthenticationLocations.logout())
                    }
                  >
                    {OTHER_TRANSLATION_KEYS.LOG_OUT}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Flex>
        </Flex>
        <SecondaryNavigation />
      </AppBar>
      <SecondaryMenuSpacing />
    </>
  )
}
export const ApplicationTopBar = compose<
  React.ComponentType<Pick<IApplicationTopBarComponentProps, 'emulatorMode'>>
>(withStyles(styles, {withTheme: true}))(ApplicationTopBarComponent)

ApplicationTopBar.displayName = 'ApplicationTopBar'
