import {LocationDescriptorObject} from 'history'
import {compact, isEmpty} from 'lodash'

import {IAddress} from '@d1g1t/api/models'

import {ISelectEntitiesState} from '@d1g1t/shared/containers/select-entities'
import {serializeQueryParams} from '@d1g1t/shared/wrappers/url-query-params'

export enum PARAMS {
  SELECTION = ':selection',
  OPTIONAL_SELECTION = ':selection?',
  PROFILE_ID = ':profileId',
  TEAM_ID = ':teamId',
  CONTACT_ID = ':contactId',
  SECTION = ':section',
  HOUSEHOLD_ID = ':householdId'
}

export interface ISelectionParams {
  selection: string
}

/**
 * Extracts an ID param from a given URL
 * @example for '/some/resource/12345' returns '12345'
 */
export const extractIdFromUrl = (url: string): string => {
  if (!url) {
    return
  }

  const parts = url.split('/')

  const first = parts.pop()

  if (first) {
    return first
  }

  return parts.pop()
}

/**
 * Uses `extractIdFromUrl` to map list of url strings into a list of ids
 */
export const mapUrlsToIds = (urls: string[]): string[] => {
  return urls?.map((url) => extractIdFromUrl(url))
}

export const createRootPath = (root: string, paths: string[]): string => {
  const segments = paths.reduce((result, path) => {
    if (path === null || path === undefined) {
      return result
    }

    if (typeof path !== 'string') {
      path = String(path)
    }

    return result.concat(compact(path.split('/')))
  }, [])

  return ['', root[0] === '/' ? root.slice(1) : root, ...segments].join('/')
}

export type UrlSelection = string | ISelectEntitiesState

/**
 * Given a selection, returns its serialized query params that can be added
 * to the URL
 * @param selection - `{UrlSelection}` string or structured selection object
 */
export function serializeSelection(selection: UrlSelection) {
  const params: ISelectEntitiesState =
    typeof selection === 'string'
      ? {
          selected: [
            {
              entityId: selection
            }
          ]
        }
      : selection

  return serializeQueryParams(params)
}

/**
 * Helper method to generate selection for an account and it parent client
 */
export function selectionForAccount(
  accountId: string,
  clientId: string,
  currency?: string
): ISelectEntitiesState {
  return {
    selected: [
      {
        entityId: clientId,
        accounts: [accountId]
      }
    ],
    currency: currency || undefined
  }
}

/**
 * Extend this static base class to create route definitions
 */
export abstract class RouteBuilder {
  /**
   * Base location for the current route locations. All methods will append
   * this to the beginning of generated routes.
   *
   * DO NOT INCLUDE TRAILING or LEADING SLASH
   *
   * @example 'clients'
   * @example 'admin/clients'
   */
  static basePath: string

  /**
   * Generate a path relative to the `baseName`
   * @param paths -  path segments to append to the `baseName`
   */
  static path(...paths: string[]) {
    return createRootPath(this.basePath, paths)
  }

  /**
   * A method to create URLs with selection query params,
   *
   * Accepts either a single string as the entityId to create selection params,
   * or the object representation of selection params.
   *
   * Returns the resource base path if no selection provided.
   *
   * @param resourcePath  - URL path of the resource
   * /**
   * @example
   * example:
   * ```
   * for `clients`  returns `/basePath/clients`
   * ```
   * @param selection - entityId string or query object
   *  @example
   * example:
   * ```
   * for `d1e0691c-04f0-452f-8e8e-225075489bde`  returns `/basePath/clients/d1e0691c-04f0-452f-8e8e-225075489bde`
   * ```
   */
  static withSelection(resourcePath: string, selection: UrlSelection) {
    const root = this.path(resourcePath)

    if (!selection) {
      return root
    }

    const query = serializeSelection(selection)

    // Return root if a selection object with no parsable values is passed
    if (!query) {
      return root
    }

    return `${root}?${query}`
  }

  /**
   * Method for compatability with deprecated URL builder, use for redirects
   * where required.
   */
  protected static withEntities_DEPRECATED(
    resourcePath: string,
    entityIds?: string[] | string,
    accounts?
  ) {
    return this.path(encodeParams(entityIds, accounts), resourcePath)
  }
}

/**
 * Extracts & returns the path from a given URL by stripping out the search string.
 */
export function extractPath(url: string): string {
  return url.split('?')[0]
}

export function urlToLocationDescriptor(url: string): LocationDescriptorObject {
  const [pathname, search] = url.split('?')

  return {
    pathname,
    search
  }
}

export interface IAccounts {
  [entityId: string]: string[]
}

export const encodeParams = (
  entityIds: string[] | string,
  accounts: IAccounts
): string => {
  if (!entityIds) {
    return ''
  }

  if (typeof entityIds === 'string') {
    entityIds = [entityIds]
  }

  return entityIds
    .map((entityId) => {
      if (accounts && accounts[entityId]) {
        return `${entityId}:${accounts[entityId].join(',')}`
      }

      return entityId
    })
    .join(';')
}

/**
 * Combines all the addresses into one and inserts them into the Google Maps link
 * @param addresses - Object of addresses
 */
export const generateGoogleMapsLink = ({
  url,
  ...addresses
}: IAddress): string => {
  if (!isEmpty(addresses)) {
    const stringyfiedAddresses = Object.values(addresses)

    const filteredAddresses = stringyfiedAddresses?.filter(Boolean)
    if (filteredAddresses.length) {
      const plusQuery = filteredAddresses.join(' ').replace(/\s+/g, '+')
      return `https://www.google.com/maps?q=${plusQuery}`
    }
  }

  return ''
}
