import React from 'react'

import {Alert} from '@d1g1t/shared/components/mui/alert'

import {reportError} from '../error-handler'
import {getErrorDisplayMessage} from './lib'
import {
  IErrorBoundaryProps,
  IErrorBoundaryState,
  IErrorFallbackProps
} from './typings'

import * as css from './styles.scss'

export * from './typings'
export * from './lib'
export {
  ModalContentsErrorFallback,
  IModalContentsErrorFallbackProps
} from './modal-contents-fallback'

export const RedTextErrorFallback: React.FC<IErrorFallbackProps> = ({
  error
}) => {
  return (
    <div>
      <div className={css.error}>{getErrorDisplayMessage(error)}</div>
    </div>
  )
}

export const DefaultAlertErrorFallback: React.FC<IErrorFallbackProps> = (
  props
) => {
  return (
    <div data-error>
      <Alert severity='error'>{getErrorDisplayMessage(props.error)}</Alert>
    </div>
  )
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  // NOTE: These should not be made `Partial<>` because it unexpectedly changes component's prop typings
  // `Partial<>` should imply we're providing partial defaults, but instead it marks all props as optional
  static defaultProps = {
    fallback: <DefaultAlertErrorFallback />,
    __testing__: false
  }

  state = {
    error: null,
    handlerDidRun: false
  }

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return {error, handlerDidRun: false}
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const handledError = this.props.handler && this.props.handler(error)
    if (handledError) {
      // don't report the error, since it's been 'handled'
      this.setState({error: handledError, handlerDidRun: true})
      return
    }

    this.setState({handlerDidRun: true})

    if (!this.props.__testing__ && process.env.NODE_ENV === 'test') {
      console.error(error)
      throw error
    }

    reportError(error, {
      context: {componentStack: info.componentStack},

      // By default, `componentDidCatch` error boundary will automatically log to console,
      // so we should avoid logging a second time in `reportError`.
      disableLog: true
    })
  }

  componentDidUpdate(prevProps: IErrorBoundaryProps) {
    if (this.state.error && prevProps.resetId !== this.props.resetId) {
      this.handleReset()
    }
  }

  handleReset = () => {
    this.setState({error: null, handlerDidRun: false})
  }

  render() {
    if (this.state.error) {
      if (!this.state.handlerDidRun || !this.props.fallback) {
        return <div />
      }

      return React.cloneElement(this.props.fallback, {
        error: this.state.error,
        onReset: this.handleReset
      } as IErrorFallbackProps)
    }

    return this.props.children as React.ReactElement<any>
  }
}
