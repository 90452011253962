import {IInstrumentIndustry} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class InstrumentIndustryEndpoints extends BaseRestEndpoints {
  static basePath = '/instrument-industries'

  static list() {
    return super._list<IApiListResponse<IInstrumentIndustry>>()
  }
}

export class InstrumentIndustryVectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/industry-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
