const START_MARKER = '-START'
const END_MARKER = '-END'
const LABEL_PREFIX = '🤖 '

/**
 * Creates UserTimings start mark
 * @param id - Friendly name to ID the measurement
 */
export function performanceMarkStart(id: string) {
  if (__DEVELOPMENT__) {
    performance.mark(`${id}${START_MARKER}`)
  }
}

/**
 * Creates UserTimings end mark
 * @param id - Friendly name to ID the measurement
 */
export function performanceMarkEnd(id: string) {
  if (__DEVELOPMENT__) {
    performance.mark(`${id}${END_MARKER}`)
  }
}

/**
 * Creates UserTimings end mark and adds a measurement from the start mark
 * @param id - Friendly name to ID the measurement
 */
export function performanceMeasureFromStartMark(id: string) {
  if (__DEVELOPMENT__) {
    performanceMarkEnd(id)
    performance.measure(
      `${LABEL_PREFIX}${id}`,
      `${id}${START_MARKER}`,
      `${id}${END_MARKER}`
    )
  }
}

/**
 * Adds start, end and measure calls around the `cb`, returns result of `cb`
 * @param id - Friendly name to ID the measurement
 * @param cb - work to be measured
 */
export function performanceMeasureWrap<TReturnValue>(
  id: string,
  cb: () => TReturnValue
): TReturnValue {
  if (__DEVELOPMENT__) {
    performanceMarkStart(id)
    const returnValue = cb()
    performanceMeasureFromStartMark(id)

    return returnValue
  }

  return cb()
}
