import {ApiParam} from 'fairlight'

import {IChartTable, IShare} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class SharingEndpoints extends BaseRestEndpoints {
  static basePath = '/sharing'

  static chart() {
    return super._chart({})
  }

  static entityChartById(id: ApiParam) {
    return super._post<IChartTable>(`/entities${super._singlePath(id)}/chart`, {
      body: {}
    })
  }
}

export class SharesEndpoints extends BaseRestEndpoints {
  static basePath = '/sharing/shares'

  static create(body: IShare) {
    return super._create<IShare>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IShare>) {
    return super._partialUpdate<IShare>(id, body)
  }
}
