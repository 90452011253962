import React from 'react'

import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Alert, Color} from '@d1g1t/shared/components/mui/alert'
import {Button} from '@d1g1t/shared/components/mui/button'

import {getErrorDisplayMessage} from '../lib'
import {IErrorFallbackProps} from '../typings'

export interface IModalContentsErrorFallbackProps extends IErrorFallbackProps {
  /**
   * Called when the user decides to close the modal. Usually this is the
   * same method passed to `Modal#onClose`
   */
  onClose(): void
  /**
   * Overrides the displayed message.
   */
  message?: string
  /**
   * Overrides `<Alert severity />`
   */
  severity?: Color
}

/**
 * Modal-specific error fallback. This should be rendered as a direct descendent
 * of `<Modal>`.
 *
 * @example
 * ```
 * <Modal open onClose={onClose}>
 *   <ErrorBoundary fallback={<ModalContentsErrorFallback onClose={onClose} />}>
 *     <ModalContentsThatMightThrowAnError />
 *   </ErrorBoundary>
 * </Modal>
 * ```
 */
export const ModalContentsErrorFallback: React.FC<
  IModalContentsErrorFallbackProps
> = (props) => {
  return (
    <>
      <ModalContent>
        <Alert severity={props.severity ?? 'error'}>
          {props.message ?? getErrorDisplayMessage(props.error)}
        </Alert>
      </ModalContent>
      <ModalActions>
        <Button primary contained onClick={props.onClose}>
          Close
        </Button>
      </ModalActions>
    </>
  )
}
