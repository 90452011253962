import {IDocumentType} from '@d1g1t/api/models'

import {IApiListResponse} from './'
import {BaseRestEndpoints} from './base'

export class WorkDocsTypesEndpoints extends BaseRestEndpoints {
  static basePath = '/workdocs/document-types'

  static list() {
    return super._list<IApiListResponse<IDocumentType>>()
  }

  static findByType(type: string) {
    return super._findById<IDocumentType>(type)
  }
}
