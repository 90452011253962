import React from 'react'
import {useTranslation} from 'react-i18next'

import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {getLocale} from '@d1g1t/shared/polyfill'
import {AVAILABLE_LANGUAGES_OPTIONS} from '@d1g1t/shared/wrappers/localization-settings/constants'
import {AVAILABLE_LANGUAGES} from '@d1g1t/shared/wrappers/localization-settings/typings'

export const LanguageSelector: React.FC = () => {
  const {i18n} = useTranslation()

  const locale = getLocale(i18n.language)

  const onLanguageChange = (locale: AVAILABLE_LANGUAGES) => {
    i18n?.changeLanguage(locale)
  }
  return (
    <div
      style={{
        top: 0,
        margin: 12,
        position: 'absolute',
        right: 0
      }}
    >
      <ValueLabelSelect
        size='small'
        noBorder
        greyBackground
        boldText
        largerFontSize
        options={AVAILABLE_LANGUAGES_OPTIONS}
        value={locale}
        onChange={onLanguageChange}
        data-testid='language-selector'
      />
    </div>
  )
}
