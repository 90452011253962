import {find} from 'lodash'

import {IDateRange} from '@d1g1t/lib/date-range'

/**
 * `UI_DATE_RANGES` are custom additions to `DATE_RANGES`.
 *
 * @remarks - These are only acceptable as display options,
 * these DO NOT have any official API support.
 */
export enum UI_DATE_RANGES {
  PAGE_PERIOD = 'page_period'
}

export const UI_DATE_RANGES_OPTIONS: IValueLabelOption<UI_DATE_RANGES>[] = [
  {
    value: UI_DATE_RANGES.PAGE_PERIOD,
    label: 'Sync to selected period'
  }
]

export const DATE_OPTION_PAGE_PERIOD: IDateRange = {
  ...find(UI_DATE_RANGES_OPTIONS, {
    value: UI_DATE_RANGES.PAGE_PERIOD
  }),
  startDate: undefined,
  endDate: undefined
}
