import {HttpEndpoints, RequestBody, RestEndpoints} from 'fairlight'

import {IChartTable} from '@d1g1t/api/models'

import {MIME_TYPE} from '@d1g1t/lib/constants'

/**
 * Provides only base HTTP endpoint methods.
 *
 * Use `BaseRestEndpoints` if you need REST endpoints such as
 * `findById`, `list`, `chart`, etc.
 */
export class BaseEndpoints extends HttpEndpoints {
  static trailingSlash = true
}

/**
 * Enhances `BaseEndpoints` with common RESTful methods.
 */
export class BaseRestEndpoints extends RestEndpoints {
  static trailingSlash = true

  /**
   * Performs a `POST` request at the `basePath` with "chart" appended to the path
   * @param body - request body
   */
  protected static _chart(body?: RequestBody, extraKey?: string) {
    return super._post<IChartTable>('/chart', {body, extraKey})
  }

  /**
   * Performs a `POST` request at the `basePath` with "chart" appended to the path
   * and sends headers to accepts Excel file format
   * @param body - request body
   */
  protected static _getExcelChart(body: RequestBody) {
    return super._post<Blob>('/chart', {
      headers: {Accept: MIME_TYPE.excel},
      body
    })
  }

  /**
   * Performs a `POST` request at the `basePath` with no path appended
   * and sends headers to accepts Excel file format
   * @param body - request body
   */
  protected static _getExcel(body: RequestBody) {
    return super._post<Blob>('/', {
      headers: {Accept: MIME_TYPE.excel},
      body
    })
  }
}
