import React from 'react'

import {FEATURE_FLAGS} from '@d1g1t/config/feature-flags'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {CircularProgress} from '@d1g1t/shared/components/mui/circular-progress'

import css from './style.scss'

interface ILoadingContainerProps {
  loading: boolean
  LoadingSpinnerProps?: ILoadingSpinnerProps
}

/**
 * Should be used to display a loading state for a `Loadable`, instead of `LoaderDisplay`.
 *
 * This is designed to set us up for `<Suspense>` data-fetching in the future:
 *   - It should be rendered at the highest possible point in the component's element
 *     hierarchy, so we can switch to wrapping the component with `<Suspense>` with relative ease.
 *   - Eventually, `<LoadingSpinnerProps>` will be used as the `Suspense#fallback`.
 *     For that reason, this component should avoid adding additional markup
 *     so we can easily switch over when the time comes.
 */
export const LoadingContainer: React.FC<ILoadingContainerProps> = (props) => {
  return (
    <>
      {props.loading && (
        <LoadingSpinnerProps {...(props.LoadingSpinnerProps || {})} />
      )}
      {props.children}
    </>
  )
}

interface ILoadingSpinnerProps {
  size?: number
}

const LoadingSpinnerProps: React.FC<ILoadingSpinnerProps> = ({size = 100}) => {
  return (
    <div data-testid='loading-container' className={classNames(css.overlay)}>
      <Flex justifyCenter alignCenter fullHeight>
        {FEATURE_FLAGS.VALENTINES_DAY_HEART_PROGRESS ? (
          <HeartBeatProgress />
        ) : (
          <CircularProgress size={size} />
        )}
      </Flex>
    </div>
  )
}

export const HeartBeatProgress = () => {
  return <div className={css.heart} />
}
