import React from 'react'

import * as css from './style.scss'

interface ISpacerProps {
  vertical?: boolean

  /**
   * 5px
   */
  xxxs?: boolean
  /**
   * 10px
   */
  xxs?: boolean
  /**
   * 20px
   */
  xs?: boolean
  /**
   * 30px
   */
  sm?: boolean
  /**
   * 40px
   */
  md?: boolean
  /**
   * 50px
   */
  lg?: boolean
  /**
   * 70px
   */
  xl?: boolean

  custom?: number
  className?: string
}

export const Spacer: React.SFC<ISpacerProps> = (props) => {
  // xxs, xs, sm, md, lg, or xl
  const classes = [
    css.spacer,
    props.vertical ? css.vertical : css.horizontal,
    props.xxxs ? css.xxxs : '',
    props.xxs ? css.xxs : '',
    props.xs ? css.xs : '',
    props.sm ? css.sm : '',
    props.md ? css.md : '',
    props.lg ? css.lg : '',
    props.xl ? css.xl : '',
    props.className
  ]

  if (props.custom) {
    const style = props.vertical
      ? {width: `${props.custom}px`}
      : {height: `${props.custom}px`}

    return <div className={classes.join(' ')} style={style} />
  }

  return <div className={classes.join(' ')} />
}
