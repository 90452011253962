import {ILiquidityScore} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class LiquidityScoresEndpoints extends BaseRestEndpoints {
  static basePath = '/liquidityscores'

  static list() {
    return super._list<IApiListResponse<ILiquidityScore>>()
  }
}
