import {IAssetClassL2} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AssetClassL2Endpoints extends BaseRestEndpoints {
  static basePath = '/asset-classes-l2'

  static list() {
    return super._list<IApiListResponse<IAssetClassL2>>()
  }
}

export class AssetClassL2VectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/asset-class-l2-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
