export enum SUPPORTED_CURRENCY {
  CAD = 'CAD',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP'
}

export const currencyFullName = (code: SUPPORTED_CURRENCY): string => {
  switch (code) {
    case SUPPORTED_CURRENCY.CAD:
      return 'Canadian Dollars'
    case SUPPORTED_CURRENCY.USD:
      return 'US Dollars'
    case SUPPORTED_CURRENCY.EUR:
      return 'Euros'
    case SUPPORTED_CURRENCY.GBP:
      return 'British Pounds'
  }
}

export const currencyCodeToSymbol = (code: SUPPORTED_CURRENCY): string => {
  switch (code) {
    case SUPPORTED_CURRENCY.CAD:
    case SUPPORTED_CURRENCY.USD:
      return '$'
    case SUPPORTED_CURRENCY.EUR:
      return '€'
    case SUPPORTED_CURRENCY.GBP:
      return '£'
    default:
      return ''
  }
}

export const currencyAbbreviation = (code: SUPPORTED_CURRENCY): string => {
  switch (code) {
    case SUPPORTED_CURRENCY.CAD:
      return 'CA'
    case SUPPORTED_CURRENCY.USD:
      return 'US'
    case SUPPORTED_CURRENCY.EUR:
    case SUPPORTED_CURRENCY.GBP:
      return ''
    default:
      return code
  }
}

export const currencyPrefix = (
  settingsCurrency: SUPPORTED_CURRENCY,
  code: SUPPORTED_CURRENCY
): string => {
  const symbol = currencyCodeToSymbol(code)

  if (settingsCurrency === code) {
    return symbol
  }

  const abbreviation = currencyAbbreviation(code)

  return `${abbreviation}${symbol}`
}

export class Currency {
  code: SUPPORTED_CURRENCY

  constructor(currencyString: string) {
    this.code = currencyString.toUpperCase() as SUPPORTED_CURRENCY
  }

  get fullName(): string {
    return currencyFullName(this.code)
  }

  get symbol(): string {
    return currencyCodeToSymbol(this.code)
  }

  get abbreviation(): string {
    return currencyAbbreviation(this.code)
  }

  prefix(settingsCurrency: SUPPORTED_CURRENCY): string {
    return currencyPrefix(settingsCurrency, this.code)
  }
}
