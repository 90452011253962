import {ApiParam} from 'fairlight'

import {
  IChartTable,
  ISleeveChangedValue,
  ITradeOrder,
  ITradeOrderBulk
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'
import {IFilterValue} from '@d1g1t/typings/general'

import {IDateRange} from '@d1g1t/lib/date-range'
import {IMetricRequestSelection} from '@d1g1t/lib/metrics'

import {BaseRestEndpoints} from './base'

export interface ITradeOrderChartRequestBody {
  metrics?: {
    selected: IMetricRequestSelection[]
  }
  filter?: IFilterValue
  options?: {
    dateRange?: IDateRange
    /**
     * Expands the aggregated trades into the individual child trades.
     * Allows for trade filtering to specific accounts that aggregated trades prevents.
     * Also allows for advisors to pick their preferred view method.
     */
    showTradesAsUngroupedItems?: boolean
  }
  pagination?: IPaginationOptions
}

interface ITradeOrderSummaryRequestBody {
  filter: IFilterValue
}

export interface IBulkPartialTradeOrder
  extends Omit<Partial<ITradeOrder>, 'entityId'> {
  entityId: string // required
}

export interface IAggregateResponse {
  resultingParents: string[] // ids
  skipped: string[] // ids
}

export interface IOrderAllocationSubmissionPayload {
  tradeOrder: string // id
  qtyFilled?: number // allocation
}

export interface IDownloadAllocation {
  data: string
  filename: string
}

export interface ITradeOrderPreviewRequestBody
  extends Required<
    Pick<
      ITradeOrder,
      | 'account'
      | 'instrument'
      | 'operation'
      | 'commission'
      | 'commissionType'
      | 'qtyType'
      | 'type'
      | 'limitPrice'
      | 'isSellAll'
      | 'expectedPrice'
    >
  > {
  quantityChange: number
  asOfDate: string
}
export interface ITradeOrderPreviewResponse {
  expectedTradeValue: number
  fundsAvailablePostTrade: number
  totalCommission: number
  /** account currency url */
  accountCurrency: string
  /** instrument currency url */
  instrumentCurrency: string
  fxRate: number
}

export class TradingOrderEndpoints extends BaseRestEndpoints {
  static basePath = '/trading/orders'

  static findById(id: ApiParam) {
    return super._findById<ITradeOrder>(id)
  }

  static summary(body: ITradeOrderSummaryRequestBody) {
    return super._post<ITradeOrder>('/summary', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static partialUpdate(id: ApiParam, body: Partial<ITradeOrder>) {
    return super._partialUpdate<ITradeOrder>(id, body)
  }

  static create(orders: ITradeOrder | ITradeOrder[]) {
    return super._create(orders)
  }

  static createSleeveOrders(
    trades: ITradeOrder | ITradeOrder[],
    currencyTargets: ISleeveChangedValue[]
  ) {
    return super._post('/sleeve-orders', {body: {trades, currencyTargets}})
  }

  static chart(body: ITradeOrderChartRequestBody) {
    return super._chart(body)
  }

  static getExcel(body: ITradeOrderChartRequestBody) {
    return super._getExcelChart(body)
  }

  static bulkUpdate(body: IBulkPartialTradeOrder[]) {
    return super._patch<ITradeOrder[]>('/', {body})
  }

  static aggregate(body: ITradeOrderBulk) {
    return super._post<IAggregateResponse>('/aggregate', {body})
  }

  static disaggregate(body: ITradeOrderBulk) {
    return super._post<ITradeOrder[]>('/disaggregate', {body})
  }

  static aggregateChart(id: ApiParam, body: ITradeOrderChartRequestBody) {
    return super._post<IChartTable>(`${super._singlePath(id)}/chart`, {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static submitAllocations(body: IOrderAllocationSubmissionPayload[]) {
    return super._post<Blob>('/allocate', {body})
  }

  static bulkAllocate(body: IOrderAllocationSubmissionPayload[]) {
    return super._post<IDownloadAllocation[]>('/bulk_allocate', {body})
  }

  static preview(body: ITradeOrderPreviewRequestBody) {
    return super._post<ITradeOrderPreviewResponse>('/preview', {body})
  }

  static aggregateAll(body: ITradeOrderChartRequestBody) {
    return super._post('/aggregate-all', {body})
  }
}
