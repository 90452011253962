import {IHouseholdRefreshPayload, IProcess} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class ProcessingEndpoints extends BaseRestEndpoints {
  static basePath = '/processing/process'

  static householdRefresh(body: IHouseholdRefreshPayload) {
    return super._post<IProcess>('/household-refresh/start', {body})
  }

  static householdRefreshStatus(body: IHouseholdRefreshPayload) {
    return super._post<IProcess>('/household-refresh/status', {body})
  }
}
