import {FILTERCRITERION_NODE_TYPE, IFilterCriterion} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse, IItemsResponse} from './typings'

export enum Formats {
  string = 'string',
  decimal = 'decimal',
  percentage = 'percentage',
  int = 'integer',
  date = 'date',
  enum = 'enum',
  default = 'default'
}

export enum Types {
  contains = 'contains',
  is = 'is',
  isNot = 'isNot',
  oneOf = 'oneOf',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  between = 'between',
  dateBetween = 'dateBetween',
  lteq = '<=',
  gteq = '>=',
  noteq = '!=',
  eq = '=',
  in = 'in',
  after = 'after',
  before = 'before',
  on = 'on'
}

export interface IFilterCriteriaOperator {
  id: number
  roleId?: number
  teamId?: number
  type: string
  key: string
  name: string
  default?: boolean
  url: string
  slug: string
}

export interface IFilterCriteriaItem {
  type: string
  key: string
  name: FILTERCRITERION_NODE_TYPE
  default?: boolean
  format?: Formats
  operators?: IFilterCriteriaOperator[]
  items?: IFilterCriteriaItem[]
  id?: number
  allowedValues?: IValueLabelOption[]
}
export class ConstantsFilterCriterionEndpoints extends BaseRestEndpoints {
  static basePath = '/constants/filtercriterion'

  static tree(query: {isGlobal: boolean}) {
    return super._get<IItemsResponse<IFilterCriteriaItem>>('/tree', {
      query: {is_global: query.isGlobal ? 1 : undefined}
    })
  }

  static list() {
    return super._list<IApiListResponse<IFilterCriterion>>({limit: 2000})
  }
}
