import {ApiParam} from 'fairlight'

import {
  IChartTable,
  IPerson,
  IPrivacyDeclarationAnswer
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class UserAccountPeopleEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/people'

  static list() {
    return super._list<IApiListResponse<IPerson>>()
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static enable(id: ApiParam) {
    return super._post<IPerson>(`${this._singlePath(id)}/enable`)
  }

  static disable(id: ApiParam) {
    return super._post<IPerson>(`${this._singlePath(id)}/disable`)
  }

  static preEnable(id: string) {
    return super._post<IPerson>(`${this._singlePath(id)}/pre-enable`)
  }

  static permissionsChart(id: string) {
    return this._post<IChartTable>(`${this._singlePath(id)}/chart`)
  }

  static privacyDeclarationPartialAnswers(
    entityId: ApiParam,
    answers: IPrivacyDeclarationAnswer[]
  ) {
    return super._post<IPrivacyDeclarationAnswer[]>(
      `${super._singlePath(entityId)}/privacy-declaration-answers`,
      {body: answers}
    )
  }

  static findById(entityId: ApiParam) {
    return super._findById<IPerson>(entityId)
  }

  static findByIds(params: {entityIds: string[]}) {
    return super._list<IApiListResponse<IPerson>>({
      entity_id__in: params.entityIds.join(','),
      limit: 10000
    })
  }
}
