import {
  IFirmConfiguration,
  IFirmConfigurationUnauthenticated
} from '@d1g1t/api/models'

import {BaseEndpoints} from './base'

export class FirmConfigurationEndpoints extends BaseEndpoints {
  static basePath = '/people/firm-configuration'

  /**
   * Get data for the firm at the configured API subdomain
   */
  static current() {
    return super._get<IFirmConfiguration>('/current')
  }

  /**
   * Get basic data for the firm at the configured API subdomain
   */
  static preLogin() {
    return super._get<IFirmConfigurationUnauthenticated>('/pre-login')
  }
}
