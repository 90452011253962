import {ApiParam} from 'fairlight'

import {IAccountsProfile} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class UserAccountProfilesEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/profiles'

  static list() {
    return super._list<IApiListResponse<IAccountsProfile>>({limit: 1000})
  }

  static create(body: DeepPartial<IAccountsProfile>) {
    return super._create<IAccountsProfile>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IAccountsProfile>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IAccountsProfile>) {
    return super._partialUpdate<IAccountsProfile>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static profilePhotoUpload(id: ApiParam, fileBody: FormData) {
    return super._patch<never>(`/${id}/profile-photo`, {body: fileBody})
  }

  static profilePhotodelete(id: ApiParam) {
    return super._patch<never>(`/${id}/profile-photo`, {
      body: {delete_internal_photo: true}
    })
  }
}
