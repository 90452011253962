import React from 'react'

import {H1} from '@d1g1t/shared/components/typography'
import {useAuth, useLoginRedirectIfLoggedOut} from '@d1g1t/shared/wrappers/auth'

export const LogoutPage: React.FC = () => {
  const auth = useAuth()

  useLoginRedirectIfLoggedOut(false)

  auth.logout()

  return <H1>Logging out...</H1>
}
