import {ApiParam} from 'fairlight'

import {
  IReferenceIndexAssignmentInput,
  IReferenceIndexAssignmentOutput
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class BenchmarksEndpoints extends BaseRestEndpoints {
  static basePath = '/reference-index-assignments'

  static chart() {
    return super._chart({})
  }

  static create(body: IReferenceIndexAssignmentInput) {
    return super._create<IReferenceIndexAssignmentOutput>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }
}
