import {RouteBuilder} from '@d1g1t/lib/url'

export class ContactGroupsLocations extends RouteBuilder {
  static basePath = '/contact-groups'

  static contactGroupCreate() {
    return super.path('/create')
  }

  static contactGroupSingleEdit(id: string) {
    return super.path(`/edit/${id}`)
  }

  static contactGroupSingleView(id: string) {
    return super.path(`/${id}`)
  }
}
