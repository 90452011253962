import {ApiParam} from 'fairlight'

import {IAccount, IChartTable} from '@d1g1t/api/models'
import {IControl} from '@d1g1t/typings/general'

import {SUPPORTED_CURRENCY} from '@d1g1t/lib/currency'
import {IMetricRequestSelection} from '@d1g1t/lib/metrics'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

interface ISettings {
  currency: SUPPORTED_CURRENCY | string
  date: {
    asOfDate: string
    value: string
  }
}

interface IAccountsChartRequestBody {
  control: IControl
  settings: ISettings
  options?: {
    singleResult?: boolean
  }
  metrics?: {
    selected: IMetricRequestSelection[]
  }
}

interface IAccountsTrackAllocationRequestBody {
  /**
   * Expects client or household entityId
   */
  entityId: string
  metrics?: {
    selected: IMetricRequestSelection[]
  }
  currency: string
  asOfDate: string
}

interface IAccountsRebalancingProgress {
  pendingCountUnderFirm: number
}

export class AccountEndpoints extends BaseRestEndpoints {
  static basePath = '/accounts'

  static list(params: {
    ownerEntityId: string
    asOfDate: string
    currency: string
  }) {
    return super._list<IApiListResponse<IAccount>>({
      currency: params.currency,
      owner_entity_id: params.ownerEntityId,
      as_of_date: params.asOfDate
    })
  }

  static findById(id: ApiParam) {
    return super._findById<IAccount>(id)
  }

  static create(body: IAccount) {
    return super._create<IAccount>(body)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IAccount>) {
    return super._partialUpdate<IAccount>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static chart(body: IAccountsChartRequestBody) {
    return super._chart(body, JSON.stringify(body))
  }

  static trackAllocations(body: IAccountsTrackAllocationRequestBody) {
    return super._post<IChartTable>('/track-allocations/', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static rebalancingProgress() {
    return super._get<IAccountsRebalancingProgress>('/rebalancing-progress/')
  }

  static clearRebalanceQueue() {
    return super._post('/clear-rebalance-queue/')
  }
}
