import {combineReducers} from 'redux'

import {ActionType, getType} from 'typesafe-actions'

import {injectAsyncReducer} from '@d1g1t/lib/redux-inject-async'

import {snackbarActions} from './actions'
import {SNACKBAR_PREFIX} from './constants'
import {ISnackbarState} from './typings'

export const snackbarReducer = combineReducers<
  ISnackbarState,
  ActionType<typeof snackbarActions>
>({
  open: (prev = false, action) => {
    switch (action.type) {
      case getType(snackbarActions.show):
        return true
      case getType(snackbarActions.close):
        return false
      default:
        return prev
    }
  },
  params: (prev = null, action) => {
    switch (action.type) {
      case getType(snackbarActions.show):
        return {...action.payload}
      default:
        return prev
    }
  }
})

injectAsyncReducer(SNACKBAR_PREFIX, snackbarReducer)
