import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {Span, Text} from '@d1g1t/shared/components/typography'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {AdministrationLocations} from '@d1g1t/advisor/locations'

import {getAlertMessages} from './lib'

/**
 * Programmatically triggers a file upload and uploads
 * the file using the given `upload` function.
 */
export function useFileUploader(params: {
  /**
   * Whitelist of mime types to allow for the upload
   */
  mimeTypes: string[]
  /**
   * Callback to run the actual file upload. Must return a promise.
   */
  upload(selectedFiles: FileList): Promise<any>
  /**
   * Callback which runs after a successful upload.
   */
  onSuccess?(): void
  /**
   * Success snack bar will contain different message with link to check status.
   * Used with runAsync activated upload endpoints.
   */
  successWithLink?: boolean
  /**
   * Success and Info Snackbars wont be shown during upload process.
   * Error snackbars will still show
   */
  hideSuccessSnackbars?: boolean
}): [
  /**
   * True if the file is uploading
   */
  boolean,
  /**
   * Triggers the upload
   */
  () => void
] {
  const [uploading, setUploading] = useState(false)
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const history = useHistory()

  const upload = () =>
    openFileSelect({mimeTypes: params.mimeTypes}, async (selectedFiles) => {
      setUploading(true)
      if (!params.hideSuccessSnackbars) {
        showSnackbar({
          variant: 'info',
          message: 'Uploading ...'
        })
      }

      try {
        const uploadResult = await params.upload(selectedFiles)
        const alertMessages = uploadResult && getAlertMessages(uploadResult)

        if (!!alertMessages) {
          if (alertMessages.errors.length > 0) {
            showSnackbar({
              variant: 'error',
              message: alertMessages.errors.concat(alertMessages.warnings)
            })
            return
          }
          if (
            alertMessages.errors.length === 0 &&
            alertMessages.warnings.length > 0
          ) {
            showSnackbar({
              variant: 'warning',
              message: alertMessages.warnings
            })
            return
          }
        }

        if (!params.hideSuccessSnackbars) {
          showSnackbar({
            variant: 'success',
            message: params.successWithLink ? (
              <Text>
                File uploaded and scheduled for processing.{' '}
                <Span
                  bold
                  action
                  style={{
                    color: '#fff'
                  }}
                  onClick={() => {
                    history.push(
                      AdministrationLocations.fileProcessingSingle(
                        extractIdFromUrl(uploadResult.url)
                      )
                    )
                  }}
                >
                  View status
                </Span>
              </Text>
            ) : (
              'Upload complete.'
            )
          })
        }

        if (params.onSuccess) {
          params.onSuccess()
        }
      } catch (error) {
        handleUnexpectedError(error, 'The upload unexpectedly failed.')
      } finally {
        setUploading(false)
      }
    })

  return [uploading, upload]
}

/**
 * Creates a DOM input and simulates the user clicking it
 *
 * https://stackoverflow.com/questions/16215771/how-open-select-file-dialog-via-js/40971885#40971885
 */
function openFileSelect(
  opts: {mimeTypes: string[]},
  onSelect: (files: FileList) => void
): void {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = opts.mimeTypes.join(', ')

  input.onchange = (e: Event) => {
    const {files} = e.target as HTMLInputElement
    onSelect(files)
  }

  input.click()
}
