import {ApiParam} from 'fairlight'

import {IFeeSchedule} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface IFeeSchedulesRequestBody {
  pagination?: IPaginationOptions
}
export class FeeSchedulesEndpoints extends BaseRestEndpoints {
  static basePath = '/fees/schedules'

  static list() {
    return super._list<IApiListResponse<IFeeSchedule>>()
  }

  static chart(body: IFeeSchedulesRequestBody) {
    return super._chart(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static findById(id: ApiParam) {
    return super._findById<IFeeSchedule>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IFeeSchedule>) {
    return super._partialUpdate<IFeeSchedule>(id, body)
  }

  static create(body: IFeeSchedule) {
    return super._create<IFeeSchedule>(body)
  }
}
