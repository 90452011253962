import {RouteBuilder, selectionForAccount, UrlSelection} from '@d1g1t/lib/url'

export class TradeLocations extends RouteBuilder {
  static basePath = 'trade'

  static manageOrders() {
    return super.path('manage-orders')
  }

  static rebalance(selection?: UrlSelection) {
    return super.withSelection('rebalance', selection)
  }

  static managePortfolios() {
    return super.path('manage-portfolios')
  }

  static rebalancePortfolios(selection?: UrlSelection) {
    return super.withSelection('rebalance-portfolios', selection)
  }

  static bulkChangeAllocation(selection?: UrlSelection) {
    return super.withSelection('bulk-change-allocation', selection)
  }

  static equitiesEtfs(
    clientId?: string,
    accountId?: string,
    currency?: string
  ) {
    return super.withSelection(
      'buy-sell/equities-etfs',
      selectionForAccount(accountId, clientId, currency)
    )
  }

  static mutualFunds(clientId?: string, accountId?: string, currency?: string) {
    return super.withSelection(
      'buy-sell/mutual-funds',
      selectionForAccount(accountId, clientId, currency)
    )
  }
}
