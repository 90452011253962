import {ApiParam} from 'fairlight'

import {IFilterRule} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class RuleFilterEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/filter-rules'

  static list() {
    return super._list<IApiListResponse<IFilterRule>>()
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IFilterRule>) {
    return super._partialUpdate<IFilterRule>(id, body)
  }

  static create(params: IFilterRule) {
    return super._create<IFilterRule>(params)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }
}
