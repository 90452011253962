import {IProfileGroupType} from '@d1g1t/api/models'

import {BaseEndpoints} from './base'
import {IApiListResponse} from './typings'

export class UserAccountEndpoints extends BaseEndpoints {
  static basePath = '/user-accounts'

  static profileGroupTypes() {
    return super._get<IApiListResponse<IProfileGroupType>>(
      '/profile-group-types'
    )
  }
}
