export enum SECURITY_SEARCH_FILTER_KEYS {
  SECURITY_TYPE = 'securityType',
  TRADING_CURRENCY = 'tradingCurrency',
  EXCHANGE_CODES = 'exchangeCodes'
}

export const SECURITY_SEARCH_FILTER_PARAMS_KEYS = {
  [SECURITY_SEARCH_FILTER_KEYS.SECURITY_TYPE]: 'security_type__in',
  [SECURITY_SEARCH_FILTER_KEYS.TRADING_CURRENCY]: 'trading_currency__in',
  [SECURITY_SEARCH_FILTER_KEYS.EXCHANGE_CODES]: 'exchange_codes'
}

export const DEFAULT_SECURITY_FILTER_VALUES: {
  [SECURITY_SEARCH_FILTER_KEYS.SECURITY_TYPE]: string[]
  [SECURITY_SEARCH_FILTER_KEYS.TRADING_CURRENCY]: string[]
  [SECURITY_SEARCH_FILTER_KEYS.EXCHANGE_CODES]: string[]
} = {
  [SECURITY_SEARCH_FILTER_KEYS.SECURITY_TYPE]: [],
  [SECURITY_SEARCH_FILTER_KEYS.TRADING_CURRENCY]: [],
  [SECURITY_SEARCH_FILTER_KEYS.EXCHANGE_CODES]: []
}
