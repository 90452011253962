import {ITrust} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeopleTrustEndpoints extends BaseRestEndpoints {
  static basePath = '/people/trusts'

  static create(body: ITrust) {
    return super._create<ITrust>(body)
  }

  static findById(id: string) {
    return super._findById<ITrust>(id)
  }

  static partialUpdate(id: string, body: DeepPartial<ITrust>) {
    return super._partialUpdate<ITrust>(id, body)
  }
}
