import thunkMiddleware from 'redux-thunk'

import {createAsyncInjectStore} from '@d1g1t/lib/redux-inject-async'

import {rootSaga} from './sagas'
import {ISagaContext} from './typings'

const initialState = {}

export const createStore = (sagaContext: ISagaContext) => {
  const middlewares = [thunkMiddleware.withExtraArgument(sagaContext)]

  const store = createAsyncInjectStore({}, initialState, middlewares)

  store.runSaga(rootSaga, sagaContext)

  if (process.env.NODE_ENV === 'development') {
    window.store = store
  }

  return store
}
