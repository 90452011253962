import {ApiParam} from 'fairlight'

import {
  IAdHocReport,
  IChartTable,
  IEntitySelectionInput,
  IReport,
  IReportsContactsResponse,
  IReportUserShareDefault,
  IRunReportsInput
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'
import {IApiListResponse} from '@d1g1t/api/typings'

import {IDateRange} from '@d1g1t/lib/date-range'

import {BaseRestEndpoints} from './base'

interface IReportsBody {
  pagination?: IPaginationOptions
  options?: {
    dateRange?: IDateRange
  }
}
interface IReportStatusResponse {
  /**
   * Number of reports that are currently being prepared
   */
  qtyReportsInQueue: number
}

export class ReportingReportEndpoints extends BaseRestEndpoints {
  static basePath = '/reporting/reports'

  static create(body: IReport) {
    return super._create<IReport>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IReport>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IReport>) {
    return super._partialUpdate<IReport>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static chart(body: IReportsBody) {
    return super._chart(body, JSON.stringify(body))
  }

  static list() {
    return super._list<IApiListResponse<IReport>>()
  }

  static accounts(body: IEntitySelectionInput) {
    return super._post<IChartTable>('/accounts', {
      body,
      extraKey: JSON.stringify(body.addedEntities)
    })
  }

  static adHoc(body: IAdHocReport) {
    return super._post<Blob>('/ad-hoc', {body})
  }

  static run(body: IRunReportsInput) {
    return super._post<never>('/run', {body})
  }

  static confirm(body: IRunReportsInput) {
    return super._post<IChartTable>('/confirm', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static reportStatus() {
    return super._get<IReportStatusResponse>('/report-status')
  }
}

interface IReportsShareBody {
  report__slug: string
  contact_owner__entity?: string
}
type ShareBodyRecords = Partial<Record<keyof IReportsShareBody, string>>

export interface IReportContactBody {
  report: string
  contact?: string
  contact_group?: string
  contact_owner: string
}

export class ReportingShareEndpoints extends BaseRestEndpoints {
  static basePath = '/reporting/reports-share/contact'

  static create(body: IReportContactBody) {
    return super._create<IReport>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static contacts(body: ShareBodyRecords) {
    return super._get<IApiListResponse<IReportsContactsResponse>>('', {
      query: body
    })
  }
}

export interface IReportPermissionsBody {
  reportSlug: string
}
export class ReportingPermissionsEndpoints extends BaseRestEndpoints {
  static basePath = '/reporting/reports-share/user'

  static create(body: IReportUserShareDefault) {
    return super._create<IReportUserShareDefault>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static update(id: ApiParam, body: DeepPartial<IReportUserShareDefault>) {
    return super._partialUpdate(id, body)
  }

  static permissions(body: ShareBodyRecords) {
    return super._get<IApiListResponse<IReportUserShareDefault>>('', {
      query: body
    })
  }
}
