import {
  IChartTable,
  IFileProcess,
  IFileProcessMessage,
  IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class ImportersEndpoints extends BaseRestEndpoints {
  static basePath = '/importers'

  static fileProcessList() {
    return super._get<IApiListResponse<IFileProcess>>(
      '/file-processing/file-process'
    )
  }

  static fileProcessChart() {
    return super._post<IChartTable>('/file-processing/file-process/chart')
  }

  static fileProcessDetails(id: string) {
    return super._get<IFileProcess>(`/file-processing/file-process/${id}`)
  }

  static fileProcessMessages(id: string) {
    return super._get<IApiListResponse<IFileProcessMessage>>(
      '/file-processing/messages/',
      {
        query: {file_process_id: id}
      }
    )
  }

  static fileProcessMessagesChart(id: string) {
    return super._post<IChartTable>('/file-processing/messages/chart', {
      query: {file_process_id: id}
    })
  }

  static bulkDelete(body: {ids: string[]}) {
    return super._post<never>('/file-processing/file-process/bulk-delete', {
      body
    })
  }
  // All uploads will use new endpoint `/upload` and will have
  // different corresponding `importer_name` property to differentiate
  // between the different upload types instead of a separate endpoint.
  // Will slowly turn all upload importers `run_async` property to `true`
  // after they are tested.

  static uploadV2(
    body: FormData,
    importerName: IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME,
    runAsync = false
  ) {
    body.append('run_async', String(runAsync))
    body.append('importer_name', importerName)

    return super._post<never>('/upload', {body})
  }

  static transactionsUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.TRANSACTIONIMPORTER,
      true
    )
  }

  static accountsUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.ACCOUNTIMPORTER,
      true
    )
  }

  static marketPricesUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.MARKETPRICEIMPORTER,
      true
    )
  }

  static instrumentsUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.INSTRUMENTIMPORTER,
      true
    )
  }

  static tradeRestrictionUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.SECURITYRESTRICTIONIMPORTER,
      true
    )
  }

  static portfoliosUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.REBALANCINGROWDRAFTIMPORTER,
      true
    )
  }

  static teamRoleUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.TEAMROLEIMPORTER,
      true
    )
  }

  static tradeLocationRulesUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.TRADELOCATIONIMPORTER,
      true
    )
  }

  static allocationExclusionUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.ALLOCATIONEXCLUSIONIMPORTER,
      true
    )
  }

  static contactsUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.CONTACTMULTISHEETIMPORTER,
      true
    )
  }

  static contactGroupsUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.CONTACTGROUPMULTISHEETIMPORTER,
      true
    )
  }

  static accessControlUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.CONTACTACCESSMULTISHEETIMPORTER,
      true
    )
  }

  static clientsUpload(body: FormData) {
    return this.uploadV2(
      body,
      IMPORTERUPLOADSERIALIZERV2_IMPORTER_NAME.CLIENTMULTISHEETIMPORTER,
      true
    )
  }
}
