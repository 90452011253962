import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {IPagePaddingProps} from './typings'

import * as css from './style.scss'

/**
 * A wrapper which adds padding around its child component
 */
export const PagePadding: React.FC<IPagePaddingProps> = ({
  className,
  secondaryNavigation,
  ...props
}) => (
  <div
    className={classNames(css.pagePadding, className, {
      [css.secondaryNavigation]: secondaryNavigation
    })}
    {...props}
  />
)
