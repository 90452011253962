import {IPerson} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeoplePeopleEndpoints extends BaseRestEndpoints {
  static basePath = '/people/people'

  static create(body: IPerson) {
    return super._create<IPerson>(body)
  }

  static findById(id: string) {
    return super._findById<IPerson>(id)
  }

  static partialUpdate(id: string, body: Partial<IPerson>) {
    return super._partialUpdate<IPerson>(id, body)
  }
}
