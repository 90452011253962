import {camelCase, isArray, isPlainObject, reduce, snakeCase} from 'lodash'

import {
  performanceMarkStart,
  performanceMeasureFromStartMark
} from '@d1g1t/lib/performance'

function innerCamelize(
  data: any,
  options: {
    maxDepth?: number
    startDepth?: number
  }
) {
  const {maxDepth, startDepth} = options

  if (Number.isFinite(maxDepth) && maxDepth <= 0) {
    return data
  }

  if (isArray(data)) {
    return data.map((a) =>
      innerCamelize(a, {
        maxDepth: Number.isFinite(maxDepth) && maxDepth - 1,
        startDepth: Number.isFinite(startDepth) && startDepth - 1
      })
    )
  }

  if (isPlainObject(data)) {
    return reduce(
      data,
      (result, value, key) => {
        const newKey =
          Number.isFinite(startDepth) && startDepth > 0 ? key : camelCase(key)

        return {
          ...result,
          [newKey]: innerCamelize(value, {
            maxDepth: Number.isFinite(maxDepth) && maxDepth - 1,
            startDepth: Number.isFinite(startDepth) && startDepth - 1
          })
        }
      },
      {}
    )
  }

  return data
}

/**
 * Converts key strings of object/array to camel case.
 * @param data - Data to be camelized.
 * @param options - Options to customize camelization.
 */
export function camelize(
  data: any,
  camelizeOptions?: {
    /**
     * The furthest depth in the object/array to apply camelization.
     * Do not pass if you would like to camelize the whole object/array.
     */
    maxDepth?: number
    /**
     * The depth at which to begin camel casing keys. `0` meaning the top level.
     * Do not pass if you would like to camelize the whole object/array.
     */
    startDepth?: number
  }
) {
  const options = {maxDepth: null, startDepth: null, ...camelizeOptions}

  if (__DEVELOPMENT__) {
    performanceMarkStart('Camelize Response')
    const result = innerCamelize(data, options)
    performanceMeasureFromStartMark('Camelize Response')

    return result
  }

  return innerCamelize(data, options)
}

export function snakeify(data: any, maxDepth: Nullable<number> = null) {
  if (Number.isFinite(maxDepth) && maxDepth <= 0) {
    return data
  }

  if (isArray(data)) {
    return data.map((a) =>
      snakeify(a, Number.isFinite(maxDepth) ? maxDepth - 1 : null)
    )
  }

  if (isPlainObject(data)) {
    return reduce(
      data,
      (result, value, key) => ({
        ...result,
        [snakeCaseWithOverrides(key)]: snakeify(
          value,
          Number.isFinite(maxDepth) ? maxDepth - 1 : null
        )
      }),
      {}
    )
  }

  return data
}

const SNAKE_CASE_OVERRIDES = {
  assetClassL2: 'asset_class_l2',
  assetClassL2Override: 'asset_class_l2_override',
  assetClassL3: 'asset_class_l3',
  assetClassesL2: 'asset_classes_l2',
  assetClassesL3: 'asset_classes_l3'
}

function snakeCaseWithOverrides(key: string): string {
  return SNAKE_CASE_OVERRIDES[key] || snakeCase(key)
}
