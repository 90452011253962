import {ApiParam} from 'fairlight'

import {IBillSpecDetail, IBillSpecItemNested} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface IFeeSpecsRequestBody {
  pagination?: IPaginationOptions
}
export class FeeSpecsEndpoints extends BaseRestEndpoints {
  static basePath = '/fees/bill-specs'

  static list() {
    return super._list<IApiListResponse<IBillSpecDetail>>()
  }

  static chart(body: IFeeSpecsRequestBody) {
    return super._chart(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static findById(id: ApiParam) {
    return super._findById<IBillSpecDetail>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IBillSpecDetail>) {
    return super._partialUpdate<IBillSpecDetail>(id, body)
  }

  static create(body: IBillSpecDetail) {
    return super._create<IBillSpecDetail>(body)
  }

  static deleteBillSpecItems(id: ApiParam, body: {itemIds: number[]}) {
    return super._post<{deleted: number}>(`/${id}/remove-items/`, {body})
  }

  static createBillSpecItems(id: ApiParam, body: IBillSpecItemNested[]) {
    return super._post<IBillSpecItemNested[]>(`/${id}/items`, {
      body
    })
  }
}
