import React, {forwardRef} from 'react'

import MuiRadio, {RadioProps} from '@material-ui/core/Radio'

import {useControlState} from '@d1g1t/shared/components/control-state'
import {
  FormControlLabel,
  IFormControlLabelProps
} from '@d1g1t/shared/components/mui/form-control-label'

export interface IRadioProps extends RadioProps {}

export const Radio: React.FC<IRadioProps> = forwardRef((props, ref) => (
  <MuiRadio ref={ref} color='primary' {...props} />
))

export interface ILabeledRadioProps extends IRadioProps {
  label: React.ReactNode
  FormControlLabelProps?: Omit<IFormControlLabelProps, 'label' | 'control'>
}

export const LabeledRadio: React.FC<ILabeledRadioProps> = forwardRef(
  ({label, FormControlLabelProps, disabled, ...props}, ref) => {
    const controlState = useControlState()
    return (
      <FormControlLabel
        ref={ref}
        label={label}
        control={<Radio color='primary' {...props} />}
        disabled={disabled || controlState.loading}
        {...FormControlLabelProps}
      />
    )
  }
)
