import {IFxRate} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class FXRateEndpoints extends BaseRestEndpoints {
  static basePath = '/fxrates'

  static list(query: {
    foreign: string[] // currency names
    date__lte: string // YYYY-MM-DD
  }) {
    return super._list<IApiListResponse<IFxRate>>({
      ...query,
      foreign: query.foreign.join(',')
    })
  }
}
