import React from 'react'
import {useTranslation} from 'react-i18next'

import {makeStyles} from '@material-ui/core'

import {classNames} from '@d1g1t/lib/class-names'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import * as css from './style.scss'

export type Levels = 1 | 2 | 3 | 4 | 5 | 6

const useStyles = makeStyles((theme) => {
  return {
    primaryColour: {
      color: theme.palette.primary.main
    }
  }
})
export interface ITypographyOptions {
  /**
   * Use light font colour
   */
  light?: boolean
  /**
   * Use medium light font colour (#616161)
   */
  mediumLight?: boolean
  /**
   * Sets font weight to thinner then normal (200)
   */
  thin?: boolean
  /**
   * Sets font weight to normal (400)
   */
  normalWeight?: boolean
  /**
   * Sets font weight to semi-bold (600)
   */
  semiBold?: boolean
  /**
   * Sets font weight to bold (800)
   */
  bold?: boolean
  /**
   * Adds italics to font style
   */
  italics?: boolean
  /**
   * Removes top and bottom margin
   */
  noMargin?: boolean
  /**
   * Removes top margin
   */
  noTopMargin?: boolean
  /**
   * Removes bottom margin
   */
  noBottomMargin?: boolean
  /**
   * When true, sets indent level to 1
   * indent add a padding-left of 1rem (10px) * indent level
   */
  indent?: boolean | Levels
  /**
   * Use active font colour (light blue)
   */
  active?: boolean
  /**
   * Use primary colour for font (d1g1t blue #0d47a1)
   */
  primaryColour?: boolean
  /**
   * Use a light grey colour
   */
  deemphasize?: boolean
  /**
   * Applies CSS white-space: nowrap
   */
  noWrap?: boolean
  /**
   * Align text to left
   */
  alignLeft?: boolean
  /**
   * Align text to right
   */
  alignRight?: boolean
  /**
   * Align text to center
   */
  alignCenter?: boolean
  /**
   * Sets cursor to pointer to indicate element is clickable
   */
  pointer?: boolean
  /**
   * Adds style that indicate the text is a clickable action
   */
  action?: boolean
  /**
   * Used with button as child to indicate element is editable
   */
  editable?: boolean
  /**
   * Applies positive green colour
   */
  positive?: boolean
  /**
   * Applies negative red colour
   */
  negative?: boolean
  /**
   * Applies css line-height to element as merged inline style
   */
  lineHeight?: number
  /**
   * Prevents text highlighting
   */
  preventHighlight?: boolean
  /**
   * Applies css font-size
   */
  fontSize?: number | string
}

type HTMLTypograpghicElement =
  | HTMLDivElement
  | HTMLHeadingElement
  | HTMLParagraphElement
  | HTMLSpanElement

const levelOption = (cssBaseName: string, value: boolean | Levels) => {
  if (Number.isFinite(value as number)) {
    return css[`${cssBaseName}${value}`]
  }

  if (!!value) {
    return css[`${cssBaseName}1`]
  }

  return null
}

export const typographicElement = <E extends HTMLTypograpghicElement>(
  Element: keyof JSX.IntrinsicElements,
  appliedClassName: string
): React.SFC<ITypographyOptions & React.HTMLAttributes<E>> =>
  React.forwardRef(
    (
      {
        light,
        mediumLight,
        thin,
        normalWeight,
        semiBold,
        bold,
        italics,
        noMargin,
        noBottomMargin,
        noTopMargin,
        className,
        indent,
        active,
        primaryColour,
        deemphasize,
        noWrap,
        alignLeft,
        alignRight,
        alignCenter,
        pointer,
        action,
        editable,
        positive,
        negative,
        style,
        lineHeight,
        preventHighlight,
        fontSize,
        ...props
      },
      ref
    ) => {
      const E = Element as unknown as React.ComponentType<any>
      const classes = useStyles()
      const {t} = useTranslation()

      return (
        <E
          className={classNames(
            appliedClassName,
            className,
            levelOption('indent', indent),
            {
              [css.light]: light,
              [css.mediumLight]: mediumLight,
              [css.thin]: thin,
              [css.normalWeight]: normalWeight,
              [css.semiBold]: semiBold,
              [css.bold]: bold,
              [css.italics]: italics,
              [css.noMargin]: noMargin,
              [css.noBottomMargin]: noBottomMargin,
              [css.noTopMargin]: noTopMargin,
              [css.active]: active,
              [classes.primaryColour]: primaryColour,
              [css.deemphasize]: deemphasize,
              [css.noWrap]: noWrap,
              [css.alignLeft]: alignLeft,
              [css.alignRight]: alignRight,
              [css.alignCenter]: alignCenter,
              [css.pointer]: pointer,
              [css.action]: action,
              [css.editable]: editable,
              [css.positive]: positive,
              [css.negative]: negative,
              [css.preventHighlight]: preventHighlight
            }
          )}
          style={{
            lineHeight,
            fontSize,
            ...style
          }}
          ref={ref}
          {...props}
        >
          {onlyTranslateStrings(t, props.children)}
        </E>
      )
    }
  )

export const H1 = typographicElement<HTMLHeadingElement>(
  'h1',
  css.globalHeader1
)
export const H2 = typographicElement<HTMLHeadingElement>(
  'h2',
  css.globalHeader2
)
export const H3 = typographicElement<HTMLHeadingElement>(
  'h3',
  css.globalHeader3
)
export const H4 = typographicElement<HTMLHeadingElement>(
  'h4',
  css.globalHeader4
)
export const H5 = typographicElement<HTMLHeadingElement>(
  'h5',
  css.globalHeader5
)
export const H6 = typographicElement<HTMLHeadingElement>(
  'h6',
  css.globalHeader6
)
export const P = typographicElement<HTMLParagraphElement>(
  'p',
  css.globalParagraph
)
export const Text = typographicElement<HTMLDivElement>('div', css.text)
export const Span = typographicElement<HTMLSpanElement>('span', css.text)
export const Label = typographicElement<HTMLLabelElement>('label', css.label)
export const Pre = typographicElement<HTMLPreElement>('pre', css.label)

export const Pos: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  className,
  children,
  ...props
}) => <span className={classNames(css.positive, className)}>+{children}</span>

export const Neg: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  className,
  children,
  ...props
}) => <span className={classNames(css.negative, className)}>-{children}</span>
