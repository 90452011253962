import React, {Suspense} from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {useEmulation} from '@d1g1t/shared/wrappers/emulation'

import {ApplicationTopBar} from '@d1g1t/investor/containers/application-top-bar'

import {ApplicationFooter} from '../footer'

import * as css from './style.scss'

/**
 * Renders application frame container and containers application footer
 */
export const Frame: React.FC = ({children}) => {
  const emulator = useEmulation()

  return (
    <div className={css.container}>
      <ApplicationTopBar emulatorMode={!!emulator} />
      <div
        data-testid='container-main-area'
        className={classNames(css.applicationFrame, {
          [css.applicationFrameWithEmulator]: !!emulator
        })}
      >
        <Suspense fallback={null}>{children}</Suspense>
      </div>
      <ApplicationFooter />
    </div>
  )
}
