import {LOGIN_PAGE_TRANSLATION_KEYS_SHARED} from '@d1g1t/shared/wrappers/localization-settings/constants'

import {
  ACTIVITY_PAGE_TRANSLATION_KEYS,
  COMPONENT_SPECIFIC_TRANSLATION_KEYS,
  DATE_RANGES_TRANSLATION_KEYS,
  DMS_PAGE_TRANSLATION_KEYS,
  DOCUMENTS_PAGE_TRANSLATION_KEYS,
  ERROR_MESSAGES_TRANSLATION_KEYS,
  FOOTER_TRANSLATION_KEYS,
  HOME_PAGE_TRANSLATION_KEYS,
  INVESTMENTS_PAGE_TRANSLATION_KEYS,
  OTHER_TRANSLATION_KEYS,
  PRIVACY_DECLARATION_TRANSLATION_KEYS,
  SECTION_TITLE_TRANSLATION_KEYS,
  SETTINGS_PAGE_TRANSLATION_KEYS
} from '../constants'

const OTHER_TRANSLATION_KEYS_FR: Record<OTHER_TRANSLATION_KEYS, string> = {
  [OTHER_TRANSLATION_KEYS.CHANGED_DATE_FORMAT]: 'Format de date changé',
  [OTHER_TRANSLATION_KEYS.CHANGED_LANGUAGE_TO]: 'Langue changée à',
  [OTHER_TRANSLATION_KEYS.CONSOLIDATED_PORTFOLIO]: 'Portefeuille consolidé',
  [OTHER_TRANSLATION_KEYS.D1G1T_INC]: 'd1g1t Inc.', //
  [OTHER_TRANSLATION_KEYS.ENGLISH]: 'English',
  [OTHER_TRANSLATION_KEYS.FRENCH]: 'Français',
  [OTHER_TRANSLATION_KEYS.GOOD_AFTERNOON]: 'Bonjour',
  [OTHER_TRANSLATION_KEYS.GOOD_EVENING]: 'Bonsoir',
  [OTHER_TRANSLATION_KEYS.GOOD_MORNING]: 'Bonjour',
  [OTHER_TRANSLATION_KEYS.LOG_OUT]: 'Déconnexion',
  [OTHER_TRANSLATION_KEYS.POWERED_BY]: 'alimenté par',
  [OTHER_TRANSLATION_KEYS.PRIVACY_POLICY]: 'Politique de confidentialité',
  [OTHER_TRANSLATION_KEYS.SET_ALL_COMPONENETS_TO_MATCH_DATE_RANGE]:
    "Définir tous les composants pour qu'ils correspondent à la plage de dates",
  [OTHER_TRANSLATION_KEYS.TERMS_AND_CONDITIONS]: 'Modalités et conditions',
  [OTHER_TRANSLATION_KEYS.TERMS_OF_USE]: "Conditions d'utilisation"
}

const COMPONENT_SPECIFIC_TRANSLATION_KEYS_FR: Record<
  COMPONENT_SPECIFIC_TRANSLATION_KEYS,
  string
> = {
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.ACCOUNT_DATA_DATA_EMPTY]:
    'Aucune donnée de compte',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.ALLOCATION_DATA_DATA_EMPTY]:
    'Aucune donnée de répartition',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.AS_OF]: 'Au',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.AS]: 'en tant que',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.BACK]: 'Retour',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.BEGINNING_VALUE_AND_NET_ADDITIONS]:
    'Valeur de début et ajouts nets',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CANCEL]: 'Annuler',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CLEAR_ALL]: 'Aucun',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CLEAR]: 'Réinitialiser',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CLOSE]: 'Fermer', //
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CONSOLIDATED_PORTFOLIO_DATA_EMPTY]:
    'Aucune donnée de portefeuille consolidé',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CURRENCY]: 'Devise',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CURRENTLY_EMULATING]: 'Émulation de',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.FILTER_RULE]: 'Filtre',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.FILTER_RULES]: 'Filtres',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.FROM]: 'De',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.LOADING]: 'Chargement...',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.MARKET_VALUE]: 'Valeur marchande',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.NET_ADDITIONS]: 'Ajouts nets',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.NO_RESULTS]: 'Aucun résultat.',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.NO_STATS_DATA]:
    'Aucune donnée statistique',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.NO_TRANSACTIONS_AVAILABLE]:
    'Aucune transaction disponible',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.NOT_FOUND_PAGE]: 'Page non trouvée',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.PERIOD]: 'Période',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.QUICK_ACCESS]: 'Accès rapide',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.REMOVE_ALL_RULE_FILTERS]:
    'Supprimer tous les filtres',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.REMOVE_FILTER]: 'Supprimer le filtre',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.RESET_ZOOM]: 'Réinitialiser le zoom',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SECURITY_DOES_NOT_HAVE_MARKET_PRICE]:
    "Ce titre n'a pas d'historique des prix du marché",
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SELECT_ALL]: 'Tous',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SHOW_AS_GRAPH]:
    'Afficher sous forme de graphique',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SHOW_AS_TABLE]:
    'Afficher sous forme de tableau',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SHOW_LESS]: 'Afficher moins',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SHOW_MORE]: 'Afficher plus',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SYNC_TO_SELECTED_PERIOD]:
    'Synchroniser avec la période sélectionnée',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.TIME_PERIOD]: 'Période',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.TO]: 'à',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.TODAY]: "Aujourd'hui",
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.TOTAL_GAIN]: 'Gain total',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.TREND_ANALYSIS_DATA_EMPTY]:
    "Aucune donnée pour l'analyse des tendances",
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.BLANK]: '<Vide>',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.VALUE_AND_ADDITIONS]: 'Valeur et ajouts',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.VALUES]: 'Valeurs',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SUBMIT]: 'Soumettre',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SAVE]: 'Sauvegarder',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SUBMITTING]: 'En cours de soumission',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.SAVING]: 'En cours de sauvegarde',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.DISCARD_CHANGES]:
    'Annuler les modifications',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.DO_YOU_WANT_TO_DISCARD_ALL_UNSAVED_CHANGES]:
    'Voulez-vous annuler toutes les modifications non sauvegardées?',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.YOU_HAVE_UNSAVED_CHANGES]:
    'Vous avez des modifications non sauvegardées',
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.CONFIRM_DISCARD_CHANGES]:
    "Confirmer l'annulation des modifications",
  [COMPONENT_SPECIFIC_TRANSLATION_KEYS.ARE_YOU_SURE_YOU_WANT_DISCARD_ALL_UNSAVED_CHANGES]:
    'Êtes-vous certain de vouloir annuler toutes les modifications? Cette action est finale.'
}

const ACTIVITY_PAGE_TRANSLATION_KEYS_FR: Record<
  ACTIVITY_PAGE_TRANSLATION_KEYS,
  string
> = {
  [ACTIVITY_PAGE_TRANSLATION_KEYS.ALL_TRANSACTIONS]: 'Toutes les transactions',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.BUY]: 'Acheté',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.DEPOSIT]: 'A contribué',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.FILTER_BY_TYPE]: 'Filtrer par type',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.OF]: 'de',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.SELL]: 'Vendu',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.TOGGLE_TAG_VALUES]:
    'Les filtres peuvent être modifiés en cliquant sur les <strong>types de transactions</strong> ci-dessous. Les <strong>types de transactions</strong> en gris seront cachés.',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.UNITS_AT]: 'unités à',
  [ACTIVITY_PAGE_TRANSLATION_KEYS.WITHDRAWL]: 'A retiré'
}

const DATE_RANGES_TRANSLATION_KEYS_FR: Record<
  DATE_RANGES_TRANSLATION_KEYS,
  string
> = {
  [DATE_RANGES_TRANSLATION_KEYS.CUSTOM_PERIOD_SELECTION]: 'Depuis',
  [DATE_RANGES_TRANSLATION_KEYS.CUSTOM]: 'Période personnalisée',
  [DATE_RANGES_TRANSLATION_KEYS.DAY]: '1 jour',
  [DATE_RANGES_TRANSLATION_KEYS.FIVE_YEARS]: '5 ans',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_ANNUALLY]: 'Annuel',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_MONTH]: 'Le mois dernier',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_MONTHLY]: 'Mensuel',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_QUARTER]: 'Les 3 dernier mois',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_QUARTERLY]: 'Trimestriel',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_SIX_MONTHS]: 'Les 6 dernier mois',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_THREE_YEARS]: '3 dernières années',
  [DATE_RANGES_TRANSLATION_KEYS.LAST_YEAR]: "L'année dernière",
  [DATE_RANGES_TRANSLATION_KEYS.MONTH]: '1 mois',
  [DATE_RANGES_TRANSLATION_KEYS.MTD]: 'Mois à ce jour',
  [DATE_RANGES_TRANSLATION_KEYS.QTD_ALT]: 'Trimestre à ce jour (TACJ)',
  [DATE_RANGES_TRANSLATION_KEYS.QTD]: 'Trimestre à ce jour',
  [DATE_RANGES_TRANSLATION_KEYS.QUARTER]: '3 mois',
  [DATE_RANGES_TRANSLATION_KEYS.SINCE_ACTIVATION]: "Depuis l'activation",
  [DATE_RANGES_TRANSLATION_KEYS.SINCE_INCEPTION_ALT]:
    'Depuis la création (CACJ)',
  [DATE_RANGES_TRANSLATION_KEYS.SINCE_INCEPTION]: 'Depuis la création',
  [DATE_RANGES_TRANSLATION_KEYS.SIX_MONTHS]: '6 mois',
  [DATE_RANGES_TRANSLATION_KEYS.THREE_YEARS]: '3 ans',
  [DATE_RANGES_TRANSLATION_KEYS.TWO_YEARS]: '2 ans',
  [DATE_RANGES_TRANSLATION_KEYS.WEEK]: '1 semaine',
  [DATE_RANGES_TRANSLATION_KEYS.YEAR]: '1 an',
  [DATE_RANGES_TRANSLATION_KEYS.YTD_ALT]: 'Année à ce jour (AACJ)',
  [DATE_RANGES_TRANSLATION_KEYS.YTD]: "L'année à ce jour"
}

const DMS_PAGE_TRANSLATION_KEYS_FR: Record<DMS_PAGE_TRANSLATION_KEYS, string> =
  {
    [DMS_PAGE_TRANSLATION_KEYS.ADD_DOCUMENT_LABEL]: 'Ajouter un document',
    [DMS_PAGE_TRANSLATION_KEYS.ADD]: 'Ajouter',
    [DMS_PAGE_TRANSLATION_KEYS.ADDING]: "En cours d'insertion…",
    [DMS_PAGE_TRANSLATION_KEYS.CANNOT_DOWNLOAD_MORE_THAN]:
      'Impossible de télécharger plus de',
    [DMS_PAGE_TRANSLATION_KEYS.COULD_NOT_FIND_DOWNLOAD_LINK_FOR]:
      'Impossible de trouver le lien de téléchargement pour',
    [DMS_PAGE_TRANSLATION_KEYS.DELETE]: 'Supprimer',
    [DMS_PAGE_TRANSLATION_KEYS.DELETED_SUCCESSFULLY]: 'Supprimé avec succès',
    [DMS_PAGE_TRANSLATION_KEYS.DOCUMENT_CATEGORY]: 'Catégorie de document',
    [DMS_PAGE_TRANSLATION_KEYS.DOCUMENTS_LABEL]: 'Documents',
    [DMS_PAGE_TRANSLATION_KEYS.DOWNLOAD]: 'Télécharger',
    [DMS_PAGE_TRANSLATION_KEYS.EDIT]: 'Modifier',
    [DMS_PAGE_TRANSLATION_KEYS.FILES_SIMULTANEOUSLY]: 'fichiers simultanément',
    [DMS_PAGE_TRANSLATION_KEYS.FILTER]: 'Filtrer',
    [DMS_PAGE_TRANSLATION_KEYS.LAST_MODIFIED]: 'Dernière modification',
    [DMS_PAGE_TRANSLATION_KEYS.MARK_ALL_AS_READ]: 'Tout marquer comme lu',
    [DMS_PAGE_TRANSLATION_KEYS.MARK_ALL_AS_UNREAD]: 'Tout marquer comme non lu',
    [DMS_PAGE_TRANSLATION_KEYS.MARK_AS_READ]: 'Marquer comme lu',
    [DMS_PAGE_TRANSLATION_KEYS.MARK_AS_UNREAD]: 'Marquer comme non lu',
    [DMS_PAGE_TRANSLATION_KEYS.MESSAGE]: 'Message (facultatif)',
    [DMS_PAGE_TRANSLATION_KEYS.NAME]: 'Nom',
    [DMS_PAGE_TRANSLATION_KEYS.NO_DOCUMENTS_FOUND]: 'Aucun document trouvé',
    [DMS_PAGE_TRANSLATION_KEYS.NO_RESULTS]:
      'Pas de résultats. Essayez d`appliquer un autre filtre de recherche ou d`état.',
    [DMS_PAGE_TRANSLATION_KEYS.NO_SUBCATEGORIES_AVAILABLE]:
      'Il n`y a pas de sous-catégories disponibles.',
    [DMS_PAGE_TRANSLATION_KEYS.NOTIFY]: 'Notifier',
    [DMS_PAGE_TRANSLATION_KEYS.TOGGLE_SUBCATEGORY_VALUES]:
      'Les filtres peuvent être modifiés en cliquant sur les <strong>sous-catégories</strong> ci-dessous. Les <strong>sous-catégories</strong> en gris seront cachées.',
    [DMS_PAGE_TRANSLATION_KEYS.UNEXPECTED_ERROR]:
      "Une erreur inattendue s'est produite",
    [DMS_PAGE_TRANSLATION_KEYS.UNREAD_ITEM]: 'élément non lu',
    [DMS_PAGE_TRANSLATION_KEYS.UNREAD_ITEMS]: 'éléments non lus',
    [DMS_PAGE_TRANSLATION_KEYS.UPLOAD_DOCUMENT_DESCRIPTION]:
      "Ajoutez un document ci-dessous ou sélectionnez l'une des catégories pour consulter vos documents.",
    [DMS_PAGE_TRANSLATION_KEYS.ALL_DOCUMENTS]: 'Tous les documents',
    [DMS_PAGE_TRANSLATION_KEYS.DOWNLOADED_SUCCESSFULLY]:
      'Téléchargé avec succès',
    [DMS_PAGE_TRANSLATION_KEYS.SEARCH_DOCUMENTS]: 'Chercher les documents'
  }

const DOCUMENTS_PAGE_TRANSLATION_KEYS_FR: Record<
  DOCUMENTS_PAGE_TRANSLATION_KEYS,
  string
> = {
  [DOCUMENTS_PAGE_TRANSLATION_KEYS.FILTER_BY_CATEGORY]: 'Filtrer par catégorie',
  [DOCUMENTS_PAGE_TRANSLATION_KEYS.NO_CATEGORIES_AVAILABLE]:
    "Il n'y a pas de catégories disponibles.",
  [DOCUMENTS_PAGE_TRANSLATION_KEYS.NO_DOCUMENT_FOR_ACCOUNT]:
    "Aucun document n'est associé à ce compte.",
  [DOCUMENTS_PAGE_TRANSLATION_KEYS.NO_DOCUMENT_FOR_CATEGORY]:
    "Aucun document n'est associé à cette catégorie.",
  [DOCUMENTS_PAGE_TRANSLATION_KEYS.TOGGLE_CATEGORY_VALUES]:
    'Sélectionnez les catégories à afficher en cliquant sur les libellés ci-dessous. Les catégories en gris seront cachées.'
}

const ERROR_MESSAGES_TRANSLATION_KEYS_FR: Record<
  ERROR_MESSAGES_TRANSLATION_KEYS,
  string
> = {
  [ERROR_MESSAGES_TRANSLATION_KEYS.BAD_GATEWAY]:
    "Le serveur de d1g1t n'a pu être contacté. Veuillez réessayer plus tard.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.ENTITY_NOT_FOUND]:
    "L'entité demandée n'a pu être trouvée. Vous pourriez ne pas y avoir accès.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.FORBIDDEN]:
    "Le contenu demandé n'a pu être trouvé.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.INTERNAL_SERVER_ERROR]:
    "Une erreur de serveur s'est produite. Veuillez réessayer plus tard.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.MAINTENANCE]:
    "Les calculs sont présentement arrêtés pour des raisons d'entretient. Veuillez réessayer plus tard.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.NETWORK_ERROR]:
    'Il semble y avoir un problème avec votre connexion internet.',
  [ERROR_MESSAGES_TRANSLATION_KEYS.NOT_FOUND]:
    "La ressource demandée n'a pu être trouvée.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.SERVICE_UNAVAILABLE]:
    "Le serveur n'est pas disponible. Veuillez réessayer plus tard.",
  [ERROR_MESSAGES_TRANSLATION_KEYS.UNEXPECTED_ERROR]:
    "Une erreur inattendue s'est produite. Veuillez réessayer plus tard."
}

const FOOTER_TRANSLATION_KEYS_FR: Record<FOOTER_TRANSLATION_KEYS, string> = {
  [FOOTER_TRANSLATION_KEYS.EMAIL]: 'Courriel',
  [FOOTER_TRANSLATION_KEYS.FAX]: 'Télécopie',
  [FOOTER_TRANSLATION_KEYS.GET_IN_TOUCH]: 'Contactez-nous',
  [FOOTER_TRANSLATION_KEYS.LEARN_MORE]: 'En savoir plus',
  [FOOTER_TRANSLATION_KEYS.LEGAL]: 'Légal',
  [FOOTER_TRANSLATION_KEYS.MAIN]: 'Principal',
  [FOOTER_TRANSLATION_KEYS.OUR_OFFICES]: 'Nos bureaux',
  [FOOTER_TRANSLATION_KEYS.RESOURCES]: 'Ressources'
}

const HOME_PAGE_TRANSLATION_KEYS_FR: Record<
  HOME_PAGE_TRANSLATION_KEYS,
  string
> = {
  [HOME_PAGE_TRANSLATION_KEYS.ACTIVITY_SUBTITLE]:
    'Accédez à vos transactions telles que les achats, les ventes, les revenus, les transferts ou les frais.',
  [HOME_PAGE_TRANSLATION_KEYS.CONSOLIDATED_PORTFOLIO_VALUE]:
    'Valeur du portefeuille consolidé',
  [HOME_PAGE_TRANSLATION_KEYS.DEPOSITS_AND_WITHDRAWALS_SUBTITLE]:
    'Suivez les flux de trésorerie entrant et sortant de vos comptes.',
  [HOME_PAGE_TRANSLATION_KEYS.DEPOSITS_AND_WITHDRAWALS]: 'Dépôts et retraits',
  [HOME_PAGE_TRANSLATION_KEYS.DOCUMENTS_SUBTITLE]:
    'Récupérez les documents partagés par votre conseiller et téléversez de nouveaux documents.',
  [HOME_PAGE_TRANSLATION_KEYS.INCOME_GENERATED_SUBTITLE]:
    'Suivez les dividendes, intérêts et autres revenus générés par vos placements.',
  [HOME_PAGE_TRANSLATION_KEYS.INCOME_GENERATED]: 'Revenus générés',
  [HOME_PAGE_TRANSLATION_KEYS.INVESTMENTS_SUBTITLE]:
    'Analysez vos positions, votre répartition et  votre rendement à travers le temps.',
  [HOME_PAGE_TRANSLATION_KEYS.SETTINGS_SUBTITLE]:
    'Consultez votre profil et vos coordonnées, et mettez à jour vos préférences.',
  [HOME_PAGE_TRANSLATION_KEYS.TOTAL_PORTFOLIO]: 'PORTEFEUILLE TOTAL',
  [HOME_PAGE_TRANSLATION_KEYS.WHAT_CAN_WE_HELP_YOU_WITH_TODAY]:
    "Comment pouvons-nous vous aider aujourd'hui?",
  [HOME_PAGE_TRANSLATION_KEYS.YOUR_ADVISORY_TEAM]: 'Votre équipe de conseillers'
}

const LOGIN_PAGE_TRANSLATION_KEYS_SHARED_FR: Record<
  LOGIN_PAGE_TRANSLATION_KEYS_SHARED,
  string
> = {
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AN_UNEXPECTED_ERROR_ON_RESET_PASSWORD]:
    "Une erreur inattendue s'est produite lors de la demande de réinitialisation de votre mot de passe.",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AN_UNEXPECTED_WHILE_CREATING_THE_PASSWORD]:
    "Une erreur inattendue s'est produite lors de la création du mot de passe.",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AUTHENTICATION_CODE_IS_REQUIRED]:
    "Un code d'authentification est requis",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AUTHENTICATION_DESTINATION_IS_REQUIRED]:
    "Une méthode d'envoi est requise",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.BACK_TO_SIGN_IN]: 'Retour',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CHECK_YOUR_EMAIL_FOR_INSTRUCTIONS]:
    'Consultez vos courriels pour savoir comment réinitialiser votre mot de passe.',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CREATE_A_NEW_PASSWORD]:
    'Créez un nouveau mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CREATE_A_PASSWORD]:
    'Créez un mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CREDENTIALS_ERROR]:
    'Impossible de se connecter avec les informations de connexion fournies.',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.D1G1T_ONLY_TOOLTIP]:
    'd1g1t uniquement: ce lien est masqué dans les env non d1g1t. Accédez manuellement à /login/all pour y accéder.',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS_IS_REQUIRED]:
    "L'adresse courriel est requise",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS_MUST_BE_A_VALID_EMAIL]:
    "L'adresse courriel doit être un courriel valide",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS]: 'Adresse courriel',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.ENTER_THE_AUTHENTICATION_CODE_TOP]:
    "Un message texte contenant un code d'authentification a été envoyé à votre numéro de téléphone mobile.",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.ENTER_THE_AUTHENTICATION_CODE_BOTTOM]:
    'Veuillez entrer le code.',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.FORGOT_PASSWORD]: 'Mot de passe oublié ?',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.GO_TO_NORMAL_LOGIN_PAGE]:
    'Accédez à la page de connexion normale.',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.INTERNAL]: 'Interne',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.INVALID_VERIFICATION_CODE]:
    "Vous avez entré un code d'authentification erroné. Un nouveau code d'authentification a été envoyé à votre téléphone.",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.LOG_IN]: 'Se connecter',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MFA_CODE]: "Code d'authentification",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MOBILE_PHONE_NUMBER]:
    'Numéro de téléphone cellulaire',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MUST_BE_A_VALID_PHONE_NUMBER]:
    'Doit être un numéro de téléphone valide',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_IS_A_REQUIRED_FIELD]:
    "'Nouveau mot de passe' est un champ obligatoire",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS]:
    'Le nouveau mot de passe doit comporter au moins 16 caractères',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS]:
    'Le nouveau mot de passe doit comporter au moins 8 caractères',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD]: 'Nouveau mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MOBILE_PHONE_NUMBER_COUNTRY_CODE]:
    "Commencez par l'indicatif de pays: par exemple +1 pour le Canada ou +33 pour la France",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.TWILIO_MESSAGE]:
    "En fournissant votre numéro de téléphone, vous acceptez de recevoir messages dans le cadre du service d'authentification multifacteur.",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD_IS_REQUIRED]:
    'Le mot de passe est requis',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS]:
    'Le mot de passe doit comporter au moins 16 caractères',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS]:
    'Le mot de passe doit comporter au moins 8 caractères',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD]: 'Mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORDS_DO_NOT_MATCH]:
    'Les mots de passe ne correspondent pas',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PHONE_NUMBER]: 'Numéro de téléphone',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PROVIDE_DESTINATION_MESSAGE]:
    "Choisissez la façon dont vous souhaitez recevoir votre code d'authentification",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RE_TYPE_PASSWORD_IS_A_REQUIRED_FIELD]:
    "'Entrez à nouveau le nouveau mot de passe' est un champ obligatoire",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RE_TYPE_PASSWORD]:
    'Entrez à nouveau le nouveau mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RE_TYPE_YOUR_PASSWORD]:
    'Entrez à nouveau le nouveau mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RESET_PASSWORD]:
    'Réinitialiser le mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.SET_PASSWORD]:
    'Sauvegarder le mot de passe',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.SHOW_HIDDEN_AUTH_METHODS]:
    "Afficher les méthodes d'authentification cachées",
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.SHOWING_ALL_LOGIN_METHODS]:
    'Affichage de toutes les méthodes de connexion. Ignorez pour accéder à la page de connexion normale.',
  [LOGIN_PAGE_TRANSLATION_KEYS_SHARED.YOURE_ALL_SET]: "C'est tout!"
}

const SECTION_TITLE_TRANSLATION_KEYS_FR: Record<
  SECTION_TITLE_TRANSLATION_KEYS,
  string
> = {
  [SECTION_TITLE_TRANSLATION_KEYS.ACTIVITY]: 'Activité',
  // @ts-ignore
  [SECTION_TITLE_TRANSLATION_KEYS.DOCUMENTS]: 'Documents',
  [SECTION_TITLE_TRANSLATION_KEYS.HOME]: 'Accueil',
  [SECTION_TITLE_TRANSLATION_KEYS.INVESTMENTS]: 'Portefeuille',
  [SECTION_TITLE_TRANSLATION_KEYS.OTHER]: 'Autre',
  [SECTION_TITLE_TRANSLATION_KEYS.PRIVACY]: 'Confidentialité',
  [SECTION_TITLE_TRANSLATION_KEYS.SETTINGS]: 'Réglages',
  [SECTION_TITLE_TRANSLATION_KEYS.REPORTS]: 'Rapports'
}

const SETTINGS_PAGE_TRANSLATION_KEYS_FR: Record<
  SETTINGS_PAGE_TRANSLATION_KEYS,
  string
> = {
  [SETTINGS_PAGE_TRANSLATION_KEYS.ADDRESS_LINE_1]: 'Adresse 1',
  [SETTINGS_PAGE_TRANSLATION_KEYS.ADDRESS_LINE_2]: 'Adresse 2',
  [SETTINGS_PAGE_TRANSLATION_KEYS.ADDRESS]: 'Adresse',
  [SETTINGS_PAGE_TRANSLATION_KEYS.ADDRESSES]: 'Adresses',
  [SETTINGS_PAGE_TRANSLATION_KEYS.CITY]: 'Ville',
  [SETTINGS_PAGE_TRANSLATION_KEYS.CONTACT_INFORMATION]: 'Vos coordonnées',
  [SETTINGS_PAGE_TRANSLATION_KEYS.COUNTRY]: 'Pays',
  [SETTINGS_PAGE_TRANSLATION_KEYS.DATE_FORMAT]: 'Format de date',
  [SETTINGS_PAGE_TRANSLATION_KEYS.DATE_OF_BIRTH]: 'Date de naissance',
  [SETTINGS_PAGE_TRANSLATION_KEYS.DD_MM_YYYY]: 'jj-mm-aaaa',
  [SETTINGS_PAGE_TRANSLATION_KEYS.EMAIL_TYPE]: 'Type de courriel',
  [SETTINGS_PAGE_TRANSLATION_KEYS.EMAIL]: 'Courriel',
  [SETTINGS_PAGE_TRANSLATION_KEYS.EMAILS]: 'Courriels',
  [SETTINGS_PAGE_TRANSLATION_KEYS.FIRST_NAME]: 'Prénom',
  [SETTINGS_PAGE_TRANSLATION_KEYS.GENDER]: 'Genre',
  [SETTINGS_PAGE_TRANSLATION_KEYS.INCORRECT_CONFIGURATION_ERROR_MESSAGE]:
    'Configuration incorrecte: aucun contact trouvé.',
  [SETTINGS_PAGE_TRANSLATION_KEYS.LANGUAGE]: 'Langue',
  [SETTINGS_PAGE_TRANSLATION_KEYS.LAST_NAME]: 'Nom de famille',
  [SETTINGS_PAGE_TRANSLATION_KEYS.MIDDLE_NAMES]: 'Deuxième prénom',
  [SETTINGS_PAGE_TRANSLATION_KEYS.MM_DD_YYYY]: 'mm-jj-aaaa',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PHONE_NUMBER]: 'Numéro de téléphone',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PHONE_TYPE]: 'Type de téléphone',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PHONES]: 'Téléphones',
  [SETTINGS_PAGE_TRANSLATION_KEYS.POSTAL_CODE]: 'Code postal',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PREFERENCES]: 'Préférences',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PRIMARY]: 'Principal',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PROFILE_INFORMATION]: 'Votre profil',
  [SETTINGS_PAGE_TRANSLATION_KEYS.PROVINCE]: 'Province',
  [SETTINGS_PAGE_TRANSLATION_KEYS.STATE]: 'État',
  [SETTINGS_PAGE_TRANSLATION_KEYS.TITLE]: 'Titre',
  [SETTINGS_PAGE_TRANSLATION_KEYS.USER_ID]: "Identifiant d'utilisateur",
  [SETTINGS_PAGE_TRANSLATION_KEYS.YYYY_MM_DD]: 'aaaa-mm-jj',
  [SETTINGS_PAGE_TRANSLATION_KEYS.ZIP_CODE]: 'Code postal'
}

const INVESTMENTS_PAGE_TRANSLATION_KEYS_FR: Record<
  INVESTMENTS_PAGE_TRANSLATION_KEYS,
  string
> = {
  [INVESTMENTS_PAGE_TRANSLATION_KEYS.ALLOCATION_BY_ACCOUNT]:
    'Répartition par compte',
  [INVESTMENTS_PAGE_TRANSLATION_KEYS.ALLOCATION_BY_ASSET_CLASS]:
    "Répartition par classe d'actifs",
  [INVESTMENTS_PAGE_TRANSLATION_KEYS.ALLOCATION_BY_SECURITY]:
    'Répartition par titre',
  [INVESTMENTS_PAGE_TRANSLATION_KEYS.MARKET_VALUE]: 'Valeur marchande',
  [INVESTMENTS_PAGE_TRANSLATION_KEYS.TOTAL_RETURN]: 'Rendement total'
}

const PRIVACY_DECLARATION_TRANSLATION_KEYS_FR: Record<
  PRIVACY_DECLARATION_TRANSLATION_KEYS,
  string
> = {
  [PRIVACY_DECLARATION_TRANSLATION_KEYS.PRIVACY_DECLARATION_UPDATED_SUCCESSFULLY]:
    'Réglages de confidentialité sauvegardés avec succès',
  [PRIVACY_DECLARATION_TRANSLATION_KEYS.AN_UNEXPECTED_ERROR_OCCURRED]:
    "Une erreur inattendue s'est produite lors de la sauvegarde des réglages de confidentialité"
}

export default {
  ...ACTIVITY_PAGE_TRANSLATION_KEYS_FR,
  ...COMPONENT_SPECIFIC_TRANSLATION_KEYS_FR,
  ...DATE_RANGES_TRANSLATION_KEYS_FR,
  ...DMS_PAGE_TRANSLATION_KEYS_FR,
  ...DOCUMENTS_PAGE_TRANSLATION_KEYS_FR,
  ...ERROR_MESSAGES_TRANSLATION_KEYS_FR,
  ...FOOTER_TRANSLATION_KEYS_FR,
  ...HOME_PAGE_TRANSLATION_KEYS_FR,
  ...INVESTMENTS_PAGE_TRANSLATION_KEYS_FR,
  ...LOGIN_PAGE_TRANSLATION_KEYS_SHARED_FR,
  ...OTHER_TRANSLATION_KEYS_FR,
  ...PRIVACY_DECLARATION_TRANSLATION_KEYS_FR,
  ...SECTION_TITLE_TRANSLATION_KEYS_FR,
  ...SETTINGS_PAGE_TRANSLATION_KEYS_FR
}
