import {IRiskReturn} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class RiskReturnsEndpoints extends BaseRestEndpoints {
  static basePath = '/risk-returns'

  static list() {
    return super._list<IApiListResponse<IRiskReturn>>()
  }
}
