import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  Reducer,
  ReducersMapObject,
  Store
} from 'redux'
import createSagaMiddleware, {Saga, Task} from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

interface IAsyncStore extends Store<any> {
  asyncReducers?: ReducersMapObject
  asyncSagas?: Dictionary<Task>
  runSaga?: typeof sagaMiddleware.run
}

let store: IAsyncStore

const asyncReducerInjectQueue: ReducersMapObject = {}
const asyncSagaInjectQueue: Dictionary<Saga> = {}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

export function createAsyncInjectStore(
  initialReducers: ReducersMapObject,
  initialState: Dictionary,
  middlewares: Middleware[]
) {
  const reducerMap = {...initialReducers, ...asyncReducerInjectQueue}
  const reducer = combineReducers(reducerMap)

  store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, ...middlewares))
  )

  store.asyncReducers = reducerMap
  store.runSaga = sagaMiddleware.run
  store.asyncSagas = {}

  for (const key in asyncSagaInjectQueue) {
    store.asyncSagas[key] = store.runSaga(asyncSagaInjectQueue[key])
  }

  return store
}

export function injectAsyncReducer(name: string, asyncReducer: Reducer<any>) {
  if (__TEST__) {
    return
  }

  if (!store) {
    queueAsyncReducerForInjection(name, asyncReducer)

    return
  }

  if (store.asyncReducers[name]) {
    console.warn(`Reducer with name ${name} has already been injected`)
  }

  store.asyncReducers[name] = asyncReducer

  const nextReducer = combineReducers(store.asyncReducers)
  store.replaceReducer(nextReducer)
}

function queueAsyncReducerForInjection(
  name: string,
  asyncReducer: Reducer<any>
) {
  if (asyncReducerInjectQueue[name]) {
    throw new Error(
      `Reducer with name ${name} has already been queued for injection`
    )
  }

  asyncReducerInjectQueue[name] = asyncReducer
}

export function injectAsyncSaga(name: string, saga: Saga) {
  if (__TEST__) {
    return
  }

  if (!store) {
    queueAsyncSagaForInjections(name, saga)

    return
  }

  if (store.asyncSagas[name]) {
    throw new Error(`Saga with name ${name} already running`)
  }

  store.asyncSagas[name] = store.runSaga(saga)
}

function queueAsyncSagaForInjections(name: string, saga: Saga) {
  if (asyncSagaInjectQueue[name]) {
    throw new Error(
      `Saga with name ${name} has already been queued for injection`
    )
  }

  asyncSagaInjectQueue[name] = saga
}
