import {IButtonGroupSettings} from '@d1g1t/shared/components/mui/button-group'

import {COMPONENT_SPECIFIC_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

export enum DOCUMENTS_KNOWN_CATEGORY_ID {
  DATE_CREATED_CATEGORY_ID = 'created',
  NAME_CATEGORY_ID = 'name',
  CATEGORY_ID = 'category',
  SUBCATEGORY_ID = 'subcategory',
  ATTACHED_ID = 'attached_to_verbose_name',
  APPROVED_ID = 'approved',
  PUBLISHED_ID = 'is_published',
  DOWNLOAD_URL_ID = 'document_download_url',
  ADVISOR_NAME_ID = 'advisor_name',
  PUBLISHED_FOR_ID = 'published_for',
  WAS_READ_ID = 'was_read',
  S3_FILE_URL = 's3_file_url'
}
export const COMPONENT_SAVE_ID = 'MANAGE-DMS-DOCUMENTS'
export const BUTTON_SAVE_ID = 'MANAGE-DMS-DOCUMENTS-INVESTOR-MARK-READ'
/**
 * Used for Mark as Read/Unread Toggle
 */
export const BUTTON_GROUP_SETTINGS: IButtonGroupSettings = {
  id: 'mark-all-read'
}
/*
 * Used for max-height of the table
 */
export const TABLE_HEIGHT_OFFSET_PX = 260
/*
 * Used as a limit for downloading selected items.
 */
export const MAX_DOWNLOAD_LIMIT = 20

/*
 * DMS page query params names
 */
export const DOCUMENT_FILE_CATEGORY_ID_IN = 'document_file__category_id__in'
export const DOCUMENT_FILE_SUBCATEGORY_ID_IN =
  'document_file__subcategory_id__in'
export const DOCUMENT_FILE_SUBCATEGORY_BLANK =
  'document_file__subcategory_blank'

/**
 * Option to get documents with empty subcategory
 */
export const BLANK_SUBCATEGORY_QUERY_PARAM_OPTION = {
  value: DOCUMENT_FILE_SUBCATEGORY_BLANK,
  label: COMPONENT_SPECIFIC_TRANSLATION_KEYS.BLANK
}
