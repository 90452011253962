import React from 'react'

import {Flex} from '@d1g1t/shared/components/flex'
import {D1G1TLogo} from '@d1g1t/shared/components/logo'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {H2, Text} from '@d1g1t/shared/components/typography'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {OTHER_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

import {GetInTouch} from './components/get-in-touch'
import {Logo} from './components/logo'
import {OurOffices} from './components/our-offices'
import {Resources} from './components/resources'

import * as css from './style.scss'

/**
 * Investor App Footer
 * 'Privacy policy' and 'Terms of use' links are optional and configurable by firm
 * Phone number 1 and 2, and disclaimer text are also configurable by firm
 * Custom links and Address can be infinitely many
 */
export const ApplicationFooter: React.FC = () => {
  const {firmConfiguration} = useFirmConfiguration()
  const {data} = firmConfiguration
  const year = new Date().getFullYear()

  return (
    <div className={css.footerContainer}>
      <div className={css.footerBackground}>
        <div className={css.footerContentContainer}>
          <Flex wrap justifySpaceBetween className={css.footerContent}>
            <Logo
              logo={data?.subfirm?.logo ?? data?.logo}
              bannerMessage={data?.bannerMessage}
            />
            <Flex wrap grow={0.66} className={css.footerInfo}>
              <Resources
                customLinks={data?.customLinks}
                privacyPolicy={data?.privacyPolicy}
                privacyPolicyLabel={data?.privacyPolicyLabel}
                termsOfUse={data?.termsOfUse}
                termsOfUseLabel={data?.termsOfUseLabel}
              />
              <GetInTouch
                phone1={data?.phone1}
                phone2={data?.phone2}
                phone3={data?.phone3}
                email={data?.email}
              />
              <OurOffices officeAddresses={data?.officeAddresses} />
            </Flex>
          </Flex>
          {firmConfiguration.data?.footerNote && (
            <Flex alignCenter className={css.footerNoteContainer}>
              <div>
                <Text data-testid='footer-note'>
                  &copy; {year} {firmConfiguration.data.displayName}
                </Text>
                <Text>{firmConfiguration.data.footerNote}</Text>
              </div>
            </Flex>
          )}
          <Divider style={{marginTop: '1rem'}} />
          <Flex justifySpaceBetween alignCenter className={css.footerD1g1t}>
            <Text className={css.poweredD1g1t}>
              &copy; {year} {OTHER_TRANSLATION_KEYS.D1G1T_INC}
            </Text>
            <Flex alignCenter className={css.footContentContainer}>
              <H2 deemphasize normalWeight className={css.poweredD1g1t}>
                {OTHER_TRANSLATION_KEYS.POWERED_BY}
              </H2>
              <D1G1TLogo />
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  )
}
