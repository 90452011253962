import {isBrowser} from '@d1g1t/lib/is-browser'
import {RouteBuilder} from '@d1g1t/lib/url'

export class AuthenticationLocations extends RouteBuilder {
  static basePath = '/login'

  static list() {
    return super.path('/list')
  }

  static listAll() {
    return super.path('/all')
  }

  static password() {
    return super.path('/password')
  }

  static token() {
    return super.path('/token')
  }

  static signin() {
    return '/signin'
  }

  static forgotPassword() {
    return super.path('/forgot-password')
  }

  static resetPassword() {
    return '/reset-password'
  }

  static logout() {
    return '/logout'
  }
}

export const loginPath = (redirectBack = false) => {
  if (isBrowser && redirectBack) {
    const currentUrl = (() => {
      if (window.location.hash?.length > window.location.pathname?.length) {
        return window.location.hash.replace('#', '') + window.location.search
      }

      return window.location.pathname + window.location.search
    })()

    if (window.location.pathname === AuthenticationLocations.basePath) {
      return currentUrl
    }

    return `${AuthenticationLocations.basePath}?redirectTo=${encodeURIComponent(
      currentUrl
    )}`
  }

  return AuthenticationLocations.basePath
}

export const forgotPasswordPath = () => `${loginPath()}/forgot-password`
