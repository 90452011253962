import {combineReducers} from 'redux'

import {ActionType, getType} from 'typesafe-actions'

import {injectAsyncReducer} from '@d1g1t/lib/redux-inject-async'

import {userActivityActions} from './actions'
import {PREFIX} from './constants'
import {IUserActivityDomain} from './typings'

export const userActivityReducer = combineReducers<
  IUserActivityDomain,
  ActionType<typeof userActivityActions>
>({
  active: (prev = null, action) => {
    switch (action.type) {
      case getType(userActivityActions.active):
        return true
      case getType(userActivityActions.idle):
        return false
      default:
        return prev
    }
  }
})

injectAsyncReducer(PREFIX, userActivityReducer)
