import React, {useCallback, useMemo} from 'react'

import produce from 'immer'
import {omit} from 'lodash'
import * as Yup from 'yup'

import {useUrlQueryParams} from '../url-query-params'
import {ISettings} from './typings'

export type CalculationSettingsOverrideValue = [
  Partial<ISettings>,
  {
    updateCalculationSettingsOverride(settings: Partial<ISettings>): void
  }
]

export const CalculationSettingsOverrideContext =
  React.createContext<CalculationSettingsOverrideValue>([
    null,
    {
      updateCalculationSettingsOverride: () => {}
    }
  ])

export interface ICalculationSettingsOverrideProviderProps {
  /**
   * Passing this value will statically override the value held in the state
   * for override value context
   *
   * When a value is present, updating the context state will save its value,
   * but the value will not be passed down the context consumers
   */
  staticValue?: Partial<ISettings>
  useDefaultReportingCurrencyForSettings?: boolean
}

export const I_SELECTED_ENTITY_SCHEMA = Yup.object({
  entityId: Yup.string(),
  ruleFilters: Yup.array().of(Yup.string()),
  /**
   * When the rule filters were touched by the user, we need to set this to
   * true so that default filters do not override the user's settings.
   */
  touchedRuleFilters: Yup.boolean(),
  accounts: Yup.array().of(Yup.string()),
  positions: Yup.array().of(Yup.string())
})

/**
 * Potential code duplication: technically this duplicates some of
 * `SelectEntities` logic (`selected` and `focused` specifically).
 * However, that is necessary in this case to ensure /shared does not depend
 * on /advisor; while still retaining the benefits of serialized and
 * cast URL query params provided by `useUrlQueryParams`.
 *
 * A neater solution could be to re-work `useUrlQueryParams` to selectively
 * update only the requested query params, but right now, we expect it to
 * fully control the query params.
 */
const VALIDATION_SCHEMA_CALCULATION_SETTINGS_OVERRIDE = Yup.object<{
  currency?: string
  date?: string
}>({
  currency: Yup.string(),
  date: Yup.string(),
  filterByType: Yup.array().of(Yup.string()),
  focused: I_SELECTED_ENTITY_SCHEMA,
  selected: Yup.array().of(I_SELECTED_ENTITY_SCHEMA)
})

/**
 * Provider must be placed in the tree where settings overrides are available
 *
 * Here we use `Partial<ISettings>`, because they are only overrides for the stored global
 * settings, `useCalculationSettings` will check if this value is null and
 * merge it into the settings provided to calculations
 */
export const CalculationSettingsOverrideProvider: React.FC<
  ICalculationSettingsOverrideProviderProps
> = ({staticValue, children}) => {
  const [urlSettings, {replaceQueryParams}] = useUrlQueryParams({
    schema: VALIDATION_SCHEMA_CALCULATION_SETTINGS_OVERRIDE
  })

  const updateCalculationSettingsOverride = useCallback(
    (nextSettings: Partial<ISettings>) => {
      replaceQueryParams(
        produce(urlSettings, (draft) => {
          if (nextSettings.date?.date) {
            draft.date = nextSettings.date?.date
          }
          if (nextSettings.currency) {
            draft.currency = nextSettings.currency
          }
        })
      )
    },
    [urlSettings, replaceQueryParams]
  )

  const providerValue: CalculationSettingsOverrideValue = useMemo(() => {
    return [
      {
        ...omit(urlSettings, 'date'),
        date: {
          date: urlSettings.date,
          value: 'specificDate'
        },
        ...staticValue
      },
      {updateCalculationSettingsOverride}
    ]
  }, [urlSettings, staticValue, updateCalculationSettingsOverride])

  return (
    <CalculationSettingsOverrideContext.Provider value={providerValue}>
      {children}
    </CalculationSettingsOverrideContext.Provider>
  )
}
