import React, {useEffect} from 'react'

import qs from 'qs'

import {Card} from '@d1g1t/shared/components/card'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2, P} from '@d1g1t/shared/components/typography'
import {useAuth} from '@d1g1t/shared/wrappers/auth'

export const TokenForm: React.FC = () => {
  const {loginWithJWTToken, loggedIn, logout} = useAuth()

  useEffect(() => {
    const authToken: string = getAuthToken()

    if (authToken) {
      loginDigitSAML(authToken)
    }
  }, [])

  const getParams = () => {
    return qs.parse(window.location.search, {ignoreQueryPrefix: true})
  }

  const loginDigitSAML = (token: string) => {
    if (loggedIn) {
      logout()
    }
    loginWithJWTToken([{token}])
  }

  // After a user successfully authenticates on the Azure SSO login page, they
  // will redirected back here with a token in the URL.
  const getAuthToken = () => {
    if (!window.location.search) {
      return
    }

    return getParams().token as string
  }

  if (getAuthToken()) {
    return null
  }

  return (
    <Card>
      <H2>Login Error</H2>
      <Spacer xs />
      <P>Could not login, please contact your administator.</P>
    </Card>
  )
}
