export enum CATEGORY_IDS {
  NAME = 'name',
  MODEL_ID = 'model_id',
  MODEL_NAME = 'model_name',
  CLIENT_ID = 'client_id',
  IS_ALIVE = 'is_alive',
  IS_LOCKED = 'is_locked',
  LINE_IN_SOURCE = 'line_in_source',
  WEIGHT = 'SpotRelativePerfContribWeightWrtParent',
  IS_NODE_ALIVE = 'is_node_alive',
  IS_TRANSACTION_CANCELLED = 'is_cancelled_transaction',
  IS_PENDING_TRANSACTION = 'is_pending_transaction'
}

export enum CATEGORY_NAMES {
  ALL = 'all',
  TOTAL = 'total'
}

export const MAX_PAGINATED_COUNT = 10_000
