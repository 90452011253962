import {ApiQueryParams} from 'fairlight'

import {
  IChartTable,
  IDistributionList,
  IDistributionListUser,
  IDMSDocument,
  IDocumentBatch,
  IDocumentCategory,
  IDocumentFile,
  IDocumentList,
  IDocumentSubCategory,
  IDocumentUpload
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'
import {IApiListResponse} from '@d1g1t/api/typings'

import {IDateRange} from '@d1g1t/lib/date-range'

import {ISingleDocumentFormValues} from '@d1g1t/advisor/pages/dms/documents/single/typings'

import {BaseRestEndpoints} from './base'

export interface IDMSFilters {
  document_file__category?: string // document_file__category
  document_file__subcategory?: string // document_file__subcategory
  advisor?: string // advisor
  is_published?: boolean // is_published
}
export interface IDMSRequestBody {
  pagination?: IPaginationOptions
  filter?: IDMSFilters
  options?: {
    dateRange?: IDateRange
  }
}
export interface IDMSEditPayload {
  advisor?: string // url
  documentFile?: IDocumentFile
  description?: string
  asOfDate?: string
}
export interface IBatchAllRequest {
  pagination: IPaginationOptions
  options?: {dateRange?: IDateRange}
  reason?: string
}
export interface IBulkDMSApprovalsRequest {
  approvals: string[]
}
export interface IBulkDMSDownloadsRequest {
  s3_files: string[]
}
export interface IDMSS3DownloadLink {
  link: string
}

export class DMSBaseEndpoints extends BaseRestEndpoints {
  static basePath = '/dms'

  static getCategories() {
    return super._get<IApiListResponse<IDocumentCategory>>(
      '/document-categories/?return_items_qty=True'
    )
  }

  static getSubCategories() {
    return super._get<IApiListResponse<IDocumentSubCategory>>(
      '/document-subcategories/?return_items_qty=True'
    )
  }

  static getCategoriesPath() {
    return super.buildPath('/document-categories/')
  }

  static getSubCategoriesPath() {
    return super.buildPath('/document-subcategories/')
  }
}

export class DMSEndpoints extends BaseRestEndpoints {
  static basePath = '/dms/documents'

  static documents() {
    return super._get('')
  }

  static getDocumentFileID(id: string) {
    return super._findById<IDMSDocument>(id)
  }

  static readDocument(id: string) {
    return super._post(`/${id}/read`)
  }

  static unreadDocument(id: string) {
    return super._post(`/${id}/not-read`)
  }

  static addContacts(
    id: string,
    contacts: Pick<IDistributionList, 'targetEntity'>[]
  ) {
    return super._put(`/${id}/add-contacts`, {
      body: {contacts}
    })
  }

  static addInternalUsers(
    id: string,
    users: Pick<IDistributionListUser, 'profile' | 'message'>[]
  ) {
    return super._put(`/${id}/add-internal-users`, {
      body: {users}
    })
  }

  static chart(body: IDMSRequestBody) {
    return super._post<IChartTable>('/chart', {
      body
    })
  }

  static investorChart(body: IDMSRequestBody, params?: ApiQueryParams) {
    return super._post<IChartTable>('/investor-chart', {
      body,
      extraKey: JSON.stringify(body.filter),
      query: params
    })
  }

  static publish(body: IDocumentBatch) {
    return super._post('/batch-publish', {body})
  }

  static update(id: string, body: IDMSEditPayload) {
    return super._partialUpdate<IDMSDocument>(id, body)
  }

  static approve(body: IDocumentBatch) {
    return super._post('/batch-approve', {body})
  }

  static reject(body: IDocumentBatch) {
    return super._post('/batch-reject', {body})
  }

  static delete(body: IDocumentList) {
    return super._delete('/batch-delete', {body})
  }

  static publishAll(body: IBatchAllRequest) {
    return super._post('/batch-publish', {body})
  }

  static approveAll(body: IBatchAllRequest) {
    return super._post('/batch-approve', {body})
  }

  static rejectAll(body: IBatchAllRequest) {
    return super._post('/batch-reject', {body})
  }

  static read(body: IDocumentList) {
    return super._post('/batch-read', {body})
  }

  static readAll(body: IBatchAllRequest) {
    return super._post('/batch-read', {body})
  }

  static unread(body: IDocumentList) {
    return super._post('/batch-unread', {body})
  }

  static unreadAll(body: IBatchAllRequest) {
    return super._post('/batch-unread', {body})
  }

  static download(id: string) {
    return super._post<Blob>(`/${id}/download`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate, br'
      }
    })
  }
}
export class DMSFilesEndpoints extends BaseRestEndpoints {
  static basePath = '/dms/document-files'

  static update(id: string, body: ISingleDocumentFormValues) {
    return super._put(`/${id}`, {body})
  }
}

export class DMSDocumentsApprove extends BaseRestEndpoints {
  static basePath = '/approvals'

  static approve(body: IBulkDMSApprovalsRequest) {
    return super._post('/bulk-approve', {body})
  }
}

export class DMSDocumentsEndpoints extends BaseRestEndpoints {
  static basePath = '/dms/documents/upload'

  static upload(body: FormData) {
    return super._create<IDocumentUpload>(body)
  }
}

export class DMSDownloadsEndpoints extends BaseRestEndpoints {
  static basePath = '/file-management/files'

  headers: {}

  static getS3Link(id: string) {
    return super._get<IDMSS3DownloadLink>(id)
  }

  static download(body: any) {
    return super._post<Blob>('/bulk-download', {
      body,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*'
      }
    })
  }
}
