import {deprecated} from 'typesafe-actions'

import {ICalculationRequestBody} from '@d1g1t/api/endpoints'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {IRangeToLoad} from '@d1g1t/shared/wrappers/chart-paginator/lib'

import {getCalculationId} from './lib'
import {ICalculationParams} from './typings'

const {createAction} = deprecated

export const actions = {
  /**
   * First action called for all subscribers, runs as soon as a valid
   * calculation ID can be derived and adds to the instance count
   */
  register: createAction('@CALCULATIONS/REGISTER', (action) => {
    return (params: ICalculationParams) =>
      action(undefined, {id: getCalculationId(params)})
  }),
  /**
   * Removes the subscriber from the instance count when its calculation ID
   * changes or the component unmounts
   */
  deregister: createAction('@CALCULATIONS/DEREGISTER', (action) => {
    return (params: ICalculationParams) =>
      action(undefined, {id: getCalculationId(params)})
  }),
  /**
   * Called to kick off a calculation and sets loading to true, not called
   * when another subscriber for this calculation has already started
   */
  initCalculation: createAction('@CALCULATIONS/INIT', (action) => {
    return (params: ICalculationParams) =>
      action(params, {id: getCalculationId(params)})
  }),
  /**
   * Called if a calculation failes to start (request body could not be
   * generated), sets loading to false
   */
  abortCalculation: createAction('@CALCULATIONS/ABORT', (action) => {
    return (id: string) => action(undefined, {id})
  }),
  /**
   * Starts a calculation and discards the results
   */
  kickoffBackgroundCalculation: createAction(
    '@CALCULATIONS/KICKOFF_BACKGROUND',
    (action) => {
      return (id: string, params: ICalculationParams) => action(params, {id})
    }
  ),
  /**
   * Called when all subscribers have deregistered from the calculation, cancels
   * in-progress requests
   */
  cancelCalculation: createAction('@CALCULATIONS/CANCEL', (action) => {
    return (params: ICalculationParams) =>
      action(undefined, {id: getCalculationId(params)})
  }),
  /**
   * Marker for starting a calculation, update calcRequestBody in the state
   */
  startCalculation: createAction('@CALCULATIONS/START', (action) => {
    return (id: string, calcRequestBody: ICalculationRequestBody) =>
      action(calcRequestBody, {id})
  }),
  /**
   * Sets the result of a calculation in the store
   */
  setResult: createAction('@CALCULATIONS/SET_RESULT', (action) => {
    return (id: string, response: StandardResponse) => action(response, {id})
  }),
  /**
   * Sets the failure result of a caluculation in the store
   */
  error: createAction('@CALCULATIONS/ERROR', (action) => {
    return (id: string, error: Error) => action(error, {id})
  }),
  /**
   * Starts a calculation export to excel, file will begin downloading automatically
   * when it is ready
   */
  exportExcel: createAction('@CALCULATIONS/REQUEST_EXCEL_EXPORT', (action) => {
    return (params: ICalculationParams) =>
      action(params, {id: getCalculationId(params)})
  }),
  /**
   * Called if excel export failed
   */
  exportExcelError: createAction(
    '@CALCULATIONS/FAILED_EXCEL_EXPORT',
    (action) => {
      return (id: string, error: Error) => action(error, {id})
    }
  ),
  /**
   * Called after an excel export completes
   */
  exportExcelComplete: createAction(
    '@CALCULATIONS/COMPLETE_EXCEL_EXPORT',
    (action) => {
      return (id: string) => action(undefined, {id})
    }
  ),
  /**
   * Starts a calculation validation export, file will begin downloading automatically
   * when it is ready
   */
  exportValidationReport: createAction(
    '@CALCULATIONS/REQUEST_VALIDATION_REPORT',
    (action) => {
      return (params: ICalculationParams) =>
        action(params, {id: getCalculationId(params)})
    }
  ),
  /**
   * Called validation report failed
   */
  exportValidationReportError: createAction(
    '@CALCULATIONS/FAILED_VALIDATION_REPORT',
    (action) => {
      return (id: string, error: Error) => action(error, {id})
    }
  ),
  /**
   * Called after validation export completes
   */
  exportValidationReportComplete: createAction(
    '@CALCULATIONS/COMPLETE_VALIDATION_REPORT',
    (action) => {
      return (id: string) => action(undefined, {id})
    }
  ),
  /**
   * For incrementally loaded data, requests the sepcified ranges in parallel
   */
  fetchRanges: createAction('@CALCULATIONS/REQUEST_FETCH_RANGES', (action) => {
    return (id: string, params: ICalculationParams, ranges: IRangeToLoad[]) =>
      action({params, ranges}, {id})
  }),
  /**
   * Updates the response with a loaded range
   */
  fetchSingleRangeComplete: createAction(
    '@CALCULATIONS/COMPLETE_FETCH_SINGLE_RANGE',
    (action) => {
      return (id: string, range: IRangeToLoad, response: StandardResponse) =>
        action({range, response}, {id})
    }
  ),
  /**
   * Resets the loading state for ranges when the request fails
   */
  fethcSingleRangeFailed: createAction(
    '@CALCULATIONS/FAILED_FETCH_SINGLE_RANGE',
    (action) => {
      return (id: string, range: IRangeToLoad) => action(range, {id})
    }
  ),
  /**
   * Removes response data and clears any pending loading requests for data or
   * ranges
   */
  clearResponseData: createAction(
    '@CALCULATIONS/CLEAR_RESPONSE_DATA',
    (action) => {
      return (id: string) => action(undefined, {id})
    }
  ),
  /**
   * Removes key `calculationId` from calculation store
   */
  invalidateCachedCalculation: createAction(
    '@CALCULATIONS/INVALIDATE_CACHED_CALCULATION',
    (action) => {
      return (id: string) => action(undefined, {id})
    }
  )
}
