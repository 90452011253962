import React from 'react'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router-dom'

import {ApiError, useApiQuery} from 'fairlight'
import {FormikProvider, useFormik} from 'formik'

import NewReleasesIcon from '@material-ui/icons/NewReleases'

import {FEATURE_FLAGS} from '@d1g1t/config/feature-flags'

import {
  FirmConfigurationEndpoints,
  IMfaDestinationRequestInput,
  IMfaLoginRequestInput
} from '@d1g1t/api/endpoints'
import {
  EXTERNALPROFILE_PREFERRED_LANGUAGE,
  IAuthMethod
} from '@d1g1t/api/models'
import {IUserLoginRequest} from '@d1g1t/api/typings'

import {history} from '@d1g1t/lib/history'

import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {RadioGroupField} from '@d1g1t/shared/components/form-field/radio-group-field'
import {MFACodeInput} from '@d1g1t/shared/components/formatted-input'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {LabeledRadio} from '@d1g1t/shared/components/mui/radio'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {RouterLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H1, P, Span} from '@d1g1t/shared/components/typography'
import {
  AuthenticationLocations,
  forgotPasswordPath
} from '@d1g1t/shared/locations'
import {useAuth} from '@d1g1t/shared/wrappers/auth'
import {LOGOUT_URL_KEY} from '@d1g1t/shared/wrappers/auth/constants'
import {isInvalidCredentialsError} from '@d1g1t/shared/wrappers/auth/lib'
import {getErrorDisplayMessage} from '@d1g1t/shared/wrappers/error-boundary'
import {LOGIN_PAGE_TRANSLATION_KEYS_SHARED} from '@d1g1t/shared/wrappers/localization-settings/constants'
import {useUrlQueryParams} from '@d1g1t/shared/wrappers/url-query-params'

import {
  AUTH_METHOD_PASSWORD,
  INITIAL_VALUES,
  LOGIN_FORM_FIELD_NAMES,
  LOGIN_FORM_QUERY_PARAM_SCHEMA
} from './constants'
import {useValidationLocalizationSchema} from './hook'
import {appendLoginRedirect, methodVisibilityPredicate} from './lib'

type ILoginFormInputInterface = IUserLoginRequest &
  IMfaLoginRequestInput &
  IMfaDestinationRequestInput

/**
 * Login form provides input for email + password.
 *
 * If MFA enabled, offers input for MFA as well to finalize login.
 */
export const LoginCredentialsForm: React.FC<{
  /**
   * Passed from the investor app login page.
   */
  investorApp?: boolean
  language: EXTERNALPROFILE_PREFERRED_LANGUAGE
}> = (props) => {
  const {t} = useTranslation()

  const auth = useAuth()

  const [mfaValidationSchema, validationSchema, mfaDestinationSchema] =
    useValidationLocalizationSchema()

  const listAllMatch = useRouteMatch(AuthenticationLocations.listAll())
  const [queryParams] = useUrlQueryParams({
    schema: LOGIN_FORM_QUERY_PARAM_SCHEMA
  })

  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const visiblePasswordMethod: IAuthMethod =
    firmPreConfiguration.data?.authMethods.find(
      (method) =>
        method.authMethod === AUTH_METHOD_PASSWORD &&
        methodVisibilityPredicate(method, props.investorApp, listAllMatch)
    )
  const visibleMethodsMinusPassword: IAuthMethod[] =
    firmPreConfiguration.data?.authMethods.filter((method) => {
      if (method.authMethod === AUTH_METHOD_PASSWORD) {
        return false
      }
      return methodVisibilityPredicate(method, props.investorApp, listAllMatch)
    }) ?? []

  /**
   * Parsed API error message
   */
  const loginErrorMessage = (() => {
    if (!auth.loginError) {
      return null
    }

    if (auth.loginError instanceof ApiError && auth.loginError.status === 400) {
      return String(Object.values(auth.loginError.responseBody)[0])
    }

    if (isInvalidCredentialsError(auth.loginError)) {
      return LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CREDENTIALS_ERROR
    }

    return getErrorDisplayMessage(auth.loginError)
  })()

  const redirectUrl = (() => {
    if (queryParams.redirectTo?.length > 1) {
      return queryParams.redirectTo
    }

    if (props.investorApp) {
      return firmPreConfiguration.data?.investorRedirectOnLogInPage
    }

    return firmPreConfiguration.data?.advisorRedirectOnLogInPage
  })()

  const formik = useFormik<ILoginFormInputInterface>({
    initialValues: INITIAL_VALUES,
    validationSchema: (() => {
      if (auth.loggingInAwaitMfaDestinationInput) {
        return mfaDestinationSchema
      }
      if (auth.loggingInAwaitMfa) {
        return mfaValidationSchema
      }
      return validationSchema
    })(),
    onSubmit: (values: ILoginFormInputInterface) => {
      if (auth.loggingInAwaitMfaDestinationInput) {
        auth.loginMfaDestination({
          redirectTo: redirectUrl,
          deliveryDestination: values.deliveryDestination
        })
      } else if (auth.loggingInAwaitMfa) {
        auth.loginMfa({
          otp: values.otp,
          redirectTo: redirectUrl,
          language: props.language
        })
      } else {
        auth.login({
          username: values.username,
          password: values.password,
          redirectTo: redirectUrl,
          language: props.language
        })
      }
      formik.resetForm()
    }
  })

  const handleAuthRedirectClick = (method: IAuthMethod) => {
    if (method.authMethod === AUTH_METHOD_PASSWORD) {
      return
    }

    window.sessionStorage.setItem(LOGOUT_URL_KEY, method.logoutUrl)

    window.location.href = appendLoginRedirect(method.loginUrl)
  }

  const renderLoginButton = () => (
    <Button
      data-testid='button-login'
      fullWidth
      large
      primary
      contained
      disabled={auth.loggingIn}
      type='submit'
    >
      {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.LOG_IN}
    </Button>
  )

  /**
   * User should input username and password
   */
  const renderCredentialInputForm = () => {
    if (!visiblePasswordMethod) {
      return null
    }

    return (
      <>
        <OutlinedInputField
          data-testid='input-login-email'
          name={LOGIN_FORM_FIELD_NAMES.username}
          label='Email address'
          outlinedInputProps={{
            autoFocus: true,
            fullWidth: true
          }}
        />
        <Spacer xs />
        <OutlinedInputField
          data-testid='input-login-password'
          name={LOGIN_FORM_FIELD_NAMES.password}
          label='Password'
          outlinedInputProps={{
            type: 'password',
            fullWidth: true
          }}
        />
        <Spacer xs />
        {renderLoginButton()}
        <Spacer xs />
        <RouterLink
          to={forgotPasswordPath()}
          style={{display: 'block', textAlign: 'center'}}
          data-testid='link-forgot-password'
        >
          <Span>{LOGIN_PAGE_TRANSLATION_KEYS_SHARED.FORGOT_PASSWORD}</Span>
        </RouterLink>
      </>
    )
  }

  /**
   * User should select where the MFA code will be sent
   */
  const renderMfaDestinationInputForm = () => {
    return (
      <>
        <RadioGroupField
          name={LOGIN_FORM_FIELD_NAMES.mfaDestination}
          label={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PROVIDE_DESTINATION_MESSAGE}
        >
          {auth.mfaDestinations.map((destination, index) => {
            return (
              <LabeledRadio
                key={destination.id}
                label={destination.destination}
                value={destination.id}
                data-testid={`radio-login-mfa-destination-${index}`}
              />
            )
          })}
        </RadioGroupField>
        {renderLoginButton()}
      </>
    )
  }

  /**
   * User should input the MFA code
   */
  const renderMfaCodeInputForm = () => (
    <>
      <P>
        {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.ENTER_THE_AUTHENTICATION_CODE_TOP}
      </P>
      <P>
        {
          LOGIN_PAGE_TRANSLATION_KEYS_SHARED.ENTER_THE_AUTHENTICATION_CODE_BOTTOM
        }
      </P>
      <Spacer xs />
      <OutlinedInputField
        name={LOGIN_FORM_FIELD_NAMES.mfaCode}
        label={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MFA_CODE}
        outlinedInputProps={{
          autoFocus: true,
          fullWidth: true,
          inputComponent: MFACodeInput
        }}
        key='input-login-mfa-code'
        data-testid='input-login-mfa-code'
      />
      <Spacer xs />
      {renderLoginButton()}
    </>
  )

  /**
   * Renders the credentials form OR MFA destination form OR MFA code input form.
   */
  const renderLoginForm = () => {
    if (auth.loggingInAwaitMfaDestinationInput) {
      return renderMfaDestinationInputForm()
    }
    if (auth.loggingInAwaitMfa || auth.invalidCode) {
      return renderMfaCodeInputForm()
    }
    return renderCredentialInputForm()
  }

  /**
   * Renders buttons for SSO methods - ignores the credential based method(s).
   */
  const renderVisibleMethodsMinusPassword = () => {
    if (!visibleMethodsMinusPassword.length) {
      return null
    }

    return visibleMethodsMinusPassword.map((method) => {
      return (
        <Button
          fullWidth
          large
          outlined
          primary
          onClick={() => handleAuthRedirectClick(method)}
          style={{marginBottom: 16, textTransform: 'none'}}
          key={method.name}
        >
          {method.name}
        </Button>
      )
    })
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{maxWidth: '80%', margin: '0 auto'}}>
          <H1 semiBold>
            {listAllMatch &&
              `${t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.INTERNAL)} `}
            {t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.LOG_IN)}
          </H1>
          <Spacer xs />
          {listAllMatch && (
            <Alert
              severity='info'
              closeText={
                LOGIN_PAGE_TRANSLATION_KEYS_SHARED.GO_TO_NORMAL_LOGIN_PAGE
              }
              onClose={() => history.replace(AuthenticationLocations.basePath)}
            >
              {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.SHOWING_ALL_LOGIN_METHODS}
            </Alert>
          )}
          {!!auth.loginError && (
            <Alert severity='error'>{loginErrorMessage}</Alert>
          )}
          {!!auth.invalidCodeErrorMessage && (
            <Alert severity='error'>{auth.invalidCodeErrorMessage}</Alert>
          )}
          <Spacer xs />
          {renderLoginForm()}
          <Spacer xs />
          {visiblePasswordMethod && visibleMethodsMinusPassword.length ? (
            <>
              <Divider />
              <Spacer xs />
            </>
          ) : null}
          {renderVisibleMethodsMinusPassword()}
          {FEATURE_FLAGS.SHOW_ALL_AUTH_METHODS && !listAllMatch && (
            <Tooltip
              title={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.D1G1T_ONLY_TOOLTIP}
            >
              <RouterLink
                to={AuthenticationLocations.listAll()}
                style={{display: 'block', textAlign: 'center'}}
              >
                <Span>
                  {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.SHOW_HIDDEN_AUTH_METHODS}
                  <NewReleasesIcon
                    style={{
                      fontSize: 'smaller',
                      verticalAlign: 'super',
                      marginLeft: 2
                    }}
                  />
                </Span>
              </RouterLink>
            </Tooltip>
          )}
        </div>
      </form>
    </FormikProvider>
  )
}
