import {ApiParam} from 'fairlight'

import {IInvestmentMandateGuidelineLimit} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeopleInvestmentMandateGuidelineLimitEndpoints extends BaseRestEndpoints {
  static basePath = '/people/investment-mandate-guideline-limits'

  static create(body: IInvestmentMandateGuidelineLimit, date: string) {
    return super._post<IInvestmentMandateGuidelineLimit>('', {
      body,
      query: {as_of_date: date}
    })
  }

  static partialUpdate(
    id: ApiParam,
    body: Partial<IInvestmentMandateGuidelineLimit>,
    date: string
  ) {
    return super._patch<IInvestmentMandateGuidelineLimit>(`/${id}`, {
      body,
      query: {as_of_date: date}
    })
  }

  static destroy(id: ApiParam, date: string) {
    return super._delete(`/${id}`, {query: {as_of_date: date}})
  }
}
