import React, {forwardRef} from 'react'
import {useTranslation} from 'react-i18next'

import MuiAlert, {AlertProps} from '@material-ui/lab/Alert'

import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

export {Color} from '@material-ui/lab/Alert'

export interface IAlertProps extends AlertProps {}

export const Alert: React.FC<IAlertProps> = forwardRef((props, ref) => {
  const {t} = useTranslation()

  return (
    <MuiAlert
      ref={ref}
      style={{marginTop: '10px', marginBottom: '10px'}}
      {...props}
    >
      {onlyTranslateStrings(t, props.children)}
    </MuiAlert>
  )
})
