import {ApiParam} from 'fairlight'

import {IFeeSharing} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface IFeeSharingRuleRequestBody {
  pagination?: IPaginationOptions
}
export class FeeSharingRulesEndpoints extends BaseRestEndpoints {
  static basePath = '/fees/sharing-rules'

  static list() {
    return super._list<IApiListResponse<IFeeSharing>>()
  }

  static chart(body: IFeeSharingRuleRequestBody) {
    return super._chart(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static create(body: IFeeSharing) {
    return super._create<IFeeSharing>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IFeeSharing>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IFeeSharing>) {
    return super._partialUpdate<IFeeSharing>(id, body)
  }
}
