import {ResponseBody} from 'fairlight'

import {
  IAntiMoneyLaundering,
  IBusinessType,
  IFoundationType,
  IInstitutionType,
  IInvestmentGuidelineGrouping,
  IInvestmentHorizon,
  IInvestmentMandateGoal,
  IInvestorKnowledge,
  ILiquidityConstraint,
  IMandate,
  IQuestionnaire,
  IRelationship,
  IRiskCapacity,
  IRiskTolerance,
  ISubFirm,
  ITaxpayerNumberType,
  ITrustType
} from '@d1g1t/api/models'
import {IProvince} from '@d1g1t/api/typings'

import {BaseEndpoints} from './base'
import {IApiListResponse} from './typings'

export const investmentMandateGoalsPathKey = 'investment-mandate-goals'

export class PeopleEndpoints extends BaseEndpoints {
  static basePath = '/people'

  static businessTypes() {
    return super._get<IApiListResponse<IBusinessType>>('/business-types')
  }

  static foundationTypes() {
    return super._get<IApiListResponse<IFoundationType>>('/foundation-types')
  }

  static institutionTypes() {
    return super._get<IApiListResponse<IInstitutionType>>('/institution-types')
  }

  static relationships() {
    return super._get<IApiListResponse<IRelationship>>('/relationships')
  }

  static investmentGuidelineGroupings() {
    return super._get<IApiListResponse<IInvestmentGuidelineGrouping>>(
      '/investment-guideline-groupings'
    )
  }

  static provinces() {
    return super._get<IProvince[]>('/provinces')
  }

  static taxPayerNumberTypes() {
    return super._get<IApiListResponse<ITaxpayerNumberType>>(
      '/taxpayernumber-types'
    )
  }

  static trustTypes() {
    return super._get<IApiListResponse<ITrustType>>('/trust-types')
  }

  static subFirms() {
    return this._get<IApiListResponse<ISubFirm>>('/subfirms')
  }

  static antiMoneyLaundering() {
    return this.latest<IAntiMoneyLaundering[]>('anti-money-laundering')
  }

  static investmentHorizons() {
    return this.latest<IInvestmentHorizon[]>('investment-horizons')
  }

  static liquidityConstraints() {
    return this.latest<ILiquidityConstraint[]>('liquidity-constraints')
  }

  static investmentMandateGoals() {
    return this.latest<IInvestmentMandateGoal[]>(investmentMandateGoalsPathKey)
  }

  static riskTolerances() {
    return this.latest<IRiskTolerance[]>('risk-tolerances')
  }

  static riskCapacities() {
    return this.latest<IRiskCapacity[]>('risk-capacities')
  }

  static investorKnowledgeList() {
    return this.latest<IInvestorKnowledge[]>('investor-knowledges')
  }

  static questionnaire() {
    return this.latest<IQuestionnaire>('questionnaires')
  }

  static mandates() {
    return this.latest<IMandate[]>('mandates')
  }

  private static latest<TResponseBody extends ResponseBody>(slug: string) {
    return super._get<TResponseBody>(`/${slug}/latest`)
  }
}
