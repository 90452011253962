import {parsePhoneNumber} from 'libphonenumber-js'
import {isNil} from 'lodash'

import {Formatter} from './base-formatter'

export class PhoneFormatter extends Formatter {
  public format(value: string) {
    if (isNil(value)) {
      return this.renderEmpty()
    }

    return parsePhoneNumber(value).formatInternational()
  }
}
