/**
 * Returns the first non-falsy key of object `obj`.
 * @remarks The order of properties in `obj` indicates the hierarchy amongst the keys of `obj`
 */
export const getFirstTruthyObjectKey = <O extends {[k: string]: boolean}>(
  obj: O
): keyof O => {
  for (const key in obj) {
    if (!obj[key]) {
      continue
    }
    return key
  }
}
