import {ApiParam} from 'fairlight'

import {BaseRestEndpoints} from './base'

export class GlobalSettingsEndpoints extends BaseRestEndpoints {
  static basePath = '/profile/global-settings'

  static findById(id: ApiParam) {
    return super._findById<object>(id)
  }

  static update(id: ApiParam, data: object, searchParams = {}) {
    // Using `put` to ensure the view ID is in the URL when looking at logs
    return super._put<object>(`/${id}`, {body: data, query: searchParams})
  }
}
