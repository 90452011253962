import React, {useMemo} from 'react'

import MuiSelect, {SelectProps} from '@material-ui/core/Select'

import {classNames} from '@d1g1t/lib/class-names'

import {InlinedInput} from '@d1g1t/shared/components/mui/inlined-input'
import {
  IMenuProps,
  muiMenuPropsFromCustomProps
} from '@d1g1t/shared/components/mui/menu'
import {
  IOutlinedInputExtraProps,
  OutlinedInput
} from '@d1g1t/shared/components/mui/outlined-input'

import * as css from './styles.scss'

type IMuiSelectProps = Omit<
  SelectProps,
  'variant' | 'classes' | 'onChange' | 'MenuProps'
>

interface ISelectCustomProps extends IOutlinedInputExtraProps {
  /**
   * Removes borders and renders `<InlineInput>`
   */
  inlined?: boolean
  /**
   * Removes left padding
   */
  noLeftPadding?: boolean
  noPadding?: boolean
  size?: 'small' | 'default' | 'medium' | 'compact'
  MenuProps?: IMenuProps
  labelWidth?: number
}

export type SelectValue = string | number | boolean | SelectMultiValue | object

export type SelectMultiValue = (string | number | boolean)[]

export interface ISelectProps extends IMuiSelectProps, ISelectCustomProps {
  onChange?(
    value?: SelectValue,
    child?: React.ReactNode,
    event?: React.ChangeEvent<HTMLSelectElement>
  ): void
}

const onChangeTransform = (
  onChange: (
    value?: SelectValue,
    child?: React.ReactNode,
    event?: React.ChangeEvent<HTMLSelectElement>
  ) => void
) => {
  return (
    event?: React.ChangeEvent<HTMLSelectElement>,
    child?: React.ReactNode
  ) => onChange(event.target.value, child, event)
}

const muiSelectPropsFromCustomProps = (props: ISelectProps): SelectProps => {
  const {
    whiteBackground,
    greyBackground,
    noBorder,
    onChange,
    inlined,
    rounded,
    noLeftPadding,
    noPadding,
    mutedBorder,
    thickBorder,
    spaceLeft,
    spaceRight,
    boldText,
    largerFontSize,
    size,
    MenuProps,
    labelWidth,
    fullWidth,
    ...other
  } = props

  const input = inlined ? (
    <InlinedInput />
  ) : (
    <OutlinedInput
      spaceLeft={spaceLeft}
      spaceRight={spaceRight}
      rounded={rounded}
      whiteBackground={whiteBackground}
      greyBackground={greyBackground}
      noBorder={noBorder}
      mutedBorder={mutedBorder}
      thickBorder={thickBorder}
      boldText={boldText}
      largerFontSize={largerFontSize}
      labelWidth={labelWidth || 0}
      fullWidth={fullWidth}
    />
  )

  const transformedOnChange = useMemo(() => {
    if (typeof onChange !== 'function') {
      return null
    }

    return onChangeTransform(onChange)
  }, [onChange])

  return {
    input,
    classes: {
      select: classNames(css.select, {
        [css.rounded]: rounded,
        [css.sizeSmall]: size === 'small',
        [css.sizeMedium]: size === 'medium',
        [css.sizeCompact]: size === 'compact',
        [css.noLeftPadding]: noLeftPadding,
        [css.noPadding]: noPadding
      }),
      icon: classNames(css.icon, css.moveLeft)
    },
    variant: 'outlined',
    onChange: transformedOnChange,
    MenuProps: muiMenuPropsFromCustomProps(MenuProps || ({} as IMenuProps)),
    ...other
  }
}

export const Select = React.forwardRef((props: ISelectProps, ref) => (
  <MuiSelect ref={ref} {...muiSelectPropsFromCustomProps(props)} />
))

Select.displayName = 'Select(d1g1t)'
