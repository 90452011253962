import {ApiParam} from 'fairlight'

import {ISleeve} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class SleevesEndpoints extends BaseRestEndpoints {
  static basePath = '/sleeves'

  static getSleeve(id: string) {
    return super._get<ISleeve>(`/${id}`)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<ISleeve>) {
    return super._partialUpdate<ISleeve>(id, body)
  }
}
