import React from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'

import {useApi, useApiQuery} from 'fairlight'

import {
  ExternalToolsEndpoints,
  FirmConfigurationEndpoints
} from '@d1g1t/api/endpoints'

import {useLoginRedirectIfLoggedOut} from '@d1g1t/shared/wrappers/auth'
import {TOKEN_KEY} from '@d1g1t/shared/wrappers/auth/constants'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useDatadogConfig} from '@d1g1t/shared/wrappers/datadog/hook'
import {useGlobalLocalizationSettingsSynchronization} from '@d1g1t/shared/wrappers/localization-settings/hook'

import {InvestorAppNotFoundPage} from '@d1g1t/investor/components/not-found-page'
import {Frame} from '@d1g1t/investor/containers/frame'
import {
  ActivityLocations,
  DocumentLocations,
  DocumentsDmsInvestorLocations,
  MonitorLocations,
  PortfolioLocations,
  SettingsLocations
} from '@d1g1t/investor/locations'
import {ActivityPage} from '@d1g1t/investor/pages/activity/loadable'
import {MonitorPage} from '@d1g1t/investor/pages/home/loadable'
import {PortfolioPage} from '@d1g1t/investor/pages/portfolio/loadable'
import {SettingsPage} from '@d1g1t/investor/pages/settings/loadable'

import {EmulatorBorders} from './containers/emulator-borders'
import {DMSDocumentsPageTable} from './pages/dms/documents/list/loadable'
import {DMSDocumentsPageCategories} from './pages/dms/documents/loadable'
import {DocumentTypePage} from './pages/documents/document-type'

export const AuthenticatedRoutes: React.FC = function AuthenticatedRoutes() {
  const {redirecting} = useLoginRedirectIfLoggedOut()

  if (redirecting) {
    return null
  }
  return (
    <>
      <EmulatorBorders />
      <Frame>
        <AuthenticatedRoutesSwitch />
      </Frame>
    </>
  )
}

const AuthenticatedRoutesSwitch: React.FC = () => {
  useDatadogConfig()
  const [localizationSynced] = useGlobalLocalizationSettingsSynchronization()

  const [calculationSettings] = useCalculationSettings()
  const history = useHistory()
  const api = useApi()

  const [firmPreconfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  if (
    !calculationSettings ||
    firmPreconfiguration.loading ||
    !localizationSynced
  ) {
    return null
  }

  return (
    <Switch>
      <Redirect
        exact
        from='/'
        to={
          firmPreconfiguration.data.investorRedirectOnLogInPage ||
          MonitorLocations.basePath
        }
      />
      <Route path={MonitorLocations.basePath}>
        <MonitorPage />
      </Route>
      <Route path={PortfolioLocations.basePath}>
        <PortfolioPage />
      </Route>
      <Route path={ActivityLocations.basePath}>
        <ActivityPage />
      </Route>
      <Route path={DocumentLocations.basePath}>
        <DocumentTypePage />
      </Route>
      <Route exact path={DocumentsDmsInvestorLocations.basePath}>
        <DMSDocumentsPageCategories />
      </Route>
      <Route path={DocumentsDmsInvestorLocations.documents()}>
        <DMSDocumentsPageTable />
      </Route>
      <Route
        exact
        path={DocumentLocations.futureVault()}
        component={() => {
          // Open FutureVault in new tab and
          // Current tab goes to previous page in history.
          history.goBack()
          const token = sessionStorage.getItem(TOKEN_KEY)
          window.open(api.buildUrl(ExternalToolsEndpoints.futureVault(token)))
          return null
        }}
      />
      <Route path={SettingsLocations.basePath}>
        <SettingsPage />
      </Route>
      <Route path='*'>
        <InvestorAppNotFoundPage />
      </Route>
    </Switch>
  )
}
