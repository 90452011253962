import React, {forwardRef} from 'react'

import MuiFormControl, {FormControlProps} from '@material-ui/core/FormControl'

import {useControlState} from '@d1g1t/shared/components/control-state'

export interface IFormControlProps extends FormControlProps {
  'data-testid'?: string
}

export const FormControl: React.FC<IFormControlProps> = forwardRef(
  (props, ref) => {
    const {loading} = useControlState()
    return <MuiFormControl ref={ref} disabled={loading} {...props} />
  }
)
