import {ICustodianTransactionCode} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class CustodianTransactionCodeEndpoints extends BaseRestEndpoints {
  static basePath = '/custodian-transaction-codes'

  static list(query: {limit?: number} = {}) {
    return super._list<IApiListResponse<ICustodianTransactionCode>>(query)
  }
}
