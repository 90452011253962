import React from 'react'

import {captureException, withScope} from '@sentry/browser'
import {ApiError} from 'fairlight'
import {capitalize} from 'lodash'

import {Spacer} from '@d1g1t/shared/components/spacer'

import {IApiErrorMessage} from './typings'

/**
 * Reports the error (either remotely or locally, dependent on environment)
 */
export function reportError(
  error: Error,
  opts: {context?: Record<string, string>; disableLog?: boolean} = {}
): void {
  if (errorShouldExpireSession(error)) {
    // don't report errors if they will result in a logout
    return
  }

  if (__DEVELOPMENT__ && !opts.disableLog) {
    console.error(error)
  }

  if (__PRODUCTION__) {
    withScope((scope) => {
      if (opts.context) {
        for (const key in opts.context) {
          if (key in opts.context) {
            scope.setContext(key, opts.context[key] as any)
          }
        }
      }

      captureException(error)
    })
  }
}

const serverErrorMessages = ['User admin does not have a profile']

const sessionExpireMessages = [
  'Signature has expired.',
  'Authentication credentials were not provided.',
  'Error decoding signature.',
  'Invalid signature.'
]

/**
 * Returns true if this error should clear the auth
 * token and redirect the user to the login page
 */
export function errorShouldExpireSession(error: Error): boolean {
  if (error instanceof ApiError) {
    if (error.status === 403) {
      const body = error.responseBody || {}

      if (sessionExpireMessages.includes(body.detail)) {
        return true // do something
      }
    } else if (error.status === 500) {
      const body = error.responseBody || {}

      if (serverErrorMessages.includes(body.detail)) {
        return true
      }
    } else if (error.status === 503) {
      return true
    }
  }

  return false
}

export function errorMessage(error: Error): React.ReactNode {
  if (
    error instanceof ApiError &&
    error.status === 400 &&
    typeof error.responseBody === 'object'
  ) {
    if (Array.isArray(error.responseBody)) {
      if (
        error.responseBody.length > 0 &&
        error.responseBody.every((value) => typeof value === 'string')
      ) {
        return error.responseBody.length > 1 ? (
          error.responseBody[0]
        ) : (
          <ul>
            {error.responseBody.map((msg, idx) => (
              <li key={idx}>{msg}</li>
            ))}
          </ul>
        )
      }
    } else {
      const entries = Object.entries(error.responseBody)
      if (
        entries.length > 0 &&
        entries.every(([, value]) => typeof value === 'string')
      ) {
        return (
          <ul>
            {entries.map(([key, value]) => (
              <li key={key}>
                <strong>
                  <code>{key}</code>
                </strong>
                : {value}
              </li>
            ))}
          </ul>
        )
      }
    }
  }
}

/**
 * Turns errors into react nodes to be displayed by the snackbar
 *
 * @param messages - an array of `IApiErrorMessage`s
 *
 * @returns An array of react nodes
 */
export function convertMessagesToReactNodes(
  messages: IApiErrorMessage[]
): JSX.Element[] {
  return messages.map(({content, parentKey}, index) => (
    <React.Fragment key={content}>
      {index > 0 && <Spacer xs />}
      <span>
        {parentKey ? <strong>{parentKey}</strong> : null}
        {parentKey ? ': ' : null}
        {content}
      </span>
    </React.Fragment>
  ))
}

/**
 * Takes any error and attempts to get any error messages from it
 * if it is an ApiError
 *
 * @param error - any Error
 *
 * @returns An array of error messages, if any
 */
export function getErrorMessagesFromApiError(
  error: Error,
  hideParentKey = false
): IApiErrorMessage[] {
  if (
    error instanceof ApiError &&
    error.status === 400 &&
    error.responseBody &&
    typeof error.responseBody === 'object'
  ) {
    return getNestedErrors(error.responseBody, hideParentKey)
  }

  return []
}

/**
 * Will recursively crawl an object to extract any error messages returned from the API
 *
 * @param errorData - an object from an ApiError's responseBody
 * @param hideParentKey - whether or not to include parent keys with error messages
 * @param parentKey - the parent key of all the error messages in the tree
 *
 * @returns An array of error messages
 */
function getNestedErrors(
  errorData: any,
  hideParentKey: boolean,
  parentKey?: string
): IApiErrorMessage[] {
  if (!errorData) {
    return []
  }

  if (Array.isArray(errorData)) {
    if (errorData.every((content) => typeof content === 'string')) {
      return errorData.map((content) => ({
        content,
        parentKey:
          parentKey && !hideParentKey ? capitalize(parentKey) : undefined
      }))
    }
  }

  const entries = Object.entries(errorData)
  const messages = []
  for (const [key, value] of entries) {
    messages.push(
      ...getNestedErrors(
        value,
        hideParentKey,
        hideParentKey ? undefined : parentKey ?? key
      )
    )
  }

  return messages
}

interface IFormErrors {
  [key: string]: IFormErrors | string | string[]
}

/**
 * Returns true if the errors can be passed to formik straight from the API.
 * This will return false if there are any fields that are not part of the form.
 */
export function hasFieldsForAllErrors(
  errorResponseBody: IFormErrors,
  initialValues: object
): boolean {
  return Object.entries(errorResponseBody).every(([key, value]) => {
    if (!Object.prototype.hasOwnProperty.call(initialValues, key)) {
      return false
    }

    if (typeof value === 'string') {
      return true
    }

    if (Array.isArray(value) && value.every((val) => typeof val === 'string')) {
      return true
    }

    if (
      typeof value === 'object' &&
      typeof initialValues[key] === 'object' &&
      !Array.isArray(initialValues[key])
    ) {
      return hasFieldsForAllErrors(
        errorResponseBody[key] as IFormErrors,
        initialValues[key]
      )
    }

    if (Array.isArray(value) && value.every((val) => typeof val === 'object')) {
      return true // array initial values might not be pre-populated, so just assume `false` to be safe
    }

    return false
  })
}
