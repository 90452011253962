import React, {forwardRef} from 'react'

import MuiInputAdornment, {InputAdornmentProps} from '@material-ui/core/InputAdornment'

export interface IInputAdornmentProps
  extends Omit<InputAdornmentProps, 'classes'> {}

export const InputAdornment: React.FC<IInputAdornmentProps> = forwardRef(
  (props, ref) => {
    return <MuiInputAdornment ref={ref} {...props} />
  }
)
