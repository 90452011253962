import {FieldMetaProps} from 'formik'

/**
 * Given a Formik field's `meta` object, returns whether the field should be
 * highlighted in red and what the error text should be.
 *
 * @param meta - Field's meta object, pulled from `useFormFieldControl` hook
 * @returns field's error state, and error text
 */
export function getFormFieldErrorState(meta: FieldMetaProps<any>): {
  /**
   * True when the field has an error
   */
  hasError: boolean
  /**
   * Field's error message
   */
  errorText: string
} {
  const hasError = meta.touched && !!meta.error

  /**
   * put empty string to always render the helper text field so that the
   * input field is always correctly positioned in relation to other form
   * fields
   */
  const errorText =
    (meta.touched &&
      (meta.error && Array.isArray(meta.error)
        ? meta.error.join(' ')
        : meta.error)) ||
    ' '

  return {hasError, errorText}
}
