import React, {useContext} from 'react'

import {useApiQuery} from 'fairlight'

import {ConfigContext} from '@d1g1t/config/context'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'
import {CHARTTABLEOPTIONS_STATUS} from '@d1g1t/api/models'

import {defaultd1g1tChartColoursOrdered} from './base-chart-colours'

export enum BASIC_COLOURS {
  RED = '#dd2c00',
  GREEN = '#2e7d32',
  YELLOW = '#ffb97a',
  ORANGE = '#ffb74d',
  BLACK = '#242424',
  GREY = '#616161',
  DARK_BLUE = '#0d47a1',
  LIGHT_BLUE = '#64b5f6'
}

export interface IInternalTheme {
  chartColors: string[]
  statusColourMap: {
    [CHARTTABLEOPTIONS_STATUS.NEUTRAL]: string
    [CHARTTABLEOPTIONS_STATUS.ERROR]: string
    [CHARTTABLEOPTIONS_STATUS.NOT_SET]: string
    [CHARTTABLEOPTIONS_STATUS.SUCCESS]: string
    [CHARTTABLEOPTIONS_STATUS.WARNING]: string
  }
  investmentGuidelineTooltipColours: {
    warning: string
    breach: string
    neutral: string
    targetWeight: string
    allowedRange: string
  }
  accountAllocationChartColours: {
    changed: string
    issues: string
  }
  uiColors: {
    positiveGreen: string
    negativeRed: string
  }
  readDocuments: {
    hasRead: string
    notRead: string
  }
}

export function useInternalTheme() {
  const {isAdvisorApp} = useContext(ConfigContext)

  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const defaultTheme: IInternalTheme = {
    chartColors:
      !isAdvisorApp &&
      firmPreConfiguration.data?.themeInvestor?.charts?.chartColors
        ? firmPreConfiguration.data.themeInvestor.charts.chartColors
        : defaultd1g1tChartColoursOrdered,
    statusColourMap: {
      [CHARTTABLEOPTIONS_STATUS.ERROR]: BASIC_COLOURS.RED,
      [CHARTTABLEOPTIONS_STATUS.SUCCESS]: BASIC_COLOURS.GREEN,
      [CHARTTABLEOPTIONS_STATUS.WARNING]: BASIC_COLOURS.YELLOW,
      [CHARTTABLEOPTIONS_STATUS.NOT_SET]: null,
      [CHARTTABLEOPTIONS_STATUS.NEUTRAL]: null
    },
    investmentGuidelineTooltipColours: {
      warning: BASIC_COLOURS.ORANGE,
      breach: BASIC_COLOURS.RED,
      neutral: BASIC_COLOURS.GREY,
      targetWeight: BASIC_COLOURS.DARK_BLUE,
      allowedRange: BASIC_COLOURS.LIGHT_BLUE
    },
    accountAllocationChartColours: {
      changed: BASIC_COLOURS.LIGHT_BLUE,
      issues: BASIC_COLOURS.RED
    },
    uiColors: {
      negativeRed: BASIC_COLOURS.RED,
      positiveGreen: BASIC_COLOURS.GREEN
    },
    readDocuments: {
      hasRead: 'transparent',
      notRead: BASIC_COLOURS.LIGHT_BLUE
    }
  }

  const InternalThemeContext = React.createContext(defaultTheme)

  return React.useContext(InternalThemeContext)
}
