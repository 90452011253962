import React, {useCallback, useDebugValue, useEffect, useMemo} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {every, isPlainObject} from 'lodash'

import {useEmulation} from '../emulation'
import {actions} from './actions'
import {createBoundGetter} from './getters'
import {mergeSettings} from './lib'
import {GlobalSettings} from './typings'

/**
 * Save and retreive values from KV store, owned by the current user.
 */
export function useGlobalSettings<T extends GlobalSettings>(
  /**
   * Key to save value in global settings.
   */
  id: string,
  /**
   * value when none is stored, must match the shape of saved values.
   * Any saved values that do not have a key in the default settings will not saved.
   */
  defaultSettings: T,
  /**
   * Similar to waitFor in useCalculationData, waits for all properties to be
   * truthy before fetching data from global settings store.
   */
  waitFor?: ReadonlyArray<any>,
  /**
   * Function that generates a search param and add it to the request url
   */
  searchParam: (nextSettings: GlobalSettings) => object = () => ({})
): [
  T,
  {
    loading: boolean
    /**
     * Simpler updater function to update the stored value, if the value is an object
     * it will be merged. Keys not present in `defaultSettings` will be discarded.
     */
    updateGlobalSettings(settings: Partial<T>): void
    /**
     * Creates a function to update a value at a specfied key.
     */
    updateGlobalSettingsAtKey<K extends keyof T>(key: K): (value: T[K]) => void
    /**
     * An object containing keys matching the shape of `defaultSettings` (if it is an object)
     * Values are "updater" methods which can be passed directly to `onChange` handlers.
     */
    updateGlobalSettingsKeys: {
      [K in keyof T]: (value: T[K] | React.SyntheticEvent) => void
    }
  }
] {
  // Create instance of getter owned by this component
  const selector = useMemo(() => {
    return createBoundGetter(id)
  }, [id])

  // Passing `shallowEqual` here to prevent re-renders on each redux update
  // since the getter returns a new object each time it is called
  const state = useSelector((state) => selector(state), shallowEqual)

  const dispatch = useDispatch()

  const waitingForDeps = !every(waitFor || [])

  useEffect(() => {
    if (!id) {
      return undefined
    }

    const requestData = () => {
      if (waitingForDeps || state.loading || state.globalSettings) {
        return
      }

      dispatch(actions.requestGlobalSettings(id, defaultSettings))
    }

    requestData()
  }, [id, waitingForDeps])

  const emulator = useEmulation()

  const updateGlobalSettings = useCallback(
    (settings: GlobalSettings) => {
      const nextSettings = mergeSettings(state.globalSettings, settings)
      dispatch(actions.setGlobalSettings(id, nextSettings))

      // do not save selected view to global settings for emulator users
      if (!emulator) {
        dispatch(
          actions.updateGlobalSettings(
            id,
            nextSettings,
            searchParam(nextSettings)
          )
        )
      }
    },
    [id, state]
  )

  const updateGlobalSettingsAtKey = useCallback(
    (key: keyof T) => (value: any) => {
      let nextValue = value
      const settings = state.globalSettings as T
      // Toggles boolean values when the existing value is a boolean
      // and the incoming is not (might be an event from onClick)
      if (
        settings &&
        typeof settings[key] === 'boolean' &&
        typeof value !== 'boolean'
      ) {
        nextValue = !settings[key]
      }

      return updateGlobalSettings({[key]: nextValue})
    },
    [updateGlobalSettings, defaultSettings]
  ) as any

  const updateGlobalSettingsKeys = useMemo(() => {
    if (Array.isArray(defaultSettings)) {
      return {}
    }

    if (!isPlainObject(defaultSettings)) {
      return {}
    }

    const updaters = {} as {[K in keyof T]: (value: T[K]) => void}
    for (const key of Object.keys(defaultSettings) as ReadonlyArray<keyof T>) {
      updaters[key] = updateGlobalSettingsAtKey(key)
    }

    return updaters
  }, [updateGlobalSettingsAtKey]) as any

  useDebugValue(state?.globalSettings)

  return [
    state?.globalSettings as T,
    {
      updateGlobalSettings,
      updateGlobalSettingsAtKey,
      updateGlobalSettingsKeys,
      loading: state?.loading
    }
  ]
}
