import {ITemplate} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class ReportTemplateEndpoints extends BaseRestEndpoints {
  static basePath = '/reporting/templates'

  static list() {
    return super._list<IApiListResponse<ITemplate>>()
  }
}
