import {ApiParam} from 'fairlight'

import {ICalculatedFeeDetail} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {IDateRange} from '@d1g1t/lib/date-range'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface ICalculatedFeesRequestBody {
  pagination?: IPaginationOptions
  options?: {date_range: IDateRange}
}
export class CalculatedFeesEndpoints extends BaseRestEndpoints {
  static basePath = '/fees/calculated-fees'

  static list() {
    return super._list<IApiListResponse<ICalculatedFeeDetail>>()
  }

  static chart(body: ICalculatedFeesRequestBody) {
    return super._chart(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static findById(id: ApiParam) {
    return super._findById<ICalculatedFeeDetail>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<ICalculatedFeeDetail>) {
    return super._partialUpdate<ICalculatedFeeDetail>(id, body)
  }
}
