import React, {forwardRef} from 'react'

import MuiOutlinedInput, {OutlinedInputProps} from '@material-ui/core/OutlinedInput'

import {classNames} from '@d1g1t/lib/class-names'

import {useControlState} from '@d1g1t/shared/components/control-state'

import * as css from './styles.scss'

export interface IOutlinedInputExtraProps {
  /**
   * Applies white background style
   */
  whiteBackground?: boolean
  /**
   * Applies a gray background style
   */
  greyBackground?: boolean
  /**
   * Hides border when not focused
   */
  noBorder?: boolean
  /**
   * Border becomes lighter gray
   */
  mutedBorder?: boolean
  /**
   * Border width becomes 2px
   */
  thickBorder?: boolean
  /**
   * Adds additional border-radius to give select a "pill" shape
   */
  rounded?: boolean
  /**
   * Applies 6px margin left
   */
  spaceLeft?: boolean
  /**
   * Applies 6px margin right
   */
  spaceRight?: boolean
  /**
   * Font weight of 600
   */
  boldText?: boolean
  /**
   * Sets font size to 16 px
   */
  largerFontSize?: boolean
  /**
   * Align text to the right
   */
  textAlignRight?: boolean
  /**
   * Colour positive and negative numbers
   */
  deltaColours?: boolean
  /**
   * Smaller height for input field to fit in tables
   */
  smallHeight?: boolean
  /**
   * If `true` will use compact mode style for inputs used in standard table. Currently used for standard table compact mode.
   */
  isCompactMode?: boolean
}

export interface IOutlinedInputProps
  extends Omit<OutlinedInputProps, 'labelWidth'>,
    IOutlinedInputExtraProps {
  /**
   * Label width (defaults to 0)
   */
  labelWidth?: number
  /**
   * If `true` cursor will become a pointer on hover.
   * Should be passed when `OutlinedInput` is externally controlled.
   */
  showPointerOnHover?: boolean
  /**
   * If `true` ensures no padding on the input
   */
  noInputPadding?: boolean
}

export const OutlinedInput: React.FC<IOutlinedInputProps> = forwardRef(
  (
    {
      rounded,
      whiteBackground,
      greyBackground,
      noBorder,
      mutedBorder,
      thickBorder,
      spaceLeft,
      spaceRight,
      boldText,
      largerFontSize,
      textAlignRight,
      deltaColours,
      smallHeight,
      isCompactMode,
      showPointerOnHover,
      labelWidth = 0,
      noInputPadding,
      ...props
    },
    ref
  ) => {
    const controlState = useControlState()
    return (
      <MuiOutlinedInput
        ref={ref}
        classes={{
          root: classNames(css.root, {
            [css.rounded]: rounded,
            [css.largerFontSize]: largerFontSize,
            [css.noBorder]: noBorder,
            [css.mutedBorder]: mutedBorder,
            [css.thickBorder]: thickBorder,
            [css.spaceLeft]: spaceLeft,
            [css.spaceRight]: spaceRight,
            [css.boldText]: boldText,
            [css.showPointerOnHover]: showPointerOnHover,
            [css.smallHeight]: !isCompactMode && smallHeight,
            [css.compactMode]: isCompactMode,
            // @ts-ignore
            [css.deltaPositiveColour]: deltaColours && props.value > 0,
            // @ts-ignore
            [css.deltaNegativeColour]: deltaColours && props.value < 0
          }),
          input: classNames(css.input, {
            [css.whiteBackground]: whiteBackground,
            [css.greyBackground]: greyBackground,
            [css.textAlignRight]: textAlignRight,
            [css.showPointerOnHover]: showPointerOnHover,
            [css.noPadding]: noInputPadding
          })
        }}
        disabled={controlState.loading || props.disabled}
        labelWidth={labelWidth}
        {...props}
        notched
      />
    )
  }
)
