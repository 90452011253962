import {ApiParam} from 'fairlight'

import {IRebalancingRule} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

type ICreateRebalancingRule = Omit<IRebalancingRule, 'id'>

export class RebalancingRuleEndpoints extends BaseRestEndpoints {
  static basePath = '/rebalancing/rules'

  static list() {
    return super._list<IApiListResponse<IRebalancingRule>>()
  }

  static create(body: ICreateRebalancingRule) {
    return super._create<IRebalancingRule>(body)
  }

  static partialUpdate(id: ApiParam, body: Partial<IRebalancingRule>) {
    return super._partialUpdate<IRebalancingRule>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }
}
