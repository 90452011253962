import React from 'react'

import {FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'

import {Flex} from '@d1g1t/shared/components/flex'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {Button} from '@d1g1t/shared/components/mui/button/index'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {ButtonLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H1, P} from '@d1g1t/shared/components/typography'

enum RESET_PASSWORD_FIELD_NAMES {
  password1 = 'password1',
  password2 = 'password2'
}

const VALIDATION_SCHEMA = Yup.object({
  [RESET_PASSWORD_FIELD_NAMES.password1]: Yup.string()
    .required()
    .label('Password'),
  [RESET_PASSWORD_FIELD_NAMES.password2]: Yup.string()
    .required()
    .label('Confirm Password')
    .oneOf(
      [Yup.ref(RESET_PASSWORD_FIELD_NAMES.password1)],
      'Passwords do not match'
    )
})

/**
 * Input matching new passwords to set a new password.
 * Emailed reset password link lands here.
 */
export const ResetPasswordForm: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      [RESET_PASSWORD_FIELD_NAMES.password1]: '',
      [RESET_PASSWORD_FIELD_NAMES.password2]: ''
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: null
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <H1 alignCenter>Resetting your password</H1>
        <P alignCenter>Please enter your new password.</P>
        <Spacer lg />
        <Grid container>
          <Grid item sm={2} />
          <Grid item sm={8}>
            <OutlinedInputField
              name={RESET_PASSWORD_FIELD_NAMES.password1}
              label='Password'
              outlinedInputProps={{
                type: 'password'
              }}
              data-testid='password-field'
            />
            <Spacer xs />
            <OutlinedInputField
              name={RESET_PASSWORD_FIELD_NAMES.password2}
              label='Confirm Password'
              outlinedInputProps={{
                type: 'password'
              }}
              data-testid='confirm-password-field'
            />
            <Spacer xxs />
            <Flex justifySpaceBetween>
              <ButtonLink to='login'>Back to Sign In</ButtonLink>
              <Button
                primary
                contained
                type='submit'
                disabled={formik.isSubmitting}
                data-testid='submit-button'
              >
                Set New Password
              </Button>
            </Flex>
          </Grid>
          <Grid item sm={2} />
        </Grid>
      </form>
    </FormikProvider>
  )
}
