import {ApiParam} from 'fairlight'

import {
  ICheckSecurityInput,
  IPortfolio,
  IPortfolioNode
} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class PortfolioEndpoints extends BaseRestEndpoints {
  static basePath = '/portfolios'

  static list() {
    return super._list<IApiListResponse<IPortfolio>>()
  }

  /**
   * GET list of rebalance dates for security model
   */
  static nodes(id: ApiParam) {
    return super._get<IPortfolioNode>(`/${id}/nodes`)
  }

  static findById(id: ApiParam) {
    return super._findById<IPortfolio>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IPortfolio>) {
    return super._partialUpdate<IPortfolio>(id, body)
  }

  static create(body: IPortfolio) {
    return super._create<IPortfolio>(body)
  }

  static destroy(id: string) {
    return super._destroy(id)
  }

  static checkSecurity(id: ApiParam, body: DeepPartial<ICheckSecurityInput>) {
    return super._post(`/${id}/check-security`, {
      body
    })
  }
}
