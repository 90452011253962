import {ApiParam} from 'fairlight'

import {
  IDataset,
  IDatasetEntityMemberCreateNested,
  IDatasetEntityMemberRemove,
  IDatasetEntityMemberViewNested
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class DatasetEndpoints extends BaseRestEndpoints {
  static basePath = '/datasets/datasets'

  static list() {
    return super._list<IApiListResponse<IDataset>>()
  }

  static create(body: IDataset) {
    return super._create<IDataset>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IDataset>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IDataset>) {
    return super._partialUpdate<IDataset>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static getMembers(id: ApiParam) {
    return super._get<IApiListResponse<IDatasetEntityMemberViewNested>>(
      `/${id}/members`
    )
  }

  static postMembers(id: ApiParam, body: IDatasetEntityMemberCreateNested[]) {
    return super._post<IDatasetEntityMemberCreateNested[]>(`/${id}/members`, {
      body
    })
  }

  static removeMembers(id: ApiParam, body: IDatasetEntityMemberRemove) {
    return super._post<{deleted: number}>(`/${id}/remove-members`, {body})
  }

  static chart(body: {pagination: IPaginationOptions}) {
    return super._chart(body)
  }
}
