import {useApiQuery, UseApiQueryActions} from 'fairlight'

import {
  IApiListResponse,
  UserAccountExternalProfileEndpoints
} from '@d1g1t/api/endpoints'
import {IExternalProfile} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

/**
 * Gets the external profile for the currently logged in
 * user (if they have one), and exposes the external profile actions.
 */
export function useExternalProfile(): [
  IExternalProfile,
  string,
  UseApiQueryActions<IApiListResponse<IExternalProfile>>
] {
  const [userProfile] = useUserProfile()

  const [externalProfiles, externalProfileActions] = useApiQuery(
    userProfile?.user && UserAccountExternalProfileEndpoints.list(),
    {fetchPolicy: 'cache-first'}
  )

  const externalProfile = externalProfiles.data?.results.find(
    (result) => result.user.url === userProfile?.user.url
  )

  return [
    externalProfile,
    extractIdFromUrl(externalProfile?.url),
    externalProfileActions
  ]
}
