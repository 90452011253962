import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import produce from 'immer'

import {
  CHART_VALUE_TYPE,
  EXTERNALPROFILE_DATE_FORMAT,
  IChartTableOptions
} from '@d1g1t/api/models'

import {SUPPORTED_CURRENCY} from '@d1g1t/lib/currency'
import {
  Formatter,
  IFormatterOptions
} from '@d1g1t/lib/formatters/base-formatter'
import {BooleanFormatter} from '@d1g1t/lib/formatters/boolean-formatter'
import {DateFormatter} from '@d1g1t/lib/formatters/date-formatter'
import {
  CurrencyDisplay,
  INumberFormatOptions,
  NumberFormatter
} from '@d1g1t/lib/formatters/number-formatter'
import {StringFormatter} from '@d1g1t/lib/formatters/string-formatter'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {useCalculationSettings} from '../calculation-settings'
import {useExternalProfileLocalizationPreferences} from '../localization-settings/hook'

interface IUseNumberFormatterOptions
  extends Omit<INumberFormatOptions, 'currency'>,
    IFormatterOptions {
  currency?: SUPPORTED_CURRENCY
  /**
   * How to display the currency. This is passed directly to `Intl.NumberFormat`
   *
   * One of: `narrowSymbol` or `code`
   *
   * https://github.com/tc39/proposal-unified-intl-numberformat/blob/master/README.md#iv-spec-cleanup
   */
  currencyDisplay?: CurrencyDisplay
}

/**
 * Given number formatter options, returns an instance of `NumberFormatter`
 * with context-specific options automatically applied.
 *
 * Currency will automatically pull from calculation settings,
 * but can be manually overridden.
 *
 * Usually, rendering a `<FormattedNumber />` directly is easier.
 *
 * @see `NumberFormatter` for usage
 */
export function useNumberFormatter(options: IUseNumberFormatterOptions = {}) {
  const {displayNullsAsDashes, currency, locale} = useFormatterOptionsContext()

  return useMemo(() => {
    const derivedOptions: INumberFormatOptions & IFormatterOptions = produce(
      options,
      (draft): any => {
        draft.locale = locale // Pass the current user's locale
        if (
          draft.style === 'currency' &&
          !Object.prototype.hasOwnProperty.call(draft, 'currency')
        ) {
          draft.currency = currency
        }

        if (
          !Object.prototype.hasOwnProperty.call(draft, 'displayNullsAsDashes')
        ) {
          draft.displayNullsAsDashes = displayNullsAsDashes
        }
      }
    )

    return new NumberFormatter(derivedOptions)
  }, [options, displayNullsAsDashes, currency, locale])
}

/**
 * Given number formatter options, formats and renders a number
 * with context-specific options automatically applied.
 *
 * This component is the recommended way to format numbers in a component's render function.
 *
 * @see `NumberFormatter` for usage
 */
export const FormattedNumber = createFormattedValueComponent(
  useNumberFormatter,
  'FormattedNumber'
)

/**
 * Given date formatter options, returns an instance of `DateFormatter`
 * with context-specific options automatically applied.
 *
 * Usually, rendering a `<FormattedDate />` directly is easier.
 *
 * @see `DateFormatter` for usage
 */
export const useDateFormatter = createUseFormatter(DateFormatter)

/**
 * Given date formatter options, formats and renders a date
 * with context-specific options automatically applied.
 *
 * This is the recommended way to format dates in a component's render function.
 *
 * @see `DateFormatter` for usage
 */
export const FormattedDate = createFormattedValueComponent(
  useDateFormatter,
  'FormattedDate'
)

/**
 * Given boolean formatter options, returns an instance of `BooleanFormatter`
 * with context-specific options automatically applied.
 *
 * Usually, rendering a `<FormattedBoolean />` directly is easier.
 *
 * @see `BooleanFormatter` for usage
 */
export const useBooleanFormatter = createUseFormatter(BooleanFormatter)

/**
 * Given boolean formatter options, formats and renders a boolean
 * with context-specific options automatically applied.
 *
 * This is the recommended way to format booleans in a component's render function.
 *
 * @see `BooleanFormatter` for usage
 */
export const FormattedBoolean = createFormattedValueComponent(
  useBooleanFormatter,
  'FormattedBoolean'
)

/**
 * Given string formatter options, returns an instance of `StringFormatter`
 * with context-specific options automatically applied.
 *
 * Usually, rendering a `<FormattedString />` directly is easier.
 *
 * @see `StringFormatter` for usage
 */
export const useStringFormatter = createUseFormatter(StringFormatter)

/**
 * Given string formatter options, formats and renders a string
 * with context-specific options automatically applied.
 *
 * This is the recommended way to format strings in a component's render function.
 *
 * @see `StringFormatter` for usage
 */
export const FormattedString = createFormattedValueComponent(
  useStringFormatter,
  'FormattedString'
)

function useFormatterOptionsContext(): {
  displayNullsAsDashes: boolean
  currency: SUPPORTED_CURRENCY
  /**
   * User's current locale
   */
  locale: string
  dateFormat: EXTERNALPROFILE_DATE_FORMAT
} {
  const {i18n} = useTranslation()
  const {firmConfiguration} = useFirmConfiguration()
  const [settings] = useCalculationSettings()
  const [profileSettings] = useExternalProfileLocalizationPreferences()

  return useMemo(() => {
    return {
      locale: i18n?.language ?? undefined,
      displayNullsAsDashes: firmConfiguration.data?.displayNullsAsDashes,
      currency:
        (settings?.currency as SUPPORTED_CURRENCY) ||
        (firmConfiguration.data?.baseCurrency &&
          (extractIdFromUrl(
            firmConfiguration.data.baseCurrency
          ) as SUPPORTED_CURRENCY)) ||
        SUPPORTED_CURRENCY.USD, // last-resort, since Intl.NumberFormat will throw if not present
      dateFormat: profileSettings.dateFormat
    }
  }, [
    firmConfiguration.data,
    settings?.currency,
    i18n?.language,
    profileSettings.dateFormat
  ])
}

interface IFormatterContext {
  displayNullsAsDashes: boolean
  locale: string
  currency: SUPPORTED_CURRENCY
  dateFormat: EXTERNALPROFILE_DATE_FORMAT
}

interface IChartValueFormatterInputs {
  valueType: string
  valueOptions: IChartTableOptions
  additionalOptions: IAdditionalChartValueFormatterOptions
}

const chartValueFormatterCache = new Map<
  string,
  ReturnType<typeof getChartValueFormatter>
>()

function chartValueFormatterCacheKey(
  context: IFormatterContext,
  {
    valueType,
    valueOptions = EMPTY_OBJECT,
    additionalOptions = EMPTY_OBJECT
  }: IChartValueFormatterInputs
) {
  const key = [
    context.displayNullsAsDashes,
    context.dateFormat,
    context.currency,
    context.locale,
    valueType,
    valueOptions.delta,
    valueOptions.decimals,
    valueOptions.currency,
    additionalOptions.allowZero,
    additionalOptions.currencyDisplay
  ]

  return key.join(':')
}

/**
 * Create a formatter given the context and inputs.
 *
 * @remarks `formatterCacheKey` must be updated to reflect use of any new
 * properties in this method.
 */
function getChartValueFormatter(
  context: IFormatterContext,
  {
    valueType,
    valueOptions = EMPTY_OBJECT,
    additionalOptions = EMPTY_OBJECT
  }: IChartValueFormatterInputs
) {
  const commonOptions = {
    displayNullsAsDashes: context.displayNullsAsDashes,
    locale: context.locale,
    dateFormat: context.dateFormat
  }

  const commonNumberOptions = {
    allowZero: additionalOptions.allowZero,
    delta: valueOptions?.delta
  }

  switch (valueType) {
    case CHART_VALUE_TYPE.INTEGER:
      return new NumberFormatter({
        ...commonOptions,
        ...commonNumberOptions,
        decimalPlaces: valueOptions.decimals
      })
    case CHART_VALUE_TYPE.DECIMAL: {
      let options: INumberFormatOptions & IFormatterOptions = {
        ...commonOptions,
        ...commonNumberOptions,
        decimalPlaces: valueOptions.decimals ?? 2
      }
      if (valueOptions.currency || additionalOptions.currency) {
        options = {
          ...options,
          style: 'currency',
          currencyDisplay: additionalOptions.currencyDisplay || 'code',
          currency:
            (additionalOptions.currency as SUPPORTED_CURRENCY) ||
            (valueOptions.currency as SUPPORTED_CURRENCY)
        }
      }

      return new NumberFormatter(options)
    }

    case CHART_VALUE_TYPE.MONEY_ABBREVIATED:
      return new NumberFormatter({
        ...commonOptions,
        style: 'currency',
        ...commonNumberOptions,
        decimalPlaces: valueOptions?.decimals ?? 1,
        abbreviate: true,
        currencyDisplay: additionalOptions.currencyDisplay,
        currency:
          (valueOptions.currency as SUPPORTED_CURRENCY) || context.currency
      })
    case CHART_VALUE_TYPE.DECIMAL_LONG:
      return new NumberFormatter({
        ...commonOptions,
        ...commonNumberOptions,
        decimalPlaces: valueOptions?.decimals ?? 4
      })
    case CHART_VALUE_TYPE.DECIMAL_LONG_LONG:
      return new NumberFormatter({
        ...commonOptions,
        ...commonNumberOptions,
        decimalPlaces: valueOptions?.decimals ?? 6
      })
    case CHART_VALUE_TYPE.PERCENTAGE:
      return new NumberFormatter({
        ...commonOptions,
        style: 'percent',
        ...commonNumberOptions,
        allowZero: true,
        decimalPlaces: valueOptions?.decimals ?? 1
      })
    case CHART_VALUE_TYPE.BASIS_POINTS:
      return new NumberFormatter({
        ...commonOptions,
        style: 'basis-points',
        ...commonNumberOptions,
        decimalPlaces: valueOptions?.decimals
      })
    case CHART_VALUE_TYPE.DATE:
      return new DateFormatter({...commonOptions})
    case CHART_VALUE_TYPE.DATETIME:
      return new DateFormatter({
        ...commonOptions,
        style: 'datetime'
      })
    case CHART_VALUE_TYPE.BOOLEAN:
      return new BooleanFormatter({...commonOptions})
    default:
      return new StringFormatter({...commonOptions})
  }
}

/**
 * Get the formatter from the cache, or create it and store.
 */
function getChartValueCachedFormatter(
  context: IFormatterContext,
  inputs: IChartValueFormatterInputs
) {
  const cacheKey = chartValueFormatterCacheKey(context, inputs)
  if (chartValueFormatterCache.has(cacheKey)) {
    return chartValueFormatterCache.get(cacheKey)
  }

  const formatter = getChartValueFormatter(context, inputs)
  chartValueFormatterCache.set(cacheKey, formatter)

  return formatter
}

/**
 * Returns a method which returns a cached formatter, this is a performance
 * optimization needed in `StandardTable` to avoid creation and cleanup of
 * new formatters for every cell.
 */
export function useChartValueCachedFormatter() {
  const formatterContext = useFormatterOptionsContext()

  return useCallback(
    (inputs: IChartValueFormatterInputs) =>
      getChartValueCachedFormatter(formatterContext, inputs),
    [formatterContext]
  )
}

/**
 * Returns a useFormatter hook for the given formatter.
 *
 * The hook returned by this factory is intended to reduce boilerplate
 * so that the user doesn't need to `useFormatterOptionsContext` and pass
 * directly to the formatter options each time.
 */
function createUseFormatter<
  TFormatterOptions,
  TFormatter extends Formatter
>(FormatterClass: {new (options: TFormatterOptions): TFormatter}) {
  return (options?: TFormatterOptions) => {
    const {displayNullsAsDashes} = useFormatterOptionsContext()

    return useMemo(() => {
      return new FormatterClass({
        displayNullsAsDashes,
        ...options
      })
    }, [
      // stringifying the value here because we can guarantee that options
      // will be a simple, shallow object
      JSON.stringify(options),
      displayNullsAsDashes
    ])
  }
}

/**
 * Returns a `Formatted____` component for the given formatter.
 *
 * This component is used to reduce boilerplate so the user can
 * render the formatted value using a component, rather than having
 * to use the `use____` hook
 */
function createFormattedValueComponent<
  TFormatterArgs,
  TFormatter extends Formatter<{}>
>(useFormatter: (options: TFormatterArgs) => TFormatter, displayName: string) {
  const FormattedValue: React.FC<
    {
      value: any
    } & TFormatterArgs
  > = ({value, ...options}) => {
    const formatter = useFormatter(options as TFormatterArgs)
    return useMemo(() => formatter.formatJSX(value), [value, formatter])
  }

  FormattedValue.displayName = displayName

  return FormattedValue
}

export interface IAdditionalChartValueFormatterOptions {
  currency?: ISettings['currency']
  allowZero?: boolean
  currencyDisplay?: CurrencyDisplay
}

/**
 * Given a chart value type and chart value options,
 * returns the correct Formatter instance to use for displaying the value
 * (DO NOT USE in a standard table cell)
 */
export function useChartValueFormatter(
  valueType: string,
  valueOptions?: IChartTableOptions,
  additionalOptions?: IAdditionalChartValueFormatterOptions
): Formatter {
  const formatterContext = useFormatterOptionsContext()
  return getChartValueCachedFormatter(formatterContext, {
    valueType,
    valueOptions,
    additionalOptions
  })
}

interface IFormattedChartValueProps {
  value: any
  valueType: string
  valueOptions?: IChartTableOptions
  additionalOptions?: IAdditionalChartValueFormatterOptions
}

/**
 * Convenience component to format a chart value based on its
 * value type and options
 */
export const FormattedChartValue: React.FC<IFormattedChartValueProps> = (
  props
) => {
  const formatter = useChartValueFormatter(
    props.valueType,
    props.valueOptions,
    props.additionalOptions
  )

  return formatter.formatJSX(props.value)
}
