import {ApiParam} from 'fairlight'

import {
  IAntiMoneyLaundering,
  IClient,
  IClientCategory,
  IClientSegment,
  IClientTenure,
  IHousehold,
  IInvestmentHorizon,
  IInvestmentMandateGoal,
  IInvestorKnowledge,
  IInvestorProfile,
  IMandateSimple,
  IRiskCapacity,
  IRiskTolerance,
  ITeam
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export interface IUpdateInvestorProfile
  extends Omit<
    Partial<IInvestorProfile>,
    'riskCapacity' | 'antiMoneyLaundering' | 'investorKnowledge' | 'url'
  > {
  riskCapacity?: Required<Pick<IRiskCapacity, 'url'>>
  antiMoneyLaundering?: Required<Pick<IAntiMoneyLaundering, 'url'>>
  investorKnowledge?: Required<Pick<IInvestorKnowledge, 'url'>>
  url: string // required, url to the client whose investor profile is updated
}
export interface IUpdateClient
  extends Omit<
    Partial<IClient>,
    | 'household'
    | 'team'
    | 'category'
    | 'tenure'
    | 'segment'
    | 'investorProfile'
    | 'investmentMandateGoal'
    | 'investmentHorizon'
    | 'riskTolerance'
    | 'riskProfile'
  > {
  household?: Required<Pick<IHousehold, 'url'>>
  team?: Required<Pick<ITeam, 'url'>>
  category?: Required<Pick<IClientCategory, 'url'>>
  tenure?: Required<Pick<IClientTenure, 'url'>>
  segment?: Required<Pick<IClientSegment, 'url'>>
  investorProfile?: IUpdateInvestorProfile
  investmentMandateGoal?: Required<Pick<IInvestmentMandateGoal, 'url'>>
  investmentHorizon?: Required<Pick<IInvestmentHorizon, 'url'>>
  riskTolerance?: Required<Pick<IRiskTolerance, 'url'>>
  riskProfile?: Required<Pick<IMandateSimple, 'url'>>
}

export class PeopleClientsEndpoints extends BaseRestEndpoints {
  static basePath = '/people/clients'

  static findById(id: ApiParam) {
    return super._findById<IClient>(id)
  }

  static partialUpdate(id: ApiParam, body: IUpdateClient) {
    return super._partialUpdate<IClient>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }
}
