import {ISelectedEntity} from '@d1g1t/shared/containers/select-entities'

import {CONSOLIDATED_PORTFOLIO_ENTITY_DETAILS_OR_SEARCH_RESULT} from '../../constants'

/**
 * Consolidated option uses multiple accounts/selected for selection. other options ALWAYS use `selection.selected.accounts`.
 * We can determine when the account is consolidated option by checking if more than one selected account exists or if
 * `selection.selected.accounts` has no accounts in it
 *
 * @param selected - The current `ISelectedEntity` some the SelectEntities state
 */
export function isConsolidatedOptionSelected(
  selected: ISelectedEntity[]
): boolean {
  return (
    !selected?.[0].entityId ||
    selected?.[0].entityId ===
      CONSOLIDATED_PORTFOLIO_ENTITY_DETAILS_OR_SEARCH_RESULT.entityId
  )
}
