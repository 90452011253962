import {IMetric, METRICGROUP_RELATED_MODEL} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export interface IMetricResponse {
  available: IMetricOption[]
  contributionDimensions: IContributionDimention[]
  assetClassOptions: IContributionDimentionOption[]
  currencyOptions: IContributionDimentionOption[]
  strategyOptions: IContributionDimentionOption[]
}

export interface IContributionDimention {
  fieldName: string
  id: number
  name: string
}

export interface IContributionDimentionOption {
  id: number
  name: string
  slug: string
  url: string
}

export interface IMetricOption {
  expandableColumn?: boolean
  default?: boolean
  key: string // slug
  name: string
  children?: IMetricOption[]
  metrics?: IMetricLeaf[]
  relatedModel?: METRICGROUP_RELATED_MODEL
  displayPortfolios?: boolean
  displayTransactionAsOfDate?: boolean
  ignorePagePeriod?: boolean
}

export interface IMetricLeaf {
  columnTitle: string
  description: string
  default?: boolean
  slug: string
  name: string
}

export class MetricEndpoints extends BaseRestEndpoints {
  static basePath = '/metric'

  static tree() {
    return super._get<IMetricResponse>('/tree')
  }

  static findById(id: string) {
    return super._findById<IMetric>(id)
  }

  static list(query: {limit?: number} = {}) {
    return super._list<IApiListResponse<IMetric>>(query)
  }
}
