import {ApiParam} from 'fairlight'

import {IAccountType} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AccountTypeEndpoints extends BaseRestEndpoints {
  static basePath = '/account-types'

  static list() {
    return super._list<IApiListResponse<IAccountType>>()
  }

  static findById(id: ApiParam) {
    return super._findById<IAccountType>(id)
  }
}
