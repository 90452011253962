import {useContext} from 'react'

import {CalculationOptionsOverrideContext} from './context'

/**
 * @returns value of the CalculationOptionsOverride context.
 */
export function useCalculationOptionsOverride() {
  return useContext(CalculationOptionsOverrideContext)
}
