import React, {useMemo, useState} from 'react'

import {IFirmConfiguration} from '@d1g1t/api/models'

import {generateGoogleMapsLink} from '@d1g1t/lib/url'

import {Flex} from '@d1g1t/shared/components/flex'
import {H2, Text} from '@d1g1t/shared/components/typography'

import {FOOTER_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

import {useStyles} from '../../use-style'

import * as css from '../../style.scss'

const DEFAULT_OFFICES_SHOW_NUMBER = 5

export const OurOffices: React.FC<
  Pick<IFirmConfiguration, 'officeAddresses'>
> = ({officeAddresses}) => {
  const classes = useStyles()
  const [showOfficesNumber, setShowOfficesNumber] = useState(
    DEFAULT_OFFICES_SHOW_NUMBER
  )
  const totalNumberOfOffices = officeAddresses?.length

  const showAllLocationsLabel = useMemo(() => {
    if (showOfficesNumber > DEFAULT_OFFICES_SHOW_NUMBER) {
      return 'Show less'
    }
    return `Show all ${totalNumberOfOffices} locations`
  }, [showOfficesNumber, totalNumberOfOffices])

  const onShowAllClick = () => {
    if (showOfficesNumber > DEFAULT_OFFICES_SHOW_NUMBER) {
      setShowOfficesNumber(DEFAULT_OFFICES_SHOW_NUMBER)
      return
    }
    setShowOfficesNumber(totalNumberOfOffices)
  }

  return (
    <Flex column className={css.footerSection}>
      {totalNumberOfOffices > 0 && (
        <H2 bold className={css.footerSectionLabel}>
          {FOOTER_TRANSLATION_KEYS.OUR_OFFICES}
        </H2>
      )}
      <Flex column>
        {officeAddresses
          ?.slice(0, showOfficesNumber)
          .map(({address}, index) => (
            <Flex
              column
              wrap
              key={address.url}
              data-testid={`footer-address-${index}`}
              className={css.footerSectionItem}
            >
              {address.city && (
                <Text
                  bold
                  className={classes.footerLink}
                  onClick={() => window.open(generateGoogleMapsLink(address))}
                >
                  {address.city}
                </Text>
              )}
              {address.address1 && <Text>{address.address1}</Text>}
              {address.address2 && <Text>{address.address2}</Text>}
              {(address.stateOrProvince || address.zipOrPostalCode) && (
                <Text>
                  {address.city}, {address.stateOrProvince}{' '}
                  {address.zipOrPostalCode}
                </Text>
              )}
            </Flex>
          ))}
        {totalNumberOfOffices > 5 && (
          <Text
            className={classes.footerShowAllOffices}
            onClick={onShowAllClick}
          >
            {showAllLocationsLabel}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
