import {
  EXTERNALPROFILE_DATE_FORMAT,
  EXTERNALPROFILE_PREFERRED_LANGUAGE
} from '@d1g1t/api/models'

/**
 * Supported languages and date formats for static front-end translations.
 * Back-end should support these too, but back-end adds region in
 * addition to these language/date codes.
 */
export enum AVAILABLE_LANGUAGES {
  ENGLISH = 'en',
  FRENCH = 'fr'
}

/**
 * Map the FRON lang codes into Back-end support lang codes.
 */
export const LANGUAGES_TO_PS_FORMAT = {
  [AVAILABLE_LANGUAGES.ENGLISH]: EXTERNALPROFILE_PREFERRED_LANGUAGE.ENGLISH,
  [AVAILABLE_LANGUAGES.FRENCH]: EXTERNALPROFILE_PREFERRED_LANGUAGE.FRENCH
}

export interface ILocalizationSettings {
  language: EXTERNALPROFILE_PREFERRED_LANGUAGE
  dateFormat: EXTERNALPROFILE_DATE_FORMAT
}
