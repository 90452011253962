import {IClientGeneration} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class PeopleClientGenerationEndpoints extends BaseRestEndpoints {
  static basePath = '/people/client-generation'

  static list() {
    return super._list<IApiListResponse<IClientGeneration>>()
  }
}
