import {IAssetSubCategory} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AssetSubCategoryEndpoints extends BaseRestEndpoints {
  static basePath = '/asset-subcategories'

  static list() {
    return super._list<IApiListResponse<IAssetSubCategory>>()
  }
}
