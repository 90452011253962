import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {Span} from '@d1g1t/shared/components/typography'

import {useStyles} from './use-styles'

import * as css from './style.scss'

export interface ITextTagProps
  extends Pick<
    React.HTMLAttributes<HTMLSpanElement>,
    'className' | 'style' | 'onClick'
  > {
  /**
   * The text to display in the tag.
   */
  title: string
  /**
   * The reference value for the tag
   * @remarks At this point only necessary for the SelectTextTag component.
   */
  value?: string
  /**
   * Shows the TextTag component in disabled style.
   *
   * @remarks Allows the tag to be clicked. Only the style is affected.
   */
  disabledStyle?: boolean
}

/**
 * Tag text component. Used in the investor app to label documents, for example.
 */
export const TextTag: React.FC<ITextTagProps> = (props) => {
  const classes = useStyles()
  return (
    <Span
      data-testid={`select-taxt-tag-${props.value}`}
      className={classNames(classes.tagBase, css.tagBase, props.className, {
        [css.hoverPointer]: !!props.onClick,
        [css.disabledStyle]: props.disabledStyle
      })}
      style={props.style}
      onClick={props.onClick ? (e) => props.onClick(e) : undefined}
    >
      {props.title}
    </Span>
  )
}
