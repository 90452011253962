import React from 'react'
import {matchPath, useLocation} from 'react-router-dom'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {RouterLink} from '@d1g1t/shared/components/router-link'
import {Text} from '@d1g1t/shared/components/typography'

import {useInvestorAppMenu} from '../../hook'

import * as css from './style.scss'

/**
 * Investor app's secondary navigation menu.
 * @remarks Conditionally rendered if the ACTIVE primary page has secondary menu items.
 */
export const SecondaryNavigation: React.FC = () => {
  const location = useLocation()

  const investorTopMenuOptions = useInvestorAppMenu()

  for (const menuItem of investorTopMenuOptions) {
    if (
      // show secondary navigation for all tabs under the base path
      // e.g. `documents/<tab-name>` all tab-name of documents will have secondary nav.
      matchPath(
        location.pathname.split('/')[1],
        menuItem.location.split('/')[1]
      ) &&
      menuItem.secondaryMenuItems?.length > 0
    ) {
      return (
        <Flex justifyCenter className={css.tabContainer}>
          {menuItem.secondaryMenuItems.map((secondaryMenuItem, index) => (
            <RouterLink
              key={secondaryMenuItem.location}
              data-testid={secondaryMenuItem.testId}
              noUnderline
              {...secondaryMenuItem}
              to={secondaryMenuItem.location}
              replace={secondaryMenuItem.replace}
              className={classNames({
                [css.activeMenuItem]: !!matchPath(
                  location.pathname.split('/')[2],
                  secondaryMenuItem.location.split('/')[2]
                )
              })}
            >
              <div
                className={classNames(css.tab, {
                  [css.active]: !!matchPath(
                    location.pathname.split('/')[2],
                    secondaryMenuItem.location
                  )
                })}
              >
                <Text semiBold>{secondaryMenuItem.label}</Text>
              </div>
            </RouterLink>
          ))}
        </Flex>
      )
    }
  }

  return null
}
