import {ApiParam} from 'fairlight'

import {ICustodianAccount} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class CustodianAccountEndpoints extends BaseRestEndpoints {
  static basePath = '/custodian-accounts'

  static list(params: {accountEntityId?: string}) {
    return super._list<IApiListResponse<ICustodianAccount>>({
      account_entity_id: params.accountEntityId
    })
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<ICustodianAccount>) {
    return super._partialUpdate<ICustodianAccount>(id, body)
  }

  static findById(id: ApiParam) {
    return super._findById<ICustodianAccount>(id)
  }

  static create(body: ICustodianAccount) {
    return super._create<ICustodianAccount>(body)
  }
}
