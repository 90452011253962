import {ApiParam} from 'fairlight'

import {IRole} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

interface IReorderRequestBody {
  to: number
}

export class RoleEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/roles'

  static list() {
    return super._list<IApiListResponse<IRole>>()
  }

  static create(body: IRole) {
    return super._create<IRole>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static chart() {
    return super._chart({})
  }

  static reorder(id: ApiParam, body: IReorderRequestBody) {
    return super._post<never>(`${super._singlePath(id)}/reorder`, {body})
  }
}
