import {Dictionary} from 'lodash'

export interface IPaginationOptions {
  /**
   * When requesting children, pass the path of the parent element, including
   * the root
   *
   * @example
   * "root" // for root elements
   * "root/a48be376-442d-46f4-943d-290f64bba3e2" // for children of this item
   */
  parentPath: string
  /**
   * Offset where to start cursor, usually should come from `nextOffset`
   * property returned by API
   */
  offset: number
  /**
   * Size of response to fetch
   */

  size: number
  /**
   * Column IDs to sort by, add a "-" in front of the ID to sort in ASC order
   * @example
   * ['-name', 'return_1yr']
   */
  orderBy?: string[]
  /**
   * Dictionary of column IDs to filter by
   * @example
   * ```json
   *  {
   *    name: nameFilter,
   *    return_1yr: returnFilter
   * }
   * ```
   */
  filtering?: Dictionary<ITokenizedSearch>
  /**
   * Full text search
   */
  search?: {
    /**
     * Value to look for
     */
    value: string
    /**
     * Columns to search in
     */
    categories: string[]
  }
}

export interface ITokenizedSearch {
  joinOperator: 'AND' | 'OR'
  format: 'string' | 'numeric' | 'boolean'
  expressions: ITokenizedExpression[]
}

type NumericOperator = 'lt' | 'gt' | 'lteq' | 'gteq' | 'neq' // not equals
type StringOperator = 'exact' | 'isNot' | 'contains' | 'isEmpty' | 'isNotEmpty'
type BooleanOperator = 'is' | 'isEmpty'
type DateOperator = 'eq' | 'lt' | 'gt' | 'gteq' | 'lteq'

export interface ITokenizedExpression {
  operator: NumericOperator | StringOperator | BooleanOperator | DateOperator
  value: string | number | boolean
}

/**
 * Map to convert internal representation of numeric relational expression
 * operators to those used by PS
 */
export const NUMERIC_RELATIONAL_EXPRESSION_MAP: Dictionary<NumericOperator> = {
  '<': 'lt',
  '>': 'gt',
  '<=': 'lteq',
  '>=': 'gteq'
}

/**
 * Map to convert internal representation of numeric relational expression
 * operators to those used by PS
 */
export const DATE_RELATIONAL_EXPRESSION_MAP: Dictionary<DateOperator> = {
  '=': 'eq',
  '<': 'lt',
  '>': 'gt',
  '<=': 'lteq',
  '>=': 'gteq'
}
