import {useEffect, useState} from 'react'

/**
 * Returns the passed value only if it has not changed since the delay time.
 * This can be the dependency for a `useEffect` call to run a "debounced" effect
 *
 * @param value - Often changing value
 *
 * @param delay - milliseconds to wait, 200ms by default. Will pass through value
 * and ignore `setTimeout` if `delay === 0` (this is a hack to reduce amount of work for
 * quick-drawer-filters; should not be used).
 */
export function useDebouncedValue<T>(value: T, delay = 200): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if (!delay) {
      return
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return delay ? debouncedValue : value
}
