import {EXTERNALPROFILE_DATE_FORMAT} from '@d1g1t/api/models'

/**
 * @example 1999-12-31
 * @example 2020-05-10
 */
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const HIGHCHARTS_ISO_DATE_FORMAT = '%Y-%m-%d'
/**
 * @example 31-12-1999
 * @example 10-05-2020
 */
export const DMY_DATE_FORMAT = 'dd-MM-yyyy'
export const HIGHCHARTS_DMY_DATE_FORMAT = '%d-%m-%Y'
/**
 * @example 31-12-1999
 * @example 10-05-2020
 */
export const MDY_DATE_FORMAT = 'MM-dd-yyyy'
export const HIGHCHARTS_MDY_DATE_FORMAT = '%m-%d-%Y'

/**
 * Map the AVAILABLE_DATE_FORMATS date formats to a suitable date-fns formats
 */
export const FNS_DATE_FORMATS = {
  [EXTERNALPROFILE_DATE_FORMAT.MDY]: MDY_DATE_FORMAT,
  [EXTERNALPROFILE_DATE_FORMAT.YMD]: ISO_DATE_FORMAT,
  [EXTERNALPROFILE_DATE_FORMAT.DMY]: DMY_DATE_FORMAT
}

/**
 * Map the AVAILABLE_DATE_FORMATS date formats to a suitable Highcharts formats
 */
export const HIGHCHARTS_DATE_FORMATS = {
  [EXTERNALPROFILE_DATE_FORMAT.MDY]: HIGHCHARTS_MDY_DATE_FORMAT,
  [EXTERNALPROFILE_DATE_FORMAT.YMD]: HIGHCHARTS_ISO_DATE_FORMAT,
  [EXTERNALPROFILE_DATE_FORMAT.DMY]: HIGHCHARTS_DMY_DATE_FORMAT
}

/**
 * Omits the `T` specified in the ISO standard.
 *
 * @example 14:30:59
 * @example 00:01:04
 */
export const ISO_TIME_FORMAT = 'HH:mm:ss'

/**
 * Omits the `T` specified in the ISO standard.
 *
 * @example 1999-12-31 14:30:59
 * @example 2020-05-10 00:01:04
 */
export const ISO_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT} ${ISO_TIME_FORMAT}`

export enum MIME_TYPE {
  jpg = 'image/jpg', // Joint Photographic Group image (JPG)
  jpeg = 'image/jpeg', // Joint Photographic Expert Group image (JPEG)
  gif = 'image/gif', // Graphics Interchange Format (GIF)
  png = 'image/png', // Portable Network Graphics (PNG)
  webp = 'image/webp', // Web Picture format (WEBP)
  tiff = 'image/tiff', // TIFF
  bmp = 'image/bmp', // BMP
  csv = 'text/csv',
  excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
