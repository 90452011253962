import React from 'react'
import {useTranslation} from 'react-i18next'

import {useApiQuery} from 'fairlight'

import AssessmentIcon from '@material-ui/icons/Assessment'
import FolderIcon from '@material-ui/icons/Folder'
import HomeIcon from '@material-ui/icons/Home'
import WorkIcon from '@material-ui/icons/Work'

import {WorkDocsTypesEndpoints} from '@d1g1t/api/endpoints'
import {FIRMCONFIGURATION_DOCUMENT_INTEGRATION_TYPE} from '@d1g1t/api/models'

import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {SECTION_TITLE_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'
import {
  ActivityLocations,
  DocumentLocations,
  DocumentsDmsInvestorLocations,
  MonitorLocations,
  PortfolioLocations
} from '@d1g1t/investor/locations'

import {IInvestorPrimaryMenuItem} from './typings'

/**
 * Returns Top Menu Options for investor app, 'Documents' nav has secondary menu options
 * that are dynamic and coming from the api
 */
export function useInvestorAppMenu(): IInvestorPrimaryMenuItem[] {
  const {firmConfiguration, firmUiConfig} = useFirmConfiguration()
  const investorUIConfig = firmUiConfig.data?.investor

  const {i18n} = useTranslation()
  const selectedLanguage = i18n.language

  // Version v2 implies new 'document management system'
  const isDMS = firmConfiguration?.data?.documentVaultVersion === 'V2'

  const [documentTypes] = useApiQuery(!isDMS && WorkDocsTypesEndpoints.list())

  // Do not show menu if no document are associated with the type
  const filteredSecondaryMenuOptions =
    documentTypes.data?.results.length &&
    documentTypes.data.results.filter((item) => item.count > 0)

  // In v1, Document system can be an external system
  const isD1g1tManagedDocumentSystem =
    firmConfiguration?.data?.documentIntegrationType ===
    FIRMCONFIGURATION_DOCUMENT_INTEGRATION_TYPE.D1G1T

  const documentPageLabel =
    investorUIConfig?.documents.pageTitle[selectedLanguage]

  const v1DocumentsExternalDocumentLabel =
    SECTION_TITLE_TRANSLATION_KEYS.DOCUMENTS.includes(documentPageLabel)
      ? firmConfiguration?.data?.documentIntegrationSettings?.label
      : documentPageLabel

  const DMSMenuItem: IInvestorPrimaryMenuItem = {
    // Navigate to the first nested menu item by default
    location: isD1g1tManagedDocumentSystem
      ? DocumentsDmsInvestorLocations.basePath
      : DocumentLocations.futureVault(),
    label: isD1g1tManagedDocumentSystem
      ? documentPageLabel
      : v1DocumentsExternalDocumentLabel,
    icon: <FolderIcon data-testid='nav-documents' fontSize='small' />,
    removeEntitiesAndFiltersOnNavigation: !isD1g1tManagedDocumentSystem,
    replace: false,
    secondaryMenuItems: []
  }

  const DocumentsMenuItem: IInvestorPrimaryMenuItem = {
    // Navigate to the first nested menu item by default
    location: isD1g1tManagedDocumentSystem
      ? DocumentLocations.basePath
      : DocumentLocations.futureVault(),
    label: isD1g1tManagedDocumentSystem
      ? documentPageLabel
      : v1DocumentsExternalDocumentLabel,
    icon: <FolderIcon data-testid='nav-documents' fontSize='small' />,
    removeEntitiesAndFiltersOnNavigation: !isD1g1tManagedDocumentSystem,
    replace: false,
    secondaryMenuItems: isD1g1tManagedDocumentSystem
      ? filteredSecondaryMenuOptions?.map((item) => ({
          label: item.name,
          location: `${DocumentLocations.basePath}/${item.slug}`,
          replace: true
        }))
      : []
  }

  return [
    {
      location: MonitorLocations.basePath,
      label: investorUIConfig?.home.pageTitle[selectedLanguage],
      icon: <HomeIcon data-testid='nav-home' fontSize='small' />,
      removeEntitiesAndFiltersOnNavigation: true
    },
    {
      location: PortfolioLocations.basePath,
      label: investorUIConfig?.investments.pageTitle[selectedLanguage],
      icon: <WorkIcon data-testid='nav-investments' fontSize='small' />
    },
    {
      location: ActivityLocations.basePath,
      label: investorUIConfig?.activity.pageTitle[selectedLanguage],
      icon: <AssessmentIcon data-testid='nav-activity' fontSize='small' />
    },
    isDMS ? DMSMenuItem : DocumentsMenuItem
  ]
}
