import {ApiParam} from 'fairlight'

import {ITransaction} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export interface IUpdateTransaction
  extends Omit<Partial<ITransaction>, 'currency' | 'type'> {
  currency: {url: string}
  type: {url: string}
}

export interface ICreateTransaction
  extends Omit<ITransaction, 'currency' | 'type'> {
  currency: {url: string}
  type: {url: string}
}

export class TransactionEndpoints extends BaseRestEndpoints {
  static basePath = '/transactions'

  static findById(id: ApiParam) {
    return super._findById<ITransaction>(id)
  }

  static partialUpdate(id: ApiParam, body: IUpdateTransaction) {
    return super._partialUpdate<ITransaction>(id, body)
  }

  static create(body: ICreateTransaction) {
    return super._create<ITransaction>(body)
  }
}
