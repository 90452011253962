import {
  IAccountTradingApprovalsInput,
  IBulkTradingApprovalsInput,
  IDownload
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class TradingApprovalsEndpoints extends BaseRestEndpoints {
  static basePath = '/trading/approvals'

  static account(body: IAccountTradingApprovalsInput) {
    return super._post<IDownload[]>('/account', {body})
  }

  static bulk(body: IBulkTradingApprovalsInput) {
    return super._post<IDownload[]>('/bulk', {body})
  }
}
