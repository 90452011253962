import {ITrustTrustee} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeopleTrustTrusteesEndpoints extends BaseRestEndpoints {
  static basePath = '/people/trust-trustees'

  static create(body: Pick<ITrustTrustee, 'order' | 'person' | 'trust'>[]) {
    return super._create<Pick<ITrustTrustee, 'order' | 'person' | 'trust'>[]>(
      body
    )
  }

  static destroy(body: Pick<ITrustTrustee, 'url'>[]) {
    return super._delete('/', {body})
  }
}
