import React from 'react'
import {useTranslation} from 'react-i18next'

import MuiInputLabel, {InputLabelProps} from '@material-ui/core/InputLabel'

import {classNames} from '@d1g1t/lib/class-names'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import css from './style.scss'

export interface IInputLabelProps extends InputLabelProps {
  smallHeight?: boolean
  hidden?: boolean
}

export const InputLabel: React.FC<IInputLabelProps> = ({
  smallHeight,
  hidden,
  ...props
}) => {
  const {t} = useTranslation()

  return (
    <MuiInputLabel
      shrink
      classes={{
        root: classNames({
          [css.smallHeight]: smallHeight,
          [css.hidden]: hidden
        }),
        outlined: classNames({
          [css.smallHeight]: smallHeight
        }),
        shrink: css.shrink
      }}
      {...props}
    >
      {onlyTranslateStrings(t, props.children)}
    </MuiInputLabel>
  )
}
