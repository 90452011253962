import {useEffect, useRef} from 'react'

/**
 * Custom hook that returns a boolean indicating
 * if this is the first render of the component
 * it is currently within
 */
export function useIsFirstRender() {
  const firstRenderRef = useRef(true)

  useEffect(() => {
    firstRenderRef.current = false
  }, [])

  return firstRenderRef.current
}
