import {ApiParam} from 'fairlight'

import {snakeify} from '@d1g1t/api/helpers'
import {ITradingLock} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface ITradingLockBulkRemoveBodyItem {
  instrument?: string
  currency?: string
  account?: string
  custodianAccount?: string
}

export class TradingLockEndpoints extends BaseRestEndpoints {
  static basePath = '/trading/locks'

  static list(query: {
    account?: string
    custodianAccount?: string
    instrument?: string
  }) {
    return super._list<IApiListResponse<ITradingLock>>(snakeify(query))
  }

  static create(body: ITradingLock) {
    return super._create<ITradingLock>(body)
  }

  static partialUpdate(id: ApiParam, body: Partial<ITradingLock>) {
    return super._partialUpdate<ITradingLock>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static bulkRemove(body: ITradingLockBulkRemoveBodyItem[]) {
    return super._post<ITradingLock>('/bulk-remove', {body})
  }
}
