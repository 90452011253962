import React from 'react'

import {ExpansionPanel} from '@d1g1t/shared/components/expansion-panel'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings/'

export interface IExpansionPanelSavedProps {
  id: string
  children: NonNullable<React.ReactNode>
  className?: string
}

const DEFAULT_SETTINGS = {
  expanded: true
}

export const ExpansionPanelSaved: React.FC<IExpansionPanelSavedProps> = (
  props
) => {
  const [settings, {updateGlobalSettingsAtKey}] = useGlobalSettings(
    props.id,
    DEFAULT_SETTINGS
  )

  const handleChange = (_, value: boolean) => {
    updateGlobalSettingsAtKey('expanded')(value)
  }

  return (
    <ExpansionPanel
      expanded={!!settings?.expanded}
      onChange={handleChange}
      {...props}
    />
  )
}
