import {
  createBrowserHistory,
  createHashHistory,
  createMemoryHistory
} from 'history'

import {isBrowser} from '@d1g1t/lib/is-browser'

export const history = (() => {
  if (__TEST__) {
    return createBrowserHistory()
  }

  return isBrowser ? createHashHistory() : createMemoryHistory()
})()
