import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    avatarWrapper: {
      padding: '8px 16px'
    },
    avatarWrapperWithBorder: {
      padding: '8px 16px',
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    avatarCircleBackground: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    avatarGreyCircleBackground: {
      backgroundColor: theme.palette.grey[200],
      fontWeight: 600,
      color: theme.palette.grey[700],
      width: '48px',
      height: '48px'
    },
    avatarCircleMenuItemRightMargin: {
      marginRight: '10px'
    }
  }
})
