import {RouteBuilder} from '@d1g1t/lib/url'

export class AdministrationLocations extends RouteBuilder {
  static basePath = '/administration'

  static userList() {
    return super.path('/users')
  }

  static userCreate() {
    return super.path('/users/create')
  }

  static userSingle(id: string) {
    return super.path(`/users/${id}`)
  }

  static userGroups() {
    return super.path('/user-groups')
  }

  static userGroupsSingle(id: string) {
    return super.path(`/user-groups/${id}`)
  }

  static clientManagementRoles() {
    return super.path('/client-management-roles')
  }

  static clientManagementRolesCreate() {
    return super.path('/client-management-roles/create')
  }

  static clientManagementTeamList() {
    return super.path('/client-management-teams')
  }

  static clientManagementTeamCreate() {
    return super.path('/client-management-teams/create')
  }

  static clientAssignments() {
    return super.path('/client-assignments')
  }

  static clientAssignmentsRolesEdit() {
    return super.path('client-assignments/edit-roles')
  }

  static contactList() {
    return super.path('/contacts')
  }

  static contactCreate() {
    return super.path('/contacts/create')
  }

  static contactSingle(id: string) {
    return super.path(`/contacts/${id}`)
  }

  static accountManagement() {
    return super.path('/account-management')
  }

  static accountManagementCreate() {
    return super.path('/account-management/create')
  }

  static custodianAccountManagement() {
    return super.path('/custodian-account-management')
  }

  static custodianAccountManagementCreate() {
    return super.path('/custodian-account-management/create')
  }

  static accountGroups() {
    return super.path('/account-groups')
  }

  static accountGroupsSingle(id: string) {
    return super.path(`/account-groups/${id}`)
  }

  static transactionsList() {
    return super.path('/transactions')
  }

  static transactionsCreate() {
    return super.path('/transactions/create')
  }

  static transactionsEdit(id: string) {
    return super.path(`/transactions/${id}/edit`)
  }

  static manageSecurities() {
    return super.path('/manage-securities')
  }

  static manageSecuritiesCreate() {
    return super.path('/manage-securities/create')
  }

  static userSettings(id: string) {
    return super.path(`/user-settings/${id}`)
  }

  static householdsList() {
    return super.path('/households')
  }

  static householdsCreate() {
    return super.path('/households/create')
  }

  static householdSingle(id: string) {
    return super.path(`/households/${id}`)
  }

  static tradeRestrictions() {
    return super.path('/trade-restrictions')
  }

  static tradeRestrictionsCreate() {
    return super.path('/trade-restrictions/create')
  }

  static allocationExclusions() {
    return super.path('/allocation-exclusions')
  }

  static allocationExclusionsCreate() {
    return super.path('/allocation-exclusions/create')
  }

  static benchmarks() {
    return super.path('/benchmarks')
  }

  static benchmarksCreate() {
    return super.path('/benchmarks/create')
  }

  static tradeLocationRules() {
    return super.path('/trade-location-rules')
  }

  static fileProcessing() {
    return super.path('/processes/files')
  }

  static fileProcessingSingle(id: string) {
    return super.path(`/processes/files/${id}`)
  }

  static datasetsList() {
    return super.path('/datasets')
  }

  static datasetsSingle(id: string) {
    return super.path(`/datasets/${id}`)
  }

  static datasetsCreate() {
    return super.path('/datasets/create')
  }

  static collectionsList() {
    return super.path('/collections')
  }

  static collectionsSingle(id: string) {
    return super.path(`/collections/${id}`)
  }

  static collectionsCreate() {
    return super.path('/collections/create')
  }
}
