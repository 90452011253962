import {ITrustBeneficiary} from '@d1g1t/api/models'

import {IApiListResponse} from './'
import {BaseRestEndpoints} from './base'

export class PeopleTrustBeneficiariesEndpoints extends BaseRestEndpoints {
  static basePath = '/people/trust-beneficiaries'

  static create(body: ITrustBeneficiary[]) {
    return super._create<IApiListResponse<ITrustBeneficiary>>(body)
  }

  static destroy(body: Pick<ITrustBeneficiary, 'url'>[]) {
    return super._destroy('', body)
  }
}
