import {IAssetClass} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AssetClassEndpoints extends BaseRestEndpoints {
  static basePath = '/asset-classes'

  static list() {
    return super._list<IApiListResponse<IAssetClass>>()
  }
}

export class AssetClassVectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/asset-class-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
