import React from 'react'

import MuiAccordion, {AccordionProps} from '@material-ui/core/Accordion'
import MuiAccordionDetails, {AccordionDetailsProps} from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary, {AccordionSummaryProps} from '@material-ui/core/AccordionSummary'

import {classNames} from '@d1g1t/lib/class-names'

import css from './styles.scss'

export interface IExpansionPanelProps
  extends Pick<
    AccordionProps,
    'children' | 'onChange' | 'expanded' | 'defaultExpanded'
  > {
  noBottomMargin?: boolean
  className?: string
}

export const ExpansionPanel: React.FC<IExpansionPanelProps> = ({
  noBottomMargin,
  expanded,
  ...props
}) => {
  return (
    <MuiAccordion
      className={classNames(css.expansionPanel, props.className, {
        [css.expanded]: expanded,
        [css.noBottomMargin]: noBottomMargin
      })}
      expanded={expanded}
      {...props}
    />
  )
}

export interface IExpansionPanelDetailsProps extends AccordionDetailsProps {
  flexColumn?: boolean
  noSidePadding?: boolean
  noPadding?: boolean
}

export const ExpansionPanelDetails: React.FC<IExpansionPanelDetailsProps> = ({
  flexColumn,
  noPadding,
  noSidePadding,
  ...props
}) => {
  return (
    <MuiAccordionDetails
      className={classNames(css.expansionPanelDetails, {
        [css.flexColumn]: flexColumn,
        [css.noSidePadding]: noSidePadding,
        [css.noPadding]: noPadding
      })}
      {...props}
    >
      {props.children ?? <div />}
    </MuiAccordionDetails>
  )
}

export interface IExpansionPanelSummaryProps extends AccordionSummaryProps {
  noPadding?: boolean
  noSidePadding?: boolean
  /**
   * data-testid id for expansion panel summary that will override the default `children.toString()`
   */
  testId?: string
}

export const ExpansionPanelSummary: React.FC<IExpansionPanelSummaryProps> = ({
  noPadding,
  noSidePadding,
  ...props
}) => {
  // ExpansionPanelSummary component may have more children than just
  // typography component with text. Logic below is to get the text
  // for the `data-testid` from first child if there is an array of
  // children.
  const testId = (() => {
    if (props.testId) {
      return props.testId
    }

    if (Array.isArray(props.children)) {
      return (props.children as React.ReactElement[])[0].props?.children
    }

    return (props.children as React.ReactElement).props?.children
  })()

  return (
    <MuiAccordionSummary
      data-testid={testId}
      className={classNames(css.expansionPanelSummary, {
        [css.noSidePadding]: noSidePadding,
        [css.noPadding]: noPadding
      })}
      {...props}
    />
  )
}
