import React from 'react'
import NumberFormat, {
  INumberFormatProps,
  INumberFormatValues
} from 'react-number-format'

import {AsYouType} from 'libphonenumber-js'
import {isNil, round} from 'lodash'

interface IFormattedInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'type'
  > {
  inputRef?: React.Ref<HTMLInputElement>
}

function preparePropsForNumberFormat(
  props: IFormattedInputProps &
    INumberFormatProps & {
      string?: boolean
      /**
       * Transforms the value before it is passed down to the input.
       */
      formatValue?: (
        value: string | number | readonly string[],
        decimalScale?: number
      ) => string
      /**
       * Transforms the float value before it is passed up to `onChange`
       */
      parseValue?: (
        floatValue: number,
        decimalScale?: number
      ) => number | string
    }
) {
  const {onChange, inputRef, value, string, formatValue, parseValue, ...rest} =
    props

  const valueString = formatValue
    ? formatValue(value, props.decimalScale)
    : (() => {
        if (string) {
          return String(value ?? '')
        }

        // The react-number-format package will not trigger an update if we pass null, as the valueString
        // https://github.com/s-yadav/react-number-format/blob/960ad2bcec58a812d5e0c1abd84c9a3e9771bc83/src/number_format.js#L117
        if (typeof value !== 'number') {
          return ''
        }

        return String(round(value as number, props.decimalScale))
      })()

  return {
    ...rest,
    getInputRef: inputRef,
    onValueChange: (values: INumberFormatValues) => {
      const changeValue = (() => {
        if (parseValue) {
          return parseValue(values.floatValue, props.decimalScale)
        }

        return string ? values.value : values.floatValue
      })()

      if (value !== changeValue) {
        onChange({
          target: {
            name: props.name,
            value: isNil(changeValue) ? null : changeValue
          }
        } as any)
      }
    },
    value: valueString
  }
}

interface ICreateNumberFormatInputOpts extends INumberFormatProps {
  string?: boolean
  formatValue?: (value: string | number | string[]) => string
  parseValue?: (floatValue: number) => number | string
}

export const createNumberFormatInput = (
  numberFormatOpts: ICreateNumberFormatInputOpts
): React.FC<IFormattedInputProps> => {
  return function NumberFormatInput(props) {
    const numberFormatProps = {
      ...props,
      ...numberFormatOpts
    }

    return (
      <NumberFormat
        {...preparePropsForNumberFormat(numberFormatProps)}
        fixedDecimalScale
      />
    )
  }
}

/**
 * `<input>` prop-compatible component with a phone number text mask applied
 */
export const PhoneNumberInput = createNumberFormatInput({
  string: true,
  format: (value) => {
    return new AsYouType().input(`+${value}`)
  },
  parseValue: (value) => (isNil(value) ? null : `+${String(value)}`)
})

/**
 * `<input>` prop-compatible component for 6 digit MFA code input.
 */
export const MFACodeInput = createNumberFormatInput({
  format: '### ###',
  decimalScale: 0,
  string: true
})

/**
 * `<input>` prop-compatible component for inputting currency
 */
export const CurrencyInput = createNumberFormatInput({
  decimalScale: 2,
  allowNegative: false,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for inputting positive whole
 * numbers including zero
 */
export const WholeNumberInput = createNumberFormatInput({
  allowNegative: false,
  decimalScale: 0,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for integers
 */
export const IntegerInput = createNumberFormatInput({
  decimalScale: 0,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for inputting positive
 * numbers including zero, up to two decimal places.
 */
export const DecimalNumberInput = createNumberFormatInput({
  decimalScale: 2,
  allowNegative: false,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for inputting cents, up
 * to 2 decimal places.
 */
export const CentsInput = createNumberFormatInput({
  allowNegative: false,
  decimalScale: 2,
  suffix: ' cents',
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for inputting zip codes
 */
export const ZipCodeInput = createNumberFormatInput({
  string: true,
  allowNegative: false,
  decimalScale: 0,
  maxLength: 5
})

/**
 * `<input>` prop-compatible component for positive only four decimal numbers
 */
export const FourDecimalInput = createNumberFormatInput({
  decimalScale: 4,
  allowNegative: false,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for positive only six decimal numbers
 */
export const SixDecimalInput = createNumberFormatInput({
  decimalScale: 6,
  allowNegative: false,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for inputting negative 2 decimal numbers
 */
export const NegativeAllowedTwoDecimalInput = createNumberFormatInput({
  decimalScale: 2,
  thousandSeparator: true
})

/**
 * `<input>` prop-compatible component for inputting negative 4 decimal numbers
 */
export const NegativeAllowedFourDecimalInput = createNumberFormatInput({
  decimalScale: 4,
  thousandSeparator: true
})

export const NegativeAllowedSixDecimalInput = createNumberFormatInput({
  decimalScale: 6,
  thousandSeparator: true
})

export const formatInputPercentage = (
  value: string | number | string[],
  decimalScale?: number
) =>
  Number.isFinite(value as number)
    ? String(
        round((value as number) * 100, decimalScale ?? 2).toFixed(
          decimalScale ?? 2
        )
      )
    : ''

export const parseInputPercentage = (
  floatValue: number,
  decimalScale?: number
) =>
  isNil(floatValue) ? null : round(floatValue / 100, (decimalScale ?? 2) + 2)

const commonPercentageOpts: ICreateNumberFormatInputOpts = {
  thousandSeparator: true,
  suffix: ' %',
  parseValue: parseInputPercentage,
  formatValue: formatInputPercentage
}

/**
 * `<input>` prop-compatible component for inputting percentages
 */
export const PercentageInput = createNumberFormatInput({
  ...commonPercentageOpts,
  decimalScale: 2
})

/**
 * `<input>` prop-compatible component for inputting percentages with 3 decimals
 */
export const PercentageInput3Decimals = createNumberFormatInput({
  ...commonPercentageOpts,
  decimalScale: 3
})
/**
 * `<input>` prop-compatible component for inputting percentages
 */
export const FourDecimalPercentageInput = createNumberFormatInput({
  ...commonPercentageOpts,
  decimalScale: 4
})

const applyMaxLimit = (value: INumberFormatValues) => {
  return value.floatValue === undefined || value.floatValue <= 100
}

/**
 * `<input>` prop-compatible component for inputting  6 decimals percentages
 * with upper limit of 100% and lower limit of 0%
 *
 */
export const SixDecimalPercentageMaxLimitedInput = createNumberFormatInput({
  ...commonPercentageOpts,
  allowNegative: false,
  decimalScale: 6,
  isAllowed: applyMaxLimit
})

/**
 * `<input>` prop-compatible component for inputting positive percentages
 */
export const PositivePercentageInput = createNumberFormatInput({
  ...commonPercentageOpts,
  decimalScale: 2,
  allowNegative: false
})
