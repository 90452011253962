import {
  ACCOUNTSHOLDINGREBALANCINGREQUEST_TARGET_MODE,
  BULKRECOMMENDATIONREQUEST_DESIRED_ACTION,
  BULKTRADELOCATIONALLINPUT_TARGET_MODE,
  BULKUPDATEREQUEST_DESIRED_ACTION,
  CALCULATION_CODES,
  COMMISSION_TYPE,
  IAccountsHoldingRebalancingEntityRequest,
  IAccountsHoldingRebalancingRequest,
  IAccountsHoldingsRebalancingResponse,
  IAccountsRebalancingRequest,
  IAccountsRebalancingResponse,
  IBulkTradeLocationEntitiesInput,
  IBulkUpdateRequest,
  IChartTable,
  IEntityCombinedHoldingsChartRequest,
  IEntityCombinedHoldingsChartResponse,
  IPortfolioDeleteDraft,
  IPortfolioRebalancingRequest,
  IPortfolioRebalancingResponse,
  IReplaceHolding,
  ISleeveRebalancingTransferInput,
  LIQUIDATE_ACTION,
  SELECT_ASSETS_FOR_REBALANCE
} from '@d1g1t/api/models'

import {BaseEndpoints} from './base'
import {ICalculationRequestBody} from './calculation'

export interface IBulkRebalanceChartParams {
  desiredAction: BULKUPDATEREQUEST_DESIRED_ACTION
  rebalanceRule: string // id or slug of rebalance rule
  asOfDate: string // date string
  accounts: string[] // account ids
  instruments: Array<{
    instrument: string // url
    lowerBound: number
    targetWeight: number
    marketPriceOverride: number
    upperBound: number
    allowTrading: boolean
    commission: number
    commissionType: COMMISSION_TYPE
  }>
  includePending?: boolean
  includeCashEquivalents?: boolean
}

export type IRebalancingDraftResponse = Array<{
  instrument?: string // url
  currency?: string // url
  targetWeight: number
}>

type IRebalancingClearDraftRequest = {
  entities: string[]
}

export enum IGenerateRecommendationsRequestActions {
  /**
   * Rebalance Positions to model option.
   * This is the default option when no IGenerateRecommendationsRequestAction is passed
   */
  REBALANCE_TO_MODEL = 'rebalance_to_model',
  /**
   * Raise cash option
   */
  RAISE_CASH = 'raise_cash',
  /**
   * Invest cash option
   */
  DEPLOY_CASH = 'deploy_cash',
  /**
   * Rebalance All Positions option
   */
  REBALANCE_ALL_POSITIONS = 'rebalance_all_positions',
  /**
   * Rebalance positions outside bonds option
   */
  REBALANCE_POSITIONS_OUTSIDE_BOUNDS = 'rebalance_positions_outside_bounds'
}

export enum IGenerateRecommendationsRequestTargetModes {
  /**
   * Rebalances targeting the target weight
   * This is the default option when no IGenerateRecommendationsRequestTargetMode is passed
   */
  TARGET_WEIGHT = 'target_weight',
  /**
   * Rebalances targeting the current weight
   */
  CURRENT_WEIGHT = 'current_weight'
}

export interface IAccountHoldingsGenerateRecommendationsResponse {
  pendingCountUnderFirm: number
}

interface IGenerateRecommendationsAllRequest extends ICalculationRequestBody {
  /**
   * If not passed as one of these two, then it is the
   * `IGenerateRecommendationsRequestActions.REBALANCE_TO_MODEL` action by default.
   */
  tradeRecommendationAction?: IGenerateRecommendationsRequestActions
  /**
   * If not passed as one of these two, then it is the
   * `IGenerateRecommendationsRequestTargetModes.TARGET_WEIGHT` action by default.
   */
  targetMode?: IGenerateRecommendationsRequestTargetModes
  /**
   * Sent to determine if all accounts or mandates
   * CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS by default
   */
  calcCode:
    | CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS
    | CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES
}

type IGenerateRecommendationsRequest = {
  /**
   * /entities/* URL of accounts/mandates
   */
  entities: string[]
  /**
   * If not passed, then it defaulted to the
   * `IGenerateRecommendationsRequestActions.REBALANCE_TO_MODEL` action.
   */
  tradeRecommendationAction?: IGenerateRecommendationsRequestActions
  /**
   * If not passed as one of these two, then it is the
   * `IGenerateRecommendationsRequestTargetModes.TARGET_WEIGHT` action by default.
   */
  targetMode?: IGenerateRecommendationsRequestTargetModes
}
interface IBulkRebalanceModelsSecuritiesCommonRequest {
  /**
   * Calculation code to differ between accounts or mandates
   */
  calcCode:
    | CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS
    | CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES
  /**
   * Custom rebalancing rule, don't send if using default
   */
  rebalancingRule?: string
  /**
   * Trading operation
   */
  operation?: BULKRECOMMENDATIONREQUEST_DESIRED_ACTION
  /**
   * Rebalancing to target or current weight
   */
  targetMode?: IGenerateRecommendationsRequestTargetModes
  /**
   * Rebalancing all positions or only positions outside min and max bounds
   */
  selectAssetsForRebalance?: SELECT_ASSETS_FOR_REBALANCE
}

interface IBulkRebalanceSecuritiesAllRequest
  extends IBulkRebalanceModelsSecuritiesCommonRequest,
    ICalculationRequestBody {
  /**
   * List of securities to be rebalanced (urls)
   */
  instruments: string[]
}

interface IBulkRebalanceSecuritiesEntitiesRequest
  extends IBulkRebalanceModelsSecuritiesCommonRequest {
  /**
   * List of securities to be rebalanced (urls)
   */
  instruments: string[]
  /**
   * List of selected entities (account or mandate as entity urls) to be rebalanced
   */
  selectedEntities: string[]
}

interface IBulkRebalanceModelsAllRequest
  extends IBulkRebalanceModelsSecuritiesCommonRequest,
    ICalculationRequestBody {
  /**
   * List of model portfolios to be rebalanced (urls)
   */
  modelPortfolios: string[]
}

interface IBulkRebalanceModelsEntitiesRequest
  extends IBulkRebalanceModelsSecuritiesCommonRequest {
  /**
   * List of model portfolios to be rebalanced (urls)
   */
  modelPortfolios: string[]
  /**
   * List of selected entities (account or mandate as entity urls) to be rebalanced
   */
  selectedEntities: string[]
}

interface IBulkRebalanceLiquidateCommonRequest {
  /**
   * Which of the 4 liquidate actions to perform
   */
  liquidateAction: LIQUIDATE_ACTION
  /**
   * Liquidate all holdings that are associated with the provided model portfolio urls
   * Only required if `liquidateAction` is `'in_model_holdings'`
   */
  modelPortfolios?: string[]
  /**
   * Calculation code to differ between accounts or mandates
   */
  calcCode:
    | CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS
    | CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES
}

interface IBulkRebalanceLiquidateAllRequest
  extends IBulkRebalanceLiquidateCommonRequest,
    ICalculationRequestBody {}

interface IBulkRebalanceLiquidateEntitiesRequest
  extends IBulkRebalanceLiquidateCommonRequest {
  /**
   * List of selected entities (account or mandate as entity urls) to be rebalanced
   */
  selectedEntities: string[]
}

interface IBulkRebalanceSwitchCommonRequest {
  holdingsToSell: IReplaceHolding[]
  securitiesToBuy: IReplaceHolding[]
  calcCode:
    | CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS
    | CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES
}

interface IBulkRebalanceSwitchAllRequest
  extends IBulkRebalanceSwitchCommonRequest,
    ICalculationRequestBody {}

interface IBulkRebalanceSwitchEntitiesRequest
  extends IBulkRebalanceSwitchCommonRequest {
  /**
   * List of selected entities (account or mandate as entity urls) to be rebalanced
   */
  selectedEntities: string[]
}

export interface IBulkRebalanceResponse {
  entities: number
}

interface IBulkTradeLocationAllRequest extends ICalculationRequestBody {
  targetMode: BULKTRADELOCATIONALLINPUT_TARGET_MODE
  /**
   * Sent to determine if all accounts or mandates
   * CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS by default
   */
  calcCode:
    | CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS
    | CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES
  asOfDate: string
}

export interface IBulkTradeLocationResponse {
  pendingLocationCount: number
}

type IPortfolioRebalanceDirectiveChartParams = Array<{
  /**
   * URL of instrument
   */
  instrument: string
}>

interface IEntityTargetAllocationChartRequest {
  /** mandate `entity` url  */
  entity: string
  referencePortfolio?: string
  groupings?: string[]
  asOfDate?: string
  targetMode: ACCOUNTSHOLDINGREBALANCINGREQUEST_TARGET_MODE
}
interface ITargetValues {
  /** instrument url  */
  instrument?: string
  /** currency url  */
  currency?: string
  weight?: number
  value?: number
}

export interface IEntityTargetAllocationUpdateRequest {
  /** model portfolio url  */
  referencePortfolio: string
  entityData: {
    /** mandate `entity` url  */
    entity: string
    targetValues: ITargetValues[]
  }
  accountsData: {
    /** account url  */
    account: string
    targetValues: ITargetValues[]
  }[]
  groupings?: string[]
  asOfDate?: string
}
export interface IEntityTargetAllocationResponse {
  data: IChartTable
  warnings?: {
    entity?: string[]
    [accountId: string]: string[]
  }
}

export type IEntityTargetAllocationSaveRequest =
  IEntityTargetAllocationUpdateRequest

interface IRemoveRebalanceDateRequest {
  asOfDate: string
  /**
   * portfolio url
   */
  portfolio: string
}

export class PortfolioRebalanceEndpoints extends BaseEndpoints {
  static basePath = '/rebalancing'

  static bulkChart(body: IBulkRebalanceChartParams) {
    return super._post<IChartTable>('/bulk', {body})
  }

  static bulkChartUpdate(body: IBulkUpdateRequest) {
    return super._post<IChartTable>('/bulk/update', {body})
  }

  static bulkDirectiveChart(body: IPortfolioRebalanceDirectiveChartParams) {
    return super._post<IChartTable>('/bulk/directive', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static bulkTradePreview(body: IBulkUpdateRequest) {
    return super._post<IChartTable>('/bulk/trade-preview', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static draft(params: {portfolioId: string}) {
    return super._get<IRebalancingDraftResponse>('/draft', {
      query: {
        table_entity: params.portfolioId
      }
    })
  }

  private static accounts(endPoint: string) {
    return (body: IAccountsRebalancingRequest) => {
      return super._post<IAccountsRebalancingResponse>(
        `/accounts/${endPoint}`,
        {
          body
        }
      )
    }
  }

  static preview(body: IAccountsRebalancingRequest) {
    return super._post<IChartTable>('/accounts/trade-preview', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static charts = PortfolioRebalanceEndpoints.accounts('')

  static saveDraft = PortfolioRebalanceEndpoints.accounts('save-draft')

  static clearDraft = PortfolioRebalanceEndpoints.accounts('clear-draft')

  private static modelPortfolio(endPoint: string) {
    return (body: IPortfolioRebalancingRequest) => {
      return super._post<IPortfolioRebalancingResponse>(
        `/portfolio/${endPoint}`,
        {body}
      )
    }
  }

  static modelPortfolioTable = PortfolioRebalanceEndpoints.modelPortfolio('')

  static modelPortfolioRebalance =
    PortfolioRebalanceEndpoints.modelPortfolio('rebalance')

  static modelPortfolioSaveDraft =
    PortfolioRebalanceEndpoints.modelPortfolio('save_draft')

  static modelPortfolioDeleteDraft(body: IPortfolioDeleteDraft) {
    return super._post('/portfolio/delete-draft', {body})
  }

  static accountHoldingsCharts(body: IAccountsHoldingRebalancingRequest) {
    return super._post<IAccountsHoldingsRebalancingResponse>(
      '/account-holdings',
      {body}
    )
  }

  static accountHoldingsSaveDraft(body: IAccountsHoldingRebalancingRequest) {
    return super._post('/account-holdings/save-draft', {body})
  }

  static acountHoldingsClearDraft(body: IRebalancingClearDraftRequest) {
    return super._post('/account-holdings/clear-draft', {body})
  }

  static accountHoldingsRebalanceToModel(
    body: IAccountsHoldingRebalancingEntityRequest
  ) {
    return super._post<IAccountsHoldingsRebalancingResponse>(
      '/account-holdings/rebalance/',
      {body}
    )
  }

  static accountHoldingsGenerateRecommendations(
    body: IGenerateRecommendationsRequest
  ) {
    return super._post<IAccountHoldingsGenerateRecommendationsResponse>(
      '/account-holdings/generate-recommendations',
      {body}
    )
  }

  static accountHoldingsGenerateAllRecommendations(
    body: IGenerateRecommendationsAllRequest
  ) {
    return super._post<IAccountHoldingsGenerateRecommendationsResponse>(
      '/account-holdings/generate-recommendations-all',
      {body}
    )
  }

  static bulkRebalanceStrategiesSecuritiesAll(
    body: IBulkRebalanceSecuritiesAllRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/securities/all',
      {body}
    )
  }

  static bulkRebalanceStrategiesSecuritiesEntities(
    body: IBulkRebalanceSecuritiesEntitiesRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/securities/entities',
      {body}
    )
  }

  static bulkRebalanceStrategiesModelsAll(
    body: IBulkRebalanceModelsAllRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/portfolios/all',
      {body}
    )
  }

  static bulkRebalanceStrategiesModelsEntities(
    body: IBulkRebalanceModelsEntitiesRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/portfolios/entities',
      {body}
    )
  }

  static bulkRebalanceStrategiesLiquidateAll(
    body: IBulkRebalanceLiquidateAllRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/liquidate/all',
      {body}
    )
  }

  static bulkRebalanceStrategiesLiquidateEntities(
    body: IBulkRebalanceLiquidateEntitiesRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/liquidate/entities',
      {body}
    )
  }

  static bulkRebalanceStrategiesSwitchAll(
    body: IBulkRebalanceSwitchAllRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/replace/all',
      {body}
    )
  }

  static bulkRebalanceStrategiesSwitchEntities(
    body: IBulkRebalanceSwitchEntitiesRequest
  ) {
    return super._post<IBulkRebalanceResponse>(
      '/bulk-rebalance-strategies/replace/entities',
      {body}
    )
  }

  static accountHoldingsRebalanceAllPositions(
    body: IAccountsHoldingRebalancingEntityRequest
  ) {
    return super._post<IAccountsHoldingsRebalancingResponse>(
      '/account-holdings/rebalance-all-positions',
      {body}
    )
  }

  static accountHoldingsRaiseCash(
    body: IAccountsHoldingRebalancingEntityRequest
  ) {
    return super._post<IAccountsHoldingsRebalancingResponse>(
      '/account-holdings/raise-cash',
      {body}
    )
  }

  static accountHoldingsDeployCash(
    body: IAccountsHoldingRebalancingEntityRequest
  ) {
    return super._post<IAccountsHoldingsRebalancingResponse>(
      '/account-holdings/deploy-cash',
      {body}
    )
  }

  static entityCombinedHoldings(body: IEntityCombinedHoldingsChartRequest) {
    return super._post<IEntityCombinedHoldingsChartResponse>(
      '/entity-combined-holdings/chart/',
      {body}
    )
  }

  static entityTargetAllocation(body: IEntityTargetAllocationChartRequest) {
    return super._post<IEntityTargetAllocationResponse>('/target_allocation', {
      body
    })
  }

  static entityTargetAllocationUpdate(
    body: IEntityTargetAllocationUpdateRequest
  ) {
    return super._post<IEntityTargetAllocationResponse>(
      '/target_allocation/update_targets',
      {body}
    )
  }

  static entityTargetAllocationSave(
    body: IEntityTargetAllocationUpdateRequest
  ) {
    return super._post<IEntityTargetAllocationResponse>(
      '/target_allocation/save',
      {body}
    )
  }

  static locateAll(body: IBulkTradeLocationAllRequest) {
    return super._post<IBulkTradeLocationResponse>(
      '/trade-location/apply/all',
      {body}
    )
  }

  static locateEntities(body: IBulkTradeLocationEntitiesInput) {
    return super._post<IBulkTradeLocationResponse>(
      '/trade-location/apply/entities',
      {body}
    )
  }

  static sleeveTransfer(body: ISleeveRebalancingTransferInput) {
    return super._post('/sleeves/transfer', {body})
  }

  static removeRebalanceDate(body: IRemoveRebalanceDateRequest) {
    return super._post('/portfolio/remove-rebalance', {body})
  }
}
