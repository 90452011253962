import React from 'react'
import {matchPath, useLocation} from 'react-router-dom'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {ListItemLink} from '@d1g1t/shared/components/router-link'
import {Text} from '@d1g1t/shared/components/typography'
import {
  parseQueryString,
  serializeQueryParams
} from '@d1g1t/shared/wrappers/url-query-params'

import {useInvestorAppMenu} from '../../hook'
import {useStyles} from './use-styles'

/**
 * Investor app's primary navigation menu.
 */
export const PrimaryNavigation: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const investorTopMenuOptions = useInvestorAppMenu()
  const paramsWithoutEntityOrFilters = (() => {
    const params = parseQueryString(
      location.search?.substring(1, location.search.length) ?? ''
    )

    const currency = params.currency ?? {}
    return serializeQueryParams({currency})
  })()

  return (
    <>
      {investorTopMenuOptions.map((item) => (
        <Flex
          key={item.location}
          className={
            matchPath(
              location.pathname.split('/')[1],
              item.location.split('/')[1]
            )
              ? classNames(classes.activeMenuItem, classes.menuItem)
              : classes.menuItem
          }
        >
          <ListItemLink
            button
            to={
              item.removeEntitiesAndFiltersOnNavigation === true
                ? {
                    pathname: item.location,
                    search: paramsWithoutEntityOrFilters
                  }
                : {pathname: item.location, search: location.search}
            }
            key={item.location}
            className={classes.menuItemLink}
            replace={item.replace}
          >
            <ListItemIcon
              className={classNames(
                classes.topBarIconContainer,
                classes.topBarIcon
              )}
            >
              {item.icon}
            </ListItemIcon>
            <Text semiBold className={classes.topBarLabel}>
              {item.label}
            </Text>
          </ListItemLink>
        </Flex>
      ))}
    </>
  )
}
