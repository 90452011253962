import '@d1g1t/shared/bootstrap'
import './i18n'

import React from 'react'
import {DndProvider} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'

// import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime'
import {ApiProvider} from 'fairlight'
import {RecoilRoot} from 'recoil'

import {config} from '@d1g1t/config/config'
import {ConfigContext, IConfigContext} from '@d1g1t/config/context'

import {history} from '@d1g1t/lib/history'

import {createApi, initializeAuth} from '@d1g1t/shared/api'
import {UserActivityTracker} from '@d1g1t/shared/containers/user-activity'
import {resolveRequiredPolyfills} from '@d1g1t/shared/polyfill'
import {CalculationContextProvider} from '@d1g1t/shared/wrappers/calculations'
import {DatadogCustomPropertyContextProvider} from '@d1g1t/shared/wrappers/datadog/context'
import {UrlQueryParamsProvider} from '@d1g1t/shared/wrappers/url-query-params'

import {BaseRoutes} from './base-routes'
import {createStore} from './store'
import {ISagaContext} from './typings'
import {getInvestorUrlAppliedRuleFilters} from './wrappers/calculations/lib'

import '@d1g1t/shared/assets/source-sans-font/sourcesanspro.css'
import './reset.scss'

if (__DEVELOPMENT__) {
  // Used for easy access to Api in browser console
  window.jsonDiff = require('json-diff')
}

// OfflinePluginRuntime.install()

if (console.table) {
  console.table(config)
}

const appContainerEl = document.createElement('div')

appContainerEl.style.height = '100%'
document.body.appendChild(appContainerEl)

const investorConfigContextValue: IConfigContext = {
  enableAzureRedirect: false,
  enableDocuSignRedirect: true,
  enablePrimedDates: false,
  isAdvisorApp: false,
  modelPath: () => null,
  useReportingCurrencyForDefaultCalculationSettings: true
}

const api = createApi()

const sagaContext: ISagaContext = {
  api,
  enableDocuSignRedirect: investorConfigContextValue.enableDocuSignRedirect
}

const store = createStore(sagaContext)

initializeAuth(api, store)

const render = (Component) => {
  const App: React.FC = () => (
    <RecoilRoot>
      <ApiProvider
        api={api}
        defaults={{
          useApiQuery: {
            fetchPolicy: 'no-cache',
            useErrorBoundary: true
          }
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <Router history={history}>
            <UrlQueryParamsProvider>
              <ConfigContext.Provider value={investorConfigContextValue}>
                <Provider store={store}>
                  <UserActivityTracker />
                  <HelmetProvider>
                    <CalculationContextProvider
                      disableCache // disabled because of language changes, should be enabled in 4.0
                      getUrlAppliedRuleFilters={
                        getInvestorUrlAppliedRuleFilters
                      }
                    >
                      <DatadogCustomPropertyContextProvider>
                        <Component />
                      </DatadogCustomPropertyContextProvider>
                    </CalculationContextProvider>
                  </HelmetProvider>
                </Provider>
              </ConfigContext.Provider>
            </UrlQueryParamsProvider>
          </Router>
        </DndProvider>
      </ApiProvider>
    </RecoilRoot>
  )

  App.displayName = 'App'

  ReactDOM.render(<App />, appContainerEl)
}

resolveRequiredPolyfills().then(() => {
  render(BaseRoutes)
})
