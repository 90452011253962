import {Task} from 'redux-saga'
import {
  call,
  cancel,
  delay,
  fork,
  getContext,
  put,
  retry,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import {Api} from 'fairlight'
import {isEmpty} from 'lodash'
import {ActionType, getType} from 'typesafe-actions'

import {
  AuthEndpoints,
  ExternalToolsEndpoints,
  FirmConfigurationEndpoints,
  ILoginResponse,
  IUserMeResponse
} from '@d1g1t/api/endpoints'
import {IFirmConfiguration} from '@d1g1t/api/models'

import {userActivityActions} from '@d1g1t/shared/containers/user-activity/actions'
import {getIsActive} from '@d1g1t/shared/containers/user-activity/getters'
import {loginPath} from '@d1g1t/shared/locations'

import {errorHandlerActions, reportError} from '../error-handler'
import {authActions} from './actions'
import {LAST_LOGIN_KEY, LOGOUT_URL_KEY, TOKEN_KEY} from './constants'
import {
  getIsAuthenticated,
  getMfaToken,
  getToken,
  getTokenExpirationMs
} from './getters'
import {
  appendLogoutRedirect,
  isInvalidCredentialsError,
  isWaitingForMfaCodeError,
  isWaitingForMfaDestinationError
} from './lib'

export function* authSagas() {
  // start sagas once `initialize` action is received
  yield take(getType(authActions.initialize))
  const runningTasks = [yield fork(tokenPersistor)]

  // wait for login if not already logged in
  yield call(waitUntilLoggedIn)

  // kick off logged in tasks and wait until logged out
  runningTasks.push(
    yield fork(logoutAfterPeriodOfInactivity),
    yield fork(tokenRefresher)
  )

  // wait for logout
  yield call(waitForLogout)

  // cancel all running tasks and trigger logout
  yield cancel(runningTasks)
  yield call(logout)
}

function* waitUntilLoggedIn() {
  if (yield select(getIsAuthenticated)) {
    return
  }

  const loginTask = yield fork(function* loginHandlers() {
    yield takeLatest(getType(authActions.loginRequest), login)
    yield takeLatest(
      getType(authActions.loginMfaDestinationRequest),
      loginMfaDestination
    )
    yield takeLatest(getType(authActions.loginMfaRequest), loginMfa)
    yield takeEvery(getType(authActions.loginAzureAdRequest), loginAzureAd)
    yield takeEvery(getType(authActions.loginSamlRequest), loginSamlRequest)
  })

  yield take(getType(authActions.loginSuccess))
  yield cancel(loginTask)
}

function* waitForLogout() {
  yield take(getType(authActions.logoutRequest))
}

function* loginMfaDestination(
  action: ReturnType<typeof authActions.loginMfaDestinationRequest>
) {
  const api: Api = yield getContext('api')
  const mfaToken: string = yield select(getMfaToken)

  try {
    const {deliveryDestination} = action.payload
    yield call(
      api.request,
      AuthEndpoints.mfaDestination({otpToken: mfaToken, deliveryDestination})
    )
    // no need to do anything with response
  } catch (error) {
    // If MFA enabled, show MFA code input field and wait
    if (isWaitingForMfaCodeError(error)) {
      yield put(
        authActions.loginAwaitingMfaCode({
          otpToken: error.responseBody.otpToken,
          invalidCode: false
        })
      )
      return
    }

    if (!isInvalidCredentialsError(error)) {
      yield call(reportError, error)
    }

    yield put(authActions.loginFailed(error))
  }
}
function* loginMfa(action: ReturnType<typeof authActions.loginMfaRequest>) {
  const api: Api = yield getContext('api')
  const mfaToken: string = yield select(getMfaToken)

  try {
    const {otp, redirectTo, language} = action.payload

    api.setDefaultHeader('Language', language)

    const response = yield call(
      api.request,
      AuthEndpoints.mfaLogin({otpToken: mfaToken, otp})
    )
    yield spawn(handleLoginResponse, response, redirectTo)
  } catch (error) {
    if (isWaitingForMfaCodeError(error)) {
      yield put(
        authActions.loginAwaitingMfaCode({
          otpToken: mfaToken,
          invalidCode: true,
          invalidCodeErrorMessage:
            'You have entered an invalid verification code. A new verification code has been sent to your phone.'
        })
      )

      return
    }

    if (!isInvalidCredentialsError(error)) {
      yield call(reportError, error)
    }

    yield put(authActions.loginFailed(error))
  }
}

function* login(action: ReturnType<typeof authActions.loginRequest>) {
  const api: Api = yield getContext('api')

  try {
    const {username, password, redirectTo, language} = action.payload

    api.setDefaultHeader('Language', language)

    const response = yield call(
      api.request,
      AuthEndpoints.login({username, password})
    )
    yield spawn(handleLoginResponse, response, redirectTo)
  } catch (error) {
    // If MFA enabled with multiple destinations, wait for selection
    if (isWaitingForMfaDestinationError(error)) {
      yield put(
        authActions.loginAwaitingMfaDestination({
          otpToken: error.responseBody.otpToken,
          deliveryDestinations: error.responseBody.deliveryDestinations,
          invalidCode: false
        })
      )
      return
    }

    // If MFA enabled, show MFA code input field and wait
    if (isWaitingForMfaCodeError(error)) {
      yield put(
        authActions.loginAwaitingMfaCode({
          otpToken: error.responseBody.otpToken,
          invalidCode: false
        })
      )
      return
    }

    if (!isInvalidCredentialsError(error)) {
      yield call(reportError, error)
    }

    yield put(authActions.loginFailed(error))
  }
}

function* loginAzureAd(
  action: ReturnType<typeof authActions.loginAzureAdRequest>
) {
  const api: Api = yield getContext('api')

  try {
    const {code, session_state, redirectTo} = action.payload
    const response = yield call(
      api.request,
      AuthEndpoints.loginAzureAd({code, session_state})
    )
    yield spawn(handleLoginResponse, response, redirectTo)
  } catch (error) {
    yield call(reportError, error)
    yield put(authActions.loginFailed(error))
  }
}

function* loginSamlRequest(
  action: ReturnType<typeof authActions.loginSamlRequest>
) {
  try {
    const {token, redirectTo} = action.payload
    yield spawn(handleLoginResponse, {token}, redirectTo)
  } catch (error) {
    yield call(reportError, error)
    yield put(authActions.loginFailed(error))
  }
}

function* redirectToDocusign(redirectTo: string) {
  const api: Api = yield getContext('api')
  const request = FirmConfigurationEndpoints.current()
  try {
    const firmConfig: IFirmConfiguration = yield call(api.request, request)
    const userData: IUserMeResponse = yield call(
      api.request,
      AuthEndpoints.me(),
      {
        fetchPolicy: 'cache-first'
      }
    )
    /**
     * Generate link to redirect to consent form.
     * Once all required documents are signed, PS will redirect back to the app
     * to the target path specified.
     */
    if (
      isEmpty(userData?.emulator) &&
      firmConfig?.docusignIntegrationSettings &&
      !!Object.keys(firmConfig?.docusignIntegrationSettings).length
    ) {
      // Directly using `window.location.href` instead of react-router `hash-history` to clean the entire URL
      window.location.href = api.buildUrl(
        ExternalToolsEndpoints.docusignUrl(
          redirectTo,
          sessionStorage.getItem(TOKEN_KEY)
        )
      )
    } else {
      window.location.href = redirectTo
    }
  } catch (error) {
    yield put(
      errorHandlerActions.handleError({
        error,
        snackbarMessage:
          'An unexpected error occurred while retrieving firm configuration.'
      })
    )
  }
}

/**
 * Saves token, cleans URL, and redirects to a specific location.
 */
function* handleLoginResponse(response: ILoginResponse, queryParams?: string) {
  const enableDocuSignRedirect = yield getContext('enableDocuSignRedirect')
  yield put(authActions.loginSuccess(response))
  yield call(
    localStorage.setItem.bind(localStorage),
    LAST_LOGIN_KEY,
    JSON.stringify({lastLogin: new Date()})
  )

  // Remove `code` and `session_state` search params
  const cleanedQueryParams = queryParams
    ? decodeURIComponent(queryParams)
        .replace(/[?]code.+/, '')
        .replace(/[?]session_state.+/, '')
    : null

  const redirectTo = `${window.location.origin}#${
    cleanedQueryParams || '/monitor/overview'
  }`

  if (enableDocuSignRedirect) {
    yield spawn(redirectToDocusign, redirectTo)
  } else {
    window.location.href = redirectTo
  }
}

/**
 * When a new token is received from the API due to login or token refresh:
 * - Persist to storage
 * - Set default api header for future requests
 */
function* tokenPersistor() {
  const api: Api = yield getContext('api')

  yield takeLatest(
    [
      getType(authActions.loginSuccess),
      getType(authActions.refreshSuccess),
      getType(authActions.initialize)
    ],
    function* persistToken(
      action: ActionType<
        | typeof authActions.loginSuccess
        | typeof authActions.refreshSuccess
        | typeof authActions.initialize
      >
    ) {
      if (action.payload.token) {
        api.setDefaultHeader('Authorization', `JWT ${action.payload.token}`)
        yield call(
          sessionStorage.setItem.bind(sessionStorage),
          TOKEN_KEY,
          action.payload.token
        )
      }
    }
  )
}

/**
 * Removes auth token from storage and redirects to login
 */
function* logout() {
  const api: Api = yield getContext('api')

  yield call(sessionStorage.removeItem.bind(sessionStorage), TOKEN_KEY)

  try {
    const config = yield call(
      api.request,
      FirmConfigurationEndpoints.preLogin(),
      {fetchPolicy: 'cache-first'}
    )

    if (config.azureAdLogoutUrl) {
      window.location.href = config.azureAdLogoutUrl

      return
    }

    const logoutUrl = sessionStorage.getItem(LOGOUT_URL_KEY)
    if (logoutUrl) {
      yield call(sessionStorage.removeItem.bind(sessionStorage), LOGOUT_URL_KEY)
      window.location.href = appendLogoutRedirect(logoutUrl)
      return
    }
  } catch (error) {}

  window.location.href = loginPath(true)
  if (typeof window.location.reload === 'function') {
    window.location.reload() // force clear temp caches
  }
}

/**
 * Amount of time before the token expires that we should start trying to refresh it.
 */
const REFRESH_BUFFER_TIME_MS = 120000 // 2 min
const NUM_RETRIES = 4

/**
 * Automatically refreshes the current auth token once it's almost expired
 */
export function* tokenRefresher() {
  const api: Api = yield getContext('api')

  while (true) {
    // wait until token is near expiry
    const tokenExpirationMs: number = yield select(getTokenExpirationMs)
    const delayTime =
      tokenExpirationMs - new Date().getTime() - REFRESH_BUFFER_TIME_MS
    yield delay(delayTime)

    if (new Date().getTime() > (yield select(getTokenExpirationMs))) {
      // token already expired (potentially because the computer went to sleep)
      // so don't bother refreshing and just log the user out
      yield put(authActions.logoutRequest())
      break
    }

    // refresh token
    const token: string = yield select(getToken)
    try {
      const response: ILoginResponse = yield retry(
        NUM_RETRIES,
        Math.floor(REFRESH_BUFFER_TIME_MS / NUM_RETRIES),
        api.request,
        AuthEndpoints.refresh({token})
      )
      yield put(authActions.refreshSuccess(response))
    } catch (error) {
      yield call(reportError, error)
      yield put(authActions.logoutRequest())
      break
    }
  }
}

/**
 * If `logoutWhenIdleFor` is not defined for the firm, this will be used instead
 * to determine how long the user should idle before logging them out.
 */
const DEFAULT_LOGOUT_WHEN_IDLE_FOR_SECONDS = 3600

/**
 * Automatically logs out after firm-defined period of inactivity
 */
export function* logoutAfterPeriodOfInactivity() {
  const api: Api = yield getContext('api')

  const maxIdlePeriodSeconds: number = yield call(
    determineMaxIdlePeriodSeconds,
    api
  )
  let logoutTimerTask: Task

  if (yield select(getIsActive)) {
    yield take(getType(userActivityActions.idle))
  }

  while (true) {
    logoutTimerTask = yield fork(logoutAfterMaxIdlePeriod, maxIdlePeriodSeconds)
    yield take(getType(userActivityActions.active))
    yield cancel(logoutTimerTask)
    yield take(getType(userActivityActions.idle))
  }
}

/**
 * Logs out after delay
 */
export function* logoutAfterMaxIdlePeriod(maxIdlePeriodSeconds: number) {
  yield delay(maxIdlePeriodSeconds * 1000)
  yield put(authActions.logoutRequest())
}

export async function determineMaxIdlePeriodSeconds(api: Api): Promise<number> {
  try {
    const firmConfiguration = await api.request(
      FirmConfigurationEndpoints.current(),
      {fetchPolicy: 'cache-first'}
    )
    return (
      firmConfiguration.firm.logoutWhenIdleFor ||
      DEFAULT_LOGOUT_WHEN_IDLE_FOR_SECONDS
    )
  } catch (error) {
    reportError(error)
    return DEFAULT_LOGOUT_WHEN_IDLE_FOR_SECONDS
  }
}
