import {useTranslation} from 'react-i18next'

import {
  DATE_OPTION_DEFAULT,
  getDateRangeOption,
  IDateRange
} from '@d1g1t/lib/date-range'

import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {UI_CONFIG_INVESTOR_PAGES_KEYS} from '@d1g1t/shared/wrappers/firm-configuration/constants'

/**
 * Hook returns title and default date range for investor page based on UI config
 * @param page - One of the available investor pages, based on which we get the UI configuration
 */
export const usePageUIConfiguration = (
  page: UI_CONFIG_INVESTOR_PAGES_KEYS
): [string, IDateRange] => {
  const {firmUiConfig} = useFirmConfiguration()
  const {i18n} = useTranslation()

  const activityFirmConfig = firmUiConfig.data.investor[page]

  const title = activityFirmConfig.pageTitle[i18n.language]

  if ('defaultPageLevelDateRange' in activityFirmConfig) {
    const defaultDateOption = getDateRangeOption(
      activityFirmConfig?.defaultPageLevelDateRange
    )

    return [title, defaultDateOption]
  }

  return [title, DATE_OPTION_DEFAULT]
}
