// Should update `ENV_DEFINITIONS` too
export interface IEnvironmentConfig {
  api: string
  tag: string
  revision: string
  enableUnreleasedFeatures: boolean
  reportWorkflow: boolean
  bbrActivityTypesSortOrder: boolean
}

let localConfig: Partial<IEnvironmentConfig> = {}

try {
  // eslint-disable-next-line import/extensions
  localConfig = require('./config.local.tsx').config
} catch {}

/**
 * Override the environment in `src/config/config.local.tsx`.
 * There should be another `IEnvironmentConfig` object that will override
 * this `config` object.
 *
 * Example content of `config.local.tsx` to target the d1g1t-rc environment:
 * @example
 * ```ts
 * import {IEnvironmentConfig} from './config'
    export const config: Partial<IEnvironmentConfig> = {
      api: 'https://api-rc.d1g1tdev.com/api/v1'
    }
 * ```
 */
export const config: IEnvironmentConfig = {
  api: process.env.DGW_API_URL || 'https://api-develop.d1g1tdev.com/api/v1',
  tag: process.env.DGW_TAG || 'dev',
  revision: process.env.DGW_REVISION || '@000000',
  enableUnreleasedFeatures:
    !!process.env.ENABLE_UNRELEASED_FEATURES || !!__DEVELOPMENT__,
  reportWorkflow: !!process.env.ENABLE_REPORT_WORKFLOW || !!__DEVELOPMENT__,
  bbrActivityTypesSortOrder:
    !!process.env.ENABLE_BBR_ACTIVITY_TYPES_SORT_ORDER || !!__DEVELOPMENT__,
  ...localConfig
} // NOTE: Also have to update `internals/webpack/webpack-base-cofnig.ts > ENV_DEFINITIONS`
