import {IRepCode} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class UserAccountRepCodeEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/rep-codes'

  static list() {
    return super._list<IApiListResponse<IRepCode>>({limit: 1000})
  }
}
