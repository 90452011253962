import {IRiskScoreCategory} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class RiskScoreCategories extends BaseRestEndpoints {
  static basePath = '/risk/risk-score-categories'

  static list() {
    return super._list<IApiListResponse<IRiskScoreCategory>>()
  }
}
