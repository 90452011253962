import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    topBarIconContainer: {
      minWidth: 0,
      paddingRight: theme.spacing(1)
    },
    topBarIcon: {
      color: theme.palette.primary.main
    },
    topBarLabel: {
      color: theme.palette.primary.main
    },
    menuItem: {
      height: '72px',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    menuItemLink: {
      '&:hover': {
        background: `${theme.palette.primary.main}25`,
        borderRadius: theme.shape.borderRadius
      }
    },
    activeMenuItem: {
      borderBottom: `4px solid ${theme.palette.primary.main}`
    }
  }
})
