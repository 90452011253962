import {IAccountsProfile} from '@d1g1t/api/models'

import {IApiListResponse} from '../typings'
import {BaseRestEndpoints} from './base'

export class AdvisoryScopesEndpoints extends BaseRestEndpoints {
  static basePath = '/advisory-scopes'

  static list() {
    return super._list<IApiListResponse<IAccountsProfile>>()
  }
}
