import {IAssetCategory} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AssetCategoryEndpoints extends BaseRestEndpoints {
  static basePath = '/asset-categories'

  static list() {
    return super._list<IApiListResponse<IAssetCategory>>()
  }
}
