import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {ApiError, useApi, useApiQuery} from 'fairlight'
import {Formik, FormikHelpers} from 'formik'
import * as Yup from 'yup'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckMarkIcon from '@material-ui/icons/Done'

import {FEATURE_FLAGS} from '@d1g1t/config/feature-flags'

import {AuthEndpoints, FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {testPhoneNumber} from '@d1g1t/lib/yup-validators'

import {Card} from '@d1g1t/shared/components/card'
import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {PhoneNumberInput} from '@d1g1t/shared/components/formatted-input'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {ButtonLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H1, H4, Text} from '@d1g1t/shared/components/typography'
import {loginPath} from '@d1g1t/shared/locations'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {LOGIN_PAGE_TRANSLATION_KEYS_SHARED} from '@d1g1t/shared/wrappers/localization-settings/constants'
import {useGlobalAuthLocalizationSettingsSynchronization} from '@d1g1t/shared/wrappers/localization-settings/hook'

import {LanguageSelector} from '../../containers/auth/language-selector'
import {CREATE_PASSWORD_FIELD_NAMES, INITIAL_VALUES} from './constants'
import {
  ICreatePasswordFormValues,
  ICreatePasswordPageProps,
  IParams
} from './typings'

import d1g1tFrontier from '@d1g1t/shared/assets/d1g1t-frontier.svg'

import * as css from './style.scss'

/**
 * Activate an account by creating a first password.
 */
export const CreatePasswordPage: React.FC<ICreatePasswordPageProps> = (
  props
) => {
  const api = useApi()

  const {t, i18n} = useTranslation()

  const {handleFormError, handleUnexpectedError} = useErrorHandler()

  const {contactId, token} = useParams<IParams>()
  const [confirmed, setConfirmed] = useState(false)

  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  useGlobalAuthLocalizationSettingsSynchronization(
    firmPreConfiguration.data?.investorFrenchEnabled
  )

  const [resetPasswordCheck] = useApiQuery(
    AuthEndpoints.resetPasswordCheck({
      uid: contactId,
      token
    }),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const renderAllSetCard = () => {
    return (
      <Card className={css.cardWidth} noMinHeight extraPadding>
        <Flex grow={1} alignCenter column>
          <CheckMarkIcon className={css.checkMarkIcon} />
          <H1>{LOGIN_PAGE_TRANSLATION_KEYS_SHARED.YOURE_ALL_SET}</H1>
        </Flex>
      </Card>
    )
  }

  if (!firmPreConfiguration.data) {
    return null
  }

  const mfaRequired = resetPasswordCheck.data?.isOtpMobilePhoneRequired

  const newPasswordErrorMessage = FEATURE_FLAGS.PASSWORD_COMPLEXITY
    ? t(
        LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS
      )
    : t(
        LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS
      )

  const renderCreatePasswordCard = () => {
    return (
      <Card className={css.cardWidth} noMinHeight extraPadding flexColumn>
        <H4>{LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CREATE_A_NEW_PASSWORD}</H4>
        <Spacer xs />
        <Text>
          {FEATURE_FLAGS.PASSWORD_COMPLEXITY
            ? LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS
            : LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS}
        </Text>
        <Spacer xs />
        <OutlinedInputField
          name={CREATE_PASSWORD_FIELD_NAMES.newPassword}
          label={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CREATE_A_PASSWORD}
          outlinedInputProps={{
            autoComplete: 'new-password',
            type: 'password'
          }}
          data-testid='password-field'
        />
        <Spacer xs />
        <OutlinedInputField
          name={CREATE_PASSWORD_FIELD_NAMES.reNewPassword}
          label={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RE_TYPE_YOUR_PASSWORD}
          outlinedInputProps={{
            autoComplete: 'new-password',
            type: 'password'
          }}
          data-testid='confirm-password-field'
        />
        {mfaRequired && (
          <>
            <Spacer xs />
            <Tooltip
              title={
                LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MOBILE_PHONE_NUMBER_COUNTRY_CODE
              }
              placement='bottom-start'
            >
              <span>
                <OutlinedInputField
                  name={CREATE_PASSWORD_FIELD_NAMES.mobilePhoneNumber}
                  label={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MOBILE_PHONE_NUMBER}
                  outlinedInputProps={{
                    inputComponent: PhoneNumberInput
                  }}
                  data-testid='mfa-phone-number-field'
                />
              </span>
            </Tooltip>
            <Text>{LOGIN_PAGE_TRANSLATION_KEYS_SHARED.TWILIO_MESSAGE}</Text>
          </>
        )}
        <Spacer md />
        <Flex justifyFlexEnd>
          <Button primary contained type='submit' data-testid='submit-button'>
            {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.SET_PASSWORD}
          </Button>
        </Flex>
      </Card>
    )
  }

  const handleSubmit = async (
    data: ICreatePasswordFormValues,
    formikBag: FormikHelpers<ICreatePasswordFormValues>
  ) => {
    try {
      await api.request(
        AuthEndpoints.resetPasswordConfirm({
          uid: contactId,
          token,
          newPassword: data.newPassword,
          reNewPassword: data.reNewPassword,
          mobilePhoneNumber: data.mobilePhoneNumber
        })
      )
      setConfirmed(true)
    } catch (error) {
      if (error instanceof ApiError && error.status === 400) {
        handleUnexpectedError(
          error,
          LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AN_UNEXPECTED_WHILE_CREATING_THE_PASSWORD,
          true,
          true
        )
      } else {
        handleFormError(
          error,
          formikBag,
          INITIAL_VALUES,
          LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AN_UNEXPECTED_WHILE_CREATING_THE_PASSWORD
        )
      }
    }
  }

  return (
    <Flex column fullHeight alignCenter justifyCenter key={i18n.language}>
      <img
        src={firmPreConfiguration.data.logoSvg || d1g1tFrontier}
        className={css.imgWidth}
      />
      <Spacer xs />
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={Yup.object({
          [CREATE_PASSWORD_FIELD_NAMES.newPassword]: Yup.string()
            .required(
              t(
                LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_IS_A_REQUIRED_FIELD
              )
            )
            .label(t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD))
            .min(
              FEATURE_FLAGS.PASSWORD_COMPLEXITY ? 16 : 8,
              newPasswordErrorMessage
            ),
          [CREATE_PASSWORD_FIELD_NAMES.reNewPassword]: Yup.string()
            .required(
              t(
                LOGIN_PAGE_TRANSLATION_KEYS_SHARED.NEW_PASSWORD_IS_A_REQUIRED_FIELD
              )
            )
            .min(
              FEATURE_FLAGS.PASSWORD_COMPLEXITY ? 16 : 8,
              newPasswordErrorMessage
            )
            .label(t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RE_TYPE_PASSWORD))
            .oneOf(
              [Yup.ref(CREATE_PASSWORD_FIELD_NAMES.newPassword)],
              t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORDS_DO_NOT_MATCH)
            ),
          [CREATE_PASSWORD_FIELD_NAMES.mobilePhoneNumber]: mfaRequired
            ? Yup.string()
                .nullable()
                .test(
                  ...testPhoneNumber(
                    t(
                      LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MUST_BE_A_VALID_PHONE_NUMBER
                    )
                  )
                )
                .required()
                .label(t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PHONE_NUMBER))
            : Yup.string()
                .nullable()
                .test(
                  ...testPhoneNumber(
                    t(
                      LOGIN_PAGE_TRANSLATION_KEYS_SHARED.MUST_BE_A_VALID_PHONE_NUMBER
                    )
                  )
                )
                .label(t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PHONE_NUMBER))
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <ControlStateProvider loading={formik.isSubmitting}>
            <form onSubmit={formik.handleSubmit} method='POST'>
              {!confirmed ? renderCreatePasswordCard() : renderAllSetCard()}
            </form>
            {confirmed && (
              <ButtonLink startIcon={<ArrowBackIcon />} to={loginPath()}>
                {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.BACK_TO_SIGN_IN}
              </ButtonLink>
            )}
          </ControlStateProvider>
        )}
      </Formik>
      {!props.advisor && firmPreConfiguration.data?.investorFrenchEnabled && (
        <LanguageSelector />
      )}
    </Flex>
  )
}
