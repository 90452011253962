import {useLayoutEffect, useRef} from 'react'

function shallowObjectIsEqual<T extends object>(objA: T, objB: T): boolean {
  if (Object.keys(objA).length !== Object.keys(objB).length) {
    return false
  }

  for (const key of Object.keys(objA)) {
    if (!Object.is(objA[key], objB[key])) {
      return false
    }
  }

  return true
}

function useShallowMemo<T extends object>(value: T): T {
  const ref = useRef<T>(value)

  if (!value || !shallowObjectIsEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

/**
 * Bind an event callback to the target. This method creates a stable callback
 * method to avoid adding/removing callbacks on each render.
 *
 * @param target - Target to attach the event
 * @param eventType - String key of the event, eg. 'keydown', 'mousedown'
 * @param cb - Event callback, can be memo-ed or not safely
 * @param options - Object passed to `addEventListener`, internally memoed using shallow equality
 */
export function useDomEventCallback<Key extends keyof DocumentEventMap>(
  target: Document,
  eventType: Key,
  cb: (evt: DocumentEventMap[Key]) => void,
  options?: AddEventListenerOptions
): void
export function useDomEventCallback<Key extends keyof HTMLElementEventMap>(
  target: HTMLElement,
  eventType: Key,
  cb: (evt: HTMLElementEventMap[Key]) => void,
  options?: AddEventListenerOptions
): void
export function useDomEventCallback<Key extends keyof WindowEventMap>(
  target: Window,
  eventType: Key,
  cb: (evt: WindowEventMap[Key]) => void,
  options?: AddEventListenerOptions
): void
export function useDomEventCallback<
  Key extends keyof (DocumentEventMap | HTMLElementEventMap | WindowEventMap)
>(
  target: Document | HTMLElement | Window,
  eventType: Key | string,
  cb: (
    evt: (DocumentEventMap | HTMLElementEventMap | WindowEventMap)[Key]
  ) => void,
  options?: AddEventListenerOptions
): void {
  const cbRef = useRef(cb)
  cbRef.current = cb

  const memoedOptions = useShallowMemo(options)

  useLayoutEffect(() => {
    if (!target) {
      return
    }

    const handleEvent: typeof cb = (event) => {
      cbRef.current(event)
    }

    target.addEventListener(eventType, handleEvent, memoedOptions)
    return () => {
      target.removeEventListener(eventType, handleEvent, memoedOptions)
    }
  }, [target, eventType, memoedOptions])
}
