import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {find} from 'lodash'

import {
  ACCOUNTSPROFILE_PAGE_LEVEL_DATE_PERIOD_TO_DATE_RANGES_MAP,
  DATE_RANGES_OPTIONS
} from '@d1g1t/api/models'

import {IDateRange} from '@d1g1t/lib/date-range'

import {ICalculationOptions} from '../calculations'
import {useUserProfile} from '../user-profile'
import {IPeriodSettings} from './typings'

export type CalculationOptionsOverrideValue = [
  Partial<IPeriodSettings> & {dateRangeOptions?: IDateRange[]},
  {
    updateCalculationPeriodOverride(settings: Partial<IPeriodSettings>): void
  }
]

export const CalculationOptionsOverrideContext =
  React.createContext<CalculationOptionsOverrideValue>([
    null,
    {
      updateCalculationPeriodOverride: () => {}
    }
  ])

export interface ICalculationOptionsOverrideProviderProps {
  staticValue?: Pick<Partial<ICalculationOptions>, 'dateRange'>
  /**
   * Must be in the default or the provided `dateRangeOptions` options.
   *
   * @remarks `DATE_OPTION_DEFAULT` by default
   */
  defaultPageLevelDateRangeOptionOverride?: IDateRange
  /**
   * Allows for different set of page level date range options than default.
   */
  pageLevelDateRangeOptionsOverride?: IDateRange[]
}

/**
 * Acts as a switch to enable the "Page Period" option in the range-selector component,
 * and enables the dropdown in the calculation-overrides and page-calculation-overrides components.
 *
 * @remarks This should be changed to accept any `ICalculationOptions` as override.
 */
export const CalculationOptionsOverrideProvider: React.FC<
  ICalculationOptionsOverrideProviderProps
> = ({
  staticValue,
  pageLevelDateRangeOptionsOverride: dateRangeOptions,
  defaultPageLevelDateRangeOptionOverride: defaultPageLevelDateRangeOption,
  children
}) => {
  const [{profile: userProfile}] = useUserProfile()

  const pageLevelDatePeriodDefault = {
    ...find(DATE_RANGES_OPTIONS, {
      value:
        userProfile &&
        ACCOUNTSPROFILE_PAGE_LEVEL_DATE_PERIOD_TO_DATE_RANGES_MAP[
          userProfile.pageLevelDatePeriod
        ]
    })
  }

  const [settings, setSettings] = useState<Partial<IPeriodSettings>>({
    pageLevelDateRange:
      defaultPageLevelDateRangeOption || pageLevelDatePeriodDefault
  })
  useEffect(() => {
    setSettings({
      pageLevelDateRange:
        defaultPageLevelDateRangeOption ?? pageLevelDatePeriodDefault
    })
  }, [userProfile?.pageLevelDatePeriod, defaultPageLevelDateRangeOption])

  const updateCalculationPeriodOverride = useCallback(
    (nextSettings: Partial<IPeriodSettings>) => {
      setSettings({
        ...settings,
        ...nextSettings
      })
    },
    [settings, setSettings]
  )

  const providerValue: CalculationOptionsOverrideValue = useMemo(() => {
    return [
      {
        setAllDateRange: null,
        ...settings,
        ...staticValue,
        dateRangeOptions
      },
      {updateCalculationPeriodOverride}
    ]
  }, [settings, setSettings])

  return (
    <CalculationOptionsOverrideContext.Provider value={providerValue}>
      {children}
    </CalculationOptionsOverrideContext.Provider>
  )
}
