import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {IFirmLogoSvgProps} from './typings'

import * as css from './style.scss'

/**
 * Component that renders sub firm logo (if there is one) or firm logo
 */
export const FirmLogoSvg: React.FC<IFirmLogoSvgProps> = ({
  className,
  ...props
}) => {
  const {firmConfiguration} = useFirmConfiguration()

  if (!firmConfiguration.data) {
    return null
  }

  return (
    <img
      className={classNames(css.logoSvgImg, className)}
      src={
        firmConfiguration.data.subfirm?.logoSvg ??
        firmConfiguration.data.logoSvg
      }
      {...props}
    />
  )
}
