import {createSelector} from 'reselect'

import {PREFIX} from './constants'
import {getCalculationId} from './lib'
import {initialStateSlice} from './reducers'
import {ICalculationState, IDomainSlice} from './typings'

export const getDomain = (state: Dictionary<any>): ICalculationState =>
  state[PREFIX]

export const getSliceId = (_, props) => getCalculationId(props)

export const createBoundGetter = (id: string) => {
  if (!id) {
    return (): IDomainSlice => ({
      response: {
        data: null,
        loading: false,
        error: null
      },
      instanceCount: null,
      loadingIndexMap: {},
      calcRequestBody: null
    })
  }

  const getSlice = (state: Dictionary) => {
    const domain = state[PREFIX]

    return domain[id]
  }

  return createSelector(getSlice, (slice): IDomainSlice => {
    return slice || initialStateSlice
  })
}

export const getCalculationKeys = createSelector(getDomain, (domain) =>
  Object.keys(domain)
)
