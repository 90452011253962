import {find} from 'lodash'

import {DATE_RANGES, DATE_RANGES_OPTIONS} from '@d1g1t/api/models'

import {UI_DATE_RANGES} from '@d1g1t/shared/components/range-selector/typings'

export type IDateRangeValueType = DATE_RANGES | UI_DATE_RANGES
export interface IDateRange extends IValueLabelOption<IDateRangeValueType> {
  /**
   * selected min date / microtimestamp
   */
  startDate?: string
  /**
   * selected max date / microtimestamp
   */
  endDate?: string
}

// NOTE: Need the `startDate` and `endDate` as `undefined` to match global settings
// shape correctly and pull the saved start/end dates out of the global settings

export const DATE_OPTION_DEFAULT: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.YEAR
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_DAY: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.DAY
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_MONTH: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.MONTH
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_QUARTER: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.QUARTER
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_SIX_MONTHS: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.SIX_MONTHS
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_YEAR: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.YEAR
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_YTD: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.YTD
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_CUSTOM: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.CUSTOM
  }),
  startDate: undefined,
  endDate: undefined
}

export const DATE_OPTION_SINCE_INCEPTION: IDateRange = {
  ...find(DATE_RANGES_OPTIONS, {
    value: DATE_RANGES.SINCE_INCEPTION
  }),
  startDate: undefined,
  endDate: undefined
}

const defaultDateRanges = [
  DATE_RANGES.MONTH,
  DATE_RANGES.QUARTER,
  DATE_RANGES.SIX_MONTHS,
  DATE_RANGES.YTD,
  DATE_RANGES.YEAR,
  DATE_RANGES.THREE_YEARS,
  DATE_RANGES.FIVE_YEARS,
  DATE_RANGES.SINCE_INCEPTION,
  DATE_RANGES.CUSTOM
]
const defaultDocumentsDateRanges = [
  DATE_RANGES.DAY,
  DATE_RANGES.WEEK,
  DATE_RANGES.MONTH,
  DATE_RANGES.QUARTER,
  DATE_RANGES.SIX_MONTHS,
  DATE_RANGES.YEAR,
  DATE_RANGES.CUSTOM
]
const defaultCalculatedFeesDateRanges = [
  DATE_RANGES.MONTH,
  DATE_RANGES.QUARTER,
  DATE_RANGES.SIX_MONTHS,
  DATE_RANGES.YEAR,
  DATE_RANGES.CUSTOM
]
/**
 * Only use these default options on Net Asset Value History table date ranges AND transaction log
 */
const defaultWithSinceActivationDateRanges = [
  DATE_RANGES.MONTH,
  DATE_RANGES.QUARTER,
  DATE_RANGES.SIX_MONTHS,
  DATE_RANGES.YTD,
  DATE_RANGES.YEAR,
  DATE_RANGES.THREE_YEARS,
  DATE_RANGES.FIVE_YEARS,
  DATE_RANGES.SINCE_ACTIVATION,
  DATE_RANGES.SINCE_INCEPTION,
  DATE_RANGES.CUSTOM
]

const defaultTransactionDateRanges = [
  DATE_RANGES.DAY,
  DATE_RANGES.WEEK,
  DATE_RANGES.MONTH,
  DATE_RANGES.QUARTER,
  DATE_RANGES.SIX_MONTHS,
  DATE_RANGES.YTD,
  DATE_RANGES.YEAR,
  DATE_RANGES.CUSTOM,
  DATE_RANGES.SINCE_ACTIVATION
]

const defaultLogDetailsTransactionDateRanges = [
  ...[DATE_RANGES.DAY],
  ...defaultWithSinceActivationDateRanges
].filter(
  (dateRangeOption) =>
    ![
      DATE_RANGES.THREE_YEARS,
      DATE_RANGES.FIVE_YEARS,
      DATE_RANGES.SINCE_ACTIVATION,
      DATE_RANGES.SINCE_INCEPTION
    ].includes(dateRangeOption)
)

const defaultWithSinceInceptionTransactionDateRanges = [
  ...[DATE_RANGES.DAY],
  ...defaultWithSinceActivationDateRanges
].filter(
  (dateRangeOption) =>
    ![
      DATE_RANGES.THREE_YEARS,
      DATE_RANGES.FIVE_YEARS,
      DATE_RANGES.SINCE_ACTIVATION
    ].includes(dateRangeOption)
)

export const DEFAULT_DATE_OPTIONS: IDateRange[] = DATE_RANGES_OPTIONS.filter(
  (option) => defaultDateRanges.indexOf(option.value) !== -1
)

export const DEFAUT_WITH_SINCE_ACTIVATION_DATE_OPTIONS: IDateRange[] =
  DATE_RANGES_OPTIONS.filter((option) =>
    defaultWithSinceActivationDateRanges.includes(option.value)
  )

export const DEFAULT_TRANSACTION_DATE_OPTIONS: IDateRange[] =
  DATE_RANGES_OPTIONS.filter((option) =>
    defaultTransactionDateRanges.includes(option.value)
  )

export const DEFAULT_LOG_DETAILS_DATE_OPTIONS: IDateRange[] =
  DATE_RANGES_OPTIONS.filter((option) =>
    defaultLogDetailsTransactionDateRanges.includes(option.value)
  )
export const DEFAULT_SINCE_INCEPTION_TRANSACTION_DATE_OPTIONS: IDateRange[] =
  DATE_RANGES_OPTIONS.filter((option) =>
    defaultWithSinceInceptionTransactionDateRanges.includes(option.value)
  )
export const CALCULATED_FEES_DATE_OPTIONS: IDateRange[] =
  DATE_RANGES_OPTIONS.filter((option) =>
    defaultCalculatedFeesDateRanges.includes(option.value)
  )

export const DOCUMENTS_DATE_OPTIONS: IDateRange[] = DATE_RANGES_OPTIONS.filter(
  (option) => defaultDocumentsDateRanges.includes(option.value)
)

/**
 * Used to find the corresponding object from DATE_RANGES_OPTIONS by known label
 * And returns corresponding object with extra startData, endDate fields
 * That is used as default date value for investor portal page level
 * @param dateOption - received from ui config label
 */
export function getDateRangeOption(dateOption: DATE_RANGES): IDateRange {
  return {
    ...find(DATE_RANGES_OPTIONS, {
      value: dateOption
    }),
    startDate: undefined,
    endDate: undefined
  }
}
