import React from 'react'

import {H1} from '@d1g1t/shared/components/typography'

import {COMPONENT_SPECIFIC_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

/**
 * A simple component used to diplay a `Page Not Found` heading when a page could not be found
 */
export const InvestorAppNotFoundPage: React.FC = () => (
  <H1>{COMPONENT_SPECIFIC_TRANSLATION_KEYS.NOT_FOUND_PAGE}</H1>
)
