import React, {createContext} from 'react'

import {UseApiQueryData} from 'fairlight'
import {IControl} from 'typings/general'

import {IApiListResponse} from '@d1g1t/api/endpoints'
import {IFilterRule} from '@d1g1t/api/models'

import {IAppliedRuleFilters} from '@d1g1t/shared/containers/rule-filter-modal'
import {ISelectEntitiesContext} from '@d1g1t/shared/containers/select-entities'

export interface ICalculationContextValue {
  /**
   * Disables local cache for calculations, overrides settings passed into
   * `useCalculationData`
   */
  disableCache?: boolean
  /**
   * Disables rule filters for calculations. Prevents rule filter list api from
   * being called and exludes `filter_sets` request payload.
   */
  disableRuleFilters?: boolean
  /**
   * Allows a context to override the default implementation of getUrlAppliedRuleFilters
   *
   * Needed for investor as it's select entities state can generate inaccurate
   * `urlAppliedRuleFilters` by default when selecting the consolidated portfolio
   * option
   */
  getUrlAppliedRuleFilters?: (
    selectedEntities: ISelectEntitiesContext,
    allRuleFilters: UseApiQueryData<IApiListResponse<IFilterRule>>,
    control?: IControl
  ) => IAppliedRuleFilters[]
}

/**
 * Context that provids overrides to default params.
 * However, explicitly setting them when calling `useCalculationData` will
 * override these values.
 */
export const CalculationContext = createContext<ICalculationContextValue>({})

export const CalculationContextProvider: React.FC<ICalculationContextValue> = ({
  children,
  ...props
}) => {
  return (
    <CalculationContext.Provider value={props}>
      {children}
    </CalculationContext.Provider>
  )
}
