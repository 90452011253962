import {ACCOUNTSHOLDINGREBALANCINGREQUEST_TARGET_MODE} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export interface IApplyTradeLocationRequest {
  /** mandate url */
  investmentMandate: string
  asOfDate: string
  targetMode: ACCOUNTSHOLDINGREBALANCINGREQUEST_TARGET_MODE
}

export class TradeLocationEndpoints extends BaseRestEndpoints {
  static basePath = '/rebalancing/trade-location'

  static apply(body: IApplyTradeLocationRequest) {
    return super._post('/apply', {body})
  }
}
