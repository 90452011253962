import {isNil, sortBy} from 'lodash'

import {
  ComparisonValue,
  IFilterSets,
  IFilterValue,
  IFilterValueItem
} from '@d1g1t/typings/general'

/**
 * Provides a deterministic string id of the filter items, based on its values.
 * Returns the same ID for equivalent filters with stable ordering
 *
 * Use `stableFilterId` to get ID for full filter value
 */
export function stableFilterItemsId(items: IFilterValueItem[]): string {
  const itemKeys: (keyof IFilterValueItem)[] = [
    'filterCriterion',
    'roleId',
    'teamId',
    'value'
  ]
  const itemsId = []
  for (const item of sortBy(items, 'id')) {
    const itemId = []
    for (const key of itemKeys) {
      if (!isNil(item[key])) {
        itemId.push(item[key])
      }
    }
    itemsId.push(itemId.join('-'))
  }

  return itemsId.join(';')
}

/**
 * Provides a deterministic string id of the filter, based on its values.
 * Returns the same ID for equivalent filters with stable ordering
 *
 * Handles null case
 */
export function stableFilterId(filter: IFilterValue | IFilterSets): string {
  if (!filter || !filter.items) {
    return ''
  }

  const entitiesId = 'entities' in filter ? `${filter.entities.join()}:` : ''

  return `filter=${filter.joinOperator}:${entitiesId}${stableFilterItemsId(
    filter.items
  )}`
}

export const parseFilterValue = (
  value: string,
  options: {
    isNumber: boolean
    isMultiple: boolean
  }
): ComparisonValue => {
  if (options.isMultiple) {
    return value
      .split('|')
      .map((v) =>
        options.isNumber ? Number(v.trim()) : v.trim()
      ) as ComparisonValue
  }

  return options.isNumber ? Number(value) : value
}

export const formatFilterValue = (value: ComparisonValue): string => {
  if (isNil(value)) {
    return ''
  }

  if (Array.isArray(value)) {
    if (typeof value[0] === 'string') {
      return value.join('|')
    }

    return (value as number[])
      .map((singleValue) => String(singleValue))
      .join('|')
  }

  return String(value)
}
