import {UseApiQueryData} from 'fairlight'
import {IControl} from 'typings/general'

import {IApiListResponse} from '@d1g1t/api/endpoints'
import {IFilterRule} from '@d1g1t/api/models'

import {IAppliedRuleFilters} from '@d1g1t/shared/containers/rule-filter-modal'
import {ISelectEntitiesContext} from '@d1g1t/shared/containers/select-entities'
import {getUrlAppliedRuleFilters} from '@d1g1t/shared/wrappers/calculations/lib'

import {isConsolidatedOptionSelected} from '@d1g1t/investor/containers/select-entities/components/entity-chip/lib'

/**
 * Used by Investor portal's `useCalculationData` hook to extract the applied filters
 * for the current entity selections. This has custom logic for when the `Consolidated Portfolio`
 * option is selected and defaults to `getUrlAppliedRuleFilters`
 */
export function getInvestorUrlAppliedRuleFilters(
  selectedEntities: Pick<ISelectEntitiesContext, 'selection'>,
  allRuleFilters: UseApiQueryData<IApiListResponse<IFilterRule>>,
  control: IControl
): IAppliedRuleFilters[] {
  const selected = selectedEntities.selection.selected
  if (isConsolidatedOptionSelected(selected)) {
    const selectedEntity = selected?.[0]

    if (!selectedEntity?.ruleFilters || !control?.selectedEntities) {
      return []
    }

    const entityFilters = allRuleFilters.data.results.filter((rule) =>
      selectedEntity.ruleFilters?.includes(rule.url)
    )

    const accounts =
      selected[0].accounts ??
      control.selectedEntities.accountsOrPositions[0] ??
      []

    return accounts.map((entityId) => ({
      entityId,
      appliedFilters: entityFilters
    }))
  }

  return getUrlAppliedRuleFilters(selectedEntities, allRuleFilters)
}
