import React from 'react'
import {Helmet} from 'react-helmet-async'
import {Route, Switch} from 'react-router-dom'

import {useApiQuery} from 'fairlight'
import {merge} from 'lodash'

import {MuiThemeProvider} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles'

import {BASIC_COLOURS} from '@d1g1t/config/theme/internal-theme'
import {IThemeOptions, muiGenericTheme} from '@d1g1t/config/theme/mui-theme'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {ConfirmationModal} from '@d1g1t/shared/components/confirmation'
import {LoginPage, LogoutPage} from '@d1g1t/shared/containers/auth/login-page'
import {SnackbarProvider} from '@d1g1t/shared/containers/snackbar'
import {AuthenticationLocations, loginPath} from '@d1g1t/shared/locations'
import {CreatePasswordPage} from '@d1g1t/shared/pages/create-password'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {AuthenticatedRoutes} from './authenticated-routes'

import favicon from '@d1g1t/shared/assets/favicon32x32.png'

export const BaseRoutes: React.FC = () => {
  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  /**
   *  Specific style to apply for Investor App light theme
   */
  const muiThemeInvestorLightOverrides: IThemeOptions = {
    palette: merge(
      {},
      {
        main: BASIC_COLOURS.DARK_BLUE,
        dark: '#19345d',
        light: '#e3e3e3',
        accent: BASIC_COLOURS.LIGHT_BLUE
      },
      firmPreConfiguration.data?.themeInvestor?.palette
    )
  }

  /**
   * * Light mode theme for investor app. It inherits from muiGenericTheme and muiLightThemeAdvisor, but
   *  has specific to Investor App overrides for primary colours
   */
  const muiLightThemeInvestor = createTheme({
    ...muiGenericTheme,
    ...muiThemeInvestorLightOverrides
  })
  if (!firmPreConfiguration.data) {
    return null
  }
  return (
    <MuiThemeProvider theme={muiLightThemeInvestor}>
      <ConfirmationModal />
      <Helmet>
        <link rel='shortcut icon' type='image/png' href={favicon} />
      </Helmet>
      <ErrorBoundary resetId='no-reset'>
        <SnackbarProvider>
          <Switch>
            <Route path={loginPath()}>
              <LoginPage />
            </Route>
            <Route path={AuthenticationLocations.logout()}>
              <LogoutPage />
            </Route>
            <Route path='/activate/:contactId/:token'>
              <CreatePasswordPage />
            </Route>
            <Route path='*' component={AuthenticatedRoutes} />
          </Switch>
        </SnackbarProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  )
}
