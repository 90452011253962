import {format} from 'date-fns'
import * as locales from 'date-fns/locale'

import {
  DATE_RANGES,
  DOCUMENTTYPE_GROUP_BY,
  IDocumentSubType,
  IWorkdocsDocument
} from '@d1g1t/api/models'

import {IDateRange} from '@d1g1t/lib/date-range'
import {parseDate} from '@d1g1t/lib/formatters/date-formatter'

export interface IWorkdocsDocumentGrouped {
  [date: string]: IWorkdocsDocument[]
}
/**
 * Returns month and year or year based on `groupBy`
 * @param groupBy - grouping param
 */
export const getGroupingParam = (groupBy: DOCUMENTTYPE_GROUP_BY): string => {
  return groupBy === DOCUMENTTYPE_GROUP_BY.YEAR ? 'yyyy' : 'MMMM yyyy'
}

/**
 * Converts array of documents to object with creation month and year/ year based on `groupBy`
 * as the key, and array of all the documents created on that period as a value
 *
 * @param documents - array of documents
 * @param groupBy - if set to `DOCUMENTTYPE_GROUP_BY.YEAR` will grouped by year, otherwise by month and year
 */
export const groupDocuments = (
  documents: IWorkdocsDocument[],
  groupBy: DOCUMENTTYPE_GROUP_BY,
  locale?: string
): IWorkdocsDocumentGrouped => {
  const result = {}
  const groupingParam = getGroupingParam(groupBy)

  for (const document of documents) {
    const key = format(parseDate(document.reportingDate), groupingParam, {
      locale: locales[locale ?? 'en']
    })
    result[key] = (result[key] || []).concat(document)
  }
  return result
}

/**
 * Method to filter documents by a given document subtype.
 *
 * @param documents - list of documents to be filtered
 * @param subtypes - document subtypes to filter for
 *
 * @returns `documents` with `subtype` property that match the `subtype.name` property
 */
export const filterDocumentsSubtype = (
  documents: IWorkdocsDocument[],
  subtypes: IDocumentSubType[]
): IWorkdocsDocument[] => {
  if (!subtypes?.length) {
    return documents
  }

  const subTypeNames = subtypes.map(
    (subtype) => subtype.originalName || subtype.name
  )

  return documents.filter((document) => {
    return subTypeNames.includes(document.subtype)
  })
}

/**
 * Method to filter documents by a given date range
 *
 * @param documents - list of documents to be filtered
 * @param dateRange - date range object to be used
 * @param todayDate - can pass a custom date as today's date (mostly used for testing)
 *
 * @returns `documents` that fit between the date range provided
 */
export const filterDocumentsDateRange = (
  documents: IWorkdocsDocument[],
  dateRange: IDateRange,
  todayDate?: string
): IWorkdocsDocument[] => {
  if (!documents) {
    return null
  }

  const moveMonth = (date: Date, numberOfMonths: number): void => {
    date.setMonth(date.getMonth() - numberOfMonths)
  }

  const moveYear = (date: Date, numberOfYears: number): void => {
    date.setFullYear(date.getFullYear() - numberOfYears)
  }

  const startDate = ((): Date => {
    if (dateRange.startDate) {
      return new Date(dateRange.startDate)
    }

    const date = todayDate ? new Date(todayDate) : new Date()

    switch (dateRange.value) {
      case DATE_RANGES.MONTH:
        moveMonth(date, 1)
        break
      case DATE_RANGES.QUARTER:
        moveMonth(date, 3)
        break
      case DATE_RANGES.SIX_MONTHS:
        moveMonth(date, 6)
        break
      case DATE_RANGES.YEAR:
        moveYear(date, 1)
        break
      case DATE_RANGES.THREE_YEARS:
        moveYear(date, 3)
        break
      case DATE_RANGES.FIVE_YEARS:
        moveYear(date, 5)
        break
      case DATE_RANGES.YTD:
        return new Date(date.getFullYear(), 0, 1)
    }

    return date
  })()

  const endDate = ((): Date => {
    if (dateRange.endDate) {
      return new Date(dateRange.endDate)
    }

    return new Date()
  })()

  return documents.filter((document) => {
    const documentDate = new Date(document.reportingDate)

    return startDate <= documentDate && documentDate <= endDate
  })
}
