import {put, takeEvery} from 'redux-saga/effects'

import {ApiError} from 'fairlight'
import {ActionType, getType} from 'typesafe-actions'

import {snackbarActions} from '@d1g1t/shared/containers/snackbar'

import {errorHandlerActions} from './actions'
import {
  convertMessagesToReactNodes,
  errorShouldExpireSession,
  getErrorMessagesFromApiError,
  reportError
} from './lib'

const DEFAULT_MESSAGE = 'An unexpected error occurred.'

function* handleError(
  action: ActionType<typeof errorHandlerActions['handleError']>
) {
  const {
    payload: {
      error,
      snackbarMessage,
      unwrapErrorMessages = false,
      displayAllErrors = false,
      hideParentKey = false
    }
  } = action

  if (errorShouldExpireSession(error)) {
    // don't display error message since the user should be automatically logged out
    return
  }

  reportError(error)

  const messages = getErrorMessagesFromApiError(error, hideParentKey)
  const nodes = convertMessagesToReactNodes(messages)
  const node = displayAllErrors ? nodes : nodes[0]

  if (error instanceof ApiError) {
    if (error.status === 403) {
      yield put(
        snackbarActions.show({
          variant: 'warning',
          message:
            error.responseBody?.detail ||
            "You don't have permissions to perform this operation."
        })
      )
      return
    }
    if (error.status === 404) {
      yield put(
        snackbarActions.show({
          variant: 'warning',
          message:
            "Requested resource does not exist or you don't have permissions to see it."
        })
      )
      return
    }
    if (error.status === 400) {
      yield put(
        snackbarActions.show({
          variant: 'warning',
          message:
            node || "Data needed to perform this operation wasn't provided."
        })
      )
      return
    }
    if (error.status === 415) {
      yield put(
        snackbarActions.show({
          variant: 'warning',
          message: 'Unsupported media type in request.'
        })
      )
      return
    }
  }

  if (unwrapErrorMessages && messages.length > 0) {
    yield put(
      snackbarActions.show({
        variant: 'error',
        message: node || snackbarMessage || DEFAULT_MESSAGE
      })
    )
  } else {
    yield put(
      snackbarActions.show({
        variant: 'error',
        message: snackbarMessage || DEFAULT_MESSAGE
      })
    )
  }
}

export function* errorHandlerSagas() {
  yield takeEvery(getType(errorHandlerActions.handleError), handleError)
}
