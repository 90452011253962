import {useEffect, useMemo} from 'react'

import {configureScope} from '@sentry/browser'
import {useApiQuery} from 'fairlight'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'
import {IFirmConfiguration} from '@d1g1t/api/models'

import {
  IUiConfiguration,
  IUseFirmConfigurationHookReturnValue
} from './constants'
import {mergeDefaultAndFirmConfigUiConfig} from './lib'

let hasSetFirmTag = false

/**
 * Returns a cached firm configuration and sets the sentry scope the first
 * time it loads.
 */
export function useFirmConfiguration(): IUseFirmConfigurationHookReturnValue {
  const [firmConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.current(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const firmUiConfig: Loadable<IUiConfiguration> = useMemo(() => {
    if (!firmConfiguration.data) {
      return {loading: true, data: null, error: null}
    }
    return {
      loading: true,
      data: mergeDefaultAndFirmConfigUiConfig(firmConfiguration.data.uiConfig),
      error: null
    }
  }, [firmConfiguration.data?.uiConfig])

  // once the firm configuration loads,
  // update the sentry scope
  useEffect(() => {
    if (firmConfiguration.data) {
      configureScope((scope) => {
        if (!hasSetFirmTag) {
          scope.setTag('firm', firmConfiguration.data.displayName)
          hasSetFirmTag = true
        }
      })
    }
  }, [firmConfiguration.data])

  if (!firmConfiguration.data && !!firmConfiguration.loading) {
    return {
      firmConfiguration: {data: null, loading: true, error: null},
      firmUiConfig: {data: null, loading: true, error: null}
    }
  }

  return {firmConfiguration, firmUiConfig}
}

export interface IFirmConfigurationChildProps {
  firmConfiguration: Loadable<IFirmConfiguration>
}
