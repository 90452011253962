import {makeStyles} from '@material-ui/core'

import {ITheme} from '@d1g1t/config/theme/mui-theme'

export const useStyles = makeStyles((theme: ITheme) => {
  return {
    tagBase: {
      backgroundColor: theme.palette.accent
    }
  }
})
