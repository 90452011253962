import React from 'react'
import {useRouteMatch} from 'react-router-dom'

import {INVESTORPORTALSECTIONNOTES_SECTION} from '@d1g1t/api/models'

import {Text} from '@d1g1t/shared/components/typography'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {
  ActivityLocations,
  DocumentLocations,
  DocumentsDmsInvestorLocations,
  MonitorLocations,
  PortfolioLocations
} from '@d1g1t/investor/locations'

import style from './style.scss'

export const SectionNotes = () => {
  const {firmConfiguration} = useFirmConfiguration()

  const section = ((): INVESTORPORTALSECTIONNOTES_SECTION => {
    const isHome = useRouteMatch({
      exact: true,
      path: MonitorLocations.basePath
    })
    const isInvestments = useRouteMatch({
      exact: true,
      path: PortfolioLocations.basePath
    })
    const isActivity = useRouteMatch({
      exact: true,
      path: ActivityLocations.basePath
    })
    const isDoc = useRouteMatch({
      exact: true,
      path: [
        DocumentLocations.basePath,
        DocumentsDmsInvestorLocations.basePath,
        DocumentsDmsInvestorLocations.documents()
      ]
    })

    if (isHome) {
      return INVESTORPORTALSECTIONNOTES_SECTION.HOME
    }
    if (isInvestments) {
      return INVESTORPORTALSECTIONNOTES_SECTION.INVESTMENTS
    }
    if (isActivity) {
      return INVESTORPORTALSECTIONNOTES_SECTION.ACTIVITY
    }
    if (isDoc) {
      return INVESTORPORTALSECTIONNOTES_SECTION.DOCUMENTS
    }
  })()

  const noteToShow = firmConfiguration.data?.investorPortalSectionNotes.find(
    (note) => note.section === section
  )

  if (noteToShow) {
    return (
      <Text alignRight className={style.note} data-testid='section-note'>
        {noteToShow.note}
      </Text>
    )
  }

  return null
}
