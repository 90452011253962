import {BaseEndpoints} from './base'

interface ISystemStatus {
  version: string
  tag: string
}

export class SystemStatusEndpoints extends BaseEndpoints {
  static basePath = '/system-status'

  static systemStatus() {
    return super._get<ISystemStatus>('')
  }
}
