import React from 'react'

/**
 * Creates a boolean state toggle
 *
 * @returns
 *   0. current state of value
 *   1. toggle state
 *   2. set true
 *   3. set false
 */
export function useToggleState(
  defaultValue: boolean | (() => boolean)
): [boolean, (nextState?: any) => any, () => any, () => any] {
  const [state, updateState] = React.useState(defaultValue)

  const toggleState = React.useCallback(
    (nextState: any) => {
      if (typeof nextState === 'boolean') {
        updateState(nextState)
      } else {
        updateState(!state)
      }
    },
    [state]
  )

  const setTrue = React.useCallback(() => {
    updateState(true)
  }, [])

  const setFalse = React.useCallback(() => {
    updateState(false)
  }, [])

  return [state, toggleState, setTrue, setFalse]
}
