import {CALCULATION_CODES} from '@d1g1t/api/models'

export const PREFIX = 'CALCULATIONS'

/**
 * Used in
 *   `pf-activity-group1`
 *   `performance-range`
 *   `distribution`
 */
export enum METRICS {
  /**
   * `pf-activity-group1`
   */
  ALLOCATION_CHANGES = 'allocationChanges',
  /**
   * `pf-activity-group1`
   */
  ALLOCATION = 'allocation',
  /**
   * `pf-activity-group1`
   */
  INFLOWS_OUTFLOWS = 'inflowsOutflows',
  /**
   * `performance-range`
   * `distribution`
   */
  RETURN = 'return'
}

/**
 * Used in
 *   `top-bottom-performers`
 */
export enum MOVER_ENTITY_TYPE {
  HOUSEHOLD = 'Household',
  CLIENT = 'Client',
  SECURITY = 'Security'
}

/**
 * Used in
 *   `top-bottom-performers`
 */
export enum MOVER_TYPE {
  TOP_10 = 'top10',
  BOTTOM_10 = 'bottom10'
}

/**
 * Used in
 *   `top-bottom-performers`
 */
export enum MOVER_VIEW_TYPE {
  PAST = 'past',
  PRESENT = 'present'
}

export const TREND_CALCULATION_CODES: CALCULATION_CODES[] = [
  CALCULATION_CODES.TREND_ALLOC,
  CALCULATION_CODES.TREND_AUM,
  CALCULATION_CODES.TREND_CUMUL_RET,
  CALCULATION_CODES.TREND_DRAWDOWNS,
  CALCULATION_CODES.TREND_FORECASTED_VOLAT,
  CALCULATION_CODES.TREND_REALIZD_VOLAT,
  CALCULATION_CODES.TREND_RET_BY_PERIOD,
  CALCULATION_CODES.TREND_VALUE_AT_RISK,
  CALCULATION_CODES.TREND_VAR
]

/**
 * Defines the codes which will automatically have its columns
 * sorted based on ordering of metrics
 */
export const REORDERABLE_TABLE_CODES: CALCULATION_CODES[] = [
  CALCULATION_CODES.CPH_TABLE,
  CALCULATION_CODES.NCP_TABLE,
  CALCULATION_CODES.LOG_DETAILS,
  CALCULATION_CODES.ACCOUNT_HOLDINGS,
  CALCULATION_CODES.ADMIN_ACCOUNTS,
  CALCULATION_CODES.ADMIN_SECURITIES,
  CALCULATION_CODES.MANAGE_FUNDS,
  CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS,
  CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES,
  CALCULATION_CODES.ADMIN_CONTACTS,
  CALCULATION_CODES.ADMIN_CLIENTS,
  CALCULATION_CODES.TRACK_MANDATES,
  CALCULATION_CODES.TAX_LOT_REALIZED_GAINS_N_LOSSES,
  CALCULATION_CODES.TAX_LOT_UNREALIZED_GAINS_N_LOSSES,
  CALCULATION_CODES.ADMIN_CUSTODIAN_ACCOUNTS
]

/**
 * Response post-processing will be skipped for these codes
 */
export const NON_STANDARD_CODES: CALCULATION_CODES[] = [
  CALCULATION_CODES.CONTRIBUTION_DRILLDOWN,
  CALCULATION_CODES.DISTRIBUTION,
  CALCULATION_CODES.PERFORMANCE_RANGE,
  CALCULATION_CODES.RISK_RETURN_MAP
]

/**
 * Defines the calculations that support pagination, and
 * the `loadDataIncrementally` config option.
 */
export const PAGINATED_CALCULATION_CODES: CALCULATION_CODES[] = [
  CALCULATION_CODES.CPH_TABLE,
  CALCULATION_CODES.LOG_DETAILS,
  CALCULATION_CODES.MANAGE_FUNDS,
  CALCULATION_CODES.ADMIN_USERS,
  CALCULATION_CODES.ADMIN_CLIENTS,
  CALCULATION_CODES.ADMIN_CONTACTS,
  CALCULATION_CODES.ADMIN_SECURITIES,
  CALCULATION_CODES.ADMIN_USERS,
  CALCULATION_CODES.ADMIN_ACCOUNTS,
  CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS,
  CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES,
  CALCULATION_CODES.NCP_TABLE
]

/**
 * Enum of non `calc` endpoints that will use calculation hook for pagination
 */
export enum NON_CALC_PAGINATED_ENDPOINTS {
  TRADING_DRAFTS_CHART_ALL = 'trading-drafts-chart-all',
  DMS_DOCUMENTS = 'dms-documents'
}
