import React from 'react'

import {IFirmConfiguration} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {H2, Text} from '@d1g1t/shared/components/typography'

import {FOOTER_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

import {useStyles} from '../../use-style'

import * as css from '../../style.scss'

export const Resources: React.FC<
  Pick<
    IFirmConfiguration,
    | 'customLinks'
    | 'privacyPolicy'
    | 'privacyPolicyLabel'
    | 'termsOfUse'
    | 'termsOfUseLabel'
  >
> = ({
  customLinks,
  privacyPolicy,
  privacyPolicyLabel,
  termsOfUse,
  termsOfUseLabel
}) => {
  const classes = useStyles()
  if (!customLinks?.length) {
    return null
  }

  return (
    <Flex column className={classNames(css.footerSection, css.resources)}>
      {(customLinks.length || privacyPolicy || termsOfUse) && (
        <H2 bold className={css.footerSectionLabel}>
          {FOOTER_TRANSLATION_KEYS.RESOURCES}
        </H2>
      )}
      <Text bold>{FOOTER_TRANSLATION_KEYS.LEARN_MORE}</Text>
      <div className={css.footerSectionItem}>
        {customLinks.map((customLink, index) => (
          <Text
            data-testid={`footer-custom-link-${index}`}
            key={customLink.customLink + index}
            className={classes.footerLink}
            onClick={() => {
              window.open(customLink.customLink, '_blank')
            }}
          >
            {customLink.customLabel}
          </Text>
        ))}
      </div>
      <Text bold>{FOOTER_TRANSLATION_KEYS.LEGAL}</Text>
      {privacyPolicy && (
        <Text
          data-testid='footer-privacy-policy-link'
          className={classes.footerLink}
          onClick={() => {
            window.open(privacyPolicy, '_blank')
          }}
        >
          {privacyPolicyLabel || 'Privacy Policy'}
        </Text>
      )}
      {termsOfUse && (
        <Text
          data-testid='footer-terms-link'
          className={classes.footerLink}
          onClick={() => {
            window.open(termsOfUse, '_blank')
          }}
        >
          {termsOfUseLabel || 'Terms and Conditions'}
        </Text>
      )}
    </Flex>
  )
}
