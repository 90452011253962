export enum CREATE_PASSWORD_FIELD_NAMES {
  newPassword = 'newPassword',
  reNewPassword = 'reNewPassword',
  mobilePhoneNumber = 'mobilePhoneNumber'
}

export const INITIAL_VALUES = {
  [CREATE_PASSWORD_FIELD_NAMES.newPassword]: '',
  [CREATE_PASSWORD_FIELD_NAMES.reNewPassword]: ''
}
