import React, {forwardRef} from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface ITwoColumnPageGridProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.Ref<HTMLDivElement>
}

export const TwoColumnPageGrid: React.FC<ITwoColumnPageGridProps> = forwardRef(
  ({className, ...props}, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(css.twoColumnPageGrid, className)}
      />
    )
  }
)
