import {BaseRestEndpoints} from './base'

export class ApprovalsEndpoints extends BaseRestEndpoints {
  static basePath = '/approvals'

  static approve(id) {
    return super._post<never>(`/${id}/approve`)
  }

  static bulkApprove(body: {approvals: string[]}) {
    return super._post<never>('/bulk-approve', {
      body
    })
  }

  static bulkDeny(body: {approvals: string[]}) {
    return super._post<never>('/bulk-deny', {
      body
    })
  }
}
