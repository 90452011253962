import {RouteBuilder} from '@d1g1t/lib/url'

export class DocumentsLocations extends RouteBuilder {
  static basePath = '/documents'

  static reportTemplates() {
    return super.path('/reports')
  }

  static newReport() {
    return super.path('/new-report')
  }

  static singleReport(id: string) {
    return super.path(`/reports/${id}`)
  }

  static documents() {
    return super.path('/manage-documents')
  }
}
