import {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {useApiQuery} from 'fairlight'
import produce from 'immer'

import {
  EntityEndpoints,
  IDefaultReportingCurrencyRequest
} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'

import {useIsControlled} from '@d1g1t/lib/hooks/use-controlled-change-warning'
import {
  extractIdFromUrl,
  serializeSelection,
  UrlSelection
} from '@d1g1t/lib/url'

import {ISearchResult} from '@d1g1t/shared/containers/search'
import {
  ISelectedEntity,
  ISelectedEntityApplyEntityFilterOptions,
  ISelectedEntityFilters,
  ISelectEntitiesActions,
  ISelectEntitiesState
} from '@d1g1t/shared/containers/select-entities'
import {reportError} from '@d1g1t/shared/wrappers/error-handler'
import {useUrlQueryParams} from '@d1g1t/shared/wrappers/url-query-params'

import {
  SELECT_ENTITIES_KEY,
  SELECT_ENTITIES_QUERY_PARAMS_VALIDATION_SCHEMA
} from './constants'

type SelectEntitiesHookValue = [
  /**
   * Typed, cast, validated query params from the URL.
   */
  Partial<ISelectEntitiesState>,
  ISelectEntitiesActions
]

type LocalStorageSeelectEntitiesHookValue = [
  Partial<ISelectEntitiesState>,
  (nextState: Partial<ISelectEntitiesState>) => void
]

/**
 * Used internally by `SelectEntities`.
 * @param selectEntitiesState - Externally-controlled state (optional)
 *
 * If `selectEntitiesState` is passed, this hook will return the select entities state
 * and no actions.
 *
 * If `selectEntitiesState` is not passed, this hook will return query-managed state
 * and actions.
 */
export function useSelectEntitiesStateFromProps(
  selectEntitiesState: Partial<ISelectEntitiesState>,
  selectEntitiesActions: ISelectEntitiesActions
): SelectEntitiesHookValue {
  const controlled = useIsControlled(
    typeof selectEntitiesState === 'object' &&
      typeof selectEntitiesActions === 'object'
  )

  if (controlled) {
    return [selectEntitiesState, selectEntitiesActions]
  }

  return useSelectEntitiesQueryParams()
}

/**
 * This can be used to externally control `<SelectEntities />` state.
 * The returned tuple should be passed directly as `props.selectEntitiesState`
 * and `selectEntitiesActions`.
 */
export function useSelectEntitiesStateControl(
  initialState: Partial<ISelectEntitiesState> = {}
): SelectEntitiesHookValue {
  const [selectEntitiesState, selectEntitiesActions] =
    useState<Partial<ISelectEntitiesState>>(initialState)
  return useManagedSelectEntitiesState(
    selectEntitiesState,
    selectEntitiesActions
  )
}

/**
 * Manages `SelectEntities` state via query params.
 */
const useSelectEntitiesQueryParams = (): SelectEntitiesHookValue => {
  const [selectEntitiesQueryParams, {pushQueryParams, replaceQueryParams}] =
    useUrlQueryParams({
      schema: SELECT_ENTITIES_QUERY_PARAMS_VALIDATION_SCHEMA
    })

  const [, handleSetLocalStorageSelectedEntitiesState] =
    useLocalStorageSelectEntities()

  const handleSetSelectedEntitiesState = (
    nextState: Partial<ISelectEntitiesState>
  ) => {
    pushQueryParams(nextState)
    handleSetLocalStorageSelectedEntitiesState(nextState)
  }

  const handleReplaceSelectedEntitiesState = (
    nextState: Partial<ISelectEntitiesState>
  ) => {
    replaceQueryParams(nextState)
    handleSetLocalStorageSelectedEntitiesState(nextState)
  }

  return useManagedSelectEntitiesState(
    selectEntitiesQueryParams,
    handleSetSelectedEntitiesState,
    handleReplaceSelectedEntitiesState
  )
}

/**
 * Hook to read and write select entities state into local storage
 */
function useLocalStorageSelectEntities(): LocalStorageSeelectEntitiesHookValue {
  const selectEntitiesState: Partial<ISelectEntitiesState> = JSON.parse(
    localStorage.getItem(SELECT_ENTITIES_KEY)
  )

  const handleSetSelectedEntitiesState = (
    nextState: Partial<ISelectEntitiesState>
  ) => {
    localStorage.setItem(SELECT_ENTITIES_KEY, JSON.stringify(nextState))
  }

  return [selectEntitiesState, handleSetSelectedEntitiesState]
}

/**
 * Storage-agonistic state manager for select entities state. Accepts
 * the current state and a state setter.
 *
 * TODO: once `useUrlQueryParams` supports a function setter,
 * update `setState` to use a function setter to avoid stale updates.
 */
function useManagedSelectEntitiesState(
  state: Partial<ISelectEntitiesState>,
  setState: (nextState: Partial<ISelectEntitiesState>) => void,
  replaceState?: (nextState: Partial<ISelectEntitiesState>) => void
): SelectEntitiesHookValue {
  const handleEntityFocus = (
    selection: ISelectedEntity,
    options: {replace: boolean} = {replace: false}
  ) => {
    if (options.replace) {
      replaceState(
        produce(state, (draft) => {
          draft.focused = selection
        })
      )

      return
    }
    setState(
      produce(state, (draft) => {
        draft.focused = selection
      })
    )
  }

  const handleAddEntityFromSearchResult = (searchResult: ISearchResult) => {
    setState(
      produce(state, (draft) => {
        if (!draft.selected) {
          draft.selected = []
        }

        if (searchResult.modelName === ALL_MODELS.ACCOUNT) {
          draft.selected.push({
            entityId: searchResult.client,
            accounts: [searchResult.entityId]
          })
        } else {
          draft.selected.push({
            entityId: searchResult.entityId
          })
        }
      })
    )
  }

  const handleAddEntity = (
    newEntityId: string,
    newEntityFilters?: ISelectedEntityFilters
  ) => {
    setState(
      produce(state, (draft) => {
        if (!draft.selected) {
          draft.selected = []
        }

        const newEntity: ISelectedEntity = {
          entityId: newEntityId
        }

        if (newEntityFilters) {
          newEntity.accounts = newEntityFilters.accounts
          newEntity.positions = newEntityFilters.positions
          newEntity.ruleFilters = newEntityFilters.ruleFilters
        }
        draft.selected.push(newEntity)
      })
    )
  }

  const handleRemoveEntity = (entityId: string) => {
    setState(
      produce(state, (draft) => {
        const indexToDelete = state.selected?.findIndex(
          (selection) => selection.entityId === entityId
        )
        if (indexToDelete >= 0) {
          draft.selected.splice(indexToDelete, 1)
        }
      })
    )
  }

  const handleReplaceEntity = (
    oldEntityId: string,
    newEntityId: string,
    newEntityFilters: ISelectedEntityFilters
  ) => {
    setState(
      produce(state, (draft) => {
        const oldEntity = draft.selected?.find(
          (selection) => selection.entityId === oldEntityId
        )
        if (oldEntity) {
          oldEntity.entityId = newEntityId
          if (newEntityFilters) {
            oldEntity.accounts = newEntityFilters.accounts
            oldEntity.positions = newEntityFilters.positions
            oldEntity.ruleFilters = newEntityFilters.ruleFilters
          }
        }
      })
    )
  }

  const handleApplyEntityFilters = (
    entityId: string,
    filters: ISelectedEntityFilters,
    options?: ISelectedEntityApplyEntityFilterOptions
  ) => {
    const newState = produce(state, (draft) => {
      const selectedEntity = draft.selected.find(
        (chipData) => chipData.entityId === entityId
      )

      // Ignore absent values in `filters`
      if (selectedEntity) {
        // `touchedRuleFilters` should always be set to `true` when adding a rule filter,
        // unless we are adding in default rule filters.
        selectedEntity.touchedRuleFilters = !options?.settingDefaultRuleFilters

        if (filters?.accounts) {
          selectedEntity.accounts = filters?.accounts
        }
        if (filters?.positions) {
          selectedEntity.positions = filters?.positions
        }
        if (filters?.ruleFilters) {
          selectedEntity.ruleFilters = filters.ruleFilters.map((ruleFilter) =>
            extractIdFromUrl(ruleFilter)
          )
        }
      }
    })

    /**
     * Will Primarily use 'replaceState' to avoid adding a new entry in the navigation history stack
     * when adding the filters.
     * Will fallback to 'setState' when 'replaceState' is not provided
     */
    if (replaceState) {
      replaceState(newState)
    } else {
      setState(newState)
    }
  }

  return [
    state,
    {
      focusEntity: handleEntityFocus,
      addEntityFromSearchResult: handleAddEntityFromSearchResult,
      addEntity: handleAddEntity,
      removeEntity: handleRemoveEntity,
      replaceEntity: handleReplaceEntity,
      applyEntityFilters: handleApplyEntityFilters
    }
  ]
}

/**
 * Use to replace the current selection while maintaining the current path
 */
export function useGenerateUrlWithCurrentPath() {
  const location = useLocation()

  return (selection: UrlSelection) => {
    const query = serializeSelection(selection)
    return `${location.pathname}?${query}`
  }
}

/**
 * Gets the default reporting currency for the current entity selections from
 * select entities' expanded state and reports errors if the loading fails.
 *
 * @param expandedSelectEntitiesState - the expanded select entities state
 *
 * @returns the default reporting currency
 */
export function useDefaultReportingCurrency(
  expandedSelectEntitiesState: DeepPartial<ISelectEntitiesState>
) {
  const defaultReportingCurrencyRequestBody =
    useMemo((): IDefaultReportingCurrencyRequest => {
      if (
        !expandedSelectEntitiesState.selected ||
        expandedSelectEntitiesState.selected.length === 0
      ) {
        return null
      }

      const focusedEntityId = expandedSelectEntitiesState.focused?.entityId
      const focusedSelection = expandedSelectEntitiesState.selected?.find(
        (selection) => selection.entityId === focusedEntityId
      )

      // Use focused entity first if it exists
      const entityId =
        focusedEntityId || expandedSelectEntitiesState.selected[0].entityId

      const accountsOrPositions = [
        ...((focusedSelection || expandedSelectEntitiesState.selected[0])
          .accounts ?? []),
        ...((focusedSelection || expandedSelectEntitiesState.selected[0])
          .positions ?? [])
      ]

      return {
        entities: [
          {
            entityId,
            accountsOrPositions:
              accountsOrPositions.length > 0 ? accountsOrPositions : undefined
          }
        ]
      }
    }, [expandedSelectEntitiesState])

  const [defaultCurrency] = useApiQuery(
    defaultReportingCurrencyRequestBody &&
      EntityEndpoints.defaultReportingCurrency(
        defaultReportingCurrencyRequestBody
      ),
    {useErrorBoundary: false}
  )

  useEffect(() => {
    if (defaultCurrency.error) {
      reportError(defaultCurrency.error)
    }
  }, [defaultCurrency.error])

  return defaultCurrency
}
