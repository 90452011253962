import {ApiParam} from 'fairlight'

import {IHousehold} from '@d1g1t/api/models'

import {IPaginationOptions} from '../pagination-typings'
import {BaseRestEndpoints} from './base'

interface IHouseholdPayload {
  household?: string
  pagination?: IPaginationOptions
}

export class PeopleHouseholdEndpoints extends BaseRestEndpoints {
  static basePath = '/people/households'

  static create(body: IHousehold) {
    return super._create<IHousehold>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IHousehold>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IHousehold>) {
    return super._partialUpdate<IHousehold>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static chart(body: IHouseholdPayload = {}) {
    return super._chart(body, body.household)
  }

  static getExcel(body: IHouseholdPayload) {
    return super._getExcelChart({...body})
  }
}
