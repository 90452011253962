import {createAction} from 'typesafe-actions'

import {
  ILoginAzureAdRequest,
  ILoginRequest,
  ILoginResponse,
  IMfaDestinationRequestInput,
  IMfaLoginRequestInput
} from '@d1g1t/api/endpoints'
import {
  EXTERNALPROFILE_PREFERRED_LANGUAGE,
  IOTPDeliveryDestination
} from '@d1g1t/api/models'

export const authActions = {
  initialize: createAction('AUTH/INITIALIZE')<{
    token: string | null
    returning?: boolean
  }>(),

  loginRequest: createAction('AUTH/LOGIN_REQUEST')<
    ILoginRequest & {
      redirectTo?: string
      language?: EXTERNALPROFILE_PREFERRED_LANGUAGE
    }
  >(),
  loginAzureAdRequest: createAction('AUTH/LOGIN_AZUREAD_REQUEST')<
    ILoginAzureAdRequest & {redirectTo?: string}
  >(),
  /**
   * Make a new login request with the MFA destination.
   */
  loginMfaDestinationRequest: createAction(
    'AUTH/LOGIN_MFA_DESTINATION_REQUEST'
  )<IMfaDestinationRequestInput & {redirectTo?: string}>(),
  /**
   * Make a new login request with the MFA Token + provided MFA Code.
   */
  loginMfaRequest: createAction('AUTH/LOGIN_MFA_REQUEST')<
    IMfaLoginRequestInput & {
      redirectTo?: string
      language?: EXTERNALPROFILE_PREFERRED_LANGUAGE
    }
  >(),
  loginSamlRequest: createAction('AUTH/LOGIN_SAML_REQUEST')<
    ILoginResponse & {redirectTo?: string}
  >(),
  /**
   * MFA Destinations sent by back-end. Preps UI to wait for MFA destination from user.
   */
  loginAwaitingMfaDestination: createAction(
    'AUTH/LOGIN_WAIT_FOR_MFA_DESTINATION'
  )<{
    otpToken: string
    deliveryDestinations: IOTPDeliveryDestination[]
    invalidCode: boolean
    invalidCodeErrorMessage?: string
  }>(),
  /**
   * MFA Code sent by back-end to user. Preps UI to wait for MFA code from user.
   */
  loginAwaitingMfaCode: createAction('AUTH/LOGIN_WAIT_FOR_MFA_CODE')<{
    otpToken: string
    invalidCode: boolean
    invalidCodeErrorMessage?: string
  }>(),
  loginSuccess: createAction('AUTH/LOGIN_SUCCESS')<ILoginResponse>(),
  loginFailed: createAction('AUTH/LOGIN_FAILED')<Error>(),

  logoutRequest: createAction('AUTH/LOGOUT_REQUEST')(),

  refreshSuccess: createAction('AUTH/REFRESH_SUCCESS')<ILoginResponse>()
}
