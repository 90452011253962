import React from 'react'
import {matchPath, useLocation} from 'react-router-dom'

import {Spacer} from '@d1g1t/shared/components/spacer'

import {useInvestorAppMenu} from '../../hook'

/**
 * This component will conditionally render needed spacing to keep the page layout
 * correct when the <SecondaryNavigation /> component renders
 */
export const SecondaryMenuSpacing: React.FC = (props) => {
  const location = useLocation()
  const investorTopMenuOptions = useInvestorAppMenu()

  const renderSpacing = investorTopMenuOptions.some(
    (investorTopMenuOption) =>
      matchPath(
        location.pathname.split('/')[1],
        investorTopMenuOption.location.split('/')[1]
      ) && investorTopMenuOption.secondaryMenuItems?.length > 0
  )

  return renderSpacing ? <Spacer custom={50} /> : null
}
