import React from 'react'
import {useTranslation} from 'react-i18next'

import MuiMenuItem, {MenuItemProps} from '@material-ui/core/MenuItem'
import {makeStyles} from '@material-ui/styles'

import {classNames} from '@d1g1t/lib/class-names'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import {
  ILinkProps,
  RouterWrappedLink
} from '@d1g1t/shared/components/router-link'

export interface IMenuItemProps extends Omit<MenuItemProps, 'classes'> {
  /**
   * Variant which applies to "selected" state of the `MenuItem`.
   *
   * `none` remove background color (for lists with checkboxes)
   */
  selectedVariant?: 'default' | 'none'
  /**
   * Disables the ripple effect on click. Is passed to underlying `Button`.
   */
  disableRipple?: boolean
  /**
   * Optionally adds an icon before the label text
   */
  icon?: React.ReactElement<any>
}
export type IMenuItemLinkProps = IMenuItemProps & ILinkProps

const useStyles = makeStyles({
  selected: {},
  selectedNone: {},
  root: {
    '&$selected': {
      '&$selectedNone': {
        backgroundColor: 'transparent'
      }
    }
  }
})

export const MenuItem: React.FC<IMenuItemProps> = React.forwardRef(
  ({selectedVariant, disableRipple, ...muiProps}, ref) => {
    const classes = useStyles({})
    const {t} = useTranslation()

    return (
      // Ignoring type error in `MuiMenuItem` due to bug in types
      // https://github.com/mui-org/material-ui/issues/16245
      // @ts-ignore
      <MuiMenuItem
        ref={ref}
        classes={{
          root: classNames(classes.root, {
            [classes.selectedNone]: selectedVariant === 'none'
          }),
          selected: classes.selected
        }}
        {...muiProps}
      >
        {onlyTranslateStrings(t, muiProps.children)}
      </MuiMenuItem>
    )
  }
)

export const MenuItemLink: React.FC<IMenuItemLinkProps> = React.forwardRef(
  (props, ref) => (
    // Ignoring type error in `MuiMenuItem` due to bug in types
    // https://github.com/mui-org/material-ui/issues/16245
    // @ts-ignore
    <MenuItem component={RouterWrappedLink as any} ref={ref} {...props} />
  )
)
