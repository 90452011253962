import {ApiParam} from 'fairlight'

import {IFirmSecurityType} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class FirmSecurityTypeEndpoints extends BaseRestEndpoints {
  static basePath = '/firm-security-types'

  static list() {
    return super._list<IApiListResponse<IFirmSecurityType>>()
  }

  static findById(id: ApiParam) {
    return super._findById<IFirmSecurityType>(id)
  }
}
