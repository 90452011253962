import produce from 'immer'
import {ActionType, getType} from 'typesafe-actions'

import {injectAsyncReducer} from '@d1g1t/lib/redux-inject-async'

import {actions} from './actions'
import {PREFIX} from './constants'
import {IDomainSlice, IGlobalSettingsState} from './typings'

export const initialStateSlice: IDomainSlice = {
  loading: false,
  globalSettings: null
}

Object.freeze(initialStateSlice)

const actionTypesSet = new Set(Object.values(actions).map(getType))

const reducer = (
  baseState: IGlobalSettingsState = {},
  action: ActionType<typeof actions>
): IGlobalSettingsState => {
  if (!actionTypesSet.has(action.type)) {
    return baseState
  }

  if (!action.meta) {
    return baseState
  }

  // Seperately create a state with initial state if required to allow us
  // to reference a draft domain object
  const state = baseState[action.meta.id]
    ? baseState
    : produce(baseState, (draft) => {
        draft[action.meta.id] = {...initialStateSlice}
      })

  return produce(state, (draft) => {
    const domain = draft[action.meta.id]

    switch (action.type) {
      case getType(actions.requestGlobalSettings):
        if (domain.loading) {
          return state
        }
        domain.loading = true
        return
      case getType(actions.setGlobalSettings):
        domain.loading = false
        domain.globalSettings = action.payload
        return
      case getType(actions.error):
        domain.loading = false
        return
      default:
        return state
    }
  })
}

injectAsyncReducer(PREFIX, reducer)
