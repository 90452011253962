import React from 'react'

import {DATE_RANGES} from '@d1g1t/api/models'

import {DEFAULT_DATE_OPTIONS, IDateRange} from '@d1g1t/lib/date-range'

import {Flex} from '@d1g1t/shared/components/flex'
import {CircularProgress} from '@d1g1t/shared/components/mui/circular-progress'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {KeyboardDatePicker} from '@d1g1t/shared/components/mui/keyboard-date-picker'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H3, P} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {useDateFormatter} from '@d1g1t/shared/wrappers/formatter'

import {COMPONENT_SPECIFIC_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

import controlBoxStyles from '@d1g1t/shared/components/control-box/styles.scss'

interface ISelectTimePeriodProps {
  /**
   * Will show a spinner while value is true
   */
  loading?: boolean
  /**
   * Currently selected time period
   */
  selected: Nullable<IDateRange>
  /**
   * Called when the a dropdown values is selected or when a custom date is set
   */
  onChange(timePeriod: IDateRange): void
  /**
   * Removes the items in the array from the dropdown list
   */
  exclude?: IDateRange[]
}

/*
 * DateRangeSelector variant
 * The custom range options are displayed bellow the dropdown instead of a modal window
 */
export const SelectTimePeriod: React.FC<ISelectTimePeriodProps> = (props) => {
  const dateFormater = useDateFormatter()

  const filteredOptions = (() => {
    const excludedValues = (props.exclude || []).map((item) => item.value)
    return DEFAULT_DATE_OPTIONS.filter(
      (opt) => !excludedValues.includes(opt.value)
    ).map((option) => ({...option, label: option.label}))
  })()

  const handleDropdownChange = (value: DATE_RANGES) => {
    const option = filteredOptions.find(
      (dateOption) => dateOption.value === value
    )

    if (value === DATE_RANGES.CUSTOM) {
      // Today's date in 'YYYY-MM-DD' format
      const today = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
      const lastYear = today.setFullYear(today.getFullYear() - 1)

      option.startDate = dateFormater.format(lastYear)
      option.endDate = dateFormater.format(today)
    }

    props.onChange(option)
  }

  const handleCustomDateChange = (
    startOrEnd: keyof Pick<IDateRange, 'startDate' | 'endDate'>,
    date: string
  ) => {
    const newSelectedPeriod = {
      ...props.selected,
      [startOrEnd]: date
    }

    props.onChange(newSelectedPeriod)
  }

  const isCustomSelected = props.selected?.value === DATE_RANGES.CUSTOM

  return (
    <div className={controlBoxStyles.controlBox}>
      <H3 semiBold>{COMPONENT_SPECIFIC_TRANSLATION_KEYS.TIME_PERIOD}</H3>
      {props.loading ? (
        <Flex justifyCenter alignCenter fullHeight>
          <CircularProgress />
        </Flex>
      ) : (
        <>
          <Spacer xxs />
          <ValueLabelSelect
            value={props.selected?.value}
            onChange={handleDropdownChange}
            options={filteredOptions}
            whiteBackground
            fullWidth
            size='small'
          />
          {isCustomSelected && (
            <>
              <Spacer xxs />
              <Divider />
              <Spacer xxs />
              <Grid container>
                <Grid item xs={12}>
                  <P>{COMPONENT_SPECIFIC_TRANSLATION_KEYS.FROM}</P>
                  <KeyboardDatePicker
                    margin='dense'
                    value={props.selected?.startDate}
                    maxDate={props.selected?.endDate}
                    onChange={(date) =>
                      handleCustomDateChange('startDate', date)
                    }
                    fullWidth
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 4
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <P>{COMPONENT_SPECIFIC_TRANSLATION_KEYS.TO}</P>
                  <KeyboardDatePicker
                    margin='dense'
                    value={props.selected?.endDate}
                    minDate={props.selected?.startDate}
                    onChange={(date) => handleCustomDateChange('endDate', date)}
                    fullWidth
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 4
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  )
}
