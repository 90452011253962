import {ApiParam} from 'fairlight'

import {IFoundation} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeopleFoundationEndpoints extends BaseRestEndpoints {
  static basePath = '/people/foundations'

  static create(body: IFoundation) {
    return super._create<IFoundation>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IFoundation>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IFoundation>) {
    return super._partialUpdate<IFoundation>(id, body)
  }
}
