import React, {createContext, useContext, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import qs from 'qs'
import * as Yup from 'yup'

export const URL_QUERY_PARAM_ARRAY_LIMIT = 250

interface IPushQueryParamsOptions {
  /**
   * parameter to change the path component with the addition of the search params to the URL
   */
  path?: string
  /**
   * parameter to merge current search params with new one
   */
  merge?: boolean
}

interface IReplaceQueryParamsOptions {
  /**
   * Parameter to change the path component with the edits to the search params of the URL
   */
  path?: string
  /**
   * Parameter to merge current search params with new one
   */
  merge?: boolean
}

type UrlHookValue<T> = [
  /**
   * Returns a typed object representation of `searchParams`.
   */
  T,
  {
    /**
     * Stringifies a typed object to the URL search params. Uses the `.push` method to add to history.
     * @param searchParamsObj - search params object
     * @param options - options to change the `path` component with the addition of the search params to the URL and/or to `merge` current search params with new one
     */
    pushQueryParams(searchParamsObj: T, options?: IPushQueryParamsOptions): void
    /**
     * Stringifies a typed object to the URL search params. Uses the `.replace` method to NOT add to history.
     * @param searchParamsObj  - search params object
     * @param options - options to change the `path` component with the edits to the search params of the URL and/or to `merge` current search params with new one
     */
    replaceQueryParams(
      searchParamsObj: T,
      options?: IReplaceQueryParamsOptions
    ): void
  }
]

const UrlQueryParamsContext = createContext<object>(EMPTY_OBJECT)

/**
 * Provides parsed query parameters to `useUrlQueryParams` hook.
 *
 * Note that this must be rendered under a react router `Router` provider.
 */
export const UrlQueryParamsProvider: React.FC = function UrlQueryParamsProvider(
  props
) {
  const location = useLocation()
  const queryParams: object = useMemo(() => {
    const rawSearchParams = location.search.replace('?', '')

    if (rawSearchParams === '') {
      return EMPTY_OBJECT
    }

    return parseQueryString(rawSearchParams)
  }, [location.search])

  return (
    <UrlQueryParamsContext.Provider value={queryParams}>
      {props.children}
    </UrlQueryParamsContext.Provider>
  )
}

/**
 * Hook to parse, validate, and coerce query parameters
 * according to a given Yup schema.
 *
 * If any query parameters do not validate the schema, the hook
 * will return an empty object.
 */
export function useUrlQueryParams<T extends Dictionary>(params: {
  /**
   * Yup Schema for the selected query parameters:
   * - It will only return values present in the schema (stripping out keys not defined in schema)
   * - You can provide default values in the schema
   * - If any values are invalid, an empty object will be cast using the schema,
   *   using any default values
   */
  schema: Yup.ObjectSchema<T>
}): UrlHookValue<Partial<T>> {
  const rawQueryParams = useContext(UrlQueryParamsContext)
  const location = useLocation()
  const history = useHistory()

  const pushQueryParams = (
    searchParamsObj: T,
    options: IPushQueryParamsOptions = {}
  ) => {
    const {path = location.pathname, merge = false} = options

    const encodedSearchParams = serializeQueryParams(
      merge ? {...rawQueryParams, ...searchParamsObj} : searchParamsObj
    )
    history.push(`${path}?${encodedSearchParams}`)
  }

  const replaceQueryParams = (
    searchParamsObj: T,
    options: IReplaceQueryParamsOptions = {}
  ) => {
    const {path = location.pathname, merge = false} = options

    const encodedSearchParams = serializeQueryParams(
      merge ? {...rawQueryParams, ...searchParamsObj} : searchParamsObj
    )
    history.replace(`${path}?${encodedSearchParams}`)
  }

  const queryParams = useMemo(
    () => castQueryParams(rawQueryParams, params.schema),
    [rawQueryParams, params.schema]
  )

  return [queryParams, {pushQueryParams, replaceQueryParams}]
}

export function serializeQueryParams(
  queryString,
  options?: qs.IStringifyOptions
): string {
  return qs.stringify(queryString, {
    skipNulls: true,
    ...options
  })
}

export function parseQueryString(search: string) {
  return qs.parse(search, {arrayLimit: URL_QUERY_PARAM_ARRAY_LIMIT})
}

export function castQueryParams<T extends object>(
  rawQueryParams: object,
  schema: Yup.ObjectSchema<T>
): T {
  const validParams = schema.isValidSync(rawQueryParams) ? rawQueryParams : {}

  return schema.cast(validParams, {
    stripUnknown: true
  })
}
