import {UseApiQueryData} from 'fairlight'
import {isEmpty, isEqual, sortBy} from 'lodash'

import {
  IApiListResponse,
  ICalculationRequestBody,
  TradingDraftsEndpoints
} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  CALCULATION_CODES,
  CHART_VALUE_TYPE,
  IChartTable,
  IChartTableCategory,
  IChartTableData,
  IChartTableItem,
  IFilterRule,
  IViewMetricItem,
  VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
} from '@d1g1t/api/models'

import {getControlId} from '@d1g1t/lib/control'
import {IDateRange, IDateRangeValueType} from '@d1g1t/lib/date-range'
import {stableFilterId} from '@d1g1t/lib/filters'
import {metricItemDateRangeSuffix} from '@d1g1t/lib/metrics'
import {pickFromObj} from '@d1g1t/lib/pick-from-obj'
import {camelizeChartTable, CATEGORY_IDS} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {IAppliedRuleFilters} from '@d1g1t/shared/containers/rule-filter-modal'
import {ISelectEntitiesContext} from '@d1g1t/shared/containers/select-entities'
import {
  DATE_RANGE_TO_PERIOD_MAP,
  PAGE_PERIOD_METRIC_SUFFIX
} from '@d1g1t/shared/containers/view-options/constants'

import {
  NON_CALC_PAGINATED_ENDPOINTS,
  NON_STANDARD_CODES,
  PAGINATED_CALCULATION_CODES
} from './constants'
import {ICalculationParams, IUseCalculationDataParams} from './typings'

/**
 * Replaces metrics with a metrics based on the picked page-level date-period.
 *
 * @param viewMetrics - metrics with page-period (PP)
 * @param pageDateRange - currently selected page level date range
 * @returns metrics with PP metrics replaced by real, valid metrics based on `pageDateRange`
 */
export const replacePagePeriodMetricsWithPageDateRangeMetric = (
  viewMetrics: IViewMetricItem[],
  pageDateRange: IDateRange
): IViewMetricItem[] => {
  return viewMetrics.map((viewMetric) => {
    if (viewMetric.metric.includes(PAGE_PERIOD_METRIC_SUFFIX)) {
      return {
        ...viewMetric,
        metric: renamePagePeriodMetricToPageDateRangeMetric(
          viewMetric.metric,
          pageDateRange.value
        ),
        dateRange: pickFromObj(pageDateRange, 'startDate', 'endDate')
      } as IViewMetricItem
    }
    return viewMetric
  })
}

/**
 * Renames a metric to it's page-period naming equivalent.
 *
 * @param metric - A metric's name with page-period (PP)
 * @param pageDateRangeValue - The currently selected page level date range's value
 * @returns A renamed metric's name based on pageDateRangeValue
 */
export const renamePagePeriodMetricToPageDateRangeMetric = (
  metric: string,
  pageDateRangeValue: IDateRangeValueType
): string =>
  metric.replace(
    PAGE_PERIOD_METRIC_SUFFIX,
    DATE_RANGE_TO_PERIOD_MAP[pageDateRangeValue]
  )

/**
 * Called at the beginning of `useCalculationData` hook
 * Produces console errors for configuration issues
 */
export function validateHookConfig(config: IUseCalculationDataParams): void {
  if (__DEVELOPMENT__) {
    if (
      config.loadDataIncrementally &&
      !PAGINATED_CALCULATION_CODES.includes(config.calculationCode)
    ) {
      console.error(
        `calculationCode=${config.calculationCode} does not support "loadDataIncrementally"`
      )
    }

    if (config.loadDataIncrementally && config.transform) {
      const unsupportedTransforms: (keyof typeof config.transform)[] = [
        'filterZeroValues',
        'consolidateItems',
        'apply'
      ]

      for (const key of unsupportedTransforms) {
        if (
          !(
            config.transform[key] === undefined ||
            config.transform[key] === null
          )
        ) {
          console.error(
            `transform "${key}" does not support "loadDataIncrementally"`,
            {[key]: config.transform[key]}
          )
        }
      }
    }
  }
}

const getOptionsId = (props: ICalculationParams): string => {
  const optionProps: (keyof ICalculationParams)[] = [
    'singleResult',
    'investmentMandateAccounts',
    'showMultiplePositions',
    'compare',
    'portfolioRebalancing',
    'lookThrough',
    'showBenchmarks'
  ]
  const includedOptions = []
  for (const option of optionProps) {
    if (props[option]) {
      includedOptions.push(option)
    }
  }

  if (props.dateRange) {
    includedOptions.push(JSON.stringify(props.dateRange))
  }

  if (props.options) {
    const keys: (keyof ICalculationParams['options'])[] = [
      'drilldownDimension',
      'timeSeries',
      'treatmentOfFx',
      'returnType',
      'metricType',
      'metric',
      'contributionDimension',
      'contributionDimension2',
      'drilldownId',
      'group',
      'moverEntityType',
      'moverType',
      'viewType',
      'referenceAssetEntityId'
    ]
    for (const key of keys) {
      const optionValue = props.options[key]
      if (Array.isArray(optionValue)) {
        includedOptions.push(optionValue.join('_'))
      } else {
        includedOptions.push(optionValue)
      }
    }

    if (__DEVELOPMENT__) {
      if (
        !Object.keys(props.options).every((key) => keys.includes(key as any))
      ) {
        console.error(
          [
            'Calculation id for "options" received an option not used to compute id',
            `received: ${sortBy(Object.keys(props.options))}`,
            `keys used: ${sortBy(keys)}`
          ].join('\n')
        )
      }
    }
  }

  return includedOptions.join(',')
}

const getGroupsId = (
  groups: ICalculationParams['groups'],
  groupingCriteria: ICalculationParams['groupingCriteria']
): string => {
  if (!groups && !groupingCriteria) {
    return ''
  }

  if (groupingCriteria) {
    return groupingCriteria
      .map((grouping) => `${grouping.nodeType} ${grouping.displayName}`)
      .join(',')
  }

  return groups.join(',')
}

interface IMappedMetricProperties {
  slug: string
  contributionDimension: number
  contributionDimension2: number
  dateRange: string
  entityId: string
  transactionAsOfDate: VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
}

export const getMetricProperties = (
  metrics: ICalculationParams['defaultMetrics'],
  metricViews: ICalculationParams['metricViews'],
  requiredMetrics: ICalculationParams['requiredMetrics']
): IMappedMetricProperties[] => {
  const metricProperties = ((): IMappedMetricProperties[] => {
    if (metricViews) {
      return metricViews.map((view) => ({
        slug: extractIdFromUrl(view.metric),
        contributionDimension: view.contributionDimension,
        contributionDimension2: view.contributionDimension2,
        dateRange: metricItemDateRangeSuffix(view.dateRange),
        entityId: view.entityId,
        transactionAsOfDate: view.transactionAsOfDate
      }))
    }

    if (metrics) {
      return metrics.map((metric) => ({
        slug: metric.slug,
        contributionDimension: metric.contributionDimension,
        contributionDimension2: metric.contributionDimension2,
        dateRange: metricItemDateRangeSuffix(metric.dateRange),
        entityId: metric.entityId,
        transactionAsOfDate: metric.transactionAsOfDate
      }))
    }
    return []
  })()

  if (requiredMetrics) {
    const slugs = metricProperties.map(({slug}) => slug)
    for (const required of requiredMetrics) {
      if (!slugs.includes(required.slug)) {
        metricProperties.push({
          slug: required.slug,
          contributionDimension: required.contributionDimension,
          contributionDimension2: required.contributionDimension2,
          dateRange: metricItemDateRangeSuffix(required.dateRange),
          entityId: required.entityId,
          transactionAsOfDate: required.transactionAsOfDate
        })
      }
    }
  }

  return metricProperties
}

/**
 * Generates the category ID by combining a metric's slug with additional
 * properties which uniquely identify the PS-generated category.
 *
 * This matches the format returned by PS for category IDs with custom date.
 */
export const generateCategoryIdForMetricProperties = ({
  slug,
  entityId,
  dateRange,
  transactionAsOfDate
}: Pick<
  IMappedMetricProperties,
  'slug' | 'entityId' | 'dateRange' | 'transactionAsOfDate'
>): string => {
  const categoryParts = [slug]

  if (entityId) {
    categoryParts.push(entityId)
  }

  if (dateRange) {
    categoryParts.push(dateRange)
  }

  if (transactionAsOfDate) {
    categoryParts.push(transactionAsOfDate)
  }

  return categoryParts.join('|')
}

export const getMetricsId = (
  metrics: ICalculationParams['defaultMetrics'],
  metricViews: ICalculationParams['metricViews'],
  requiredMetrics: ICalculationParams['requiredMetrics']
): string => {
  if (!metrics && !metricViews && !requiredMetrics) {
    return ''
  }

  const metricProperties = getMetricProperties(
    metrics,
    metricViews,
    requiredMetrics
  )

  return JSON.stringify(
    metricProperties.map(
      ({
        slug,
        contributionDimension,
        contributionDimension2,
        dateRange,
        entityId,
        transactionAsOfDate
      }) => [
        slug,
        contributionDimension,
        contributionDimension2,
        dateRange,
        entityId,
        transactionAsOfDate
      ]
    )
  )
}

export const parseMetricsId = (
  metricsId: string
): IMappedMetricProperties[] => {
  if (!metricsId) {
    return []
  }

  return JSON.parse(metricsId).map(
    ([slug, contributionDimension, contributionDimension2, dateRange]) => ({
      slug,
      contributionDimension,
      contributionDimension2,
      dateRange
    })
  )
}

export const metricPropertiesIsASubset = (
  potentialSubset: IMappedMetricProperties[],
  potentialSuperset: IMappedMetricProperties[]
): boolean => {
  return potentialSubset.every((metricProperty) => {
    for (const supersetMetricProperty of potentialSuperset) {
      if (isEqual(metricProperty, supersetMetricProperty)) {
        return true
      }
    }

    return false
  })
}

export const getSettingsId = (
  settings: ICalculationParams['calculationSettings']['data']
): string => {
  if (!settings) {
    return ''
  }

  const includedSettings = []
  includedSettings.push(settings.currency)
  if (settings.date) {
    includedSettings.push(settings.date.date)
    includedSettings.push(settings.date.value)
  }

  return includedSettings.join(',')
}

interface ICalculationIdProperties {
  calculationCode: CALCULATION_CODES
  metricsId: string
  optionsId: string
  nonCalcPaginatedEndpointOverride: NON_CALC_PAGINATED_ENDPOINTS
}

export const getCalculationId = (props: ICalculationParams): string => {
  const idMap: Map<string, string> = new Map()

  idMap.set('options', getOptionsId(props) || '')
  idMap.set('control', getControlId(props.control))
  idMap.set('filter', stableFilterId(props.filter))
  if (props.filterSets) {
    idMap.set(
      'filterSets',
      props.filterSets.map(stableFilterId).sort().join('')
    )
  }
  idMap.set('groups', getGroupsId(props.groups, props.groupingCriteria))
  idMap.set('settings', getSettingsId(props.calculationSettings.data))

  const idString = Array.from(idMap)
    .map(([key, value]) => `${key}=${value}`)
    .join(':')

  const idProperties: ICalculationIdProperties = {
    calculationCode: props.calculationCode,
    metricsId: getMetricsId(
      props.defaultMetrics,
      props.metricViews,
      props.requiredMetrics
    ),
    optionsId: idString,
    nonCalcPaginatedEndpointOverride: props.nonCalcPaginatedEndpointOverride
  }

  return JSON.stringify(idProperties)
}

export const parseCalculationId = (
  calculationId: string
): ICalculationIdProperties => {
  return JSON.parse(calculationId)
}

export const parseCalculationResponse = (
  code: CALCULATION_CODES,
  response: IChartTable,
  requestBody: ICalculationRequestBody
): unknown => {
  switch (code) {
    case CALCULATION_CODES.PERF_AND_RISK:
      return parseBenchmarking(response)
    case CALCULATION_CODES.CONTRIBUTION:
      return parseContribution(response)
    case CALCULATION_CODES.CLIENT_POSITION_LIST:
      return parseClientPositionList(response, requestBody.options.singleResult)
    default:
      if (NON_STANDARD_CODES.includes(code)) {
        return response
      }

      if (response) {
        return camelizeChartTable(response)
      }
  }

  return null
}

export const isChartTableData = (
  code: CALCULATION_CODES,
  data: unknown
): data is IChartTable => {
  return !NON_STANDARD_CODES.includes(code)
}

const parseBenchmarking = (unformattedData: any): IChartTable => {
  if (!unformattedData || isEmpty(unformattedData.summary)) {
    return null
  }

  const categories: IChartTableCategory[] = [
    {
      id: CATEGORY_IDS.NAME,
      valueType: CHART_VALUE_TYPE.STRING,
      name: 'Metric'
    }
  ]

  for (let i = 0; i < unformattedData.summary.header.data.length; i++) {
    const name = unformattedData.summary.header.data[i]

    categories.push({
      name,
      id: `data-${i}`,
      valueType: CHART_VALUE_TYPE.PERCENTAGE
    })
  }

  const items: IChartTableItem[] = unformattedData.summary.rows.map((row) => {
    const data: IChartTableData[] = [
      {
        categoryId: CATEGORY_IDS.NAME,
        value: row.name
      }
    ]

    for (let i = 0; i < unformattedData.summary.header.data.length; i++) {
      const dataItem = row.data[i] || {}

      data.push({
        categoryId: `data-${i}`,
        value: dataItem.value,
        valueType: dataItem.type,
        options: {
          delta: !!dataItem.color
        }
      })
    }

    return {
      data,
      id: row.name
    }
  })

  if (!isEmpty(unformattedData.performance)) {
    for (const row of unformattedData.performance.rows) {
      const data: IChartTableData[] = [
        {
          categoryId: CATEGORY_IDS.NAME,
          value: row.name
        },
        {
          categoryId: 'data-0',
          value: null
        }
      ]

      for (let i = 0; i < row.data.length; i++) {
        const dataItem = row.data[i]

        data.push({
          categoryId: `data-${i + 1}`,
          value: dataItem.value,
          valueType: dataItem.type,
          options: {
            delta: !!dataItem.color
          }
        })
      }

      items.push({
        data,
        id: row.name
      })
    }
  }

  return {
    categories,
    items
  }
}

const parseContribution = (data: any): IChartTable => {
  if (!data || isEmpty(data.table) || isEmpty(data.table.columns)) {
    return null
  }

  const categories: IChartTableCategory[] = data.table.columns.map((column) => {
    return {
      id: column.id,
      name: column.name,
      valueType: column.type,
      options: {
        delta: column.color
      }
    }
  })

  const items: IChartTableItem[] = data.table.rows.map((row) => {
    const data: IChartTableData[] = []

    for (const category of categories) {
      data.push({
        categoryId: category.id,
        value: row[category.id],
        sortValue: category.id === 'name' ? row.sort_value : null
      })
    }

    return {
      data,
      id: row.id
    }
  })

  return {
    items,
    categories
  }
}

const parseClientPositionList = (
  data: any,
  singleResult: boolean
): IChartTable => {
  if (!data || isEmpty(data.table) || isEmpty(data.table.columns)) {
    return null
  }

  const categories: IChartTableCategory[] = [
    {
      id: 'name',
      valueType: CHART_VALUE_TYPE.STRING,
      name: data.table.columns[0].name
    },
    {
      id: 'value',
      valueType: CHART_VALUE_TYPE.BASIS_POINTS,
      name: data.table.columns[1].name
    }
  ]

  if (!singleResult) {
    categories.push({
      id: 'model_name',
      valueType: CHART_VALUE_TYPE.STRING,
      options: {
        hidden: true
      },
      name: 'Model Name'
    })
    categories.push({
      id: 'model_id',
      valueType: CHART_VALUE_TYPE.STRING,
      options: {
        hidden: true
      },
      name: 'Model ID'
    })
  }

  const nameCategoryId =
    data.table.columns[0].id || data.table.columns[0]["id'"]

  const valueCategoryId = data.table.columns[1].id

  const items: IChartTableItem[] = data.table.rows.map((row) => {
    const dataItems: IChartTableData[] = [
      {
        categoryId: 'name',
        value: row[nameCategoryId]
      },
      {
        categoryId: 'value',
        value: row[valueCategoryId]
      }
    ]

    if (!singleResult) {
      dataItems.push({
        categoryId: 'model_id',
        value: row.id
      })
      dataItems.push({
        categoryId: 'model_name',
        value: ALL_MODELS.PERSON
      })
    }

    return {
      data: dataItems,
      id: row.id
    }
  })

  return {
    items,
    categories
  }
}

/**
 * Used by the `useCalculationData` hook to extract the applied filters
 * for the current entity selections
 */
export function getUrlAppliedRuleFilters(
  selectedEntities: Pick<ISelectEntitiesContext, 'selection'>,
  allRuleFilters: UseApiQueryData<IApiListResponse<IFilterRule>>
): IAppliedRuleFilters[] {
  return selectedEntities?.selection.selected
    ?.map((selected) => {
      if (!selected.ruleFilters) {
        return
      }

      const entityId = selected.entityId
      const entityFilters = allRuleFilters.data.results.filter((rule) =>
        selected.ruleFilters.includes(rule.url)
      )
      return {
        entityId,
        appliedFilters: entityFilters
      }
    })
    .filter((ruleFilter) => ruleFilter)
}

/**
 * Returns request for non-calculation paginated e
 * (Contains switch to quickly add more cases if needed later on)
 */
export function getOverridePaginatedEndpointRequest(
  nonCalcEndpointPaginatedOverride: NON_CALC_PAGINATED_ENDPOINTS,
  code: CALCULATION_CODES,
  {nonCalcPagination, ...requestBody}: ICalculationRequestBody
): ReturnType<typeof TradingDraftsEndpoints.chartAll> {
  switch (nonCalcEndpointPaginatedOverride) {
    case NON_CALC_PAGINATED_ENDPOINTS.TRADING_DRAFTS_CHART_ALL:
      return TradingDraftsEndpoints.chartAll({
        calcCode: code,
        calcParams: requestBody,
        asOfDate: requestBody.settings.date.date,
        pagination: nonCalcPagination
      })
  }
}
