import {Ref, useEffect, useRef, useState} from 'react'

import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField
} from 'formik'

import {useIsControlled} from '@d1g1t/lib/hooks/use-controlled-change-warning'

import {IFormFieldProps} from './typings'

/**
 * Helper for determining MUI `InputLabel` width and
 * exposing the value so it can be applied to an input
 * component
 *
 * Adapted from MUI `TextField` source:
 * https://github.com/mui-org/material-ui/blob/v4.3.3/packages/material-ui/src/TextField/TextField.js#L94
 */
export function useMuiLabelWidth(): [
  /**
   * Label width, to be passed to input `labelWidth` prop
   */
  number,
  /**
   * Label ref, to be passed to `InputLabel`
   */
  Ref<HTMLLabelElement>
] {
  const [labelWidth, setLabelWidth] = useState(0)
  const labelRef = useRef<HTMLLabelElement>(null)
  useEffect(() => {
    const {current: labelEl} = labelRef
    setLabelWidth(labelEl != null ? labelEl.offsetWidth : 0)
  }, [])
  return [labelWidth, labelRef]
}

const controlledNoOp = () => {
  if (__DEVELOPMENT__) {
    console.warn(
      'Calling field helper props from a controlled field is a no-op'
    )
  }
}

/**
 * If the form field is controlled, is controlled from props.
 * If the form field is not controlled, is controlled from `formik#useField`.
 */
export function useFormFieldControl(
  props: IFormFieldProps
): [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>] {
  const controlled = useIsControlled(
    typeof props.onChange === 'function' &&
      Object.prototype.hasOwnProperty.call(props, 'value')
  )

  if (controlled) {
    return [
      {
        name: props.name,
        value: props.value,
        onChange: props.onChange,
        onBlur: props.onBlur
      },
      {
        value: props.value,
        error: props.error,
        touched: props.touched,
        initialTouched: false
      },
      {
        setValue: controlledNoOp,
        setTouched: controlledNoOp,
        setError: controlledNoOp
      }
    ]
  }

  return useField(props.name)
}

/**
 * Provides a hook version of `<FieldArray>`
 */
export function useFieldArray<T>({name}: {name: string}) {
  const [field, , {setValue}] = useField<T[]>(name)

  const append = (value: T) => {
    setValue([...field.value, value])
  }

  const remove = (index: number) => {
    const nextState = [...field.value]
    nextState.splice(index, 1)

    return setValue(nextState)
  }

  return {fields: field.value, append, remove, setValue}
}
