import {ApiParam} from 'fairlight'

import {
  IChartTable,
  IHierarchyLevelInput,
  IInvestmentProgram,
  IInvestmentProgramInput
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

interface IChartRequestBody {
  isGlobal: boolean
}

interface ISingleChartRequestBody
  extends Omit<IInvestmentProgramInput, 'levelWeights'> {
  levelWeights: Array<Omit<IHierarchyLevelInput, 'path'>>
}

export class InvestmentProgramEndpoints extends BaseRestEndpoints {
  static basePath = '/strategies/investment-programs'

  static chart(body: IChartRequestBody) {
    return super._chart(body, JSON.stringify(body))
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static findById(id: ApiParam) {
    return super._findById<IInvestmentProgram>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IInvestmentProgram>) {
    return super._partialUpdate<IInvestmentProgram>(id, body)
  }

  static singleChart(id: ApiParam, body: ISingleChartRequestBody) {
    return super._post<IChartTable>(`${super._singlePath(id)}/chart`, {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static copy(id: ApiParam) {
    return super._post<IInvestmentProgram>(`${super._singlePath(id)}/copy/`, {
      body: {}
    })
  }

  static create(body: IInvestmentProgram) {
    body.firmProvidedKey = body.firmProvidedKey
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, '-')
    return super._create<IInvestmentProgram>(body)
  }
}
