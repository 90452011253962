import React from 'react'

import {IFirmConfiguration} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {Text} from '@d1g1t/shared/components/typography'

import * as css from '../../style.scss'

export const Logo: React.FC<
  Pick<IFirmConfiguration, 'bannerMessage' | 'logo'>
> = ({logo, bannerMessage}) => {
  if (!logo) {
    return null
  }

  return (
    <Flex column className={css.footerLogo}>
      <img src={logo} />
      <Text>{bannerMessage}</Text>
    </Flex>
  )
}
