export enum FILTER_CRITERIA_SLUG {
  CLIENT_PROPERTY_NAME_IS_NOT_EMPTY = 'client-property-name-is-not-empty',
  CLIENT_PROPERTY_NAME_IS = 'client-property-name-is',
  CLIENT_PROPERTY_NAME_IS_EMPTY = 'client-property-name-is-empty',
  CLIENT_PROPERTY_NAME_ONE_OF = 'client-property-name-one-of',
  CLIENT_PROPERTY_NAME_CONTAINS = 'client-property-name-contains',
  CLIENT_PROPERTY_NAME_IS_NOT = 'client-property-name-is-not',
  CLIENT_PROPERTY_ENTITY_ID_UUIDIN = 'client-property-entity-id-uuidin',
  CLIENT_PROPERTY_ENTITY_ID_UUIDEQUALS = 'client-property-entity-id-uuidequals',
  CLIENT_METRIC_ASSETS_UNDER_MANAGEMENT_BETWEEN = 'client-metric-assets-under-management-between',
  CLIENT_METRIC_ASSETS_UNDER_MANAGEMENT_GTE = 'client-metric-assets-under-management-gte',
  CLIENT_METRIC_ASSETS_UNDER_MANAGEMENT_NOTEQUALS = 'client-metric-assets-under-management-notequals',
  CLIENT_METRIC_ASSETS_UNDER_MANAGEMENT_LTE = 'client-metric-assets-under-management-lte',
  CLIENT_METRIC_ASSETS_UNDER_MANAGEMENT_EQUALS = 'client-metric-assets-under-management-equals',
  CLIENT_METRIC_ASSETS_UNDER_MANAGEMENT_IN = 'client-metric-assets-under-management-in',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_MONTH_BETWEEN = 'client-metric-gainloss-realized-last-month-between',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_MONTH_GTE = 'client-metric-gainloss-realized-last-month-gte',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_MONTH_NOTEQUALS = 'client-metric-gainloss-realized-last-month-notequals',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_MONTH_LTE = 'client-metric-gainloss-realized-last-month-lte',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_MONTH_EQUALS = 'client-metric-gainloss-realized-last-month-equals',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_MONTH_IN = 'client-metric-gainloss-realized-last-month-in',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_BETWEEN = 'client-metric-gainloss-realized-last-quarter-between',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_GTE = 'client-metric-gainloss-realized-last-quarter-gte',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_NOTEQUALS = 'client-metric-gainloss-realized-last-quarter-notequals',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_LTE = 'client-metric-gainloss-realized-last-quarter-lte',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_EQUALS = 'client-metric-gainloss-realized-last-quarter-equals',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_IN = 'client-metric-gainloss-realized-last-quarter-in',
  CLIENT_METRIC_GAINLOSS_REALIZED_YTD_BETWEEN = 'client-metric-gainloss-realized-ytd-between',
  CLIENT_METRIC_GAINLOSS_REALIZED_YTD_GTE = 'client-metric-gainloss-realized-ytd-gte',
  CLIENT_METRIC_GAINLOSS_REALIZED_YTD_NOTEQUALS = 'client-metric-gainloss-realized-ytd-notequals',
  CLIENT_METRIC_GAINLOSS_REALIZED_YTD_LTE = 'client-metric-gainloss-realized-ytd-lte',
  CLIENT_METRIC_GAINLOSS_REALIZED_YTD_EQUALS = 'client-metric-gainloss-realized-ytd-equals',
  CLIENT_METRIC_GAINLOSS_REALIZED_YTD_IN = 'client-metric-gainloss-realized-ytd-in',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_YEAR_BETWEEN = 'client-metric-gainloss-realized-last-year-between',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_YEAR_GTE = 'client-metric-gainloss-realized-last-year-gte',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_YEAR_NOTEQUALS = 'client-metric-gainloss-realized-last-year-notequals',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_YEAR_LTE = 'client-metric-gainloss-realized-last-year-lte',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_YEAR_EQUALS = 'client-metric-gainloss-realized-last-year-equals',
  CLIENT_METRIC_GAINLOSS_REALIZED_LAST_YEAR_IN = 'client-metric-gainloss-realized-last-year-in',
  CLIENT_METRIC_GAINLOSS_REALIZED_3_YEARS_BETWEEN = 'client-metric-gainloss-realized-3-years-between',
  CLIENT_METRIC_GAINLOSS_REALIZED_3_YEARS_GTE = 'client-metric-gainloss-realized-3-years-gte',
  CLIENT_METRIC_GAINLOSS_REALIZED_3_YEARS_NOTEQUALS = 'client-metric-gainloss-realized-3-years-notequals',
  CLIENT_METRIC_GAINLOSS_REALIZED_3_YEARS_LTE = 'client-metric-gainloss-realized-3-years-lte',
  CLIENT_METRIC_GAINLOSS_REALIZED_3_YEARS_EQUALS = 'client-metric-gainloss-realized-3-years-equals',
  CLIENT_METRIC_GAINLOSS_REALIZED_3_YEARS_IN = 'client-metric-gainloss-realized-3-years-in',
  CLIENT_METRIC_RETURN_NET_LAST_MONTH_BETWEEN = 'client-metric-return-net-last-month-between',
  CLIENT_METRIC_RETURN_NET_LAST_MONTH_GTE = 'client-metric-return-net-last-month-gte',
  CLIENT_METRIC_RETURN_NET_LAST_MONTH_NOTEQUALS = 'client-metric-return-net-last-month-notequals',
  CLIENT_METRIC_RETURN_NET_LAST_MONTH_LTE = 'client-metric-return-net-last-month-lte',
  CLIENT_METRIC_RETURN_NET_LAST_MONTH_EQUALS = 'client-metric-return-net-last-month-equals',
  CLIENT_METRIC_RETURN_NET_LAST_MONTH_IN = 'client-metric-return-net-last-month-in',
  CLIENT_METRIC_RETURN_NET_LAST_QUARTER_BETWEEN = 'client-metric-return-net-last-quarter-between',
  CLIENT_METRIC_RETURN_NET_LAST_QUARTER_GTE = 'client-metric-return-net-last-quarter-gte',
  CLIENT_METRIC_RETURN_NET_LAST_QUARTER_NOTEQUALS = 'client-metric-return-net-last-quarter-notequals',
  CLIENT_METRIC_RETURN_NET_LAST_QUARTER_LTE = 'client-metric-return-net-last-quarter-lte',
  CLIENT_METRIC_RETURN_NET_LAST_QUARTER_EQUALS = 'client-metric-return-net-last-quarter-equals',
  CLIENT_METRIC_RETURN_NET_LAST_QUARTER_IN = 'client-metric-return-net-last-quarter-in',
  CLIENT_METRIC_RETURN_NET_YTD_BETWEEN = 'client-metric-return-net-ytd-between',
  CLIENT_METRIC_RETURN_NET_YTD_GTE = 'client-metric-return-net-ytd-gte',
  CLIENT_METRIC_RETURN_NET_YTD_NOTEQUALS = 'client-metric-return-net-ytd-notequals',
  CLIENT_METRIC_RETURN_NET_YTD_LTE = 'client-metric-return-net-ytd-lte',
  CLIENT_METRIC_RETURN_NET_YTD_EQUALS = 'client-metric-return-net-ytd-equals',
  CLIENT_METRIC_RETURN_NET_YTD_IN = 'client-metric-return-net-ytd-in',
  CLIENT_METRIC_RETURN_NET_LAST_YEAR_BETWEEN = 'client-metric-return-net-last-year-between',
  CLIENT_METRIC_RETURN_NET_LAST_YEAR_GTE = 'client-metric-return-net-last-year-gte',
  CLIENT_METRIC_RETURN_NET_LAST_YEAR_NOTEQUALS = 'client-metric-return-net-last-year-notequals',
  CLIENT_METRIC_RETURN_NET_LAST_YEAR_LTE = 'client-metric-return-net-last-year-lte',
  CLIENT_METRIC_RETURN_NET_LAST_YEAR_EQUALS = 'client-metric-return-net-last-year-equals',
  CLIENT_METRIC_RETURN_NET_LAST_YEAR_IN = 'client-metric-return-net-last-year-in',
  CLIENT_METRIC_RETURN_NET_3_YEARS_BETWEEN = 'client-metric-return-net-3-years-between',
  CLIENT_METRIC_RETURN_NET_3_YEARS_GTE = 'client-metric-return-net-3-years-gte',
  CLIENT_METRIC_RETURN_NET_3_YEARS_NOTEQUALS = 'client-metric-return-net-3-years-notequals',
  CLIENT_METRIC_RETURN_NET_3_YEARS_LTE = 'client-metric-return-net-3-years-lte',
  CLIENT_METRIC_RETURN_NET_3_YEARS_EQUALS = 'client-metric-return-net-3-years-equals',
  CLIENT_METRIC_RETURN_NET_3_YEARS_IN = 'client-metric-return-net-3-years-in',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_BETWEEN = 'client-metric-net-depositwithdrawal-last-month-between',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_GTE = 'client-metric-net-depositwithdrawal-last-month-gte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_NOTEQUALS = 'client-metric-net-depositwithdrawal-last-month-notequals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_LTE = 'client-metric-net-depositwithdrawal-last-month-lte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_EQUALS = 'client-metric-net-depositwithdrawal-last-month-equals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_IN = 'client-metric-net-depositwithdrawal-last-month-in',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_BETWEEN = 'client-metric-net-depositwithdrawal-last-quarter-between',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_GTE = 'client-metric-net-depositwithdrawal-last-quarter-gte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_NOTEQUALS = 'client-metric-net-depositwithdrawal-last-quarter-notequals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_LTE = 'client-metric-net-depositwithdrawal-last-quarter-lte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_EQUALS = 'client-metric-net-depositwithdrawal-last-quarter-equals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_IN = 'client-metric-net-depositwithdrawal-last-quarter-in',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_BETWEEN = 'client-metric-net-depositwithdrawal-ytd-between',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_GTE = 'client-metric-net-depositwithdrawal-ytd-gte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_NOTEQUALS = 'client-metric-net-depositwithdrawal-ytd-notequals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_LTE = 'client-metric-net-depositwithdrawal-ytd-lte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_EQUALS = 'client-metric-net-depositwithdrawal-ytd-equals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_IN = 'client-metric-net-depositwithdrawal-ytd-in',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_BETWEEN = 'client-metric-net-depositwithdrawal-last-year-between',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_GTE = 'client-metric-net-depositwithdrawal-last-year-gte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_NOTEQUALS = 'client-metric-net-depositwithdrawal-last-year-notequals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_LTE = 'client-metric-net-depositwithdrawal-last-year-lte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_EQUALS = 'client-metric-net-depositwithdrawal-last-year-equals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_IN = 'client-metric-net-depositwithdrawal-last-year-in',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_BETWEEN = 'client-metric-net-depositwithdrawal-3-years-between',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_GTE = 'client-metric-net-depositwithdrawal-3-years-gte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_NOTEQUALS = 'client-metric-net-depositwithdrawal-3-years-notequals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_LTE = 'client-metric-net-depositwithdrawal-3-years-lte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_EQUALS = 'client-metric-net-depositwithdrawal-3-years-equals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_IN = 'client-metric-net-depositwithdrawal-3-years-in',
  CLIENT_PROPERTY_STATUS_IS = 'client-property-status-is',
  CLIENT_PROPERTY_STATUS_IS_EMPTY = 'client-property-status-is-empty',
  CLIENT_PROPERTY_STATUS_IS_NOT = 'client-property-status-is-not',
  CLIENT_PROPERTY_STATUS_IS_NOT_EMPTY = 'client-property-status-is-not-empty',
  CLIENT_PROPERTY_STATUS_ONE_OF = 'client-property-status-one-of',
  CLIENT_PROPERTY_TEAM_CONTAINS = 'client-property-team-contains',
  CLIENT_PROPERTY_TEAM_IS = 'client-property-team-is',
  CLIENT_PROPERTY_TEAM_IS_EMPTY = 'client-property-team-is-empty',
  CLIENT_PROPERTY_TEAM_IS_NOT = 'client-property-team-is-not',
  CLIENT_PROPERTY_TEAM_IS_NOT_EMPTY = 'client-property-team-is-not-empty',
  CLIENT_PROPERTY_TEAM_ONE_OF = 'client-property-team-one-of',
  CLIENT_PROPERTY_ROLENAME_IS = 'client-property-rolename-is',
  CLIENT_PROPERTY_ROLENAME_IS_EMPTY = 'client-property-rolename-is-empty',
  CLIENT_PROPERTY_ROLENAME_IS_NOT = 'client-property-rolename-is-not',
  CLIENT_PROPERTY_ROLENAME_IS_NOT_EMPTY = 'client-property-rolename-is-not-empty',
  CLIENT_PROPERTY_ROLENAME_ONE_OF = 'client-property-rolename-one-of',
  CLIENT_PROPERTY_REFERRER_CONTAINS = 'client-property-referrer-contains',
  CLIENT_PROPERTY_REFERRER_IS = 'client-property-referrer-is',
  CLIENT_PROPERTY_REFERRAL_TYPE_IS = 'client-property-referral-type-is',
  CLIENT_PROPERTY_REFERRER_IS_EMPTY = 'client-property-referrer-is-empty',
  CLIENT_PROPERTY_REFERRAL_TYPE_IS_EMPTY = 'client-property-referral-type-is-empty',
  CLIENT_PROPERTY_REFERRER_IS_NOT = 'client-property-referrer-is-not',
  CLIENT_PROPERTY_REFERRAL_TYPE_IS_NOT = 'client-property-referral-type-is-not',
  CLIENT_PROPERTY_REFERRER_IS_NOT_EMPTY = 'client-property-referrer-is-not-empty',
  CLIENT_PROPERTY_REFERRAL_TYPE_IS_NOT_EMPTY = 'client-property-referral-type-is-not-empty',
  CLIENT_PROPERTY_REFERRER_ONE_OF = 'client-property-referrer-one-of',
  CLIENT_PROPERTY_REFERRAL_TYPE_ONE_OF = 'client-property-referral-type-one-of',
  CLIENT_METRIC_CASH_BALANCE_LTE = 'client-metric-cash-balance-lte',
  CLIENT_METRIC_CASH_BALANCE_EQUALS = 'client-metric-cash-balance-equals',
  CLIENT_METRIC_CASH_BALANCE_NOTEQUALS = 'client-metric-cash-balance-notequals',
  CLIENT_METRIC_CASH_BALANCE_GTE = 'client-metric-cash-balance-gte',
  CLIENT_METRIC_CASH_BALANCE_IN = 'client-metric-cash-balance-in',
  CLIENT_METRIC_CASH_BALANCE_BETWEEN = 'client-metric-cash-balance-between',
  CLIENT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_LTE = 'client-metric-gainloss-unrealized-since-inception-lte',
  CLIENT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_EQUALS = 'client-metric-gainloss-unrealized-since-inception-equals',
  CLIENT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_NOTEQUALS = 'client-metric-gainloss-unrealized-since-inception-notequals',
  CLIENT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_GTE = 'client-metric-gainloss-unrealized-since-inception-gte',
  CLIENT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_IN = 'client-metric-gainloss-unrealized-since-inception-in',
  CLIENT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_BETWEEN = 'client-metric-gainloss-unrealized-since-inception-between',
  CLIENT_METRIC_RETURN_NET_SINCE_INCEPTION_LTE = 'client-metric-return-net-since-inception-lte',
  CLIENT_METRIC_RETURN_NET_SINCE_INCEPTION_EQUALS = 'client-metric-return-net-since-inception-equals',
  CLIENT_METRIC_RETURN_NET_SINCE_INCEPTION_NOTEQUALS = 'client-metric-return-net-since-inception-notequals',
  CLIENT_METRIC_RETURN_NET_SINCE_INCEPTION_GTE = 'client-metric-return-net-since-inception-gte',
  CLIENT_METRIC_RETURN_NET_SINCE_INCEPTION_IN = 'client-metric-return-net-since-inception-in',
  CLIENT_METRIC_RETURN_NET_SINCE_INCEPTION_BETWEEN = 'client-metric-return-net-since-inception-between',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_LTE = 'client-metric-net-depositwithdrawal-since-inception-lte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_EQUALS = 'client-metric-net-depositwithdrawal-since-inception-equals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_NOTEQUALS = 'client-metric-net-depositwithdrawal-since-inception-notequals',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_GTE = 'client-metric-net-depositwithdrawal-since-inception-gte',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_IN = 'client-metric-net-depositwithdrawal-since-inception-in',
  CLIENT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_BETWEEN = 'client-metric-net-depositwithdrawal-since-inception-between',
  CLIENT_PROPERTY_TEAM_ROLES_PROFILE_IS = 'client-property-team-roles-profile-is',
  CLIENT_PROPERTY_PRIMARY_MANAGER_IS = 'client-property-primary-manager-is',
  CLIENT_PROPERTY_PRIMARY_MANAGER_IS_EMPTY = 'client-property-primary-manager-is-empty',
  CLIENT_PROPERTY_PRIMARY_MANAGER_IS_NOT = 'client-property-primary-manager-is-not',
  CLIENT_PROPERTY_PRIMARY_MANAGER_IS_NOT_EMPTY = 'client-property-primary-manager-is-not-empty',
  CLIENT_PROPERTY_PRIMARY_MANAGER_ONE_OF = 'client-property-primary-manager-one-of',
  CLIENT_PROPERTY_SECONDARY_MANAGER_IS = 'client-property-secondary-manager-is',
  CLIENT_PROPERTY_SECONDARY_MANAGER_IS_EMPTY = 'client-property-secondary-manager-is-empty',
  CLIENT_PROPERTY_SECONDARY_MANAGER_IS_NOT = 'client-property-secondary-manager-is-not',
  CLIENT_PROPERTY_SECONDARY_MANAGER_IS_NOT_EMPTY = 'client-property-secondary-manager-is-not-empty',
  CLIENT_PROPERTY_SECONDARY_MANAGER_ONE_OF = 'client-property-secondary-manager-one-of',
  CLIENT_PROPERTY_ASSOCIATE_IS = 'client-property-associate-is',
  CLIENT_PROPERTY_ASSOCIATE_IS_EMPTY = 'client-property-associate-is-empty',
  CLIENT_PROPERTY_ASSOCIATE_IS_NOT = 'client-property-associate-is-not',
  CLIENT_PROPERTY_ASSOCIATE_IS_NOT_EMPTY = 'client-property-associate-is-not-empty',
  CLIENT_PROPERTY_ASSOCIATE_ONE_OF = 'client-property-associate-one-of',
  CLIENT_PROPERTY_AGE_GROUP_EQUALS = 'client-property-age-group-equals',
  CLIENT_PROPERTY_AGE_GROUP_IS_EMPTY = 'client-property-age-group-is-empty',
  CLIENT_PROPERTY_AGE_GROUP_NOTEQUALS = 'client-property-age-group-notequals',
  CLIENT_PROPERTY_AGE_GROUP_IS_NOT_EMPTY = 'client-property-age-group-is-not-empty',
  CLIENT_PROPERTY_AGE_GROUP_ONE_OF = 'client-property-age-group-one-of',
  CLIENT_PROPERTY_GENERATION_EQUALS = 'client-property-generation-equals',
  CLIENT_PROPERTY_GENERATION_IS_EMPTY = 'client-property-generation-is-empty',
  CLIENT_PROPERTY_GENERATION_NOTEQUALS = 'client-property-generation-notequals',
  CLIENT_PROPERTY_GENERATION_IS_NOT_EMPTY = 'client-property-generation-is-not-empty',
  CLIENT_PROPERTY_GENERATION_ONE_OF = 'client-property-generation-one-of',
  CLIENT_PROPERTY_CATEGORY_EQUALS = 'client-property-category-equals',
  CLIENT_PROPERTY_CATEGORY_IS_EMPTY = 'client-property-category-is-empty',
  CLIENT_PROPERTY_CATEGORY_NOTEQUALS = 'client-property-category-notequals',
  CLIENT_PROPERTY_CATEGORY_IS_NOT_EMPTY = 'client-property-category-is-not-empty',
  CLIENT_PROPERTY_CATEGORY_ONE_OF = 'client-property-category-one-of',
  CLIENT_PROPERTY_SEGMENT_EQUALS = 'client-property-segment-equals',
  CLIENT_PROPERTY_SEGMENT_IS_EMPTY = 'client-property-segment-is-empty',
  CLIENT_PROPERTY_SEGMENT_NOTEQUALS = 'client-property-segment-notequals',
  CLIENT_PROPERTY_SEGMENT_IS_NOT_EMPTY = 'client-property-segment-is-not-empty',
  CLIENT_PROPERTY_SEGMENT_ONE_OF = 'client-property-segment-one-of',
  CLIENT_PROPERTY_TENURE_EQUALS = 'client-property-tenure-equals',
  CLIENT_PROPERTY_TENURE_IS_EMPTY = 'client-property-tenure-is-empty',
  CLIENT_PROPERTY_TENURE_NOTEQUALS = 'client-property-tenure-notequals',
  CLIENT_PROPERTY_TENURE_IS_NOT_EMPTY = 'client-property-tenure-is-not-empty',
  CLIENT_PROPERTY_TENURE_ONE_OF = 'client-property-tenure-one-of',
  CLIENT_PROPERTY_EMPLOYMENT_OCCUPATION_CONTAINS = 'client-property-employment-occupation-contains',
  CLIENT_PROPERTY_EMPLOYMENT_OCCUPATION_IS = 'client-property-employment-occupation-is',
  CLIENT_PROPERTY_EMPLOYMENT_OCCUPATION_IS_EMPTY = 'client-property-employment-occupation-is-empty',
  CLIENT_PROPERTY_EMPLOYMENT_OCCUPATION_IS_NOT = 'client-property-employment-occupation-is-not',
  CLIENT_PROPERTY_EMPLOYMENT_OCCUPATION_IS_NOT_EMPTY = 'client-property-employment-occupation-is-not-empty',
  CLIENT_PROPERTY_EMPLOYMENT_OCCUPATION_ONE_OF = 'client-property-employment-occupation-one-of',
  CLIENT_PROPERTY_TYPE_CONTAINS = 'client-property-type-contains',
  CLIENT_PROPERTY_TYPE_IS = 'client-property-type-is',
  CLIENT_PROPERTY_TYPE_IS_EMPTY = 'client-property-type-is-empty',
  CLIENT_PROPERTY_TYPE_IS_NOT = 'client-property-type-is-not',
  CLIENT_PROPERTY_TYPE_IS_NOT_EMPTY = 'client-property-type-is-not-empty',
  CLIENT_PROPERTY_TYPE_ONE_OF = 'client-property-type-one-of',
  CLIENT_PROPERTY_MEETING_FREQUENCY_CONTAINS = 'client-property-meeting-frequency-contains',
  CLIENT_PROPERTY_MEETING_FREQUENCY_IS = 'client-property-meeting-frequency-is',
  CLIENT_PROPERTY_MEETING_FREQUENCY_IS_EMPTY = 'client-property-meeting-frequency-is-empty',
  CLIENT_PROPERTY_MEETING_FREQUENCY_IS_NOT = 'client-property-meeting-frequency-is-not',
  CLIENT_PROPERTY_MEETING_FREQUENCY_IS_NOT_EMPTY = 'client-property-meeting-frequency-is-not-empty',
  CLIENT_PROPERTY_MEETING_FREQUENCY_ONE_OF = 'client-property-meeting-frequency-one-of',
  CLIENT_PROPERTY_DESIRED_MEETING_METHOD_CONTAINS = 'client-property-desired-meeting-method-contains',
  CLIENT_PROPERTY_DESIRED_MEETING_METHOD_IS = 'client-property-desired-meeting-method-is',
  CLIENT_PROPERTY_DESIRED_MEETING_METHOD_IS_EMPTY = 'client-property-desired-meeting-method-is-empty',
  CLIENT_PROPERTY_DESIRED_MEETING_METHOD_IS_NOT = 'client-property-desired-meeting-method-is-not',
  CLIENT_PROPERTY_DESIRED_MEETING_METHOD_IS_NOT_EMPTY = 'client-property-desired-meeting-method-is-not-empty',
  CLIENT_PROPERTY_DESIRED_MEETING_METHOD_ONE_OF = 'client-property-desired-meeting-method-one-of',
  CLIENT_PROPERTY_LAST_MEETING_DATE_ON = 'client-property-last-meeting-date-on',
  CLIENT_PROPERTY_LAST_MEETING_DATE_AFTER = 'client-property-last-meeting-date-after',
  CLIENT_PROPERTY_LAST_MEETING_DATE_BEFORE = 'client-property-last-meeting-date-before',
  CLIENT_PROPERTY_LAST_MEETING_DATE_DATE_BETWEEN = 'client-property-last-meeting-date-date-between',
  CLIENT_PROPERTY_DESIRED_REPORTING_FREQUENCY_CONTAINS = 'client-property-desired-reporting-frequency-contains',
  CLIENT_PROPERTY_DESIRED_REPORTING_FREQUENCY_IS = 'client-property-desired-reporting-frequency-is',
  CLIENT_PROPERTY_DESIRED_REPORTING_FREQUENCY_IS_EMPTY = 'client-property-desired-reporting-frequency-is-empty',
  CLIENT_PROPERTY_DESIRED_REPORTING_FREQUENCY_IS_NOT = 'client-property-desired-reporting-frequency-is-not',
  CLIENT_PROPERTY_DESIRED_REPORTING_FREQUENCY_IS_NOT_EMPTY = 'client-property-desired-reporting-frequency-is-not-empty',
  CLIENT_PROPERTY_DESIRED_REPORTING_FREQUENCY_ONE_OF = 'client-property-desired-reporting-frequency-one-of',
  CLIENT_PROPERTY_DESIRED_REPORTING_MEDIUM_CONTAINS = 'client-property-desired-reporting-medium-contains',
  CLIENT_PROPERTY_DESIRED_REPORTING_MEDIUM_IS = 'client-property-desired-reporting-medium-is',
  CLIENT_PROPERTY_DESIRED_REPORTING_MEDIUM_IS_EMPTY = 'client-property-desired-reporting-medium-is-empty',
  CLIENT_PROPERTY_DESIRED_REPORTING_MEDIUM_IS_NOT = 'client-property-desired-reporting-medium-is-not',
  CLIENT_PROPERTY_DESIRED_REPORTING_MEDIUM_IS_NOT_EMPTY = 'client-property-desired-reporting-medium-is-not-empty',
  CLIENT_PROPERTY_DESIRED_REPORTING_MEDIUM_ONE_OF = 'client-property-desired-reporting-medium-one-of',
  CLIENT_PROPERTY_EMPLOYMENT_STATUS_CONTAINS = 'client-property-employment-status-contains',
  CLIENT_PROPERTY_EMPLOYMENT_STATUS_IS = 'client-property-employment-status-is',
  CLIENT_PROPERTY_EMPLOYMENT_STATUS_IS_EMPTY = 'client-property-employment-status-is-empty',
  CLIENT_PROPERTY_EMPLOYMENT_STATUS_IS_NOT = 'client-property-employment-status-is-not',
  CLIENT_PROPERTY_EMPLOYMENT_STATUS_IS_NOT_EMPTY = 'client-property-employment-status-is-not-empty',
  CLIENT_PROPERTY_EMPLOYMENT_STATUS_ONE_OF = 'client-property-employment-status-one-of',
  CLIENT_PROPERTY_USER_DEFINED_1_IS_NOT_EMPTY = 'client-property-user-defined-1-is-not-empty',
  CLIENT_PROPERTY_USER_DEFINED_1_IS = 'client-property-user-defined-1-is',
  CLIENT_PROPERTY_USER_DEFINED_1_IS_EMPTY = 'client-property-user-defined-1-is-empty',
  CLIENT_PROPERTY_USER_DEFINED_1_ONE_OF = 'client-property-user-defined-1-one-of',
  CLIENT_PROPERTY_USER_DEFINED_1_CONTAINS = 'client-property-user-defined-1-contains',
  CLIENT_PROPERTY_USER_DEFINED_1_IS_NOT = 'client-property-user-defined-1-is-not',
  CLIENT_PROPERTY_USER_DEFINED_2_IS_NOT_EMPTY = 'client-property-user-defined-2-is-not-empty',
  CLIENT_PROPERTY_USER_DEFINED_2_IS = 'client-property-user-defined-2-is',
  CLIENT_PROPERTY_USER_DEFINED_2_IS_EMPTY = 'client-property-user-defined-2-is-empty',
  CLIENT_PROPERTY_USER_DEFINED_2_ONE_OF = 'client-property-user-defined-2-one-of',
  CLIENT_PROPERTY_USER_DEFINED_2_CONTAINS = 'client-property-user-defined-2-contains',
  CLIENT_PROPERTY_USER_DEFINED_2_IS_NOT = 'client-property-user-defined-2-is-not',
  CLIENT_PROPERTY_USER_DEFINED_3_IS_NOT_EMPTY = 'client-property-user-defined-3-is-not-empty',
  CLIENT_PROPERTY_USER_DEFINED_3_IS = 'client-property-user-defined-3-is',
  CLIENT_PROPERTY_USER_DEFINED_3_IS_EMPTY = 'client-property-user-defined-3-is-empty',
  CLIENT_PROPERTY_USER_DEFINED_3_ONE_OF = 'client-property-user-defined-3-one-of',
  CLIENT_PROPERTY_USER_DEFINED_3_CONTAINS = 'client-property-user-defined-3-contains',
  CLIENT_PROPERTY_USER_DEFINED_3_IS_NOT = 'client-property-user-defined-3-is-not',
  CLIENT_PROPERTY_USER_DEFINED_4_IS_NOT_EMPTY = 'client-property-user-defined-4-is-not-empty',
  CLIENT_PROPERTY_USER_DEFINED_4_IS = 'client-property-user-defined-4-is',
  CLIENT_PROPERTY_USER_DEFINED_4_IS_EMPTY = 'client-property-user-defined-4-is-empty',
  CLIENT_PROPERTY_USER_DEFINED_4_ONE_OF = 'client-property-user-defined-4-one-of',
  CLIENT_PROPERTY_USER_DEFINED_4_CONTAINS = 'client-property-user-defined-4-contains',
  CLIENT_PROPERTY_USER_DEFINED_4_IS_NOT = 'client-property-user-defined-4-is-not',
  CLIENT_PROPERTY_USER_DEFINED_5_IS_NOT_EMPTY = 'client-property-user-defined-5-is-not-empty',
  CLIENT_PROPERTY_USER_DEFINED_5_IS = 'client-property-user-defined-5-is',
  CLIENT_PROPERTY_USER_DEFINED_5_IS_EMPTY = 'client-property-user-defined-5-is-empty',
  CLIENT_PROPERTY_USER_DEFINED_5_ONE_OF = 'client-property-user-defined-5-one-of',
  CLIENT_PROPERTY_USER_DEFINED_5_CONTAINS = 'client-property-user-defined-5-contains',
  CLIENT_PROPERTY_USER_DEFINED_5_IS_NOT = 'client-property-user-defined-5-is-not',
  CLIENT_PROPERTY_REPORTING_NAME_IS_NOT_EMPTY = 'client-property-reporting-name-is-not-empty',
  CLIENT_PROPERTY_REPORTING_NAME_IS = 'client-property-reporting-name-is',
  CLIENT_PROPERTY_REPORTING_NAME_IS_EMPTY = 'client-property-reporting-name-is-empty',
  CLIENT_PROPERTY_REPORTING_NAME_ONE_OF = 'client-property-reporting-name-one-of',
  CLIENT_PROPERTY_REPORTING_NAME_CONTAINS = 'client-property-reporting-name-contains',
  CLIENT_PROPERTY_REPORTING_NAME_IS_NOT = 'client-property-reporting-name-is-not',
  CLIENT_PROPERTY_JURISDICTION_COUNTRY_CONTAINS = 'client-property-jurisdiction-country-contains',
  CLIENT_PROPERTY_JURISDICTION_COUNTRY_IS = 'client-property-jurisdiction-country-is',
  CLIENT_PROPERTY_JURISDICTION_COUNTRY_IS_EMPTY = 'client-property-jurisdiction-country-is-empty',
  CLIENT_PROPERTY_JURISDICTION_COUNTRY_IS_NOT = 'client-property-jurisdiction-country-is-not',
  CLIENT_PROPERTY_JURISDICTION_COUNTRY_IS_NOT_EMPTY = 'client-property-jurisdiction-country-is-not-empty',
  CLIENT_PROPERTY_JURISDICTION_COUNTRY_ONE_OF = 'client-property-jurisdiction-country-one-of',
  CLIENT_PROPERTY_JURISDICTION_PROVINCE_CONTAINS = 'client-property-jurisdiction-province-contains',
  CLIENT_PROPERTY_JURISDICTION_PROVINCE_IS = 'client-property-jurisdiction-province-is',
  CLIENT_PROPERTY_JURISDICTION_PROVINCE_IS_EMPTY = 'client-property-jurisdiction-province-is-empty',
  CLIENT_PROPERTY_JURISDICTION_PROVINCE_IS_NOT = 'client-property-jurisdiction-province-is-not',
  CLIENT_PROPERTY_JURISDICTION_PROVINCE_IS_NOT_EMPTY = 'client-property-jurisdiction-province-is-not-empty',
  CLIENT_PROPERTY_JURISDICTION_PROVINCE_ONE_OF = 'client-property-jurisdiction-province-one-of',
  ACCOUNT_PROPERTY_ACCOUNT_ID_IS_NOT_EMPTY = 'account-property-account-id-is-not-empty',
  ACCOUNT_PROPERTY_ACCOUNT_ID_IS = 'account-property-account-id-is',
  ACCOUNT_PROPERTY_ACCOUNT_ID_IS_EMPTY = 'account-property-account-id-is-empty',
  ACCOUNT_PROPERTY_ACCOUNT_ID_ONE_OF = 'account-property-account-id-one-of',
  ACCOUNT_PROPERTY_ACCOUNT_ID_CONTAINS = 'account-property-account-id-contains',
  ACCOUNT_PROPERTY_ACCOUNT_ID_IS_NOT = 'account-property-account-id-is-not',
  ACCOUNT_PROPERTY_ACCOUNT_NAME_IS_NOT_EMPTY = 'account-property-account-name-is-not-empty',
  ACCOUNT_PROPERTY_ACCOUNT_NAME_IS = 'account-property-account-name-is',
  ACCOUNT_PROPERTY_ACCOUNT_NAME_IS_EMPTY = 'account-property-account-name-is-empty',
  ACCOUNT_PROPERTY_ACCOUNT_NAME_ONE_OF = 'account-property-account-name-one-of',
  ACCOUNT_PROPERTY_ACCOUNT_NAME_CONTAINS = 'account-property-account-name-contains',
  ACCOUNT_PROPERTY_ACCOUNT_NAME_IS_NOT = 'account-property-account-name-is-not',
  ACCOUNT_PROPERTY_ENTITY_ID_UUIDIN = 'account-property-entity-id-uuidin',
  ACCOUNT_PROPERTY_ENTITY_ID_UUIDEQUALS = 'account-property-entity-id-uuidequals',
  ACCOUNT_PROPERTY_INCEPTION_DATE_DATE_BETWEEN = 'account-property-inception-date-date-between',
  ACCOUNT_PROPERTY_INCEPTION_DATE_ON = 'account-property-inception-date-on',
  ACCOUNT_PROPERTY_INCEPTION_DATE_BEFORE = 'account-property-inception-date-before',
  ACCOUNT_PROPERTY_INCEPTION_DATE_AFTER = 'account-property-inception-date-after',
  ACCOUNT_PROPERTY_ACCOUNT_TYPE_IS_NOT_EMPTY = 'account-property-account-type-is-not-empty',
  ACCOUNT_PROPERTY_ACCOUNT_TYPE_IS = 'account-property-account-type-is',
  ACCOUNT_PROPERTY_ACCOUNT_TYPE_IS_EMPTY = 'account-property-account-type-is-empty',
  ACCOUNT_PROPERTY_ACCOUNT_TYPE_ONE_OF = 'account-property-account-type-one-of',
  ACCOUNT_PROPERTY_ACCOUNT_TYPE_CONTAINS = 'account-property-account-type-contains',
  ACCOUNT_PROPERTY_ACCOUNT_TYPE_IS_NOT = 'account-property-account-type-is-not',
  ACCOUNT_PROPERTY_MARGIN_STATUS_IS_NOT_EMPTY = 'account-property-margin-status-is-not-empty',
  ACCOUNT_PROPERTY_MARGIN_STATUS_IS = 'account-property-margin-status-is',
  ACCOUNT_PROPERTY_MARGIN_STATUS_IS_EMPTY = 'account-property-margin-status-is-empty',
  ACCOUNT_PROPERTY_MARGIN_STATUS_ONE_OF = 'account-property-margin-status-one-of',
  ACCOUNT_PROPERTY_MARGIN_STATUS_IS_NOT = 'account-property-margin-status-is-not',
  ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS_NOT_EMPTY = 'account-property-shorting-allowed-is-not-empty',
  ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS = 'account-property-shorting-allowed-is',
  ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS_EMPTY = 'account-property-shorting-allowed-is-empty',
  ACCOUNT_PROPERTY_SHORTING_ALLOWED_ONE_OF = 'account-property-shorting-allowed-one-of',
  ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS_NOT = 'account-property-shorting-allowed-is-not',
  ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS_NOT_EMPTY = 'account-property-registration-status-is-not-empty',
  ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS = 'account-property-registration-status-is',
  ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS_EMPTY = 'account-property-registration-status-is-empty',
  ACCOUNT_PROPERTY_REGISTRATION_STATUS_ONE_OF = 'account-property-registration-status-one-of',
  ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS_NOT = 'account-property-registration-status-is-not',
  ACCOUNT_PROPERTY_TAX_STATUS_IS_NOT_EMPTY = 'account-property-tax-status-is-not-empty',
  ACCOUNT_PROPERTY_TAX_STATUS_IS = 'account-property-tax-status-is',
  ACCOUNT_PROPERTY_TAX_STATUS_IS_EMPTY = 'account-property-tax-status-is-empty',
  ACCOUNT_PROPERTY_TAX_STATUS_ONE_OF = 'account-property-tax-status-one-of',
  ACCOUNT_PROPERTY_TAX_STATUS_IS_NOT = 'account-property-tax-status-is-not',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_IS = 'account-property-investment-mandate-is',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_ONE_OF = 'account-property-investment-mandate-one-of',
  ACCOUNT_METRIC_ASSETS_UNDER_MANAGEMENT_BETWEEN = 'account-metric-assets-under-management-between',
  ACCOUNT_METRIC_ASSETS_UNDER_MANAGEMENT_GTE = 'account-metric-assets-under-management-gte',
  ACCOUNT_METRIC_ASSETS_UNDER_MANAGEMENT_NOTEQUALS = 'account-metric-assets-under-management-notequals',
  ACCOUNT_METRIC_ASSETS_UNDER_MANAGEMENT_LTE = 'account-metric-assets-under-management-lte',
  ACCOUNT_METRIC_ASSETS_UNDER_MANAGEMENT_EQUALS = 'account-metric-assets-under-management-equals',
  ACCOUNT_METRIC_ASSETS_UNDER_MANAGEMENT_IN = 'account-metric-assets-under-management-in',
  ACCOUNT_METRIC_RETURN_NET_LAST_MONTH_BETWEEN = 'account-metric-return-net-last-month-between',
  ACCOUNT_METRIC_RETURN_NET_LAST_MONTH_GTE = 'account-metric-return-net-last-month-gte',
  ACCOUNT_METRIC_RETURN_NET_LAST_MONTH_NOTEQUALS = 'account-metric-return-net-last-month-notequals',
  ACCOUNT_METRIC_RETURN_NET_LAST_MONTH_LTE = 'account-metric-return-net-last-month-lte',
  ACCOUNT_METRIC_RETURN_NET_LAST_MONTH_EQUALS = 'account-metric-return-net-last-month-equals',
  ACCOUNT_METRIC_RETURN_NET_LAST_MONTH_IN = 'account-metric-return-net-last-month-in',
  ACCOUNT_METRIC_RETURN_NET_LAST_QUARTER_BETWEEN = 'account-metric-return-net-last-quarter-between',
  ACCOUNT_METRIC_RETURN_NET_LAST_QUARTER_GTE = 'account-metric-return-net-last-quarter-gte',
  ACCOUNT_METRIC_RETURN_NET_LAST_QUARTER_NOTEQUALS = 'account-metric-return-net-last-quarter-notequals',
  ACCOUNT_METRIC_RETURN_NET_LAST_QUARTER_LTE = 'account-metric-return-net-last-quarter-lte',
  ACCOUNT_METRIC_RETURN_NET_LAST_QUARTER_EQUALS = 'account-metric-return-net-last-quarter-equals',
  ACCOUNT_METRIC_RETURN_NET_LAST_QUARTER_IN = 'account-metric-return-net-last-quarter-in',
  ACCOUNT_METRIC_RETURN_NET_YTD_BETWEEN = 'account-metric-return-net-ytd-between',
  ACCOUNT_METRIC_RETURN_NET_YTD_GTE = 'account-metric-return-net-ytd-gte',
  ACCOUNT_METRIC_RETURN_NET_YTD_NOTEQUALS = 'account-metric-return-net-ytd-notequals',
  ACCOUNT_METRIC_RETURN_NET_YTD_LTE = 'account-metric-return-net-ytd-lte',
  ACCOUNT_METRIC_RETURN_NET_YTD_EQUALS = 'account-metric-return-net-ytd-equals',
  ACCOUNT_METRIC_RETURN_NET_YTD_IN = 'account-metric-return-net-ytd-in',
  ACCOUNT_METRIC_RETURN_NET_LAST_YEAR_BETWEEN = 'account-metric-return-net-last-year-between',
  ACCOUNT_METRIC_RETURN_NET_LAST_YEAR_GTE = 'account-metric-return-net-last-year-gte',
  ACCOUNT_METRIC_RETURN_NET_LAST_YEAR_NOTEQUALS = 'account-metric-return-net-last-year-notequals',
  ACCOUNT_METRIC_RETURN_NET_LAST_YEAR_LTE = 'account-metric-return-net-last-year-lte',
  ACCOUNT_METRIC_RETURN_NET_LAST_YEAR_EQUALS = 'account-metric-return-net-last-year-equals',
  ACCOUNT_METRIC_RETURN_NET_LAST_YEAR_IN = 'account-metric-return-net-last-year-in',
  ACCOUNT_METRIC_RETURN_NET_3_YEARS_BETWEEN = 'account-metric-return-net-3-years-between',
  ACCOUNT_METRIC_RETURN_NET_3_YEARS_GTE = 'account-metric-return-net-3-years-gte',
  ACCOUNT_METRIC_RETURN_NET_3_YEARS_NOTEQUALS = 'account-metric-return-net-3-years-notequals',
  ACCOUNT_METRIC_RETURN_NET_3_YEARS_LTE = 'account-metric-return-net-3-years-lte',
  ACCOUNT_METRIC_RETURN_NET_3_YEARS_EQUALS = 'account-metric-return-net-3-years-equals',
  ACCOUNT_METRIC_RETURN_NET_3_YEARS_IN = 'account-metric-return-net-3-years-in',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_BETWEEN = 'account-metric-net-depositwithdrawal-last-month-between',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_GTE = 'account-metric-net-depositwithdrawal-last-month-gte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_NOTEQUALS = 'account-metric-net-depositwithdrawal-last-month-notequals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_LTE = 'account-metric-net-depositwithdrawal-last-month-lte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_EQUALS = 'account-metric-net-depositwithdrawal-last-month-equals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_IN = 'account-metric-net-depositwithdrawal-last-month-in',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_BETWEEN = 'account-metric-net-depositwithdrawal-last-quarter-between',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_GTE = 'account-metric-net-depositwithdrawal-last-quarter-gte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_NOTEQUALS = 'account-metric-net-depositwithdrawal-last-quarter-notequals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_LTE = 'account-metric-net-depositwithdrawal-last-quarter-lte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_EQUALS = 'account-metric-net-depositwithdrawal-last-quarter-equals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_IN = 'account-metric-net-depositwithdrawal-last-quarter-in',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_BETWEEN = 'account-metric-net-depositwithdrawal-ytd-between',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_GTE = 'account-metric-net-depositwithdrawal-ytd-gte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_NOTEQUALS = 'account-metric-net-depositwithdrawal-ytd-notequals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_LTE = 'account-metric-net-depositwithdrawal-ytd-lte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_EQUALS = 'account-metric-net-depositwithdrawal-ytd-equals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_YTD_IN = 'account-metric-net-depositwithdrawal-ytd-in',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_BETWEEN = 'account-metric-net-depositwithdrawal-last-year-between',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_GTE = 'account-metric-net-depositwithdrawal-last-year-gte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_NOTEQUALS = 'account-metric-net-depositwithdrawal-last-year-notequals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_LTE = 'account-metric-net-depositwithdrawal-last-year-lte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_EQUALS = 'account-metric-net-depositwithdrawal-last-year-equals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_IN = 'account-metric-net-depositwithdrawal-last-year-in',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_BETWEEN = 'account-metric-net-depositwithdrawal-3-years-between',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_GTE = 'account-metric-net-depositwithdrawal-3-years-gte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_NOTEQUALS = 'account-metric-net-depositwithdrawal-3-years-notequals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_LTE = 'account-metric-net-depositwithdrawal-3-years-lte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_EQUALS = 'account-metric-net-depositwithdrawal-3-years-equals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_IN = 'account-metric-net-depositwithdrawal-3-years-in',
  ACCOUNT_PROPERTY_MODEL_PORTFOLIO_UUIDEQUALS = 'account-property-model-portfolio-uuidequals',
  ACCOUNT_PROPERTY_STATUS_IS = 'account-property-status-is',
  ACCOUNT_PROPERTY_STATUS_IS_EMPTY = 'account-property-status-is-empty',
  ACCOUNT_PROPERTY_STATUS_IS_NOT = 'account-property-status-is-not',
  ACCOUNT_PROPERTY_STATUS_IS_NOT_EMPTY = 'account-property-status-is-not-empty',
  ACCOUNT_PROPERTY_STATUS_ONE_OF = 'account-property-status-one-of',
  ACCOUNT_PROPERTY_MANDATE_RISK_PROFILE_CONTAINS = 'account-property-mandate-risk-profile-contains',
  ACCOUNT_PROPERTY_MANDATE_RISK_PROFILE_IS = 'account-property-mandate-risk-profile-is',
  ACCOUNT_PROPERTY_MANDATE_RISK_PROFILE_IS_EMPTY = 'account-property-mandate-risk-profile-is-empty',
  ACCOUNT_PROPERTY_MANDATE_RISK_PROFILE_IS_NOT = 'account-property-mandate-risk-profile-is-not',
  ACCOUNT_PROPERTY_MANDATE_RISK_PROFILE_IS_NOT_EMPTY = 'account-property-mandate-risk-profile-is-not-empty',
  ACCOUNT_PROPERTY_MANDATE_RISK_PROFILE_ONE_OF = 'account-property-mandate-risk-profile-one-of',
  ACCOUNT_PROPERTY_MODEL_PORTFOLIO_UUIDIN = 'account-property-model-portfolio-uuidin',
  ACCOUNT_METRIC_CASH_BALANCE_LTE = 'account-metric-cash-balance-lte',
  ACCOUNT_METRIC_CASH_BALANCE_EQUALS = 'account-metric-cash-balance-equals',
  ACCOUNT_METRIC_CASH_BALANCE_NOTEQUALS = 'account-metric-cash-balance-notequals',
  ACCOUNT_METRIC_CASH_BALANCE_GTE = 'account-metric-cash-balance-gte',
  ACCOUNT_METRIC_CASH_BALANCE_IN = 'account-metric-cash-balance-in',
  ACCOUNT_METRIC_CASH_BALANCE_BETWEEN = 'account-metric-cash-balance-between',
  ACCOUNT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_LTE = 'account-metric-gainloss-unrealized-since-inception-lte',
  ACCOUNT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_EQUALS = 'account-metric-gainloss-unrealized-since-inception-equals',
  ACCOUNT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_NOTEQUALS = 'account-metric-gainloss-unrealized-since-inception-notequals',
  ACCOUNT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_GTE = 'account-metric-gainloss-unrealized-since-inception-gte',
  ACCOUNT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_IN = 'account-metric-gainloss-unrealized-since-inception-in',
  ACCOUNT_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_BETWEEN = 'account-metric-gainloss-unrealized-since-inception-between',
  ACCOUNT_METRIC_RETURN_NET_SINCE_INCEPTION_LTE = 'account-metric-return-net-since-inception-lte',
  ACCOUNT_METRIC_RETURN_NET_SINCE_INCEPTION_EQUALS = 'account-metric-return-net-since-inception-equals',
  ACCOUNT_METRIC_RETURN_NET_SINCE_INCEPTION_NOTEQUALS = 'account-metric-return-net-since-inception-notequals',
  ACCOUNT_METRIC_RETURN_NET_SINCE_INCEPTION_GTE = 'account-metric-return-net-since-inception-gte',
  ACCOUNT_METRIC_RETURN_NET_SINCE_INCEPTION_IN = 'account-metric-return-net-since-inception-in',
  ACCOUNT_METRIC_RETURN_NET_SINCE_INCEPTION_BETWEEN = 'account-metric-return-net-since-inception-between',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_LTE = 'account-metric-net-depositwithdrawal-since-inception-lte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_EQUALS = 'account-metric-net-depositwithdrawal-since-inception-equals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_NOTEQUALS = 'account-metric-net-depositwithdrawal-since-inception-notequals',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_GTE = 'account-metric-net-depositwithdrawal-since-inception-gte',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_IN = 'account-metric-net-depositwithdrawal-since-inception-in',
  ACCOUNT_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_BETWEEN = 'account-metric-net-depositwithdrawal-since-inception-between',
  ACCOUNT_PROPERTY_DATE_OPENED_ON = 'account-property-date-opened-on',
  ACCOUNT_PROPERTY_DATE_OPENED_AFTER = 'account-property-date-opened-after',
  ACCOUNT_PROPERTY_DATE_OPENED_BEFORE = 'account-property-date-opened-before',
  ACCOUNT_PROPERTY_DATE_OPENED_DATE_BETWEEN = 'account-property-date-opened-date-between',
  ACCOUNT_PROPERTY_CURRENCY_CONTAINS = 'account-property-currency-contains',
  ACCOUNT_PROPERTY_CURRENCY_IS = 'account-property-currency-is',
  ACCOUNT_PROPERTY_CURRENCY_IS_EMPTY = 'account-property-currency-is-empty',
  ACCOUNT_PROPERTY_CURRENCY_IS_NOT = 'account-property-currency-is-not',
  ACCOUNT_PROPERTY_CURRENCY_IS_NOT_EMPTY = 'account-property-currency-is-not-empty',
  ACCOUNT_PROPERTY_CURRENCY_ONE_OF = 'account-property-currency-one-of',
  ACCOUNT_PROPERTY_ACCOUNT_REP_CODE_CONTAINS = 'account-property-account-rep-code-contains',
  ACCOUNT_PROPERTY_ACCOUNT_REP_CODE_IS = 'account-property-account-rep-code-is',
  ACCOUNT_PROPERTY_ACCOUNT_REP_CODE_IS_EMPTY = 'account-property-account-rep-code-is-empty',
  ACCOUNT_PROPERTY_ACCOUNT_REP_CODE_IS_NOT = 'account-property-account-rep-code-is-not',
  ACCOUNT_PROPERTY_ACCOUNT_REP_CODE_IS_NOT_EMPTY = 'account-property-account-rep-code-is-not-empty',
  ACCOUNT_PROPERTY_ACCOUNT_REP_CODE_ONE_OF = 'account-property-account-rep-code-one-of',
  ACCOUNT_PROPERTY_CREATION_DATE_ON = 'account-property-creation-date-on',
  ACCOUNT_PROPERTY_CREATION_DATE_AFTER = 'account-property-creation-date-after',
  ACCOUNT_PROPERTY_CREATION_DATE_BEFORE = 'account-property-creation-date-before',
  ACCOUNT_PROPERTY_CREATION_DATE_DATE_BETWEEN = 'account-property-creation-date-date-between',
  ACCOUNT_PROPERTY_ADVISORY_FIRM_CONTAINS = 'account-property-advisory-firm-contains',
  ACCOUNT_PROPERTY_ADVISORY_FIRM_IS = 'account-property-advisory-firm-is',
  ACCOUNT_PROPERTY_ADVISORY_FIRM_IS_EMPTY = 'account-property-advisory-firm-is-empty',
  ACCOUNT_PROPERTY_ADVISORY_FIRM_IS_NOT = 'account-property-advisory-firm-is-not',
  ACCOUNT_PROPERTY_ADVISORY_FIRM_IS_NOT_EMPTY = 'account-property-advisory-firm-is-not-empty',
  ACCOUNT_PROPERTY_ADVISORY_FIRM_ONE_OF = 'account-property-advisory-firm-one-of',
  ACCOUNT_PROPERTY_CUSTODIAN_IS_NOT_EMPTY = 'account-property-custodian-is-not-empty',
  ACCOUNT_PROPERTY_CUSTODIAN_IS = 'account-property-custodian-is',
  ACCOUNT_PROPERTY_CUSTODIAN_IS_EMPTY = 'account-property-custodian-is-empty',
  ACCOUNT_PROPERTY_CUSTODIAN_ONE_OF = 'account-property-custodian-one-of',
  ACCOUNT_PROPERTY_CUSTODIAN_CONTAINS = 'account-property-custodian-contains',
  ACCOUNT_PROPERTY_CUSTODIAN_IS_NOT = 'account-property-custodian-is-not',
  ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS_NOT_EMPTY = 'account-property-accounting-category-is-not-empty',
  ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS = 'account-property-accounting-category-is',
  ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS_EMPTY = 'account-property-accounting-category-is-empty',
  ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_ONE_OF = 'account-property-accounting-category-one-of',
  ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS_NOT = 'account-property-accounting-category-is-not',
  ACCOUNT_PROPERTY_SUB_ADVISOR_IS_NOT_EMPTY = 'account-property-sub-advisor-is-not-empty',
  ACCOUNT_PROPERTY_SUB_ADVISOR_IS = 'account-property-sub-advisor-is',
  ACCOUNT_PROPERTY_SUB_ADVISOR_IS_EMPTY = 'account-property-sub-advisor-is-empty',
  ACCOUNT_PROPERTY_SUB_ADVISOR_ONE_OF = 'account-property-sub-advisor-one-of',
  ACCOUNT_PROPERTY_SUB_ADVISOR_IS_NOT = 'account-property-sub-advisor-is-not',
  ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS_NOT_EMPTY = 'account-property-is-cash-reserve-is-not-empty',
  ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS = 'account-property-is-cash-reserve-is',
  ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS_EMPTY = 'account-property-is-cash-reserve-is-empty',
  ACCOUNT_PROPERTY_IS_CASH_RESERVE_ONE_OF = 'account-property-is-cash-reserve-one-of',
  ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS_NOT = 'account-property-is-cash-reserve-is-not',
  ACCOUNT_PROPERTY_USER_DEFINED_1_IS_NOT_EMPTY = 'account-property-user-defined-1-is-not-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_1_IS = 'account-property-user-defined-1-is',
  ACCOUNT_PROPERTY_USER_DEFINED_1_IS_EMPTY = 'account-property-user-defined-1-is-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_1_ONE_OF = 'account-property-user-defined-1-one-of',
  ACCOUNT_PROPERTY_USER_DEFINED_1_CONTAINS = 'account-property-user-defined-1-contains',
  ACCOUNT_PROPERTY_USER_DEFINED_1_IS_NOT = 'account-property-user-defined-1-is-not',
  ACCOUNT_PROPERTY_USER_DEFINED_2_IS_NOT_EMPTY = 'account-property-user-defined-2-is-not-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_2_IS = 'account-property-user-defined-2-is',
  ACCOUNT_PROPERTY_USER_DEFINED_2_IS_EMPTY = 'account-property-user-defined-2-is-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_2_ONE_OF = 'account-property-user-defined-2-one-of',
  ACCOUNT_PROPERTY_USER_DEFINED_2_CONTAINS = 'account-property-user-defined-2-contains',
  ACCOUNT_PROPERTY_USER_DEFINED_2_IS_NOT = 'account-property-user-defined-2-is-not',
  ACCOUNT_PROPERTY_USER_DEFINED_3_IS_NOT_EMPTY = 'account-property-user-defined-3-is-not-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_3_IS = 'account-property-user-defined-3-is',
  ACCOUNT_PROPERTY_USER_DEFINED_3_IS_EMPTY = 'account-property-user-defined-3-is-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_3_ONE_OF = 'account-property-user-defined-3-one-of',
  ACCOUNT_PROPERTY_USER_DEFINED_3_CONTAINS = 'account-property-user-defined-3-contains',
  ACCOUNT_PROPERTY_USER_DEFINED_3_IS_NOT = 'account-property-user-defined-3-is-not',
  ACCOUNT_PROPERTY_USER_DEFINED_4_IS_NOT_EMPTY = 'account-property-user-defined-4-is-not-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_4_IS = 'account-property-user-defined-4-is',
  ACCOUNT_PROPERTY_USER_DEFINED_4_IS_EMPTY = 'account-property-user-defined-4-is-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_4_ONE_OF = 'account-property-user-defined-4-one-of',
  ACCOUNT_PROPERTY_USER_DEFINED_4_CONTAINS = 'account-property-user-defined-4-contains',
  ACCOUNT_PROPERTY_USER_DEFINED_4_IS_NOT = 'account-property-user-defined-4-is-not',
  ACCOUNT_PROPERTY_USER_DEFINED_5_IS_NOT_EMPTY = 'account-property-user-defined-5-is-not-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_5_IS = 'account-property-user-defined-5-is',
  ACCOUNT_PROPERTY_USER_DEFINED_5_IS_EMPTY = 'account-property-user-defined-5-is-empty',
  ACCOUNT_PROPERTY_USER_DEFINED_5_ONE_OF = 'account-property-user-defined-5-one-of',
  ACCOUNT_PROPERTY_USER_DEFINED_5_CONTAINS = 'account-property-user-defined-5-contains',
  ACCOUNT_PROPERTY_USER_DEFINED_5_IS_NOT = 'account-property-user-defined-5-is-not',
  ACCOUNT_PROPERTY_ASSET_CLASS_IS_NOT_EMPTY = 'account-property-asset-class-is-not-empty',
  ACCOUNT_PROPERTY_ASSET_CLASS_EQUALS = 'account-property-asset-class-equals',
  ACCOUNT_PROPERTY_ASSET_CLASS_IS_EMPTY = 'account-property-asset-class-is-empty',
  ACCOUNT_PROPERTY_ASSET_CLASS_ONE_OF = 'account-property-asset-class-one-of',
  ACCOUNT_PROPERTY_ASSET_CLASS_NOTEQUALS = 'account-property-asset-class-notequals',
  ACCOUNT_PROPERTY_ASSET_SUB_CLASS_IS_NOT_EMPTY = 'account-property-asset-sub-class-is-not-empty',
  ACCOUNT_PROPERTY_ASSET_SUB_CLASS_EQUALS = 'account-property-asset-sub-class-equals',
  ACCOUNT_PROPERTY_ASSET_SUB_CLASS_IS_EMPTY = 'account-property-asset-sub-class-is-empty',
  ACCOUNT_PROPERTY_ASSET_SUB_CLASS_ONE_OF = 'account-property-asset-sub-class-one-of',
  ACCOUNT_PROPERTY_ASSET_SUB_CLASS_NOTEQUALS = 'account-property-asset-sub-class-notequals',
  ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS = 'account-property-advisory-scope-is',
  ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS_EMPTY = 'account-property-advisory-scope-is-empty',
  ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS_NOT = 'account-property-advisory-scope-is-not',
  ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS_NOT_EMPTY = 'account-property-advisory-scope-is-not-empty',
  ACCOUNT_PROPERTY_ADVISORY_SCOPE_IN = 'account-property-advisory-scope-in',
  ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS_NOT_EMPTY = 'account-property-is-pro-account-is-not-empty',
  ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS = 'account-property-is-pro-account-is',
  ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS_EMPTY = 'account-property-is-pro-account-is-empty',
  ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_ONE_OF = 'account-property-is-pro-account-one-of',
  ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS_NOT = 'account-property-is-pro-account-is-not',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_LTE = 'account-property-minimum-cash-reserve-lte',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_LT = 'account-property-minimum-cash-reserve-lt',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_EQUALS = 'account-property-minimum-cash-reserve-equals',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_NOTEQUALS = 'account-property-minimum-cash-reserve-notequals',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_GTE = 'account-property-minimum-cash-reserve-gte',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_GT = 'account-property-minimum-cash-reserve-gt',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_IN = 'account-property-minimum-cash-reserve-in',
  ACCOUNT_PROPERTY_MINIMUM_CASH_RESERVE_BETWEEN = 'account-property-minimum-cash-reserve-between',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_LTE = 'account-property-target-cash-reserve-lte',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_LT = 'account-property-target-cash-reserve-lt',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_EQUALS = 'account-property-target-cash-reserve-equals',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_NOTEQUALS = 'account-property-target-cash-reserve-notequals',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_GTE = 'account-property-target-cash-reserve-gte',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_GT = 'account-property-target-cash-reserve-gt',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_IN = 'account-property-target-cash-reserve-in',
  ACCOUNT_PROPERTY_TARGET_CASH_RESERVE_BETWEEN = 'account-property-target-cash-reserve-between',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_LTE = 'account-property-Maximum-cash-reserve-lte',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_LT = 'account-property-Maximum-cash-reserve-lt',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_EQUALS = 'account-property-Maximum-cash-reserve-equals',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_NOTEQUALS = 'account-property-Maximum-cash-reserve-notequals',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_GTE = 'account-property-Maximum-cash-reserve-gte',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_GT = 'account-property-Maximum-cash-reserve-gt',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_IN = 'account-property-Maximum-cash-reserve-in',
  ACCOUNT_PROPERTY_MAXIMUM_CASH_RESERVE_BETWEEN = 'account-property-Maximum-cash-reserve-between',
  ACCOUNT_PROPERTY_ACCOUNT_COMBINED_ID_IS = 'account-property-account-combined-id-is',
  ACCOUNT_PROPERTY_ACCOUNT_COMBINED_ID_ONE_OF = 'account-property-account-combined-id-one-of',
  ACCOUNT_PROPERTY_DATA_SOURCE_CONTAINS = 'account-property-data-source-contains',
  ACCOUNT_PROPERTY_DATA_SOURCE_IS = 'account-property-data-source-is',
  ACCOUNT_PROPERTY_DATA_SOURCE_IS_EMPTY = 'account-property-data-source-is-empty',
  ACCOUNT_PROPERTY_DATA_SOURCE_IS_NOT = 'account-property-data-source-is-not',
  ACCOUNT_PROPERTY_DATA_SOURCE_IS_NOT_EMPTY = 'account-property-data-source-is-not-empty',
  ACCOUNT_PROPERTY_DATA_SOURCE_ONE_OF = 'account-property-data-source-one-of',
  ACCOUNT_PROPERTY_ACCOUNTING_SUBCATEGORY_IS_NOT_EMPTY = 'account-property-accounting-subcategory-is-not-empty',
  ACCOUNT_PROPERTY_ACCOUNTING_SUBCATEGORY_EQUALS = 'account-property-accounting-subcategory-equals',
  ACCOUNT_PROPERTY_ACCOUNTING_SUBCATEGORY_IS_EMPTY = 'account-property-accounting-subcategory-is-empty',
  ACCOUNT_PROPERTY_ACCOUNTING_SUBCATEGORY_ONE_OF = 'account-property-accounting-subcategory-one-of',
  ACCOUNT_PROPERTY_ACCOUNTING_SUBCATEGORY_NOTEQUALS = 'account-property-accounting-subcategory-notequals',
  ACCOUNT_PROPERTY_ACTIVE_STATEGY_IS = 'account-property-active-stategy-is',
  ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS_NOT_EMPTY = 'account-property-is-discretionary-is-not-empty',
  ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS = 'account-property-is-discretionary-is',
  ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS_EMPTY = 'account-property-is-discretionary-is-empty',
  ACCOUNT_PROPERTY_IS_DISCRETIONARY_ONE_OF = 'account-property-is-discretionary-one-of',
  ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS_NOT = 'account-property-is-discretionary-is-not',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_FIRM_PROVIDED_KEY_IS_NOT_EMPTY = 'account-property-investment-mandate-firm-provided-key-is-not-empty',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_FIRM_PROVIDED_KEY_IS = 'account-property-investment-mandate-firm-provided-key-is',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_FIRM_PROVIDED_KEY_IS_EMPTY = 'account-property-investment-mandate-firm-provided-key-is-empty',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_FIRM_PROVIDED_KEY_ONE_OF = 'account-property-investment-mandate-firm-provided-key-one-of',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_FIRM_PROVIDED_KEY_CONTAINS = 'account-property-investment-mandate-firm-provided-key-contains',
  ACCOUNT_PROPERTY_INVESTMENT_MANDATE_FIRM_PROVIDED_KEY_IS_NOT = 'account-property-investment-mandate-firm-provided-key-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_ID_IS_NOT_EMPTY = 'custodian-account-property-id-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ID_IS = 'custodian-account-property-id-is',
  CUSTODIAN_ACCOUNT_PROPERTY_ID_IS_EMPTY = 'custodian-account-property-id-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ID_ONE_OF = 'custodian-account-property-id-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_ID_CONTAINS = 'custodian-account-property-id-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_ID_IS_NOT = 'custodian-account-property-id-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_NAME_IS_NOT_EMPTY = 'custodian-account-property-name-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_NAME_IS = 'custodian-account-property-name-is',
  CUSTODIAN_ACCOUNT_PROPERTY_NAME_IS_EMPTY = 'custodian-account-property-name-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_NAME_ONE_OF = 'custodian-account-property-name-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_NAME_CONTAINS = 'custodian-account-property-name-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_NAME_IS_NOT = 'custodian-account-property-name-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_ENTITY_ID_UUIDIN = 'custodian-account-property-entity-id-uuidin',
  CUSTODIAN_ACCOUNT_PROPERTY_ENTITY_ID_UUIDEQUALS = 'custodian-account-property-entity-id-uuidequals',
  CUSTODIAN_ACCOUNT_PROPERTY_INCEPTION_DATE_DATE_BETWEEN = 'custodian-account-property-inception-date-date-between',
  CUSTODIAN_ACCOUNT_PROPERTY_INCEPTION_DATE_ON = 'custodian-account-property-inception-date-on',
  CUSTODIAN_ACCOUNT_PROPERTY_INCEPTION_DATE_BEFORE = 'custodian-account-property-inception-date-before',
  CUSTODIAN_ACCOUNT_PROPERTY_INCEPTION_DATE_AFTER = 'custodian-account-property-inception-date-after',
  CUSTODIAN_ACCOUNT_PROPERTY_TYPE_IS_NOT_EMPTY = 'custodian-account-property-type-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_TYPE_IS = 'custodian-account-property-type-is',
  CUSTODIAN_ACCOUNT_PROPERTY_TYPE_IS_EMPTY = 'custodian-account-property-type-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_TYPE_ONE_OF = 'custodian-account-property-type-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_TYPE_CONTAINS = 'custodian-account-property-type-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_TYPE_IS_NOT = 'custodian-account-property-type-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_MARGIN_STATUS_IS_NOT_EMPTY = 'custodian-account-property-margin-status-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_MARGIN_STATUS_IS = 'custodian-account-property-margin-status-is',
  CUSTODIAN_ACCOUNT_PROPERTY_MARGIN_STATUS_IS_EMPTY = 'custodian-account-property-margin-status-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_MARGIN_STATUS_ONE_OF = 'custodian-account-property-margin-status-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_MARGIN_STATUS_IS_NOT = 'custodian-account-property-margin-status-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS_NOT_EMPTY = 'custodian-account-property-shorting-allowed-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS = 'custodian-account-property-shorting-allowed-is',
  CUSTODIAN_ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS_EMPTY = 'custodian-account-property-shorting-allowed-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_SHORTING_ALLOWED_ONE_OF = 'custodian-account-property-shorting-allowed-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_SHORTING_ALLOWED_IS_NOT = 'custodian-account-property-shorting-allowed-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS_NOT_EMPTY = 'custodian-account-property-registration-status-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS = 'custodian-account-property-registration-status-is',
  CUSTODIAN_ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS_EMPTY = 'custodian-account-property-registration-status-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_REGISTRATION_STATUS_ONE_OF = 'custodian-account-property-registration-status-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_REGISTRATION_STATUS_IS_NOT = 'custodian-account-property-registration-status-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_TAX_STATUS_IS_NOT_EMPTY = 'custodian-account-property-tax-status-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_TAX_STATUS_IS = 'custodian-account-property-tax-status-is',
  CUSTODIAN_ACCOUNT_PROPERTY_TAX_STATUS_IS_EMPTY = 'custodian-account-property-tax-status-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_TAX_STATUS_ONE_OF = 'custodian-account-property-tax-status-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_TAX_STATUS_IS_NOT = 'custodian-account-property-tax-status-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_STATUS_IS = 'custodian-account-property-status-is',
  CUSTODIAN_ACCOUNT_PROPERTY_STATUS_IS_EMPTY = 'custodian-account-property-status-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_STATUS_IS_NOT = 'custodian-account-property-status-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_STATUS_IS_NOT_EMPTY = 'custodian-account-property-status-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_STATUS_ONE_OF = 'custodian-account-property-status-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_DATE_OPENED_ON = 'custodian-account-property-date-opened-on',
  CUSTODIAN_ACCOUNT_PROPERTY_DATE_OPENED_AFTER = 'custodian-account-property-date-opened-after',
  CUSTODIAN_ACCOUNT_PROPERTY_DATE_OPENED_BEFORE = 'custodian-account-property-date-opened-before',
  CUSTODIAN_ACCOUNT_PROPERTY_DATE_OPENED_DATE_BETWEEN = 'custodian-account-property-date-opened-date-between',
  CUSTODIAN_ACCOUNT_PROPERTY_CURRENCY_CONTAINS = 'custodian-account-property-currency-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_CURRENCY_IS = 'custodian-account-property-currency-is',
  CUSTODIAN_ACCOUNT_PROPERTY_CURRENCY_IS_EMPTY = 'custodian-account-property-currency-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_CURRENCY_IS_NOT = 'custodian-account-property-currency-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_CURRENCY_IS_NOT_EMPTY = 'custodian-account-property-currency-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_CURRENCY_ONE_OF = 'custodian-account-property-currency-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_CREATION_DATE_ON = 'custodian-account-property-creation-date-on',
  CUSTODIAN_ACCOUNT_PROPERTY_CREATION_DATE_AFTER = 'custodian-account-property-creation-date-after',
  CUSTODIAN_ACCOUNT_PROPERTY_CREATION_DATE_BEFORE = 'custodian-account-property-creation-date-before',
  CUSTODIAN_ACCOUNT_PROPERTY_CREATION_DATE_DATE_BETWEEN = 'custodian-account-property-creation-date-date-between',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_FIRM_CONTAINS = 'custodian-account-property-advisory-firm-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_FIRM_IS = 'custodian-account-property-advisory-firm-is',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_FIRM_IS_EMPTY = 'custodian-account-property-advisory-firm-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_FIRM_IS_NOT = 'custodian-account-property-advisory-firm-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_FIRM_IS_NOT_EMPTY = 'custodian-account-property-advisory-firm-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_FIRM_ONE_OF = 'custodian-account-property-advisory-firm-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_CUSTODIAN_IS_NOT_EMPTY = 'custodian-account-property-custodian-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_CUSTODIAN_IS = 'custodian-account-property-custodian-is',
  CUSTODIAN_ACCOUNT_PROPERTY_CUSTODIAN_IS_EMPTY = 'custodian-account-property-custodian-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_CUSTODIAN_ONE_OF = 'custodian-account-property-custodian-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_CUSTODIAN_CONTAINS = 'custodian-account-property-custodian-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_CUSTODIAN_IS_NOT = 'custodian-account-property-custodian-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS_NOT_EMPTY = 'custodian-account-property-accounting-category-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS = 'custodian-account-property-accounting-category-is',
  CUSTODIAN_ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS_EMPTY = 'custodian-account-property-accounting-category-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_ONE_OF = 'custodian-account-property-accounting-category-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_ACCOUNTING_CATEGORY_IS_NOT = 'custodian-account-property-accounting-category-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_SUB_ADVISOR_IS_NOT_EMPTY = 'custodian-account-property-sub-advisor-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_SUB_ADVISOR_IS = 'custodian-account-property-sub-advisor-is',
  CUSTODIAN_ACCOUNT_PROPERTY_SUB_ADVISOR_IS_EMPTY = 'custodian-account-property-sub-advisor-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_SUB_ADVISOR_ONE_OF = 'custodian-account-property-sub-advisor-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_SUB_ADVISOR_IS_NOT = 'custodian-account-property-sub-advisor-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS_NOT_EMPTY = 'custodian-account-property-is-cash-reserve-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS = 'custodian-account-property-is-cash-reserve-is',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS_EMPTY = 'custodian-account-property-is-cash-reserve-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_CASH_RESERVE_ONE_OF = 'custodian-account-property-is-cash-reserve-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_CASH_RESERVE_IS_NOT = 'custodian-account-property-is-cash-reserve-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_1_IS_NOT_EMPTY = 'custodian-account-property-user-defined-1-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_1_IS = 'custodian-account-property-user-defined-1-is',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_1_IS_EMPTY = 'custodian-account-property-user-defined-1-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_1_ONE_OF = 'custodian-account-property-user-defined-1-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_1_CONTAINS = 'custodian-account-property-user-defined-1-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_1_IS_NOT = 'custodian-account-property-user-defined-1-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_2_IS_NOT_EMPTY = 'custodian-account-property-user-defined-2-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_2_IS = 'custodian-account-property-user-defined-2-is',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_2_IS_EMPTY = 'custodian-account-property-user-defined-2-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_2_ONE_OF = 'custodian-account-property-user-defined-2-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_2_CONTAINS = 'custodian-account-property-user-defined-2-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_2_IS_NOT = 'custodian-account-property-user-defined-2-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_3_IS_NOT_EMPTY = 'custodian-account-property-user-defined-3-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_3_IS = 'custodian-account-property-user-defined-3-is',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_3_IS_EMPTY = 'custodian-account-property-user-defined-3-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_3_ONE_OF = 'custodian-account-property-user-defined-3-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_3_CONTAINS = 'custodian-account-property-user-defined-3-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_3_IS_NOT = 'custodian-account-property-user-defined-3-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_4_IS_NOT_EMPTY = 'custodian-account-property-user-defined-4-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_4_IS = 'custodian-account-property-user-defined-4-is',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_4_IS_EMPTY = 'custodian-account-property-user-defined-4-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_4_ONE_OF = 'custodian-account-property-user-defined-4-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_4_CONTAINS = 'custodian-account-property-user-defined-4-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_4_IS_NOT = 'custodian-account-property-user-defined-4-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_5_IS_NOT_EMPTY = 'custodian-account-property-user-defined-5-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_5_IS = 'custodian-account-property-user-defined-5-is',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_5_IS_EMPTY = 'custodian-account-property-user-defined-5-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_5_ONE_OF = 'custodian-account-property-user-defined-5-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_5_CONTAINS = 'custodian-account-property-user-defined-5-contains',
  CUSTODIAN_ACCOUNT_PROPERTY_USER_DEFINED_5_IS_NOT = 'custodian-account-property-user-defined-5-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_CLASS_IS_NOT_EMPTY = 'custodian-account-property-asset-class-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_CLASS_EQUALS = 'custodian-account-property-asset-class-equals',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_CLASS_IS_EMPTY = 'custodian-account-property-asset-class-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_CLASS_ONE_OF = 'custodian-account-property-asset-class-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_CLASS_NOTEQUALS = 'custodian-account-property-asset-class-notequals',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_SUB_CLASS_IS_NOT_EMPTY = 'custodian-account-property-asset-sub-class-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_SUB_CLASS_EQUALS = 'custodian-account-property-asset-sub-class-equals',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_SUB_CLASS_IS_EMPTY = 'custodian-account-property-asset-sub-class-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_SUB_CLASS_ONE_OF = 'custodian-account-property-asset-sub-class-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_ASSET_SUB_CLASS_NOTEQUALS = 'custodian-account-property-asset-sub-class-notequals',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS = 'custodian-account-property-advisory-scope-is',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS_EMPTY = 'custodian-account-property-advisory-scope-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS_NOT = 'custodian-account-property-advisory-scope-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_SCOPE_IS_NOT_EMPTY = 'custodian-account-property-advisory-scope-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_ADVISORY_SCOPE_IN = 'custodian-account-property-advisory-scope-in',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS_NOT_EMPTY = 'custodian-account-property-is-pro-account-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS = 'custodian-account-property-is-pro-account-is',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS_EMPTY = 'custodian-account-property-is-pro-account-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_ONE_OF = 'custodian-account-property-is-pro-account-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_PRO_ACCOUNT_IS_NOT = 'custodian-account-property-is-pro-account-is-not',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS_NOT_EMPTY = 'custodian-account-property-is-discretionary-is-not-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS = 'custodian-account-property-is-discretionary-is',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS_EMPTY = 'custodian-account-property-is-discretionary-is-empty',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_DISCRETIONARY_ONE_OF = 'custodian-account-property-is-discretionary-one-of',
  CUSTODIAN_ACCOUNT_PROPERTY_IS_DISCRETIONARY_IS_NOT = 'custodian-account-property-is-discretionary-is-not',
  DOCUMENT_PROPERTY_CATEGORY_CONTAINS = 'document-property-category-contains',
  DOCUMENT_PROPERTY_TITLE_CONTAINS = 'document-property-title-contains',
  DOCUMENT_PROPERTY_CATEGORY_IS = 'document-property-category-is',
  DOCUMENT_PROPERTY_TITLE_IS = 'document-property-title-is',
  DOCUMENT_PROPERTY_CATEGORY_IS_EMPTY = 'document-property-category-is-empty',
  DOCUMENT_PROPERTY_CATEGORY_IS_NOT = 'document-property-category-is-not',
  DOCUMENT_PROPERTY_TITLE_IS_NOT = 'document-property-title-is-not',
  DOCUMENT_PROPERTY_CATEGORY_IS_NOT_EMPTY = 'document-property-category-is-not-empty',
  DOCUMENT_PROPERTY_CATEGORY_ONE_OF = 'document-property-category-one-of',
  DOCUMENT_PROPERTY_TITLE_ONE_OF = 'document-property-title-one-of',
  DOCUMENT_PROPERTY_AS_OF_DATE_ON = 'document-property-as-of-date-on',
  DOCUMENT_PROPERTY_AS_OF_DATE_AFTER = 'document-property-as-of-date-after',
  DOCUMENT_PROPERTY_AS_OF_DATE_BEFORE = 'document-property-as-of-date-before',
  DOCUMENT_PROPERTY_AS_OF_DATE_DATE_BETWEEN = 'document-property-as-of-date-date-between',
  DOCUMENT_PROPERTY_PREPARED_BY_IS = 'document-property-prepared-by-is',
  DOCUMENT_PROPERTY_PREPARED_FOR_IS = 'document-property-prepared-for-is',
  DOCUMENT_PROPERTY_PREPARED_BY_IS_EMPTY = 'document-property-prepared-by-is-empty',
  DOCUMENT_PROPERTY_PREPARED_BY_IS_NOT = 'document-property-prepared-by-is-not',
  DOCUMENT_PROPERTY_PREPARED_FOR_IS_NOT = 'document-property-prepared-for-is-not',
  DOCUMENT_PROPERTY_PREPARED_BY_IS_NOT_EMPTY = 'document-property-prepared-by-is-not-empty',
  DOCUMENT_PROPERTY_PREPARED_BY_ONE_OF = 'document-property-prepared-by-one-of',
  DOCUMENT_PROPERTY_PREPARED_FOR_ONE_OF = 'document-property-prepared-for-one-of',
  HOUSEHOLD_PROPERTY_NAME_IS_NOT_EMPTY = 'household-property-name-is-not-empty',
  HOUSEHOLD_PROPERTY_NAME_IS = 'household-property-name-is',
  HOUSEHOLD_PROPERTY_NAME_IS_EMPTY = 'household-property-name-is-empty',
  HOUSEHOLD_PROPERTY_NAME_ONE_OF = 'household-property-name-one-of',
  HOUSEHOLD_PROPERTY_NAME_CONTAINS = 'household-property-name-contains',
  HOUSEHOLD_PROPERTY_NAME_IS_NOT = 'household-property-name-is-not',
  HOUSEHOLD_PROPERTY_ENTITY_ID_UUIDIN = 'household-property-entity-id-uuidin',
  HOUSEHOLD_PROPERTY_ENTITY_ID_UUIDEQUALS = 'household-property-entity-id-uuidequals',
  HOUSEHOLD_METRIC_ASSETS_UNDER_MANAGEMENT_BETWEEN = 'household-metric-assets-under-management-between',
  HOUSEHOLD_METRIC_ASSETS_UNDER_MANAGEMENT_GTE = 'household-metric-assets-under-management-gte',
  HOUSEHOLD_METRIC_ASSETS_UNDER_MANAGEMENT_NOTEQUALS = 'household-metric-assets-under-management-notequals',
  HOUSEHOLD_METRIC_ASSETS_UNDER_MANAGEMENT_LTE = 'household-metric-assets-under-management-lte',
  HOUSEHOLD_METRIC_ASSETS_UNDER_MANAGEMENT_EQUALS = 'household-metric-assets-under-management-equals',
  HOUSEHOLD_METRIC_ASSETS_UNDER_MANAGEMENT_IN = 'household-metric-assets-under-management-in',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_MONTH_BETWEEN = 'household-metric-gainloss-realized-last-month-between',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_MONTH_GTE = 'household-metric-gainloss-realized-last-month-gte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_MONTH_NOTEQUALS = 'household-metric-gainloss-realized-last-month-notequals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_MONTH_LTE = 'household-metric-gainloss-realized-last-month-lte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_MONTH_EQUALS = 'household-metric-gainloss-realized-last-month-equals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_MONTH_IN = 'household-metric-gainloss-realized-last-month-in',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_BETWEEN = 'household-metric-gainloss-realized-last-quarter-between',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_GTE = 'household-metric-gainloss-realized-last-quarter-gte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_NOTEQUALS = 'household-metric-gainloss-realized-last-quarter-notequals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_LTE = 'household-metric-gainloss-realized-last-quarter-lte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_EQUALS = 'household-metric-gainloss-realized-last-quarter-equals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_IN = 'household-metric-gainloss-realized-last-quarter-in',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_YTD_BETWEEN = 'household-metric-gainloss-realized-ytd-between',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_YTD_GTE = 'household-metric-gainloss-realized-ytd-gte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_YTD_NOTEQUALS = 'household-metric-gainloss-realized-ytd-notequals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_YTD_LTE = 'household-metric-gainloss-realized-ytd-lte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_YTD_EQUALS = 'household-metric-gainloss-realized-ytd-equals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_YTD_IN = 'household-metric-gainloss-realized-ytd-in',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_YEAR_BETWEEN = 'household-metric-gainloss-realized-last-year-between',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_YEAR_GTE = 'household-metric-gainloss-realized-last-year-gte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_YEAR_NOTEQUALS = 'household-metric-gainloss-realized-last-year-notequals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_YEAR_LTE = 'household-metric-gainloss-realized-last-year-lte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_YEAR_EQUALS = 'household-metric-gainloss-realized-last-year-equals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_LAST_YEAR_IN = 'household-metric-gainloss-realized-last-year-in',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_3_YEARS_BETWEEN = 'household-metric-gainloss-realized-3-years-between',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_3_YEARS_GTE = 'household-metric-gainloss-realized-3-years-gte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_3_YEARS_NOTEQUALS = 'household-metric-gainloss-realized-3-years-notequals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_3_YEARS_LTE = 'household-metric-gainloss-realized-3-years-lte',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_3_YEARS_EQUALS = 'household-metric-gainloss-realized-3-years-equals',
  HOUSEHOLD_METRIC_GAINLOSS_REALIZED_3_YEARS_IN = 'household-metric-gainloss-realized-3-years-in',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_MONTH_BETWEEN = 'household-metric-return-net-last-month-between',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_MONTH_GTE = 'household-metric-return-net-last-month-gte',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_MONTH_NOTEQUALS = 'household-metric-return-net-last-month-notequals',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_MONTH_LTE = 'household-metric-return-net-last-month-lte',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_MONTH_EQUALS = 'household-metric-return-net-last-month-equals',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_MONTH_IN = 'household-metric-return-net-last-month-in',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_QUARTER_BETWEEN = 'household-metric-return-net-last-quarter-between',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_QUARTER_GTE = 'household-metric-return-net-last-quarter-gte',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_QUARTER_NOTEQUALS = 'household-metric-return-net-last-quarter-notequals',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_QUARTER_LTE = 'household-metric-return-net-last-quarter-lte',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_QUARTER_EQUALS = 'household-metric-return-net-last-quarter-equals',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_QUARTER_IN = 'household-metric-return-net-last-quarter-in',
  HOUSEHOLD_METRIC_RETURN_NET_YTD_BETWEEN = 'household-metric-return-net-ytd-between',
  HOUSEHOLD_METRIC_RETURN_NET_YTD_GTE = 'household-metric-return-net-ytd-gte',
  HOUSEHOLD_METRIC_RETURN_NET_YTD_NOTEQUALS = 'household-metric-return-net-ytd-notequals',
  HOUSEHOLD_METRIC_RETURN_NET_YTD_LTE = 'household-metric-return-net-ytd-lte',
  HOUSEHOLD_METRIC_RETURN_NET_YTD_EQUALS = 'household-metric-return-net-ytd-equals',
  HOUSEHOLD_METRIC_RETURN_NET_YTD_IN = 'household-metric-return-net-ytd-in',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_YEAR_BETWEEN = 'household-metric-return-net-last-year-between',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_YEAR_GTE = 'household-metric-return-net-last-year-gte',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_YEAR_NOTEQUALS = 'household-metric-return-net-last-year-notequals',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_YEAR_LTE = 'household-metric-return-net-last-year-lte',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_YEAR_EQUALS = 'household-metric-return-net-last-year-equals',
  HOUSEHOLD_METRIC_RETURN_NET_LAST_YEAR_IN = 'household-metric-return-net-last-year-in',
  HOUSEHOLD_METRIC_RETURN_NET_3_YEARS_BETWEEN = 'household-metric-return-net-3-years-between',
  HOUSEHOLD_METRIC_RETURN_NET_3_YEARS_GTE = 'household-metric-return-net-3-years-gte',
  HOUSEHOLD_METRIC_RETURN_NET_3_YEARS_NOTEQUALS = 'household-metric-return-net-3-years-notequals',
  HOUSEHOLD_METRIC_RETURN_NET_3_YEARS_LTE = 'household-metric-return-net-3-years-lte',
  HOUSEHOLD_METRIC_RETURN_NET_3_YEARS_EQUALS = 'household-metric-return-net-3-years-equals',
  HOUSEHOLD_METRIC_RETURN_NET_3_YEARS_IN = 'household-metric-return-net-3-years-in',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_BETWEEN = 'household-metric-net-depositwithdrawal-last-month-between',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_GTE = 'household-metric-net-depositwithdrawal-last-month-gte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_NOTEQUALS = 'household-metric-net-depositwithdrawal-last-month-notequals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_LTE = 'household-metric-net-depositwithdrawal-last-month-lte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_EQUALS = 'household-metric-net-depositwithdrawal-last-month-equals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_IN = 'household-metric-net-depositwithdrawal-last-month-in',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_BETWEEN = 'household-metric-net-depositwithdrawal-last-quarter-between',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_GTE = 'household-metric-net-depositwithdrawal-last-quarter-gte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_NOTEQUALS = 'household-metric-net-depositwithdrawal-last-quarter-notequals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_LTE = 'household-metric-net-depositwithdrawal-last-quarter-lte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_EQUALS = 'household-metric-net-depositwithdrawal-last-quarter-equals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_IN = 'household-metric-net-depositwithdrawal-last-quarter-in',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_YTD_BETWEEN = 'household-metric-net-depositwithdrawal-ytd-between',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_YTD_GTE = 'household-metric-net-depositwithdrawal-ytd-gte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_YTD_NOTEQUALS = 'household-metric-net-depositwithdrawal-ytd-notequals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_YTD_LTE = 'household-metric-net-depositwithdrawal-ytd-lte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_YTD_EQUALS = 'household-metric-net-depositwithdrawal-ytd-equals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_YTD_IN = 'household-metric-net-depositwithdrawal-ytd-in',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_BETWEEN = 'household-metric-net-depositwithdrawal-last-year-between',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_GTE = 'household-metric-net-depositwithdrawal-last-year-gte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_NOTEQUALS = 'household-metric-net-depositwithdrawal-last-year-notequals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_LTE = 'household-metric-net-depositwithdrawal-last-year-lte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_EQUALS = 'household-metric-net-depositwithdrawal-last-year-equals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_IN = 'household-metric-net-depositwithdrawal-last-year-in',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_BETWEEN = 'household-metric-net-depositwithdrawal-3-years-between',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_GTE = 'household-metric-net-depositwithdrawal-3-years-gte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_NOTEQUALS = 'household-metric-net-depositwithdrawal-3-years-notequals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_LTE = 'household-metric-net-depositwithdrawal-3-years-lte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_EQUALS = 'household-metric-net-depositwithdrawal-3-years-equals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_IN = 'household-metric-net-depositwithdrawal-3-years-in',
  HOUSEHOLD_METRIC_CASH_BALANCE_LTE = 'household-metric-cash-balance-lte',
  HOUSEHOLD_METRIC_CASH_BALANCE_EQUALS = 'household-metric-cash-balance-equals',
  HOUSEHOLD_METRIC_CASH_BALANCE_NOTEQUALS = 'household-metric-cash-balance-notequals',
  HOUSEHOLD_METRIC_CASH_BALANCE_GTE = 'household-metric-cash-balance-gte',
  HOUSEHOLD_METRIC_CASH_BALANCE_IN = 'household-metric-cash-balance-in',
  HOUSEHOLD_METRIC_CASH_BALANCE_BETWEEN = 'household-metric-cash-balance-between',
  HOUSEHOLD_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_LTE = 'household-metric-gainloss-unrealized-since-inception-lte',
  HOUSEHOLD_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_EQUALS = 'household-metric-gainloss-unrealized-since-inception-equals',
  HOUSEHOLD_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_NOTEQUALS = 'household-metric-gainloss-unrealized-since-inception-notequals',
  HOUSEHOLD_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_GTE = 'household-metric-gainloss-unrealized-since-inception-gte',
  HOUSEHOLD_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_IN = 'household-metric-gainloss-unrealized-since-inception-in',
  HOUSEHOLD_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_BETWEEN = 'household-metric-gainloss-unrealized-since-inception-between',
  HOUSEHOLD_METRIC_RETURN_NET_SINCE_INCEPTION_LTE = 'household-metric-return-net-since-inception-lte',
  HOUSEHOLD_METRIC_RETURN_NET_SINCE_INCEPTION_EQUALS = 'household-metric-return-net-since-inception-equals',
  HOUSEHOLD_METRIC_RETURN_NET_SINCE_INCEPTION_NOTEQUALS = 'household-metric-return-net-since-inception-notequals',
  HOUSEHOLD_METRIC_RETURN_NET_SINCE_INCEPTION_GTE = 'household-metric-return-net-since-inception-gte',
  HOUSEHOLD_METRIC_RETURN_NET_SINCE_INCEPTION_IN = 'household-metric-return-net-since-inception-in',
  HOUSEHOLD_METRIC_RETURN_NET_SINCE_INCEPTION_BETWEEN = 'household-metric-return-net-since-inception-between',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_LTE = 'household-metric-net-depositwithdrawal-since-inception-lte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_EQUALS = 'household-metric-net-depositwithdrawal-since-inception-equals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_NOTEQUALS = 'household-metric-net-depositwithdrawal-since-inception-notequals',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_GTE = 'household-metric-net-depositwithdrawal-since-inception-gte',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_IN = 'household-metric-net-depositwithdrawal-since-inception-in',
  HOUSEHOLD_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_BETWEEN = 'household-metric-net-depositwithdrawal-since-inception-between',
  PORTFOLIO_PROPERTY_PORTFOLIO_ID_IS_NOT_EMPTY = 'portfolio-property-portfolio-id-is-not-empty',
  PORTFOLIO_PROPERTY_PORTFOLIO_ID_IS = 'portfolio-property-portfolio-id-is',
  PORTFOLIO_PROPERTY_PORTFOLIO_ID_IS_EMPTY = 'portfolio-property-portfolio-id-is-empty',
  PORTFOLIO_PROPERTY_PORTFOLIO_ID_ONE_OF = 'portfolio-property-portfolio-id-one-of',
  PORTFOLIO_PROPERTY_PORTFOLIO_ID_CONTAINS = 'portfolio-property-portfolio-id-contains',
  PORTFOLIO_PROPERTY_PORTFOLIO_ID_IS_NOT = 'portfolio-property-portfolio-id-is-not',
  PORTFOLIO_PROPERTY_ENTITY_ID_UUIDIN = 'portfolio-property-entity-id-uuidin',
  PORTFOLIO_PROPERTY_ENTITY_ID_UUIDEQUALS = 'portfolio-property-entity-id-uuidequals',
  PORTFOLIO_PROPERTY_TYPE_ONE_OF = 'portfolio-property-type-one-of',
  PORTFOLIO_PROPERTY_TYPE_IS = 'portfolio-property-type-is',
  PORTFOLIO_PROPERTY_TYPE_IS_EMPTY = 'portfolio-property-type-is-empty',
  PORTFOLIO_PROPERTY_TYPE_IS_NOT_EMPTY = 'portfolio-property-type-is-not-empty',
  PORTFOLIO_PROPERTY_TYPE_IS_NOT = 'portfolio-property-type-is-not',
  PORTFOLIO_PROPERTY_TYPE_CONTAINS = 'portfolio-property-type-contains',
  PORTFOLIO_PROPERTY_START_DATE_ON = 'portfolio-property-start-date-on',
  PORTFOLIO_PROPERTY_START_DATE_AFTER = 'portfolio-property-start-date-after',
  PORTFOLIO_PROPERTY_START_DATE_BEFORE = 'portfolio-property-start-date-before',
  PORTFOLIO_PROPERTY_START_DATE_DATE_BETWEEN = 'portfolio-property-start-date-date-between',
  POSITION_METRIC_QUANTITY_LTE = 'position-metric-quantity-lte',
  POSITION_METRIC_QUANTITY_EQUALS = 'position-metric-quantity-equals',
  POSITION_METRIC_QUANTITY_NOTEQUALS = 'position-metric-quantity-notequals',
  POSITION_METRIC_QUANTITY_GTE = 'position-metric-quantity-gte',
  POSITION_PROPERTY_QUANTITY_IN = 'position-property-quantity-in',
  POSITION_PROPERTY_QUANTITY_BETWEEN = 'position-property-quantity-between',
  POSITION_PROPERTY_ENTITY_ID_UUIDEQUALS = 'position-property-entity-id-uuidequals',
  POSITION_PROPERTY_ENTITY_ID_UUIDIN = 'position-property-entity-id-uuidin',
  POSITION_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_BETWEEN = 'position-metric-gainloss-unrealized-since-inception-between',
  POSITION_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_NOTEQUALS = 'position-metric-gainloss-unrealized-since-inception-notequals',
  POSITION_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_GTE = 'position-metric-gainloss-unrealized-since-inception-gte',
  POSITION_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_EQUALS = 'position-metric-gainloss-unrealized-since-inception-equals',
  POSITION_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_LTE = 'position-metric-gainloss-unrealized-since-inception-lte',
  POSITION_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_IN = 'position-metric-gainloss-unrealized-since-inception-in',
  POSITION_PROPERTY_FEE_PAYING = 'position-property-fee-paying',
  POSITION_PROPERTY_FEE_PAYING_IS_NOT = 'position-property-fee-paying-is-not',
  POSITION_PROPERTY_FEE_PAYING_IS_NOT_EMPTY = 'position-property-fee-paying-is-not-empty',
  POSITION_PROPERTY_FEE_PAYING_ONE_OF = 'position-property-fee-paying-one-of',
  POSITION_PROPERTY_FEE_PAYING_CONTAINS = 'position-property-fee-paying-contains',
  POSITION_PROPERTY_IS_CONCENTRATED = 'position-property-is-concentrated',
  POSITION_PROPERTY_IS_CONCENTRATED_IS_NOT = 'position-property-is-concentrated-is-not',
  POSITION_PROPERTY_IS_CONCENTRATED_IS_NOT_EMPTY = 'position-property-is-concentrated-is-not-empty',
  POSITION_PROPERTY_IS_CONCENTRATED_ONE_OF = 'position-property-is-concentrated-one-of',
  POSITION_PROPERTY_IS_CONCENTRATED_CONTAINS = 'position-property-is-concentrated-contains',
  POSITION_PROPERTY_IS_RECOMMENDED = 'position-property-is-recommended',
  POSITION_PROPERTY_IS_RECOMMENDED_IS_NOT = 'position-property-is-recommended-is-not',
  POSITION_PROPERTY_IS_RECOMMENDED_IS_NOT_EMPTY = 'position-property-is-recommended-is-not-empty',
  POSITION_PROPERTY_IS_RECOMMENDED_ONE_OF = 'position-property-is-recommended-one-of',
  POSITION_PROPERTY_IS_RECOMMENDED_CONTAINS = 'position-property-is-recommended-contains',
  POSITION_PROPERTY_EXCLUDE_FROM_MANDATE = 'position-property-exclude-from-mandate',
  POSITION_PROPERTY_EXCLUDE_FROM_MANDATE_IS_NOT = 'position-property-exclude-from-mandate-is-not',
  POSITION_PROPERTY_EXCLUDE_FROM_MANDATE_IS_NOT_EMPTY = 'position-property-exclude-from-mandate-is-not-empty',
  POSITION_PROPERTY_EXCLUDE_FROM_MANDATE_ONE_OF = 'position-property-exclude-from-mandate-one-of',
  POSITION_PROPERTY_EXCLUDE_FROM_MANDATE_CONTAINS = 'position-property-exclude-from-mandate-contains',
  POSITION_PROPERTY_EXCLUDE_FROM_ALLOCATION = 'position-property-exclude-from-allocation',
  POSITION_PROPERTY_EXCLUDE_FROM_ALLOCATION_IS_NOT = 'position-property-exclude-from-allocation-is-not',
  POSITION_PROPERTY_EXCLUDE_FROM_ALLOCATION_IS_NOT_EMPTY = 'position-property-exclude-from-allocation-is-not-empty',
  POSITION_PROPERTY_EXCLUDE_FROM_ALLOCATION_ONE_OF = 'position-property-exclude-from-allocation-one-of',
  POSITION_PROPERTY_EXCLUDE_FROM_ALLOCATION_CONTAINS = 'position-property-exclude-from-allocation-contains',
  REPORT_PROPERTY_TITLE_CONTAINS = 'report-property-title-contains',
  REPORT_PROPERTY_TEMPLATE_NAME_CONTAINS = 'report-property-template-name-contains',
  REPORT_PROPERTY_CREATED_CONTAINS = 'report-property-created-contains',
  REPORT_PROPERTY_TITLE_IS = 'report-property-title-is',
  REPORT_PROPERTY_TEMPLATE_NAME_IS = 'report-property-template-name-is',
  REPORT_PROPERTY_CREATED_IS = 'report-property-created-is',
  REPORT_PROPERTY_TITLE_IS_EMPTY = 'report-property-title-is-empty',
  REPORT_PROPERTY_TEMPLATE_NAME_IS_EMPTY = 'report-property-template-name-is-empty',
  REPORT_PROPERTY_CREATED_IS_EMPTY = 'report-property-created-is-empty',
  REPORT_PROPERTY_TITLE_IS_NOT = 'report-property-title-is-not',
  REPORT_PROPERTY_TEMPLATE_NAME_IS_NOT = 'report-property-template-name-is-not',
  REPORT_PROPERTY_CREATED_IS_NOT = 'report-property-created-is-not',
  REPORT_PROPERTY_TITLE_IS_NOT_EMPTY = 'report-property-title-is-not-empty',
  REPORT_PROPERTY_TEMPLATE_NAME_IS_NOT_EMPTY = 'report-property-template-name-is-not-empty',
  REPORT_PROPERTY_CREATED_IS_NOT_EMPTY = 'report-property-created-is-not-empty',
  REPORT_PROPERTY_TITLE_ONE_OF = 'report-property-title-one-of',
  REPORT_PROPERTY_TEMPLATE_NAME_ONE_OF = 'report-property-template-name-one-of',
  REPORT_PROPERTY_CREATED_ONE_OF = 'report-property-created-one-of',
  REPORT_PROPERTY_PREPARED_FOR_IS = 'report-property-prepared-for-is',
  REPORT_PROPERTY_PREPARED_FOR_IS_EMPTY = 'report-property-prepared-for-is-empty',
  REPORT_PROPERTY_PREPARED_FOR_IS_NOT = 'report-property-prepared-for-is-not',
  REPORT_PROPERTY_PREPARED_FOR_IS_NOT_EMPTY = 'report-property-prepared-for-is-not-empty',
  REPORT_PROPERTY_PREPARED_FOR_ONE_OF = 'report-property-prepared-for-one-of',
  SECURITY_PROPERTY_NAME_IS_NOT_EMPTY = 'security-property-name-is-not-empty',
  SECURITY_PROPERTY_NAME_IS = 'security-property-name-is',
  SECURITY_PROPERTY_NAME_IS_EMPTY = 'security-property-name-is-empty',
  SECURITY_PROPERTY_NAME_ONE_OF = 'security-property-name-one-of',
  SECURITY_PROPERTY_NAME_CONTAINS = 'security-property-name-contains',
  SECURITY_PROPERTY_NAME_IS_NOT = 'security-property-name-is-not',
  SECURITY_PROPERTY_EXCHANGE_IS_NOT_EMPTY = 'security-property-exchange-is-not-empty',
  SECURITY_PROPERTY_EXCHANGE_IS = 'security-property-exchange-is',
  SECURITY_PROPERTY_EXCHANGE_IS_EMPTY = 'security-property-exchange-is-empty',
  SECURITY_PROPERTY_EXCHANGE_ONE_OF = 'security-property-exchange-one-of',
  SECURITY_PROPERTY_EXCHANGE_CONTAINS = 'security-property-exchange-contains',
  SECURITY_PROPERTY_EXCHANGE_IS_NOT = 'security-property-exchange-is-not',
  SECURITY_PROPERTY_TICKER_IS_NOT_EMPTY = 'security-property-ticker-is-not-empty',
  SECURITY_PROPERTY_TICKER_IS = 'security-property-ticker-is',
  SECURITY_PROPERTY_TICKER_IS_EMPTY = 'security-property-ticker-is-empty',
  SECURITY_PROPERTY_TICKER_ONE_OF = 'security-property-ticker-one-of',
  SECURITY_PROPERTY_TICKER_CONTAINS = 'security-property-ticker-contains',
  SECURITY_PROPERTY_TICKER_IS_NOT = 'security-property-ticker-is-not',
  SECURITY_PROPERTY_CUSIP_IS_NOT_EMPTY = 'security-property-cusip-is-not-empty',
  SECURITY_PROPERTY_CUSIP_IS = 'security-property-cusip-is',
  SECURITY_PROPERTY_CUSIP_IS_EMPTY = 'security-property-cusip-is-empty',
  SECURITY_PROPERTY_CUSIP_ONE_OF = 'security-property-cusip-one-of',
  SECURITY_PROPERTY_CUSIP_CONTAINS = 'security-property-cusip-contains',
  SECURITY_PROPERTY_CUSIP_IS_NOT = 'security-property-cusip-is-not',
  SECURITY_PROPERTY_CURRENCY_IS_NOT_EMPTY = 'security-property-currency-is-not-empty',
  SECURITY_PROPERTY_CURRENCY_EQUALS = 'security-property-currency-equals',
  SECURITY_PROPERTY_CURRENCY_IS_EMPTY = 'security-property-currency-is-empty',
  SECURITY_PROPERTY_CURRENCY_ONE_OF = 'security-property-currency-one-of',
  SECURITY_PROPERTY_CURRENCY_NOTEQUALS = 'security-property-currency-notequals',
  SECURITY_PROPERTY_ASSET_CLASS_IS_NOT_EMPTY = 'security-property-asset-class-is-not-empty',
  SECURITY_PROPERTY_ASSET_CLASS_EQUALS = 'security-property-asset-class-equals',
  SECURITY_PROPERTY_ASSET_CLASS_IS_EMPTY = 'security-property-asset-class-is-empty',
  SECURITY_PROPERTY_ASSET_CLASS_ONE_OF = 'security-property-asset-class-one-of',
  SECURITY_PROPERTY_ASSET_CLASS_NOTEQUALS = 'security-property-asset-class-notequals',
  SECURITY_PROPERTY_SECTOR_IS_NOT_EMPTY = 'security-property-sector-is-not-empty',
  SECURITY_PROPERTY_SECTOR_IS = 'security-property-sector-is',
  SECURITY_PROPERTY_SECTOR_IS_EMPTY = 'security-property-sector-is-empty',
  SECURITY_PROPERTY_SECTOR_ONE_OF = 'security-property-sector-one-of',
  SECURITY_PROPERTY_SECTOR_IS_NOT = 'security-property-sector-is-not',
  SECURITY_PROPERTY_INSTRUMENT_TYPE_IS = 'security-property-instrument-type-is',
  SECURITY_PROPERTY_INSTRUMENT_TYPE_IS_EMPTY = 'security-property-instrument-type-is-empty',
  SECURITY_PROPERTY_INSTRUMENT_TYPE_IS_NOT = 'security-property-instrument-type-is-not',
  SECURITY_PROPERTY_INSTRUMENT_TYPE_IS_NOT_EMPTY = 'security-property-instrument-type-is-not-empty',
  SECURITY_PROPERTY_INSTRUMENT_TYPE_ONE_OF = 'security-property-instrument-type-one-of',
  SECURITY_PROPERTY_ASSET_SUB_CLASS_IS = 'security-property-asset-sub-class-is',
  SECURITY_PROPERTY_ASSET_SUB_CLASS_IS_EMPTY = 'security-property-asset-sub-class-is-empty',
  SECURITY_PROPERTY_ASSET_SUB_CLASS_IS_NOT = 'security-property-asset-sub-class-is-not',
  SECURITY_PROPERTY_ASSET_SUB_CLASS_IS_NOT_EMPTY = 'security-property-asset-sub-class-is-not-empty',
  SECURITY_PROPERTY_ASSET_SUB_CLASS_ONE_OF = 'security-property-asset-sub-class-one-of',
  SECURITY_PROPERTY_ASSET_CLASS_L3_IS = 'security-property-asset-class-l3-is',
  SECURITY_PROPERTY_ASSET_CLASS_L3_IS_EMPTY = 'security-property-asset-class-l3-is-empty',
  SECURITY_PROPERTY_ASSET_CLASS_L3_IS_NOT = 'security-property-asset-class-l3-is-not',
  SECURITY_PROPERTY_ASSET_CLASS_L3_IS_NOT_EMPTY = 'security-property-asset-class-l3-is-not-empty',
  SECURITY_PROPERTY_ASSET_CLASS_L3_ONE_OF = 'security-property-asset-class-l3-one-of',
  SECURITY_PROPERTY_PRODUCT_TYPE_CONTAINS = 'security-property-product-type-contains',
  SECURITY_PROPERTY_PRODUCT_TYPE_IS = 'security-property-product-type-is',
  SECURITY_PROPERTY_PRODUCT_TYPE_IS_EMPTY = 'security-property-product-type-is-empty',
  SECURITY_PROPERTY_PRODUCT_TYPE_IS_NOT = 'security-property-product-type-is-not',
  SECURITY_PROPERTY_PRODUCT_TYPE_IS_NOT_EMPTY = 'security-property-product-type-is-not-empty',
  SECURITY_PROPERTY_PRODUCT_TYPE_ONE_OF = 'security-property-product-type-one-of',
  SECURITY_PROPERTY_ID_ONE_OF = 'security-property-id-one-of',
  SECURITY_PROPERTY_ID_IS = 'security-property-id-is',
  SECURITY_PROPERTY_ID_IS_NOT = 'security-property-id-is-not',
  SECURITY_PROPERTY_PUBLICPRIVATE_IS = 'security-property-publicprivate-is',
  SECURITY_PROPERTY_STRATEGY_EQUALS = 'security-property-strategy-equals',
  SECURITY_PROPERTY_STRATEGY_NOTEQUALS = 'security-property-strategy-notequals',
  SECURITY_PROPERTY_STRATEGY_IS_EMPTY = 'security-property-strategy-is-empty',
  SECURITY_PROPERTY_STRATEGY_IS_NOT_EMPTY = 'security-property-strategy-is-not-empty',
  SECURITY_PROPERTY_STRATEGY_ONE_OF = 'security-property-strategy-one-of',
  SECURITY_PROPERTY_INDUSTRY_EQUALS = 'security-property-industry-equals',
  SECURITY_PROPERTY_INDUSTRY_NOTEQUALS = 'security-property-industry-notequals',
  SECURITY_PROPERTY_INDUSTRY_IS_EMPTY = 'security-property-industry-is-empty',
  SECURITY_PROPERTY_INDUSTRY_IS_NOT_EMPTY = 'security-property-industry-is-not-empty',
  SECURITY_PROPERTY_INDUSTRY_ONE_OF = 'security-property-industry-one-of',
  SECURITY_PROPERTY_REGION_EQUALS = 'security-property-region-equals',
  SECURITY_PROPERTY_REGION_NOTEQUALS = 'security-property-region-notequals',
  SECURITY_PROPERTY_REGION_IS_EMPTY = 'security-property-region-is-empty',
  SECURITY_PROPERTY_REGION_IS_NOT_EMPTY = 'security-property-region-is-not-empty',
  SECURITY_PROPERTY_REGION_ONE_OF = 'security-property-region-one-of',
  SECURITY_PROPERTY_COUNTRY_CONTAINS = 'security-property-country-contains',
  SECURITY_PROPERTY_COUNTRY_IS = 'security-property-country-is',
  SECURITY_PROPERTY_COUNTRY_IS_EMPTY = 'security-property-country-is-empty',
  SECURITY_PROPERTY_COUNTRY_IS_NOT = 'security-property-country-is-not',
  SECURITY_PROPERTY_COUNTRY_IS_NOT_EMPTY = 'security-property-country-is-not-empty',
  SECURITY_PROPERTY_COUNTRY_ONE_OF = 'security-property-country-one-of',
  SECURITY_PROPERTY_GEOGRAPHY_CONTAINS = 'security-property-geography-contains',
  SECURITY_PROPERTY_GEOGRAPHY_IS = 'security-property-geography-is',
  SECURITY_PROPERTY_GEOGRAPHY_IS_EMPTY = 'security-property-geography-is-empty',
  SECURITY_PROPERTY_GEOGRAPHY_IS_NOT = 'security-property-geography-is-not',
  SECURITY_PROPERTY_GEOGRAPHY_IS_NOT_EMPTY = 'security-property-geography-is-not-empty',
  SECURITY_PROPERTY_GEOGRAPHY_ONE_OF = 'security-property-geography-one-of',
  SECURITY_PROPERTY_RISK_SCORE_LTE = 'security-property-risk-score-lte',
  SECURITY_PROPERTY_RISK_SCORE_EQUALS = 'security-property-risk-score-equals',
  SECURITY_PROPERTY_RISK_SCORE_NOTEQUALS = 'security-property-risk-score-notequals',
  SECURITY_PROPERTY_RISK_SCORE_GTE = 'security-property-risk-score-gte',
  SECURITY_PROPERTY_RISK_SCORE_IN = 'security-property-risk-score-in',
  SECURITY_PROPERTY_RISK_SCORE_BETWEEN = 'security-property-risk-score-between',
  SECURITY_PROPERTY_LEGAL_STRUCTURE_CONTAINS = 'security-property-legal-structure-contains',
  SECURITY_PROPERTY_LEGAL_STRUCTURE_IS = 'security-property-legal-structure-is',
  SECURITY_PROPERTY_LEGAL_STRUCTURE_IS_EMPTY = 'security-property-legal-structure-is-empty',
  SECURITY_PROPERTY_LEGAL_STRUCTURE_IS_NOT = 'security-property-legal-structure-is-not',
  SECURITY_PROPERTY_LEGAL_STRUCTURE_IS_NOT_EMPTY = 'security-property-legal-structure-is-not-empty',
  SECURITY_PROPERTY_LEGAL_STRUCTURE_ONE_OF = 'security-property-legal-structure-one-of',
  SECURITY_PROPERTY_LIQUIDITY_SCORE_EQUALS = 'security-property-liquidity-score-equals',
  SECURITY_PROPERTY_LIQUIDITY_SCORE_IS_EMPTY = 'security-property-liquidity-score-is-empty',
  SECURITY_PROPERTY_LIQUIDITY_SCORE_NOTEQUALS = 'security-property-liquidity-score-notequals',
  SECURITY_PROPERTY_LIQUIDITY_SCORE_IS_NOT_EMPTY = 'security-property-liquidity-score-is-not-empty',
  SECURITY_PROPERTY_LIQUIDITY_SCORE_ONE_OF = 'security-property-liquidity-score-one-of',
  SECURITY_PROPERTY_ISSUER_IS_NOT_EMPTY = 'security-property-issuer-is-not-empty',
  SECURITY_PROPERTY_ISSUER_IS = 'security-property-issuer-is',
  SECURITY_PROPERTY_ISSUER_IS_EMPTY = 'security-property-issuer-is-empty',
  SECURITY_PROPERTY_ISSUER_ONE_OF = 'security-property-issuer-one-of',
  SECURITY_PROPERTY_ISSUER_CONTAINS = 'security-property-issuer-contains',
  SECURITY_PROPERTY_ISSUER_IS_NOT = 'security-property-issuer-is-not',
  SECURITY_PROPERTY_SUB_STRATEGY_EQUALS = 'security-property-sub-strategy-equals',
  SECURITY_PROPERTY_SUB_STRATEGY_NOTEQUALS = 'security-property-sub-strategy-notequals',
  SECURITY_PROPERTY_SUB_STRATEGY_IS_EMPTY = 'security-property-sub-strategy-is-empty',
  SECURITY_PROPERTY_SUB_STRATEGY_IS_NOT_EMPTY = 'security-property-sub-strategy-is-not-empty',
  SECURITY_PROPERTY_SUB_STRATEGY_ONE_OF = 'security-property-sub-strategy-one-of',
  SECURITY_PROPERTY_FOREIGN_FOR_COUNTRIES_LIST_IS = 'security-property-foreign-for-countries-list-is',
  SECURITY_PROPERTY_FOREIGN_FOR_COUNTRIES_LIST_IS_EMPTY = 'security-property-foreign-for-countries-list-is-empty',
  SECURITY_PROPERTY_FOREIGN_FOR_COUNTRIES_LIST_IS_NOT = 'security-property-foreign-for-countries-list-is-not',
  SECURITY_PROPERTY_FOREIGN_FOR_COUNTRIES_LIST_IS_NOT_EMPTY = 'security-property-foreign-for-countries-list-is-not-empty',
  SECURITY_PROPERTY_FOREIGN_FOR_COUNTRIES_LIST_ONE_OF = 'security-property-foreign-for-countries-list-one-of',
  SECURITY_PROPERTY_TRADING_TYPE_CONTAINS = 'security-property-trading-type-contains',
  SECURITY_PROPERTY_TRADING_TYPE_IS = 'security-property-trading-type-is',
  SECURITY_PROPERTY_TRADING_TYPE_IS_EMPTY = 'security-property-trading-type-is-empty',
  SECURITY_PROPERTY_TRADING_TYPE_IS_NOT = 'security-property-trading-type-is-not',
  SECURITY_PROPERTY_TRADING_TYPE_IS_NOT_EMPTY = 'security-property-trading-type-is-not-empty',
  SECURITY_PROPERTY_TRADING_TYPE_ONE_OF = 'security-property-trading-type-one-of',
  SECURITY_PROPERTY_FUND_TYPE_IS_NOT_EMPTY = 'security-property-fund-type-is-not-empty',
  SECURITY_PROPERTY_FUND_TYPE_IS = 'security-property-fund-type-is',
  SECURITY_PROPERTY_FUND_TYPE_IS_EMPTY = 'security-property-fund-type-is-empty',
  SECURITY_PROPERTY_FUND_TYPE_ONE_OF = 'security-property-fund-type-one-of',
  SECURITY_PROPERTY_FUND_TYPE_CONTAINS = 'security-property-fund-type-contains',
  SECURITY_PROPERTY_FUND_TYPE_IS_NOT = 'security-property-fund-type-is-not',
  SECURITY_PROPERTY_FUND_MANAGER_IS_NOT_EMPTY = 'security-property-fund-manager-is-not-empty',
  SECURITY_PROPERTY_FUND_MANAGER_IS = 'security-property-fund-manager-is',
  SECURITY_PROPERTY_FUND_MANAGER_IS_EMPTY = 'security-property-fund-manager-is-empty',
  SECURITY_PROPERTY_FUND_MANAGER_ONE_OF = 'security-property-fund-manager-one-of',
  SECURITY_PROPERTY_FUND_MANAGER_CONTAINS = 'security-property-fund-manager-contains',
  SECURITY_PROPERTY_FUND_MANAGER_IS_NOT = 'security-property-fund-manager-is-not',
  SECURITY_PROPERTY_FUND_MANDATE_IS_NOT_EMPTY = 'security-property-fund-mandate-is-not-empty',
  SECURITY_PROPERTY_FUND_MANDATE_IS = 'security-property-fund-mandate-is',
  SECURITY_PROPERTY_FUND_MANDATE_IS_EMPTY = 'security-property-fund-mandate-is-empty',
  SECURITY_PROPERTY_FUND_MANDATE_ONE_OF = 'security-property-fund-mandate-one-of',
  SECURITY_PROPERTY_FUND_MANDATE_CONTAINS = 'security-property-fund-mandate-contains',
  SECURITY_PROPERTY_FUND_MANDATE_IS_NOT = 'security-property-fund-mandate-is-not',
  SECURITY_PROPERTY_FUND_VEHICLE_IS_NOT_EMPTY = 'security-property-fund-vehicle-is-not-empty',
  SECURITY_PROPERTY_FUND_VEHICLE_IS = 'security-property-fund-vehicle-is',
  SECURITY_PROPERTY_FUND_VEHICLE_IS_EMPTY = 'security-property-fund-vehicle-is-empty',
  SECURITY_PROPERTY_FUND_VEHICLE_ONE_OF = 'security-property-fund-vehicle-one-of',
  SECURITY_PROPERTY_FUND_VEHICLE_CONTAINS = 'security-property-fund-vehicle-contains',
  SECURITY_PROPERTY_FUND_VEHICLE_IS_NOT = 'security-property-fund-vehicle-is-not',
  SECURITY_PROPERTY_FUND_STRUCTURE_IS_NOT_EMPTY = 'security-property-fund-structure-is-not-empty',
  SECURITY_PROPERTY_FUND_STRUCTURE_IS = 'security-property-fund-structure-is',
  SECURITY_PROPERTY_FUND_STRUCTURE_IS_EMPTY = 'security-property-fund-structure-is-empty',
  SECURITY_PROPERTY_FUND_STRUCTURE_ONE_OF = 'security-property-fund-structure-one-of',
  SECURITY_PROPERTY_FUND_STRUCTURE_CONTAINS = 'security-property-fund-structure-contains',
  SECURITY_PROPERTY_FUND_STRUCTURE_IS_NOT = 'security-property-fund-structure-is-not',
  SECURITY_PROPERTY_RISK_RETURN_IS_NOT_EMPTY = 'security-property-risk-return-is-not-empty',
  SECURITY_PROPERTY_RISK_RETURN_IS = 'security-property-risk-return-is',
  SECURITY_PROPERTY_RISK_RETURN_IS_EMPTY = 'security-property-risk-return-is-empty',
  SECURITY_PROPERTY_RISK_RETURN_ONE_OF = 'security-property-risk-return-one-of',
  SECURITY_PROPERTY_RISK_RETURN_CONTAINS = 'security-property-risk-return-contains',
  SECURITY_PROPERTY_RISK_RETURN_IS_NOT = 'security-property-risk-return-is-not',
  SECURITY_PROPERTY_USER_DEFINED_1_IS_NOT_EMPTY = 'security-property-user-defined-1-is-not-empty',
  SECURITY_PROPERTY_USER_DEFINED_1_IS = 'security-property-user-defined-1-is',
  SECURITY_PROPERTY_USER_DEFINED_1_IS_EMPTY = 'security-property-user-defined-1-is-empty',
  SECURITY_PROPERTY_USER_DEFINED_1_ONE_OF = 'security-property-user-defined-1-one-of',
  SECURITY_PROPERTY_USER_DEFINED_1_CONTAINS = 'security-property-user-defined-1-contains',
  SECURITY_PROPERTY_USER_DEFINED_1_IS_NOT = 'security-property-user-defined-1-is-not',
  SECURITY_PROPERTY_USER_DEFINED_2_IS_NOT_EMPTY = 'security-property-user-defined-2-is-not-empty',
  SECURITY_PROPERTY_USER_DEFINED_2_IS = 'security-property-user-defined-2-is',
  SECURITY_PROPERTY_USER_DEFINED_2_IS_EMPTY = 'security-property-user-defined-2-is-empty',
  SECURITY_PROPERTY_USER_DEFINED_2_ONE_OF = 'security-property-user-defined-2-one-of',
  SECURITY_PROPERTY_USER_DEFINED_2_CONTAINS = 'security-property-user-defined-2-contains',
  SECURITY_PROPERTY_USER_DEFINED_2_IS_NOT = 'security-property-user-defined-2-is-not',
  SECURITY_PROPERTY_USER_DEFINED_3_IS_NOT_EMPTY = 'security-property-user-defined-3-is-not-empty',
  SECURITY_PROPERTY_USER_DEFINED_3_IS = 'security-property-user-defined-3-is',
  SECURITY_PROPERTY_USER_DEFINED_3_IS_EMPTY = 'security-property-user-defined-3-is-empty',
  SECURITY_PROPERTY_USER_DEFINED_3_ONE_OF = 'security-property-user-defined-3-one-of',
  SECURITY_PROPERTY_USER_DEFINED_3_CONTAINS = 'security-property-user-defined-3-contains',
  SECURITY_PROPERTY_USER_DEFINED_3_IS_NOT = 'security-property-user-defined-3-is-not',
  SECURITY_PROPERTY_USER_DEFINED_4_IS_NOT_EMPTY = 'security-property-user-defined-4-is-not-empty',
  SECURITY_PROPERTY_USER_DEFINED_4_IS = 'security-property-user-defined-4-is',
  SECURITY_PROPERTY_USER_DEFINED_4_IS_EMPTY = 'security-property-user-defined-4-is-empty',
  SECURITY_PROPERTY_USER_DEFINED_4_ONE_OF = 'security-property-user-defined-4-one-of',
  SECURITY_PROPERTY_USER_DEFINED_4_CONTAINS = 'security-property-user-defined-4-contains',
  SECURITY_PROPERTY_USER_DEFINED_4_IS_NOT = 'security-property-user-defined-4-is-not',
  SECURITY_PROPERTY_USER_DEFINED_5_IS_NOT_EMPTY = 'security-property-user-defined-5-is-not-empty',
  SECURITY_PROPERTY_USER_DEFINED_5_IS = 'security-property-user-defined-5-is',
  SECURITY_PROPERTY_USER_DEFINED_5_IS_EMPTY = 'security-property-user-defined-5-is-empty',
  SECURITY_PROPERTY_USER_DEFINED_45ONE_OF = 'security-property-user-defined-45one-of',
  SECURITY_PROPERTY_USER_DEFINED_5_CONTAINS = 'security-property-user-defined-5-contains',
  SECURITY_PROPERTY_USER_DEFINED_5_IS_NOT = 'security-property-user-defined-5-is-not',
  SECURITY_PROPERTY_MATURITY_YEAR_LTE = 'security-property-maturity-year-lte',
  SECURITY_PROPERTY_MATURITY_YEAR_EQUALS = 'security-property-maturity-year-equals',
  SECURITY_PROPERTY_MATURITY_YEAR_NOTEQUALS = 'security-property-maturity-year-notequals',
  SECURITY_PROPERTY_MATURITY_YEAR_GTE = 'security-property-maturity-year-gte',
  SECURITY_PROPERTY_MATURITY_YEAR_IN = 'security-property-maturity-year-in',
  SECURITY_PROPERTY_MATURITY_YEAR_BETWEEN = 'security-property-maturity-year-between',
  SECURITY_PROPERTY_TRADING_CURRENCY_IS_NOT_EMPTY = 'security-property-trading-currency-is-not-empty',
  SECURITY_PROPERTY_TRADING_CURRENCY_EQUALS = 'security-property-trading-currency-equals',
  SECURITY_PROPERTY_TRADING_CURRENCY_IS_EMPTY = 'security-property-trading-currency-is-empty',
  SECURITY_PROPERTY_TRADING_CURRENCY_ONE_OF = 'security-property-trading-currency-one-of',
  SECURITY_PROPERTY_TRADING_CURRENCY_NOTEQUALS = 'security-property-trading-currency-notequals',
  SECURITY_PROPERTY_PRIMARY_MARKET_CONTAINS = 'security-property-primary-market-contains',
  SECURITY_PROPERTY_PRIMARY_MARKET_IS_NOT_EMPTY = 'security-property-primary-market-is-not-empty',
  SECURITY_PROPERTY_PRIMARY_MARKET_EQUALS = 'security-property-primary-market-equals',
  SECURITY_PROPERTY_PRIMARY_MARKET_IS_EMPTY = 'security-property-primary-market-is-empty',
  SECURITY_PROPERTY_PRIMARY_MARKET_ONE_OF = 'security-property-primary-market-one-of',
  SECURITY_PROPERTY_PRIMARY_MARKET_NOTEQUALS = 'security-property-primary-market-notequals',
  SECURITY_PROPERTY_SECONDARY_MARKET_IS_NOT_EMPTY = 'security-property-secondary-market-is-not-empty',
  SECURITY_PROPERTY_SECONDARY_MARKET_EQUALS = 'security-property-secondary-market-equals',
  SECURITY_PROPERTY_SECONDARY_MARKET_IS_EMPTY = 'security-property-secondary-market-is-empty',
  SECURITY_PROPERTY_SECONDARY_MARKET_ONE_OF = 'security-property-secondary-market-one-of',
  SECURITY_PROPERTY_SECONDARY_MARKET_NOTEQUALS = 'security-property-secondary-market-notequals',
  SECURITY_PROPERTY_SUB_STRATEGY_CONTAINS = 'security-property-sub-strategy-contains',
  SECURITY_PROPERTY_SUB_STRATEGY_IS = 'security-property-sub-strategy-is',
  SECURITY_PROPERTY_SUB_STRATEGY_IS_EMPTY3 = 'security-property-sub-strategy-is-empty3',
  SECURITY_PROPERTY_SUB_STRATEGY_IS_NOT = 'security-property-sub-strategy-is-not',
  SECURITY_PROPERTY_SUB_STRATEGY_IS_NOT_EMPTY3 = 'security-property-sub-strategy-is-not-empty3',
  SECURITY_PROPERTY_SUB_STRATEGY_ONE_OF3 = 'security-property-sub-strategy-one-of3',
  SECURITY_PROPERTY_ASSET_CATEGORY_CONTAINS = 'security-property-asset-category-contains',
  SECURITY_PROPERTY_ASSET_CATEGORY_IS = 'security-property-asset-category-is',
  SECURITY_PROPERTY_ASSET_CATEGORY_IS_EMPTY = 'security-property-asset-category-is-empty',
  SECURITY_PROPERTY_ASSET_CATEGORY_IS_NOT = 'security-property-asset-category-is-not',
  SECURITY_PROPERTY_ASSET_CATEGORY_IS_NOT_EMPTY = 'security-property-asset-category-is-not-empty',
  SECURITY_PROPERTY_ASSET_CATEGORY_ONE_OF = 'security-property-asset-category-one-of',
  SECURITY_PROPERTY_ASSET_SUBCATEGORY_CONTAINS = 'security-property-asset-subcategory-contains',
  SECURITY_PROPERTY_ASSET_SUBCATEGORY_IS = 'security-property-asset-subcategory-is',
  SECURITY_PROPERTY_ASSET_SUBCATEGORY_IS_EMPTY = 'security-property-asset-subcategory-is-empty',
  SECURITY_PROPERTY_ASSET_SUBCATEGORY_IS_NOT = 'security-property-asset-subcategory-is-not',
  SECURITY_PROPERTY_ASSET_SUBCATEGORY_IS_NOT_EMPTY = 'security-property-asset-subcategory-is-not-empty',
  SECURITY_PROPERTY_ASSET_SUBCATEGORY_ONE_OF = 'security-property-asset-subcategory-one-of',
  SECURITY_PROPERTY_DEFAULT_COMMISSION_LTE = 'security-property-default-commission-lte',
  SECURITY_PROPERTY_DEFAULT_COMMISSION_GTE = 'security-property-default-commission-gte',
  SECURITY_PROPERTY_DEFAULT_COMMISSION_EQUALS = 'security-property-default-commission-equals',
  SECURITY_PROPERTY_DEFAULT_COMMISSION_BETWEEN = 'security-property-default-commission-between',
  SECURITY_PROPERTY_DEFAULT_COMMISSION_TYPE_EQUALS = 'security-property-default-commission-type-equals',
  SECURITY_PROPERTY_DEFAULT_COMMISSION_TYPE_ONE_OF = 'security-property-default-commission-type-one-of',
  SLEEVE_PROPERTY_SLEEVE_ID_IS_NOT_EMPTY = 'sleeve-property-sleeve-id-is-not-empty',
  SLEEVE_PROPERTY_SLEEVE_ID_IS = 'sleeve-property-sleeve-id-is',
  SLEEVE_PROPERTY_SLEEVE_ID_IS_EMPTY = 'sleeve-property-sleeve-id-is-empty',
  SLEEVE_PROPERTY_SLEEVE_ID_ONE_OF = 'sleeve-property-sleeve-id-one-of',
  SLEEVE_PROPERTY_SLEEVE_ID_CONTAINS = 'sleeve-property-sleeve-id-contains',
  SLEEVE_PROPERTY_SLEEVE_ID_IS_NOT = 'sleeve-property-sleeve-id-is-not',
  SLEEVE_PROPERTY_STRATEGY_IS_NOT_EMPTY = 'sleeve-property-strategy-is-not-empty',
  SLEEVE_PROPERTY_STRATEGY_IS = 'sleeve-property-strategy-is',
  SLEEVE_PROPERTY_STRATEGY_IS_EMPTY = 'sleeve-property-strategy-is-empty',
  SLEEVE_PROPERTY_STRATEGY_ONE_OF = 'sleeve-property-strategy-one-of',
  SLEEVE_PROPERTY_STRATEGY_CONTAINS = 'sleeve-property-strategy-contains',
  SLEEVE_PROPERTY_STRATEGY_IS_NOT = 'sleeve-property-strategy-is-not',
  SLEEVE_METRIC_ASSETS_UNDER_MANAGEMENT_BETWEEN = 'sleeve-metric-assets-under-management-between',
  SLEEVE_METRIC_ASSETS_UNDER_MANAGEMENT_GTE = 'sleeve-metric-assets-under-management-gte',
  SLEEVE_METRIC_ASSETS_UNDER_MANAGEMENT_NOTEQUALS = 'sleeve-metric-assets-under-management-notequals',
  SLEEVE_METRIC_ASSETS_UNDER_MANAGEMENT_LTE = 'sleeve-metric-assets-under-management-lte',
  SLEEVE_METRIC_ASSETS_UNDER_MANAGEMENT_EQUALS = 'sleeve-metric-assets-under-management-equals',
  SLEEVE_METRIC_ASSETS_UNDER_MANAGEMENT_IN = 'sleeve-metric-assets-under-management-in',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_MONTH_BETWEEN = 'sleeve-metric-gainloss-realized-last-month-between',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_MONTH_GTE = 'sleeve-metric-gainloss-realized-last-month-gte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_MONTH_NOTEQUALS = 'sleeve-metric-gainloss-realized-last-month-notequals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_MONTH_LTE = 'sleeve-metric-gainloss-realized-last-month-lte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_MONTH_EQUALS = 'sleeve-metric-gainloss-realized-last-month-equals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_MONTH_IN = 'sleeve-metric-gainloss-realized-last-month-in',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_BETWEEN = 'sleeve-metric-gainloss-realized-last-quarter-between',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_GTE = 'sleeve-metric-gainloss-realized-last-quarter-gte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_NOTEQUALS = 'sleeve-metric-gainloss-realized-last-quarter-notequals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_LTE = 'sleeve-metric-gainloss-realized-last-quarter-lte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_EQUALS = 'sleeve-metric-gainloss-realized-last-quarter-equals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_QUARTER_IN = 'sleeve-metric-gainloss-realized-last-quarter-in',
  SLEEVE_METRIC_GAINLOSS_REALIZED_YTD_BETWEEN = 'sleeve-metric-gainloss-realized-ytd-between',
  SLEEVE_METRIC_GAINLOSS_REALIZED_YTD_GTE = 'sleeve-metric-gainloss-realized-ytd-gte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_YTD_NOTEQUALS = 'sleeve-metric-gainloss-realized-ytd-notequals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_YTD_LTE = 'sleeve-metric-gainloss-realized-ytd-lte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_YTD_EQUALS = 'sleeve-metric-gainloss-realized-ytd-equals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_YTD_IN = 'sleeve-metric-gainloss-realized-ytd-in',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_YEAR_BETWEEN = 'sleeve-metric-gainloss-realized-last-year-between',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_YEAR_GTE = 'sleeve-metric-gainloss-realized-last-year-gte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_YEAR_NOTEQUALS = 'sleeve-metric-gainloss-realized-last-year-notequals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_YEAR_LTE = 'sleeve-metric-gainloss-realized-last-year-lte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_YEAR_EQUALS = 'sleeve-metric-gainloss-realized-last-year-equals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_LAST_YEAR_IN = 'sleeve-metric-gainloss-realized-last-year-in',
  SLEEVE_METRIC_GAINLOSS_REALIZED_3_YEARS_BETWEEN = 'sleeve-metric-gainloss-realized-3-years-between',
  SLEEVE_METRIC_GAINLOSS_REALIZED_3_YEARS_GTE = 'sleeve-metric-gainloss-realized-3-years-gte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_3_YEARS_NOTEQUALS = 'sleeve-metric-gainloss-realized-3-years-notequals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_3_YEARS_LTE = 'sleeve-metric-gainloss-realized-3-years-lte',
  SLEEVE_METRIC_GAINLOSS_REALIZED_3_YEARS_EQUALS = 'sleeve-metric-gainloss-realized-3-years-equals',
  SLEEVE_METRIC_GAINLOSS_REALIZED_3_YEARS_IN = 'sleeve-metric-gainloss-realized-3-years-in',
  SLEEVE_METRIC_RETURN_NET_LAST_MONTH_BETWEEN = 'sleeve-metric-return-net-last-month-between',
  SLEEVE_METRIC_RETURN_NET_LAST_MONTH_GTE = 'sleeve-metric-return-net-last-month-gte',
  SLEEVE_METRIC_RETURN_NET_LAST_MONTH_NOTEQUALS = 'sleeve-metric-return-net-last-month-notequals',
  SLEEVE_METRIC_RETURN_NET_LAST_MONTH_LTE = 'sleeve-metric-return-net-last-month-lte',
  SLEEVE_METRIC_RETURN_NET_LAST_MONTH_EQUALS = 'sleeve-metric-return-net-last-month-equals',
  SLEEVE_METRIC_RETURN_NET_LAST_MONTH_IN = 'sleeve-metric-return-net-last-month-in',
  SLEEVE_METRIC_RETURN_NET_LAST_QUARTER_BETWEEN = 'sleeve-metric-return-net-last-quarter-between',
  SLEEVE_METRIC_RETURN_NET_LAST_QUARTER_GTE = 'sleeve-metric-return-net-last-quarter-gte',
  SLEEVE_METRIC_RETURN_NET_LAST_QUARTER_NOTEQUALS = 'sleeve-metric-return-net-last-quarter-notequals',
  SLEEVE_METRIC_RETURN_NET_LAST_QUARTER_LTE = 'sleeve-metric-return-net-last-quarter-lte',
  SLEEVE_METRIC_RETURN_NET_LAST_QUARTER_EQUALS = 'sleeve-metric-return-net-last-quarter-equals',
  SLEEVE_METRIC_RETURN_NET_LAST_QUARTER_IN = 'sleeve-metric-return-net-last-quarter-in',
  SLEEVE_METRIC_RETURN_NET_YTD_BETWEEN = 'sleeve-metric-return-net-ytd-between',
  SLEEVE_METRIC_RETURN_NET_YTD_GTE = 'sleeve-metric-return-net-ytd-gte',
  SLEEVE_METRIC_RETURN_NET_YTD_NOTEQUALS = 'sleeve-metric-return-net-ytd-notequals',
  SLEEVE_METRIC_RETURN_NET_YTD_LTE = 'sleeve-metric-return-net-ytd-lte',
  SLEEVE_METRIC_RETURN_NET_YTD_EQUALS = 'sleeve-metric-return-net-ytd-equals',
  SLEEVE_METRIC_RETURN_NET_YTD_IN = 'sleeve-metric-return-net-ytd-in',
  SLEEVE_METRIC_RETURN_NET_LAST_YEAR_BETWEEN = 'sleeve-metric-return-net-last-year-between',
  SLEEVE_METRIC_RETURN_NET_LAST_YEAR_GTE = 'sleeve-metric-return-net-last-year-gte',
  SLEEVE_METRIC_RETURN_NET_LAST_YEAR_NOTEQUALS = 'sleeve-metric-return-net-last-year-notequals',
  SLEEVE_METRIC_RETURN_NET_LAST_YEAR_LTE = 'sleeve-metric-return-net-last-year-lte',
  SLEEVE_METRIC_RETURN_NET_LAST_YEAR_EQUALS = 'sleeve-metric-return-net-last-year-equals',
  SLEEVE_METRIC_RETURN_NET_LAST_YEAR_IN = 'sleeve-metric-return-net-last-year-in',
  SLEEVE_METRIC_RETURN_NET_3_YEARS_BETWEEN = 'sleeve-metric-return-net-3-years-between',
  SLEEVE_METRIC_RETURN_NET_3_YEARS_GTE = 'sleeve-metric-return-net-3-years-gte',
  SLEEVE_METRIC_RETURN_NET_3_YEARS_NOTEQUALS = 'sleeve-metric-return-net-3-years-notequals',
  SLEEVE_METRIC_RETURN_NET_3_YEARS_LTE = 'sleeve-metric-return-net-3-years-lte',
  SLEEVE_METRIC_RETURN_NET_3_YEARS_EQUALS = 'sleeve-metric-return-net-3-years-equals',
  SLEEVE_METRIC_RETURN_NET_3_YEARS_IN = 'sleeve-metric-return-net-3-years-in',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_BETWEEN = 'sleeve-metric-net-depositwithdrawal-last-month-between',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_GTE = 'sleeve-metric-net-depositwithdrawal-last-month-gte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_NOTEQUALS = 'sleeve-metric-net-depositwithdrawal-last-month-notequals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_LTE = 'sleeve-metric-net-depositwithdrawal-last-month-lte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_EQUALS = 'sleeve-metric-net-depositwithdrawal-last-month-equals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_MONTH_IN = 'sleeve-metric-net-depositwithdrawal-last-month-in',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_BETWEEN = 'sleeve-metric-net-depositwithdrawal-last-quarter-between',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_GTE = 'sleeve-metric-net-depositwithdrawal-last-quarter-gte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_NOTEQUALS = 'sleeve-metric-net-depositwithdrawal-last-quarter-notequals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_LTE = 'sleeve-metric-net-depositwithdrawal-last-quarter-lte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_EQUALS = 'sleeve-metric-net-depositwithdrawal-last-quarter-equals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_QUARTER_IN = 'sleeve-metric-net-depositwithdrawal-last-quarter-in',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_YTD_BETWEEN = 'sleeve-metric-net-depositwithdrawal-ytd-between',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_YTD_GTE = 'sleeve-metric-net-depositwithdrawal-ytd-gte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_YTD_NOTEQUALS = 'sleeve-metric-net-depositwithdrawal-ytd-notequals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_YTD_LTE = 'sleeve-metric-net-depositwithdrawal-ytd-lte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_YTD_EQUALS = 'sleeve-metric-net-depositwithdrawal-ytd-equals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_YTD_IN = 'sleeve-metric-net-depositwithdrawal-ytd-in',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_BETWEEN = 'sleeve-metric-net-depositwithdrawal-last-year-between',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_GTE = 'sleeve-metric-net-depositwithdrawal-last-year-gte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_NOTEQUALS = 'sleeve-metric-net-depositwithdrawal-last-year-notequals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_LTE = 'sleeve-metric-net-depositwithdrawal-last-year-lte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_EQUALS = 'sleeve-metric-net-depositwithdrawal-last-year-equals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_LAST_YEAR_IN = 'sleeve-metric-net-depositwithdrawal-last-year-in',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_BETWEEN = 'sleeve-metric-net-depositwithdrawal-3-years-between',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_GTE = 'sleeve-metric-net-depositwithdrawal-3-years-gte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_NOTEQUALS = 'sleeve-metric-net-depositwithdrawal-3-years-notequals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_LTE = 'sleeve-metric-net-depositwithdrawal-3-years-lte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_EQUALS = 'sleeve-metric-net-depositwithdrawal-3-years-equals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_3_YEARS_IN = 'sleeve-metric-net-depositwithdrawal-3-years-in',
  SLEEVE_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_LTE = 'sleeve-metric-gainloss-unrealized-since-inception-lte',
  SLEEVE_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_EQUALS = 'sleeve-metric-gainloss-unrealized-since-inception-equals',
  SLEEVE_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_NOTEQUALS = 'sleeve-metric-gainloss-unrealized-since-inception-notequals',
  SLEEVE_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_GTE = 'sleeve-metric-gainloss-unrealized-since-inception-gte',
  SLEEVE_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_IN = 'sleeve-metric-gainloss-unrealized-since-inception-in',
  SLEEVE_METRIC_GAINLOSS_UNREALIZED_SINCE_INCEPTION_BETWEEN = 'sleeve-metric-gainloss-unrealized-since-inception-between',
  SLEEVE_METRIC_RETURN_NET_SINCE_INCEPTION_LTE = 'sleeve-metric-return-net-since-inception-lte',
  SLEEVE_METRIC_RETURN_NET_SINCE_INCEPTION_EQUALS = 'sleeve-metric-return-net-since-inception-equals',
  SLEEVE_METRIC_RETURN_NET_SINCE_INCEPTION_NOTEQUALS = 'sleeve-metric-return-net-since-inception-notequals',
  SLEEVE_METRIC_RETURN_NET_SINCE_INCEPTION_GTE = 'sleeve-metric-return-net-since-inception-gte',
  SLEEVE_METRIC_RETURN_NET_SINCE_INCEPTION_IN = 'sleeve-metric-return-net-since-inception-in',
  SLEEVE_METRIC_RETURN_NET_SINCE_INCEPTION_BETWEEN = 'sleeve-metric-return-net-since-inception-between',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_LTE = 'sleeve-metric-net-depositwithdrawal-since-inception-lte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_EQUALS = 'sleeve-metric-net-depositwithdrawal-since-inception-equals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_NOTEQUALS = 'sleeve-metric-net-depositwithdrawal-since-inception-notequals',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_GTE = 'sleeve-metric-net-depositwithdrawal-since-inception-gte',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_IN = 'sleeve-metric-net-depositwithdrawal-since-inception-in',
  SLEEVE_METRIC_NET_DEPOSITWITHDRAWAL_SINCE_INCEPTION_BETWEEN = 'sleeve-metric-net-depositwithdrawal-since-inception-between',
  T_PROPERTY_TICKER_IS = 't-property-ticker-is',
  T_PROPERTY_SYMBOL_IS_NOT = 't-property-symbol-is-not',
  T_PROPERTY_SYMBOL_IS_NOT_EMPTY = 't-property-symbol-is-not-empty',
  T_PROPERTY_SYMBOL_IS_EMPTY = 't-property-symbol-is-empty',
  T_PROPERTY_SYMBOL_CONTAINS = 't-property-symbol-contains',
  T_PROPERTY_SYMBOL_ONE_OF = 't-property-symbol-one-of',
  T_PROPERTY_SECURITY_NAME_IS_NOT = 't-property-security-name-is-not',
  T_PROPERTY_SECURITY_NAME_IS_NOT_EMPTY = 't-property-security-name-is-not-empty',
  T_PROPERTY_SECURITY_NAME_IS_EMPTY = 't-property-security-name-is-empty',
  T_PROPERTY_SECURITY_NAME_IS = 't-property-security-name-is',
  T_PROPERTY_SECURITY_NAME_CONTAINS = 't-property-security-name-contains',
  T_PROPERTY_SECURITY_NAME_ONE_OF = 't-property-security-name-one-of',
  T_PROPERTY_ORDER_STATUS_IS_NOT = 't-property-order-status-is-not',
  T_PROPERTY_ORDER_STATUS_IS_NOT_EMPTY = 't-property-order-status-is-not-empty',
  T_PROPERTY_ORDER_STATUS_IS_EMPTY = 't-property-order-status-is-empty',
  T_PROPERTY_ORDER_STATUS_IS = 't-property-order-status-is',
  T_PROPERTY_ORDER_STATUS_ONE_OF = 't-property-order-status-one-of',
  T_PROPERTY_ORDER_FILL_STATUS_IS_NOT = 't-property-order-fill-status-is-not',
  T_PROPERTY_ORDER_FILL_STATUS_IS_NOT_EMPTY = 't-property-order-fill-status-is-not-empty',
  T_PROPERTY_ORDER_FILL_STATUS_IS_EMPTY = 't-property-order-fill-status-is-empty',
  T_PROPERTY_ORDER_FILL_STATUS_IS = 't-property-order-fill-status-is',
  T_PROPERTY_ORDER_FILL_STATUS_ONE_OF = 't-property-order-fill-status-one-of',
  T_PROPERTY_ACCOUNT_ID_IS_NOT = 't-property-account-id-is-not',
  T_PROPERTY_ACCOUNT_ID_IS_NOT_EMPTY = 't-property-account-id-is-not-empty',
  T_PROPERTY_ACCOUNT_ID_IS_EMPTY = 't-property-account-id-is-empty',
  T_PROPERTY_ACCOUNT_ID_IS = 't-property-account-id-is',
  T_PROPERTY_ACCOUNT_ID_CONTAINS = 't-property-account-id-contains',
  T_PROPERTY_ACCOUNT_ID_ONE_OF = 't-property-account-id-one-of',
  T_PROPERTY_SECURITY_TYPE_IS_NOT = 't-property-security-type-is-not',
  T_PROPERTY_SECURITY_TYPE_IS_NOT_EMPTY = 't-property-security-type-is-not-empty',
  T_PROPERTY_SECURITY_TYPE_IS_EMPTY = 't-property-security-type-is-empty',
  T_PROPERTY_SECURITY_TYPE_IS = 't-property-security-type-is',
  T_PROPERTY_SECURITY_TYPE_ONE_OF = 't-property-security-type-one-of',
  T_PROPERTY_CREATED_BY_IS_NOT = 't-property-created-by-is-not',
  T_PROPERTY_CREATED_BY_IS_NOT_EMPTY = 't-property-created-by-is-not-empty',
  T_PROPERTY_CREATED_BY_IS_EMPTY = 't-property-created-by-is-empty',
  T_PROPERTY_CREATED_BY_IS = 't-property-created-by-is',
  T_PROPERTY_CREATED_BY_CONTAINS = 't-property-created-by-contains',
  T_PROPERTY_CREATED_BY_ONE_OF = 't-property-created-by-one-of',
  T_PROPERTY_SECTOR_IS_NOT = 't-property-sector-is-not',
  T_PROPERTY_SECTOR_IS_NOT_EMPTY = 't-property-sector-is-not-empty',
  T_PROPERTY_SECTOR_IS_EMPTY = 't-property-sector-is-empty',
  T_PROPERTY_SECTOR_IS = 't-property-sector-is',
  T_PROPERTY_SECTOR_CONTAINS = 't-property-sector-contains',
  T_PROPERTY_SECTOR_ONE_OF = 't-property-sector-one-of',
  T_PROPERTY_SYMBOL_IS = 't-property-symbol-is',
  TRADE_ORDER_PROPERTY_EXCHANGE_CONTAINS = 'trade-order-property-exchange-contains',
  TRADE_ORDER_PROPERTY_EXCHANGE_IS = 'trade-order-property-exchange-is',
  TRADE_ORDER_PROPERTY_EXCHANGE_IS_EMPTY = 'trade-order-property-exchange-is-empty',
  TRADE_ORDER_PROPERTY_EXCHANGE_IS_NOT = 'trade-order-property-exchange-is-not',
  TRADE_ORDER_PROPERTY_EXCHANGE_IS_NOT_EMPTY = 'trade-order-property-exchange-is-not-empty',
  TRADE_ORDER_PROPERTY_EXCHANGE_ONE_OF = 'trade-order-property-exchange-one-of',
  TRADE_ORDER_PROPERTY_ORDER_TYPE_IS = 'trade-order-property-order-type-is',
  TRADE_ORDER_PROPERTY_ORDER_TYPE_IS_EMPTY = 'trade-order-property-order-type-is-empty',
  TRADE_ORDER_PROPERTY_ORDER_TYPE_IS_NOT = 'trade-order-property-order-type-is-not',
  TRADE_ORDER_PROPERTY_ORDER_TYPE_IS_NOT_EMPTY = 'trade-order-property-order-type-is-not-empty',
  TRADE_ORDER_PROPERTY_ORDER_TYPE_ONE_OF = 'trade-order-property-order-type-one-of',
  TRADE_ORDER_PROPERTY_QTY_REMAINING_LTE = 'trade-order-property-qty-remaining-lte',
  TRADE_ORDER_PROPERTY_QTY_REMAINING_EQUALS = 'trade-order-property-qty-remaining-equals',
  TRADE_ORDER_PROPERTY_QTY_REMAINING_NOTEQUALS = 'trade-order-property-qty-remaining-notequals',
  TRADE_ORDER_PROPERTY_QTY_REMAINING_GTE = 'trade-order-property-qty-remaining-gte',
  TRADE_ORDER_PROPERTY_QTY_REMAINING_IN = 'trade-order-property-qty-remaining-in',
  TRADE_ORDER_PROPERTY_QTY_REMAINING_BETWEEN = 'trade-order-property-qty-remaining-between',
  TRADE_ORDER_PROPERTY_FUND_CODE_CONTAINS = 'trade-order-property-fund-code-contains',
  TRADE_ORDER_PROPERTY_FUND_CODE_IS = 'trade-order-property-fund-code-is',
  TRADE_ORDER_PROPERTY_FUND_CODE_IS_EMPTY = 'trade-order-property-fund-code-is-empty',
  TRADE_ORDER_PROPERTY_FUND_CODE_IS_NOT = 'trade-order-property-fund-code-is-not',
  TRADE_ORDER_PROPERTY_FUND_CODE_IS_NOT_EMPTY = 'trade-order-property-fund-code-is-not-empty',
  TRADE_ORDER_PROPERTY_FUND_CODE_ONE_OF = 'trade-order-property-fund-code-one-of',
  TRADE_ORDER_PROPERTY_ASSET_CLASS_IS = 'trade-order-property-asset-class-is',
  TRADE_ORDER_PROPERTY_ASSET_CLASS_IS_EMPTY = 'trade-order-property-asset-class-is-empty',
  TRADE_ORDER_PROPERTY_ASSET_CLASS_IS_NOT = 'trade-order-property-asset-class-is-not',
  TRADE_ORDER_PROPERTY_ASSET_CLASS_IS_NOT_EMPTY = 'trade-order-property-asset-class-is-not-empty',
  TRADE_ORDER_PROPERTY_ASSET_CLASS_ONE_OF = 'trade-order-property-asset-class-one-of',
  TRADE_ORDER_PROPERTY_CLIENT_NAME_CONTAINS = 'trade-order-property-client-name-contains',
  TRADE_ORDER_PROPERTY_CLIENT_NAME_IS = 'trade-order-property-client-name-is',
  TRADE_ORDER_PROPERTY_CLIENT_NAME_IS_EMPTY = 'trade-order-property-client-name-is-empty',
  TRADE_ORDER_PROPERTY_CLIENT_NAME_IS_NOT = 'trade-order-property-client-name-is-not',
  TRADE_ORDER_PROPERTY_CLIENT_NAME_IS_NOT_EMPTY = 'trade-order-property-client-name-is-not-empty',
  TRADE_ORDER_PROPERTY_CLIENT_NAME_ONE_OF = 'trade-order-property-client-name-one-of',
  TRADE_ORDER_PROPERTY_HOUSEHOLD_CONTAINS = 'trade-order-property-household-contains',
  TRADE_ORDER_PROPERTY_HOUSEHOLD_IS = 'trade-order-property-household-is',
  TRADE_ORDER_PROPERTY_HOUSEHOLD_IS_EMPTY = 'trade-order-property-household-is-empty',
  TRADE_ORDER_PROPERTY_HOUSEHOLD_IS_NOT = 'trade-order-property-household-is-not',
  TRADE_ORDER_PROPERTY_HOUSEHOLD_IS_NOT_EMPTY = 'trade-order-property-household-is-not-empty',
  TRADE_ORDER_PROPERTY_HOUSEHOLD_ONE_OF = 'trade-order-property-household-one-of',
  TRADE_ORDER_PROPERTY_ENTITYID_ONE_OF = 'trade-order-property-entityid-one-of',
  TRADE_ORDER_PROPERTY_IS_AGGREGATED_TRADE_EQUALS = 'trade-order-property-is-aggregated-trade-equals',
  TRADE_ORDER_PROPERTY_IS_AGGREGATED_TRADE_NOT_EQUALS = 'trade-order-property-is-aggregated-trade-not-equals',
  T_PROPERTY_INSTRUMENT_INVESTMENT_CODE_CONTAINS = 't-property-instrument-investment-code-contains',
  T_PROPERTY_INSTRUMENT_INVESTMENT_CODE_IS = 't-property-instrument-investment-code-is',
  T_PROPERTY_INSTRUMENT_INVESTMENT_CODE_IS_EMPTY = 't-property-instrument-investment-code-is-empty',
  T_PROPERTY_INSTRUMENT_INVESTMENT_CODE_IS_NOT = 't-property-instrument-investment-code-is-not',
  T_PROPERTY_INSTRUMENT_INVESTMENT_CODE_IS_NOT_EMPTY = 't-property-instrument-investment-code-is-not-empty',
  T_PROPERTY_INSTRUMENT_INVESTMENT_CODE_ONE_OF = 't-property-instrument-investment-code-one-of',
  T_PROPERTY_SHORT_ID_CONTAINS = 't-property-short_id-contains',
  T_PROPERTY_SHORT_ID_IS = 't-property-short-id-is',
  T_PROPERTY_SHORT_ID_IS_EMPTY = 't-property-short-id-is-empty',
  T_PROPERTY_SHORT_ID_IS_NOT = 't-property-short-id-is-not',
  T_PROPERTY_SHORT_ID_IS_NOT_EMPTY = 't-property-short-id-is-not-empty',
  T_PROPERTY_SHORT_ID_ONE_OF = 't-property-short-id-one-of',
  TRADE_ORDER_PROPERTY_ALLOCATION_ACCOUNT_IS = 'trade-order-property-allocation-account-is',
  TRADE_ORDER_PROPERTY_SETTLEMENT_ACCOUNT_IS = 'trade-order-property-settlement-account-is',
  TRADE_ORDER_PROPERTY_CUSTODIAN_ACCOUNT_IS = 'trade-order-property-custodian-account-is',
  TRADE_ORDER_PROPERTY_ACCOUNT_CURRENCY_IS = 'trade-order-property-account-currency-is',
  TRADE_ORDER_PROPERTY_CUSTODIAN_ACCOUNT_CURRENCY_IS = 'trade-order-property-custodian-account-currency-is',
  TRADE_ORDER_PROPERTY_CUSTODIAN_ACCOUNT_PRO_IS = 'trade-order-property-custodian-account-pro-is',
  TRADE_ORDER_PROPERTY_ACCOUNT_PRO_IS = 'trade-order-property-account-pro-is',
  TRADE_ORDER_PROPERTY_OPERATION_IS = 'trade-order-property-operation-is',
  TRADE_ORDER_PROPERTY_OPERATION_IS_NOT = 'trade-order-property-operation-is-not',
  TRADE_ORDER_PROPERTY_OPERATION_ONE_OF = 'trade-order-property-operation-one-of',
  TRANSACTION_PROPERTY_TRANSACTION_CODE_CONTAINS = 'transaction-property-transaction-code-contains',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_TYPE_CONTAINS = 'transaction-property-d1g1t-transaction-type-contains',
  TRANSACTION_PROPERTY_TRANSACTION_DESCRIPTION_CONTAINS = 'transaction-property-transaction-description-contains',
  TRANSACTION_PROPERTY_TRANSACTION_CODE_IS = 'transaction-property-transaction-code-is',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_TYPE_IS = 'transaction-property-d1g1t-transaction-type-is',
  TRANSACTION_PROPERTY_TRANSACTION_DESCRIPTION_IS = 'transaction-property-transaction-description-is',
  TRANSACTION_PROPERTY_TRANSACTION_CODE_IS_EMPTY = 'transaction-property-transaction-code-is-empty',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_TYPE_IS_EMPTY = 'transaction-property-d1g1t-transaction-type-is-empty',
  TRANSACTION_PROPERTY_TRANSACTION_DESCRIPTION_IS_EMPTY = 'transaction-property-transaction-description-is-empty',
  TRANSACTION_PROPERTY_TRANSACTION_CODE_IS_NOT = 'transaction-property-transaction-code-is-not',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_TYPE_IS_NOT = 'transaction-property-d1g1t-transaction-type-is-not',
  TRANSACTION_PROPERTY_TRANSACTION_DESCRIPTION_IS_NOT = 'transaction-property-transaction-description-is-not',
  TRANSACTION_PROPERTY_TRANSACTION_CODE_IS_NOT_EMPTY = 'transaction-property-transaction-code-is-not-empty',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_TYPE_IS_NOT_EMPTY = 'transaction-property-d1g1t-transaction-type-is-not-empty',
  TRANSACTION_PROPERTY_TRANSACTION_DESCRIPTION_IS_NOT_EMPTY = 'transaction-property-transaction-description-is-not-empty',
  TRANSACTION_PROPERTY_TRANSACTION_CODE_ONE_OF = 'transaction-property-transaction-code-one-of',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_TYPE_ONE_OF = 'transaction-property-d1g1t-transaction-type-one-of',
  TRANSACTION_PROPERTY_TRANSACTION_DESCRIPTION_ONE_OF = 'transaction-property-transaction-description-one-of',
  TRANSACTION_PROPERTY_TRADE_DATE_ON = 'transaction-property-trade-date-on',
  TRANSACTION_PROPERTY_TRADE_DATE_AFTER = 'transaction-property-trade-date-after',
  TRANSACTION_PROPERTY_TRADE_DATE_BEFORE = 'transaction-property-trade-date-before',
  TRANSACTION_PROPERTY_TRADE_DATE_DATE_BETWEEN = 'transaction-property-trade-date-date-between',
  TRANSACTION_PROPERTY_TRANSACTION_TYPE_CONTAINS = 'transaction-property-transaction-type-contains',
  TRANSACTION_PROPERTY_TRANSACTION_TYPE_IS = 'transaction-property-transaction-type-is',
  TRANSACTION_PROPERTY_TRANSACTION_TYPE_IS_EMPTY = 'transaction-property-transaction-type-is-empty',
  TRANSACTION_PROPERTY_TRANSACTION_TYPE_IS_NOT = 'transaction-property-transaction-type-is-not',
  TRANSACTION_PROPERTY_TRANSACTION_TYPE_IS_NOT_EMPTY = 'transaction-property-transaction-type-is-not-empty',
  TRANSACTION_PROPERTY_TRANSACTION_TYPE_ONE_OF = 'transaction-property-transaction-type-one-of',
  TRANSACTION_PROPERTY_TRANSACTION_ISVALIDATION_EQUALS = 'transaction-property-transaction-isvalidation-equals',
  TRANSACTION_PROPERTY_TRANSACTION_ISVALIDATION_NOT_EQUALS = 'transaction-property-transaction-isvalidation-not-equals',
  TRANSACTION_PROPERTY_TRANSACTION_SOURCE_CONTAINS = 'transaction-property-transaction-source-contains',
  TRANSACTION_PROPERTY_TRANSACTION_SOURCE_IS = 'transaction-property-transaction-source-is',
  TRANSACTION_PROPERTY_TRANSACTION_SOURCE_IS_EMPTY = 'transaction-property-transaction-source-is-empty',
  TRANSACTION_PROPERTY_TRANSACTION_SOURCE_IS_NOT = 'transaction-property-transaction-source-is-not',
  TRANSACTION_PROPERTY_TRANSACTION_SOURCE_IS_NOT_EMPTY = 'transaction-property-transaction-source-is-not-empty',
  TRANSACTION_PROPERTY_TRANSACTION_SOURCE_ONE_OF = 'transaction-property-transaction-source-one-of',
  TRANSACTION_PROPERTY_SECURITY_ID_CONTAINS = 'transaction-property-security-id-contains',
  TRANSACTION_PROPERTY_SECURITY_ID_IS = 'transaction-property-security-id-is',
  TRANSACTION_PROPERTY_SECURITY_ID_IS_NOT = 'transaction-property-security-id-is-not',
  TRANSACTION_PROPERTY_SECURITY_ID_ONE_OF = 'transaction-property-security-id-one-of',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_LTE = 'transaction-property-commission-amount-lte',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_LT = 'transaction-property-commission-amount-lt',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_EQUALS = 'transaction-property-commission-amount-equals',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_NOTEQUALS = 'transaction-property-commission-amount-notequals',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_GTE = 'transaction-property-commission-amount-gte',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_GT = 'transaction-property-commission-amount-gt',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_IN = 'transaction-property-commission-amount-in',
  TRANSACTION_PROPERTY_COMMISSION_AMOUNT_BETWEEN = 'transaction-property-commission-amount-between',
  TRANSACTION_PROPERTY_IS_SOLICITED_IS = 'transaction-property-is-solicited-is',
  TRANSACTION_PROPERTY_IS_SOLICITED_IS_EMPTY = 'transaction-property-is-solicited-is-empty',
  TRANSACTION_PROPERTY_IS_SOLICITED_IS_NOT = 'transaction-property-is-solicited-is-not',
  TRANSACTION_PROPERTY_IS_SOLICITED_IS_NOT_EMPTY = 'transaction-property-is-solicited-is-not-empty',
  TRANSACTION_PROPERTY_IS_SOLICITED_ONE_OF = 'transaction-property-is-solicited-one-of',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ID_CONTAINS = 'transaction-property-funding-security-id-contains',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ID_IS = 'transaction-property-funding-security-id-is',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ID_IS_EMPTY = 'transaction-property-funding-security-id-is-empty',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ID_IS_NOT = 'transaction-property-funding-security-id-is-not',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ID_IS_NOT_EMPTY = 'transaction-property-funding-security-id-is-not-empty',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ID_ONE_OF = 'transaction-property-funding-security-id-one-of',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_CONTAINS = 'transaction-property-funding-security-contains',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_IS = 'transaction-property-funding-security-is',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_IS_EMPTY = 'transaction-property-funding-security-is-empty',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_IS_NOT = 'transaction-property-funding-security-is-not',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_IS_NOT_EMPTY = 'transaction-property-funding-security-is-not-empty',
  TRANSACTION_PROPERTY_FUNDING_SECURITY_ONE_OF = 'transaction-property-funding-security-one-of',
  TRANSACTION_PROPERTY_TRANSACTION_CATEGORY_CONTAINS = 'transaction-property-transaction-category-contains',
  TRANSACTION_PROPERTY_TRANSACTION_CATEGORY_IS = 'transaction-property-transaction-category-is',
  TRANSACTION_PROPERTY_TRANSACTION_CATEGORY_IS_EMPTY = 'transaction-property-transaction-category-is-empty',
  TRANSACTION_PROPERTY_TRANSACTION_CATEGORY_IS_NOT = 'transaction-property-transaction-category-is-not',
  TRANSACTION_PROPERTY_TRANSACTION_CATEGORY_IS_NOT_EMPTY = 'transaction-property-transaction-category-is-not-empty',
  TRANSACTION_PROPERTY_TRANSACTION_CATEGORY_ONE_OF = 'transaction-property-transaction-category-one-of',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_LTE = 'transaction-property-d1g1t-transaction-amount-gross-lte',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_LT = 'transaction-property-d1g1t-transaction-amount-gross-lt',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_EQUALS = 'transaction-property-d1g1t-transaction-amount-gross-equals',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_NOTEQUALS = 'transaction-property-d1g1t-transaction-amount-gross-notequals',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_GTE = 'transaction-property-d1g1t-transaction-amount-gross-gte',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_GT = 'transaction-property-d1g1t-transaction-amount-gross-gt',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_IN = 'transaction-property-d1g1t-transaction-amount-gross-in',
  TRANSACTION_PROPERTY_D1G1T_TRANSACTION_AMOUNT_GROSS_BETWEEN = 'transaction-property-d1g1t-transaction-amount-gross-between',
  TRANSACTION_METRIC_INVESTOR_TRANSACTION_TYPE_IS = 'transaction-metric-investor-transaction-type-is',
  TRANSACTION_METRIC_INVESTOR_TRANSACTION_TYPE_ONE_OF = 'transaction-metric-investor-transaction-type-one-of',
  TRANSACTION_METRIC_INVESTOR_TRANSACTION_TYPE_CONTAINS = 'transaction-metric-investor-transaction-type-contains',
  TRANSACTION_METRIC_INVESTOR_TRANSACTION_TYPE_IS_EMPTY = 'transaction-metric-investor-transaction-type-is-empty',
  TRANSACTION_METRIC_INVESTOR_TRANSACTION_TYPE_IS_NOT_EMPTY = 'transaction-metric-investor-transaction-type-is-not-empty',
  TRANSACTION_METRIC_INVESTOR_TRANSACTION_TYPE_IS_NOT = 'transaction-metric-investor-transaction-type-is-not',
  CLASS_SERIES_PROPERTY_ENTITY_ID_UUIDEQUALS = 'class-series-property-entity-id-uuidequals',
  CLASS_SERIES_PROPERTY_NAME_CONTAINS = 'class-series-property-name-contains',
  CLASS_SERIES_PROPERTY_NAME_IS = 'class-series-property-name-is',
  FUND_PROPERTY_ENTITY_ID_UUIDEQUALS = 'fund-property-entity-id-uuidequals',
  FUND_PROPERTY_NAME_CONTAINS = 'fund-property-name-contains',
  FUND_PROPERTY_NAME_IS = 'fund-property-name-is',
  MANDATE_PROPERTY_MANDATE_ID_IS_NOT_EMPTY = 'mandate-property-mandate-id-is-not-empty',
  MANDATE_PROPERTY_MANDATE_ID_IS = 'mandate-property-mandate-id-is',
  MANDATE_PROPERTY_MANDATE_ID_IS_EMPTY = 'mandate-property-mandate-id-is-empty',
  MANDATE_PROPERTY_MANDATE_ID_ONE_OF = 'mandate-property-mandate-id-one-of',
  MANDATE_PROPERTY_MANDATE_ID_CONTAINS = 'mandate-property-mandate-id-contains',
  MANDATE_PROPERTY_MANDATE_ID_IS_NOT = 'mandate-property-mandate-id-is-not',
  MANDATE_PROPERTY_MANDATE_NAME_IS_NOT_EMPTY = 'mandate-property-mandate-name-is-not-empty',
  MANDATE_PROPERTY_MANDATE_NAME_IS = 'mandate-property-mandate-name-is',
  MANDATE_PROPERTY_MANDATE_NAME_IS_EMPTY = 'mandate-property-mandate-name-is-empty',
  MANDATE_PROPERTY_MANDATE_NAME_ONE_OF = 'mandate-property-mandate-name-one-of',
  MANDATE_PROPERTY_MANDATE_NAME_CONTAINS = 'mandate-property-mandate-name-contains',
  MANDATE_PROPERTY_MANDATE_NAME_IS_NOT = 'mandate-property-mandate-name-is-not',
  MANDATE_PROPERTY_CLIENT_NAME_IS = 'mandate-property-client-name-is',
  MANDATE_PROPERTY_CLIENT_NAME_CONTAINS = 'mandate-property-client-name-contains',
  MANDATE_PROPERTY_CLIENT_NAME_IS_EMPTY = 'mandate-property-client-name-is-empty',
  MANDATE_PROPERTY_CLIENT_NAME_IS_NOT = 'mandate-property-client-name-is-not',
  MANDATE_PROPERTY_CLIENT_NAME_IS_NOT_EMPTY = 'mandate-property-client-name-is-not-empty',
  MANDATE_PROPERTY_CLIENT_NAME_ONE_OF = 'mandate-property-client-name-one-of',
  MANDATE_PROPERTY_HOUSEHOLD_NAME_IS = 'mandate-property-household-name-is',
  MANDATE_PROPERTY_HOUSEHOLD_NAME_CONTAINS = 'mandate-property-household-name-contains',
  MANDATE_PROPERTY_HOUSEHOLD_NAME_IS_EMPTY = 'mandate-property-household-name-is-empty',
  MANDATE_PROPERTY_HOUSEHOLD_NAME_IS_NOT = 'mandate-property-household-name-is-not',
  MANDATE_PROPERTY_HOUSEHOLD_NAME_IS_NOT_EMPTY = 'mandate-property-household-name-is-not-empty',
  MANDATE_PROPERTY_HOUSEHOLD_NAME_ONE_OF = 'mandate-property-household-name-one-of',
  MANDATE_PROPERTY_MODEL_PORTFOLIO_NAME_IS = 'mandate-property-model-portfolio-name-is',
  MANDATE_PROPERTY_MODEL_PORTFOLIO_NAME_CONTAINS = 'mandate-property-model-portfolio-name-contains',
  MANDATE_PROPERTY_MODEL_PORTFOLIO_NAME_IS_EMPTY = 'mandate-property-model-portfolio-name-is-empty',
  MANDATE_PROPERTY_MODEL_PORTFOLIO_NAME_IS_NOT = 'mandate-property-model-portfolio-name-is-not',
  MANDATE_PROPERTY_MODEL_PORTFOLIO_NAME_IS_NOT_EMPTY = 'mandate-property-model-portfolio-name-is-not-empty',
  MANDATE_PROPERTY_MODEL_PORTFOLIO_NAME_ONE_OF = 'mandate-property-model-portfolio-name-one-of',
  MANDATE_PROPERTY_RISK_PROFILE_NAME_IS = 'mandate-property-risk-profile-name-is',
  MANDATE_PROPERTY_RISK_PROFILE_NAME_CONTAINS = 'mandate-property-risk-profile-name-contains',
  MANDATE_PROPERTY_RISK_PROFILE_NAME_IS_EMPTY = 'mandate-property-risk-profile-name-is-empty',
  MANDATE_PROPERTY_RISK_PROFILE_NAME_IS_NOT = 'mandate-property-risk-profile-name-is-not',
  MANDATE_PROPERTY_RISK_PROFILE_NAME_IS_NOT_EMPTY = 'mandate-property-risk-profile-name-is-not-empty',
  MANDATE_PROPERTY_RISK_PROFILE_NAME_ONE_OF = 'mandate-property-risk-profile-name-one-of',
  MANDATE_PROPERTY_RISK_TOLERANCE_NAME_IS = 'mandate-property-risk-tolerance-name-is',
  MANDATE_PROPERTY_RISK_TOLERANCE_NAME_CONTAINS = 'mandate-property-risk-tolerance-name-contains',
  MANDATE_PROPERTY_RISK_TOLERANCE_NAME_IS_EMPTY = 'mandate-property-risk-tolerance-name-is-empty',
  MANDATE_PROPERTY_RISK_TOLERANCE_NAME_IS_NOT = 'mandate-property-risk-tolerance-name-is-not',
  MANDATE_PROPERTY_RISK_TOLERANCE_NAME_IS_NOT_EMPTY = 'mandate-property-risk-tolerance-name-is-not-empty',
  MANDATE_PROPERTY_RISK_TOLERANCE_NAME_ONE_OF = 'mandate-property-risk-tolerance-name-one-of',
  MANDATE_PROPERTY_ACTIVE_STATEGY_IS = 'mandate-property-active-stategy-is',
}
export enum GROUPING_CRITERIA_SLUG {
  HOUSEHOLD_NAME = 'household-name',
  CLIENT_NAME = 'client-name',
  CLIENT_ID = 'client-id',
  ACCOUNT_MANDATE = 'account-mandate',
  ACCOUNT_NAME = 'account-name',
  ACCOUNT_ID = 'account-id',
  ACCOUNT_REGISTRATION_STATUS = 'account-registration-status',
  ACCOUNT_TYPE = 'account-type',
  SLEEVE_ID = 'sleeve-id',
  PORTFOLIO_ID = 'portfolio-id',
  PORTFOLIO_FUND_NAME = 'portfolio-fund-name',
  POSITION_NAME = 'position-name',
  SECURITY_ASSET_CLASS = 'security-asset-class',
  SECURITY_NAME = 'security-name',
  SECURITY_SECTOR = 'security-sector',
  SECURITY_CURRENCY = 'security-currency',
  ACCOUNT_RISK_PROFILE = 'account-risk-profile',
  ACCOUNT_MODEL_PORTFOLIO = 'account-model-portfolio',
  SECURITY_STRATEGY = 'security-strategy',
  ACCOUNT_REP_CODE = 'account-rep-code',
  ACCOUNT_GOAL = 'account-goal',
  TRANSACTION_YEAR = 'transaction-year',
  TRANSACTION_MONTH = 'transaction-month',
  CLIENT_JURISDICTIONRESIDENCY = 'client-jurisdictionresidency',
  CLIENT_INSIDER = 'client-insider',
  CLIENT_ACCREDITED_INVESTOR = 'client-accredited-investor',
  CLIENT_RESTRICTED_PERSON = 'client-restricted-person',
  CLIENT_POLITICALLY_EXPOSED_CANADA = 'client-politically-exposed-canada',
  CLIENT_POLITICALLY_EXPOSED_USA = 'client-politically-exposed-usa',
  CLIENT_POLITICALLY_EXPOSED_OTHERS = 'client-politically-exposed-others',
  ACCOUNT_CURRENCY = 'account-currency',
  ACCOUNT_STATUS = 'account-status',
  CLIENT_AGE = 'client-age',
  ACCOUNT_PORTFOLIO_MANAGER = 'account-portfolio-manager',
  CLIENT_REFERRER = 'client-referrer',
  CLIENT_REFERRAL_TYPE = 'client-referral-type',
  SECURITY_ASSET_SUB_CLASS = 'security-asset-sub-class',
  CLIENT_STATUS = 'client-status',
  ACCOUNT_COMBINED_ID = 'account-combined-id',
  HOUSEHOLD_ID = 'household-id',
  CLIENT_FAMILY = 'client-family',
  SECURITY_FUND_NAME = 'security-fund-name',
  SECURITY_ID = 'security-id',
  SECURITY_SYMBOL = 'security-symbol',
  CLIENT_RELATION_TO_USA = 'client-relation-to-usa',
  ACCOUNT_ADVISORY_SCOPE = 'account-advisory-scope',
  TRANSACTION_TRANSACTION_DESCRIPTION = 'transaction-transaction-description',
  TRANSACTION_TRANSACTION_CODE = 'transaction-transaction-code',
  TRANSACTION_D1G1T_TRANSACTION_TYPE = 'transaction-d1g1t-transaction-type',
  TRANSACTION_TYPE = 'transaction-type',
  CLIENT_AGE_GROUP = 'client-age-group',
  CLIENT_GENERATION = 'client-generation',
  CLIENT_CATEGORY = 'client-category',
  CLIENT_SEGMENT = 'client-segment',
  CLIENT_TENURE = 'client-tenure',
  CLIENT_EMPLOYMENT_OCCUPATION = 'client-employment-occupation',
  SECURITY_PUBLICPRIVATE = 'security-publicprivate',
  CLASSSERIES_NAME = 'classseries-name',
  SECURITY_INDUSTRY = 'security-industry',
  SECURITY_REGION = 'security-region',
  SECURITY_RISK_RATING = 'security-risk-rating',
  SECURITY_SUB_STRATEGY = 'security-sub-strategy',
  SECURITY_COUNTRY = 'security-country',
  SECURITY_GEOGRAPHY = 'security-geography',
  SECURITY_RISK_SCORE = 'security-risk-score',
  SECURITY_LEGAL_STRUCTURE = 'security-legal-structure',
  SECURITY_LIQUIDITY_SCORE = 'security-liquidity-score',
  SECURITY_ISSUER = 'security-issuer',
  SECURITY_ISSUER_CREDIT_RATING = 'security-issuer-credit-rating',
  SECURITY_PRODUCT_TYPE = 'security-product-type',
  CLIENT_TYPE = 'client-type',
  CLIENT_MEETING_FREQUENCY = 'client-meeting-frequency',
  CLIENT_MEETING_METHOD = 'client-meeting-method',
  CLIENT_LAST_MEETING_DATE = 'client-last-meeting-date',
  CLIENT_REPORTING_FREQUENCY = 'client-reporting-frequency',
  CLIENT_REPORTING_MEDIUM = 'client-reporting-medium',
  CLIENT_EMPLOYMENT_STATUS = 'client-employment-status',
  ACCOUNT_CUSTODIAN = 'account-custodian',
  ACCOUNT_ADVISORY_FIRM = 'account-advisory-firm',
  CUSTODIANACCOUNT_ADVISORY_FIRM = 'custodianaccount-advisory-firm',
  CUSTODIANACCOUNT_ADVISORY_SCOPE = 'custodianaccount-advisory-scope',
  CUSTODIANACCOUNT_COMBINED_ID = 'custodianaccount-combined-id',
  CUSTODIANACCOUNT_CURRENCY = 'custodianaccount-currency',
  CUSTODIANACCOUNT_CUSTODIAN = 'custodianaccount-custodian',
  CUSTODIANACCOUNT_ID = 'custodianaccount-id',
  CUSTODIANACCOUNT_NAME = 'custodianaccount-name',
  CUSTODIANACCOUNT_REGISTRATION_STATUS = 'custodianaccount-registration-status',
  CUSTODIANACCOUNT_STATUS = 'custodianaccount-status',
  CUSTODIANACCOUNT_TYPE = 'custodianaccount-type',
  ACCOUNT_ACCOUNTING_CATEGORY = 'account-accounting-category',
  ACCOUNT_SUB_ADVISOR = 'account-sub-advisor',
  ACCOUNT_USER_DEFINED_1 = 'account-user-defined-1',
  ACCOUNT_USER_DEFINED_2 = 'account-user-defined-2',
  ACCOUNT_USER_DEFINED_3 = 'account-user-defined-3',
  ACCOUNT_USER_DEFINED_4 = 'account-user-defined-4',
  ACCOUNT_USER_DEFINED_5 = 'account-user-defined-5',
  ACCOUNT_ASSET_CLASS = 'account-asset-class',
  ACCOUNT_ASSET_SUBCLASS = 'account-asset-subclass',
  ACCOUNT_REPORTING_NAME = 'account-reporting-name',
  CUSTODIANACCOUNT_ACCOUNTING_CATEGORY = 'custodianaccount-accounting-category',
  CUSTODIANACCOUNT_SUB_ADVISOR = 'custodianaccount-sub-advisor',
  CUSTODIANACCOUNT_USER_DEFINED_1 = 'custodianaccount-user-defined-1',
  CUSTODIANACCOUNT_USER_DEFINED_2 = 'custodianaccount-user-defined-2',
  CUSTODIANACCOUNT_USER_DEFINED_3 = 'custodianaccount-user-defined-3',
  CUSTODIANACCOUNT_USER_DEFINED_4 = 'custodianaccount-user-defined-4',
  CUSTODIANACCOUNT_USER_DEFINED_5 = 'custodianaccount-user-defined-5',
  CUSTODIANACCOUNT_ASSET_CLASS = 'custodianaccount-asset-class',
  CUSTODIANACCOUNT_ASSET_SUBCLASS = 'custodianaccount-asset-subclass',
  CLIENT_REPORTING_NAME = 'client-reporting-name',
  CLIENT_USER_DEFINED_1 = 'client-user-defined-1',
  CLIENT_USER_DEFINED_2 = 'client-user-defined-2',
  CLIENT_USER_DEFINED_3 = 'client-user-defined-3',
  CLIENT_USER_DEFINED_4 = 'client-user-defined-4',
  CLIENT_USER_DEFINED_5 = 'client-user-defined-5',
  CLIENT_JURISDICTION_PROVINCE = 'client-jurisdiction-province',
  SECURITY_ASSET_CLASS_L3 = 'security-asset-class-l3',
  SECURITY_FUND_TYPE = 'security-fund-type',
  SECURITY_FUND_MANAGER = 'security-fund-manager',
  SECURITY_FUND_MANDATE = 'security-fund-mandate',
  SECURITY_FUND_VEHICLE = 'security-fund-vehicle',
  SECURITY_FUND_STRUCTURE = 'security-fund-structure',
  SECURITY_RISKRETURN = 'security-riskreturn',
  SECURITY_USER_DEFINED_1 = 'security-user-defined-1',
  SECURITY_USER_DEFINED_2 = 'security-user-defined-2',
  SECURITY_USER_DEFINED_3 = 'security-user-defined-3',
  SECURITY_USER_DEFINED_4 = 'security-user-defined-4',
  SECURITY_USER_DEFINED_5 = 'security-user-defined-5',
  SECURITY_MATURITY_YEAR = 'security-maturity-year',
  POSITION_IS_CONCENTRATED = 'position-is-concentrated',
  POSITION_IS_RECOMMENDED = 'position-is-recommended',
  POSITION_FEE_PAYINGNON_FEE_PAYING = 'position-fee-payingnon-fee-paying',
  TRANSACTION_SOLICITED = 'transaction-solicited',
  TRANSACTION_SECURITY_ID = 'transaction-security-id',
  TRANSACTION_FUNDING_SECURITY_ID = 'transaction-funding-security-id',
  TRANSACTION_FUNDING_SECURITY = 'transaction-funding-security',
  TRANSACTION_SOURCE = 'transaction-source',
  TRANSACTION_CATEGORY = 'transaction-category',
  CUSTODIANACCOUNT_DATA_SOURCE = 'custodianaccount-data-source',
  ACCOUNT_DATA_SOURCE = 'account-data-source',
  SECURITY_REPORTING_NAME = 'security-reporting-name',
  SECURITY_ASSET_CATEGORY = 'security-asset-category',
  SECURITY_ASSET_SUB_CATEGORY = 'security-asset-sub-category',
  CLASSSERIES_ID = 'classseries-id',
  FUNDMANAGER_ID = 'fundmanager-id',
  FUNDMANAGER_NAME = 'fundmanager-name',
  FUND_ID = 'fund-id',
  FUND_NAME = 'fund-name',
  FUND_TYPE = 'fund-type',
  FUND_TERM = 'fund-term',
  FUND_VINTAGE_YEAR = 'fund-vintage-year',
  FUND_WATERFALL = 'fund-waterfall',
  FUND_DOMICILE = 'fund-domicile',
  FUND_MANDATE = 'fund-mandate',
  POSITION_IS_RESERVED = 'position-is-reserved',
  ACCOUNT_GROUPING_0 = 'account-grouping-0',
  SLEEVE_GROUPING_1 = 'sleeve-grouping-1',
  POSITION_GROUPING_2 = 'position-grouping-2',
  ACCOUNT_GROUPING_3 = 'account-grouping-3',
  SLEEVE_GROUPING_4 = 'sleeve-grouping-4',
  POSITION_GROUPING_5 = 'position-grouping-5',
  POSITION_HELD_AT = 'position-held-at',
  ACCOUNT_ACCOUNTING_SUBCATEGORY = 'account-accounting-subcategory',
  ACCOUNT_IS_DISCRETIONARY = 'account-is-discretionary',
  CUSTODIANACCOUNT_IS_DISCRETIONARY = 'custodianaccount-is-discretionary',
  ACCOUNT_MANDATE_FIRM_PROVIDED_KEY = 'account-mandate-firm-provided-key',
  SECURITY_EXCLUDED_FROM_ALLOCATION = 'security-excluded-from-allocation',
  ACCOUNT_TAX_STATUS = 'account-tax-status',
  CUSTODIANACCOUNT_TAX_STATUS = 'custodianaccount-tax-status',
}
