import React, {createContext, useContext, useMemo} from 'react'

export interface IControlStateContextValue {
  loading?: boolean
}

export const ControlStateContext = createContext<IControlStateContextValue>({})

export interface IControlStateProviderProps extends IControlStateContextValue {}

/**
 * Provides a context boundary which sets certain states of descendent control elements
 *
 * Used to provide an easy interface to disable or set a loading state of several
 * control elements, such as buttons and inputs
 *
 * @example
 * ```
 * <ControlStateProvider loading={true}>
 *  <ValueLabelSelect {...selectProps} />
 *  <Button {...buttonProps}>Action</Button>
 * </ControlStateProvider>
 * ```
 *
 * without needing to pass disabled=true to select and button, they will
 * be disabled
 */
export const ControlStateProvider: React.FC<IControlStateProviderProps> = ({
  loading = false,
  children
}) => {
  const value: IControlStateContextValue = useMemo(() => {
    return {
      loading
    }
  }, [loading])

  return (
    <ControlStateContext.Provider value={value}>
      {children}
    </ControlStateContext.Provider>
  )
}

export function useControlState() {
  return useContext(ControlStateContext)
}
