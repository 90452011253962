import {pick} from 'lodash'

/**
 * Wraps lodash.pick and adds type safety to ensure only `obj` keys can be picked to compose a new object.
 */
export const pickFromObj = <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> => {
  return pick(obj, ...keys) as Pick<T, K>
}
