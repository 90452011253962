import {IChartTable} from '@d1g1t/api/models'

import {MIME_TYPE} from '@d1g1t/lib/constants'

import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'

import {BaseEndpoints} from './base'

interface IManageFundsParams {
  control: {
    nonClientPortfolio: boolean
  }
  settings: ISettings
}

export class ManageFundsEndpoints extends BaseEndpoints {
  static basePath = '/manage-funds'

  static chart(body: IManageFundsParams) {
    return super._post<IChartTable>('/', {body})
  }

  static getExcel(body: IManageFundsParams) {
    return super._post<Blob>('/chart', {
      headers: {Accept: MIME_TYPE.excel},
      body
    })
  }
}
