import {BaseRestEndpoints} from './base'

export class ExternalToolsEndpoints extends BaseRestEndpoints {
  static basePath = '/external-tools'

  static trailingSlash = false

  /**
   * Returns docusign url to redirect to
   */
  static docusignUrl(target: string, jwt: string) {
    const query = {
      jwt,
      target
    }
    const newParams = new URLSearchParams(query)
    return `${super.buildPath('/docusign')}?${newParams.toString()}`
  }

  static futureVault(jwt: string) {
    return super.buildPath(`/futurevault?jwt=${jwt}`)
  }
}
