import {IInstrumentSector} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class InstrumentSectorEndpoints extends BaseRestEndpoints {
  static basePath = '/instrument-sectors'

  static list() {
    return super._list<IApiListResponse<IInstrumentSector>>()
  }
}

export class InstrumentSectorVectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/sector-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
