import React, {forwardRef} from 'react'
import {useTranslation} from 'react-i18next'

import MuiTooltip, {TooltipProps} from '@material-ui/core/Tooltip'

import {classNames} from '@d1g1t/lib/class-names'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import * as css from './style.scss'

export interface ITooltipProps extends Omit<TooltipProps, 'classes'> {
  /**
   * Removes MUI default max width (300px)
   * and sets it to none instad
   */
  noMaxWidth?: boolean
}

const muiPropsFromCustomProps = (props: ITooltipProps): TooltipProps => {
  // Only show dark theme tooltip for simple text.
  // Complex React Nodes have graphics that require light background

  const tooltipWidth = props.noMaxWidth ? css.noMaxWidth : undefined
  const themedTooltip =
    typeof props.title === 'string'
      ? classNames(tooltipWidth, css.tooltipDark)
      : classNames(tooltipWidth, css.tooltip)
  const themedArrow =
    typeof props.title === 'string' ? css.arrowDark : css.arrow
  return {
    classes: {
      tooltip: themedTooltip,
      arrow: props.arrow ? themedArrow : undefined
    },
    ...props
  }
}

export const Tooltip: React.FC<ITooltipProps> = forwardRef((props, ref) => {
  const {t} = useTranslation()

  if (!props.title) {
    return <>{onlyTranslateStrings(t, props.children)}</>
  }

  return (
    <MuiTooltip
      ref={ref}
      {...muiPropsFromCustomProps(props)}
      title={onlyTranslateStrings(t, props.title)}
    />
  )
})
