import {ApiParam} from 'fairlight'

import {IBillSpecAccountInfoListRetrieve} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class BillingAccountInfosEndpoint extends BaseRestEndpoints {
  static basePath = '/fees/account-infos'

  static findById(id: ApiParam) {
    return super._findById<IBillSpecAccountInfoListRetrieve>(id)
  }

  static partialUpdate(
    id: ApiParam,
    body: Partial<IBillSpecAccountInfoListRetrieve>
  ) {
    return super._partialUpdate<IBillSpecAccountInfoListRetrieve>(id, body)
  }
}
