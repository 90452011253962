import {IAdvisor} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AdvisorsEndpoints extends BaseRestEndpoints {
  static basePath = '/advisors'

  static list() {
    return super._list<IApiListResponse<IAdvisor>>()
  }
}
