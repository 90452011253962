import {TFunction} from 'react-i18next'

/**
 * Convenience method to prevent errors when trying to translate react
 * nodes that aren't strings
 * @param t - Translate function
 * @param node - React node to possibly translate
 * @returns Translated string or original node
 */
export const onlyTranslateStrings = (
  t: TFunction<'translation', undefined>,
  node: React.ReactNode
) => {
  return typeof node === 'string' ? t(node) : node
}
