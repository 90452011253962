import jwtDecode from 'jwt-decode'
import {createSelector} from 'reselect'

import {PREFIX} from './constants'
import {initialState} from './reducers'
import {IAuthDomain} from './typings'

interface IDecodedToken {
  exp: number
}

export const getAuthDomain = (state): IAuthDomain => state[PREFIX]

/**
 * Slice out authentication related redux state.
 */
export const getters = createSelector(
  getAuthDomain,
  (domain): IAuthDomain => domain || initialState
)

export const getToken = createSelector(getters, (domain) => domain.token)

export const getMfaToken = createSelector(getters, (domain) => domain.mfaToken)

export const getIsAuthenticated = createSelector(getToken, (token) => !!token)

export const getDecodedToken = createSelector(getToken, (token) =>
  jwtDecode<IDecodedToken>(token)
)

export const getTokenExpirationMs = createSelector(
  getDecodedToken,
  (decoded) => {
    if (!decoded) {
      return null
    }

    return decoded.exp * 1000
  }
)
