import {AVAILABLE_LANGUAGES} from '@d1g1t/shared/wrappers/localization-settings/typings'

/**
 * Lifted from https://github.com/formatjs/formatjs/blob/master/packages/intl-unified-numberformat/src/core.ts
 */
function isUnitSupported(unit: string) {
  try {
    // eslint-disable-next-line no-new
    new Intl.NumberFormat(undefined, {
      style: 'unit',
      unit
    } as any)
  } catch (e) {
    return false
  }
  return true
}

/**
 * returns the appropriate language from the list of available languages,
 * for consistency of working with languages in the application.
 *
 * The language is determined from the browser settings, or from the received parameter.
 *
 * @param language - the variant of the language with which need to find a match
 */
export function getLocale(language?: string): AVAILABLE_LANGUAGES {
  const browserLanguagePreference = language || navigator.language
  const availableLanguage = Object.values(AVAILABLE_LANGUAGES).find(
    (availableLanguage) =>
      browserLanguagePreference?.includes(availableLanguage)
  )

  if (availableLanguage) {
    return availableLanguage
  }

  return AVAILABLE_LANGUAGES.ENGLISH
}

function fetchLocaleData() {
  const locale = getLocale()

  switch (locale) {
    case AVAILABLE_LANGUAGES.ENGLISH:
      return import(
        '@formatjs/intl-unified-numberformat/dist/locale-data/en.json'
      )
    case AVAILABLE_LANGUAGES.FRENCH:
      return import(
        '@formatjs/intl-unified-numberformat/dist/locale-data/fr.json'
      )
  }
}

export async function resolveRequiredPolyfills(): Promise<void> {
  if (!isUnitSupported('bit')) {
    const module = await import('@formatjs/intl-unified-numberformat')
    const localeData = await fetchLocaleData()

    module.UnifiedNumberFormat.__addLocaleData(localeData)
    Intl.NumberFormat = module.UnifiedNumberFormat as any
  }
}
