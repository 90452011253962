import produce from 'immer'
import {ActionType, getType} from 'typesafe-actions'

import {loaderInit} from '@d1g1t/lib/reducers'
import {injectAsyncReducer} from '@d1g1t/lib/redux-inject-async'

import {
  updateLoadingIndexMapForLoadedRange,
  updateLoadingIndexMapForLoadingRange
} from '@d1g1t/shared/wrappers/chart-paginator/lib'

import {actions} from './actions'
import {PREFIX} from './constants'
import {ICalculationState, IDomainSlice} from './typings'

export const initialStateSlice: IDomainSlice = {
  instanceCount: 0,
  loadingIndexMap: {},
  response: loaderInit(null),
  calcRequestBody: null
}

const actionTypesSet = new Set(Object.values(actions).map(getType))

const reducer = (
  baseState: ICalculationState = {},
  action: ActionType<typeof actions>
): ICalculationState => {
  // Check if this action is part of calculation actions to avoid creating
  // invalid keys in the calculation state
  if (!actionTypesSet.has(action.type)) {
    return baseState
  }

  if (!action.meta) {
    return baseState
  }

  // Seperately create a state with initial state if required to allow us
  // to reference a draft domain object
  const state = baseState[action.meta.id]
    ? baseState
    : produce(baseState, (draft) => {
        draft[action.meta.id] = {...initialStateSlice}
      })

  return produce(state, (draft) => {
    if (action.type === getType(actions.invalidateCachedCalculation)) {
      delete draft[action.meta.id]

      return
    }

    const domain = draft[action.meta.id]

    switch (action.type) {
      case getType(actions.register):
        domain.instanceCount++
        return
      case getType(actions.deregister):
        domain.instanceCount--
        return
      case getType(actions.initCalculation):
        domain.response.loading = true
        domain.response.error = null
        return
      case getType(actions.startCalculation):
        domain.calcRequestBody = action.payload
        return
      case getType(actions.abortCalculation):
        domain.response.loading = false
        domain.response.error = null
        return
      case getType(actions.cancelCalculation):
        domain.instanceCount = 0
        domain.response.loading = false
        domain.loadingIndexMap = {}
        return
      case getType(actions.setResult):
        domain.response.loading = false
        domain.response.data = action.payload
        return
      case getType(actions.error):
        domain.response.data = null
        domain.response.loading = false
        domain.response.error = action.payload
        return
      case getType(actions.fetchRanges):
        for (const range of action.payload.ranges) {
          domain.loadingIndexMap = updateLoadingIndexMapForLoadingRange(
            domain.loadingIndexMap,
            range
          )
        }
        return
      case getType(actions.fetchSingleRangeComplete):
        domain.loadingIndexMap = updateLoadingIndexMapForLoadedRange(
          domain.loadingIndexMap,
          action.payload.range
        )
        domain.response.data = action.payload.response
        return
      case getType(actions.fethcSingleRangeFailed):
        updateLoadingIndexMapForLoadedRange(
          domain.loadingIndexMap,
          action.payload
        )
        return
      case getType(actions.clearResponseData):
        if (domain.response.data) {
          domain.response.data = domain.response.data.replaceItems([], 0)
        }
        domain.response.loading = false
        domain.response.error = null
        domain.loadingIndexMap = {}
        return
      default:
        return state
    }
  })
}

injectAsyncReducer(PREFIX, reducer)
