import {ApiQueryParams} from 'fairlight'

import {ACCOUNTGETSINGLE_STATUS, ALL_MODELS} from '@d1g1t/api/models'

import {ISearchResult} from '@d1g1t/shared/containers/search'
import {SECURITY_SEARCH_FILTER_PARAMS_KEYS} from '@d1g1t/shared/containers/security-search-filter/constants'

import {BaseEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface ISearchQuery {
  q?: string
  entityId?: string
  lookupId?: string
  models?: ALL_MODELS[]
  entityIds?: string[]
  offset?: number
  limit?: number
  status?: ACCOUNTGETSINGLE_STATUS[]
  isTradable?: boolean
  includedEntityId?: string
  isAccountSpecificNot?: boolean
  filters?: object
  isPortfolioInstrument?: boolean
}

export class SearchEndpoints extends BaseEndpoints {
  static basePath = '/search'

  static search(params: ISearchQuery) {
    const {
      models,
      entityIds,
      entityId,
      lookupId,
      status,
      isTradable,
      includedEntityId,
      isAccountSpecificNot,
      filters,
      isPortfolioInstrument,
      ...passthroughParams
    } = params

    const query: ApiQueryParams = {
      ...passthroughParams,
      entity_id: entityId,
      lookup_id__in: lookupId
    }

    if (models) {
      query.model__in = models.join(',')
    }

    if (entityIds) {
      query.entity_id__in = entityIds.join(',')
    }

    if (status) {
      query.status_exact__in = status.join(',')
    }
    if (isTradable) {
      query.is_tradable = '1'
    }
    if (includedEntityId) {
      query.entity_id = includedEntityId
    }
    if (isAccountSpecificNot) {
      query.is_account_specific__not = 'true'
    }

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value.length) {
          query[SECURITY_SEARCH_FILTER_PARAMS_KEYS[key]] = value.join(',')
        }
      }
    }

    if (isPortfolioInstrument) {
      query.is_portfolio_instrument = 'true'
    }

    return super._get<IApiListResponse<ISearchResult>>('/', {query})
  }
}
