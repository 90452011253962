import React from 'react'

import {useApi, useApiQuery} from 'fairlight'

import UploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import PersonIcon from '@material-ui/icons/Person'

import {UserAccountProfilesEndpoints} from '@d1g1t/api/endpoints'
import {IProfilePhotoRetrieve} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {MIME_TYPE} from '@d1g1t/lib/constants'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {confirm} from '@d1g1t/shared/components/confirmation'
import {Avatar} from '@d1g1t/shared/components/mui/avatar'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFileUploader} from '@d1g1t/shared/wrappers/file-uploader'
import {useExternalProfile} from '@d1g1t/shared/wrappers/user-external-profile/hook'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {Flex} from '../flex'
import {IconButton} from '../mui/icon-button'
import {MenuItem} from '../mui/menu-item'
import {Tooltip} from '../mui/tooltip'
import {H6, Text} from '../typography'
import {useStyles} from './use-styles'

export interface IUserAvatarProps {
  /**
   * When passed, shows the photo/avatar of a different user than the logged in user.
   * Upload/remove options are only offered when this prop is passed.
   */
  userIdOverride?: string
  /**
   * Passed by investor app, uses the `/me` endpoint response instead of profiles.
   * TODO: this should probably use the external profile response?
   */
  externalProfile?: boolean
  /**
   * Wraps `Avatar` in `MenuItem` and adds name + email.
   */
  menuItem?: boolean
  /**
   * Removes the right margins/paddings.
   */
  noRightMargin?: boolean
  /**
   * Sets style for "grey avatar" used in investor app.
   */
  grey?: boolean
  /**
   * Changes the height/width of the photo/avatar.
   */
  sizeOverride?: number
  /**
   * Changes the font size of the avatar.
   */
  fontSizeOverride?: number
}

/**
 * Renders the `Avatar` with logged in user initials, or the uploaded photo.
 */
export const UserAvatar: React.FC<IUserAvatarProps> = React.forwardRef(
  (
    {
      menuItem = false,
      grey,
      sizeOverride,
      fontSizeOverride,
      userIdOverride,
      noRightMargin,
      externalProfile
    },
    ref
  ) => {
    const classes = useStyles()
    const api = useApi()
    const {showSnackbar} = useSnackbar()
    const {handleUnexpectedError} = useErrorHandler()

    const [externalProf] = useExternalProfile()

    // Use logged-in user's profile ID if `props.userIdOverride` is not passed
    const [{user: loggedInUser}, loggedInUserActions] = useUserProfile()

    // NOTE: this whole component should render based on this profile's data
    const profileId = userIdOverride || extractIdFromUrl(loggedInUser?.profile)
    const [userProfileData, userProfileActions] = useApiQuery(
      profileId && UserAccountProfilesEndpoints.findById(profileId),
      {
        useErrorBoundary: false,
        fetchPolicy: 'cache-first'
      }
    )

    const [uploadingPhoto, openPhotosFileUploader] = useFileUploader({
      mimeTypes: [
        MIME_TYPE.jpg,
        MIME_TYPE.jpeg,
        MIME_TYPE.webp,
        MIME_TYPE.gif,
        MIME_TYPE.png,
        MIME_TYPE.tiff,
        MIME_TYPE.bmp
      ],
      upload: (selectedFiles) => {
        const data = new FormData()
        data.append('photo_file', selectedFiles[0])
        return api.request(
          UserAccountProfilesEndpoints.profilePhotoUpload(userIdOverride, data)
        )
      },
      onSuccess: () => {
        userProfileActions.refetch()
        loggedInUserActions.refetchUserProfile()
      }
    })

    const handleDeletePhoto = async () => {
      try {
        const confirmation = confirm({
          title: 'Delete Photo',
          content: 'Do you want to delete the photo?',
          confirmLabel: 'Delete'
        })
        if (!(await confirmation)) {
          return
        }
        await api.request(
          UserAccountProfilesEndpoints.profilePhotodelete(userIdOverride)
        )
        userProfileActions.refetch()
        loggedInUserActions.refetchUserProfile()
        showSnackbar({
          variant: 'success',
          message: 'Deleted photo'
        })
      } catch (error) {
        handleUnexpectedError(error)
      }
    }

    const profile = userProfileData.data || externalProf

    const hasPhotos =
      profile &&
      profile.profilePhoto &&
      profile.profilePhoto.normalPhotoUrl &&
      profile.profilePhoto.thumbnailPhotoUrl

    const user = profile?.user || externalProf?.user

    if (!user) {
      return null
    }

    const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
    const fullName = `${user.firstName} ${user.lastName}`
    const initialsOrPersonIcon = initials || (
      <PersonIcon style={{height: sizeOverride, width: sizeOverride}} />
    )

    if (menuItem) {
      return (
        <MenuItem
          className={
            menuItem ? classes.avatarWrapperWithBorder : classes.avatarWrapper
          }
          divider
        >
          <Flex>
            {hasPhotos ? (
              <PhotoAvatar
                photos={profile.profilePhoto}
                noRightMargin={noRightMargin}
              />
            ) : (
              <Avatar
                className={classNames(
                  classes.avatarCircleBackground,
                  !noRightMargin
                    ? classes.avatarCircleMenuItemRightMargin
                    : null
                )}
              >
                {initialsOrPersonIcon}
              </Avatar>
            )}
          </Flex>
          <Flex column>
            <Text>{fullName}</Text>
            <H6>{user.email}</H6>
          </Flex>
        </MenuItem>
      )
    }

    return (
      <>
        {hasPhotos ? (
          <PhotoAvatar
            sizeOverride={sizeOverride}
            noRightMargin={noRightMargin}
            photos={profile.profilePhoto}
          />
        ) : (
          <Avatar
            className={
              grey
                ? classes.avatarGreyCircleBackground
                : classes.avatarCircleBackground
            }
            style={{
              height: sizeOverride,
              width: sizeOverride,
              fontSize: fontSizeOverride
            }}
          >
            {initialsOrPersonIcon}
          </Avatar>
        )}
        <Flex row alignCenter>
          {userIdOverride && profile.profilePhoto?.editable !== false && (
            <Tooltip title='Upload photo'>
              <IconButton
                disabled={uploadingPhoto}
                onClick={openPhotosFileUploader}
              >
                <UploadIcon />
              </IconButton>
            </Tooltip>
          )}
          {userIdOverride && hasPhotos && profile.profilePhoto.editable && (
            <Tooltip title='Delete photo'>
              <IconButton disabled={uploadingPhoto} onClick={handleDeletePhoto}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Flex>
      </>
    )
  }
)

const PhotoAvatar: React.FC<{
  photos: IProfilePhotoRetrieve
  sizeOverride?: number
  noRightMargin?: boolean
}> = (props) => {
  const classes = useStyles()
  return (
    <Avatar
      src={
        props.sizeOverride > 50
          ? props.photos.normalPhotoUrl
          : props.photos.thumbnailPhotoUrl
      }
      className={classNames(
        classes.avatarCircleBackground,
        !props.noRightMargin ? classes.avatarCircleMenuItemRightMargin : null
      )}
      style={{
        height: props.sizeOverride ?? undefined,
        width: props.sizeOverride ?? undefined
      }}
    />
  )
}
