import {initReactI18next} from 'react-i18next'

import i18n from 'i18next'

import ENGLISH_TRANSLATIONS from './translations/en'
import FRENCH_TRANSLATIONS from './translations/fr'

const D1G1T_INVESTOR_I18N_RESOURCES = {
  fr: {
    translation: FRENCH_TRANSLATIONS
  },
  en: {
    translation: ENGLISH_TRANSLATIONS
  }
}

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources: D1G1T_INVESTOR_I18N_RESOURCES,
  saveMissing: true,

  missingKeyHandler: (lng, ns, key) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.debug(
        `Missing a key: "${key}". Override language to use: "${lng}". Override namespaces (string or array):"${ns}"`
      )
    }
  }
})

export default i18n
