import React, {useEffect, useRef, useState} from 'react'
import * as ReactDOM from 'react-dom'

import BackArrowIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Clear'

import {classNames} from '@d1g1t/lib/class-names'
import {useDomEventCallback, useToggleState} from '@d1g1t/lib/hooks'

import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {H3} from '@d1g1t/shared/components/typography'

import {Button} from '../mui/button'

import * as css from './style.scss'

export interface IModalProps {
  modalContentClass?: string
  title: string | JSX.Element
  open: boolean
  fullscreen?: boolean
  offWhiteBackground?: boolean
  noMinHeight?: boolean
  narrow?: boolean
  wide?: boolean
  xwide?: boolean
  fullScreenWide?: boolean
  headerClass?: string
  onOpen?: React.MouseEventHandler<Element>
  onClose?(event?: React.MouseEvent<Element> | KeyboardEvent)
  onBack?(event?: React.MouseEvent<Element> | KeyboardEvent)
}

interface IModalActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  alignCenter?: boolean
}

export const ModalActions: React.FC<IModalActionsProps> =
  function ModalActions({children, className, alignCenter, ...other}) {
    return (
      <div
        className={classNames(
          css.modalActions,
          className,
          alignCenter && css.alignCenter
        )}
        {...other}
      >
        {children}
      </div>
    )
  }

interface IModalContentProps extends React.HTMLAttributes<HTMLDivElement> {
  allowOverflow?: boolean
  noMinHeight?: boolean
  noBottomPadding?: boolean
  noBottomMargin?: boolean
  noPadding?: boolean
  permaBottomBorder?: boolean
  fullHeight?: boolean
  /**
   * Full height modal that has action bar on buttom
   */
  fullHeightActionsBar?: boolean
  darkerBackground?: boolean
}

export const ModalContent: React.FC<IModalContentProps> =
  function ModalContent({
    className,
    allowOverflow,
    noPadding,
    noBottomPadding,
    noBottomMargin,
    permaBottomBorder,
    fullHeight,
    fullHeightActionsBar,
    darkerBackground,
    ...props
  }) {
    const el = useRef<HTMLDivElement>(null)
    const updateRequest = useRef<number>(null)

    const [overflowed, setOverflowed] = useState(false)

    const isOverflowed = () => {
      if (el.current) {
        return el.current.offsetHeight < el.current.scrollHeight
      }
    }

    const updateOverflow = () => {
      updateRequest.current = window.requestAnimationFrame(() => {
        setOverflowed(isOverflowed())
      })
    }
    const updateOverflowCbRef = useRef(updateOverflow)
    updateOverflowCbRef.current = updateOverflow

    useEffect(() => {
      if (allowOverflow) {
        return
      }

      const updateOverflowCb = () => {
        updateOverflowCbRef.current()
      }
      updateOverflow()
      window.addEventListener('resize', updateOverflowCb)

      return () => {
        window.cancelAnimationFrame(updateRequest.current)
        if (allowOverflow) {
          return
        }

        window.removeEventListener('resize', updateOverflowCb)
      }
    }, [])

    const getEl = (node) => {
      el.current = node
    }

    return (
      <div
        className={classNames(css.content, className, {
          [css.overflow]: overflowed,
          [css.overflowAuto]: !allowOverflow,
          [css.noPadding]: noPadding,
          [css.noBottomPadding]: noBottomPadding,
          [css.noBottomMargin]: noBottomMargin,
          [css.permaBottomBorder]: permaBottomBorder,
          [css.fullHeight]: fullHeight,
          [css.fullHeightActionsBar]: fullHeightActionsBar,
          [css.darkerBackground]: darkerBackground
        })}
        ref={getEl}
        {...props}
      />
    )
  }

const portalRoot = document.createElement('div')

document.body.appendChild(portalRoot)

interface IPortalProps {
  disableBackgroundScroll?: boolean
}

export const Portal: React.FC<IPortalProps> = function Portal(props) {
  const mountEl = useRef<Element>(document.createElement('div'))

  const [portalRootInDom, togglePortalRootInDom] = useToggleState(false)

  useEffect(() => {
    portalRoot.appendChild(mountEl.current)

    if (props.disableBackgroundScroll) {
      document.body.classList.add(css.noScroll)
    }

    togglePortalRootInDom()

    return () => {
      portalRoot.removeChild(mountEl.current)

      if (props.disableBackgroundScroll) {
        document.body.classList.remove(css.noScroll)
      }
    }
  }, [])

  /**
   * Prevent rendering the children until element is in the DOM.
   * This is necessary to allow `autoFocus` elements to
   *
   * More here: https://reactjs.org/docs/portals.html#event-bubbling-through-portals
   */
  if (!portalRootInDom) {
    return null
  }

  return ReactDOM.createPortal(props.children, mountEl.current)
}

export const Modal: React.FC<IModalProps> = function Modal({
  fullscreen = false,
  ...props
}) {
  const contentEl = useRef<Element>(null)
  const overlayEl = useRef<Element>(null)
  const [contentMouseDownFirst, toggleContentMouseDownFirst] =
    useToggleState(false)

  useDomEventCallback(window, 'keydown', (event) => {
    if (event.keyCode === 27 && props.open) {
      close(event)
    }
  })

  const close = (event: React.MouseEvent<Element> | KeyboardEvent) => {
    if (props.onClose) {
      props.onClose(event)
    }
  }

  const handleClick = (event: React.MouseEvent<Element>) => {
    if (event.currentTarget !== overlayEl.current || contentMouseDownFirst) {
      event.stopPropagation()

      toggleContentMouseDownFirst()

      return
    }

    close(event)
  }

  const handleCloseButtonClick = (event: React.MouseEvent<Element>) => {
    close(event)
  }

  const title = (
    <Flex
      justifySpaceBetween
      alignCenter
      shrink={0}
      className={classNames(css.header, props.headerClass)}
    >
      <Flex alignCenter grow>
        {props.onBack && (
          <Button
            primary
            contained
            small
            spaceRight
            noMinWidth
            onClick={props.onBack}
            data-testid='back-modal-button'
          >
            <BackArrowIcon fontSize='small' />
          </Button>
        )}
        {typeof props.title === 'string' ? <H3>{props.title}</H3> : props.title}
      </Flex>
      {props.onClose && (
        <IconButton
          onClick={handleCloseButtonClick}
          data-testid='close-modal-button'
        >
          <CloseIcon />
        </IconButton>
      )}
    </Flex>
  )

  const contentRef = (node) => {
    contentEl.current = node
  }

  const content = (
    <div
      className={classNames(css.modalContent, props.modalContentClass, {
        [css.fullscreen]: fullscreen,
        [css.offWhiteBackground]: props.offWhiteBackground,
        [css.noMinHeight]: props.noMinHeight,
        [css.narrow]: props.narrow,
        [css.wide]: props.wide,
        [css.xwide]: props.xwide,
        [css.fullScreenWide]: props.fullScreenWide
      })}
      ref={contentRef}
      onClick={handleClick}
      onMouseDown={toggleContentMouseDownFirst}
    >
      {title}
      {props.children}
    </div>
  )

  const overlayRef = (node) => {
    overlayEl.current = node
  }

  const modal = (
    <div className={css.modal}>
      <div className={css.modalOverlay} onClick={handleClick} ref={overlayRef}>
        <div className={css.contentWrapper}>{content}</div>
      </div>
    </div>
  )

  if (!props.open) {
    return null
  }

  return (
    <Portal key='portal' disableBackgroundScroll>
      {modal}
    </Portal>
  )
}
