import React from 'react'
import {useTranslation} from 'react-i18next'

import {classNames} from '@d1g1t/lib/class-names'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import * as css from './style.scss'

export interface IFlexProps extends React.HTMLAttributes<HTMLDivElement> {
  justifySpaceBetween?: boolean
  justifySpaceAround?: boolean
  justifyFlexEnd?: boolean
  justifyFlexStart?: boolean
  justifyCenter?: boolean
  alignCenter?: boolean
  alignEnd?: boolean
  alignStart?: boolean
  /**
   * Proxy for justify-space-between, align-center, and 56px height.
   * Used at the top of cards for controls and titles
   */
  toolbar?: boolean
  /**
   * Proxy for justify-space-between, align-center, and 80px height.
   * Used at the top of each page with a title by the `PageTitleBar` component.
   */
  toolbarWithSettings?: boolean

  fullHeight?: boolean
  fullHeightWithFilter?: boolean
  fullWidth?: boolean
  wrap?: boolean
  wrapReverse?: boolean
  gridRow?: boolean
  gridItem?: boolean
  grow?: number | boolean
  shrink?: number | boolean
  minWidth?: number
  maxWidth?: number
  minHeight?: number
  reverse?: boolean
  row?: boolean
  column?: boolean
  stretch?: boolean
  noPadding?: boolean
  negativeGridMargin?: boolean
  gridMargin?: boolean
  flexBasis?: string | number
  inlineFlex?: boolean
  pointer?: boolean
  ref?: React.Ref<HTMLDivElement>
}

const getDirectionClass = (isRow: boolean, isReversed: boolean) => {
  if (isRow) {
    return isReversed ? css.rowReverse : null
  }

  return isReversed ? css.columnReverse : css.column
}

export const Flex: React.SFC<IFlexProps> = React.forwardRef(
  (
    {
      className,
      justifySpaceBetween,
      justifySpaceAround,
      justifyFlexEnd,
      justifyFlexStart,
      justifyCenter,
      alignCenter,
      alignEnd,
      alignStart,
      toolbar,
      toolbarWithSettings,
      fullHeight,
      fullHeightWithFilter,
      fullWidth,
      wrap,
      wrapReverse,
      gridRow,
      gridItem,
      style,
      grow = 0,
      shrink = 1,
      reverse,
      row,
      column,
      stretch,
      noPadding,
      minWidth,
      maxWidth,
      minHeight,
      negativeGridMargin,
      gridMargin,
      flexBasis,
      inlineFlex,
      pointer,
      ...props
    },
    ref
  ) => {
    const {t} = useTranslation()
    return (
      <div
        ref={ref}
        className={classNames(
          css.flex,
          className,
          getDirectionClass(!column || row, !!reverse),
          {
            [css.inlineFlex]: inlineFlex,
            [css.toolbar]: toolbar,
            [css.toolbarWithSettings]: toolbarWithSettings,
            [css.spaceBetween]: justifySpaceBetween,
            [css.spaceAround]: justifySpaceAround,
            [css.flexEnd]: justifyFlexEnd,
            [css.flexStart]: justifyFlexStart,
            [css.center]: justifyCenter,
            [css.alignCenter]: alignCenter,
            [css.alignEnd]: alignEnd,
            [css.alignStart]: alignStart,
            [css.fullHeight]: fullHeight,
            [css.fullWidth]: fullWidth,
            [css.fullHeightWithFilter]: fullHeightWithFilter,
            [css.wrap]: wrap,
            [css.wrapReverse]: wrapReverse,
            [css.grid]: gridRow,
            [css.gridItem]: gridItem && !row,
            [css.stretch]: stretch,
            [css.noPadding]: noPadding,
            [css.negativeGridMargin]: negativeGridMargin,
            [css.gridMargin]: gridMargin,
            [css.pointer]: pointer
          }
        )}
        style={{
          flexGrow: Number(grow),
          flexShrink: Number(shrink),
          ...(Number.isFinite(minWidth) ? {minWidth} : null),
          ...(Number.isFinite(maxWidth) ? {maxWidth} : null),
          ...(Number.isFinite(minHeight) ? {minHeight} : null),
          ...(flexBasis ? {flexBasis} : null),
          ...style
        }}
        {...props}
      >
        {onlyTranslateStrings(t, props.children)}
      </div>
    )
  }
)

Flex.displayName = 'Flex'

export const FlexGridRow: React.SFC<IFlexProps> = (props) => (
  <Flex gridRow {...props} />
)

interface IFlexGridItemProps extends IFlexProps {
  col: string
  offset?: string
}

export const FlexGridItem: React.SFC<IFlexGridItemProps> = ({
  col,
  offset,
  ...props
}) => {
  const columns = col.split('/')
  const columnsPercent = (Number(columns[0]) / Number(columns[1])) * 100

  let offsetPercent: number = null
  if (offset) {
    const [offsetNumerator, offsetDenominator] = offset.split('/')
    offsetPercent = (Number(offsetNumerator) / Number(offsetDenominator)) * 100
  }

  return (
    <Flex
      gridItem
      style={{
        flexBasis: `${columnsPercent}%`,
        ...(offsetPercent && {
          [props.reverse ? 'marginRight' : 'marginLeft']: `${offsetPercent}%`
        })
      }}
      {...props}
    />
  )
}

export interface IFlexChildProps extends React.HTMLAttributes<HTMLDivElement> {
  order?: number
  grow?: number | boolean
  shrink?: number | boolean
  basis?:
    | number
    | string
    | 'auto'
    | 'fill'
    | 'max-content'
    | 'min-content'
    | 'fit-content'
    | 'content'
  alignSelf?: 'auto' | 'start' | 'end' | 'center' | 'baseline' | 'stretch'
  justifySelf?: 'auto' | 'start' | 'end' | 'center' | 'baseline' | 'stretch'
  minWidth?: number
  maxWidth?: number
  gridMargin?: boolean
  minHeight?: number
}

export const FlexChild: React.SFC<IFlexChildProps> = ({
  order = 0,
  grow = 1,
  shrink = 1,
  basis = 'auto',
  alignSelf = 'auto',
  justifySelf = 'auto',
  minWidth,
  maxWidth,
  minHeight,
  gridMargin,
  className,
  style,
  ...props
}) => (
  <div
    className={classNames(className, {
      [css.gridMargin]: gridMargin
    })}
    style={{
      order,
      justifySelf,
      alignSelf,
      flexGrow: Number(grow),
      flexShrink: Number(shrink),
      flexBasis: basis,
      ...(Number.isFinite(minWidth) ? {minWidth} : null),
      ...(Number.isFinite(maxWidth) ? {maxWidth} : null),
      ...(Number.isFinite(minHeight) ? {minHeight} : null),
      ...style
    }}
    {...props}
  />
)

FlexChild.displayName = 'FlexChild'
