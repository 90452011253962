import {IInstrumentSubStrategy} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class InstrumentSubStrategyEndpoints extends BaseRestEndpoints {
  static basePath = '/instrument-sub-strategies'

  static list() {
    return super._list<IApiListResponse<IInstrumentSubStrategy>>()
  }
}
