import {ITransactionType} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class TransactionTypeEndpoints extends BaseRestEndpoints {
  static basePath = '/transaction-types/visible'

  // Language param is a hack to use different cached data for different languages
  static list(params?: {language: string}) {
    return super._list<IApiListResponse<ITransactionType>>(params)
  }
}
export class TransactionTypeInvestorEndpoints extends BaseRestEndpoints {
  static basePath = '/transaction-types/investor'

  // Language param is a hack to use different cached data for different languages
  static list(params?: {language: string}) {
    return super._list<IApiListResponse<string>>(params)
  }
}
