import {call, getContext, put, takeEvery} from 'redux-saga/effects'

import {Api} from 'fairlight'
import {getType} from 'typesafe-actions'

import {GlobalSettingsEndpoints} from '@d1g1t/api/endpoints'

import {errorHandlerActions} from '../error-handler'
import {actions} from './actions'
import {matchDefaultSettingsShape} from './lib'

export function* requestGlobalSettings(
  action: ReturnType<typeof actions.requestGlobalSettings>
) {
  const api: Api = yield getContext('api')
  const request = GlobalSettingsEndpoints.findById(action.meta.id)
  if (api.requestInProgress(request)) {
    return
  }

  try {
    const response = yield call(api.request, request)
    yield put(
      actions.setGlobalSettings(
        action.meta.id,
        matchDefaultSettingsShape(action.payload, response)
      )
    )
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
    yield put(
      errorHandlerActions.handleError({
        error,
        snackbarMessage:
          'An unexpected error occurred while retrieving global settings.'
      })
    )
  }
}

export function* updateGlobalSettings(
  action: ReturnType<typeof actions.updateGlobalSettings>
) {
  const api: Api = yield getContext('api')
  try {
    yield call(
      api.request,
      GlobalSettingsEndpoints.update(
        action.meta.id,
        action.payload,
        action.meta.searchParam
      )
    )
  } catch (error) {
    yield put(
      errorHandlerActions.handleError({
        error,
        snackbarMessage:
          'An unexpected error occurred while saving global settings.'
      })
    )
  }
}

export function* globalSettingsSagas() {
  yield takeEvery(getType(actions.requestGlobalSettings), requestGlobalSettings)
  yield takeEvery(getType(actions.updateGlobalSettings), updateGlobalSettings)
}
