import {ApiParam} from 'fairlight'

import {IAccountsUser} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class UserAccountUserEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/users'

  static findById(id: ApiParam) {
    return super._findById<IAccountsUser>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IAccountsUser>) {
    return super._partialUpdate<IAccountsUser>(id, body)
  }
}
