import {isEmpty, isNil, isObject} from 'lodash'

import {GlobalSettings} from './typings'

export const mergeSettings = (
  currentSettings: GlobalSettings,
  nextSettings: GlobalSettings
): GlobalSettings => {
  if (!nextSettings || Array.isArray(nextSettings)) {
    return nextSettings
  }

  return {
    ...currentSettings,
    ...nextSettings
  }
}

export const matchDefaultSettingsShape = (
  defaultSettings: GlobalSettings,
  nextSettings: GlobalSettings
): GlobalSettings => {
  if (!nextSettings) {
    return defaultSettings
  }

  if (Array.isArray(defaultSettings) && !Array.isArray(nextSettings)) {
    return defaultSettings
  }

  if (Array.isArray(defaultSettings) && Array.isArray(nextSettings)) {
    return nextSettings
  }

  const settings: Dictionary = {}

  for (const key in defaultSettings) {
    if (
      typeof defaultSettings[key] === 'object' &&
      !isNil(defaultSettings[key])
    ) {
      settings[key] = matchDefaultSettingsShape(
        defaultSettings[key],
        nextSettings[key]
      )

      continue
    }

    const isObj = isObject(nextSettings[key])

    settings[key] =
      (isObj && isEmpty(nextSettings[key])) ||
      (!isObj && isNil(nextSettings[key]))
        ? defaultSettings[key]
        : nextSettings[key]
  }

  return settings
}
