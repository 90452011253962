import {createAction} from 'typesafe-actions'

import {DEFAULT_AUTO_HIDE_DURATION} from './constants'
import {IShowSnackbarParams, ISnackbarParams} from './typings'

export const snackbarActions = {
  show: createAction('SNACKBAR/SHOW', (params: IShowSnackbarParams) => {
    return {
      key: params.key || `${new Date().getTime()}`,
      autoHideDuration: params.autoHideDuration || DEFAULT_AUTO_HIDE_DURATION,
      ...params
    }
  })<ISnackbarParams>(),
  close: createAction('SNACKBAR/CLOSE')()
}
