import {ICorporationBeneficialOwner} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class PeopleCorporationBeneficialOwnerEndpoints extends BaseRestEndpoints {
  static basePath = '/people/corporation-beneficial-owners'

  static create(body: ICorporationBeneficialOwner[]) {
    return super._create<IApiListResponse<ICorporationBeneficialOwner>>(body)
  }

  static destroy(body: Pick<ICorporationBeneficialOwner, 'url'>[]) {
    return super._destroy('', body)
  }
}
