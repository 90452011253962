import {ApiParam} from 'fairlight'

import {IBillEntityInfo} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'

export interface IEntityInfosRequestBody {
  pagination?: IPaginationOptions
}
export class EntityInfosEndpoints extends BaseRestEndpoints {
  static basePath = '/fees/bill-properties'

  static chart(body: IEntityInfosRequestBody) {
    return super._chart(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static create(body: IBillEntityInfo) {
    return super._create<IBillEntityInfo>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IBillEntityInfo>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IBillEntityInfo>) {
    return super._partialUpdate<IBillEntityInfo>(id, body)
  }
}
