import {EXTERNALPROFILE_DATE_FORMAT} from '@d1g1t/api/models'

import {AVAILABLE_LANGUAGES} from './typings'

export const SETTINGS_ID = 'localization-settings'

/**
 * Languages available for translation in investor app. Also offered as a
 * dropdown in advisor app\'s contact edit page.
 */
export const AVAILABLE_LANGUAGES_OPTIONS: IValueLabelOption<AVAILABLE_LANGUAGES>[] =
  [
    {
      value: AVAILABLE_LANGUAGES.ENGLISH,
      label: 'English'
    },
    {
      value: AVAILABLE_LANGUAGES.FRENCH,
      label: 'Français'
    }
  ]

export enum LOGIN_PAGE_TRANSLATION_KEYS_SHARED {
  AN_UNEXPECTED_WHILE_CREATING_THE_PASSWORD = 'An unexpected error occurred while creating the password.',
  PROVIDE_DESTINATION_MESSAGE = 'Select how you would like to receive your authentication code',
  AUTHENTICATION_DESTINATION_IS_REQUIRED = 'A delivery method is required',
  AUTHENTICATION_CODE_IS_REQUIRED = 'Authentication code is required',
  BACK_TO_SIGN_IN = 'Back to Sign In',
  CHECK_YOUR_EMAIL_FOR_INSTRUCTIONS = 'Check your email for instructions on how to reset your password.',
  CREATE_A_NEW_PASSWORD = 'Create a new password',
  CREATE_A_PASSWORD = 'Create a Password',
  CREDENTIALS_ERROR = 'Unable to log in with provided credentials.',
  D1G1T_ONLY_TOOLTIP = 'd1g1t only: this link is hidden in non-d1g1t envs. Manually navigate to /login/all to access it.',
  EMAIL_ADDRESS = 'Email address',
  EMAIL_ADDRESS_IS_REQUIRED = 'Email address is required',
  EMAIL_ADDRESS_MUST_BE_A_VALID_EMAIL = 'Email Address must be a valid email',
  ENTER_THE_AUTHENTICATION_CODE_TOP = 'A text message with a code has been sent to your mobile phone number.',
  ENTER_THE_AUTHENTICATION_CODE_BOTTOM = 'Please enter the code.',
  FORGOT_PASSWORD = 'Forgot password?',
  GO_TO_NORMAL_LOGIN_PAGE = 'Go to normal login page.',
  INTERNAL = 'Internal',
  INVALID_VERIFICATION_CODE = 'You have entered an invalid verification code. A new verification code has been sent to your phone.',
  LOG_IN = 'Log In',
  MFA_CODE = 'MFA Code',
  MOBILE_PHONE_NUMBER = 'Mobile Phone Number',
  MOBILE_PHONE_NUMBER_COUNTRY_CODE = 'Please include country code: +1 for US/Canada, +44 for UK, etc.',
  MUST_BE_A_VALID_PHONE_NUMBER = 'Must be a valid phone number',
  NEW_PASSWORD = 'New Password',
  NEW_PASSWORD_IS_A_REQUIRED_FIELD = 'New Password is a required field',
  NEW_PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS = 'New Password must be at least 16 characters',
  NEW_PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS = 'New Password must be at least 8 characters',
  PASSWORD = 'Password',
  PASSWORD_IS_REQUIRED = 'Password is required',
  PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS = 'Password must be at least 16 characters',
  PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS = 'Password must be at least 8 characters',
  PASSWORDS_DO_NOT_MATCH = 'Passwords do not match',
  PHONE_NUMBER = 'Phone number',
  RE_TYPE_PASSWORD = 'Re-type Password',
  RE_TYPE_PASSWORD_IS_A_REQUIRED_FIELD = 'Re-type Password is a required field',
  RE_TYPE_YOUR_PASSWORD = 'Re-type your password',
  RESET_PASSWORD = 'Reset Password',
  SET_PASSWORD = 'SET PASSWORD',
  SHOW_HIDDEN_AUTH_METHODS = 'Show hidden auth methods',
  SHOWING_ALL_LOGIN_METHODS = 'Showing all login methods, dismiss to go to normal login page.',
  YOURE_ALL_SET = "You're All Set!",
  AN_UNEXPECTED_ERROR_ON_RESET_PASSWORD = 'An unexpected error occurred while requesting to reset your password.',
  TWILIO_MESSAGE = 'By providing your phone number, you are consenting to receive messages as part of the Multi-Factor Authentication service.'
}

export const AVAILABLE_DATE_FORMAT_OPTIONS: IValueLabelOption<string>[] = [
  {
    value: EXTERNALPROFILE_DATE_FORMAT.YMD,
    label: 'YYYY-MM-DD'
  },
  {
    value: EXTERNALPROFILE_DATE_FORMAT.MDY,
    label: 'MM-DD-YYYY'
  },
  {
    value: EXTERNALPROFILE_DATE_FORMAT.DMY,
    label: 'DD-MM-YYYY'
  }
]

export enum LOCALIZATION_PREFERENCE_KEYS {
  LANGUAGE = 'language',
  DATE_FORMAT = 'dateFormat'
}

export const LOCALIZATION_PREFERENCE_TITLE = {
  [LOCALIZATION_PREFERENCE_KEYS.LANGUAGE]: 'Changed language',
  [LOCALIZATION_PREFERENCE_KEYS.DATE_FORMAT]: 'Changed date format'
}
