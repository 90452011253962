import {deprecated} from 'typesafe-actions'

import {GlobalSettings} from './typings'

const {createAction} = deprecated

export const actions = {
  /**
   * Request initial data for ID, has no effect if data is already loaded
   * or set to `defaultValue`
   */
  requestGlobalSettings: createAction(
    '@GLOBAL-SETTINGS/REQUEST_GLOBAL_SETTINGS',
    (action) => {
      return (id: string, defaultData: GlobalSettings) =>
        action(defaultData, {id})
    }
  ),
  /**
   * Sets the loading value to false without setting `defaultValue`
   */
  error: createAction('@GLOBAL-SETTINGS/ERROR', (action) => {
    return (id: string, error: Error) => action(error, {id})
  }),
  /**
   * Sets the loading value to false and updates the stored value, does not
   * do any merging in the reducer
   */
  setGlobalSettings: createAction(
    '@GLOBAL-SETTINGS/SET_GLOBAL_SETTINGS',
    (action) => {
      return (id: string, data: GlobalSettings) => action(data, {id})
    }
  ),
  /**
   * Does not have any effect on the state, kicks off a background task to
   * update the value stored on the server
   */
  updateGlobalSettings: createAction(
    '@GLOBAL-SETTINGS/UPDATE_GLOBAL_SETTINGS',
    (action) => {
      return (id: string, data: GlobalSettings, searchParam = {}) =>
        action(data, {id, searchParam})
    }
  )
}
