import {IInvestmentHierarchyStructure} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class InvestmentStructureEndpoints extends BaseRestEndpoints {
  static basePath = '/strategies/investment-structures'

  static list() {
    return super._list<IApiListResponse<IInvestmentHierarchyStructure>>()
  }
}
