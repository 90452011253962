import {IChartTable} from '@d1g1t/api/models'

import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'

import {BaseEndpoints} from './base'

interface IAccountMandateChartRequestBody {
  control: {
    investmentMandateId: string
  }
  settings: ISettings
}

export class AccountMandateEndpoints extends BaseEndpoints {
  static basePath = '/account-mandates'

  static chart(body: IAccountMandateChartRequestBody) {
    return super._post<IChartTable>('', {body, extraKey: JSON.stringify(body)})
  }
}
