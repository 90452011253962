import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {snackbarActions} from './actions'
import {IShowSnackbarParams} from './typings'

interface IUseSnackbarReturnType {
  showSnackbar(params: IShowSnackbarParams): void
}

export function useSnackbar(): IUseSnackbarReturnType {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  return {
    showSnackbar: (params: IShowSnackbarParams) =>
      dispatch(
        snackbarActions.show({...params, message: t(params.message as string)})
      )
  }
}
