export enum ERROR_MESSAGE {
  networkError = 'There seems to be a problem with your internet connection.',
  internalServerError = 'A server error occurred. Please try again later.',
  badGateway = 'Could not reach d1g1t servers. Please try again later.',
  serviceUnavailble = 'The server is unavailable. Please try again later.',

  maintenance = 'Calculations are currently disabled for maintenance. Please try again later.',

  forbidden = 'The requested content could not be found.',
  notFound = 'The requested resource could not be found.',

  entityNotFound = 'The selected entity could not be found, possibly due to permissions.',

  unexpectedError = 'An unexpected error occurred. Please try again later.'
}

/**
 * Used to display errors to the logged in users.
 *
 * ErrorBoundary will render error text based on
 * provided `CustomDisplayedError.message` property.
 */
export class CustomDisplayedError extends Error {}

/**
 * ErrorBoundary will catch these and show pre-defined
 * permission error message.
 *
 * @see ERROR_MESSAGE.permissionNotFound
 */
export class EntityNotFound extends Error {}

export class HandledBoundaryError extends Error {}

export interface IErrorFallbackProps {
  error?: Error
  onReset?(): void
}

export interface IErrorBoundaryState {
  error: Nullable<Error>
  handlerDidRun: boolean
}

export interface IErrorBoundaryProps {
  /**
   * Content to render
   */
  children: React.ReactNode
  /**
   * If an error has occurred, and this `resetId` changes
   * on a future render, the error boundary will be 'reset'
   * and the children will attempt to render again.
   *
   * `resetId` can be a string, or it can be a boolean or a number,
   * that is cast to a string.
   * E.g. 1 \>  `${1}` or true \>  `${true}`
   *
   * When the ErrorBoundary should not be reset, use resetId='no-reset'.
   */
  resetId: 'no-reset' | string
  /**
   * If specified, will render this instead of `children`
   * once an error occurs.
   */
  fallback?: React.ReactElement<IErrorFallbackProps>
  /**
   * If provided, will be called to handle the error.
   *
   * If this returns a new error, the error will be considered
   * 'handled'. Neither the original, nor handled error will be reported.
   * The fallback will be rendered with the new error returned by this function.
   *
   * If this doesn't return a new error, the error will be
   * reported.
   */
  handler?(error: Error): Nullable<HandledBoundaryError>
  __testing__?: boolean
}
