import React from 'react'
import {
  Link as ReactRouterLink,
  LinkProps,
  NavLink,
  NavLinkProps,
  Router
} from 'react-router-dom'

import ListItem, {ListItemProps} from '@material-ui/core/ListItem'

import {classNames} from '@d1g1t/lib/class-names'
import {history} from '@d1g1t/lib/history'

import {Button, IButtonProps} from '@d1g1t/shared/components/mui/button'
import {
  IconButton,
  IIconButtonProps
} from '@d1g1t/shared/components/mui/icon-button'
import {ITypographyOptions} from '@d1g1t/shared/components/typography'

import * as css from './style.scss'

export interface ILinkProps
  extends Omit<ITypographyOptions, 'action'>,
    Omit<LinkProps, 'referrerPolicy'> {
  underline?: boolean
  noUnderline?: boolean
}

const LinkWrapper = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    if (props.to) {
      return <ReactRouterLink innerRef={ref} {...props} />
    }

    // @ts-ignore
    return <a ref={ref} {...props} /> // eslint-disable-line jsx-a11y/anchor-has-content
  }
)

export const RouterLink = React.forwardRef<HTMLAnchorElement, ILinkProps>(
  (
    {
      className,
      light,
      semiBold,
      bold,
      italics,
      noMargin,
      noBottomMargin,
      noTopMargin,
      active,
      underline,
      noUnderline,
      ...props
    },
    ref
  ) => (
    <LinkWrapper
      ref={ref}
      className={classNames(css.hyperLink, className, {
        [css.light]: light,
        [css.semiBold]: semiBold,
        [css.bold]: bold,
        [css.noMargin]: noMargin,
        [css.active]: active,
        [css.underline]: underline,
        [css.noUnderline]: noUnderline
      })}
      {...props}
    />
  )
)

export const RouterWrappedLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    return (
      <Router history={history}>
        <LinkWrapper ref={ref} {...props} />
      </Router>
    )
  }
)

export const RouterWrappedNavLink = React.forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>((props, ref) => {
  return (
    <Router history={history}>
      <NavLink
        // Disabled due to limitation in react-router-dom
        // https://github.com/ReactTraining/react-router/issues/6056
        // @ts-ignore
        innerRef={ref}
        {...props}
      />
    </Router>
  )
})

// Ignoring type issues for all the MUI wrappers because of an issue with
// `component` prop
// https://github.com/mui-org/material-ui/issues/15827

export const ListItemLink = React.forwardRef<
  HTMLAnchorElement,
  ListItemProps & LinkProps
>((props, ref) => {
  return (
    // @ts-ignore
    <ListItem ref={ref} component={RouterWrappedLink as any} {...props} />
  )
})

export const ListItemNavLink = React.forwardRef<
  HTMLAnchorElement,
  ListItemProps & NavLinkProps
>((props, ref) => {
  return (
    // @ts-ignore
    <ListItem ref={ref} component={RouterWrappedNavLink as any} {...props} />
  )
})

export const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  IButtonProps & Omit<LinkProps, 'color' | 'type'>
>((props, ref) => {
  return (
    // @ts-ignore
    <Button ref={ref} component={RouterWrappedLink as any} {...props} />
  )
})

export const IconButtonLink = React.forwardRef<
  HTMLAnchorElement,
  IIconButtonProps & LinkProps
>((props, ref) => {
  return (
    // @ts-ignore
    <IconButton ref={ref} component={RouterWrappedLink as any} {...props} />
  )
})
