import {ApiParam} from 'fairlight'

import {
  IChartTable,
  IInvestmentMandate,
  IInvestmentMandateGetSingle
} from '@d1g1t/api/models'
import {IControl} from '@d1g1t/typings/general'

import {IMetricRequestSelection} from '@d1g1t/lib/metrics'

import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

interface IMandatesTrackAllocationRequestBody {
  /**
   * Expects client or household entityId
   */
  entityId: string
  metrics?: {
    selected: IMetricRequestSelection[]
  }
  currency: string
  asOfDate: string
}

interface ITradeLocationStatusResponse {
  pendingCountUnderFirm: number
}

export class PeopleInvestmentMandateEndpoints extends BaseRestEndpoints {
  static basePath = '/people/investment-mandates'

  static list(query: {
    clientEntityId: string
    date: string
    entityId?: string
    visibleToClient?: boolean
  }) {
    return super._list<IApiListResponse<IInvestmentMandateGetSingle>>({
      entity_id: query.entityId,
      client__entity_id: query.clientEntityId,
      visible_to_client: query.visibleToClient,
      as_of_date: query.date
    })
  }

  static create(body: IInvestmentMandate, date: string) {
    return super._post<IInvestmentMandate>('', {
      body,
      query: {as_of_date: date}
    })
  }

  static findById(id: ApiParam, date: string) {
    return super._get<IInvestmentMandateGetSingle>(`/${id}`, {
      query: {as_of_date: date}
    })
  }

  static partialUpdate(
    id: ApiParam,
    body: DeepPartial<IInvestmentMandateGetSingle>,
    date: string
  ) {
    return super._patch<IInvestmentMandateGetSingle>(`/${id}`, {
      body,
      query: {as_of_date: date}
    })
  }

  static destroy(id: ApiParam, date: string) {
    return super._delete(`/${id}`, {query: {as_of_date: date}})
  }

  static chart(body: {
    control: IControl
    settings: ISettings
    options: {singleResult?: boolean}
  }) {
    return super._chart(body)
  }

  static resetGuidelines(mandateId: string) {
    return super._post(`${super._singlePath(mandateId)}/reset-guidelines`, {
      body: {}
    })
  }

  static resetRiskConstraints(mandateId: string) {
    return super._post(
      `${super._singlePath(mandateId)}/reset-risk-constraints`,
      {
        body: {}
      }
    )
  }

  static trackAllocations(body: IMandatesTrackAllocationRequestBody) {
    return super._post<IChartTable>('/track-allocations/', {
      body,
      extraKey: JSON.stringify(body)
    })
  }

  static tradeLocationStatus() {
    return super._get<ITradeLocationStatusResponse>('/trade-location-status')
  }

  static clearRebalanceQueue() {
    return super._post('/clear-rebalance-queue/')
  }

  static clearLocationQueue() {
    return super._post('/clear-location-queue/')
  }
}
