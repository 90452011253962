import {ApiParam} from 'fairlight'

import {CALCULATION_CODES, IChartTable, ITradeDraft} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {ICalculationRequestBody, monkeyPatchRequestBody} from './calculation'

interface ICreateOrdersRequest {
  /**
   * UUIDs of "trade_draft_entity_id", "account_entity_id",
   * "mandate_entity_id", "household_entity_id", "client_entity_id".
   */
  entityIds: string[]
}

interface ICreateAllOrderRequest extends ICalculationRequestBody {
  calcCode:
    | CALCULATION_CODES.TRACK_ALLOCATIONS_ACCOUNTS
    | CALCULATION_CODES.TRACK_ALLOCATIONS_MANDATES
}

export interface IAccountsResponse {
  accountsSucceeded: number
  accountsFailed: number
  tradesSucceeded: number
  tradesFailed: number
}

interface IRecommendedOrdersSelectedEntities {
  accounts?: string[]
  mandates?: string[]
}

interface IRecommendedOrdersRequest {
  asOfDate: string
  selectedEntities: IRecommendedOrdersSelectedEntities
}

interface IRecommendedOrdersAllRequest {
  asOfDate: string
  calcCode: CALCULATION_CODES
  calcParams: ICalculationRequestBody
  pagination: IPaginationOptions
}

interface IRecommendTradesBulkDeleteRequest {
  /**
   * List of trade draft urls to delete
   */
  tradeDrafts: string[]
}

export class TradingDraftsEndpoints extends BaseRestEndpoints {
  static basePath = '/trading/drafts'

  static chart(body: IRecommendedOrdersRequest) {
    return super._post<IChartTable>('/chart', {body})
  }

  static chartAll(body: IRecommendedOrdersAllRequest) {
    const calcParams = monkeyPatchRequestBody(body.calcCode, body.calcParams)

    const {pagination} = monkeyPatchRequestBody(body.calcCode, {
      pagination: body.pagination
    })

    return super._post<IChartTable>('/chart-all', {
      body: {...body, calcParams, pagination}
    })
  }

  static createOrders(body: ICreateOrdersRequest) {
    return super._post<IAccountsResponse>('/create-orders', {body})
  }

  static createAllOrders(body: ICreateAllOrderRequest) {
    return super._post('/create-orders-all', {body})
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<ITradeDraft>) {
    return super._partialUpdate(id, body)
  }

  static bulkDelete(body: IRecommendTradesBulkDeleteRequest) {
    return super._delete('', {body})
  }
}
