import {IDateRange} from '@d1g1t/lib/date-range'

import {UI_DATE_RANGES} from './typings'

/**
 * Given the widget and the page level date ranges, returns the page date
 * range if "Page Period" is the widget option.
 */
export const mergeWidgetAndPageDateRanges = (
  widgetDateRange: IDateRange,
  pageDateRange: IDateRange
): IDateRange => {
  if (widgetDateRange.value === UI_DATE_RANGES.PAGE_PERIOD) {
    return pageDateRange
  }
  return widgetDateRange
}
