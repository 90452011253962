import React from 'react'

import MuiFormControlLabel, {FormControlLabelProps} from '@material-ui/core/FormControlLabel'

import {Text} from '@d1g1t/shared/components/typography'

export interface IFormControlLabelProps extends FormControlLabelProps {}

export const FormControlLabel: React.FC<IFormControlLabelProps> = ({
  label,
  ...props
}) => <MuiFormControlLabel label={<Text>{label}</Text>} {...props} />
