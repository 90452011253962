import {IPrimedDate, PRIMEDDATE_PAGE} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class PrimedDatesEndpoints extends BaseRestEndpoints {
  static basePath = '/primed-dates'

  static list(query: {page: PRIMEDDATE_PAGE}) {
    return super._list<IApiListResponse<IPrimedDate>>({
      ...query,
      limit: 365
    })
  }
}
