import {compact, flatten, isObject, isString, map} from 'lodash'

type classArgs = string | [string, boolean] | {[name: string]: boolean}

export function classNames(...args: classArgs[]): string {
  return compact(
    flatten(
      args.map((className) => {
        if (isString(className)) {
          return className
        }

        if (Array.isArray(className)) {
          return className[1] ? className[0] : null
        }

        if (isObject(className)) {
          return map(className, (val, key) => (val ? key : null))
        }

        return null
      })
    )
  ).join(' ')
}
