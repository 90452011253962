import {
  call,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery
} from 'redux-saga/effects'

import delay from '@redux-saga/delay-p'
import {format} from 'date-fns'
import {Api, ApiError} from 'fairlight'
import FileSaver from 'file-saver'
import produce from 'immer'
import {isEmpty, sortBy} from 'lodash'
import {ActionType, getType} from 'typesafe-actions'

import {
  CalculationEndpoints,
  dashifyCalculationCode,
  GroupingCriteriaEndpoints,
  IApiListResponse,
  ICalculationRequestBody
} from '@d1g1t/api/endpoints'
import {CALCULATION_CODES, IChartTable, IGrouping} from '@d1g1t/api/models'
import {GROUPING_CRITERIA_SLUG} from '@d1g1t/api/models/slugs'
import {IControl} from '@d1g1t/typings/general'

import {MIME_TYPE} from '@d1g1t/lib/constants'
import {mapMetricsToRequest} from '@d1g1t/lib/metrics'
import {pickFromObj} from '@d1g1t/lib/pick-from-obj'
import {
  camelizeChartTable,
  StandardResponse
} from '@d1g1t/lib/standard-response'

import {IDisplayData} from '@d1g1t/shared/containers/view-options/components/display-options/typings'
import {
  applyItemsForLoadedRange,
  generateFullTextSearch,
  generatePaginationFilter,
  IRangeToLoad
} from '@d1g1t/shared/wrappers/chart-paginator/lib'

import {actions} from './actions'
import {
  NON_CALC_PAGINATED_ENDPOINTS,
  TREND_CALCULATION_CODES
} from './constants'
import {createBoundGetter} from './getters'
import {
  getCalculationId,
  getOverridePaginatedEndpointRequest,
  isChartTableData,
  parseCalculationResponse
} from './lib'
import {
  ICalculationParams,
  IDomainSlice,
  IRequestGroups,
  IRequestMetrics,
  IRequestOptions,
  RequestGroupingCriterion
} from './typings'

export const DEFAULT_CONTROL: IControl = {
  filter: {}
}

function* fetchGroupingsFromSlugs(slugs: GROUPING_CRITERIA_SLUG[]) {
  const api: Api = yield getContext('api')

  const groupings: IApiListResponse<IGrouping> = yield call(
    api.request,
    GroupingCriteriaEndpoints.list(),
    {fetchPolicy: 'cache-first'}
  )

  const availableGroupings = groupings.results.filter(
    (grouping) => grouping.isVisible
  )

  return slugs.map((group) =>
    availableGroupings.find(
      (availableGrouping) => group === availableGrouping.slug
    )
  )
}

export function* getCalculationGroups(groups?: GROUPING_CRITERIA_SLUG[]) {
  const api: Api = yield getContext('api')

  if (!groups || groups.length === 0) {
    return {
      selected: []
    }
  }

  const selectedGroupings: IGrouping[] = yield call(
    fetchGroupingsFromSlugs,
    groups
  )

  return {
    selected: selectedGroupings.map(
      (grouping, order) =>
        ({
          order,
          groupingCriterion: api.buildUrl(
            GroupingCriteriaEndpoints.pathToResource(grouping.slug)
          )
        } as RequestGroupingCriterion)
    )
  }
}

function constructRequestMetrics(
  payload: ICalculationParams,
  {reorderMetrics = false}: {reorderMetrics: boolean}
): IRequestMetrics {
  const selected = (() => {
    if (!isEmpty(payload.metricViews)) {
      return mapMetricsToRequest(payload.metricViews)
    }
    if (payload.defaultMetrics) {
      return payload.defaultMetrics
    }
    return []
  })()

  const metrics: IRequestMetrics = {
    selected
  }

  if (payload.requiredMetrics) {
    const slugs = metrics.selected.map(({slug}) => slug)
    for (const required of payload.requiredMetrics) {
      if (!slugs.includes(required.slug)) {
        metrics.selected.push(required)
      }
    }
  }

  if (reorderMetrics) {
    metrics.selected = sortBy(metrics.selected, [
      'slug',
      'contributionDimension',
      'contributionDimension2'
    ])
  }

  metrics.selected = metrics.selected.map((metric, order) => ({
    ...metric,
    order
  }))

  return metrics
}

function constructRequestControl(payload: ICalculationParams): IControl {
  if (isEmpty(payload.control)) {
    return DEFAULT_CONTROL
  }

  return payload.control
}

function* contructRequestOptions(payload: ICalculationParams) {
  const options: IRequestOptions = {
    ...payload.options,
    singleResult: !!payload.singleResult
  }

  const booleanOptions: (keyof ICalculationParams)[] = [
    'investmentMandateAccounts',
    'compare',
    'portfolioRebalancing',
    'lookThrough',
    'showBenchmarks',
    'showMultiplePositions'
  ]

  if (!isEmpty(payload.weightDrift)) {
    options.weightDrift = payload.weightDrift
  }

  if (payload.dateRange) {
    options.dateRange = payload.dateRange
  }

  for (const key of booleanOptions) {
    if (typeof payload[key] === 'boolean') {
      options[key] = payload[key]
    }
  }

  if (options.group) {
    const [grouping] = yield call(fetchGroupingsFromSlugs, [options.group])
    options.group = grouping.url as any
  }

  return options
}

interface IRequestBodyOptions {
  /**
   * When `true` metrics will be reordered using a stable sort to avoid
   * creating new calculations for order changes
   * @defaultValue false
   */
  reorderMetrics?: boolean
  /**
   * When set, will apply specific logic for the request type
   * @defaultValue false
   */
  requestType?: 'validationReport' | 'excelExport'
}

interface IContrustRequestBodyIterator {
  next(value?: any): IteratorResult<any>
  return?(value?: any): IteratorResult<ICalculationRequestBody>
  throw?(e?: any): IteratorResult<any>
}

export function* constructRequestBody(
  calculationId: string,
  payload: ICalculationParams,
  {reorderMetrics = false, requestType}: IRequestBodyOptions
): IContrustRequestBodyIterator {
  if (payload.nonCalcPaginatedEndpointCalcRequestBody) {
    const body: ICalculationRequestBody = {
      ...payload.nonCalcPaginatedEndpointCalcRequestBody
    }

    const descending =
      payload.transform.columnSort.sortOrder === SORT_ORDER.DESC

    const columnName = `${descending ? '-' : ''}${
      payload.transform.columnSort.categoryId
    }`

    body.nonCalcPagination = {
      parentPath: 'root',
      offset: 0,
      size: 100, // can be adjusted to any reasonable value
      orderBy: [columnName]
    }

    const getter = createBoundGetter(calculationId)
    const domain: IDomainSlice = yield select(getter as any)

    // `nonCalcPagination` is pagination object outside of `calcParams` in the
    // API request which controls sorting and filtering of non-calc paginated endpoint.
    // `pagination` property will go into `calcParams` and relates to the original table
    // it is sourced from (e.g. pagination filtering from track strategies)
    const nonCalcEndpointAdjustedFilter = generatePaginationFilter(
      domain.response.data,
      {
        columnFilter: payload.transform.columnFilter
      }
    )

    if (!isEmpty(nonCalcEndpointAdjustedFilter)) {
      body.nonCalcPagination.filtering = nonCalcEndpointAdjustedFilter
    }

    return body
  }

  const body: ICalculationRequestBody = {
    options: yield call(contructRequestOptions, payload),
    control: constructRequestControl(payload),
    settings: payload.calculationSettings.data
  }

  if (payload.filter && !isEmpty(payload.filter.items)) {
    body.filter = payload.filter
  }

  if (payload.filterSets && payload.filterSets.length > 0) {
    body.filterSets = payload.filterSets
  }

  if (!TREND_CALCULATION_CODES.includes(payload.calculationCode)) {
    const groups: IRequestGroups = !isEmpty(payload.groupingCriteria)
      ? {
          selected: payload.groupingCriteria.map((viewGrouping) => {
            return pickFromObj(
              viewGrouping,
              'groupingCriterion',
              'order'
            ) as RequestGroupingCriterion
          })
        }
      : yield call(getCalculationGroups, payload.groups)

    body.groups = groups
    body.metrics = constructRequestMetrics(payload, {reorderMetrics})
  }

  // Add displayData if sending exportToExcel request
  if (!reorderMetrics && payload.transform) {
    body.displayData = {
      showCurrentDataOnly: payload.transform.showCurrentDataOnly,
      showInternalTransactions: payload.transform.showInternalTransactions,
      hideEmptyRows: payload.transform.hideEmptyRows,
      hideEmptyNodes: payload.transform.hideEmptyNodes,
      showPendingTransactions: payload.transform.showPendingTransactions
    } as IDisplayData
  }

  if (requestType === 'validationReport') {
    body.options.needValidationReport = true
  }

  if (
    requestType === 'excelExport' &&
    typeof payload.weightDrift === 'number'
  ) {
    body.options.weightDrift = payload.weightDrift
  }

  if (payload.transform) {
    // We should be able to only send this in 5.0, even in case of pagination
    if (payload.transform.columnSort) {
      let columnName = payload.transform.columnSort.categoryId
      if (payload.transform.columnSort.sortOrder === SORT_ORDER.DESC) {
        columnName = `-${columnName}`
      }
      body.options.orderBy = [columnName]
    }
  }

  if (payload.loadDataIncrementally) {
    body.pagination = {
      parentPath: 'root',
      offset: 0,
      size: 100 // can be adjusted to any reasonable value
    }

    if (payload.transform) {
      if (payload.transform.columnSort) {
        // NOTE: should be able to drop this in 5.0 to only send `body.options.order_by`
        let columnName = payload.transform.columnSort.categoryId
        if (payload.transform.columnSort.sortOrder === SORT_ORDER.DESC) {
          columnName = `-${columnName}`
        }
        body.pagination.orderBy = [columnName]
      }

      let getter = createBoundGetter(calculationId)
      let domain: IDomainSlice = yield select(getter as any)
      if (!domain.response.data) {
        // response.data is undefined, most likely calculationId did not have control, remove it and try again
        const {control, ...payloadWithoutControl} = payload
        getter = createBoundGetter(getCalculationId(payloadWithoutControl))
        domain = yield select(getter as any)
      }

      const searchFilter = generateFullTextSearch(domain.response.data, {
        searchString: payload.transform.fullTextFilter
      })

      if (!isEmpty(searchFilter)) {
        body.pagination.search = searchFilter
      }

      const adjustedFilter = generatePaginationFilter(domain.response.data, {
        columnFilter: payload.transform.columnFilter,
        showInternalTransactions: payload.transform.showInternalTransactions,
        showCurrentDataOnly: payload.transform.showCurrentDataOnly,
        hideEmptyNodes: payload.transform.hideEmptyNodes,
        showCancelledTransactions: payload.transform.showCancelledTransactions,
        showPendingTransactions: payload.transform.showPendingTransactions,
        additionalFiltersForCategoryId:
          payload.transform.additionalFiltersForCategoryId
      })

      if (!isEmpty(adjustedFilter)) {
        body.pagination.filtering = adjustedFilter
      }
    }
  }

  return body
}

export function* fetchCalculation(
  code: CALCULATION_CODES,
  requestBody: ICalculationRequestBody,
  nonCalcEndpointPaginatedOverride: NON_CALC_PAGINATED_ENDPOINTS
) {
  const api: Api = yield getContext('api')

  while (true) {
    try {
      const response: IChartTable = yield call(
        api.request,
        nonCalcEndpointPaginatedOverride
          ? getOverridePaginatedEndpointRequest(
              nonCalcEndpointPaginatedOverride,
              code,
              requestBody
            )
          : CalculationEndpoints.calculate(code, requestBody)
      )

      // Do not parse response as it is already camelized
      const calculationData = nonCalcEndpointPaginatedOverride
        ? response
        : parseCalculationResponse(code, response, requestBody)

      if (isChartTableData(code, calculationData)) {
        return new StandardResponse(calculationData)
      }

      return calculationData
    } catch (error) {
      if (error instanceof ApiError && error.status === 202) {
        yield delay(1000)
        continue
      }

      throw error
    }
  }
}

export function* calculationRunner(
  action: ReturnType<typeof actions.initCalculation>
) {
  let requestBody: ICalculationRequestBody
  try {
    requestBody = yield call(
      constructRequestBody,
      action.meta.id,
      action.payload,
      {
        reorderMetrics: true
      }
    )
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
  }

  if (!requestBody) {
    yield put(actions.abortCalculation(action.meta.id))
    return
  }

  yield put(actions.startCalculation(action.meta.id, requestBody))

  try {
    const {response, timeout} = yield race({
      response: call(
        fetchCalculation,
        action.payload.calculationCode,
        requestBody,
        action.payload.nonCalcPaginatedEndpointOverride
      ),
      cancel: take(
        (incoming: ActionType<typeof actions>) =>
          incoming.type === getType(actions.cancelCalculation) &&
          (incoming.meta && incoming.meta.id) === action.meta.id
      ),
      clear: take(
        (incoming: ActionType<typeof actions>) =>
          incoming.type === getType(actions.clearResponseData) &&
          (incoming.meta && incoming.meta.id) === action.meta.id
      ),
      timeout: call(delay, 3e5)
    })

    if (response) {
      yield put(actions.setResult(action.meta.id, response))
    }

    if (timeout) {
      const error =
        timeout instanceof Error
          ? timeout
          : new Error('Calculation request timed out')
      yield put(actions.error(action.meta.id, error))
    }
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
  }
}

export function* exportCalculationToExcel(
  action: ReturnType<typeof actions.exportExcel>
) {
  const api: Api = yield getContext('api')

  let requestBody: ICalculationRequestBody
  try {
    requestBody = yield call(
      constructRequestBody,
      action.meta.id,
      action.payload,
      {
        reorderMetrics: false,
        requestType: 'excelExport'
      }
    )
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
  }

  if (!requestBody) {
    return
  }

  try {
    const fileContent = yield call(
      api.request,
      CalculationEndpoints.getExcel(action.payload.calculationCode, requestBody)
    )

    if (fileContent.type !== MIME_TYPE.excel) {
      throw new Error('Received file is not an excel file')
    }

    FileSaver.saveAs(
      new Blob([fileContent], {
        type: MIME_TYPE.excel
      }),
      `calc-${dashifyCalculationCode(action.payload.calculationCode)}.xlsx`
    )

    yield put(actions.exportExcelComplete(action.meta.id))
  } catch (error) {
    yield put(actions.exportExcelError(action.meta.id, error))
  }
}

export function* exportCalculationValidationReport(
  action: ReturnType<typeof actions.exportValidationReport>
) {
  const api: Api = yield getContext('api')

  let requestBody: ICalculationRequestBody
  try {
    requestBody = yield call(
      constructRequestBody,
      action.meta.id,
      action.payload,
      {
        reorderMetrics: false,
        requestType: 'validationReport'
      }
    )
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
  }

  if (!requestBody) {
    return
  }

  try {
    const fileContent = yield call(
      api.request,
      CalculationEndpoints.getExcel(action.payload.calculationCode, requestBody)
    )

    const filename = `validation-report--${dashifyCalculationCode(
      action.payload.calculationCode
    )}--${format(new Date(), 'yyyy-MM-dd')}.xlsx`

    FileSaver.saveAs(new Blob([fileContent], {type: MIME_TYPE.excel}), filename)

    yield put(actions.exportValidationReportComplete(action.meta.id))
  } catch (error) {
    yield put(actions.exportValidationReportError(action.meta.id, error))
  }
}

export function* kickoffBackgroundCalculation(
  action: ReturnType<typeof actions.kickoffBackgroundCalculation>
) {
  const api: Api = yield getContext('api')

  let requestBody: ICalculationRequestBody
  try {
    requestBody = yield call(
      constructRequestBody,
      action.meta.id,
      action.payload,
      {
        reorderMetrics: true
      }
    )
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
  }

  if (!requestBody) {
    return
  }

  try {
    api.request(
      CalculationEndpoints.calculate(
        action.payload.calculationCode,
        requestBody
      )
    )
  } catch (e) {}
}

/**
 * Saga which fetches an unloaded range and sets the next response items with
 * the filled range
 */
function* fetchRange(
  id: string,
  code: CALCULATION_CODES,
  requestBody: ICalculationRequestBody,
  range: IRangeToLoad,
  nonCalcEndpointPaginatedOverride: NON_CALC_PAGINATED_ENDPOINTS
) {
  const api: Api = yield getContext('api')

  const adjustedRequestBody = produce(requestBody, (draft) => {
    if (nonCalcEndpointPaginatedOverride) {
      draft.nonCalcPagination.parentPath = range.parentPath
      draft.nonCalcPagination.offset = range.startIndex
      draft.nonCalcPagination.size = range.stopIndex - range.startIndex + 1

      return
    }

    draft.pagination.parentPath = range.parentPath
    draft.pagination.offset = range.startIndex
    draft.pagination.size = range.stopIndex - range.startIndex + 1
  })

  try {
    const {rawItemResponse} = yield race({
      rawItemResponse: call(
        api.request,
        nonCalcEndpointPaginatedOverride
          ? getOverridePaginatedEndpointRequest(
              nonCalcEndpointPaginatedOverride,
              code,
              adjustedRequestBody
            )
          : CalculationEndpoints.calculate(code, adjustedRequestBody)
      ),
      cancel: take(
        (incoming: ActionType<typeof actions>) =>
          incoming.type === getType(actions.cancelCalculation) &&
          (incoming.meta && incoming.meta.id) === id
      ),
      clear: take(
        (incoming: ActionType<typeof actions>) =>
          incoming.type === getType(actions.clearResponseData) &&
          (incoming.meta && incoming.meta.id) === id
      )
    })

    if (!rawItemResponse) {
      return
    }

    // Do not parse response as it is already camelized
    const parsedItemResponse = nonCalcEndpointPaginatedOverride
      ? rawItemResponse
      : camelizeChartTable(rawItemResponse)

    // Get the value in the redux store
    const getter = createBoundGetter(id)
    const domain: IDomainSlice = yield select(getter as any)

    const nextItems = applyItemsForLoadedRange(
      domain.response.data.toChartTable().items,
      parsedItemResponse.items,
      range
    )

    const nextResponse = domain.response.data.replaceItems(nextItems)

    yield put(actions.fetchSingleRangeComplete(id, range, nextResponse))
  } catch (error) {
    yield put(actions.fethcSingleRangeFailed(id, range))
    if (__DEVELOPMENT__) {
      console.error(error)
    }
  }
}

/**
 * Saga which forks requests to fetch data for unloaded ranges
 *
 * Called on every `fetchRanges` action
 */
export function* fetchUnloadedRanges(
  action: ReturnType<typeof actions.fetchRanges>
) {
  let requestBody: ICalculationRequestBody
  try {
    requestBody = yield call(
      constructRequestBody,
      action.meta.id,
      action.payload.params,
      {
        reorderMetrics: true
      }
    )
  } catch (error) {
    yield put(actions.error(action.meta.id, error))
  }

  if (!requestBody) {
    yield put(actions.abortCalculation(action.meta.id))
    return
  }

  // Start parallel effects to fetch the data for each unloaded range
  for (const range of action.payload.ranges) {
    yield fork(
      fetchRange,
      action.meta.id,
      action.payload.params.calculationCode,
      requestBody,
      range,
      action.payload.params.nonCalcPaginatedEndpointOverride
    )
  }
}

export function* cphTableSagas() {
  yield takeEvery(getType(actions.initCalculation), calculationRunner)
  yield takeEvery(getType(actions.exportExcel), exportCalculationToExcel)
  yield takeEvery(
    getType(actions.exportValidationReport),
    exportCalculationValidationReport
  )
  yield takeEvery(
    getType(actions.kickoffBackgroundCalculation),
    kickoffBackgroundCalculation
  )
  yield takeEvery(getType(actions.fetchRanges), fetchUnloadedRanges)
}
