import {ApiParam} from 'fairlight'

import {
  IExternalProfile,
  IExternalProfileRelationsResponse,
  ISupportUserProfile
} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class UserAccountExternalProfileEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/external-profiles'

  static list() {
    return super._list<IApiListResponse<IExternalProfile>>()
  }

  static findById(id: ApiParam) {
    return super._findById<IExternalProfile>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IExternalProfile>) {
    return super._partialUpdate<IExternalProfile>(id, body)
  }

  static accountsRelations(entityId: string) {
    return super._get<IExternalProfileRelationsResponse>(
      `${super._singlePath(entityId)}/accounts-relations`
    )
  }

  static supportUsers(id: ApiParam) {
    return super._get<ISupportUserProfile[]>(`/${id}/support-users`)
  }
}
