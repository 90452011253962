import {CHART_VALUE_TYPE, IChartTableCategory} from '@d1g1t/api/models'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {IStandardTableCategory, IStandardTableColumnWidthTypes} from './typings'

export const STANDARD_TABLE_ITEM_PADDING: Omit<
  StandardResponseItem,
  keyof StandardResponseItem
> = {
  expanded: false,
  fullyExpanded: false,
  fullyCollapsed: false,
  group: false,
  level: 0
}

export const STANDARD_TABLE_CATEGORY_PADDING: Omit<
  IStandardTableCategory,
  keyof IChartTableCategory
> = {
  width: 0,
  expanded: false,
  level: 0
}

export enum KNOWN_CATEGORY_IDS {
  NAME = 'name',
  MODEL_ID = 'model_id',
  MODEL_NAME = 'model_name',
  PARENT_MODEL_ID = 'parent_model_id',
  PARENT_MODEL_NAME = 'parent_model_name',
  CLIENT_ID = 'client_id',
  ENTITY_ID = 'entity_id',
  IS_LOCKED = 'is_locked',
  IS_EXCLUDED_FROM_ALLOCATION = 'is_excluded_from_allocation',
  RANK = 'rank'
}

export enum CHECKED_STATE {
  EMPTY,
  PARTIAL,
  CHECKED
}

export const DRAG_ROW_TYPE = 'STANDARD-TABLE-ROW'

export const CHECK_ROW_TYPE = 'STANDARD-TABLE-CHECK-ROW'

export const APPLY_COLUMN_FILTER_BUTTON_COLUMN =
  'APPLY_COLUMN_FILTER_BUTTON_COLUMN'

export const EXPAND_COLOURS = [
  {
    border: '#4dd0e1',
    background: 'rgba(77, 208, 225, 0.1)'
  },
  {
    border: '#64b5f6',
    background: 'rgba(74, 144, 226, 0.1)'
  },
  {
    border: '#bb6bd9',
    background: 'rgba(187, 107, 217, 0.1)'
  },
  {
    border: '#4C6085',
    background: 'rgba(76, 98, 133, 0.1)'
  },
  {
    border: '#397367',
    background: 'rgba(57, 115, 103, 0.1)'
  }
]

export const CLASS_NAME_TOP_RIGHT_GRID = 'class-name-top-right-grid'

export const DEFAULT_COLUMN_WIDTH_TYPES: IStandardTableColumnWidthTypes = {
  [CHART_VALUE_TYPE.BASIS_POINTS]: 120,
  [CHART_VALUE_TYPE.BOOLEAN]: 90,
  [CHART_VALUE_TYPE.DATE]: 100,
  [CHART_VALUE_TYPE.DATETIME]: 160,
  [CHART_VALUE_TYPE.DECIMAL]: 120,
  [CHART_VALUE_TYPE.DECIMAL_LONG]: 130,
  [CHART_VALUE_TYPE.DECIMAL_LONG_LONG]: 135,
  [CHART_VALUE_TYPE.INTEGER]: 100,
  [CHART_VALUE_TYPE.MIX]: 140,
  [CHART_VALUE_TYPE.MONEY_ABBREVIATED]: 90,
  [CHART_VALUE_TYPE.PERCENTAGE]: 100,
  [CHART_VALUE_TYPE.STRING]: 140
}

export enum ROW_STYLE_STATE {
  HIGHLIGHTED = 'highlighted'
}

export const DEFAULT_EXPANDED_HEADER_HEIGHT = 22
export const STICKY_ELEMENT_HEIGHT = 254
