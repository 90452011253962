import {useCallback, useContext, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {shallowEqual, useDispatch, useSelector, useStore} from 'react-redux'

import {useApiQuery} from 'fairlight'
import produce from 'immer'
import {debounce, every, isEmpty} from 'lodash'

import {RuleFilterEndpoints} from '@d1g1t/api/endpoints'

import {useDebouncedValue, usePrevious} from '@d1g1t/lib/hooks'
import {CATEGORY_IDS, StandardResponse} from '@d1g1t/lib/standard-response'

import {mergeWidgetAndPageDateRanges} from '@d1g1t/shared/components/range-selector/lib'
import {IAppliedRuleFilters} from '@d1g1t/shared/containers/rule-filter-modal'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'
import {IItemPageId} from '@d1g1t/shared/containers/standard-table'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {findRangesToLoad} from '@d1g1t/shared/wrappers/chart-paginator/lib'
import {useGlobalFilterContext} from '@d1g1t/shared/wrappers/global-filter'

import {AVAILABLE_LANGUAGES} from '../localization-settings/typings'
import {actions} from './actions'
import {NON_STANDARD_CODES, REORDERABLE_TABLE_CODES} from './constants'
import {CalculationContext} from './context'
import {createBoundGetter, getCalculationKeys, getDomain} from './getters'
import {
  generateCategoryIdForMetricProperties,
  getCalculationId,
  getMetricProperties,
  getUrlAppliedRuleFilters,
  metricPropertiesIsASubset,
  parseCalculationId,
  parseMetricsId,
  replacePagePeriodMetricsWithPageDateRangeMetric,
  validateHookConfig
} from './lib'
import {
  CalculationHookValue,
  ICalculationParams,
  IDomainSlice,
  IUseCalculationDataParams
} from './typings'

const FILTER_DEBOUNCE_DURATION_MS = 500

export function useCalculationData<R extends StandardResponse>(
  config: IUseCalculationDataParams
): CalculationHookValue<R> {
  const {i18n} = useTranslation()
  const [calculationSettings] = useCalculationSettings()
  const [calculationOptions] = useCalculationOptionsOverride()

  const globalFilter = useGlobalFilterContext()
  const calculationContext = useContext(CalculationContext)

  const excludeRuleFilters = (() => {
    if (config.excludeRuleBasedFilters === false) {
      return false
    }

    if (
      calculationContext.disableRuleFilters ||
      config.excludeRuleBasedFilters
    ) {
      return true
    }

    return false
  })()

  const [allRuleFilters] = useApiQuery(
    !excludeRuleFilters && RuleFilterEndpoints.list(),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )
  const selectedEntities = useSelectedEntities()

  const waitingForDeps = !every(config.waitFor)
  const waitingForGlobalFilter = globalFilter && !globalFilter.data
  const isWaiting =
    waitingForDeps ||
    !calculationSettings ||
    !allRuleFilters || // waiting for rules from global-settings
    waitingForGlobalFilter

  const params = produce(config, (draft: ICalculationParams) => {
    if (calculationOptions && draft.dateRange) {
      // Calculation should use the selected page-level date range from context
      draft.dateRange = mergeWidgetAndPageDateRanges(
        draft.dateRange,
        calculationOptions.pageLevelDateRange
      )
    }

    if (calculationOptions && draft.metricViews) {
      draft.metricViews = replacePagePeriodMetricsWithPageDateRangeMetric(
        draft.metricViews,
        calculationOptions.pageLevelDateRange
      )
    }

    draft.calculationSettings = {
      data: calculationSettings
    }

    if (!draft.control) {
      draft.control = {}
    }

    if (globalFilter) {
      draft.control.filter = globalFilter.data
    }

    if (config.loadDataIncrementally || calculationContext.disableCache) {
      draft.useCachedData = false
    }
    if (
      allRuleFilters.data?.results.length > 0 &&
      !config.excludeRuleBasedFilters
    ) {
      const urlAppliedRuleFilters: IAppliedRuleFilters[] =
        !!calculationContext.getUrlAppliedRuleFilters
          ? calculationContext.getUrlAppliedRuleFilters(
              selectedEntities,
              allRuleFilters,
              config.control
            )
          : getUrlAppliedRuleFilters(selectedEntities, allRuleFilters)

      if (urlAppliedRuleFilters) {
        draft.filterSets = []
        for (const appliedRuleFilter of urlAppliedRuleFilters) {
          for (const appliedFilter of appliedRuleFilter.appliedFilters) {
            draft.filterSets.push({
              entities: [appliedRuleFilter.entityId],
              joinOperator: appliedFilter.joinOperator,
              items: appliedFilter.ruleFilterItems
            })
          }
        }
      }
    }

    if (!isEmpty(config.weightDrift)) {
      draft.weightDrift = config.weightDrift
    }
  }) as ICalculationParams

  const calculationId = isWaiting ? null : getCalculationId(params)

  if (__DEVELOPMENT__) {
    useEffect(() => {
      if (!calculationId) {
        return
      }

      validateHookConfig(params)
    }, [calculationId])
  }

  const boundGetter = useMemo(
    () => createBoundGetter(calculationId),
    [calculationId]
  )
  const state: IDomainSlice = useSelector(boundGetter, shallowEqual)
  const dispatch = useDispatch()
  const store = useStore()

  const prevCalculationId = usePrevious(calculationId)
  const prevInstanceCount = usePrevious(state.instanceCount)

  // Effect to sync changes to calculationId
  useEffect(() => {
    const requestData = () => {
      if (!calculationId) {
        return
      }

      dispatch(actions.register(params))

      if (boundGetter(store.getState()).response.loading) {
        return
      }

      if (params.useCachedData && state.response.data) {
        return
      }

      if (
        REORDERABLE_TABLE_CODES.includes(params.calculationCode) &&
        params.useCachedData
      ) {
        const requestedCalculationIdProperties =
          parseCalculationId(calculationId)
        const calculationKeys = getCalculationKeys(store.getState())
        for (const key of calculationKeys) {
          const calculationIdProperties = parseCalculationId(key)
          if (
            calculationIdProperties.calculationCode !== params.calculationCode
          ) {
            continue
          }

          // Metrics did not change
          if (
            calculationIdProperties.metricsId ===
            requestedCalculationIdProperties.metricsId
          ) {
            continue
          }

          // Other properties have changed, must initialize new calculation
          if (
            calculationIdProperties.optionsId !==
            requestedCalculationIdProperties.optionsId
          ) {
            continue
          }

          // At this point we have a key which could be a potential superset of the requested data
          const metricProperties = parseMetricsId(
            calculationIdProperties.metricsId
          )
          const requestedMetricProperties = parseMetricsId(
            requestedCalculationIdProperties.metricsId
          )

          // Check that the stored data contains all the requested metrics
          if (
            !metricPropertiesIsASubset(
              requestedMetricProperties,
              metricProperties
            )
          ) {
            continue
          }

          // At this point there is data in the store that probably already has all the requested data
          const calculationStore = getDomain(store.getState())
          const supersetData = calculationStore[key].response.data
          if (!supersetData) {
            continue
          }

          const nextSortOrder = requestedMetricProperties.map(({slug}) => slug)
          // TODO, prevent kicking off background calculation when PS
          // sends superset data
          dispatch(actions.kickoffBackgroundCalculation(calculationId, params))
          dispatch(
            actions.setResult(
              calculationId,
              supersetData.reorderVisibleCategories(nextSortOrder, {
                discardUnmapped: true
              })
            )
          )

          return
        }
      }

      dispatch(actions.initCalculation(params))
    }

    requestData()

    return () => {
      if (!calculationId) {
        return
      }

      if (prevInstanceCount <= 1) {
        dispatch(actions.cancelCalculation(params))

        return
      }

      dispatch(actions.deregister(params))
    }
  }, [calculationId])

  // Effect to sync changes to properties which require dumping data
  // for paginated responses
  useEffect(() => {
    if (
      !(
        calculationId &&
        calculationId === prevCalculationId &&
        params.loadDataIncrementally
      )
    ) {
      return
    }

    // When sorting changes, clear data and initialize a new calculation
    dispatch(actions.clearResponseData(calculationId))
    dispatch(actions.initCalculation(params))
  }, [
    params.transform?.columnSort?.categoryId,
    params.transform?.columnSort?.sortOrder,
    params.transform?.hideEmptyNodes,
    params.transform?.showCurrentDataOnly,
    params.transform?.showInternalTransactions,
    params.transform?.showCancelledTransactions,
    params.transform?.showPendingTransactions
  ])
  // Effect to sync changes to filters, debounced to avoid excess requests
  const debouncedColumnFilter = useDebouncedValue(
    // When using a column filter stored in recoil it initially starts as
    // `undefined` and then the atom initializes to `EMPTY_OBJECT`. The
    // `debouncedColumnFilter` value will change, this will prevent
    // triggering the `useEffect` below.
    params.transform?.columnFilter ?? EMPTY_OBJECT,
    params.transform?.disableDebounceColumnFilter
      ? 0
      : FILTER_DEBOUNCE_DURATION_MS
  )

  const debouncedFullTextFilter = useDebouncedValue(
    params.transform?.fullTextFilter,
    FILTER_DEBOUNCE_DURATION_MS
  )

  useEffect(() => {
    if (
      !(
        calculationId &&
        calculationId === prevCalculationId &&
        params.loadDataIncrementally
      )
    ) {
      return
    }

    // When column filters change, clear data and initialize a new calculation
    dispatch(actions.clearResponseData(calculationId))
    dispatch(actions.initCalculation(params))
  }, [debouncedColumnFilter, debouncedFullTextFilter])

  const exportExcel = () => {
    dispatch(actions.exportExcel(params))
  }

  const exportValidationReport = () => {
    dispatch(actions.exportValidationReport(params))
  }

  const refresh = useCallback(() => {
    if (state.response.loading) {
      return
    }

    dispatch(actions.initCalculation(params))
  }, [calculationId, state.response.loading])

  const updateData = useCallback(
    (data: StandardResponse) => {
      dispatch(actions.setResult(calculationId, data))
    },
    [calculationId]
  )

  /**
   * Method sent to return value of hook.
   * null if `config.loadDataIncrementally` is not true
   */
  const fetchRanges = useCallback(
    // Debounce this method to avoid creating a backlog of network requests
    // as we are limited to 6 multiplexed requests using XHR.
    debounce((itemPageIds: IItemPageId[]) => {
      if (!config.loadDataIncrementally) {
        return
      }

      // using internal selector instead of `state` to avoid potentially
      // calling this method with stale state when the state is modified
      // but the host component did not render before the debounce is queued.
      const domain = boundGetter(store.getState())

      if (!domain.response.data || domain.response.data.size === 0) {
        return
      }

      const rangesToLoad = findRangesToLoad({
        itemPageIds,
        loadingIndexMap: domain.loadingIndexMap,
        // This minimumBatchSize value was chosen arbitrarily, seems like a decent
        // minimum. Can be adjusted to see if we can improve loading time.
        minimumBatchSize: 50,
        loadedItems: domain.response.data.toChartTable().items
      })

      if (!rangesToLoad.length) {
        return
      }

      dispatch(actions.fetchRanges(calculationId, params, rangesToLoad))

      // 30ms debounce time was chosen from some playing around. Longer times
      // will cause fewer requests and less data loaded that the user has scrolled
      // past. Shorter times will make loading feel faster at the expense of
      // loading more irrelevant data.
    }, 30),
    [
      calculationId,
      boundGetter,
      // Fetch unloaded ranges saga uses sort properties
      params.transform?.columnSort?.sortOrder,
      params.transform?.columnSort?.categoryId,
      // Fetch unloaded ranges saga uses column filter
      params.transform?.columnFilter,
      // Fetch unloaded ranges saga uses full text filter
      params.transform?.fullTextFilter,
      // Filters converted to pagination properties
      params.transform?.showCancelledTransactions,
      params.transform?.showInternalTransactions,
      params.transform?.showPendingTransactions,
      params.transform?.hideEmptyNodes,
      params.transform?.showCurrentDataOnly
    ]
  )

  // Apply front-end transformations to the response
  const response = useMemo(() => {
    if (NON_STANDARD_CODES.includes(config.calculationCode)) {
      return state.response
    }

    if (!state.response.data) {
      return state.response
    }

    let nextResponseData = state.response.data

    // Prevent re-ordering of visible categories, filtering by metric option and
    // updating category names by metric options of metrics that are not there.
    // This is because metrics supplied by calc hook fetching from non-calc paginated
    // endpoint will not be the metrics in the response.
    if (!config.nonCalcPaginatedEndpointOverride) {
      if (
        REORDERABLE_TABLE_CODES.includes(config.calculationCode) &&
        (!isEmpty(config.defaultMetrics) || !isEmpty(params.metricViews))
      ) {
        const selectedCategoryIds = getMetricProperties(
          config.defaultMetrics,
          params.metricViews,
          config.requiredMetrics
        ).map(generateCategoryIdForMetricProperties)

        nextResponseData = nextResponseData.reorderVisibleCategories(
          selectedCategoryIds,
          {discardUnmapped: true}
        )
      }

      if (!isEmpty(params.metricViews)) {
        nextResponseData = nextResponseData.filterByMetricOption(
          params.metricViews
        )
      }

      // Only use category names from api response if language is not english or no language is selected
      if (
        (!i18n.language || i18n.language === AVAILABLE_LANGUAGES.ENGLISH) &&
        REORDERABLE_TABLE_CODES.includes(config.calculationCode) &&
        !isEmpty(params.metricViews)
      ) {
        nextResponseData = nextResponseData.updateCategoryNamesByMetricOptions(
          params.metricViews
        )
      }
    }

    if (!config.transform) {
      return {
        ...state.response,
        data: nextResponseData
      }
    }

    if (config.transform.changeCategoryOptions) {
      nextResponseData = nextResponseData.updateCategoryOptions(
        config.transform.changeCategoryOptions.categoryIdsToChange,
        config.transform.changeCategoryOptions.optionsToChange
      )
    }

    if (
      config.transform.categoryOrderOverride &&
      !isEmpty(config.transform.categoryOrderOverride.orderIds)
    ) {
      nextResponseData = nextResponseData.reorderVisibleCategories(
        config.transform.categoryOrderOverride.orderIds,
        config.transform.categoryOrderOverride.options
      )
    }

    // Transforms after this point are not supported in pagination
    if (params.loadDataIncrementally) {
      return {
        ...state.response,
        data: nextResponseData
      }
    }

    const showCurrentHoldingsOnly =
      !!nextResponseData.findCategoryById(CATEGORY_IDS.IS_ALIVE) &&
      config.transform.showCurrentDataOnly &&
      !!nextResponseData.findCategoryById(CATEGORY_IDS.IS_NODE_ALIVE)

    if (showCurrentHoldingsOnly && !config.transform.hideEmptyNodes) {
      nextResponseData = nextResponseData.filterIsAlive()
    }

    if (showCurrentHoldingsOnly && !!config.transform.hideEmptyNodes) {
      nextResponseData = nextResponseData.filterIsNodeAlive()
    }

    if (config.transform.filterEmptyCategories) {
      const options =
        typeof config.transform.filterEmptyCategories === 'boolean'
          ? undefined
          : config.transform.filterEmptyCategories
      nextResponseData = nextResponseData.filterEmptyCategories(options)
    }

    if (config.transform.consolidateItems) {
      if (typeof config.transform.consolidateItems === 'boolean') {
        nextResponseData = nextResponseData.consolidateItems(
          nextResponseData.firstNumericCategoryId
        )
      } else {
        const valueCategoryId =
          config.transform.consolidateItems.valueCategoryId ||
          nextResponseData.firstNumericCategoryId

        nextResponseData = nextResponseData.consolidateItems(
          valueCategoryId,
          config.transform.consolidateItems.options
        )
      }
    }

    if (config.transform.filterZeroValues) {
      if (typeof config.transform.filterZeroValues === 'boolean') {
        nextResponseData = nextResponseData.filterZeroValues(
          null,
          {},
          {keepFamily: false}
        )
      } else {
        nextResponseData = nextResponseData.filterZeroValues(
          config.transform.filterZeroValues.categoryIds,
          config.transform.filterZeroValues.options,
          {keepFamily: false}
        )
      }
    }

    // Hide empty and zero-value rows
    if (config.transform.hideEmptyRows) {
      nextResponseData = nextResponseData.filterEmptyRows()

      nextResponseData = nextResponseData.filterZeroValues(
        null,
        {setSmallValueThresholdBasedOnValueType: true},
        {keepFamily: false}
      )
    }

    if (config.transform.showInternalTransactions === false) {
      nextResponseData = nextResponseData.filterLineNotInSource()
    }

    // Default is to hide cancelled transactions unless otherwise specified
    // Hide cancelled if column exists and show is false/undefined
    if (
      !!nextResponseData.findCategoryById(
        CATEGORY_IDS.IS_TRANSACTION_CANCELLED
      ) &&
      !config.transform.showCancelledTransactions
    ) {
      nextResponseData = nextResponseData.filterCancelledTransactions()
    }

    if (
      !!nextResponseData.findCategoryById(
        CATEGORY_IDS.IS_PENDING_TRANSACTION
      ) &&
      !config.transform.showPendingTransactions
    ) {
      nextResponseData = nextResponseData.filterPendingTransactions()
    }

    if (config.transform.apply) {
      nextResponseData = config.transform.apply(nextResponseData)
    }

    return {
      ...state.response,
      data: nextResponseData
    }
  }, [
    state.response.loading,
    state.response.error,
    state.response.data,
    params.loadDataIncrementally,
    params.metricViews,
    config.defaultMetrics,
    config.requiredMetrics,
    config.calculationCode,
    config.transform?.filterEmptyCategories,
    config.transform?.categoryOrderOverride,
    config.transform?.apply,
    config.transform?.showCurrentDataOnly,
    config.transform?.hideEmptyNodes,
    config.transform?.filterZeroValues,
    config.transform?.showInternalTransactions,
    config.transform?.showPendingTransactions,
    config.transform?.consolidateItems?.valueCategoryId,
    config.transform?.consolidateItems?.options,
    config.nonCalcPaginatedEndpointOverride
  ])

  // Separating sorting to avoid recomputing other transform when sorting changes
  const sortedResponseItems = useMemo(() => {
    // Local sorting is not supported for paginated data
    if (
      config.loadDataIncrementally ||
      NON_STANDARD_CODES.includes(config.calculationCode) ||
      !response.data ||
      !config.transform
    ) {
      return response
    }
    let nextResponseData = response.data

    if (config.transform.columnSort?.sortOrder) {
      // If no CategoryID sort using first column as default
      const categoryId =
        config.transform.columnSort.categoryId ??
        nextResponseData.categories[0].id
      const sortOrder = config.transform.columnSort.sortOrder

      nextResponseData = produce(
        nextResponseData,
        (draftChart: StandardResponse) => {
          draftChart.applySortTableItems(categoryId, sortOrder)
        }
      )
    }
    return {
      ...response,
      data: nextResponseData
    }
  }, [
    response,
    config.calculationCode,
    config.transform?.columnSort?.categoryId,
    config.transform?.columnSort?.sortOrder
  ])

  // Separate search filters because these change often and should not cause
  // recalculation of other transforms
  const searchFilteredResponse = useMemo(() => {
    // Local search filtering is not supported for paginated data
    if (config.loadDataIncrementally) {
      return sortedResponseItems
    }

    if (NON_STANDARD_CODES.includes(config.calculationCode)) {
      return sortedResponseItems
    }

    if (!sortedResponseItems.data) {
      return sortedResponseItems
    }

    if (!config.transform) {
      return sortedResponseItems
    }

    let nextResponseData = sortedResponseItems.data

    if (config.transform.fullTextFilter) {
      nextResponseData = nextResponseData.fullTextSearchFilter(
        config.transform.fullTextFilter
      )
    }

    if (
      config.transform.categoryTextFilter &&
      config.transform.categoryTextFilter.text
    ) {
      nextResponseData = nextResponseData.categoryTextSearchFilter(
        config.transform.categoryTextFilter.text,
        config.transform.categoryTextFilter.categoryIds
      )
    }

    if (
      config.transform.columnFilter &&
      config.transform.columnFilter !== EMPTY_OBJECT
    ) {
      nextResponseData = nextResponseData.columnFilter(
        config.transform.columnFilter
      )
    }

    if (config.transform.statusFilter) {
      nextResponseData = nextResponseData.statusFilter(
        config.transform.statusFilter
      )
    }

    return {
      ...sortedResponseItems,
      data: nextResponseData
    }
  }, [
    sortedResponseItems,
    config.calculationCode,
    config.transform?.fullTextFilter,
    config.transform?.columnFilter,
    config.transform?.categoryTextFilter?.text,
    config.transform?.categoryTextFilter?.categoryIds,
    config.transform?.statusFilter
  ])

  if (params.useErrorBoundary && searchFilteredResponse.error) {
    // handle error in error boundary
    throw searchFilteredResponse.error
  }

  return [
    searchFilteredResponse as Loadable<R>,
    {
      exportExcel,
      exportValidationReport,
      refresh,
      updateData,
      calculationId,
      fetchRanges,
      calcRequestBody: state.calcRequestBody,
      instanceCount: state.instanceCount
    }
  ]
}

export function useInvalidateCalculation() {
  const store = useStore()
  const dispatch = useDispatch()
  const calculationStore = getDomain(store.getState())
  const calculationKeys = getCalculationKeys(store.getState())

  return {
    invalidateMetric: (slug: string) => {
      for (const calculationKey of calculationKeys) {
        const {metricsId} = parseCalculationId(calculationKey)
        const mappedMetricPropertiesList = parseMetricsId(metricsId)

        if (
          mappedMetricPropertiesList.find((metric) => metric.slug === slug) &&
          calculationStore[calculationKey].response.data
        ) {
          dispatch(actions.invalidateCachedCalculation(calculationKey))
        }
      }
    }
  }
}
