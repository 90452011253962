import {IDocumentSubType} from '@d1g1t/api/models'

import {IApiListResponse} from './'
import {BaseRestEndpoints} from './base'

export class DocumentSubtypeEndpoints extends BaseRestEndpoints {
  static basePath = '/workdocs/document-subtypes'

  static list() {
    return super._list<IApiListResponse<IDocumentSubType>>()
  }
}
