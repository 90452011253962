import {ApiParam} from 'fairlight'

import {
  IBillGroupDetail,
  IBillGroupInvoiceNested,
  IBillGroupInvoicesRemove
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface IBillingGroupsRequestBody {
  pagination?: IPaginationOptions
}

export class BillingGroupsEndpoint extends BaseRestEndpoints {
  static basePath = '/fees/bill-groups'

  static list() {
    return super._list<IApiListResponse<IBillGroupDetail>>()
  }

  static chart(body: IBillingGroupsRequestBody) {
    return super._chart(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static create(body: IBillGroupDetail) {
    return super._create<IBillGroupDetail>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IBillGroupDetail>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IBillGroupDetail>) {
    return super._partialUpdate<IBillGroupDetail>(id, body)
  }

  static postInvoices(id: ApiParam, body: Partial<IBillGroupInvoiceNested[]>) {
    return super._post<IBillGroupInvoiceNested[]>(`/${id}/invoices`, {body})
  }

  static removeInvoices(id: ApiParam, body: IBillGroupInvoicesRemove) {
    return super._post<{deleted: number}>(`/${id}/remove-invoices`, {body})
  }
}
