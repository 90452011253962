import InputBase from '@material-ui/core/InputBase'
import {createStyles, withStyles} from '@material-ui/core/styles'

const styles = createStyles({
  input: {
    border: 'none'
  }
})

export const InlinedInput = withStyles(styles)(InputBase)
