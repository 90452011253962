import {ApiParam} from 'fairlight'

import {IInvestmentStrategyWrite} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class InvestmentStrategiesEndpoints extends BaseRestEndpoints {
  static basePath = '/strategies/investment-strategies'

  static partialUpdate(id: ApiParam, body: Partial<IInvestmentStrategyWrite>) {
    return super._partialUpdate<IInvestmentStrategyWrite>(id, body)
  }

  static create(body: IInvestmentStrategyWrite) {
    return super._create<IInvestmentStrategyWrite>(body)
  }

  static destroy(id: string) {
    return super._destroy(id)
  }
}
