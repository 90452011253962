import {useMemo, useRef} from 'react'

import {deepEqual} from 'fast-equals'

/**
 * Provides the same API as `useMemo`, but the dependency can be any object.
 *
 * Values of the object get deep compared using `fast-equals`
 */
export function useDeepMemo<T extends object>(valueCb: () => T, deps: any): T {
  const depsRef = useRef(deps)

  if (!deepEqual(deps, depsRef.current)) {
    depsRef.current = deps
  }

  return useMemo(valueCb, [depsRef.current])
}
