import escapeStringRegexp from 'escape-string-regexp'
import {isEmpty} from 'lodash'

import {
  CHART_VALUE_TYPE,
  CHARTTABLEOPTIONS_STATUS,
  IChartTableCategory,
  IChartTableItem
} from '@d1g1t/api/models'

import {thresholdValueForDecimals} from '@d1g1t/lib/formatters/number-formatter'
import {
  andOrStringTokenizer,
  LOGICAL_OPERATOR,
  relationalDateExpressionStringParser,
  relationalNumericalExpressionStringParser
} from '@d1g1t/lib/string-parsers'

import {StandardResponse, StandardResponseItem} from './'
import {CATEGORY_IDS} from './constants'
import {findCategoryReference, isNumberCell} from './lib'

export type StandardResponseItemFilter = (
  item: IChartTableItem,
  categories?: IChartTableCategory[]
) => boolean

export interface IFilterItemsOptions {
  /**
   * Flag which includes all children and parent items when set to true
   * @defaultValue true
   */
  keepFamily?: boolean
}

/**
 * Removes `dataItems` which are not included in `categoryIds` from each item
 * in the response
 * @deprecated kept for compatability, to remove categories from a response,
 * just remove them from the `categories`, rendering methods should ignore values
 * not included
 */
export const filterStandardItemsByCategoryIds = (
  items: IChartTableItem[],
  categoryIds: string[]
): IChartTableItem[] =>
  items.map((item) => {
    if (!item) {
      return item
    }

    const data = []
    for (const categoryId of categoryIds) {
      data.push(
        item.data.find((dataItem) => dataItem.categoryId === categoryId)
      )
    }

    if (item.items) {
      return {
        ...item,
        data,
        items: filterStandardItemsByCategoryIds(item.items, categoryIds)
      }
    }

    return {
      ...item,
      data
    }
  })

/**
 * Recursively adds all item ids of items and children in `standardItems`
 * to the `itemsToKeep` set
 */
const subTreeIds = (
  standardItems: StandardResponseItem[],
  itemsToKeep: Set<string>
) => {
  for (const standardItem of standardItems) {
    itemsToKeep.add(standardItem.id)

    if (standardItem.children) {
      subTreeIds(standardItem.children, itemsToKeep)
    }
  }
}

/**
 * Recursively checks and adds item ids of `standardItems` that satisfy
 * the condition of the `itemFilter` along with the item ids of the all
 * their parents and children
 */
const keepFilterItemSet = (
  itemFilter: StandardResponseItemFilter,
  standardItems: StandardResponseItem[],
  categories: IChartTableCategory[],
  itemsToKeep: Set<string>,
  options: IFilterItemsOptions
): void => {
  for (const standardItem of standardItems) {
    if (itemFilter(standardItem.item, categories)) {
      itemsToKeep.add(standardItem.id)

      if (standardItem.children && options.keepFamily) {
        subTreeIds(standardItem.children, itemsToKeep)
      }

      let currentItem = standardItem

      while (currentItem.itemParent && options.keepFamily) {
        currentItem = currentItem.itemParent
        itemsToKeep.add(currentItem.id)
      }

      if (options.keepFamily) {
        continue
      }
    }

    if (standardItem.children) {
      keepFilterItemSet(
        itemFilter,
        standardItem.children,
        categories,
        itemsToKeep,
        options
      )
    }
  }
}

/**
 * Removes items in the tree if their ids are not in the `itemsToKeep` Set
 *
 * NOTE: This method does not keep all children of an item if the children IDs
 * are not included in itemsToKeep. It keeps the parent item to preserve hierarchy.
 */
export const removeItems = (
  itemsToKeep: Set<string>,
  items: IChartTableItem[]
): IChartTableItem[] => {
  const returnItems = []

  for (const item of items) {
    if (!item) {
      returnItems.push(item)
      continue
    }

    if (itemsToKeep.size > 0 && itemsToKeep.has(item.id)) {
      const filteredChildItems = item.items
        ? removeItems(itemsToKeep, item.items)
        : undefined

      returnItems.push({
        ...item,
        items: filteredChildItems
      })
    }
  }

  return returnItems
}

/**
 * Performs a deep-recursive search given a filter function
 * Keeps items where the filter function returns true
 */
export const filterStandardItems = (
  items: IChartTableItem[],
  itemFilter: StandardResponseItemFilter,
  categories: IChartTableCategory[],
  filterOptions: IFilterItemsOptions = {}
): IChartTableItem[] => {
  const options = {
    keepFamily: true,
    ...filterOptions
  }

  const standardResponse = new StandardResponse({items, categories})
  const itemsToKeep = new Set<string>()

  keepFilterItemSet(
    itemFilter,
    standardResponse.allItems(),
    categories,
    itemsToKeep,
    options
  )

  return removeItems(itemsToKeep, items)
}

/**
 * Removes categories in the tree if their ids are not in the `categoriesToKeep` Set
 */
export const removeCategories = (
  categoriesToKeep: Set<string>,
  categories: IChartTableCategory[]
): IChartTableCategory[] => {
  const returnCategories = []

  for (const category of categories) {
    if (!category) {
      returnCategories.push(category)
      continue
    }

    if (categoriesToKeep.size > 0 && categoriesToKeep.has(category.id)) {
      const filteredChildCategories = category.categories
        ? removeCategories(categoriesToKeep, category.categories)
        : undefined

      returnCategories.push({
        ...category,
        categories: filteredChildCategories
      })
    }
  }

  return returnCategories
}

/**
 * Normalizes a string or regexp into regexp
 */
export const regExpFromSearchExpression = (
  searchExpression: string | RegExp
): RegExp => {
  if (typeof searchExpression === 'string') {
    return new RegExp(escapeStringRegexp(searchExpression), 'i')
  }

  return searchExpression
}

/**
 * Creates a filter which searches for text across all values
 */
export const fullTextFilter =
  (searchExpression: Nullable<string | RegExp>): StandardResponseItemFilter =>
  (item) => {
    if (!searchExpression) {
      return true
    }

    const searchRegExp = regExpFromSearchExpression(searchExpression)

    for (const dataItem of item.data) {
      if (String(dataItem.value).match(searchRegExp)) {
        return true
      }
    }

    return false
  }

/**
 *  Creates a filter which keeps items that match all
 *  corresponding categoryId search expressions.
 *  @param columnFilterInputState - object containing
 *  categoryIds for keys and corresponding column
 *  filter input field text as values.
 */
export const columnFilter =
  (
    columnFilterInputState: Dictionary<string | string[]>
  ): StandardResponseItemFilter =>
  (item, categories) => {
    if (isEmpty(columnFilterInputState)) {
      return true
    }

    return item.data.every((dataItem) => {
      const columnSearchStringOrEnumArray =
        columnFilterInputState[dataItem.categoryId]

      if (
        !columnSearchStringOrEnumArray ||
        (Array.isArray(columnSearchStringOrEnumArray) &&
          columnSearchStringOrEnumArray.length === 0)
      ) {
        return true
      }

      const [, category] = findCategoryReference(
        {categories, items: []},
        dataItem.categoryId
      )

      // For column filtering on a number column
      if (isNumberCell(category.valueType as CHART_VALUE_TYPE)) {
        const {tokens, expressionType} = andOrStringTokenizer(
          columnSearchStringOrEnumArray as string
        )

        if (tokens.length === 1 || expressionType === LOGICAL_OPERATOR.AND) {
          return tokens.every((token) =>
            relationalNumericalExpressionStringParser(dataItem.value, token)
          )
        }

        if (expressionType === LOGICAL_OPERATOR.OR) {
          return tokens.some((token) =>
            relationalNumericalExpressionStringParser(dataItem.value, token)
          )
        }
      } else if (CHART_VALUE_TYPE.DATE === category.valueType) {
        const {tokens, expressionType} = andOrStringTokenizer(
          columnSearchStringOrEnumArray as string
        )

        if (tokens.length === 1 || expressionType === LOGICAL_OPERATOR.AND) {
          return tokens.every((token) =>
            relationalDateExpressionStringParser(dataItem.value, token)
          )
        }

        if (expressionType === LOGICAL_OPERATOR.OR) {
          return tokens.some((token) =>
            relationalDateExpressionStringParser(dataItem.value, token)
          )
        }
      }

      if (category.options?.allowedValues) {
        return (columnSearchStringOrEnumArray as any[]).includes(dataItem.key)
      }

      // For column filtering on a text column
      return (columnSearchStringOrEnumArray as string)
        .split(/\s*,\s*/)
        .filter(Boolean)
        .map(regExpFromSearchExpression)
        .some((searchRegExp) => String(dataItem.value).match(searchRegExp))
    })
  }

/**
 * Creates a filter that keeps items containing one of the statuses (OR filter)
 * `statusFilterList` in their investment guideline columns
 */
export const statusFilter = (
  filterList: CHARTTABLEOPTIONS_STATUS[],
  breachMap: Dictionary<CHARTTABLEOPTIONS_STATUS>
): StandardResponseItemFilter => {
  return (item) => {
    if (filterList.length === 0) {
      return false
    }

    return filterList.includes(breachMap[item.id])
  }
}

/**
 * Creates a filter which search for text across categoryIds
 */
export const categoryTextFilter =
  (
    searchExpression: Nullable<string | RegExp>,
    categoryIds: IChartTableCategory['id'][]
  ): StandardResponseItemFilter =>
  (item) => {
    if (!searchExpression) {
      return true
    }

    const searchRegExp = regExpFromSearchExpression(searchExpression)

    for (const dataItem of item.data) {
      if (
        categoryIds.includes(dataItem.categoryId) &&
        String(dataItem.value).match(searchRegExp)
      ) {
        return true
      }
    }

    return false
  }

export interface IZeroValueFilterOptions {
  /**
   * When passed, will remove values which are smaller in absolute value
   */
  filterSmallValuesThreshold?: number

  /**
   * When true, filterSmallValuesThreshold is set based on value type
   */
  setSmallValueThresholdBasedOnValueType?: boolean
}

const decimalCountForValueType = (valueType: CHART_VALUE_TYPE): number => {
  switch (valueType) {
    case CHART_VALUE_TYPE.INTEGER:
      return 0
    case CHART_VALUE_TYPE.DECIMAL_LONG:
      return 4
    case CHART_VALUE_TYPE.DECIMAL_LONG_LONG:
      return 6
    case CHART_VALUE_TYPE.MONEY_ABBREVIATED:
      return 1
    case CHART_VALUE_TYPE.PERCENTAGE:
      return 3
    default:
      return 2
  }
}

/**
 * Filters items where the values of all columns passed is falsey
 */
export const zeroValueFilter =
  (
    categoryIds: string[],
    options: IZeroValueFilterOptions = {}
  ): StandardResponseItemFilter =>
  (item) => {
    for (const dataItem of item.data) {
      if (!categoryIds.includes(dataItem.categoryId)) {
        continue
      }

      const {filterSmallValuesThreshold} = {
        filterSmallValuesThreshold:
          options.setSmallValueThresholdBasedOnValueType
            ? thresholdValueForDecimals(
                decimalCountForValueType(dataItem.valueType as CHART_VALUE_TYPE)
              )
            : 0,
        ...options
      }

      if (filterSmallValuesThreshold && Number.isFinite(dataItem.value)) {
        if (Math.abs(dataItem.value) > filterSmallValuesThreshold) {
          return true
        }
      } else if (!!dataItem.value) {
        return true
      }
    }
    return false
  }

/**
 * Filters items where all values of a row are falsey
 */
export const emptyRowFilter =
  (categoryIds: string[]): StandardResponseItemFilter =>
  (item) => {
    for (const dataItem of item.data) {
      if (!categoryIds.includes(dataItem.categoryId)) {
        continue
      }

      if (!!dataItem.value) {
        return true
      }
    }

    return false
  }

/**
 * Removes item with id `itemId`
 */
export const removeItemByIdFilter = (
  itemId: string
): StandardResponseItemFilter => removeItemsByIdsFilter([itemId])

/**
 * Removes items with ids in `itemIds`
 * @param itemIds - Array of ids to remove from response
 */
export const removeItemsByIdsFilter =
  (itemIds: string[]): StandardResponseItemFilter =>
  (item) => {
    return item && !itemIds.includes(item.id)
  }

/**
 * Removes lines from positions table which are "dead"
 *
 * Keeps lines when `IS_ALIVE` === `true`
 *
 * This only applies to `cph-table` when grouping by position
 */
export const isAliveFilter: StandardResponseItemFilter = (item) =>
  new StandardResponseItem(item).getValue(CATEGORY_IDS.IS_ALIVE)

/**
 * Hide lines and their children from the table where isNodeAlive is false
 *
 * Keeps lines when `IS_NODE_ALIVE` === `true`
 *
 * This only applies when keepDeadValues is false
 */
export const isNodeAliveFilter: StandardResponseItemFilter = (item) =>
  new StandardResponseItem(item).getValue(CATEGORY_IDS.IS_NODE_ALIVE)

/**
 * Removes lines from a transaction log which are in the source; IE removes internally
 * generated transactions which are created for transaction codes which do not map
 * exactly to internal d1g1t transactions
 *
 * This only applies to `log-details`
 */
export const lineNotInSourceFilter: StandardResponseItemFilter = (item) =>
  new StandardResponseItem(item).getValue(CATEGORY_IDS.LINE_IN_SOURCE) === true

/**
 * Removes lines from a transaction log which are marked as cancelled. (Where
 * IS_TRANSACTION_CANCELLED !== true)
 *
 * This only applies to `log-details`
 */
export const removeCancelledItemFilter: StandardResponseItemFilter = (item) =>
  new StandardResponseItem(item).getValue(
    CATEGORY_IDS.IS_TRANSACTION_CANCELLED
  ) !== true

/**
 *
 * @param item - Removes lines from a transaction log which are marked as pending. (Where
 * IS_PENDING_TRANSACTION !== true)
 *
 * This only applies to `log-details`
 */
export const removePendingItemFilter: StandardResponseItemFilter = (item) =>
  new StandardResponseItem(item).getValue(
    CATEGORY_IDS.IS_PENDING_TRANSACTION
  ) !== true

/**
 * Takes a list of filters and creates a new filter which passes if all filters
 * return true
 * @param filters  - the Filters to combine
 */
export const combineFilters = (
  ...filters: StandardResponseItemFilter[]
): StandardResponseItemFilter => {
  if (filters.length === 0) {
    return () => true
  }

  if (filters.length === 1) {
    return filters[0]
  }

  return (...args) => {
    for (const filter of filters) {
      if (filter(...args)) {
        return true
      }
    }

    return false
  }
}
