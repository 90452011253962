import {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {useApiQuery} from 'fairlight'

import {ConfigContext} from '@d1g1t/config/context'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {AuthenticationLocations, loginPath} from '@d1g1t/shared/locations'

import {authActions} from './actions'
import {getters} from './getters'
import {IUseAuthReturnType} from './typings'

export * from './typings'

/**
 * Wrapper around the authentication workflow.
 */
export function useAuth(): IUseAuthReturnType {
  const domain = useSelector(getters)
  const dispatch = useDispatch()

  return {
    returning: domain.returning,
    loggingIn: domain.loggingIn,
    loggingInAwaitMfaDestinationInput: domain.loggingInAwaitMfaDestinationInput,
    loggingInAwaitMfa: domain.loggingInAwaitMfaCode,
    invalidCode: domain.invalidCode,
    mfaToken: domain.mfaToken,
    mfaDestinations: domain.mfaDestinations,
    loginError: domain.loginError,
    loggedIn: !!domain.token,
    invalidCodeErrorMessage: domain.invalidCodeErrorMessage,

    login: (loginRequest: Parameters<typeof authActions.loginRequest>[0]) =>
      dispatch(authActions.loginRequest(loginRequest)),
    loginMfa: (
      otpLoginRequest: Parameters<typeof authActions.loginMfaRequest>[0]
    ) => dispatch(authActions.loginMfaRequest(otpLoginRequest)),
    loginMfaDestination: (
      mfaDestinationReq: Parameters<
        typeof authActions.loginMfaDestinationRequest
      >[0]
    ) => dispatch(authActions.loginMfaDestinationRequest(mfaDestinationReq)),
    loginAzureAd: (
      loginRequest: Parameters<typeof authActions.loginAzureAdRequest>[0]
    ) => dispatch(authActions.loginAzureAdRequest(loginRequest)),
    loginWithJWTToken: (
      loginRequest: Parameters<typeof authActions.loginSamlRequest>
    ) => dispatch(authActions.loginSamlRequest(loginRequest[0])),
    logout: () => dispatch(authActions.logoutRequest())
  }
}

/**
 * Show the login screen if user is logged out.
 *
 * @param redirect - pass as `false` to avoid redirecting back to originating route.
 *  Currently used to prevent redirect back to the `/logout` route.
 */
export function useLoginRedirectIfLoggedOut(redirect = true): {
  redirecting: boolean
} {
  // ASSUMPTION: only advisor app sets this to true
  const {enableAzureRedirect} = useContext(ConfigContext)

  const history = useHistory()
  const {loggedIn} = useAuth()

  const [firmPreconfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  useEffect(() => {
    if (!firmPreconfiguration.data) {
      return
    }

    if (!loggedIn) {
      history.replace(
        enableAzureRedirect && firmPreconfiguration.data.azureAdLoginUrl
          ? AuthenticationLocations.signin()
          : loginPath(redirect)
      )
    }
  }, [loggedIn, firmPreconfiguration.data])

  return {redirecting: !loggedIn}
}
