import {RouteBuilder} from '@d1g1t/lib/url'

export class ClientsLocations extends RouteBuilder {
  static basePath = '/client'

  // Client portfolios
  static portfolios() {
    return super.path('/portfolios')
  }

  // Holdings
  static holdings() {
    return super.path('/holdings')
  }

  // Households
  static households() {
    return super.path('/households')
  }

  // Clients
  static clients() {
    return super.path('/clients')
  }

  // Accounts
  static accounts() {
    return super.path('/manage-accounts')
  }
}
