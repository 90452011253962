import {useCallback, useContext} from 'react'

import {useApi, useApiQuery} from 'fairlight'

import {ConfigContext} from '@d1g1t/config/context'

import {
  AuthEndpoints,
  IUserMeResponse,
  UserAccountProfilesEndpoints
} from '@d1g1t/api/endpoints'
import {
  EXTERNALPROFILE_PREFERRED_LANGUAGE,
  IAccountsProfile
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

/**
 * Gets the user profile for the currently logged in
 * user, and exposes a helper method to refetch it after profile edits.
 */
export const useUserProfile = (): [
  {
    user: IUserMeResponse
    profile: IAccountsProfile
  },
  {refetchUserProfile(): void}
] => {
  const api = useApi()
  const {isAdvisorApp} = useContext(ConfigContext)

  const [userData, userDataActions] = useApiQuery(AuthEndpoints.me(), {
    fetchPolicy: 'cache-first'
  })

  const profileId = extractIdFromUrl(userData.data?.profile)
  const [userProfileData, userProfileActions] = useApiQuery(
    profileId && UserAccountProfilesEndpoints.findById(profileId),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const handleRefetchUserAndProfile = useCallback(() => {
    userDataActions.refetch()
    userProfileActions.refetch()
  }, [userDataActions, userProfileActions])

  // Wait for user, and user + profile when user has profile
  if (!userData.data || (userData.data.profile && !userProfileData.data)) {
    return [{user: null, profile: null}, null]
  }

  if (userData.data?.preferredLanguage) {
    const language = (() => {
      if (
        !isAdvisorApp &&
        EXTERNALPROFILE_PREFERRED_LANGUAGE.FRENCH.includes(
          userData.data.preferredLanguage
        )
      ) {
        return EXTERNALPROFILE_PREFERRED_LANGUAGE.FRENCH
      }

      return EXTERNALPROFILE_PREFERRED_LANGUAGE.ENGLISH
    })()

    api.setDefaultHeader('Language', language)
  }

  return [
    {user: userData.data, profile: userProfileData.data},
    {
      refetchUserProfile: handleRefetchUserAndProfile
    }
  ]
}
