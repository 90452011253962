import {
  DATE_RANGES,
  IUserView,
  METRICGROUP_RELATED_MODEL,
  PERIOD
} from '@d1g1t/api/models'

export const PREFIX = 'VIEW_OPTIONS'

// By default all types except Trade Order, Transaction, Investment Mandate
export const DEFAULT_METRIC_TYPES: METRICGROUP_RELATED_MODEL[] = [
  METRICGROUP_RELATED_MODEL.ACCOUNT,
  METRICGROUP_RELATED_MODEL.CUSTODIAN_ACCOUNT,
  METRICGROUP_RELATED_MODEL.CLIENT,
  METRICGROUP_RELATED_MODEL.INSTRUMENT_POSITION,
  METRICGROUP_RELATED_MODEL.SECURITY,
  METRICGROUP_RELATED_MODEL.QLIB,
  METRICGROUP_RELATED_MODEL.RISK
]

export const DEFAULT_SHOW_LOOK_THROUGH_TOGGLE = false

// For global settings
export const BLANK_VIEW = {
  url: null,
  firm: null,
  tableKey: null,
  name: null,
  order: null,
  groups: null,
  filters: null,
  metrics: null,
  displayData: null,
  isGlobal: null
} as IUserView

export const CUSTOM_METRIC_PREFIX = 'custom_metric'

// Follows pattern of `PERIOD`
export const PAGE_PERIOD_METRIC_SUFFIX = 'page-period'

/**
 * Used by PS in the view metrics' `path` property.
 */
export const PERIOD_SHORTFORMS: Record<
  PERIOD.LASTMONTH | PERIOD.CUSTOM | typeof PAGE_PERIOD_METRIC_SUFFIX,
  string
> = {
  [PERIOD.LASTMONTH]: 'q1mnth',
  [PERIOD.CUSTOM]: 'qcstm',
  [PAGE_PERIOD_METRIC_SUFFIX]: 'qsp'
}

/**
 * `DATE_RANGES` enum is for widget date periods.
 * `PERIOD` enum is for the suffixes of metrics.
 */
export const DATE_RANGE_TO_PERIOD_MAP: Record<DATE_RANGES, PERIOD> = {
  [DATE_RANGES.DAY]: PERIOD.ASOFDAY, // Should not be used, does not exist
  [DATE_RANGES.WEEK]: PERIOD.LASTWEEK,
  [DATE_RANGES.LAST_MONTH]: PERIOD.LASTMONTH,
  [DATE_RANGES.MONTH]: PERIOD.LASTMONTH,
  [DATE_RANGES.LAST_QUARTER]: PERIOD.LASTQUARTER,
  [DATE_RANGES.QUARTER]: PERIOD.LASTQUARTER,
  [DATE_RANGES.LAST_SIX_MONTHS]: PERIOD.LAST6MONTHS,
  [DATE_RANGES.SIX_MONTHS]: PERIOD.LAST6MONTHS,
  [DATE_RANGES.YTD]: PERIOD.YTD,
  [DATE_RANGES.MTD]: PERIOD.MTD,
  [DATE_RANGES.QTD]: PERIOD.QTD,
  [DATE_RANGES.LAST_YEAR]: PERIOD.LASTYEAR,
  [DATE_RANGES.YEAR]: PERIOD.LASTYEAR,
  [DATE_RANGES.TWO_YEARS]: PERIOD.LAST2YEARS,
  [DATE_RANGES.LAST_THREE_YEARS]: PERIOD.LAST3YEARS,
  [DATE_RANGES.THREE_YEARS]: PERIOD.LAST3YEARS,
  [DATE_RANGES.FIVE_YEARS]: PERIOD.LAST5YEARS,
  [DATE_RANGES.SINCE_INCEPTION]: PERIOD.SINCEINCEPTION,
  [DATE_RANGES.SINCE_ACTIVATION]: PERIOD.SINCEACTIVATION,
  [DATE_RANGES.SINCE_DISCRETIONARY]: PERIOD.SINCEDISCRETIONARY,
  [DATE_RANGES.LAST_MONTHLY]: PERIOD.LASTMONTHLY,
  [DATE_RANGES.LAST_QUARTERLY]: PERIOD.LASTQUARTERLY,
  [DATE_RANGES.LAST_ANNUALLY]: PERIOD.LASTANNUALLY,
  [DATE_RANGES.CUSTOM]: PERIOD.CUSTOM
}

export const ALLOWED_INVESTOR_VIEW_TABLE_KEYS = {
  cphMonitorOverviewSingleClient:
    'CALCULATIONS-CPH-TABLE-MONITOR-OVERVIEW-CLIENT',
  lightConeMonitorOverviewSingleClient:
    'LIGHT-CONE-ANALYTICS-MONITOR-OVERVIEW-CLIENT'
}
