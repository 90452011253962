import React, {useCallback, useEffect, useState} from 'react'

import {
  IModalProps,
  Modal,
  ModalActions,
  ModalContent
} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'

export interface IConfirmation {
  /**
   * Title that appears in the modal, passed through to `<Modal />`
   */
  title: IModalProps['title']
  /**
   * CSS class passed though to `<Modal />`
   */
  headerClass?: IModalProps['headerClass']

  /**
   * Any `ReactNode`, if a string type is passed, will be wrapped in `<Text />`
   */
  content: React.ReactNode
  /**
   * Defaults to "OK", otherwise just added as children to the `<Button>`
   */
  confirmLabel?: React.ReactNode
  /**
   * Defaults to "Cancel", otherwise just added as children to the `<Button>`
   */
  cancelLabel?: React.ReactNode
  /**
   * When true, does not render the cancel button and disabled dimissing by
   * escape key / click off the modal
   */
  disableCancel?: boolean
}

enum CONFRIM_EVENTS {
  OPEN = '@confirmation/open',
  RESOLVE = '@confirmation/resolve'
}

/**
 * Call this method anywhere to open a confirmation dialog
 */
export const confirm = (confirmation: IConfirmation): Promise<boolean> => {
  const event = new CustomEvent(CONFRIM_EVENTS.OPEN, {
    detail: confirmation
  })

  window.dispatchEvent(event)

  return new Promise((resolve) => {
    window.addEventListener(
      CONFRIM_EVENTS.RESOLVE,
      (event: CustomEvent<boolean>) => {
        resolve(event.detail)
      },
      {once: true}
    )
  })
}

/**
 * Allows manual closing of the open modal, useful if you want to close
 * a model from a link inside the content, just call this method to `onClick`
 */
export const closeConfirm = (...args: any[]) => {
  dispatchResolve(false)
}

const dispatchResolve = (result: boolean) => {
  const event = new CustomEvent(CONFRIM_EVENTS.RESOLVE, {
    detail: result
  })

  window.dispatchEvent(event)
}

/**
 * This should be placed near the root level of the app.
 * Use `confirm` to open the model from anywhere
 */
export const ConfirmationModal: React.FC = () => {
  const [confirmation, setConfirmation] = useState<IConfirmation>(null)

  useEffect(() => {
    const cbOpen = (event: CustomEvent<IConfirmation>) => {
      setConfirmation(event.detail)
    }
    const cbClose = (event: CustomEvent<boolean>) => {
      setConfirmation(null)
    }

    window.addEventListener(CONFRIM_EVENTS.OPEN, cbOpen)
    window.addEventListener(CONFRIM_EVENTS.RESOLVE, cbClose)

    return () => {
      window.removeEventListener(CONFRIM_EVENTS.OPEN, cbOpen)
      window.removeEventListener(CONFRIM_EVENTS.RESOLVE, cbClose)
    }
  }, [])

  const handleConfirm = useCallback(() => {
    dispatchResolve(true)
  }, [])

  const handleCancel = useCallback(() => {
    dispatchResolve(false)
  }, [])

  return (
    confirmation && (
      <Modal
        noMinHeight
        open
        title={confirmation.title}
        headerClass={confirmation.headerClass}
        onClose={confirmation.disableCancel ? null : handleCancel}
      >
        <ErrorBoundary
          resetId='no-reset'
          fallback={<ModalContentsErrorFallback onClose={handleCancel} />}
        >
          <ModalContent data-testid='confirmation-modal'>
            {typeof confirmation.content === 'string' ? (
              <Text>{confirmation.content}</Text>
            ) : (
              confirmation.content
            )}
          </ModalContent>
          <ModalActions>
            {!confirmation.disableCancel && (
              <>
                <Button data-testid='cancel-button' onClick={handleCancel}>
                  {confirmation.cancelLabel || 'Cancel'}
                </Button>
                <Spacer vertical xxs />
              </>
            )}
            <Button
              data-testid='confirm-button'
              primary
              contained
              onClick={handleConfirm}
            >
              {confirmation.confirmLabel || 'OK'}
            </Button>
          </ModalActions>
        </ErrorBoundary>
      </Modal>
    )
  )
}
