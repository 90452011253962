import {IInvestorProfile} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class PeopleInvestorProfileEndpoints extends BaseRestEndpoints {
  static basePath = '/people/investor-profiles'

  static list(query: {clientEntityId: string}) {
    return super._list<IApiListResponse<IInvestorProfile>>({
      client__entity_id: query.clientEntityId
    })
  }

  static partialUpdate(id: string, body: DeepPartial<IInvestorProfile>) {
    return super._partialUpdate<IInvestorProfile>(id, body)
  }
}
