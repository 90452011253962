import produce from 'immer'
import {capitalize, find, identity, isEmpty, isNil, orderBy} from 'lodash'
import memoizeOne from 'memoize-one'

import {
  CHART_VALUE_TYPE,
  IChartTable,
  IChartTableCategory,
  IChartTableData,
  IChartTableItem,
  IChartTableOptions,
  IViewMetricItem
} from '@d1g1t/api/models'

import {
  categoryIdForMetricItem,
  conditionResultCategoriesFromItems
} from '@d1g1t/lib/metrics'

import {KNOWN_CATEGORY_IDS} from '@d1g1t/shared/containers/standard-table/constants'

import {CATEGORY_IDS, CATEGORY_NAMES} from './constants'
import {
  filterStandardItems,
  filterStandardItemsByCategoryIds,
  StandardResponseItemFilter,
  zeroValueFilter
} from './filters'

export interface IFilterResponseByCategoryIdsOptions {
  /**
   * When false, removes total row from response if it exists
   */
  includeTotal?: boolean
  /**
   * When true, the passed category ids will be excluded;
   * default is to only keep passed category ids
   */
  exclude?: boolean
}

export interface IMapTableToConcentrationOptions {
  /**
   * Which column is used to populate the legend
   */
  nameCategoryId?: string
  /**
   * Override which category is used for weight
   */
  weightCategoryId?: string
  /**
   * Removes 0 values
   */
  filterZeroValues?: boolean
}

export const isTotalRow = (item: IChartTableItem): boolean => {
  if (!item || !item.data) {
    return false
  }

  const totalDataItem = item.data.find(
    (dataItem) => dataItem.categoryId === CATEGORY_IDS.NAME
  )

  return (
    totalDataItem &&
    totalDataItem.value &&
    [String(CATEGORY_NAMES.ALL), String(CATEGORY_NAMES.TOTAL)].includes(
      String(totalDataItem.value).toLowerCase()
    )
  )
}

export const filterResponseByCategoryIds = (
  response: IChartTable,
  categoryIds: string[],
  options?: IFilterResponseByCategoryIdsOptions
): IChartTable => {
  const {includeTotal, exclude} = {
    includeTotal: true,
    exclude: false,
    ...options
  }

  const categories: IChartTableCategory[] = []
  for (const category of response.categories) {
    const included = categoryIds.includes(category.id)

    if (exclude ? !included : included) {
      categories.push(category)
    }
  }

  const items = filterStandardItemsByCategoryIds(
    !includeTotal && isTotalRow(response.items[0])
      ? response.items.slice(1)
      : response.items,
    categories.map(({id}) => id)
  )

  return {categories, items}
}

/**
 * Maps a table response to an `IChartTable` that can be used in
 * `<ConcentractionChart />` or `<DriftChart />`
 *
 * Converts values in the `name` category into strings
 */
export const mapTableToConcentration = (
  table: IChartTable,
  {
    nameCategoryId = CATEGORY_IDS.NAME,
    weightCategoryId = null,
    filterZeroValues = true
  }: IMapTableToConcentrationOptions = {}
): IChartTable => {
  if (!table) {
    return null
  }

  let derivedWeightCategoryId = weightCategoryId
  if (!derivedWeightCategoryId) {
    for (const category of table.categories) {
      if (category.id === CATEGORY_IDS.WEIGHT) {
        derivedWeightCategoryId = category.id
        break
      }
    }
  }

  if (!derivedWeightCategoryId) {
    for (const category of table.categories) {
      if (category.valueType !== CHART_VALUE_TYPE.PERCENTAGE) {
        continue
      }

      derivedWeightCategoryId = category.id
      break
    }
  }

  const filterCategories = [derivedWeightCategoryId]

  let response = filterResponseByCategoryIds(
    table,
    [nameCategoryId, ...filterCategories],
    {
      includeTotal: false
    }
  )

  // Ensure all names are string values, a requirement for `asSeries()`
  const itemMapper = convertItemNameToString(nameCategoryId)
  response = transformStandardResponseItems(response, itemMapper)

  if (!filterZeroValues) {
    return response
  }

  return {
    ...response,
    items: filterStandardItems(
      response.items,
      zeroValueFilter(filterCategories, {filterSmallValuesThreshold: 0.005}),
      response.categories
    )
  }
}

/**
 * Checks if a response is nil or categories/items are empty
 */
export const isResponseEmpty = (response?: IChartTable): boolean => {
  if (!response) {
    return true
  }

  if (!response.categories || !response.items) {
    return true
  }

  if (response.categories.length === 0 || response.items.length === 0) {
    return true
  }

  return false
}

export const responseWithoutTotal = (response: IChartTable): IChartTable => {
  if (isTotalRow(response.items[0])) {
    return {
      ...response,
      items: response.items.slice(1)
    }
  }

  return response
}

export const calculateCategoryTotal = (
  response: IChartTable,
  categoryId: string
): number =>
  responseWithoutTotal(response).items.reduce((total, item) => {
    const dataItem = find(item.data, {categoryId})
    if (!dataItem) {
      throw new Error(`category "${categoryId}" not found in ${item.id}`)
    }

    return total + (dataItem.value || 0)
  }, 0)

export const createVirtualTotalItem = (
  response: IChartTable,
  calculateTotalCategoryIds?: string[]
): IChartTableItem => {
  if (isTotalRow(response.items[0]) && isEmpty(calculateTotalCategoryIds)) {
    return response.items[0]
  }

  const totalItem: IChartTableItem = {
    id: 'virtual-total',
    data: []
  }
  for (const category of response.categories) {
    if (category.id === CATEGORY_IDS.NAME) {
      totalItem.data.push({
        categoryId: CATEGORY_IDS.NAME,
        value: capitalize(CATEGORY_NAMES.TOTAL)
      })
      continue
    }

    if (
      calculateTotalCategoryIds &&
      calculateTotalCategoryIds.includes(category.id)
    ) {
      const value = calculateCategoryTotal(response, category.id)
      totalItem.data.push({
        value,
        categoryId: category.id
      })
      continue
    }

    totalItem.data.push({
      categoryId: category.id,
      value: null
    })
  }

  return totalItem
}

export const responseWithUpdatedTotal = (
  response: IChartTable,
  calculateTotalCategoryIds?: string[]
): IChartTable => {
  if (isTotalRow(response.items[0]) && isEmpty(calculateTotalCategoryIds)) {
    return response
  }

  const totalItem = createVirtualTotalItem(response, calculateTotalCategoryIds)
  const items = isTotalRow(response.items[0])
    ? [totalItem, ...response.items.slice(1)]
    : [totalItem, ...response.items]

  return {
    ...response,
    items
  }
}

/**
 * @deprecated use updateResponseWithCategoryNames
 * Returns a new chart table response with category names updated.
 * Returns a a reference to the original response object if the category name is
 * equal to the current name.
 * @param response - Chart table response to update category name
 * @param Id  - of category to be updated
 * @param name - New name to update category with
 */
export const responseWithUpdatedCategoryNames = (
  response: IChartTable,
  updates: Array<{categoryId: string; name: string}>
): IChartTable =>
  produce(response, (draft) => {
    for (const {categoryId, name} of updates) {
      const [, category] = findCategoryReference(draft, categoryId)

      if (!category) {
        return
      }

      category.name = name
    }
  })

/**
 * Returns a new chart table response with category names updated.
 * Returns a a reference to the original response object if the category name is
 * equal to the current name.
 * @param response - Chart table response to update category name
 * @param Id  - of category to be updated
 * @param name - New name to update category with
 */
export const updateResponseWithCategoryNames = (
  response: IChartTable,
  updates: Array<{categoryId: string; name: string}>
): void => {
  for (const {categoryId, name} of updates) {
    const [, category] = findCategoryReference(response, categoryId)

    if (!category) {
      return
    }

    category.name = name
  }
}

export const responseWithFilteredCategories = (
  response: IChartTable,
  filter: (category?: IChartTableCategory, index?: number) => boolean
): IChartTable => {
  const nextCategories = response.categories.filter(filter)
  if (nextCategories.length === response.categories.length) {
    return response
  }

  return {
    ...response,
    categories: nextCategories
  }
}

/**
 * Returns a new chart table response with the updated category.
 * Returns a reference to the original response object if the category
 * is equal to the current value.
 * @param response - Chart table response to update category in
 * @param categoryId - Id of category to be updated
 * @param category - New category data to update with
 */
export const responseWithUpdatedCategory = (
  response: IChartTable,
  categoryId: string,
  category: IChartTableCategory
): IChartTable =>
  produce(response, (draft) => {
    const [index, , containingCollection] = findCategoryReference(
      draft,
      categoryId
    )

    if (response.categories[index] === category) {
      return response
    }

    containingCollection[index] = category
  })

/**
 * Returns a new category with updated name. Returns a
 * reference to the original category if the name is equal
 * to the current value.
 * @param category - category to update name
 * @param name - value of new name
 */
export const categoryWithUpdatedName = (
  category: IChartTableCategory,
  name: string
): IChartTableCategory =>
  produce(category, (draft) => {
    if (draft.name === name) {
      return
    }

    draft.name = name
  })

export const responseWithUpdatedItem = (
  response: IChartTable,
  itemId: string,
  item: IChartTableItem
): IChartTable => {
  return produce(response, (draft) => {
    const index = response.items.findIndex((item) => item && item.id === itemId)

    if (response.items[index] === item) {
      return response
    }

    draft.items[index] = item

    return undefined
  })
}

/**
 * Create a new item with updated value at `categoryId`, returns a reference to
 * the original object if the `dataItem` for the category is not found
 */
export const itemWithUpdatedValue = (
  item: IChartTableItem,
  categoryId: string,
  value: any,
  key?: any
): IChartTableItem => {
  return produce(item, (draft) => {
    const index = item.data.findIndex((data) => data.categoryId === categoryId)

    if (index === -1) {
      return item
    }

    if (item.data[index].value === value) {
      return item
    }

    draft.data[index].value = value
    if (key !== undefined) {
      draft.data[index].key = key
    }

    return undefined
  })
}

interface IUpdateResponseValueParams {
  response: IChartTable
  categoryId: string
  itemId: string
  value: any
  key?: any
}

/**
 * Creates a new response with an updated value for data cell at `categoryId`
 * and `itemId`
 */
export const responseWithUpdatedValue = ({
  response,
  categoryId,
  itemId,
  value,
  key
}: IUpdateResponseValueParams): IChartTable => {
  return produce(response, (draft) => {
    const ref = findItemReferenceById(draft, itemId)
    if (!ref) {
      return response
    }

    const [i, items] = ref
    const nextItem = itemWithUpdatedValue(items[i], categoryId, value, key)

    if (nextItem === items[i]) {
      return response
    }

    items[i] = nextItem

    return undefined
  })
}

/**
 * @deprecated use `StandardResponseItem#getDatum`
 */
export const getItem_DEPRECATED = (
  item: IChartTableItem,
  categoryId: string
): any => item?.data?.find((data) => data.categoryId === categoryId)

/**
 * @deprecated use `StandardResponseItem#getValue`
 */
export const getItemValue_DEPRECATED = (
  item: IChartTableItem,
  categoryId: string
): unknown => {
  const dataItem = getItem_DEPRECATED(item, categoryId)

  return dataItem && dataItem.value
}

export interface IEmptyValueOptions {
  allowZero?: boolean
  allowBoolean?: boolean
}

const itemValueIsEmpty = (
  value,
  {allowZero, allowBoolean}: IEmptyValueOptions
): boolean => {
  if (allowBoolean && allowZero && !isNil(value)) {
    return false
  }

  if (allowBoolean && typeof value === 'boolean') {
    return false
  }

  if (allowZero && typeof value === 'number') {
    return false
  }

  if (!!value) {
    return false
  }

  return true
}

const itemsValueIsEmptyAtIndex = (
  items: IChartTableItem[],
  categoryIndex: number,
  options: IEmptyValueOptions
): boolean => {
  for (const item of items) {
    const value = item.data[categoryIndex].value

    if (!itemValueIsEmpty(value, options)) {
      return false
    }

    if (item.items) {
      return itemsValueIsEmptyAtIndex(item.items, categoryIndex, options)
    }

    return true
  }
}

const itemsValueIsEmptyAtCategoryId = (
  items: IChartTableItem[],
  categoryId: string,
  options: IEmptyValueOptions
): boolean => {
  for (const item of items) {
    const value = getItemValue_DEPRECATED(item, categoryId)

    if (!itemValueIsEmpty(value, options)) {
      return false
    }

    if (item.items) {
      return itemsValueIsEmptyAtCategoryId(item.items, categoryId, options)
    }
  }
  return true
}

/**
 * Remove `categories` and `dataItems` from response where the entire column
 * is considered zero (can be modified by options)
 */
export const filterResponseByEmptyCategory = (
  response: IChartTable,
  options?: IEmptyValueOptions
): IChartTable => {
  const optionsWithDefaultValues: IEmptyValueOptions = {
    allowZero: false,
    allowBoolean: false,
    ...options
  }

  const categories: IChartTableCategory[] = []

  for (const category of response.categories) {
    if (
      itemsValueIsEmptyAtCategoryId(
        response.items,
        category.id,
        optionsWithDefaultValues
      )
    ) {
      continue
    }

    categories.push(category)
  }

  const items = filterStandardItemsByCategoryIds(
    response.items,
    categories.map(({id}) => id)
  )

  return {
    categories,
    items
  }
}

export const getAllCategoryIds = (category: IChartTableCategory[]): string[] =>
  category.reduce((ids, category) => {
    ids.push(category.id)
    if (category.categories) {
      return ids.concat(getAllCategoryIds(category.categories))
    }

    return ids
  }, [])

/**
 * Used to filter out child categories that have been excluded by `metricItems`
 * @param response - responses
 * @param metricItems - metric items
 */
export const filterResponseByMetricOptions = (
  response: IChartTable,
  metricItems: IViewMetricItem[]
): IChartTable => {
  const conditionCategories = conditionResultCategoriesFromItems(metricItems)

  if (isEmpty(conditionCategories)) {
    return response
  }

  const categories = []
  for (const category of response.categories) {
    if (!(conditionCategories[category.id] && category.categories)) {
      categories.push(category)
      continue
    }

    const children = []
    for (const child of category.categories) {
      if (conditionCategories[category.id].includes(child.id)) {
        children.push(child)
      }
    }

    categories.push({
      ...category,
      categories: children
    })
  }

  if (isEmpty(response.items)) {
    return {
      categories,
      items: []
    }
  }

  const categoryIds = getAllCategoryIds(categories)
  const firstItem = response.items[0]

  const nextCategoryIds = []
  for (const dataItem of firstItem.data) {
    if (categoryIds.includes(dataItem.categoryId)) {
      nextCategoryIds.push(dataItem.categoryId)
    }
  }

  const items = filterStandardItemsByCategoryIds(
    response.items,
    nextCategoryIds
  )

  return {
    categories,
    items
  }
}

/**
 * Updates the category names displayed from view options
 * @param metricItems - from view options
 */
export const updateCategoryNamesByMetricOptions = (
  categories: IChartTableCategory[],
  metricItems: IViewMetricItem[]
): IChartTableCategory[] => {
  if (isEmpty(metricItems)) {
    return categories
  }

  const nextCategoryNamesMap: Dictionary<string> = metricItems.reduce(
    (result, metricItem) => {
      if (!metricItem.displayName) {
        return result
      }

      return {
        ...result,
        [categoryIdForMetricItem(metricItem)]: metricItem.displayName
      }
    },
    {}
  )

  return produce(categories, (nextCategories) => {
    for (const category of nextCategories) {
      if (nextCategoryNamesMap[category.id]) {
        category.name = nextCategoryNamesMap[category.id]
      }
    }
  })
}

/**
 * Returns a flat list of values at the leaf nodes, defaults to returning a list
 * of `item.id`s for truthy values
 */
export const itemLeafValuesByCategoryId = (
  items: IChartTableItem[],
  categoryId: string,
  /**
   * Each leaf item, where `predicate` returns true is passed through `iteratee`,
   * the return result is added to the returned flat list of values
   */
  iteratee: (item: IChartTableItem) => any = (item): string => item.id,
  /**
   * Each value at category id of leaf items is passed through `predicate`, if
   * it returns true, the value from `iteratee` is added to the returned flat list of values
   */
  predicate: (value: any) => boolean = identity
): any[] => {
  return items.reduce((result: string[], item) => {
    if (item.items) {
      return [
        ...result,
        ...itemLeafValuesByCategoryId(
          item.items,
          categoryId,
          iteratee,
          predicate
        )
      ]
    }

    const value = categoryId ? getItemValue_DEPRECATED(item, categoryId) : null

    if (!!predicate(value)) {
      const accumulatorValue = iteratee(item)

      return [...result, accumulatorValue]
    }

    return result
  }, [])
}

/**
 * Returns a flat list of ids at the leaf nodes
 */
export const itemLeafIds = (items: IChartTableItem[]): string[] =>
  itemLeafValuesByCategoryId(
    items,
    null,
    (item) => item.id,
    () => true
  )

/**
 * Returns a list of model IDs corresponding to a given list of StandardResponseItem IDs.
 *
 * @param items - StandardResponseItems to search recursively
 * @param ids - list of StandardResponseItem IDs
 */
export const modelIdsByItemIds = (
  items: IChartTableItem[],
  ids: string[]
): string[] => {
  const modelIds = []

  for (const item of items) {
    if (ids.includes(item.id)) {
      const modelId = getItemValue_DEPRECATED(item, CATEGORY_IDS.MODEL_ID)
      modelIds.push(modelId)
    }

    if (item.items) {
      const itemsModelIds: string[] = modelIdsByItemIds(item.items, ids)

      if (itemsModelIds.length > 0) {
        modelIds.push(...itemsModelIds)
      }
    }
  }

  return modelIds
}

export interface IReorderVisibleCategoriesOptions {
  /**
   * When true, removes all unmapped categories which are not hidden
   */
  discardUnmapped?: boolean
}
/**
 * Reorders non-hidden categories
 * @param orderedIds - a list of category IDs in the expected order
 */
export const reorderVisibleCategories = (
  categories: IChartTableCategory[],
  orderedIds: string[],
  options: IReorderVisibleCategoriesOptions = {}
): IChartTableCategory[] => {
  // Don't touch name and hidden categories
  const nextCategories = nonDataCategories(categories)

  // Ignore sufixes split across ":" and "|" characters
  const splitToken = /[:|]/

  // Add categories defined in order
  for (const id of orderedIds) {
    const category = categories.find((category) => {
      // Check for raw IDs and IDs split accross splitToken
      return category.id === id || category.id.split(splitToken)[0] === id
    })
    if (category) {
      nextCategories.push(category)
    }
  }

  // Add unmapped categories back to the list if discardUnmapped is not true
  if (!options.discardUnmapped) {
    const topLevelIds = nextCategories.map(({id}) => id.split(splitToken)[0])
    const topLevelRawIds = nextCategories.map(({id}) => id)
    for (const category of categories) {
      if (
        !topLevelIds.includes(category.id.split(splitToken)[0]) &&
        !topLevelRawIds.includes(category.id)
      ) {
        nextCategories.push(category)
      }
    }
  }

  return nextCategories
}

/**
 * Returns name and hidden categories
 */
export const nonDataCategories = (
  categories: IChartTableCategory[]
): IChartTableCategory[] => {
  return categories.filter((category, index) => {
    return (
      (index === 0 && category.id === KNOWN_CATEGORY_IDS.NAME) ||
      (category.options && category.options.hidden)
    )
  })
}

/**
 * Returns all categories except name and hidden
 */
export const dataCategories = (
  categories: IChartTableCategory[]
): IChartTableCategory[] => {
  return categories.filter((category, index) => {
    if (index === 0) {
      return false
    }

    if (!category.options) {
      return true
    }

    if (category.options.hidden) {
      return false
    }

    return true
  })
}

const getDataForSortCategory = (
  item: IChartTableItem,
  sortCategoryId: string
): IChartTableData =>
  item.data.find((data) => data.categoryId === sortCategoryId)

/**
 * Create a scoring method for items that can be sent to `orderBy` or `sortBy`
 */
export const createItemSortScore = memoizeOne((sortCategoryId: string) => [
  (item: IChartTableItem): string => {
    return item?.options?.subtable
  },
  (item: IChartTableItem): boolean => {
    return !!item?.options?.pinned
  },
  (item: IChartTableItem): string => {
    if (!item) {
      return null
    }

    return getDataForSortCategory(item, sortCategoryId)?.sortValue ?? null
  },
  (item: IChartTableItem): string => {
    if (!item) {
      return null
    }

    const dataItem = getDataForSortCategory(item, sortCategoryId)

    if (!dataItem) {
      return ''
    }

    let value = Array.isArray(dataItem.value)
      ? dataItem.value[dataItem.value.length - 1]?.value ||
        dataItem.value[dataItem.value.length - 1]
      : dataItem.value

    if (typeof value === 'string') {
      value = value.toLowerCase()
    }

    return value ?? ''
  }
])

/**
 * Sorts a flat list of items by the given sort options.
 * Returns the original reference if no sort options are provided.
 */
export const flatSortTableItems = (
  items: IChartTableItem[],
  sortCategoryId?: string,
  sortOrder?: SORT_ORDER
): IChartTableItem[] => {
  if (!(sortCategoryId && sortOrder)) {
    return items
  }

  const itemSortScore = createItemSortScore(sortCategoryId)
  const sortByOrder = sortOrder.toLowerCase() as 'asc' | 'desc'

  return orderBy<IChartTableItem>(items, itemSortScore, [
    'asc', // First sorts in ascending order by `item.option.subtable` so
    // 'cash' would come first followed by 'noncash' these are currently
    // the only two subtables that exist.
    'desc', // Then sorts by pinned items in descending. They are booleans
    // so `true` puts items at top of sort.
    sortByOrder, // Then sorts by `item.data[n].sortValue` in what ever
    // direction the table column sort order is set to
    // (e.g. 'asc' or 'desc').
    sortByOrder // Then sorts by `item.data[n].value` in what ever direction
    // the table column sort order is set to (e.g. 'asc' or 'desc').
    // Important to keep in mind here that total row/item is not included
    // in the sorting algorithm and will always be at the top.
  ])
}

/**
 * Sorts all items in the items tree.
 */
const internalRecursivelySortTableItems = (
  items: IChartTableItem[],
  sortCategoryId: string,
  sortOrder: SORT_ORDER
): IChartTableItem[] => {
  return flatSortTableItems(items, sortCategoryId, sortOrder).map((item) => {
    if (!item || !item.items) {
      return item
    }

    return {
      ...item,
      items: internalRecursivelySortTableItems(
        item.items,
        sortCategoryId,
        sortOrder
      )
    }
  })
}

/**
 * Sorts all items in the items tree, maintaining total item if it exists.
 * Returns the original reference if no sort options are provided.
 */
export const recursivelySortTableItems = (
  items: IChartTableItem[],
  sortCategoryId: string,
  sortOrder: SORT_ORDER
): IChartTableItem[] => {
  if (!(sortCategoryId && sortOrder)) {
    return items
  }

  let totalItem: IChartTableItem
  if (items && items[0] && isTotalRow(items[0])) {
    totalItem = items[0]
  }

  const seperatedItems = totalItem ? items.slice(1) : items

  const sortedItems = internalRecursivelySortTableItems(
    seperatedItems,
    sortCategoryId,
    sortOrder
  )

  if (totalItem) {
    sortedItems.unshift(totalItem)
  }

  return sortedItems
}

export interface IConsolidateItemsOptions {
  /**
   * Keep at most `maxTopItemCount` top values
   * To include both top and bottom, pass `maxBottomItemCount` as well
   * Pass `0` to remove all top values
   * @defaultValue 15
   */
  maxTopItemCount?: number
  /**
   * Keep at most `maxBottomItemCount` bottom values
   * To include both top and bottom, pass `maxTopItemCount` as well
   * Pass `0` or omit value to remove all top values
   * @defaultValue 0
   */
  maxBottomItemCount?: number
  /**
   * Sets the value of "name" category for remaining item
   * @defaultValue "Other"
   */
  remainingItemLabel?: string
  /**
   * When true, remaining value is calculated as the average instead of sum
   * @defaultValue false
   */
  averageRemainingValues?: boolean
  /**
   * When true, remaining item is not included in response
   * @defaultValue false
   */
  discardRemainingItem?: boolean
}

/**
 * Creates a new SORTED array of items, by keeping only the top or bottom items
 * specified by the value at `valueCategoryId` and adds a "remainder" item if
 * number of items exceeds `options.maxItemCount`
 */
export const consolidateItems = (
  items: IChartTableItem[],
  valueCategoryId: string,
  options: IConsolidateItemsOptions = {}
): IChartTableItem[] => {
  const {
    remainingItemLabel,
    averageRemainingValues,
    maxTopItemCount,
    maxBottomItemCount,
    discardRemainingItem
  } = {
    remainingItemLabel: 'Other',
    averageRemainingValues: false,
    maxTopItemCount: 15,
    maxBottomItemCount: 0,
    discardRemainingItem: false,
    ...options
  }

  const sortedItems = flatSortTableItems(items, valueCategoryId, SORT_ORDER.ASC)

  const totalItemCount = maxTopItemCount + maxBottomItemCount

  if (sortedItems.length <= totalItemCount) {
    return produce(sortedItems, (draft) => {
      for (let i = 0; i < sortedItems.length; i++) {
        if (sortedItems[i].items) {
          draft[i].items = consolidateItems(
            sortedItems[i].items,
            valueCategoryId,
            options
          )
        }
      }
    })
  }

  const itemsWithValue = sortedItems.filter(
    (item) => getItemValue_DEPRECATED(item, valueCategoryId) != null
  )

  const bottomItems = itemsWithValue.slice(0, maxBottomItemCount)
  const topItems = itemsWithValue.slice(
    itemsWithValue.length - maxTopItemCount,
    itemsWithValue.length
  )

  if (discardRemainingItem) {
    const consolidated = [...bottomItems, ...topItems]

    return produce(consolidated, (draft) => {
      for (let i = 0; i < consolidated.length; i++) {
        if (consolidated[i].items) {
          draft[i].items = consolidateItems(
            consolidated[i].items,
            valueCategoryId,
            options
          )
        }
      }
    })
  }

  const remainingItems = itemsWithValue.slice(
    maxBottomItemCount,
    itemsWithValue.length - maxTopItemCount
  )

  let remainingValue = remainingItems.reduce((sum, nextItem) => {
    return (
      sum +
      ((getItemValue_DEPRECATED(nextItem, valueCategoryId) as number) || 0)
    )
  }, 0)

  if (averageRemainingValues) {
    remainingValue /= remainingItems.length
  }

  const remainingItem = {
    id: 'remaining-value',
    data: remainingItems[0].data.map((dataItem) => {
      if (dataItem.categoryId === CATEGORY_IDS.NAME) {
        return {
          ...dataItem,
          value: remainingItemLabel
        }
      }

      if (dataItem.categoryId === valueCategoryId) {
        return {
          ...dataItem,
          value: remainingValue
        }
      }

      return dataItem
    })
  }

  let consolidated = [...bottomItems, ...topItems]

  consolidated = produce(consolidated, (draft) => {
    for (let i = 0; i < consolidated.length; i++) {
      if (consolidated[i].items) {
        draft[i].items = consolidateItems(
          consolidated[i].items,
          valueCategoryId,
          options
        )
      }
    }
  })

  return [...consolidated, remainingItem]
}

/**
 * Returns the first non-string category from a list
 */
export const firstNumericCategory = (
  categories: IChartTableCategory[]
): IChartTableCategory => {
  for (const category of categories) {
    if (category.valueType === 'string') {
      continue
    }

    return category
  }
}

/**
 * Recursively updates value type of certain categories
 */
export const changeCategoryValueType = (
  categories: IChartTableCategory[],
  categoryIdsToChange: Set<string>,
  valueType: CHART_VALUE_TYPE
): IChartTableCategory[] => {
  return produce(categories, (draft) => {
    for (const category of draft) {
      if (categoryIdsToChange.has(category.id)) {
        category.valueType = valueType
      }

      if (category.categories) {
        category.categories = changeCategoryValueType(
          category.categories,
          categoryIdsToChange,
          valueType
        )
      }
    }
  })
}

/**
 * @deprecated use updateCategoryOptions
 */
export const changeCategoryOptions = (
  categories: IChartTableCategory[],
  categoryIdsToChange: Set<string>,
  optionsToChange: IChartTableOptions
): IChartTableCategory[] =>
  produce(categories, (draftCategories) => {
    updateCategoryOptions(draftCategories, categoryIdsToChange, optionsToChange)
  })

/**
 * Recursively updates options of certain categories
 */
export function updateCategoryOptions(
  categories: IChartTableCategory[],
  categoryIdsToChange: Set<string>,
  optionsToChange: IChartTableOptions
): void {
  for (const category of categories) {
    if (categoryIdsToChange.has(category.id)) {
      category.options = {
        ...category.options,
        ...optionsToChange
      }
    }

    if (category.categories) {
      updateCategoryOptions(
        category.categories,
        categoryIdsToChange,
        optionsToChange
      )
    }
  }
}

/**
 * Returns a reference to the first item that is found where the `itemPredicate`
 * passes, performs a recursive depth first search.
 */
export const findItemReference = (
  response: IChartTable,
  itemPredicate: StandardResponseItemFilter
): [number, IChartTableItem[]] => {
  return internalFindItemReference(
    response.items,
    response.categories,
    response.items,
    itemPredicate
  )
}

/**
 * Returns the index and category from a chart table response with a
 * corresponding categoryId
 * @param response - response in which to find category
 * @param categoryId - id of category that gets returned
 *
 * @returns
 *   0. Index of found value or -1
 *   1. Found element or undefined
 *   2. Containing array for the found element
 */
export const findCategoryReference = (
  response: IChartTable,
  categoryId: string
): [number, IChartTableCategory, IChartTableCategory[]] => {
  for (let i = 0; i < response.categories.length; i++) {
    const category = response.categories[i]
    if (category.id === categoryId) {
      return [i, category, response.categories]
    }

    if (category.categories) {
      const result = findCategoryReference(
        {categories: category.categories} as IChartTable,
        categoryId
      )

      if (result[1]) {
        return result
      }
    }
  }

  return [-1, undefined, response.categories]
}

/**
 * Returns a reference to the first item that is found with id === `itemId`,
 * performs a recursive depth first search.
 */
export const findItemReferenceById = (
  response: IChartTable,
  itemId: string
): [number, IChartTableItem[]] => {
  const itemPredicate: StandardResponseItemFilter = (item) => item.id === itemId

  return findItemReference(response, itemPredicate)
}

/**
 * Used internally by `findItemReference`
 *
 * Returns a reference to the first item that is found where the `itemPredicate`
 * passes, performs a recursive depth first search.
 *
 * @remarks We return a reference to the "owner" collection, instead of the
 * item itself to allow us to replace the found item with a new object
 *
 * @returns
 *   0. index of found item
 *   1. reference to array to which found item belongs
 */
export const internalFindItemReference = (
  items: IChartTableItem[],
  categories: IChartTableCategory[],
  allItems: IChartTableItem[],
  itemPredicate: StandardResponseItemFilter
): [number, IChartTableItem[]] => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i]

    // Account for potentially null items
    if (!item) {
      continue
    }

    if (itemPredicate(item, categories)) {
      return [i, items]
    }

    if (item.items) {
      const result = internalFindItemReference(
        item.items,
        categories,
        allItems,
        itemPredicate
      )
      if (result) {
        return result
      }
    }
  }

  return null
}

/**
 * Interface used to transform all items in a table
 */
export type StandardResponseItemMapper = (
  item: IChartTableItem,
  categories: IChartTableCategory[]
) => IChartTableItem

export interface ITransformStandardResponseItemsOptions {}

/**
 * Transform an `IChartTable` by recursively mapping all items with `itemMapper`,
 * includes dirty checks which return the original reference if `itemMapper`
 * does not produce a new value
 *
 * Recommended to use `immer` in mapping method to help provide immutable transforms
 */
export const transformStandardResponseItems = (
  response: IChartTable,
  itemMapper: StandardResponseItemMapper,
  options: ITransformStandardResponseItemsOptions = {}
): IChartTable => {
  const nextItems = mapStandardResponseItems(
    response.items,
    response.categories,
    itemMapper,
    options
  )

  if (nextItems !== response.items) {
    return {
      ...response,
      items: nextItems
    }
  }

  return response
}

/**
 * Recursively maps all items with `itemMapper`, includes dirty checks which
 * return the original reference if `itemMapper` does not produce a new value
 */
export const mapStandardResponseItems = (
  items: IChartTableItem[],
  categories: IChartTableCategory[],
  itemMapper: StandardResponseItemMapper,
  options: ITransformStandardResponseItemsOptions = {}
): IChartTableItem[] => {
  let dirty = false

  const mappedItems = items.map((item) => {
    const nextItem = itemMapper(item, categories)
    if (nextItem !== item) {
      dirty = true
    }

    let benchmarks: IChartTableItem[]
    if (nextItem.benchmarks) {
      benchmarks = mapStandardResponseItems(
        nextItem.benchmarks,
        categories,
        itemMapper,
        options
      )
    }

    if (nextItem.items) {
      const nextChildren = mapStandardResponseItems(
        nextItem.items,
        categories,
        itemMapper,
        options
      )

      if (nextChildren !== nextItem.items) {
        dirty = true
        return {
          ...nextItem,
          items: nextChildren,
          benchmarks
        }
      }
    }

    return {
      ...nextItem,
      benchmarks
    }
  })

  if (dirty) {
    return mappedItems
  }

  return items
}

/**
 * Converts the value at a given category to a string, defaults to name cateogry
 *
 * Returns original item if no changes are required
 */
export const convertItemNameToString =
  (nameCategoryId: string = CATEGORY_IDS.NAME): StandardResponseItemMapper =>
  (item: IChartTableItem) => {
    const dataItemIndex = item.data.findIndex(
      (dataItem) => dataItem.categoryId === nameCategoryId
    )
    if (dataItemIndex === -1) {
      return item
    }

    return produce(item, (draft) => {
      if (typeof item.data[dataItemIndex].value === 'string') {
        return item
      }

      draft.data[dataItemIndex].value = String(draft.data[dataItemIndex].value)

      return undefined
    })
  }

export const isNumberCell = (valueType: CHART_VALUE_TYPE): boolean =>
  [
    CHART_VALUE_TYPE.PERCENTAGE,
    CHART_VALUE_TYPE.DECIMAL,
    CHART_VALUE_TYPE.INTEGER,
    CHART_VALUE_TYPE.DECIMAL_LONG,
    CHART_VALUE_TYPE.DECIMAL_LONG_LONG,
    CHART_VALUE_TYPE.MONEY_ABBREVIATED
  ].includes(valueType)
