import React, {useContext, useMemo} from 'react'

import {IGlobalFilter} from '@d1g1t/typings/general'

import {useGlobalFilter} from './'
import {DEFAULT_ALL_CLIENT_OPTION} from './constants'

const GlobalFilterContext = React.createContext<{data: IGlobalFilter}>(null)

interface IGlobalFilterProviderProps {
  /**
   * Permits the 'all-client' global filter only. Does not send any custom global filter.
   * Note: the menu option should be hidden.
   */
  allClientsOnly?: boolean
}
/**
 * Wrap any page which requires global filters in this provider.
 * Any decendent calculations will use the global filters when present.
 */
export const GlobalFilterProvider: React.FC<IGlobalFilterProviderProps> = ({
  allClientsOnly,
  children
}) => {
  const [filter] = useGlobalFilter()

  const value = useMemo(() => {
    return {
      data: allClientsOnly ? DEFAULT_ALL_CLIENT_OPTION : filter
    }
  }, [filter, allClientsOnly])

  return (
    <GlobalFilterContext.Provider value={value}>
      {children}
    </GlobalFilterContext.Provider>
  )
}

/**
 * Provides context for global filters in calculations.
 *
 * Returns `null` when no provider conext is present, otherwise
 * calculations should wait for the `data` value to kick off calculations.
 */
export function useGlobalFilterContext() {
  return useContext(GlobalFilterContext)
}
