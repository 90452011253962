import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    footerLink: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    footerShowAllOffices: {
      color: theme.palette.grey[400],
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  }
})
