import React, {createContext, useMemo} from 'react'

export interface IMenuStateContextValue {
  open: boolean
}

export const MenuStateContext = createContext({
  open: false
})

interface IMenuStateProviderProps {
  open: boolean
}

/**
 * Used to provide context of the parent menu's state to nested menus.
 */
export const MenuStateProvider: React.FC<IMenuStateProviderProps> = ({
  open = false,
  children
}) => {
  const value: IMenuStateContextValue = useMemo(() => {
    return {
      open
    }
  }, [open])

  return (
    <MenuStateContext.Provider value={value}>
      {children}
    </MenuStateContext.Provider>
  )
}
