type IOptions = {
  allOrNone: boolean
  dryRun: boolean
  failFast: boolean
  firm: string
  ignoreFieldValue: string
}
type ISheets = {
  fileErrors: string[]
  options: IOptions
  fileWarnings?: string[]
  rowErrors: string[]
  rows: number
  saveErrors: string[]
  rowWarnings?: string[]
  sheetName: string
}
type IMessages = {
  errors: string[]
  warnings: string[]
}
export interface IUploadResult {
  fileErrors: string[]
  fileWarnings: string[]
  rowErrors?: string[]
  saveErrors?: string[]
  sheets?: ISheets[]
  filename: string
}
export const getAlertMessages = (uploadResult: IUploadResult): IMessages => {
  if (!uploadResult) {
    return null
  }
  const messages = {
    errors: [],
    warnings: []
  }

  if (uploadResult.fileErrors?.length) {
    messages.errors = [...messages.errors, ...uploadResult.fileErrors]
  }
  if (uploadResult.rowErrors?.length) {
    messages.warnings = [...messages.warnings, ...uploadResult.rowErrors]
  }
  if (uploadResult.saveErrors?.length) {
    messages.warnings = [...messages.warnings, ...uploadResult.saveErrors]
  }
  if (uploadResult.fileWarnings?.length) {
    messages.warnings = [...messages.warnings, ...uploadResult.fileWarnings]
  }

  if (uploadResult.sheets?.length) {
    // check if there are errors and push them to the messages.error
    const errorSheets = uploadResult.sheets
      .filter((sheet) => sheet.fileErrors.length > 0)
      .map((sheet) =>
        `\n\n ${sheet.sheetName} has error(s): ${sheet.fileErrors}`.replace(
          /,/g,
          ', '
        )
      )

    if (errorSheets.length > 0) {
      messages.errors = [...messages.errors, ...errorSheets.flat()]
    }
    // check if there are warnings and push them to the messages.warnings
    const saveRowErrorSheets = uploadResult.sheets
      .filter(
        (sheet) =>
          sheet.saveErrors.length > 0 ||
          sheet.rowWarnings.length > 0 ||
          sheet.rowWarnings.length > 0
      )
      .map((sheet) =>
        `\n\n ${sheet.sheetName} has warnings(s): ${
          (sheet.rowErrors.length > 0 && sheet.rowErrors) ||
          (sheet.saveErrors.length && sheet.saveErrors) ||
          (sheet.rowWarnings?.length && sheet.rowWarnings)
        }`.replace(/,/g, ', ')
      )
    if (saveRowErrorSheets.length > 0) {
      messages.warnings = [...messages.warnings, ...saveRowErrorSheets.flat()]
    }
    if (messages.errors.length > 0) {
      messages.errors = ['Import failed.'].concat(messages.errors)
    }
  }

  return messages
}
