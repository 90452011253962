import {ApiParam} from 'fairlight'

import {IPersonGroup} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class PeoplePersonGroupsEndpoints extends BaseRestEndpoints {
  static basePath = '/people/person-groups'

  static list() {
    return super._list<IApiListResponse<IPersonGroup>>()
  }

  static findById(id: ApiParam) {
    return super._findById<IPersonGroup>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IPersonGroup>) {
    return super._partialUpdate<IPersonGroup>(id, body)
  }

  static create(body: IPersonGroup) {
    return super._create<IPersonGroup>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }
}
