import {useCallback, useRef} from 'react'

/**
 * Creates a new function which is referentially stable for the lifetime of the
 * react component, and will call to the latest value.
 *
 * This is meant as a performance optimization to avoid re-renders and should
 * only be used when there is performance issue observed.
 */
export function useStableCallback<T extends (...args: any[]) => any>(cb: T): T {
  const cbRef = useRef(cb)
  cbRef.current = cb

  return useCallback(cbRef.current, [])
}
