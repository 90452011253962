import {ERROR_MESSAGE} from '@d1g1t/shared/wrappers/error-boundary/typings'
import {LOGIN_PAGE_TRANSLATION_KEYS_SHARED} from '@d1g1t/shared/wrappers/localization-settings/constants'

export enum OTHER_TRANSLATION_KEYS {
  CHANGED_DATE_FORMAT = 'Changed date format',
  CHANGED_LANGUAGE_TO = 'Changed language to',
  CONSOLIDATED_PORTFOLIO = 'Consolidated Portfolio',
  D1G1T_INC = 'd1g1t Inc.',
  ENGLISH = 'English',
  FRENCH = 'Français',
  GOOD_AFTERNOON = 'Good afternoon',
  GOOD_EVENING = 'Good evening',
  GOOD_MORNING = 'Good morning',
  LOG_OUT = 'Log Out',
  POWERED_BY = 'powered by',
  PRIVACY_POLICY = 'Privacy Policy',
  SET_ALL_COMPONENETS_TO_MATCH_DATE_RANGE = 'Set all components to match date range',
  TERMS_AND_CONDITIONS = 'Terms and Conditions',
  TERMS_OF_USE = 'Terms of Use'
}

export enum COMPONENT_SPECIFIC_TRANSLATION_KEYS {
  ACCOUNT_DATA_DATA_EMPTY = 'Account data is empty',
  ALLOCATION_DATA_DATA_EMPTY = 'Allocation data is empty',
  ARE_YOU_SURE_YOU_WANT_DISCARD_ALL_UNSAVED_CHANGES = 'Are you sure you want discard all unsaved changes? This can not be undone.',
  AS = 'as',
  AS_OF = 'As Of',
  BACK = 'Back',
  BEGINNING_VALUE_AND_NET_ADDITIONS = 'Beginning Value + Net Additions',
  BLANK = '<Blank>',
  CANCEL = 'Cancel',
  CLEAR = 'Clear',
  CLEAR_ALL = 'Clear All',
  CLOSE = 'Close',
  CONFIRM_DISCARD_CHANGES = 'Confirm Discard Changes',
  CONSOLIDATED_PORTFOLIO_DATA_EMPTY = 'Consolidated Portfolio data is empty',
  CURRENCY = 'Currency',
  CURRENTLY_EMULATING = 'Currently emulating',
  DISCARD_CHANGES = 'Discard Changes',
  DO_YOU_WANT_TO_DISCARD_ALL_UNSAVED_CHANGES = 'Do you want to discard all unsaved changes?',
  FILTER_RULE = 'Filter rule',
  FILTER_RULES = 'Filter Rules',
  FROM = 'From',
  LOADING = 'Loading...',
  MARKET_VALUE = 'Market Value',
  NET_ADDITIONS = 'Net Additions',
  NOT_FOUND_PAGE = 'Page Not Found',
  NO_RESULTS = 'No results.',
  NO_STATS_DATA = 'No Stats Data',
  NO_TRANSACTIONS_AVAILABLE = 'No transactions available',
  PERIOD = 'Period',
  QUICK_ACCESS = 'Quick Access',
  REMOVE_ALL_RULE_FILTERS = 'Remove All Rule Filters',
  REMOVE_FILTER = 'Remove filter',
  RESET_ZOOM = 'Reset zoom',
  SAVE = 'Save',
  SAVING = 'Saving',
  SECURITY_DOES_NOT_HAVE_MARKET_PRICE = 'This security does not have market price history',
  SELECT_ALL = 'Select All',
  SHOW_AS_GRAPH = 'Show as Graph',
  SHOW_AS_TABLE = 'Show as Table',
  SHOW_LESS = 'Show Less',
  SHOW_MORE = 'Show More',
  SUBMIT = 'Submit',
  SUBMITTING = 'Submitting',
  SYNC_TO_SELECTED_PERIOD = 'Sync to selected period',
  TIME_PERIOD = 'Time Period',
  TO = 'To',
  TODAY = 'Today',
  TOTAL_GAIN = 'Total Gain',
  TREND_ANALYSIS_DATA_EMPTY = 'Trend Analysis data is empty',
  VALUES = 'Values',
  VALUE_AND_ADDITIONS = 'Value & Additions',
  YOU_HAVE_UNSAVED_CHANGES = 'You have unsaved changes'
}

export enum SECTION_TITLE_TRANSLATION_KEYS {
  ACTIVITY = 'Activity',
  DOCUMENTS = 'Documents',
  HOME = 'Home',
  INVESTMENTS = 'Investments',
  OTHER = 'Other',
  PRIVACY = 'Privacy',
  REPORTS = 'Reports',
  SETTINGS = 'Settings'
}

export enum HOME_PAGE_TRANSLATION_KEYS {
  ACTIVITY_SUBTITLE = 'Access transactions such as buys and sells, income, transfers, and fees.',
  CONSOLIDATED_PORTFOLIO_VALUE = 'Consolidated Portfolio Value',
  DEPOSITS_AND_WITHDRAWALS = 'Deposits and Withdrawals',
  DEPOSITS_AND_WITHDRAWALS_SUBTITLE = 'Track the flow of money in and out of your accounts.',
  DOCUMENTS_SUBTITLE = 'Retrieve the documents shared by your advisor and upload new documents.',
  INCOME_GENERATED = 'Income Generated',
  INCOME_GENERATED_SUBTITLE = 'Track the dividends, interests and other income generated by your investments.',
  INVESTMENTS_SUBTITLE = 'Analyze your holdings, allocation and performance over time.',
  SETTINGS_SUBTITLE = 'View your profile and contact information, and update your preferences.',
  TOTAL_PORTFOLIO = 'TOTAL PORTFOLIO',
  WHAT_CAN_WE_HELP_YOU_WITH_TODAY = 'What can we help you with today?',
  YOUR_ADVISORY_TEAM = 'Your Advisory Team'
}

export enum DOCUMENTS_PAGE_TRANSLATION_KEYS {
  FILTER_BY_CATEGORY = 'Filter by Category',
  NO_CATEGORIES_AVAILABLE = 'There are no categories available.',
  NO_DOCUMENT_FOR_ACCOUNT = 'There are no documents associated with this account.',
  NO_DOCUMENT_FOR_CATEGORY = 'There are no documents associated with this category.',
  TOGGLE_CATEGORY_VALUES = 'Toggle filters by clicking on the categories below. Categories in grey will be hidden.'
}

export enum ACTIVITY_PAGE_TRANSLATION_KEYS {
  ALL_TRANSACTIONS = 'All Transactions',
  BUY = 'Bought',
  DEPOSIT = 'Deposited',
  FILTER_BY_TYPE = 'Filter by Type',
  OF = 'of',
  SELL = 'Sold',
  TOGGLE_TAG_VALUES = 'Toggle filters by clicking on the <strong>transaction types</strong> below. <strong>Transaction types</strong> in grey will be hidden.',
  UNITS_AT = 'units at',
  WITHDRAWL = 'Withdrew'
}

export enum SETTINGS_PAGE_TRANSLATION_KEYS {
  ADDRESS = 'Address',
  ADDRESS_LINE_1 = 'Address Line 1',
  ADDRESS_LINE_2 = 'Address Line 2',
  ADDRESSES = 'Addresses',
  CITY = 'City',
  CONTACT_INFORMATION = 'Contact Information',
  COUNTRY = 'Country',
  DATE_FORMAT = 'Date Format',
  DATE_OF_BIRTH = 'Date of Birth',
  DD_MM_YYYY = 'DD-MM-YYYY',
  EMAIL = 'Email',
  EMAIL_TYPE = 'Email Type',
  EMAILS = 'Emails',
  FIRST_NAME = 'First Name',
  GENDER = 'Gender',
  INCORRECT_CONFIGURATION_ERROR_MESSAGE = 'Incorrect configuration: no contact found.',
  LANGUAGE = 'Language',
  LAST_NAME = 'Last Name',
  MIDDLE_NAMES = 'Middle Names',
  MM_DD_YYYY = 'MM-DD-YYYY',
  PHONE_NUMBER = 'Phone Number',
  PHONE_TYPE = 'Phone Type',
  PHONES = 'Phones',
  POSTAL_CODE = 'Postal Code',
  PREFERENCES = 'Preferences',
  PRIMARY = 'Primary',
  PROFILE_INFORMATION = 'Profile Information',
  PROVINCE = 'Province',
  STATE = 'State',
  TITLE = 'Title',
  USER_ID = 'User ID',
  YYYY_MM_DD = 'YYYY-MM-DD',
  ZIP_CODE = 'ZIP Code'
}

export enum DATE_RANGES_TRANSLATION_KEYS {
  DAY = '1 Day',
  WEEK = '1 Week',
  LAST_MONTH = 'Last Month',
  MONTH = '1 Month',
  LAST_QUARTER = 'Last 3 Months',
  QUARTER = '3 Months',
  LAST_SIX_MONTHS = 'Last 6 Months',
  SIX_MONTHS = '6 Months',
  YTD = 'Year-to-Date',
  YTD_ALT = 'Year-to-Date (YtD)',
  MTD = 'Month-to-Date',
  QTD = 'Quarter-to-Date',
  QTD_ALT = 'Quarter-to-Date (QtD)',
  LAST_YEAR = 'Last Year',
  YEAR = '1 Year',
  TWO_YEARS = '2 Years',
  LAST_THREE_YEARS = 'Last 3 Years',
  THREE_YEARS = '3 Years',
  FIVE_YEARS = '5 Years',
  CUSTOM = 'Custom',
  SINCE_INCEPTION = 'Since Inception',
  SINCE_INCEPTION_ALT = 'Since Inception (ItD)',
  SINCE_ACTIVATION = 'Since Activation',
  LAST_MONTHLY = 'Monthly',
  LAST_QUARTERLY = 'Quarterly',
  LAST_ANNUALLY = 'Annual',
  CUSTOM_PERIOD_SELECTION = 'Custom_calculation_label'
}

export enum ERROR_MESSAGES_TRANSLATION_KEYS {
  NETWORK_ERROR = ERROR_MESSAGE.networkError,
  INTERNAL_SERVER_ERROR = ERROR_MESSAGE.internalServerError,
  BAD_GATEWAY = ERROR_MESSAGE.badGateway,
  SERVICE_UNAVAILABLE = ERROR_MESSAGE.serviceUnavailble,
  MAINTENANCE = ERROR_MESSAGE.maintenance,
  FORBIDDEN = ERROR_MESSAGE.forbidden,
  NOT_FOUND = ERROR_MESSAGE.notFound,
  ENTITY_NOT_FOUND = ERROR_MESSAGE.entityNotFound,
  UNEXPECTED_ERROR = ERROR_MESSAGE.unexpectedError
}

export enum INVESTMENTS_PAGE_TRANSLATION_KEYS {
  ALLOCATION_BY_ACCOUNT = 'Allocation by Account',
  ALLOCATION_BY_ASSET_CLASS = 'Allocation by Asset Class',
  ALLOCATION_BY_SECURITY = 'Allocation by Security',
  MARKET_VALUE = 'Market Value',
  TOTAL_RETURN = 'Total Return'
}

export enum DMS_PAGE_TRANSLATION_KEYS {
  ADD = 'Add',
  ADD_DOCUMENT_LABEL = 'Add Document',
  ADDING = 'Adding...',
  ALL_DOCUMENTS = 'All Documents',
  CANNOT_DOWNLOAD_MORE_THAN = 'Cannot download more than',
  COULD_NOT_FIND_DOWNLOAD_LINK_FOR = 'Could not find download link for',
  DELETE = 'Delete',
  DELETED_SUCCESSFULLY = 'Deleted successfully',
  DOCUMENT_CATEGORY = 'Document Category',
  DOCUMENTS_LABEL = 'Documents',
  DOWNLOAD = 'Download',
  DOWNLOADED_SUCCESSFULLY = 'Downloaded successfully',
  EDIT = 'Edit',
  FILES_SIMULTANEOUSLY = 'files simultaneously',
  FILTER = 'Filter',
  LAST_MODIFIED = 'Last Modified',
  MARK_ALL_AS_READ = 'Mark all as read',
  MARK_ALL_AS_UNREAD = 'Mark all as unread',
  MARK_AS_READ = 'Mark as read',
  MARK_AS_UNREAD = 'Mark as unread',
  MESSAGE = 'Message (optional)',
  NAME = 'Name',
  NO_DOCUMENTS_FOUND = 'No Documents Found',
  NO_RESULTS = 'No results. Try applying a different search or status filter.',
  NO_SUBCATEGORIES_AVAILABLE = 'There are no subcategories available.',
  NOTIFY = 'Notify',
  SEARCH_DOCUMENTS = 'Search documents',
  TOGGLE_SUBCATEGORY_VALUES = 'Toggle filters by clicking on the <strong>sub-categories</strong> below. <strong>Sub-categories</strong> in grey will be hidden.',
  UNEXPECTED_ERROR = 'An unexpected error occurred',
  UNREAD_ITEM = 'unread item',
  UNREAD_ITEMS = 'unread items',
  UPLOAD_DOCUMENT_DESCRIPTION = 'Add a document below or select one of the categories to view your documents.'
}

export enum PRIVACY_DECLARATION_TRANSLATION_KEYS {
  PRIVACY_DECLARATION_UPDATED_SUCCESSFULLY = 'Privacy settings updated successfully',
  AN_UNEXPECTED_ERROR_OCCURRED = 'An unexpected error occurred while updating your privacy settings'
}

export enum FOOTER_TRANSLATION_KEYS {
  EMAIL = 'Email',
  FAX = 'Fax',
  GET_IN_TOUCH = 'Get In Touch',
  LEARN_MORE = 'Learn more',
  LEGAL = 'Legal',
  MAIN = 'Main',
  OUR_OFFICES = 'Our Offices',
  RESOURCES = 'Resources'
}

export const ALL_INVESTOR_TRANSLATION_KEYS = {
  ...ACTIVITY_PAGE_TRANSLATION_KEYS,
  ...COMPONENT_SPECIFIC_TRANSLATION_KEYS,
  ...DATE_RANGES_TRANSLATION_KEYS,
  ...DMS_PAGE_TRANSLATION_KEYS,
  ...DOCUMENTS_PAGE_TRANSLATION_KEYS,
  ...ERROR_MESSAGES_TRANSLATION_KEYS,
  ...FOOTER_TRANSLATION_KEYS,
  ...HOME_PAGE_TRANSLATION_KEYS,
  ...INVESTMENTS_PAGE_TRANSLATION_KEYS,
  ...LOGIN_PAGE_TRANSLATION_KEYS_SHARED,
  ...OTHER_TRANSLATION_KEYS,
  ...PRIVACY_DECLARATION_TRANSLATION_KEYS,
  ...SECTION_TITLE_TRANSLATION_KEYS,
  ...SETTINGS_PAGE_TRANSLATION_KEYS
}
