import {ApiParam} from 'fairlight'

import {IAccountGroup, IChartTable} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class AccountGroupEndpoints extends BaseRestEndpoints {
  static basePath = '/account-groups'

  static chart() {
    return super._chart({})
  }

  static singleChart(id: ApiParam) {
    return super._post<IChartTable>(`${super._singlePath(id)}/chart`, {
      body: {}
    })
  }

  static findById(id: ApiParam) {
    return super._findById<IAccountGroup>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IAccountGroup>) {
    return super._partialUpdate<IAccountGroup>(id, body)
  }

  static create(body: IAccountGroup) {
    return super._create<IAccountGroup>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static upload(body: FormData) {
    return super._post<never>('/upload-groups', {body})
  }

  static uploadAccounts(body: FormData) {
    return super._post<never>('/upload-accounts', {body})
  }
}
