import {RouteBuilder} from '@d1g1t/lib/url'

export class ContactsLocations extends RouteBuilder {
  static basePath = '/contacts'

  static contactCreate() {
    return super.path('/create')
  }

  static contactSingleEdit(id: string) {
    return super.path(`/edit/${id}`)
  }

  static contactSingleView(id: string) {
    return super.path(`/view/${id}`)
  }
}
