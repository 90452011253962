import {IChartTable} from '@d1g1t/api/models'

import {BaseEndpoints} from './base'

export class GoalEndpoints extends BaseEndpoints {
  static basePath = '/goals'

  static marketRiskReturns() {
    return super._get<IChartTable>('/market-risk-returns')
  }
}
