import * as Yup from 'yup'

import {
  ALL_MODELS,
  METRIC_Q_INTERVAL_BASIC_PERF_VALUES_ANNUALIZED_GIPS_RETURN_YTD,
  METRIC_Q_INTERVAL_BASIC_PERF_VALUES_INTERN_INVEST_PN_L_YTD,
  METRIC_Q_SPOT_HOLDING_VALUES_CASH_AMOUNT,
  METRIC_Q_SPOT_HOLDING_VALUES_VALUE
} from '@d1g1t/api/models'

import {ISelectEntitiesState} from '@d1g1t/shared/containers/select-entities'
import {I_SELECTED_ENTITY_SCHEMA} from '@d1g1t/shared/wrappers/calculation-settings'

export const DEFAULT_BASIC_INFO_METRICS = [
  METRIC_Q_SPOT_HOLDING_VALUES_VALUE,
  METRIC_Q_INTERVAL_BASIC_PERF_VALUES_INTERN_INVEST_PN_L_YTD,
  METRIC_Q_INTERVAL_BASIC_PERF_VALUES_ANNUALIZED_GIPS_RETURN_YTD,
  METRIC_Q_SPOT_HOLDING_VALUES_CASH_AMOUNT
]

export const ALL_MODELS_TO_ISELECTEDENTITIES_KEY_MAP = {
  [ALL_MODELS.INDIVIDUAL]: 'clients',
  [ALL_MODELS.HOUSEHOLD]: 'households',
  [ALL_MODELS.PORTFOLIO]: 'portfolios',
  [ALL_MODELS.CORPORATION]: 'clients',
  [ALL_MODELS.FOUNDATION]: 'clients',
  [ALL_MODELS.TRUST]: 'clients',
  [ALL_MODELS.ACCOUNTGROUP]: 'accountGroups',
  [ALL_MODELS.INVESTMENTMANDATE]: 'investmentMandates',
  [ALL_MODELS.CLASSSERIES]: 'class_series'
}

export const SELECT_ENTITIES_QUERY_PARAMS_VALIDATION_SCHEMA =
  Yup.object<ISelectEntitiesState>({
    currency: Yup.string(),
    date: Yup.string(),
    filterByType: Yup.array().of(Yup.string()),
    focused: I_SELECTED_ENTITY_SCHEMA,
    selected: Yup.array().of(I_SELECTED_ENTITY_SCHEMA)
  })

export const SELECT_ENTITIES_KEY = 'selectEntities'
