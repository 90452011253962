import React, {useCallback, useState} from 'react'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import {Flex} from '@d1g1t/shared/components/flex'
import {CircularProgress} from '@d1g1t/shared/components/mui/circular-progress'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {ITextTagProps, TextTag} from '@d1g1t/shared/components/text-tag'
import {H3, P, Span, Text} from '@d1g1t/shared/components/typography'

import controlBoxStyles from '@d1g1t/shared/components/control-box/styles.scss'

interface ISelectTextTagProps {
  loading?: boolean
  widgetTitle: string
  widgetDescription: string | JSX.Element
  /**
   * Array of `value` properties of `options`
   */
  selectedOptions: Nullable<string[]>
  options: ITextTagProps[]
  /**
   * Number of options that will be displayed without clicking the "Show More" button
   */
  maxOptions?: number
  onOptionClicked(value: string[]): void
}

export const SelectTextTags: React.FC<ISelectTextTagProps> = (props) => {
  const [optionsExpanded, setOptionsExpanded] = useState(false)

  const displayShowMoreButton =
    props.maxOptions && props.options.length > props.maxOptions

  const showAllOptions = !displayShowMoreButton || optionsExpanded
  const displayedOptions = showAllOptions
    ? props.options
    : props.options.slice(0, props.maxOptions)

  const allOptionsSelected =
    props.selectedOptions.length === props.options.length

  const handleOptionClick = (value) => () => {
    const foundIndex = props.selectedOptions.findIndex(
      (selectedTransactionType) => selectedTransactionType === value
    )
    if (foundIndex >= 0) {
      const optionsWithoutClicked = [...props.selectedOptions]
      optionsWithoutClicked.splice(foundIndex, 1)
      props.onOptionClicked(optionsWithoutClicked)
    } else {
      props.onOptionClicked([...props.selectedOptions, value])
    }
  }

  const handleSelectAll = useCallback(() => {
    const allOptions = props.options.map(
      (displayedOption) => displayedOption.value
    )
    props.onOptionClicked(allOptions)
  }, [props.options])

  const handleClearAll = () => props.onOptionClicked([])

  return (
    <div className={controlBoxStyles.controlBox}>
      <Flex row alignCenter justifyFlexEnd>
        <Flex grow justifyFlexStart>
          <H3 semiBold>{props.widgetTitle}</H3>
        </Flex>
        <P
          primaryColour
          pointer
          preventHighlight
          noBottomMargin
          onClick={allOptionsSelected ? handleClearAll : handleSelectAll}
        >
          {allOptionsSelected ? 'Clear All' : 'Select All'}
        </P>
      </Flex>
      <Spacer xxs />
      <Text fontSize={12}>{props.widgetDescription}</Text>
      <Spacer xxs />
      {props.loading ? (
        <Flex justifyCenter alignCenter fullHeight>
          <CircularProgress />
        </Flex>
      ) : (
        displayedOptions.map((option, index) => {
          const isSelected = props.selectedOptions?.includes(option.value)
          return (
            <div
              key={`select-text-tag-option-${index}`}
              style={{marginBottom: '5px'}}
            >
              <TextTag
                value={option.value}
                title={option.title}
                disabledStyle={option.disabledStyle || !isSelected}
                onClick={handleOptionClick(option.value)}
              />
            </div>
          )
        })
      )}
      <Spacer xxs />
      {displayShowMoreButton && (
        <Flex
          row
          alignCenter
          justifySpaceBetween
          pointer
          onClick={() => setOptionsExpanded(!optionsExpanded)}
        >
          <Span primaryColour>
            {optionsExpanded ? 'Show Less' : 'Show More'}
          </Span>
          <Span primaryColour>
            {optionsExpanded ? (
              <KeyboardArrowUpIcon style={{marginTop: 5}} />
            ) : (
              <KeyboardArrowDownIcon style={{marginTop: 5}} />
            )}
          </Span>
        </Flex>
      )}
    </div>
  )
}
