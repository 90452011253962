import {ApiParam} from 'fairlight'

import {ICorporation} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeopleCorporationEndpoints extends BaseRestEndpoints {
  static basePath = '/people/corporations'

  static create(body: ICorporation) {
    return super._create<ICorporation>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<ICorporation>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<ICorporation>) {
    return super._partialUpdate<ICorporation>(id, body)
  }
}
