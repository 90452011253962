import {IClientTenure} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class PeopleClientTenureEndpoints extends BaseRestEndpoints {
  static basePath = '/people/client-tenure'

  static list() {
    return super._list<IApiListResponse<IClientTenure>>()
  }
}
