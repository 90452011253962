import {ApiParam} from 'fairlight'

import {IPermissionGroup} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class UserAccountPermissionGroupEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/permission-groups'

  static findById(id: ApiParam) {
    return super._findById<IPermissionGroup>(id)
  }
}
