import {UseApiQueryData} from 'fairlight'

import {DATE_RANGES, IFirmConfiguration} from '@d1g1t/api/models'

/**
 * This interface is controlled by front-end team.
 * Its purpose is to add various configuration options for a firm without requiring back-end work.
 *
 * Follow good naming conventions. Always use positive variable names .. i.e. `showFeatureABC` instead of `hideFeatureABC`
 * Organize the interface by investor vs. advisor app, by specific pages, and by specific widgets. One option that affects multiple page should be at the app level, explain this in the docstring.
 * Once you classify an option as a 'home' page option, its name no longer needs to include 'home' in it.
 * Never use presence/absence of a key as business logic, explicitly set the key as `true` || `false`.
 *
 * @remarks MUST DOCUMENT every change in Notion page: https://www.notion.so/d1g1t/UI-Configuration-Documentation-a54e38b607784195937bfeae07a12cce
 */
export interface ICommonInvestorPagesUIConfiguration {
  /**
   * Used to replace default page title
   */
  pageTitle: {
    en: string
    fr: string
  }
  /**
   * Used to replace default page level date range
   */
  defaultPageLevelDateRange: DATE_RANGES
}

export interface INavigationWidgetItem
  extends Pick<ICommonInvestorPagesUIConfiguration, 'pageTitle'> {
  /**
   *  Used to control the visibility of the Navigation Widget elements
   */
  visibility: boolean
  /**
   * Contains a set of filters applied on the "Activity" page
   */
  filterByType: string[]
}

export interface IInvestorHomeUIConfiguration
  extends ICommonInvestorPagesUIConfiguration {
  /**
   * Displays the "What can we help you with today" widget instead of the line graph,
   * if "visibility" not set to `false`.
   * Each subsequent property enables/disables one of the 3 cards available for disabling.
   */
  navigationWidget: {
    income: INavigationWidgetItem
    deposits: INavigationWidgetItem
    /**
     * Since the configuration of the "settings" is configured separately,
     * then at this place we only control its visibility within the Navigation Widget
     */
    settings: Pick<INavigationWidgetItem, 'visibility'>
    /**
     * Used to control the visibility of the Navigation Widget
     */
    visibility: boolean
  }
}

export enum UI_CONFIG_INVESTOR_PAGES_KEYS {
  HOME = 'home',
  INVESTMENTS = 'investments',
  ACTIVITY = 'activity',
  DOCUMENTS = 'documents',
  SETTINGS = 'settings',
  PRIVACY_DECLARATION = 'privacyDeclaration'
}

export interface IInvestorInvestmentsUIConfiguration
  extends ICommonInvestorPagesUIConfiguration {}

export interface IInvestorActivityUIConfiguration
  extends ICommonInvestorPagesUIConfiguration {}

export interface IInvestorDocumentsUIConfiguration
  extends ICommonInvestorPagesUIConfiguration {}

export interface IInvestorSettingsUIConfiguration
  extends Pick<ICommonInvestorPagesUIConfiguration, 'pageTitle'> {}

export interface IInvestorPrivacyDeclarationUIConfiguration
  extends Pick<ICommonInvestorPagesUIConfiguration, 'pageTitle'> {}

export interface IUiConfiguration {
  investor: {
    [UI_CONFIG_INVESTOR_PAGES_KEYS.HOME]: IInvestorHomeUIConfiguration
    [UI_CONFIG_INVESTOR_PAGES_KEYS.INVESTMENTS]: IInvestorInvestmentsUIConfiguration
    [UI_CONFIG_INVESTOR_PAGES_KEYS.ACTIVITY]: IInvestorActivityUIConfiguration
    [UI_CONFIG_INVESTOR_PAGES_KEYS.DOCUMENTS]: IInvestorDocumentsUIConfiguration
    [UI_CONFIG_INVESTOR_PAGES_KEYS.SETTINGS]: IInvestorSettingsUIConfiguration
    [UI_CONFIG_INVESTOR_PAGES_KEYS.PRIVACY_DECLARATION]: IInvestorPrivacyDeclarationUIConfiguration
    /**
     * Removes the Firm Provided Key of the accounts from the EntityChip dropdown options' names.
     */
    hideEntityChipAccountFirmProvidedKey: boolean
    /**
     * Alters the line graph on "Home" and "Investments" pages to show two lines: the 'market value' line, and the 'market value and net deposits' line.
     */
    showMarketValueAndNetDeposits: boolean
  }
  advisor: {
    client: {
      monitor: {
        overview: {
          /**
           * When passed, enables 'Net Asset Value History', 'Inflows/Outflows', 'Investment Flows', 'Transactions' widgets
           * on "Manage Client" page's "Overview" tab. These are available in the "Activity" tab otherwise.
           */
          showPortfolioActivityWidgets: boolean
        }
      }
    }
    monitor: {
      overview: {
        /**
         * When passed, enables 'Net Asset Value History', 'Inflows/Outflows', 'Investment Flows', 'Transactions' widgets
         * on "Monitor Business" page. These are available in the "Activity" tab otherwise.
         */
        showPortfolioActivityWidgets: boolean
      }
    }
    /**
     * If set to `true`, the environment's API version is shown with the top-right user initials icon.
     */
    showApiTagVersion: boolean
  }
}
export interface IUseFirmConfigurationHookReturnValue {
  firmConfiguration:
    | UseApiQueryData<IFirmConfiguration>
    | {data: null; loading: boolean; error: null}
  firmUiConfig:
    | Loadable<IUiConfiguration>
    | {data: null; loading: boolean; error: null}
}
export const DEFAULT_UI_CONFIGURATION: DeepRequired<IUiConfiguration> = {
  advisor: {
    client: {
      monitor: {
        overview: {
          showPortfolioActivityWidgets: false
        }
      }
    },
    monitor: {
      overview: {
        showPortfolioActivityWidgets: false
      }
    },
    showApiTagVersion: false
  },
  investor: {
    [UI_CONFIG_INVESTOR_PAGES_KEYS.HOME]: {
      navigationWidget: {
        income: {
          pageTitle: {
            en: 'Income Generated',
            fr: 'Revenus générés'
          },
          visibility: true,
          filterByType: []
        },
        deposits: {
          pageTitle: {
            en: 'Deposits and Withdrawals',
            fr: 'Dépôts et retraits'
          },
          visibility: true,
          filterByType: []
        },
        settings: {
          visibility: true
        },
        visibility: true
      },
      pageTitle: {
        en: 'Home',
        fr: 'Accueil'
      },
      defaultPageLevelDateRange: DATE_RANGES.YEAR
    },
    [UI_CONFIG_INVESTOR_PAGES_KEYS.INVESTMENTS]: {
      pageTitle: {
        en: 'Investments',
        fr: 'Portefeuille'
      },
      defaultPageLevelDateRange: DATE_RANGES.YEAR
    },
    [UI_CONFIG_INVESTOR_PAGES_KEYS.ACTIVITY]: {
      pageTitle: {
        en: 'Activity',
        fr: 'Activité'
      },
      defaultPageLevelDateRange: DATE_RANGES.YEAR
    },
    [UI_CONFIG_INVESTOR_PAGES_KEYS.DOCUMENTS]: {
      pageTitle: {
        en: 'Documents',
        fr: 'Documents'
      },
      defaultPageLevelDateRange: DATE_RANGES.SIX_MONTHS
    },
    [UI_CONFIG_INVESTOR_PAGES_KEYS.SETTINGS]: {
      pageTitle: {
        en: 'Settings',
        fr: 'Réglages'
      }
    },
    [UI_CONFIG_INVESTOR_PAGES_KEYS.PRIVACY_DECLARATION]: {
      pageTitle: {
        en: 'Privacy',
        fr: 'Confidentialité'
      }
    },
    hideEntityChipAccountFirmProvidedKey: false,
    showMarketValueAndNetDeposits: false
  }
}
