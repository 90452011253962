import React from 'react'

import {useApiQuery} from 'fairlight'

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'

import {AuthEndpoints} from '@d1g1t/api/endpoints'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {H4} from '@d1g1t/shared/components/typography'
import {useEmulation} from '@d1g1t/shared/wrappers/emulation'

import {COMPONENT_SPECIFIC_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

import {useStyles} from './use-styles'

/**
 * Adds sticky banner on top and sticky borders around the app
 * if advisor user logins as investor-user (emulator mode)
 */
export const EmulatorBorders: React.FC = (props) => {
  const classes = useStyles()
  const [userData] = useApiQuery(AuthEndpoints.me(), {
    fetchPolicy: 'cache-first'
  })
  const emulator = useEmulation()

  if (!emulator || !userData.data) {
    return null
  }

  return (
    <>
      <Flex
        alignCenter
        justifyCenter
        className={classNames(classes.emulatorBanner, classes.emulator)}
      >
        <SupervisedUserCircleIcon />
        <H4 className={classes.emulatorH4}>
          {COMPONENT_SPECIFIC_TRANSLATION_KEYS.CURRENTLY_EMULATING}{' '}
          {userData.data.firstName} {userData.data.lastName}{' '}
          {COMPONENT_SPECIFIC_TRANSLATION_KEYS.AS} {emulator.firstName}{' '}
          {emulator.lastName}
        </H4>
      </Flex>
      <div className={classNames(classes.emulatorLeftLine, classes.emulator)} />
      <div
        className={classNames(classes.emulatorRightLine, classes.emulator)}
      />
      <div
        className={classNames(classes.emulatorBottomLine, classes.emulator)}
      />
    </>
  )
}
