import {config} from './config'

const date = new Date()

/**
 * Typically shown with text of "Only available in d1g1t environments."
 * Typically shown with icon of 'material-ui/icons/NewReleases'
 */
export const FEATURE_FLAGS = {
  /**
   * Controls heart progress loader visibility between 12 and 14 February in d1g1t envs.
   */
  VALENTINES_DAY_HEART_PROGRESS:
    config.enableUnreleasedFeatures &&
    date.getMonth() === 1 &&
    date.getDate() >= 12 &&
    date.getDate() <= 14,
  /**
   * d1g1t env passwords are 16 char minimum.
   */
  PASSWORD_COMPLEXITY: config.enableUnreleasedFeatures,
  /**
   * Shows a link to see all login methods in d1g1t env.
   */
  SHOW_ALL_AUTH_METHODS: config.enableUnreleasedFeatures,
  /**
   * Run report option in Datasets
   * Run report option in Collections
   * Workflow version in report template cph table
   * Use for collection/dataset checkbox in report generation
   */
  REPORT_WORKFLOW: config.reportWorkflow,
  /**
   * Changes the sort order, and default selected activity types
   * on the investor portal's activity page
   */
  BBR_ACTIVITY_TYPES_SORT_ORDER: config.bbrActivityTypesSortOrder,
  /**
   * Hide "log in scale" option from line charts three dots menu
   */
  SHOW_IN_LOG_SCALE: config.enableUnreleasedFeatures
}
