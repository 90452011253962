import {IInstrumentRegion} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class InstrumentRegionEndpoints extends BaseRestEndpoints {
  static basePath = '/instrumentregions'

  static list() {
    return super._list<IApiListResponse<IInstrumentRegion>>()
  }
}

export class InstrumentRegionVectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/region-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
