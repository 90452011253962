import React from 'react'

import {ApiError, ResponseBody} from 'fairlight'

import {CustomDisplayedError, EntityNotFound, ERROR_MESSAGE} from './'

export function getErrorDisplayMessage(error): React.ReactNode {
  if (error === null || error === undefined) {
    return ''
  }

  if (isFetchNetworkError(error)) {
    return ERROR_MESSAGE.networkError
  }

  if (error instanceof ApiError) {
    return getErrorDisplayMessageForApiError(error)
  }

  if (error instanceof EntityNotFound) {
    return ERROR_MESSAGE.entityNotFound
  }

  if (error instanceof CustomDisplayedError) {
    return error.toString()
  }

  if (error instanceof Error) {
    return ERROR_MESSAGE.unexpectedError
  }

  return error.toString()
}

function getErrorDisplayMessageForApiError<T extends ResponseBody>(
  error: ApiError<T>
): React.ReactNode {
  return mapResponseStatus(error.status)
}

function isFetchNetworkError(error: Error): boolean {
  return error instanceof TypeError && error.message === 'Failed to fetch'
}

const mapResponseStatus = (status: number): ERROR_MESSAGE => {
  switch (status) {
    case 500:
      return ERROR_MESSAGE.internalServerError
    case 502:
      return ERROR_MESSAGE.badGateway
    case 503:
      return ERROR_MESSAGE.serviceUnavailble
    case 403:
      return ERROR_MESSAGE.forbidden
    case 404:
      return ERROR_MESSAGE.notFound
    case 422:
      return ERROR_MESSAGE.maintenance
    default:
      return ERROR_MESSAGE.unexpectedError
  }
}
