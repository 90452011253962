import {RouteBuilder} from '@d1g1t/lib/url'

export class GlobalFilterLocations extends RouteBuilder {
  static basePath = 'global-filters'

  static single(id: string) {
    return super.path(`/${id}`)
  }

  static create() {
    return super.path('/new')
  }
}
