/**
 * Ordered chart colours. These are used to show the slices/bars of a pie/bar graph in specific colour order.
 */
export const defaultd1g1tChartColoursOrdered = [
  '#64b5f6',
  '#7986cb',
  '#ba68c8',
  '#f06292',
  '#4dd0e1',
  '#4db6ac',
  '#aed581',
  '#fff176',
  '#ffb74d',
  '#ff8a65',
  '#0277bd',
  '#303f9f',
  '#7b1fa2',
  '#c2185b',
  '#0097a7',
  '#00796b',
  '#1b5e20',
  '#cddc39',
  '#ff6f00',
  '#d84315'
]
