import React from 'react'
import {useTranslation} from 'react-i18next'

import {FormControl} from '@d1g1t/shared/components/mui/form-control'
import {FormHelperText} from '@d1g1t/shared/components/mui/form-helper-text'
import {FormLabel} from '@d1g1t/shared/components/mui/form-label'
import {
  IRadioGroupProps,
  RadioGroup
} from '@d1g1t/shared/components/mui/radio-group'

import {useFormFieldControl} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'

interface IRadioGroupFieldProps extends IFormFieldProps {
  radioGroupProps?: Omit<IRadioGroupProps, 'name' | 'value' | 'onChange'>
  children: React.ReactNode[]
  displayHelperTextAbove?: boolean
}

/**
 * `formik`-compatible `RadioGroup` field
 */
export const RadioGroupField: React.FC<IRadioGroupFieldProps> = (props) => {
  const {t} = useTranslation()

  const {children, radioGroupProps = {}} = props

  const [field, meta] = useFormFieldControl(props)
  const {hasError, errorText} = getFormFieldErrorState(meta)

  const id = props.id || props.name
  const errorTextId = `${id}-error-text`

  const helperText = (
    <FormHelperText id={errorTextId}>{errorText}</FormHelperText>
  )

  return (
    <FormControl error={hasError} variant='outlined' fullWidth>
      {props.label && <FormLabel>{t(props.label)}</FormLabel>}
      {props.displayHelperTextAbove && helperText}
      <RadioGroup
        id={id}
        {...radioGroupProps}
        {...field}
        value={field.value}
        aria-describedby={errorTextId}
      >
        {children}
      </RadioGroup>
      {!props.displayHelperTextAbove && helperText}
    </FormControl>
  )
}
