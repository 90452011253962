import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import {useApi, useApiQuery} from 'fairlight'
import FileSaver from 'file-saver'
import {orderBy} from 'lodash'
import useArrayState from 'use-array-state'

import DownloadIcon from '@material-ui/icons/CloudDownload'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {WorkDocsEndpoints, WorkDocsTypesEndpoints} from '@d1g1t/api/endpoints'
import {
  DATE_RANGES,
  IDocumentSubType,
  IDocumentType,
  IWorkdocsDocument
} from '@d1g1t/api/models'

import {
  DATE_OPTION_DEFAULT,
  DEFAULT_DATE_OPTIONS,
  getDateRangeOption
} from '@d1g1t/lib/date-range'
import {DateFormatter} from '@d1g1t/lib/formatters/date-formatter'
import {useToggleState} from '@d1g1t/lib/hooks'

import {Card} from '@d1g1t/shared/components/card'
import {ExpansionPanelSummary} from '@d1g1t/shared/components/expansion-panel'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {LinearProgress} from '@d1g1t/shared/components/mui/linear-progress'
import {TextTag} from '@d1g1t/shared/components/text-tag'
import {TwoColumnPageGrid} from '@d1g1t/shared/components/two-column-page-grid'
import {H2, H3, H4, Text} from '@d1g1t/shared/components/typography'
import {ExpansionPanelSaved} from '@d1g1t/shared/containers/expansion-panel-saved'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {UI_CONFIG_INVESTOR_PAGES_KEYS} from '@d1g1t/shared/wrappers/firm-configuration/constants'
import {useExternalProfileLocalizationPreferences} from '@d1g1t/shared/wrappers/localization-settings/hook'

import {PagePadding} from '@d1g1t/investor/components/page-padding'
import {InvestorPageTitle} from '@d1g1t/investor/containers/page-title'
import {DOCUMENTS_PAGE_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'
import {usePageUIConfiguration} from '@d1g1t/investor/wrappers/page-ui-config/hook'

import {DocumentsReportsFilters} from './components/documents-reports-filters'
import {
  filterDocumentsDateRange,
  filterDocumentsSubtype,
  groupDocuments
} from './lib'

import * as css from './style.scss'

interface IReportsPageContentProps {
  documentType: IDocumentType
  defaultPageLevelDateRange: DATE_RANGES
}

/**
 * List of documents page.
 */
export const DocumentTypePage: React.FC = () => {
  const location = useLocation()
  const {firmConfiguration, firmUiConfig} = useFirmConfiguration()

  const [title] = usePageUIConfiguration(
    UI_CONFIG_INVESTOR_PAGES_KEYS.DOCUMENTS
  )

  const [documentType] = useApiQuery(
    WorkDocsTypesEndpoints.findByType(location.pathname.split('/')[2] ?? '')
  )

  if (documentType.loading) {
    return <LinearProgress />
  }
  return (
    <>
      <PagePadding>
        <InvestorPageTitle
          title={documentType.data?.name ?? title}
          asOfDateText={firmConfiguration.data?.latestDataAvailable}
        />
        <ErrorBoundary resetId={documentType.data?.slug}>
          <DocumentTypePageContent
            documentType={documentType.data}
            defaultPageLevelDateRange={
              firmUiConfig.data?.investor.documents.defaultPageLevelDateRange
            }
          />
        </ErrorBoundary>
      </PagePadding>
    </>
  )
}

const DocumentTypePageContent: React.FC<IReportsPageContentProps> = (props) => {
  const api = useApi()
  const {handleUnexpectedError} = useErrorHandler()
  const {i18n} = useTranslation()

  const [profileSettings] = useExternalProfileLocalizationPreferences()

  const [documents] = useApiQuery(
    WorkDocsEndpoints.listByType({type: props.documentType.originalName})
  )

  const [dateRange, setDateRange] = useState(getDefaultDate)
  const [subTypesLoading, setSubTypesLoading] = useToggleState(false)
  const [selectedDocumentSubTypeFilter, selectedDocumentSubTypeFilterActions] =
    useArrayState<Nullable<IDocumentSubType>>(null)

  function getDefaultDate() {
    const {defaultPageLevelDateRange} = props
    if (
      DEFAULT_DATE_OPTIONS.find(
        (option) => option.value === defaultPageLevelDateRange
      )
    ) {
      return getDateRangeOption(defaultPageLevelDateRange)
    }
    return DATE_OPTION_DEFAULT
  }

  const handleDocumentDownloadClick = async (document: IWorkdocsDocument) => {
    try {
      const portfolioStatement = await api.request(
        WorkDocsEndpoints.download(document.versionId)
      )

      FileSaver.saveAs(
        new Blob([portfolioStatement], {
          type: 'application/pdf'
        }),
        document.filename
      )
    } catch (error) {
      handleUnexpectedError(error, 'Could not download portfolio statement.')
    }
  }

  const handleDocumentSubTypeFilterClick = (subType: IDocumentSubType[]) => {
    selectedDocumentSubTypeFilterActions.set(subType)
  }

  const groupedAndFilteredDocumentPanelElements = (() => {
    if (!documents.data || subTypesLoading) {
      return null
    }

    const filteredDocuments = filterDocumentsDateRange(
      filterDocumentsSubtype(
        orderBy(documents.data, (document) => document.reportingDate, 'desc'),
        selectedDocumentSubTypeFilter
      ),
      dateRange
    )
    const groupedDocuments = groupDocuments(
      filteredDocuments,
      props.documentType.groupBy,
      i18n.language
    )
    return Object.entries(groupedDocuments).map(
      ([dateHeader, documentsList]) => (
        <ExpansionPanelSaved key={dateHeader} id={dateHeader}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={css.expansionPanel}>
              <H2>{dateHeader}</H2>
            </div>
          </ExpansionPanelSummary>
          <div className={css.card}>
            {documentsList.map((document) => (
              <Grid
                key={document.versionId}
                container
                alignItems='center'
                className={css.report}
              >
                <Grid item xs={5}>
                  <Text bold>
                    {document.filename.split('.').slice(0, -1).join('.')}
                  </Text>
                </Grid>
                <Grid item xs={2}>
                  <Text>{document.fileType}</Text>
                </Grid>
                <Grid item xs={2}>
                  <Text>
                    {new DateFormatter({
                      dateFormat: profileSettings.dateFormat
                    }).format(document.reportingDate)}
                  </Text>
                </Grid>
                <Grid item xs={3}>
                  {document.subtype && (
                    <TextTag
                      title={document.subtype}
                      style={
                        document.subtypeColor
                          ? {backgroundColor: document.subtypeColor}
                          : null
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={1} container direction='row' justify='flex-end'>
                  <Button
                    onClick={() => {
                      handleDocumentDownloadClick(document)
                    }}
                  >
                    <DownloadIcon />
                  </Button>
                </Grid>
              </Grid>
            ))}
          </div>
        </ExpansionPanelSaved>
      )
    )
  })()

  const availableDocumentSubTypes = documents.data?.map(
    (document) => document.subtype
  )

  return (
    <LoadingContainer loading={documents.loading}>
      {documents.data?.length === 0 ? (
        <H4>{DOCUMENTS_PAGE_TRANSLATION_KEYS.NO_DOCUMENT_FOR_ACCOUNT}</H4>
      ) : (
        <TwoColumnPageGrid>
          <DocumentsReportsFilters
            dateRange={dateRange}
            selectedDocumentSubTypeFilter={selectedDocumentSubTypeFilter}
            onDateRangeChange={setDateRange}
            onDocumentSubTypeFilterChange={handleDocumentSubTypeFilterClick}
            availableDocumentSubTypes={availableDocumentSubTypes}
            setSubTypesLoading={setSubTypesLoading}
          />
          <div>
            {groupedAndFilteredDocumentPanelElements?.length ? (
              groupedAndFilteredDocumentPanelElements
            ) : (
              <Card noMinHeight>
                <H3>
                  {DOCUMENTS_PAGE_TRANSLATION_KEYS.NO_DOCUMENT_FOR_CATEGORY}
                </H3>
              </Card>
            )}
          </div>
        </TwoColumnPageGrid>
      )}
    </LoadingContainer>
  )
}

export default DocumentTypePage
