import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useApiQuery} from 'fairlight'

import {DocumentSubtypeEndpoints} from '@d1g1t/api/endpoints'
import {IDocumentSubType} from '@d1g1t/api/models'

import {DATE_OPTION_SINCE_INCEPTION} from '@d1g1t/lib/date-range'

import {IRangeSelectorProps} from '@d1g1t/shared/components/range-selector'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {ITextTagProps} from '@d1g1t/shared/components/text-tag'

import {SelectTextTags} from '@d1g1t/investor/components/select-text-tag'
import {SelectTimePeriod} from '@d1g1t/investor/components/select-time-period'
import {
  COMPONENT_SPECIFIC_TRANSLATION_KEYS,
  DOCUMENTS_PAGE_TRANSLATION_KEYS
} from '@d1g1t/investor/i18n/constants'
import {DOCUMENT_FILE_SUBCATEGORY_BLANK} from '@d1g1t/investor/pages/dms/documents/constants'

interface IDocumentsReportsFiltersProps
  extends Pick<IRangeSelectorProps, 'dateRange' | 'onDateRangeChange'> {
  /**
   * The available DocumentSubType based on documents returned
   * */
  availableDocumentSubTypes: Nullable<string[]>
  /**
   * Currently selected DocumentSubType that is used to filter the documents list
   * */
  selectedDocumentSubTypeFilter: Nullable<IDocumentSubType[]>
  /**
   * Provides the new selected DocumentSubtype to filter the documents list
   * */
  onDocumentSubTypeFilterChange(subtype: IDocumentSubType[]): void
  setSubTypesLoading(subTypeLoaded: boolean): void
}

/**
 * Section that controls the documents filter options.
 */
export const DocumentsReportsFilters: React.FC<
  IDocumentsReportsFiltersProps
> = (props) => {
  const [documentSubTypes] = useApiQuery(DocumentSubtypeEndpoints.list())
  const subTypes = documentSubTypes.data?.results ?? []
  const availableDocumentSubTypes = props.availableDocumentSubTypes ?? []
  const {i18n} = useTranslation()

  useEffect(() => {
    // Temporary solution until PS sends the translated filters
    if (subTypes.length) {
      const translations = subTypes.reduce(
        (acc, subType) => {
          acc.en = {...acc.en, [subType.originalName]: subType.originalName}
          acc.fr = {...acc.fr, [subType.originalName]: subType.name}
          return acc
        },
        {en: {}, fr: {}}
      )

      i18n.addResources('en', 'translation', translations.en)
      i18n.addResources('fr', 'translation', translations.fr)
    }
    props.setSubTypesLoading(documentSubTypes.loading)
  }, [subTypes])

  const subTypesWithBlankOption = useMemo(
    () => [...subTypes, {name: '', url: DOCUMENT_FILE_SUBCATEGORY_BLANK}],
    [subTypes]
  )

  const subtypesOptions = subTypes
    .filter((subType) => availableDocumentSubTypes.includes(subType.name))
    .map<ITextTagProps>((subType) => ({
      title: subType.name,
      value: subType.url
    }))
    .concat({
      title: COMPONENT_SPECIFIC_TRANSLATION_KEYS.BLANK,
      value: DOCUMENT_FILE_SUBCATEGORY_BLANK
    })

  const handleOptionClicked = (values: string[]) => {
    const docSubTypes = values.map(
      (value) =>
        subTypesWithBlankOption.find((subType) => subType.url === value) || null
    )

    props.onDocumentSubTypeFilterChange(docSubTypes)
  }

  return (
    <span>
      <SelectTimePeriod
        loading={!props.dateRange}
        selected={props.dateRange}
        onChange={props.onDateRangeChange}
        exclude={[DATE_OPTION_SINCE_INCEPTION]}
      />
      <Spacer xs />
      <SelectTextTags
        loading={!documentSubTypes.data?.results}
        selectedOptions={props.selectedDocumentSubTypeFilter.map(
          (selected) => selected.url
        )}
        options={subtypesOptions}
        onOptionClicked={handleOptionClicked}
        widgetTitle='Filter by Category'
        widgetDescription={
          subtypesOptions.length === 1
            ? DOCUMENTS_PAGE_TRANSLATION_KEYS.NO_CATEGORIES_AVAILABLE
            : DOCUMENTS_PAGE_TRANSLATION_KEYS.TOGGLE_CATEGORY_VALUES
        }
      />
    </span>
  )
}
