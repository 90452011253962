import {Store} from 'redux'

import {Api} from 'fairlight'

import {config} from '@d1g1t/config/config'

import {camelize, snakeify} from '@d1g1t/api/helpers'

import {authActions} from '@d1g1t/shared/wrappers/auth/actions'
import {getIsAuthenticated} from '@d1g1t/shared/wrappers/auth/getters'
import {errorShouldExpireSession} from '@d1g1t/shared/wrappers/error-handler/lib'

import {LAST_LOGIN_KEY, TOKEN_KEY} from './wrappers/auth/constants'

export function createApi(): Api {
  return new Api({
    baseUrl: config.api,
    serializeRequestJson: (body, requestParams) => {
      if (!requestParams.url.includes('/calc/')) {
        return snakeify(body)
      }

      return body
    },
    parseResponseJson: (body, requestParams) => {
      if (requestParams.url.includes('/entities-relations')) {
        return camelize(body, {startDepth: 1})
      }

      if (!requestParams.url.includes('/calc/')) {
        return camelize(body)
      }

      return body
    }
  })
}

/**
 * - Sets the initial `Authorization` header for all future requests
 * - On token expiration error, will trigger a logout
 * - Initializes auth redux saga
 */
export function initializeAuth(api: Api, store: Store): void {
  const token = sessionStorage.getItem(TOKEN_KEY)
  const returning = !!localStorage.getItem(LAST_LOGIN_KEY)

  if (token) {
    api.setDefaultHeader('Authorization', `JWT ${token}`)
  }

  api.onError.subscribe((error) => {
    if (
      getIsAuthenticated(store.getState()) &&
      errorShouldExpireSession(error)
    ) {
      store.dispatch(authActions.logoutRequest())
    }
  })

  store.dispatch(authActions.initialize({token, returning}))
}
