import {IGrouping} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class GroupingCriteriaEndpoints extends BaseRestEndpoints {
  static basePath = '/constants/groupings'

  static list(query: {limit?: number; slug?: string} = {}) {
    return super._list<IApiListResponse<IGrouping>>(query)
  }

  static findById(slug: string) {
    return super._findById<IGrouping>(slug)
  }
}
