import React from 'react'
import {useTranslation} from 'react-i18next'

import MuiListItemText, {ListItemTextProps} from '@material-ui/core/ListItemText'

import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

export interface IListItemTextProps extends ListItemTextProps {}

export const ListItemText: React.FC<IListItemTextProps> = (props) => {
  const {t} = useTranslation()
  return (
    <MuiListItemText {...props}>
      {onlyTranslateStrings(t, props.children)}
    </MuiListItemText>
  )
}

ListItemText.displayName = 'ListItemText(d1g1t)'
