import {ApiParam} from 'fairlight'

import {RouteBuilder} from '@d1g1t/lib/url'

export class DMSDocumentsLocations extends RouteBuilder {
  static basePath = '/dms'

  static documents() {
    return super.path('/manage-documents')
  }

  static upload() {
    return super.path('/manage-documents/upload')
  }

  static edit(id: ApiParam) {
    return super.path(`/manage-documents/${id}/edit`)
  }
}
