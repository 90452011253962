import {camelCase, reduce} from 'lodash'

import {
  IChartTable,
  IChartTableCategory,
  IChartTableData,
  IChartTableItem,
  IChartTableOptions
} from '@d1g1t/api/models'

import {
  performanceMarkStart,
  performanceMeasureFromStartMark
} from '@d1g1t/lib/performance'

export const camelizeChartTableOptions = ({
  ...options
}: any): IChartTableOptions => {
  return reduce(
    options,
    (result, value, key) => {
      return {
        ...result,
        [camelCase(key)]: value
      }
    },
    {}
  )
}

export const camelizeChartTableData = (dataItem): IChartTableData => {
  const data: IChartTableData = {
    categoryId: dataItem.category_id,
    key: dataItem.key,
    value: dataItem.value,
    valueType: dataItem.value_type,
    sortValue: dataItem.sort_value
  }

  if (dataItem.options) {
    data.options = camelizeChartTableOptions(dataItem.options)
  }

  return data
}

export const camelizeChartTableItems = (items: any[]): IChartTableItem[] => {
  return items.map((nextItem) => {
    const item: IChartTableItem = {
      id: nextItem.id,
      data: nextItem.data.map(camelizeChartTableData)
    }

    if (nextItem.items) {
      item.items = camelizeChartTableItems(nextItem.items)
    }

    if (nextItem.benchmarks) {
      item.benchmarks = camelizeChartTableItems(nextItem.benchmarks)
    }

    if (nextItem.parent_path) {
      item.parentPath = nextItem.parent_path
    }
    if (typeof nextItem.child_count === 'number') {
      item.childCount = nextItem.child_count
    }

    return item
  })
}

export const camelizeChartTableCategories = (
  categories: any[]
): IChartTableCategory[] => {
  return categories.map((nextCategory) => {
    const category: IChartTableCategory = {
      id: nextCategory.id,
      valueType: nextCategory.value_type,
      name: nextCategory.name
    }

    if (nextCategory.options) {
      category.options = camelizeChartTableOptions(nextCategory.options)
    }

    if (nextCategory.categories) {
      category.categories = camelizeChartTableCategories(
        nextCategory.categories
      )
    }

    return category
  })
}

export const camelizeChartTable = (data: any): IChartTable => {
  if (!data) {
    return data
  }

  performanceMarkStart('Parse Standard Response')

  const result = {} as IChartTable
  if (data.statistics) {
    result.statistics = data.statistics
  }
  if (data.items) {
    result.items = camelizeChartTableItems(data.items)
  }
  if (data.categories) {
    result.categories = camelizeChartTableCategories(data.categories)
  }
  if (typeof data.next_offset === 'number') {
    result.nextOffset = data.next_offset
  }
  if (typeof data.count === 'number') {
    result.count = data.count
  }

  performanceMeasureFromStartMark('Parse Standard Response')

  return result
}
