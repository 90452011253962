import {RouteBuilder, UrlSelection} from '@d1g1t/lib/url'

export class SecurityModelLocations extends RouteBuilder {
  static basePath = 'portfolio'

  static default(selection?: UrlSelection) {
    return super.withSelection('default', selection)
  }

  static overview(selection?: UrlSelection) {
    return super.withSelection('monitor/overview', selection)
  }

  static portfolioCreate(basePath: string) {
    return super.path(`${basePath}/create`)
  }

  static holdings(selection?: UrlSelection) {
    return super.withSelection('holdings', selection)
  }

  static settings(selection?: UrlSelection) {
    return super.withSelection('settings', selection)
  }

  static settingsEdit(selection?: UrlSelection) {
    return super.withSelection('settings/edit', selection)
  }

  static list() {
    return super.path('list')
  }
}
