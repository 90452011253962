import {snakeify} from '@d1g1t/api/helpers'
import {IPositionHolding, IPositionHoldingReleations} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class PositionEndpoints extends BaseRestEndpoints {
  static basePath = '/positions'

  static list(query: {
    asOfDate: string
    instrument?: string
    account?: string
    custodianAccount?: string
  }) {
    return super._list<IApiListResponse<IPositionHolding>>(snakeify(query))
  }

  static relations(id: string, query: {asOfDate: string}) {
    return super._get<IPositionHoldingReleations[]>(
      `${super._singlePath(id)}/relations`,
      {
        query: {
          as_of_date: query.asOfDate
        }
      }
    )
  }

  static classSeries(query: {asOfDate: string; classSeriesEntityId: string}) {
    return super._get<IPositionHolding[]>(`${super.basePath}/class_series`, {
      query: {
        as_of_date: query.asOfDate,
        class_series_entity_id: query.classSeriesEntityId
      }
    })
  }
}
