import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useApiMutation} from 'fairlight'
import {FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {AuthEndpoints} from '@d1g1t/api/endpoints'

import {Flex} from '@d1g1t/shared/components/flex'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {Button} from '@d1g1t/shared/components/mui/button'
import {ButtonLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H1, H2} from '@d1g1t/shared/components/typography'
import {loginPath} from '@d1g1t/shared/locations'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {LOGIN_PAGE_TRANSLATION_KEYS_SHARED} from '@d1g1t/shared/wrappers/localization-settings/constants'

enum FORGOT_PASSWORD_FIELD_NAMES {
  email = 'email'
}

interface IForgotPasswordFormValues {
  [FORGOT_PASSWORD_FIELD_NAMES.email]: string
}

/**
 * Forgot password form.
 *
 * Accepts an email, kicks off the reset request.
 */
export const ForgotPasswordForm: React.FC = () => {
  const {t} = useTranslation()

  const {handleUnexpectedError} = useErrorHandler()

  const [submitted, setSubmitted] = useState(false)

  const [resetPassword] = useApiMutation({
    mutation: (values: IForgotPasswordFormValues) => (api) =>
      api.request(AuthEndpoints.resetPassword({email: values.email})),

    onSuccess: () => setSubmitted(true),

    onError: (error) =>
      handleUnexpectedError(
        error,
        t(
          LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AN_UNEXPECTED_ERROR_ON_RESET_PASSWORD
        )
      )
  })

  const formik = useFormik<IForgotPasswordFormValues>({
    initialValues: {
      [FORGOT_PASSWORD_FIELD_NAMES.email]: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          t(
            LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS_MUST_BE_A_VALID_EMAIL
          )
        )
        .label('Email Address')
        .required(
          t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS_IS_REQUIRED)
        )
    }),
    onSubmit: resetPassword
  })

  if (submitted) {
    return (
      <H2 alignCenter>
        {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.CHECK_YOUR_EMAIL_FOR_INSTRUCTIONS}
      </H2>
    )
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{maxWidth: '80%', margin: '0 auto'}}>
          <H1 semiBold>{LOGIN_PAGE_TRANSLATION_KEYS_SHARED.FORGOT_PASSWORD}</H1>
          <Spacer md />
          <OutlinedInputField
            name={FORGOT_PASSWORD_FIELD_NAMES.email}
            label={LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS}
          />
          <Spacer xxs />
          <Flex justifySpaceBetween>
            <ButtonLink
              spaceLeft
              startIcon={<ArrowBackIcon />}
              to={loginPath()}
            >
              {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.BACK_TO_SIGN_IN}
            </ButtonLink>
            <Button
              primary
              contained
              disabled={formik.isSubmitting}
              type='submit'
            >
              {LOGIN_PAGE_TRANSLATION_KEYS_SHARED.RESET_PASSWORD}
            </Button>
          </Flex>
        </div>
      </form>
    </FormikProvider>
  )
}
