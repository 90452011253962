import produce from 'immer'
import {snakeCase} from 'lodash'

import {snakeify} from '@d1g1t/api/helpers'
import {CALCULATION_CODES, WEIGHT_DRIFT_OPTION} from '@d1g1t/api/models'
import {IControl, IFilterSets, IFilterValue} from '@d1g1t/typings/general'

import {MIME_TYPE} from '@d1g1t/lib/constants'
import {DATE_OPTION_DEFAULT, IDateRange} from '@d1g1t/lib/date-range'
import {IMetricRequestSelection} from '@d1g1t/lib/metrics'
import {pickFromObj} from '@d1g1t/lib/pick-from-obj'

import {IDisplayData} from '@d1g1t/shared/containers/view-options/components/display-options/typings'
import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {
  ITimeSeriesOptions,
  RequestGroupingCriterion
} from '@d1g1t/shared/wrappers/calculations'

import {IPaginationOptions} from '../pagination-typings'
import {BaseEndpoints} from './base'

// TODO - centralize all of the above types in the api folder

export interface ICalculationRequestBody {
  control: IControl
  settings: ISettings
  options: IRequestOptions
  groups?: IRequestGroups
  filter?: IFilterValue
  filterSets?: IFilterSets[]
  metrics?: IRequestMetrics
  displayData?: IDisplayData
  pagination?: IPaginationOptions
  /**
   * `nonCalcPagination` is pagination object outside of `calcParams` in the
   *  API request which controls sorting and filtering of non-calc paginated endpoint.
   * `pagination` property will go into `calcParams` and relates to the original table
   *  it is sourced from (e.g. pagination filtering from track strategies)
   */
  nonCalcPagination?: IPaginationOptions
}

export interface IRequestOptions
  extends ICalculationOptions,
    Partial<ITimeSeriesOptions> {
  needValidationReport?: boolean
  weightDrift?: WEIGHT_DRIFT_OPTION
  lookThrough?: boolean
}

export interface ICalculationOptions extends ICalculationDateRange {
  singleResult: boolean
  showMultiplePositions?: boolean
  investmentMandateAccounts?: boolean
  compare?: boolean
  portfolioRebalancing?: boolean
  /**
   * Column IDs to sort by, add a "-" in front of the ID to sort in ASC order.
   * Currently used for export to excel. In 5.0 we would like this to be the
   * property that governs pagination response's sorting.
   *
   * @example
   * ['-name', 'return_1yr']
   */
  orderBy?: string[]
}

export interface ICalculationDateRange {
  dateRange?: IDateRange
}

export interface IRequestGroups {
  selected: RequestGroupingCriterion[]
}

export interface IRequestMetrics {
  selected: IMetricRequestSelection[]
}

export class CalculationEndpoints extends BaseEndpoints {
  static basePath = '/calc'

  static calculate(code: CALCULATION_CODES, body: ICalculationRequestBody) {
    const patchedRequestBody = monkeyPatchRequestBody(code, body)

    return super._post(`/${dashifyCalculationCode(code)}`, {
      body: patchedRequestBody,
      successCodes: [200]
    })
  }

  static getExcel(code: CALCULATION_CODES, body: ICalculationRequestBody) {
    const patchedRequestBody = monkeyPatchRequestBody(code, body)

    return super._post<Blob>(`/${dashifyCalculationCode(code)}`, {
      headers: {Accept: MIME_TYPE.excel},
      body: patchedRequestBody
    })
  }
}

export function monkeyPatchRequestBody(
  code: CALCULATION_CODES,
  requestBody: Partial<ICalculationRequestBody>
): Partial<ICalculationRequestBody> {
  return produce(
    requestBody,
    (patchedRequestBody: Partial<ICalculationRequestBody>) => {
      if (patchedRequestBody.control?.selectedEntities?.accounts) {
        patchedRequestBody.control.selectedEntities.accountsOrPositions = [
          ...patchedRequestBody.control.selectedEntities.accounts
        ]
        delete patchedRequestBody.control.selectedEntities.accounts
      }

      // Monkeypatch request body because reasons
      if (
        [
          CALCULATION_CODES.PAST_PERF_RETURN,
          CALCULATION_CODES.FUTURE_RISK_CONTRIB
        ].includes(code) &&
        requestBody.groups
      ) {
        // @ts-ignore
        patchedRequestBody.groupings = requestBody.groups.selected
        delete patchedRequestBody.groups
        delete patchedRequestBody.metrics
      }

      // Future risk requires `dateRange` option, but it does not get used
      // by the calculation
      if ([CALCULATION_CODES.FUTURE_RISK_CONTRIB].includes(code)) {
        patchedRequestBody.options.dateRange = DATE_OPTION_DEFAULT
      }

      // Must not include groups or metrics
      if (
        [
          CALCULATION_CODES.SUMMARY,
          CALCULATION_CODES.PERF_AND_RISK,
          CALCULATION_CODES.SUMMARY_SINGLE,
          CALCULATION_CODES.BASIC_INFORMATION,
          CALCULATION_CODES.CONTRIBUTION,
          CALCULATION_CODES.CONTRIBUTION_DRILLDOWN,
          CALCULATION_CODES.CLIENT_POSITION_LIST,
          CALCULATION_CODES.PERFORMANCE_RANGE,
          CALCULATION_CODES.DISTRIBUTION,
          CALCULATION_CODES.TOP_BOTTOM_PERFORMERS
        ].includes(code)
      ) {
        delete patchedRequestBody.groups
        delete patchedRequestBody.metrics
      }

      // We only need to send the `slug` and `order` of groups
      if (patchedRequestBody.groups && patchedRequestBody.groups.selected) {
        patchedRequestBody.groups.selected =
          patchedRequestBody.groups.selected.map((requestedGroup) => {
            return pickFromObj(requestedGroup, 'groupingCriterion', 'order')
          })
      }

      for (const key in patchedRequestBody) {
        const snakeKey = snakeCase(key)

        if (key === 'pagination') {
          const pagination = snakeify(patchedRequestBody.pagination, 1)
          for (const categoryId in pagination.filtering) {
            pagination.filtering[categoryId] = snakeify(
              pagination.filtering[categoryId]
            )
          }
          patchedRequestBody.pagination = pagination
        } else {
          patchedRequestBody[snakeKey] = snakeify(patchedRequestBody[key])
        }

        if (snakeKey !== key) {
          delete patchedRequestBody[key]
        }
      }
    }
  )
}

export function dashifyCalculationCode(code: CALCULATION_CODES): string {
  return code.replace(/_/g, '-')
}
