import {ITransactionCategory} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class TransactionCategoriesEndpoints extends BaseRestEndpoints {
  static basePath = '/transaction-categories'

  static list() {
    return super._list<IApiListResponse<ITransactionCategory>>({limit: 2000})
  }
}
