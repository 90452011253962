import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    emulator: {
      background: theme.palette.primary.main,
      position: 'fixed',
      zIndex: 9999
    },
    emulatorBanner: {
      height: '45px',
      width: '100%',
      color: theme.palette.background.paper
    },
    emulatorH4: {
      color: theme.palette.background.paper,
      marginLeft: theme.spacing(0.5)
    },
    emulatorLeftLine: {
      height: '100%',
      width: theme.spacing(1)
    },
    emulatorRightLine: {
      height: '100%',
      width: theme.spacing(1),
      right: 0
    },
    emulatorBottomLine: {
      height: theme.spacing(1),
      width: '100%',
      bottom: '0'
    }
  }
})
