import {camelCase, merge} from 'lodash'

import {IMetricLeaf} from '@d1g1t/api/endpoints'
import {
  FIRMCONFIGURATION_WIDGETS_CONFIG_KEYS,
  IWidgetConfiguration,
  METRIC_SLUG_TO_CONSTANT_MAP,
  PERIOD
} from '@d1g1t/api/models'

import {IMetricRequestSelection} from '@d1g1t/lib/metrics'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ICategory} from '@d1g1t/shared/containers/view-options/components/display-options/metrics-tab/typings'
import {IViewOptionsConfiguration} from '@d1g1t/shared/containers/view-options/typings'

import {DEFAULT_UI_CONFIGURATION, IUiConfiguration} from './constants'

/**
 * Overrides the default UI Config with FirmConfiguration UI Config.
 * Preserves the defaults in case someone deletes the shape of the object in the Django panel.
 * @param uiConfig - From the FirmConfiguration API request
 * @returns UI Configuration with expected defaults, all keys are present
 */
export function mergeDefaultAndFirmConfigUiConfig(
  uiConfig: any
): IUiConfiguration {
  return merge({}, DEFAULT_UI_CONFIGURATION, uiConfig)
}

/**
 * Generate the key used in IFirmConfiguration.widgetConfigurations
 *
 * @param section - The name of the section that this widget is in. References
 * Section.slug on the backend.
 * @param widget - The name of the widget that the key is for. This value is
 * editable in the Django Admin UI as a free form text field that looks to be
 * slugified before being used as a key.
 */
export function generateWidgetConfigurationKey(
  section: string,
  widget: string
): FIRMCONFIGURATION_WIDGETS_CONFIG_KEYS {
  return camelCase(
    `${section.toLowerCase()}-${widget.toLowerCase()}`
  ) as FIRMCONFIGURATION_WIDGETS_CONFIG_KEYS
}

/**
 * Pulls `defaultMetrics` from `widgetConfig` and returns a set of
 * metrics that can be passed to `ViewOptions`.
 */
export function getDefaultMetrics(
  widgetConfig: IWidgetConfiguration
): IMetricRequestSelection[] {
  return widgetConfig?.defaultMetrics?.map<IMetricRequestSelection>((value) => {
    const slug = extractIdFromUrl(value)
    const metric = METRIC_SLUG_TO_CONSTANT_MAP[slug]
    return metric
  })
}

/**
 * Generate an IViewOptionsConfiguration.metrics instance from widgetConfig
 */
export function generateMetricsConfig(
  widgetConfig: IWidgetConfiguration
): IViewOptionsConfiguration['metrics'] {
  const metricsConfig: IViewOptionsConfiguration['metrics'] = {}

  if (widgetConfig?.allowedMetricsCategories?.length > 0) {
    metricsConfig.includeRelatedModels = widgetConfig.allowedMetricsCategories
  }

  if (widgetConfig?.allowedMetrics?.length > 0) {
    const metricSlugs: string[] =
      widgetConfig.allowedMetrics.map(extractIdFromUrl)
    metricsConfig.metricsFilterPredicate = (metric: IMetricLeaf): boolean =>
      metricSlugs.includes(metric.slug)
  }

  return metricsConfig
}

/**
 * Takes the firm config for Summary and Net Asset Value History widgets, and
 * produces a set of top-level metrics advisors can select.
 *
 * Assumption: PS will only return top level metrics in the `allowed_metrics` array.
 *
 * @param widgetName - a unique string used in the metric ID
 */
export const generateViewOptionsConfig = (
  widgetConfig: IWidgetConfiguration,
  widgetName: string
): ICategory[] => {
  if (!widgetConfig.allowedMetrics) {
    return null
  }

  const allPeriodSuffixes: string[] = Object.values(PERIOD)

  const setOfUniqueAllowedMetrics = new Set<string>()

  for (const metricUrl of widgetConfig.allowedMetrics) {
    let url = metricUrl
    for (const periodSuffix of allPeriodSuffixes) {
      url = url.replace(`-${periodSuffix}`, '')
    }
    setOfUniqueAllowedMetrics.add(url)
  }

  const metricICategories: ICategory[] = [...setOfUniqueAllowedMetrics].map(
    (partialMetricUrl) => {
      const metricConstant =
        METRIC_SLUG_TO_CONSTANT_MAP[extractIdFromUrl(partialMetricUrl)] ||
        METRIC_SLUG_TO_CONSTANT_MAP[
          `${extractIdFromUrl(partialMetricUrl)}-last-year`
        ]
      return {
        id: partialMetricUrl,
        name: metricConstant.columnTitle.replace(/\s\(.*\)$/, '')
      }
    }
  )

  return [
    {
      id: `custom-${widgetName}-id`,
      name: `${widgetName} Properties`,
      children: [
        {
          id: `r-${widgetName}-props`,
          name: 'Property',
          children: [],
          metrics: metricICategories
        }
      ]
    }
  ]
}

/**
 * Removes period (e.g. 'last-6-months', 'as-of-day') from metrics slugs, returns only unique slugs
 */
export const getDefaultMetricsWithoutPeriod = (
  defaultMetricsUrls: string[]
): string[] => {
  const ALL_PERIOD_SUFFIXES: string[] = Object.values(PERIOD)
  const metricsWithoutPeriod = new Set<string>()

  for (const metric of defaultMetricsUrls) {
    let id = extractIdFromUrl(metric)
    for (const periodSuffix of ALL_PERIOD_SUFFIXES) {
      id = id.replace(`-${periodSuffix}`, '')
    }
    metricsWithoutPeriod.add(id)
  }

  return [...metricsWithoutPeriod]
}
