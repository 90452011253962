import React, {useContext} from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {LinkProps} from 'react-router-dom'

import BackArrowIcon from '@material-ui/icons/ArrowBack'

import {ConfigContext} from '@d1g1t/config/context'

import {classNames} from '@d1g1t/lib/class-names'
import {DateFormatter} from '@d1g1t/lib/formatters/date-formatter'

import {Flex} from '@d1g1t/shared/components/flex'
import {ButtonLink} from '@d1g1t/shared/components/router-link'
import {H1, Text} from '@d1g1t/shared/components/typography'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {useExternalProfileLocalizationPreferences} from '@d1g1t/shared/wrappers/localization-settings/hook'

import {SectionNotes} from '@d1g1t/investor/containers/section-notes'

import * as css from './style.scss'

export interface IPageTitleBarProps {
  /**
   * Title of the page.
   */
  title: string
  /**
   * Shows a back button to left of title, redirects to the provided link on click.
   */
  backLink?: LinkProps['to']
  /**
   *  Shows Latest available date on right
   */
  asOfDateText?: string
  /**
   * Sets the PageTitleBar as sticky.
   */
  stickyTopBar?: boolean
  /**
   * Disables calculation settings selector for currency.
   * Passed down to the Settings component.
   */
  disableCurrency?: boolean
  /**
   * Hides calculation settings selector for currency.
   * Passed down to the Settings component.
   */
  hideCurrency?: boolean
  /**
   * Disables calculation settings selector for as-of-date.
   * Passed down to the Settings component.
   */
  disableDate?: boolean
  /**
   * Removes the bottom margin from underneath PageTitleBar component.
   */
  noBottomMargin?: boolean
}

/**
 * Provides a title bar to show the page title.
 *
 * Optionally offers sticky title bar, page navigation (for moving between SelectEntities pages),
 * back-to-list navigation, and calculation settings UI elements.
 */
export const InvestorPageTitle: React.FC<IPageTitleBarProps> = (props) => {
  const config = useContext(ConfigContext)
  const {t} = useTranslation()
  const [profileSettings] = useExternalProfileLocalizationPreferences()
  return (
    <div
      className={classNames(css.filtersToolbar, {
        [css.noBottomMargin]: props.noBottomMargin,
        [css.stickyTopBar]: props.stickyTopBar
      })}
      data-testid='container-settings-toolbar'
    >
      <Helmet
        title={`${config.pageTitleBarHelmetPrefix || ''}${props.title}`}
      />
      <Flex
        grow
        alignCenter
        toolbarWithSettings={false}
        className={css.pageTitleBar}
      >
        <Flex grow>
          {props.backLink && (
            <ButtonLink
              style={{
                width: 34,
                height: 34,
                marginRight: 24
              }}
              noMinWidth
              contained
              primary
              to={props.backLink}
            >
              <BackArrowIcon />
            </ButtonLink>
          )}
          <ErrorBoundary resetId={props.title}>
            <Flex alignCenter style={{gap: '12px'}}>
              <H1 noWrap noMargin>
                {props.title}
              </H1>
              {props.asOfDateText && (
                <Text className={css.asOfDate}>
                  {t('As Of')}{' '}
                  {new DateFormatter({
                    dateFormat: profileSettings.dateFormat
                  }).format(props.asOfDateText)}
                </Text>
              )}
            </Flex>
          </ErrorBoundary>
        </Flex>
        <Flex shrink>
          <div>{props.children}</div>
        </Flex>
        <SectionNotes />
      </Flex>
    </div>
  )
}
