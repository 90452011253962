import React from 'react'

import MuiLinearProgress, {LinearProgressProps} from '@material-ui/core/LinearProgress'

export interface ILinearProgressProps extends LinearProgressProps {}

export const LinearProgress = React.forwardRef(
  (props: ILinearProgressProps, ref?: React.Ref<HTMLDivElement>) => {
    return <MuiLinearProgress ref={ref} {...props} />
  }
)
