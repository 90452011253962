import {ISearchResult} from '@d1g1t/shared/containers/search'

export const MANAGE_CLIENTS_BASIC_INFO_COMPONENT_ID =
  'MANAGE-CLIENTS-BASIC-INFO-METRICS'

// The default properties for the 'Consolidated portfolio' option
export const CONSOLIDATED_PORTFOLIO_ENTITY_DETAILS_OR_SEARCH_RESULT: ISearchResult =
  {
    printName: 'Consolidated Portfolio',
    entityId: 'consolidated-portfolio',
    modelName: null,
    displayText: null
  }
