import React, {useState} from 'react'

export function useInputState(
  defaultValue: string
): [
  string,
  (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
] {
  const [state, updateState] = useState(defaultValue)

  const updateInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (typeof event === 'string') {
      updateState(event)
      return
    }

    updateState(event.target.value)
  }

  return [state, updateInput]
}
