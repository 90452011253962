import {IUserView} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class ViewsEndpoints extends BaseRestEndpoints {
  static basePath = '/profile/views'

  /**
   * List of views related to a component. Should use fetch-policy to utilize cache first.
   *
   * @param tableKey - component's save key
   */
  static list(query: {table_key: string}) {
    return super._list<IApiListResponse<IUserView>>(query)
  }

  static create(params: IUserView) {
    return super._create<IUserView>(params)
  }

  static partialUpdate(id: string, view: Partial<IUserView>) {
    return super._partialUpdate<IUserView>(id, view)
  }

  static destroy(id: string) {
    return super._destroy(id)
  }
}
