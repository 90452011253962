import {ApiParam} from 'fairlight'

import {
  IChartTable,
  ISecurityRestriction,
  ISecurityRestrictionPairIdRequest
} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class TradingRestrictionEndpoints extends BaseRestEndpoints {
  static basePath = '/trading/security-restrictions'

  static findById(id: ApiParam) {
    return super._findById<ISecurityRestriction>(id)
  }

  static list(
    entity: string,
    propertyName: string,
    propertyDisplayValue: string
  ) {
    return super._list<IApiListResponse<ISecurityRestriction>>({
      entity,
      displayed_restricted_object: `${propertyName}:${propertyDisplayValue}`
    })
  }

  static chart(relatedEntity?: string) {
    return super._post<IChartTable>('/chart', {
      query: {related_entity: relatedEntity},
      body: {}
    })
  }

  static create(body: ISecurityRestriction) {
    return super._create<ISecurityRestriction>(body)
  }

  static partialUpdate(id: ApiParam, body: Partial<ISecurityRestriction>) {
    return super._partialUpdate<ISecurityRestriction>(id, body)
  }

  static update(id: ApiParam, body: ISecurityRestriction) {
    return super._update<ISecurityRestriction>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static bulkRemove(body: ISecurityRestrictionPairIdRequest[]) {
    return super._post<ISecurityRestrictionPairIdRequest[]>('/bulk-remove', {
      body
    })
  }
}
