import {createSelector} from 'reselect'

import {PREFIX} from './constants'
import {initialStateSlice} from './reducers'
import {IDomainSlice, IGlobalSettingsState} from './typings'

export const createBoundGetter = (id: string) => {
  if (!id) {
    return NOOP
  }

  const getSlice = (state: IGlobalState) => {
    const domain: IGlobalSettingsState = state[PREFIX]

    return domain[id]
  }

  return createSelector(getSlice, (slice): IDomainSlice => {
    return slice || initialStateSlice
  })
}
