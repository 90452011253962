import {sortBy} from 'lodash'
import qs from 'qs'

import {IControl, IGlobalFilter} from '@d1g1t/typings/general'

import {stableFilterId} from '@d1g1t/lib/filters'
import {encodeParams, extractIdFromUrl} from '@d1g1t/lib/url'

function isGlobalFilter(value: IControl['filter']): value is IGlobalFilter {
  if (!value) {
    return false
  }

  return 'filterItems' in value
}

export const getControlId = (control: IControl): Nullable<string> => {
  if (!control) {
    return null
  }

  let id = ''

  if (control.entityId) {
    id += encodeParams(
      control.entityId,
      control.selectedAccounts
        ? {
            [control.entityId]: control.selectedAccounts
          }
        : null
    )
  }

  if (control.selectedEntities) {
    id += qs.stringify(control.selectedEntities)
  }

  if (control.affectedByModels) {
    id += qs.stringify(control.affectedByModels)
  }

  if (control.filterTypes) {
    const types = sortBy(control.filterTypes, 'slug')
      .map((type) => type.slug)
      .join(':')
    id += `filterTypes=${types}`
  }

  if (isGlobalFilter(control.filter)) {
    id += stableFilterId({
      ...control.filter,
      items: control.filter.filterItems?.map((filterItem) => ({
        ...filterItem,
        slug: extractIdFromUrl(filterItem.filterCriterion)
      }))
    })
  }

  if (id) {
    return id
  }

  return null
}

export const uniqueId = (control: IControl, id?: string): string => {
  const controlId = getControlId(control)
  if (controlId && id) {
    return `${controlId}-${id}`
  }

  return controlId || id
}

interface IStandardSliceProps {
  id?: string
  control?: IControl
  waitForControl?: boolean
}

export const getActionId = (
  props: IStandardSliceProps & {[id: string]: any},
  type = 'action'
) => {
  const id = uniqueId(props.control, props.id)

  if (id) {
    return id
  }

  throw new Error(
    `Cannot compute ${type} id from props, an id prop must be specified if control does not contain entity ids`
  )
}

export const getGetterId = (
  _,
  props: IStandardSliceProps & {[id: string]: any}
) => {
  if (props.waitForControl && !props.control) {
    return null
  }

  return getActionId(props, 'getter')
}
