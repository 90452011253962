import produce from 'immer'
import {ActionType, getType} from 'typesafe-actions'

import {injectAsyncReducer} from '@d1g1t/lib/redux-inject-async'

import {authActions} from './actions'
import {PREFIX} from './constants'
import {IAuthDomain} from './typings'

export const initialState: IAuthDomain = {
  loggingIn: false,
  loggingInAwaitMfaDestinationInput: false,
  loggingInAwaitMfaCode: false,
  loginError: null,
  token: null,
  mfaToken: null,
  mfaDestination: null,
  mfaDestinations: null,
  returning: null,
  redirectTo: null,
  invalidCode: false,
  invalidCodeErrorMessage: null
}

export const authReducer = (
  state: IAuthDomain = initialState,
  action: ActionType<typeof authActions>
): IAuthDomain => {
  switch (action.type) {
    case getType(authActions.initialize):
      return produce(state, (draft) => {
        draft.token = action.payload.token || null
        draft.returning = !!action.payload.returning
      })
    case getType(authActions.loginRequest):
      return produce(state, (draft) => {
        draft.loggingIn = true
        draft.loggingInAwaitMfaDestinationInput = false
        draft.loggingInAwaitMfaCode = false
        draft.loginError = null
        draft.redirectTo = action.payload.redirectTo
      })
    case getType(authActions.loginAwaitingMfaDestination):
      return produce(state, (draft) => {
        draft.loggingIn = false
        draft.loggingInAwaitMfaDestinationInput = true
        draft.loggingInAwaitMfaCode = false
        draft.mfaToken = action.payload.otpToken
        draft.mfaDestinations = action.payload.deliveryDestinations
        draft.token = null
        draft.invalidCode = action.payload.invalidCode
        draft.invalidCodeErrorMessage = action.payload.invalidCodeErrorMessage
      })

    case getType(authActions.loginMfaDestinationRequest):
      return produce(state, (draft) => {
        draft.loggingInAwaitMfaDestinationInput = false
        draft.loggingInAwaitMfaCode = true
      })
    case getType(authActions.loginAwaitingMfaCode):
      return produce(state, (draft) => {
        draft.loggingIn = false
        draft.loggingInAwaitMfaDestinationInput = false
        draft.loggingInAwaitMfaCode = true
        draft.mfaToken = action.payload.otpToken
        draft.token = null
        draft.invalidCode = action.payload.invalidCode
        draft.invalidCodeErrorMessage = action.payload.invalidCodeErrorMessage
      })

    case getType(authActions.loginFailed):
      return produce(state, (draft) => {
        draft.loggingIn = false
        draft.loggingInAwaitMfaDestinationInput = false
        draft.loggingInAwaitMfaCode = false
        draft.loginError = action.payload
        draft.token = null
        draft.mfaToken = null
      })
    case getType(authActions.loginSuccess):
      return produce(state, (draft) => {
        draft.loggingIn = false
        draft.loggingInAwaitMfaDestinationInput = false
        draft.loggingInAwaitMfaCode = false
        draft.loginError = null
        draft.token = action.payload.token
        draft.mfaToken = null
        draft.returning = true
        draft.redirectTo = null
      })
    case getType(authActions.refreshSuccess):
      return produce(state, (draft) => {
        draft.token = action.payload.token
      })
    default:
      return state
  }
}

injectAsyncReducer(PREFIX, authReducer)
