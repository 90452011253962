import produce from 'immer'
import {Dictionary, find} from 'lodash'

import {
  CHART_VALUE_TYPE,
  IChartTableItem,
  TOKENIZEDEXPRESSION_OPERATOR
} from '@d1g1t/api/models'
import {
  DATE_RELATIONAL_EXPRESSION_MAP,
  ITokenizedExpression,
  ITokenizedSearch,
  NUMERIC_RELATIONAL_EXPRESSION_MAP
} from '@d1g1t/api/pagination-typings'

import {
  CATEGORY_IDS,
  isNumberCell,
  isTotalRow,
  StandardResponse
} from '@d1g1t/lib/standard-response'
import {
  andOrStringTokenizer,
  LOGICAL_OPERATOR,
  parseDateExpressionToken,
  parseNumericExpressionToken
} from '@d1g1t/lib/string-parsers'

import {IItemPageId} from '@d1g1t/shared/containers/standard-table'

import {IFilterGenerator, IIndexLoadingMap} from './typings'

export interface IRangeToLoad {
  parentPath: string
  startIndex: number
  stopIndex: number
}

interface IFindRangesToLoadOptions {
  /**
   * Ordered page ids of items to scan for
   */
  itemPageIds: IItemPageId[]
  /**
   * Minimum size to batch requests
   */
  minimumBatchSize: number
  /**
   * Array of all currently-loaded items
   */
  loadedItems: Array<IChartTableItem | null>
  /**
   * Mapping of parent paths to a mapping of indexes to booleans.
   *
   * If true, the item at the specified parentPath/index is loading.
   */
  loadingIndexMap: {
    [parentPath: string]: {
      [indexInLevel: number]: boolean
    }
  }
}

function buildPageRangesByParentPath(
  unloadedItemPageIds: IItemPageId[]
): Map<string, IPageRange> {
  return unloadedItemPageIds.reduce<Map<string, IPageRange>>(
    (prev, {parentPath, indexInLevel}) => {
      if (!prev.has(parentPath)) {
        prev.set(parentPath, {
          startIndex: indexInLevel,
          stopIndex: indexInLevel
        })
      } else {
        prev.set(parentPath, {
          startIndex: Math.min(indexInLevel, prev.get(parentPath).startIndex),
          stopIndex: Math.max(indexInLevel, prev.get(parentPath).stopIndex)
        })
      }

      return prev
    },
    new Map()
  )
}

type LoadedItemsByParentPath = Map<string, Array<IChartTableItem | null>>

function buildLoadedItemsByParentPath(
  items: Array<IChartTableItem | null>,
  loadedItemsByParentPath: LoadedItemsByParentPath = new Map(),
  parentPath = 'root'
): LoadedItemsByParentPath {
  loadedItemsByParentPath.set(parentPath, items)

  for (const item of items) {
    if (item && item.items) {
      loadedItemsByParentPath = buildLoadedItemsByParentPath(
        item.items,
        loadedItemsByParentPath,
        `${parentPath}/${item.id}`
      )
    }
  }

  return loadedItemsByParentPath
}

/**
 * Returns a list of ranges within a range which contain unloaded rows,
 * respecting a minimum batch size
 *
 * The result of this method should be used to make API requests to fill
 * in the unloaded ranges
 */
export function findRangesToLoad({
  itemPageIds,
  minimumBatchSize,
  loadedItems,
  loadingIndexMap
}: IFindRangesToLoadOptions): IRangeToLoad[] {
  if (isTotalRow(loadedItems[0])) {
    loadedItems = loadedItems.slice(1) // ignore total row
  }

  const pageRangesByParentPath = buildPageRangesByParentPath(itemPageIds)
  const loadedItemsByParentPath = buildLoadedItemsByParentPath(loadedItems)

  return Array.from(pageRangesByParentPath).reduce(
    (prev, [parentPath, range]) => {
      const loadedItemsForParentPath =
        loadedItemsByParentPath.get(parentPath) || []

      const unloadedRangesForParentPath = scanForUnloadedRanges({
        range,
        minimumBatchSize,
        rowCount: loadedItemsForParentPath.length,
        loadedItems: loadedItemsForParentPath,
        loadingIndexMap: loadingIndexMap[parentPath] || {}
      })

      return prev.concat(
        unloadedRangesForParentPath.map((unloadedRange) => ({
          parentPath,
          ...unloadedRange
        }))
      )
    },
    []
  )
}

interface IPageRange {
  startIndex: number
  stopIndex: number
}

interface IScanForUnloadedRangesOptions {
  /**
   * Range within a larger range to scan for unloaded ranges
   */
  range: IPageRange
  /**
   * Total row count for the entire response
   */
  rowCount: number
  /**
   * Minimum size to batch requests
   */
  minimumBatchSize: number
  /**
   * Array of all currently-loaded items
   */
  loadedItems: Array<IChartTableItem | null>
  /**
   * Mapping of indexes to booleans. If true, the index has been loaded
   */
  loadingIndexMap: {[key: number]: boolean}
}

/**
 * Returns a list of ranges within a range which contain unloaded rows,
 * respecting a minimum batch size
 *
 * The result of this method should be used to make API requests to fill
 * in the unloaded ranges
 */
export function scanForUnloadedRanges({
  range,
  rowCount,
  minimumBatchSize,
  loadedItems,
  loadingIndexMap
}: IScanForUnloadedRangesOptions): IPageRange[] {
  const unloadedRanges: IPageRange[] = []

  let rangeStartIndex: number = null
  let rangeStopIndex: number = null

  /**
   * Checks if an index is loaded or being loaded, true in either case
   */
  const isRowLoaded = (index: number): boolean => {
    return !!loadedItems[index] || loadingIndexMap[index]
  }
  const lastRowLoaded = loadedItems.findIndex((value) => !value)

  for (let i = range.startIndex; i <= range.stopIndex; i++) {
    const loaded = isRowLoaded(i)
    if (!loaded) {
      // Move the cursor for stop index to the current index
      rangeStopIndex = i

      // If start has not been set, set cursor for start
      if (rangeStartIndex === null) {
        rangeStartIndex = i
      }
    } else if (rangeStopIndex !== null) {
      // If we've reached here, we have a cursor for start and stop indexes
      // and are at the end of an unloaded range
      unloadedRanges.push({
        startIndex: rangeStartIndex,
        stopIndex: rangeStopIndex
      })

      // Reset the cursors
      rangeStartIndex = null
      rangeStopIndex = null
    }
  }

  // This conditional is checking if the user is scrolling through already loaded ranges.
  if (
    rangeStartIndex === null &&
    rangeStopIndex === null &&
    unloadedRanges.length === 0 &&
    lastRowLoaded !== -1 &&
    !isRowLoaded(lastRowLoaded)
  ) {
    if (range.stopIndex > lastRowLoaded / 2) {
      // User is more than halfway through the loaded items
      // Start fetching the next range of items.
      unloadedRanges.push({
        startIndex: lastRowLoaded,
        stopIndex: Math.min(lastRowLoaded + minimumBatchSize, rowCount - 1)
      })
    }
  }

  // If rangeStopIndex is not null, we have an unterminated range of unloaded rows
  // which terminates outside the request range window
  //
  // Scanning forward filling our minimumBatchSize
  if (rangeStopIndex !== null) {
    // Check to end of row count or to end of minimum batch size
    const potentialStopIndex = Math.min(
      Math.max(rangeStopIndex, rangeStartIndex + minimumBatchSize - 1),
      rowCount - 1
    )

    for (let i = rangeStopIndex + 1; i <= potentialStopIndex; i++) {
      if (!isRowLoaded(i)) {
        rangeStopIndex = i
      } else {
        break
      }
    }

    unloadedRanges.push({
      startIndex: rangeStartIndex,
      stopIndex: rangeStopIndex
    })
  }

  if (unloadedRanges.length) {
    const firstUnloadedRange = unloadedRanges[0]

    // Checking if the first range ended prematurely — is smaller then
    // the minimumBatchSize
    //
    // Scanning backwards to fill out minimumBatchSize
    while (
      firstUnloadedRange.stopIndex - firstUnloadedRange.startIndex + 1 <
        minimumBatchSize &&
      firstUnloadedRange.startIndex > 0
    ) {
      const i = firstUnloadedRange.startIndex - 1

      if (!isRowLoaded(i)) {
        firstUnloadedRange.startIndex = i
      } else {
        break
      }
    }
  }

  return unloadedRanges
}

export function generatePaginationFilter(
  standardResponse: StandardResponse,
  params: {
    /**
     * Filters items by checking if all cateogryId search expressions
     * match. Search text in each field is split by comma and acts as an OR
     * operator. Search text in different fields act as an AND operator
     */
    columnFilter?: {
      [categoryId: string]: string | any[]
    }
    fullTextFilter?: string
    showInternalTransactions?: boolean
    showCurrentDataOnly?: boolean
    showCancelledTransactions?: boolean
    showPendingTransactions?: boolean
    hideEmptyNodes?: boolean
    /**
     * Any additional filters to be applied for a category
     */
    additionalFiltersForCategoryId?: Dictionary<IFilterGenerator>
  }
): Dictionary<ITokenizedSearch> {
  const filter = {}

  // Maps transforms which should be passed as `true` values to paginated
  // filtering API
  const transformBooleanMap = {
    // When show internal transactions is not checked then we should send the line in source filter
    [CATEGORY_IDS.LINE_IN_SOURCE]: params.showInternalTransactions === false,
    [CATEGORY_IDS.IS_ALIVE]: params.showCurrentDataOnly,
    [CATEGORY_IDS.IS_NODE_ALIVE]: params.hideEmptyNodes
  }

  for (const key in transformBooleanMap) {
    if (transformBooleanMap[key]) {
      filter[key] = {
        format: 'boolean',
        joinOperator: LOGICAL_OPERATOR.AND,
        expressions: [
          {
            value: true,
            operator: TOKENIZEDEXPRESSION_OPERATOR.IS_
          }
        ]
      }
    }
  }

  // For cancelled transactions, the logic is inverted, we always want to filter
  // out true values unless specifically asked to be included
  if (params.showCancelledTransactions === false) {
    filter[CATEGORY_IDS.IS_TRANSACTION_CANCELLED] = {
      format: 'boolean',
      joinOperator: LOGICAL_OPERATOR.AND,
      expressions: [
        {
          value: false,
          operator: TOKENIZEDEXPRESSION_OPERATOR.IS_
        }
      ]
    }
  }

  // For pending transactions, the logic is inverted, we always want to filter
  // out true values unless specifically asked to be included.
  if (params.showPendingTransactions === false) {
    filter[CATEGORY_IDS.IS_PENDING_TRANSACTION] = {
      format: 'boolean',
      joinOperator: LOGICAL_OPERATOR.AND,
      expressions: [
        {
          value: false,
          operator: TOKENIZEDEXPRESSION_OPERATOR.IS_
        }
      ]
    }
  }

  if (standardResponse && params.columnFilter) {
    for (const key in params.columnFilter) {
      // Remove falsy values
      if (params.columnFilter[key].length > 0) {
        const category = standardResponse.findCategoryById(key)
        // Check type of category
        if (isNumberCell(category.valueType as any)) {
          const {tokens, expressionType} = andOrStringTokenizer(
            params.columnFilter[key] as string
          )
          const expressions: ITokenizedExpression[] = []
          for (const token of tokens) {
            const expression = parseNumericExpressionToken(token)
            if (expression) {
              expressions.push({
                value: expression.value,
                operator: NUMERIC_RELATIONAL_EXPRESSION_MAP[expression.operator]
              })
            }
          }

          if (expressions.length) {
            filter[key] = {
              expressions,
              joinOperator: expressionType || LOGICAL_OPERATOR.OR,
              format: 'numeric'
            }
          }
        } else if (
          CHART_VALUE_TYPE.DATE === category.valueType ||
          CHART_VALUE_TYPE.DATETIME === category.valueType
        ) {
          const {tokens, expressionType} = andOrStringTokenizer(
            params.columnFilter[key] as string
          )
          const expressions: ITokenizedExpression[] = []
          for (const token of tokens) {
            const expression = parseDateExpressionToken(token)
            if (expression) {
              expressions.push({
                value: expression.value,
                operator: DATE_RELATIONAL_EXPRESSION_MAP[expression.operator]
              })
            }
          }

          if (expressions.length) {
            filter[key] = {
              expressions,
              joinOperator: expressionType || LOGICAL_OPERATOR.OR,
              format: 'date'
            }
          }
        } else if (
          category?.options?.allowedValues ||
          category.valueType === CHART_VALUE_TYPE.BOOLEAN
        ) {
          filter[key] = {
            expressions: (params.columnFilter[key] as any[]).map(
              (enumValue) => ({
                value: enumValue,
                operator: TOKENIZEDEXPRESSION_OPERATOR.IS_
              })
            ),
            joinOperator: LOGICAL_OPERATOR.OR,
            /**
             * going to be removed at some point from backend requirement
             * it is not used for filtering but needs to be in request
             */
            format: 'string'
          }
        } else {
          const stringTokens = (params.columnFilter[key] as string)
            .split(/\s*,\s*/)
            .filter(Boolean)

          if (stringTokens.length) {
            filter[key] = {
              joinOperator: LOGICAL_OPERATOR.OR,
              format: 'string',
              expressions: stringTokens.map((token) => {
                return {
                  value: token,
                  operator: 'contains'
                }
              })
            }
          }
        }
      }

      if (!!params.additionalFiltersForCategoryId?.[key]) {
        const {tokens} = andOrStringTokenizer(
          params.columnFilter[key] as string
        )
        const getAdditionalFilters = params.additionalFiltersForCategoryId[key]
        const additionalFilters = getAdditionalFilters(tokens)
        for (const filterKey in additionalFilters) {
          filter[filterKey] = additionalFilters[filterKey]
        }
      }
    }
  }

  return filter
}

export function generateFullTextSearch(
  standardResponse: StandardResponse,
  params: {
    searchString: string
  }
): Nullable<{
  value: string
  categories: string[]
}> {
  if (!standardResponse || !params.searchString) {
    return null
  }

  return {
    value: params.searchString,
    categories: standardResponse.categories
      .filter((category) => category.valueType === CHART_VALUE_TYPE.STRING)
      .map((category) => category.id)
  }
}

/**
 * Applies a newly-loaded set of items to a root-level set of items
 */
export function applyItemsForLoadedRange(
  existingItems: IChartTableItem[],
  /**
   * Items to add, which were loaded for the given range argument
   */
  newItems: IChartTableItem[],
  /**
   * Range that was just loaded
   */
  range: IRangeToLoad
): IChartTableItem[] {
  return produce(existingItems, (draft) => {
    // find items by the parent path
    const [, ...parentIds] = range.parentPath.split('/')
    let items = draft
    for (const parentId of parentIds) {
      const item = find(items, {id: parentId})
      if (!item || !item.items) {
        return
      }
      items = item.items
    }

    // insert items at the range indices
    let rawItemIndex = 0
    for (let i = range.startIndex; i <= range.stopIndex; i++) {
      const itemIndex = isTotalRow(items[0]) ? i + 1 : i
      items[itemIndex] = newItems[rawItemIndex]
      // fill empty item children using child count
      items[itemIndex].items =
        items[itemIndex].childCount > 0 && !items[itemIndex].items
          ? new Array(items[itemIndex].childCount).fill(null)
          : items[itemIndex].items

      rawItemIndex++
    }
  })
}

export function updateLoadingIndexMapForLoadingRange(
  loadingIndexMap: IIndexLoadingMap,
  range: IRangeToLoad
): IIndexLoadingMap {
  return produce(loadingIndexMap, (draft) => {
    if (!draft[range.parentPath]) {
      draft[range.parentPath] = {}
    }

    for (let i = range.startIndex; i <= range.stopIndex; i++) {
      draft[range.parentPath][i] = true
    }
  })
}

export function updateLoadingIndexMapForLoadedRange(
  loadingIndexMap: IIndexLoadingMap,
  range: IRangeToLoad
): IIndexLoadingMap {
  return produce(loadingIndexMap, (draft) => {
    if (draft[range.parentPath]) {
      for (let i = range.startIndex; i <= range.stopIndex; i++) {
        delete draft[range.parentPath][i]
      }

      if (Object.keys(draft[range.parentPath]).length === 0) {
        delete draft[range.parentPath]
      }
    }
  })
}
