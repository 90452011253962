import {ApiParam} from 'fairlight'

import {IMarketPrice, MARKETPRICE_SOURCE} from '@d1g1t/api/models'

import {IDateRange} from '@d1g1t/lib/date-range'

import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'

import {BaseRestEndpoints} from './base'

export interface IMarketPriceHistoryChartTableBody {
  control: {
    selectedEntities: {
      instrument: string
    }
  }
  settings: ISettings
  options: {
    singleResult: boolean
    investmentMandateAccounts: boolean
    compare: boolean
    portfolioRebalancing: boolean
    dateRange: IDateRange
    source: MARKETPRICE_SOURCE
  }
}

export class MarketPriceEndpoints extends BaseRestEndpoints {
  static basePath = '/marketprices'

  static create(body: IMarketPrice) {
    return super._create<IMarketPrice>(body)
  }

  static partialUpdate(id: ApiParam, body: Partial<IMarketPrice>) {
    return super._partialUpdate<IMarketPrice>(id, body)
  }

  static chart(body: IMarketPriceHistoryChartTableBody) {
    return super._chart(body, JSON.stringify(body))
  }

  static getExcel(body: IMarketPriceHistoryChartTableBody) {
    return super._getExcelChart(body)
  }
}
