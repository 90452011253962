import {IClientCategory} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class PeopleClientCategoryEndpoints extends BaseRestEndpoints {
  static basePath = '/people/client-category'

  static list() {
    return super._list<IApiListResponse<IClientCategory>>()
  }
}
