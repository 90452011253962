import {RouteBuilder} from '@d1g1t/lib/url'

export class InvestmentProgramLocations extends RouteBuilder {
  static basePath = 'investment-programs'

  static single(id: string) {
    return super.path(`/${id}`)
  }

  static create() {
    return super.path('/new')
  }

  static copy(id: string) {
    return super.path(`/copy/${id}`)
  }
}
