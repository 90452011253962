import React from 'react'

import {ALL_MODELS} from '@d1g1t/api/models'

import {UrlSelection} from '@d1g1t/lib/url'

export interface IConfigContext {
  /**
   * Method used to construct routes for models. Allows us to set seperate
   * definitions for advisor and client apps.
   */
  modelPath(
    modelNameCaseSensitive: ALL_MODELS,
    selection: UrlSelection,
    clientSubResourceId?: string
  ): string
  /**
   * Method to return the redirect/entity path for an Entity ID in
   * `StandardTable`  \>  `DataCell`. Falls back to `modelPath` if this method
   * returns null/undefined
   */
  entityPath?(entityId: string, modelName?: ALL_MODELS): string

  /**
   * Controls if we should use primed dates in calculation request processing.
   * This allows us to turn it off for the investor application.
   */
  enablePrimedDates?: boolean
  /**
   * The `PageTitleBar` component will prefix it to the window/tab title.
   */
  pageTitleBarHelmetPrefix?: string
  /**
   * Controls if we should default to the firms default reporting currency
   * or the static `ISettings` for `useGlobalSettings` in `useCalculationSettings`.
   *
   * This allows us to default to the firms reporting currency instead of the static CAD
   * for the investor application.
   */
  useReportingCurrencyForDefaultCalculationSettings?: boolean
  /**
   * Controls if we sould check for any Docusign cosent form to redirect to on login.
   * Currently only Investor portal has this enabled.
   */
  enableDocuSignRedirect?: boolean
  /**
   * Advisor app should auto redirect to Azure login path, if provided.
   * Set to `true` in advisor app for the shared login page.
   * Also used by `useLoginRedirectIfLoggedOut` hook with ASSUMPTION that only advisor app sets this to true.
   */
  enableAzureRedirect?: boolean
  /**
   * Indicates whether it is an Advisor or a Investor portal
   */
  isAdvisorApp?: boolean
}

export const defaultContext: IConfigContext = {
  modelPath() {
    return '/'
  },
  entityPath: NOOP
}

export const ConfigContext = React.createContext(defaultContext)
