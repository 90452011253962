import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

export interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  dark?: boolean
  grow?: boolean
  offWhiteBackground?: boolean
  noPadding?: boolean
  noMinHeight?: boolean
  noBottomMargin?: boolean
  noBottomRoundedCorners?: boolean
  extraPadding?: boolean
  shadow?: boolean
  fixedHeight?: boolean
  minHeight?: number
  flexColumn?: boolean
  fullPageHeight?: boolean
  fullPageTopBarMenu?: boolean
  fullPageSaveBarTopBarMenu?: boolean
  fullPageNavButtons?: boolean
  fullWidth?: boolean
  extraRoundedCorners?: boolean
  hideOverflow?: boolean
}

export const Card: React.SFC<ICardProps> = ({
  dark,
  offWhiteBackground,
  noPadding,
  noBottomMargin,
  noMinHeight,
  noBottomRoundedCorners,
  shadow,
  extraPadding,
  minHeight,
  fixedHeight,
  className,
  style,
  flexColumn,
  fullPageHeight,
  fullPageSaveBarTopBarMenu,
  fullPageTopBarMenu,
  fullPageNavButtons,
  fullWidth,
  grow,
  extraRoundedCorners,
  hideOverflow,
  ...props
}) => (
  <div
    className={classNames(
      css.card,
      className,
      noMinHeight || fixedHeight ? css.noMinHeight : css.minHeight,
      {
        [css.dark]: dark,
        [css.offWhiteBackground]: offWhiteBackground,
        [css.noPadding]: noPadding,
        [css.noBottomMargin]: noBottomMargin,
        [css.shadow]: shadow,
        [css.extraPadding]: extraPadding,
        [css.fixedHeight]: fixedHeight,
        [css.flexColumn]: flexColumn,
        [css.fullPageHeight]: fullPageHeight,
        [css.fullPageSaveBarTopBarMenu]: fullPageSaveBarTopBarMenu,
        [css.fullPageNavButtons]: fullPageNavButtons,
        [css.fullPageTopBarMenu]: fullPageTopBarMenu,
        [css.extraRoundedCorners]: extraRoundedCorners,
        [css.noBottomRoundedCorners]: noBottomRoundedCorners,
        [css.fullWidth]: fullWidth,
        [css.grow]: grow,
        [css.hideOverflow]: hideOverflow
      }
    )}
    style={{
      ...{minHeight},
      ...style
    }}
    {...props}
  />
)
