import {ALL_INVESTOR_TRANSLATION_KEYS} from '../constants'

const ENGLISH_TRANSLATIONS = {}

for (const key in ALL_INVESTOR_TRANSLATION_KEYS) {
  ENGLISH_TRANSLATIONS[ALL_INVESTOR_TRANSLATION_KEYS[key]] =
    ALL_INVESTOR_TRANSLATION_KEYS[key]
}

export default ENGLISH_TRANSLATIONS
