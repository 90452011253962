import {IFirmConfiguration} from '@d1g1t/api/models'

import {ISettings} from './typings'

export const mergeDateSettings = (
  savedSettings: ISettings,
  firmConfiguration: IFirmConfiguration,
  latestPrimedDate?: string
): ISettings => {
  if (savedSettings.date && savedSettings.date.date) {
    return savedSettings
  }

  const defaultDate = {
    ...savedSettings.date,
    date:
      savedSettings.date?.date ??
      latestPrimedDate ??
      firmConfiguration.latestDataAvailable,
    value: 'specificDate'
  }

  return {
    ...savedSettings,
    date: defaultDate
  }
}

export const calculationDateFromDateString = (
  dateString: string
): ISettings['date'] => {
  return {
    date: dateString,
    value: 'specificDate'
  }
}
