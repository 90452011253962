import {IAllocationExclusion} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class AllocationExclusionsEndpoints extends BaseRestEndpoints {
  static basePath = '/allocation-exclusions'

  static create(body: IAllocationExclusion) {
    return super._create<IAllocationExclusion>(body)
  }

  static destroy(body: IAllocationExclusion) {
    return super._destroy('', body)
  }
}
