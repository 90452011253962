import {ISecurityRiskRating} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class SecurityRiskRatingEndpoints extends BaseRestEndpoints {
  static basePath = '/security-risk-ratings'

  static list() {
    return super._list<IApiListResponse<ISecurityRiskRating>>()
  }
}
