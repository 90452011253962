import {createContext, useContext} from 'react'

import {IControl} from '@d1g1t/typings/general'

import {ISearchResult} from '@d1g1t/shared/containers/search'
import {ISelectEntitiesState} from '@d1g1t/shared/containers/select-entities'

export interface ISelectEntitiesContext {
  /**
   * Selected entities state, can be passed directly to route builders
   */
  selection: Partial<ISelectEntitiesState>
  control: IControl
  /**
   * `true` when there is no selection and none is currently loading
   */
  noData: boolean
  /**
   * First or focused search result
   */
  focusedSearchResult: ISearchResult
  /**
   * Expanded search results for all selected entitiy IDs
   */
  searchResults: ISearchResult[] | null
  /**
   * Refresh the data for all selected entities
   */
  refresh: () => void
}

export const SelectEntitiesContext = createContext<ISelectEntitiesContext>(null)
if (__DEVELOPMENT__) {
  SelectEntitiesContext.displayName = 'SelectEntitiesContext'
}

export function useSelectedEntities() {
  return useContext(SelectEntitiesContext)
}
