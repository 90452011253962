import React from 'react'

import {IFirmConfiguration} from '@d1g1t/api/models'

import {PhoneFormatter} from '@d1g1t/lib/formatters/phone-formatter'

import {Flex} from '@d1g1t/shared/components/flex'
import {H2, Text} from '@d1g1t/shared/components/typography'

import {FOOTER_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'

import {useStyles} from '../../use-style'

import * as css from '../../style.scss'

export const GetInTouch: React.FC<
  Pick<IFirmConfiguration, 'phone1' | 'phone2' | 'phone3' | 'email'>
> = ({phone1, phone2, phone3, email}) => {
  const classes = useStyles()
  const phoneFormatter = new PhoneFormatter()
  return (
    <Flex column className={css.footerSection}>
      {(phone1 || phone2 || phone3 || email) && (
        <H2 bold className={css.footerSectionLabel}>
          {FOOTER_TRANSLATION_KEYS.GET_IN_TOUCH}
        </H2>
      )}
      {(phone1 || phone2) && (
        <Flex column className={css.footerSectionItem}>
          <Text bold>{FOOTER_TRANSLATION_KEYS.MAIN}</Text>
          {phone1 && (
            <Text
              className={classes.footerLink}
              onClick={() => {
                window.open(`tel:${phone1}`)
              }}
            >
              {phoneFormatter.format(phone1)}
            </Text>
          )}
          {phone2 && (
            <Text
              className={classes.footerLink}
              onClick={() => {
                window.open(`tel:${phone2}`)
              }}
            >
              {phoneFormatter.format(phone2)}
            </Text>
          )}
        </Flex>
      )}
      {phone3 && (
        <Flex column className={css.footerSectionItem}>
          <Text bold>{FOOTER_TRANSLATION_KEYS.FAX}</Text>
          <Text
            className={classes.footerLink}
            onClick={() => {
              window.open(`tel:${phone3}`)
            }}
          >
            {phoneFormatter.format(phone3)}
          </Text>
        </Flex>
      )}
      {email && (
        <Flex column className={css.footerSectionItem}>
          <Text bold>{FOOTER_TRANSLATION_KEYS.EMAIL}</Text>
          <Text
            className={classes.footerLink}
            onClick={() => {
              window.open(`mailto:${email}`)
            }}
          >
            {email}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
