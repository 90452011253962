import {IPaginationOptions} from '@d1g1t/api/pagination-typings'
import {IFilterValue} from '@d1g1t/typings/general'

import {BaseRestEndpoints} from './base'

export class DocumentEndpoints extends BaseRestEndpoints {
  static basePath = '/dmanager/documents'

  static chart(body: {filter?: IFilterValue; pagination: IPaginationOptions}) {
    return super._chart(body, JSON.stringify(body.filter))
  }

  static delete(body: Array<{url: string /* document url */}>) {
    return super._post('/delete', {body})
  }
}
