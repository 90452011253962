import './reducers'

import React, {useEffect, useLayoutEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {debounce} from 'lodash'

import {userActivityActions} from './actions'
import {TIME_UNTIL_IDLE_MS} from './constants'
import {getIsActive} from './getters'

const EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange'
] as (keyof DocumentEventMap)[]

const LISTENER_OPTIONS: AddEventListenerOptions = {
  capture: true,
  passive: true
}

/**
 * Dispatches actions to redux when a user has become
 * idle or active again (alternates back and forth).
 */
export const UserActivityTracker: React.FC = (props) => {
  const dispatch = useDispatch()
  const isActive = useSelector(getIsActive)

  useEffect(() => {
    // initialize as idle
    dispatch(userActivityActions.idle())
  }, [])

  useLayoutEffect(() => {
    if (isActive === null) {
      return
    }

    const handler = isActive
      ? debounce(() => dispatch(userActivityActions.idle()), TIME_UNTIL_IDLE_MS)
      : () => dispatch(userActivityActions.active())

    for (const event of EVENTS) {
      document.addEventListener(event, handler, LISTENER_OPTIONS)
    }

    return () => {
      for (const event of EVENTS) {
        document.removeEventListener(event, handler, LISTENER_OPTIONS)
      }
    }
  }, [isActive])

  return null
}
