import {ApiParam} from 'fairlight'

import {
  IAllocationExclusionPairIdRequest,
  IAllocationExclusionTrading,
  IChartTable
} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class TradingAllocationExclusionsEndpoints extends BaseRestEndpoints {
  static basePath = '/trading/allocation-exclusions'

  static chart(relatedEntity?: string) {
    return super._post<IChartTable>('/chart', {
      query: {related_entity: relatedEntity},
      body: {}
    })
  }

  static findById(id: ApiParam) {
    return super._findById<IAllocationExclusionTrading>(id)
  }

  static create(body: IAllocationExclusionTrading) {
    return super._create<IAllocationExclusionTrading>(body)
  }

  static partialUpdate(
    id: ApiParam,
    body: Partial<IAllocationExclusionTrading>
  ) {
    return super._partialUpdate<IAllocationExclusionTrading>(id, body)
  }

  static update(id: ApiParam, body: IAllocationExclusionTrading) {
    return super._update<IAllocationExclusionTrading>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static bulkRemove(body: IAllocationExclusionPairIdRequest[]) {
    return super._post<IAllocationExclusionPairIdRequest[]>('/bulk-remove', {
      body
    })
  }
}
