import {RouteBuilder} from '@d1g1t/lib/url'

export class BillingLocations extends RouteBuilder {
  static basePath = '/billing'

  /**
   * Billing module is divided into two sections: 'Configure Billing' and 'Manage Billing'.
   *
   * MANAGE BILLING SECTION: this is step 2 where advisors can see the fees that will be charged.
   */
  static manageBilling = 'manage-billing'

  static calculatedFees() {
    return super.path(`/${this.manageBilling}/calculated-fees`)
  }

  static calculatedFeeSingle(id: string) {
    return super.path(`/${this.manageBilling}/calculated-fees/${id}`)
  }

  /**
   * Billing module is divided into two sections: 'Configure Billing' and 'Manage Billing'.
   *
   * CONFIGURE BILLING SECTION: this is step 1 where advisors configure the fee structure.
   */
  static configureBilling = 'configure-billing'

  static billingGroups() {
    return super.path(`/${this.configureBilling}/billing-groups`)
  }

  static billingGroupCreate() {
    return super.path(`/${this.configureBilling}/billing-groups/create`)
  }

  static billingGroupSingleEdit(id: string) {
    return super.path(`/${this.configureBilling}/billing-groups/edit/${id}`)
  }

  static billingGroupSingle(id: string) {
    return super.path(`/${this.configureBilling}/billing-groups/${id}`)
  }

  static feeSchedules() {
    return super.path(`/${this.configureBilling}/fee-schedule`)
  }

  static feeSharingRules() {
    return super.path(`/${this.configureBilling}/fee-sharing-rules`)
  }

  static feeScheduleSingle(id: string) {
    return super.path(`/${this.configureBilling}/fee-schedule/${id}`)
  }

  static feeScheduleSingleEdit(id: string) {
    return super.path(`/${this.configureBilling}/fee-schedule/edit/${id}`)
  }

  static feeScheduleCreate() {
    return super.path(`/${this.configureBilling}/fee-schedule/create`)
  }

  static feeSpecs() {
    return super.path(`/${this.configureBilling}/fee-specs`)
  }

  static feeSpecsCreate() {
    return super.path(`/${this.configureBilling}/fee-specs/create`)
  }

  static feeSpecSingleEdit(id: string) {
    return super.path(`/${this.configureBilling}/fee-specs/edit/${id}`)
  }

  static feeSpecSingle(id: string) {
    return super.path(`/${this.configureBilling}/fee-specs/${id}`)
  }

  static feeSharingRuleCreate() {
    return super.path(`/${this.configureBilling}/fee-sharing-rules/create`)
  }

  static billingProperties() {
    return super.path(`/${this.configureBilling}/billing-properties`)
  }

  static billingPropertiesSingleEdit(id: string) {
    return super.path(`/${this.configureBilling}/billing-properties/edit/${id}`)
  }

  static billingPropertiesCreate() {
    return super.path(`/${this.configureBilling}/billing-properties/create`)
  }
}
