import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {ApiError} from 'fairlight'
import {FormikHelpers} from 'formik'

import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import {useSnackbar} from '@d1g1t/shared/containers/snackbar'

import {errorHandlerActions} from './actions'
import {errorMessage, hasFieldsForAllErrors} from './lib'

export * from './lib'
export * from './actions'

type FormErrorHandler<IFormState extends object = object> = (
  error: Error,
  formikBag: FormikHelpers<IFormState>,
  initialValues: IFormState,
  unexpectedErrorSnackbarMessage?: string
) => void

interface IUseErrorHandlerReturnType {
  /**
   * Handles an unexpected error by reporting the error
   * and showing a snackbar notification to the user.
   *
   * If the second argument is `null`, no snackbar will show.
   */
  handleUnexpectedError(
    error: Error,
    snackbarMessage?: string | null,
    unwrapErrorMessages?: boolean,
    hideParentKey?: boolean
  ): void
  /**
   * Handles a form error by either translating a 400-level `ApiError`
   * to formik errors, or handling an unexpected error.
   */
  handleFormError: FormErrorHandler
}

export function useErrorHandler(): IUseErrorHandlerReturnType {
  const {t} = useTranslation()
  const {showSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const handleUnexpectedError = (
    error: Error,
    snackbarMessage?: string | null,
    unwrapErrorMessages?: boolean,
    hideParentKey?: boolean
  ) => {
    dispatch(
      errorHandlerActions.handleError({
        error,
        snackbarMessage: onlyTranslateStrings(t, snackbarMessage) as string,
        unwrapErrorMessages,
        hideParentKey
      })
    )
  }

  const handleFormError: FormErrorHandler = (
    error,
    formikBag,
    initialValues,
    unexpectedErrorSnackbarMessage?: string
  ) => {
    if (
      error instanceof ApiError &&
      error.status === 400 &&
      typeof error.responseBody === 'object' &&
      !Array.isArray(error.responseBody) &&
      hasFieldsForAllErrors(error.responseBody, initialValues)
    ) {
      formikBag.setErrors(error.responseBody)
      showSnackbar({
        variant: 'error',
        message:
          'Some form fields are invalid. Correct each of the errors and re-submit.'
      })
    } else {
      const message = errorMessage(error)

      if (message) {
        showSnackbar({
          variant: 'error',
          message
        })
      } else {
        handleUnexpectedError(error, unexpectedErrorSnackbarMessage)
      }
    }
  }

  return {
    handleUnexpectedError,
    handleFormError
  }
}
