import * as Yup from 'yup'

export enum LOGIN_FORM_FIELD_NAMES {
  username = 'username',
  password = 'password',
  mfaCode = 'otp',
  mfaDestination = 'deliveryDestination'
}

export const INITIAL_VALUES = {
  [LOGIN_FORM_FIELD_NAMES.username]: '',
  [LOGIN_FORM_FIELD_NAMES.password]: '',
  [LOGIN_FORM_FIELD_NAMES.mfaCode]: null,
  [LOGIN_FORM_FIELD_NAMES.mfaDestination]: null
}

export const LOGIN_FORM_QUERY_PARAM_SCHEMA = Yup.object({
  redirectTo: Yup.string()
})

export const AUTH_METHOD_PASSWORD = 'password'
