import {ICurrency} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class CurrencyEndpoints extends BaseRestEndpoints {
  static basePath = '/market/currency'

  static list() {
    return super._list<IApiListResponse<ICurrency>>()
  }
}

export class CurrencyVectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/currency-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
