import {match} from 'react-router-dom'

import {IAuthMethod} from '@d1g1t/api/models'

import {AuthenticationLocations} from '@d1g1t/shared/locations'

/**
 * Returns true when rendering advisor app login form, and the method is marked as available to external-profiles.
 * Returns true when rendering investor app login form, and the method is marked as available to internal-profiles.
 *
 * @param method - Auth method
 * @param investorApp - should be passed `true` when rendering investor app `<LoginForm />`.
 *
 * @returns boolean when method is available for the advisor/investor app.
 */
export const methodVisibilityPredicate = (
  method: IAuthMethod,
  investorApp: boolean,
  listAllMatch: match<{}>
): boolean => {
  return (
    (investorApp &&
      method.forExternalProfile &&
      (!!listAllMatch || !method.hiddenForExternalProfile)) ||
    (!investorApp &&
      method.forInternalProfile &&
      (!!listAllMatch || !method.hiddenForInternalProfile))
  )
}

/**
 * Returns a string of url to the SSO login page. If login_redirect is already in param, replace it.
 * @param url - login url for SSO
 * @returns string of the login url with redirect param set
 */
export const appendLoginRedirect = (url: string): string => {
  const newUrl = new URL(url)
  const searchParams = new URLSearchParams(newUrl.search)
  searchParams.set(
    'login_redirect',
    `${window.location.origin}/#${AuthenticationLocations.token()}`
  )
  newUrl.search = searchParams.toString()
  return newUrl.toString()
}
