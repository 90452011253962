import {Theme} from '@material-ui/core'
import {createTheme, ThemeOptions} from '@material-ui/core/styles'
import {PaletteOptions} from '@material-ui/core/styles/createPalette'

/**
 * Generic theme, used as a base for all other themes
 */
export const muiGenericTheme = createTheme({
  zIndex: {
    modal: 1401
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: 'Source Sans Pro, sans-serif',
    subtitle1: {
      lineHeight: 1
    },
    subtitle2: {
      lineHeight: 1
    }
  },
  overrides: {
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 18
      }
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, .12)'
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, .12)'
        }
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
        '&$disabled': {
          backdropFilter: 'brightness(97%)',
          color: 'rgba(0, 0, 0, .54)'
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        '&> legend': {
          fontSize: '1.2rem'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40
      }
    },
    MuiListItemText: {
      inset: {
        paddingLeft: 40,
        marginBottom: 0,
        marginTop: 0
      }
    },
    MuiListSubheader: {
      inset: {
        paddingLeft: 40
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14
      }
    }
  }
})
/**
 * Light mode theme for advisor app
 */
export const muiLightThemeAdvisor = createTheme({
  ...muiGenericTheme,
  palette: {
    type: 'light',
    primary: {
      light: '#e3e3e3',
      main: '#0d47a1',
      dark: '#19345d'
    },
    grey: {
      100: '#f7f7f7',
      200: '#EDEDED',
      300: '#E0E0E0',
      400: '#C4C4C4',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#4A4A4A',
      900: '#212121'
    },
    text: {
      primary: '#616161'
    }
  }
})

export interface IPaletteOptions extends PaletteOptions {
  accent?: string
}
export interface ITheme extends Omit<Theme, 'palette'> {
  palette: IPaletteOptions
}
export interface IThemeOptions extends Omit<ThemeOptions, 'palette'> {
  palette?: IPaletteOptions
}
