import React from 'react'

import produce from 'immer'

import {BASIC_COLOURS} from '@d1g1t/config/theme/internal-theme'

import {SUPPORTED_CURRENCY} from '@d1g1t/lib/currency'

import {Formatter, IFormatterOptions} from './base-formatter'

export type CurrencyDisplay = 'narrowSymbol' | 'code'

export type INumberFormatOptions = (
  | {style?: 'decimal' | 'percent' | 'basis-points'}
  | {
      style?: 'currency'
      currency?: SUPPORTED_CURRENCY
      /**
       * How to display the currency. This is passed directly to `Intl.NumberFormat`
       *
       * One of: `narrowSymbol` or `code`
       *
       * https://github.com/tc39/proposal-unified-intl-numberformat/blob/master/README.md#iv-spec-cleanup
       */
      currencyDisplay?: CurrencyDisplay
    }
) & {
  /**
   * Number of decimal places to round/show
   */
  decimalPlaces?: number
  /**
   * If true, will format and display `0` values instead of treating them as `null`
   */
  allowZero?: boolean
  /**
   * If true, will apply coloring to differentiate negative from positive
   * when formatting to JSX
   */
  delta?: boolean
  /**
   * If true, will prefix positive values with a `+` sign
   */
  showPlus?: boolean
  /**
   * If true, will abbreviate using millions, etc.
   */
  abbreviate?: boolean
  /**
   * If true, will truncate trailing zeros
   */
  trailingZeroDisplay?: 'auto' | 'stripIfInteger'
  /**
   * Pass the user's locale for formatting numbers
   */
  locale?: string
}

/**
 * Wrapper around Intl.NumberFormat with opinionated parsing and NaN formatting
 */
export class NumberFormatter extends Formatter<INumberFormatOptions> {
  constructor(options: INumberFormatOptions & IFormatterOptions = {}) {
    super(
      produce(options, (draft) => {
        draft.style = draft.style || 'decimal'

        if (draft.decimalPlaces === undefined) {
          draft.decimalPlaces = draft.style === 'currency' ? 2 : 0
        }

        if (draft.style === 'currency' && draft.currencyDisplay === undefined) {
          draft.currencyDisplay = 'narrowSymbol'
        }
      })
    )

    this.intlNumberFormat = createIntlNumberFormatter(this.options)
  }

  private intlNumberFormat: Intl.NumberFormat

  private shouldRenderEmpty(value: number) {
    if (Number.isNaN(value)) {
      return true
    }

    if (
      this.options.allowZero === false &&
      numberRoundsToZero(value, this.options)
    ) {
      return true
    }

    return false
  }

  private applyFormat(value: number): string {
    if (this.options.style === 'basis-points') {
      // basis-points aren't supported by Intl.NumberFormat,
      // so we will apply some manual formatting
      return `${this.intlNumberFormat.format(value * 10000)} bp`
    }

    return this.intlNumberFormat.format(value)
  }

  format(unparsedValue) {
    const numberValue = parseNumber(unparsedValue)

    if (this.shouldRenderEmpty(numberValue)) {
      return this.renderEmpty()
    }

    const formattedValue = this.applyFormat(numberValue)

    return formattedValue
  }

  /**
   * Returns the JSX version of the formatted number
   */
  formatJSX(unparsedValue) {
    const numberValue = parseNumber(unparsedValue)

    if (this.shouldRenderEmpty(numberValue)) {
      return <>{this.renderEmpty()}</>
    }

    const formattedValue = this.applyFormat(numberValue)

    if (!this.options.delta || numberRoundsToZero(numberValue, this.options)) {
      return <>{formattedValue}</>
    }

    const color = numberValue > 0 ? BASIC_COLOURS.GREEN : BASIC_COLOURS.RED

    return <span style={{color}}>{formattedValue}</span>
  }
}

export function thresholdValueForDecimals(count: number): number {
  return 5 / 10 ** (1 + count)
}

export function parseNumber(value: any): number {
  if (value === null || value === '' || Array.isArray(value)) {
    return NaN
  }

  if (typeof value !== 'number') {
    return Number(value)
  }

  return value
}

export function numberRoundsToZero(
  value: number,
  options: INumberFormatOptions
): boolean {
  const decimalPlacesForStyle = (() => {
    switch (options.style) {
      case 'percent':
        return options.decimalPlaces + 2
      case 'basis-points':
        return options.decimalPlaces + 4
      default:
        return options.decimalPlaces
    }
  })()

  return Math.abs(value) < thresholdValueForDecimals(decimalPlacesForStyle)
}

function createIntlNumberFormatter(
  options: INumberFormatOptions
): Intl.NumberFormat {
  const intlNumberFormatterOptions: Intl.NumberFormatOptions & {
    // Supported in newest version of Intl.NumberFormat, but not yet part of ES spec
    // See https://github.com/tc39/proposal-unified-intl-numberformat
    signDisplay?: 'exceptZero'
    notation?: 'compact'
    /**
     * Adjust the style when `notation='compact'`
     * @defaultValue 'short'
     */
    compactDisplay?: 'long' | 'short'
    trailingZeroDisplay?: 'auto' | 'stripIfInteger'
  } = {
    style: ['decimal', 'percent', 'currency'].includes(options.style)
      ? options.style
      : 'decimal',
    trailingZeroDisplay: options.trailingZeroDisplay,
    minimumFractionDigits: !options.trailingZeroDisplay
      ? options.decimalPlaces
      : undefined,
    maximumFractionDigits: options.decimalPlaces
  }

  if (options.showPlus) {
    // @ts-ignore
    intlNumberFormatterOptions.signDisplay = 'exceptZero'
  }

  if (options.abbreviate) {
    intlNumberFormatterOptions.notation = 'compact'
  }

  if (options.style === 'currency') {
    intlNumberFormatterOptions.currency = options.currency
    intlNumberFormatterOptions.currencyDisplay = options.currencyDisplay
  }

  let numberFormatter: Intl.NumberFormat

  try {
    numberFormatter = new Intl.NumberFormat(
      options.locale,
      intlNumberFormatterOptions
    )
  } catch (e) {
    if (e.constructor !== RangeError) {
      throw e
    }

    intlNumberFormatterOptions.currencyDisplay = 'symbol'

    numberFormatter = new Intl.NumberFormat(
      options.locale,
      intlNumberFormatterOptions
    )

    console.warn(
      '`currencyDisplay` `narrowSymbol` is not supported; using `symbol` instead'
    )
  }

  const resolvedOptions = numberFormatter.resolvedOptions()

  if (
    options.showPlus &&
    !Object.prototype.hasOwnProperty.call(resolvedOptions, 'notation')
  ) {
    console.warn('NumberFormat `notation` option is not supported')
  }

  if (
    options.abbreviate &&
    !Object.prototype.hasOwnProperty.call(resolvedOptions, 'signDisplay')
  ) {
    console.warn('NumberFormat `signDisplay` option is not supported')
  }

  return numberFormatter
}
