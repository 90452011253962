import {useApiQuery} from 'fairlight'
import {isEmpty} from 'lodash'

import {AuthEndpoints, IEmulator} from '@d1g1t/api/endpoints'

/**
 * Returns emulator datails if user logs in as another user (emulation mode) and null otherwise
 */

export const useEmulation = (): IEmulator => {
  const [userData] = useApiQuery(AuthEndpoints.me(), {
    fetchPolicy: 'cache-first'
  })

  if (!userData.data) {
    return null
  }
  return isEmpty(userData.data.emulator) ? null : userData.data.emulator
}
