import {isEmpty, snakeCase} from 'lodash'

import {
  IAssetClass,
  ICurrencySimple,
  IInstrumentStrategy,
  IViewMetricItem,
  IViewMetricItemAssetClass,
  IViewMetricItemCurrency,
  IViewMetricItemDateRange,
  IViewMetricItemStrategy,
  VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
} from '@d1g1t/api/models'

import {DATE_OPTION_CUSTOM, IDateRange} from '@d1g1t/lib/date-range'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {generateCategoryIdForMetricProperties} from '@d1g1t/shared/wrappers/calculations'

export type ConditionResultsValue =
  | IViewMetricItemAssetClass
  | IViewMetricItemCurrency
  | IViewMetricItemStrategy

export type ConditionResultsModel =
  | ICurrencySimple
  | IInstrumentStrategy
  | IAssetClass

export type MappedConditionResultsValue<K extends CONDITION_RESULTS_KEY> =
  K extends CONDITION_RESULTS_KEY.ASSET_CLASSES
    ? IViewMetricItemAssetClass
    : K extends CONDITION_RESULTS_KEY.CURRENCIES
    ? IViewMetricItemCurrency
    : K extends CONDITION_RESULTS_KEY.STRATEGIES
    ? IViewMetricItemStrategy
    : unknown

export interface IMetricRequestSelection {
  id?: number
  order?: number
  slug: string
  contributionDimension?: number
  contributionDimension2?: number
  columnTitle: string
  dateRange?: Required<IDateRange>
  entityId?: string
  transactionAsOfDate?: VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
}

export interface IMetricRequest {
  selected: IMetricRequestSelection[]
}

export const mapMetricsToRequest = (
  metrics: IViewMetricItem[]
): IMetricRequestSelection[] => {
  if (!metrics) {
    return []
  }

  return metrics
    .filter((metric) => metric.path)
    .map((metric, order) => {
      const requestMetric: IMetricRequestSelection = {
        order,
        slug: extractIdFromUrl(metric.metric),
        contributionDimension: metric.contributionDimension,
        contributionDimension2: metric.contributionDimension2,
        columnTitle: metric.displayName
      }

      if (metric.dateRange && metric.dateRange.startDate) {
        requestMetric.dateRange = {
          ...DATE_OPTION_CUSTOM,
          ...metric.dateRange
        }
      }

      if (metric.entityId) {
        requestMetric.entityId = metric.entityId
      }

      if (metric.transactionAsOfDate) {
        requestMetric.transactionAsOfDate = metric.transactionAsOfDate
      }

      return requestMetric
    })
}

export enum CONTRIBUTION_DIMENSION_FIELD_NAME {
  ASSET_CLASSES = 'asset_classes',
  CURRENCIES = 'currencies',
  STRATEGIES = 'strategies'
}

export enum CONDITION_RESULTS_KEY {
  ASSET_CLASSES = 'assetClasses',
  CURRENCIES = 'currencies',
  STRATEGIES = 'strategies'
}

export enum CONDITION_RESULTS_OPTION_KEY {
  ASSET_CLASS = 'assetClass',
  CURRENCY = 'currency',
  STRATEGY = 'strategy'
}

export const optionResultsKeyFromResultsKey = (
  resultsKey: CONDITION_RESULTS_KEY
): CONDITION_RESULTS_OPTION_KEY => {
  switch (resultsKey) {
    case CONDITION_RESULTS_KEY.ASSET_CLASSES:
      return CONDITION_RESULTS_OPTION_KEY.ASSET_CLASS
    case CONDITION_RESULTS_KEY.CURRENCIES:
      return CONDITION_RESULTS_OPTION_KEY.CURRENCY
    case CONDITION_RESULTS_KEY.STRATEGIES:
      return CONDITION_RESULTS_OPTION_KEY.STRATEGY
  }
}

export const conditionResultsOptionKey = (
  option: ConditionResultsValue
): CONDITION_RESULTS_OPTION_KEY => {
  if ('assetClass' in option) {
    return CONDITION_RESULTS_OPTION_KEY.ASSET_CLASS
  }

  if ('currency' in option) {
    return CONDITION_RESULTS_OPTION_KEY.CURRENCY
  }

  if ('strategy' in option) {
    return CONDITION_RESULTS_OPTION_KEY.STRATEGY
  }
}

export const conditionResultsOptionModel = (
  option: ConditionResultsValue
): ConditionResultsModel => {
  if ('assetClass' in option) {
    return option.assetClass
  }

  if ('currency' in option) {
    return option.currency
  }

  if ('strategy' in option) {
    return option.strategy
  }
}

export const slugFromConditionModel = (
  model: ConditionResultsModel
): string => {
  if (!model) {
    return null
  }

  if ('slug' in model) {
    return model.slug
  }

  if ('url' in model) {
    return extractIdFromUrl(model.url)
  }
}

export const conditionResultCategoriesFromItems = (
  items: IViewMetricItem[]
): Dictionary<string[]> => {
  const result = []
  for (const item of items) {
    if (!item.contributionDimension) {
      continue
    }

    const metricSlug = item.path[item.path.length - 1]
    const includedChildIds = []

    for (const key of Object.values(
      CONDITION_RESULTS_KEY
    ) as CONDITION_RESULTS_KEY[]) {
      if (isEmpty(item[key])) {
        continue
      }

      const parentId = `${metricSlug}:${snakeCase(key)}`
      const optionKey = optionResultsKeyFromResultsKey(key)

      const value = item[key] as ConditionResultsValue[]

      for (const option of value) {
        const model = option[optionKey] as ConditionResultsModel
        const slug = slugFromConditionModel(model) || 'None'

        const childId = `${parentId}:${slug}`

        includedChildIds.push(childId)
      }

      result.push({
        parentId,
        includedChildIds
      })
    }
  }

  return result.reduce(
    (prev, next) => ({
      ...prev,
      [next.parentId]: next.includedChildIds
    }),
    {}
  )
}

/**
 * Converts the `dateRange` object inside a metric item to the format used by PS
 * to construct category ids
 */
export const metricItemDateRangeSuffix = (
  dateRange: IViewMetricItemDateRange
): string => {
  if (!dateRange) {
    return ''
  }

  if (!dateRange.startDate && !dateRange.endDate) {
    return ''
  }

  // Format here matches format returned by PS for category IDs with custom dates
  return `from_${dateRange.startDate}_to_${dateRange.endDate}`
}

/**
 * Returns the category id PS is expected to send for a metric item, accounts for
 * custom date ranges
 *
 * NOTE: this does not account for contribution dimentions see
 * https://r2fcapital.atlassian.net/browse/FRON-3096
 */
export const categoryIdForMetricItem = (
  metricItem: IViewMetricItem
): string => {
  const slug = extractIdFromUrl(metricItem.metric)

  return generateCategoryIdForMetricProperties({
    slug,
    dateRange:
      metricItem.dateRange &&
      metricItem.dateRange.startDate &&
      metricItem.dateRange.endDate
        ? metricItemDateRangeSuffix(metricItem.dateRange)
        : null,
    entityId: metricItem.entityId,
    transactionAsOfDate: metricItem.transactionAsOfDate
  })
}
