import {ApiParam} from 'fairlight'

import {
  IEntityCollection,
  IEntityCollectionMember,
  IEntityIdList
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class CollectionEndpoints extends BaseRestEndpoints {
  static basePath = '/datasets/collections'

  static list() {
    return super._list<IApiListResponse<IEntityCollection>>()
  }

  static create(body: IEntityCollection) {
    return super._create<IEntityCollection>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IEntityCollection>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IEntityCollection>) {
    return super._partialUpdate<IEntityCollection>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static getMembers(id: ApiParam) {
    return super._get<IApiListResponse<IEntityCollectionMember>>(
      `/${id}/members`
    )
  }

  static postMembers(id: ApiParam, body: IEntityIdList) {
    return super._post<IEntityCollectionMember[]>(`/${id}/members`, {body})
  }

  static removeMembers(id: ApiParam, body: IEntityIdList) {
    return super._post<{deleted: number}>(`/${id}/remove-members`, {body})
  }

  static chart(body: {pagination: IPaginationOptions}) {
    return super._chart(body)
  }
}
