import {IFirm} from '@d1g1t/api/models'

import {IApiListResponse} from './'
import {BaseRestEndpoints} from './base'

export class PeopleFirmsEndpoints extends BaseRestEndpoints {
  static basePath = '/people/firms'

  static list() {
    return super._list<IApiListResponse<IFirm>>()
  }
}
