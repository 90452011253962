import {ApiParam} from 'fairlight'

import {IInstrument} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

type IInstrumentListParams = {
  position_holding__in?: string
  private_equity_holding__in?: string
  limit?: number
} & (
  | {
      portfolio: string
      holding_as_of_date: string
    }
  | {}
)

export interface IUpdateInstrument
  extends Omit<
    Partial<IInstrument>,
    | 'assetClass'
    | 'assetClassL2'
    | 'assetCategory'
    | 'assetSubcategory'
    | 'sector'
    | 'strategy'
    | 'subStrategy'
    | 'riskReturn'
    | 'region'
    | 'liquidity'
    | 'exchange'
    | 'industry'
  > {
  assetClass?: {url: string}
  assetClassL2?: {url: string}
  assetCategory?: {url: string}
  assetSubcategory?: {url: string}
  sector?: {url: string}
  strategy?: {url: string}
  subStrategy?: {url: string}
  riskReturn?: {url: string}
  region?: {url: string}
  liquidity?: {url: string}
  exchange?: {url: string}
  industry?: {url: string}
}

export class InstrumentEndpoints extends BaseRestEndpoints {
  static basePath = '/instruments'

  static list(query: IInstrumentListParams) {
    return super._list<IApiListResponse<IInstrument>>(query)
  }

  static create(params: IInstrument) {
    return super._create<IInstrument>(params)
  }

  static findById(id: ApiParam) {
    return super._findById<IInstrument>(id)
  }

  static partialUpdate(id: ApiParam, body: IUpdateInstrument) {
    return super._partialUpdate<IInstrument>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }
}
