import {useEffect} from 'react'

import {datadogRum} from '@datadog/browser-rum'
import {endsWith} from 'lodash'

import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {useAuth} from '../auth'

/**
 * Checks if user is logged in and sets datadog user with userProfile user object.
 * When the user is using a d1g1t.com email, is_d1g1t is set to true, else false.
 */
export const useDatadogConfig = () => {
  const {loggedIn} = useAuth()
  const [userProfile] = useUserProfile()

  useEffect(() => {
    if (__PRODUCTION__ && loggedIn && userProfile.user) {
      datadogRum.setUser({
        is_d1g1t: endsWith(userProfile.user.email, '@d1g1t.com'),
        ...userProfile.user
      })
    }
  }, [loggedIn, userProfile.user])
}
