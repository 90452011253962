import {ApiParam} from 'fairlight'

import {IInvestmentMandateRiskConstraintDetail} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {BaseRestEndpoints} from './base'

export class PeopleRiskConstraints extends BaseRestEndpoints {
  static basePath = '/people/investment-mandate-risk-constraints'

  static list(date: string) {
    return super._list<
      IApiListResponse<IInvestmentMandateRiskConstraintDetail>
    >({
      as_of_date: date
    })
  }

  static findById(id: ApiParam, date: string) {
    return super._get<IInvestmentMandateRiskConstraintDetail>(`/${id}`, {
      query: {as_of_date: date}
    })
  }

  static partialUpdate(
    id: ApiParam,
    body: IInvestmentMandateRiskConstraintDetail,
    date: string
  ) {
    return super._patch<IInvestmentMandateRiskConstraintDetail>(`/${id}`, {
      body,
      query: {as_of_date: date}
    })
  }

  static destroy(
    body: Pick<IInvestmentMandateRiskConstraintDetail, 'url'>[],
    date: string
  ) {
    return super._delete('', {body, query: {as_of_date: date}})
  }

  static create(
    body:
      | IInvestmentMandateRiskConstraintDetail
      | IInvestmentMandateRiskConstraintDetail[],
    date: string
  ) {
    return super._post<IInvestmentMandateRiskConstraintDetail>('', {
      body,
      query: {as_of_date: date}
    })
  }

  static bulkUpdate(
    body: IInvestmentMandateRiskConstraintDetail[],
    date: string
  ) {
    return super._patch<IInvestmentMandateRiskConstraintDetail[]>('/', {
      body,
      query: {as_of_date: date}
    })
  }
}
