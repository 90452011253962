import React from 'react'

import {EXTERNALPROFILE_DATE_FORMAT} from '@d1g1t/api/models'

export interface IFormatterOptions {
  /**
   * If true, will display `-` for null (or invalid) values instead of `''`
   */
  displayNullsAsDashes?: boolean
  dateFormat?: EXTERNALPROFILE_DATE_FORMAT
}

export abstract class Formatter<TFormatterOptions extends {} = {}> {
  protected options: IFormatterOptions & TFormatterOptions

  constructor(
    options: IFormatterOptions & TFormatterOptions = {} as IFormatterOptions &
      TFormatterOptions
  ) {
    this.options = options
  }

  protected renderEmpty() {
    return this.options.displayNullsAsDashes ? '-' : ''
  }

  public resolvedOptions() {
    return this.options
  }

  public abstract format(value: any): string

  public formatJSX(value: any): JSX.Element {
    return <>{this.format(value)}</>
  }
}
