import React, {useMemo} from 'react'

import MuiMenu, {MenuProps} from '@material-ui/core/Menu'

import {MenuStateProvider} from './context'

const blurDocument = () => {
  const active = document.activeElement as HTMLElement
  if (active && active.nodeName !== 'INPUT') {
    active.blur()
  }
}

export interface IMenuProps extends Optional<MenuProps, 'open'> {
  /**
   * When true, the select will retain focus after a value has been selected
   */
  retainFocus?: boolean
  /**
   * Inversion of `disableAutoFocusItem` which is handy for key access
   * Set to true to get the default selected behaviour when opening menus
   */
  enableAutoFocusItem?: boolean
}

export const muiMenuPropsFromCustomProps = ({
  retainFocus,
  enableAutoFocusItem,
  onExited,
  ...props
}: IMenuProps): Optional<MenuProps, 'open'> => {
  const onExitedModified: MenuProps['onExited'] = useMemo(() => {
    if (retainFocus && typeof onExited !== 'function') {
      return null
    }

    if (retainFocus && typeof onExited === 'function') {
      return onExited
    }

    if (!retainFocus && typeof onExited !== 'function') {
      return blurDocument
    }

    return (node: HTMLElement) => {
      blurDocument()
      onExited(node)
    }
  }, [retainFocus, onExited])

  return {
    disableAutoFocusItem: !enableAutoFocusItem,
    TransitionProps: {onExited: onExitedModified},
    ...props
  }
}

export const Menu: React.FC<IMenuProps> = React.forwardRef((props, ref) => {
  return (
    <MenuStateProvider open={props.open || false}>
      <MuiMenu
        open={undefined} // to satisfy TS
        {...muiMenuPropsFromCustomProps(props)}
        ref={ref}
      />
    </MenuStateProvider>
  )
})
