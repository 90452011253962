import {useTranslation} from 'react-i18next'

import * as Yup from 'yup'

import {FEATURE_FLAGS} from '@d1g1t/config/feature-flags'

import {LOGIN_PAGE_TRANSLATION_KEYS_SHARED} from '@d1g1t/shared/wrappers/localization-settings/constants'

import {LOGIN_FORM_FIELD_NAMES} from './constants'

export function useValidationLocalizationSchema() {
  const {t} = useTranslation()

  const mfaDestinationSchema = Yup.object({
    [LOGIN_FORM_FIELD_NAMES.mfaDestination]: Yup.string()
      .nullable()
      .required(
        t(
          LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AUTHENTICATION_DESTINATION_IS_REQUIRED
        )
      )
  })

  const mfaValidationSchema = Yup.object({
    [LOGIN_FORM_FIELD_NAMES.mfaCode]: Yup.number()
      .nullable()
      .required(
        t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.AUTHENTICATION_CODE_IS_REQUIRED)
      )
  })

  const validationSchema = Yup.object({
    [LOGIN_FORM_FIELD_NAMES.username]: Yup.string().required(
      t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.EMAIL_ADDRESS_IS_REQUIRED)
    ),
    [LOGIN_FORM_FIELD_NAMES.password]: Yup.string()
      .min(
        FEATURE_FLAGS.PASSWORD_COMPLEXITY ? 16 : 8,
        FEATURE_FLAGS.PASSWORD_COMPLEXITY
          ? t(
              LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD_MUST_BE_AT_LEAST_16_CHARACTERS
            )
          : t(
              LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS
            )
      )
      .required(t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD_IS_REQUIRED))
      .label(t(LOGIN_PAGE_TRANSLATION_KEYS_SHARED.PASSWORD))
  })

  return [mfaValidationSchema, validationSchema, mfaDestinationSchema]
}
