import {useContext, useMemo} from 'react'

import {memoize} from 'lodash'

import {ConfigContext} from '@d1g1t/config/context'

import {DateFormatter} from '@d1g1t/lib/formatters/date-formatter'
import {usePrimedDates} from '@d1g1t/lib/hooks'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {DEFAULT_SETTINGS, SETTINGS_ID} from './constants'
import {CalculationSettingsOverrideContext} from './context'
import {mergeDateSettings} from './lib'
import {ISettings} from './typings'

/**
 * Memo value of settings to avoid re-renders when merging an override with
 * the same values as current value.
 */
const stableCalculationSettings = memoize(
  (currency: string, date: string, dateValue: string): ISettings => {
    return {
      currency,
      date: {
        date,
        value: dateValue
      }
    }
  },
  // Custom cache key resolver to use all args, default lodash resolver
  // only uses first arg as a cache key
  (...args: string[]) => args.join(',')
)

/**
 * Used to get overrides and set overrides, calculations do not read directly
 * from this context, rather it is merged into the global calculation settings
 */
export function useCalculationSettingsOverride() {
  return useContext(CalculationSettingsOverrideContext)
}

/**
 *
 * Compose calculation settings
 */
export function useCalculationSettings(options?: {
  /**
   * If passed, uses the calendar date instead of the `firmConfig.latestPrimedDate`
   * when building calc settings without any user specified date. This allows
   * us to default the calc settings to use the calendar date by default, rather
   * than the firm's latest primed dates. This is usedful for various trading
   * pages where advisors need to see latest info, and rebalance on non-primed dates.
   */
  ignoreFirmConfigLatestDate?: boolean
}): [
  ISettings,
  {
    updateCalculationSettings(settings: Partial<ISettings>): void
  }
] {
  const config = useContext(ConfigContext)

  const {latestPrimedDate} = usePrimedDates(config.enablePrimedDates)

  const {firmConfiguration} = useFirmConfiguration()

  const defaultSettings = useMemo(() => {
    if (
      config.useReportingCurrencyForDefaultCalculationSettings === null ||
      !firmConfiguration.data
    ) {
      return null
    }

    const defaultReportingCurrency = extractIdFromUrl(
      firmConfiguration.data?.firm.reportingCurrencies.find(
        (reportingCurrency) => reportingCurrency.isDefault
      )?.currency.url
    )

    return config.useReportingCurrencyForDefaultCalculationSettings
      ? {
          ...DEFAULT_SETTINGS,
          currency: defaultReportingCurrency
        }
      : DEFAULT_SETTINGS
  }, [
    config.useReportingCurrencyForDefaultCalculationSettings,
    firmConfiguration.data
  ])

  const [settings, {updateGlobalSettings}] = useGlobalSettings(
    SETTINGS_ID,
    defaultSettings,
    [defaultSettings?.currency]
  )
  const [settingsOverride] = useCalculationSettingsOverride() // from URL

  const data = useMemo(() => {
    if (!settings || !firmConfiguration.data) {
      return null
    }

    const {currency, date} = mergeDateSettings(
      {...settings, ...settingsOverride},
      firmConfiguration.data,
      options?.ignoreFirmConfigLatestDate
        ? new DateFormatter().format(new Date())
        : latestPrimedDate
    )

    return stableCalculationSettings(currency, date.date, date.value)
  }, [
    settings,
    settingsOverride,
    firmConfiguration.data,
    latestPrimedDate,
    options?.ignoreFirmConfigLatestDate
  ])

  return [data, {updateCalculationSettings: updateGlobalSettings}]
}
