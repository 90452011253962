import {IAssetClassL3} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class AssetClassL3Endpoints extends BaseRestEndpoints {
  static basePath = '/asset-classes-l3'

  static list() {
    return super._list<IApiListResponse<IAssetClassL3>>()
  }
}

export class AssetClassL3VectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/asset-class-l3-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
