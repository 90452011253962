import {ApiParam} from 'fairlight'

import {IRetirementGoal} from '@d1g1t/api/models'
import {IControl} from '@d1g1t/typings/general'

import {IDateRange} from '@d1g1t/lib/date-range'

import {ISettings} from '@d1g1t/shared/wrappers/calculation-settings'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export const retirementGoalsPathKey = 'retirement-goals'

export interface IBasicRequestBody {
  control: IControl
  settings: ISettings
}

export interface IClientRequestBody extends IBasicRequestBody {
  options: {
    dateRange?: IDateRange
    singleResult?: boolean
  }
}

export interface ISimulation {
  id: number
  projectedWealth: ISimulationPoint[]
  targetWealth?: number
  sensitivities?: any
  returnProfile: IReturnProfilePoint[]
  startingRateOfReturn: number
  endingRateOfReturn: number
  exceedanceProbability?: number
  expectedWealth?: number
  age?: number
  targetAverageLongTermRateOfReturn: number
  decumulationStartsAt: number
}

export interface ISimulationPoint {
  avg: number
  band50: {
    min: number
    max: number
  }
  band80: {
    min: number
    max: number
  }
  year: number
}

export interface IReturnProfilePoint {
  year: number
  returnRate: number
}

export interface IRequiredGrowthRate {
  result: number[]
}

export class RetirementGoalEndpoints extends BaseRestEndpoints {
  static basePath = `/goals/${retirementGoalsPathKey}`

  static chart(body: IClientRequestBody) {
    return super._chart(body, JSON.stringify(body))
  }

  static list(query: {clientEntityId: string}) {
    return super._list<IApiListResponse<IRetirementGoal>>({
      client__entity_id: query.clientEntityId
    })
  }

  static create(body: IRetirementGoal) {
    return super._create<IRetirementGoal>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IRetirementGoal>(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IRetirementGoal>) {
    return super._partialUpdate<IRetirementGoal>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static simulate(goalId: ApiParam) {
    return super._get<ISimulation>(`${super._singlePath(goalId)}/simulate`)
  }

  static calculateRequiredGrowthRate(goalId: ApiParam) {
    return super._get<IRequiredGrowthRate>(
      `${super._singlePath(goalId)}/calculate-required-growth-rate`
    )
  }
}
