export enum COLLAPSABLE_URLS {
  ACTIVATE = '/activate',
  DOCUMENTS_REPORTS = '/documents/reports'
}
export const COLLAPSE_URL = [
  COLLAPSABLE_URLS.ACTIVATE,
  COLLAPSABLE_URLS.DOCUMENTS_REPORTS
]

/**
 * Collapse urlToRCollapse on the first "/" when it matches url
 * @param url - the url to match
 * @param urlToCollapse - the url to collapse
 * @returns Url string that is collapsed
 */
export const collapseUrl = (url: string, urlToCollapse: string) =>
  urlToCollapse.replace(new RegExp(`${url}/.*`), `${url}`)
