import {ApiError} from 'fairlight'

import {AuthenticationLocations} from '@d1g1t/shared/locations'

const CREDENTIALS_ERROR = 'Unable to log in with provided credentials.'

export function isInvalidCredentialsError(loginError: Error): boolean {
  return (
    loginError instanceof ApiError &&
    loginError.status === 400 &&
    loginError?.responseBody?.nonFieldErrors?.[0] === CREDENTIALS_ERROR
  )
}

/**
 * When MFA is enabled with multiple destinations, /login request will throw this error.
 * UI should handle this error, show MFA destinations, and wait for selection.
 */
export function isWaitingForMfaDestinationError(loginError: Error): boolean {
  return (
    loginError instanceof ApiError &&
    loginError.status === 401 &&
    loginError?.responseBody?.otpToken?.length > 0 &&
    loginError?.responseBody?.deliveryDestinations?.length > 0
  )
}

/**
 * When MFA is enabled, /login request will throw an error.
 * UI should handle this error, show MFA code input field, and wait for code.
 */
export function isWaitingForMfaCodeError(loginError: Error): boolean {
  return (
    loginError instanceof ApiError &&
    loginError.status === 401 &&
    loginError?.responseBody?.otpToken?.length > 0
  )
}

export const appendLogoutRedirect = (url: string): string => {
  const newUrl = new URL(url)
  const searchParams = new URLSearchParams()
  searchParams.set(
    'logout_redirect',
    `${window.location.origin}/#${AuthenticationLocations.logout()}`
  )
  newUrl.search = searchParams.toString()
  return newUrl.toString()
}
