import {ApiParam} from 'fairlight'

import {IIndividual} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class PeopleIndividualEndpoints extends BaseRestEndpoints {
  static basePath = '/people/individuals'

  static create(body: DeepPartial<IIndividual>) {
    return super._create<IIndividual>(body)
  }

  static findById(id: ApiParam) {
    return super._findById<IIndividual>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<IIndividual>) {
    return super._partialUpdate<IIndividual>(id, body)
  }
}
