import React, {useMemo} from 'react'

import MuiCheckbox, {CheckboxProps} from '@material-ui/core/Checkbox'

import {useControlState} from '@d1g1t/shared/components/control-state'
import {
  FormControlLabel,
  IFormControlLabelProps
} from '@d1g1t/shared/components/mui/form-control-label'

export interface ICheckboxProps extends CheckboxProps {
  /**
   * stop event propagation to prevent triggering onChange methods in forms
   */
  stopPropagation?: boolean
  /**
   * Callback to provide interop with handlers which expect data first
   */
  onChangeValue?(value: boolean): void
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  onChangeValue,
  onChange,
  stopPropagation,
  ...props
}) => {
  const {loading} = useControlState()

  const onChangeModified = useMemo(() => {
    if (typeof onChangeValue !== 'function' && typeof onChange !== 'function') {
      return
    }

    return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (stopPropagation) {
        event.stopPropagation()
      }

      if (typeof onChangeValue === 'function') {
        onChangeValue(event.target.checked)
      }

      if (typeof onChange === 'function') {
        onChange(event, checked)
      }
    }
  }, [onChangeValue, onChange])

  return (
    <MuiCheckbox
      onChange={onChangeModified}
      color='primary'
      {...props}
      disabled={loading || props.disabled}
    />
  )
}

export interface ILabeledCheckboxProps extends ICheckboxProps {
  label: React.ReactNode
  FormControlLabelProps?: Omit<IFormControlLabelProps, 'label' | 'control'>
}

export const LabeledCheckbox: React.FC<ILabeledCheckboxProps> = ({
  label,
  FormControlLabelProps,
  ...props
}) => {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox {...props} />}
      {...FormControlLabelProps}
    />
  )
}
