import {ITradeLocationRuleSet} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class TradeLocationRulesetEndpoints extends BaseRestEndpoints {
  static basePath = '/rebalancing/trade-location/ruleset'

  static list() {
    return super._list<IApiListResponse<ITradeLocationRuleSet>>()
  }

  static chart() {
    return super._chart({})
  }

  static findById(id: string) {
    return super._findById<ITradeLocationRuleSet>(id)
  }
}
