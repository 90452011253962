import {useEffect, useRef} from 'react'

/**
 * Returns previous value, or null if first render pass
 * @param value - updating value
 */
export function usePrevious<T>(value: T): T {
  const ref = useRef(null)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
