import {ApiParam} from 'fairlight'

import {IGlobalFilter} from '@d1g1t/typings/general'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class GlobalFilterEndpoints extends BaseRestEndpoints {
  static basePath = '/profile/global-filters'

  static chart() {
    return super._chart({})
  }

  static list() {
    return super._list<IApiListResponse<IGlobalFilter>>()
  }

  static findById(id: ApiParam) {
    return super._findById<IGlobalFilter>(id)
  }

  static create(params: IGlobalFilter) {
    return super._create<IGlobalFilter>(params)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static partialUpdate(id: ApiParam, body: Partial<IGlobalFilter>) {
    return super._partialUpdate<IGlobalFilter>(id, body)
  }
}
