import {Formatter} from './base-formatter'

export class BooleanFormatter extends Formatter {
  public format(value) {
    if (value === null || value === undefined) {
      return this.renderEmpty()
    }

    return parseBoolean(value) ? 'Yes' : 'No'
  }
}

const FALSY_STRINGS = ['false', 'no', '0']

function parseBoolean(value: any): boolean {
  if (
    typeof value === 'string' &&
    FALSY_STRINGS.includes(value.toLowerCase())
  ) {
    return false
  }

  return Boolean(value)
}
