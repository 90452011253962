import {IWorkdocsDocument} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'

export class WorkDocsEndpoints extends BaseRestEndpoints {
  static basePath = '/workdocs/documents'

  static list() {
    return super._list<IWorkdocsDocument[]>()
  }

  static listByType(query: {type: string}) {
    return super._list<IWorkdocsDocument[]>(query)
  }

  static download(versionId: string) {
    return super._get<Blob>(`/${versionId}/download`)
  }
}
