import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  IAccountsHoldingRebalancingRequest,
  IBroker,
  IChartTable,
  IExchange,
  IMarket,
  IState
} from '@d1g1t/api/models'

import {serializeQueryParams} from '@d1g1t/shared/wrappers/url-query-params'

import {BaseEndpoints} from './base'
import {IApiListResponse} from './typings'

export interface ITradingCheckRequestBody {
  /** account url */
  account: string
  /** instrument url */
  instrument: string
  quantityChange: number
  isSellAll: boolean
  isDscFree: boolean
}

export interface ITradingCheckResponse {
  messages: []
  isValidForTrading: boolean
}

export interface IManualTradeDriverEvent {
  securityType: FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE
  data: string
}

export interface ITradePreviewRequestBody {
  asOfDate: string
  accountsData: IAccountsHoldingRebalancingRequest['accountsData']
  sleevesData: IAccountsHoldingRebalancingRequest['sleevesData']
  selectedEntities: {
    accounts?: string[]
    clients?: string[]
    mandates?: string[]
    households?: string[]
  }
  targetCurrencyName: string
}

class BaseTradingEndpoints extends BaseEndpoints {
  static _serializeQuery(query) {
    return serializeQueryParams(query, {arrayFormat: 'repeat'})
  }
}

export class TradingEndpoints extends BaseTradingEndpoints {
  static basePath = '/trading'

  static states() {
    return super._get<IApiListResponse<IState>>('/state')
  }

  static markets() {
    return super._get<IApiListResponse<IMarket>>('/markets')
  }

  static brokers() {
    return super._get<IApiListResponse<IBroker>>('/brokers')
  }

  static check(body: ITradingCheckRequestBody) {
    return super._post<ITradingCheckResponse>('/check', {body})
  }

  /**
   * Uploads CSV file of trade order changes
   * @param body - body
   */
  static events(body: FormData) {
    return super._post<never>('/events', {body})
  }

  static downloadEvents(query) {
    return super._get<IManualTradeDriverEvent[]>('/events/download', {
      query
    })
  }

  static preview(body: ITradePreviewRequestBody) {
    return super._post<IChartTable>('/drafts/chart', {
      body
    })
  }

  static exchanges() {
    return super._get<IApiListResponse<IExchange>>('/exchanges')
  }
}
