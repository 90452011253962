import {BaseEndpoints} from './base'

export interface IPriceStatistic {
  priceChanged: number
  priceReturn: number
}

export interface IMarketTickerPrice {
  name: string
  precision: number
  series: {
    date: string
    value: number
  }[]
  priceStatistics: IPriceStatistic
}

export class MarketTickerEndpoints extends BaseEndpoints {
  static basePath = '/market/tickers'

  static tickerPrices(body: {
    options: {
      tickers: string[]
      fxRates: string[]
      startDate: string
      endDate: string
    }
  }) {
    return super._post<IMarketTickerPrice[]>('/tickersprice', {
      body,
      extraKey: JSON.stringify(body.options)
    })
  }
}
