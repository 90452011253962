import {ApiParam} from 'fairlight'

import {ITeam, ITeamList} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class TeamEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/teams'

  static create(body: ITeam) {
    return super._create<ITeam>(body)
  }

  static list() {
    return super._list<IApiListResponse<ITeamList>>({limit: 1000})
  }

  static findById(id: ApiParam) {
    return super._findById<ITeam>(id)
  }

  static partialUpdate(id: ApiParam, body: DeepPartial<ITeam>) {
    return super._partialUpdate<ITeam>(id, body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static chart() {
    return super._chart({})
  }
}
