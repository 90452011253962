import {isValid, parse} from 'date-fns'
import {isPossiblePhoneNumber} from 'libphonenumber-js'
import {mapValues} from 'lodash'
import * as Yup from 'yup'

import {MULTIPLE_VALUES} from '@d1g1t/shared/components/form-field/constants'

import {ISO_DATE_FORMAT} from './constants'

type YupErrorMessageCreator =
  | string
  | ((params: object & Partial<Yup.TestMessageParams>) => string)

type YupTestArgs = (errorMessageCreator?: YupErrorMessageCreator) => [
  /**
   * Name of the test
   */
  string,
  /**
   * Error message creator if the test doesn't pass
   */
  YupErrorMessageCreator,
  /**
   *
   */
  (
    this: Yup.TestContext,
    value?: any
  ) => boolean | Yup.ValidationError | Promise<boolean | Yup.ValidationError>,
  /**
   * callbackStyleAsync
   */
  boolean?
]

/**
 * Yup test for phone number
 */
export const testPhoneNumber: YupTestArgs = (errorMessageCreator) => [
  'is-phone-number',
  errorMessageCreator || (({label}) => `${label} is not a valid number`),
  function validatePhoneNumber(value) {
    // https://www.npmjs.com/package/libphonenumber-js#using-phone-number-validation-feature
    // There is another method called `isValidPhoneNumber` which actually validates the d1g1ts
    // but according to the docs it is better to just evaluate the length of the number.
    // From docs: "Telephone numbering plans can and sometimes do change." Therefore the validation
    // method may become obsolete.
    return !value || isPossiblePhoneNumber(value)
  }
]

export const testDateString: YupTestArgs = (errorMessageCreator) => [
  'is-date-string',
  errorMessageCreator || (({label}) => `${label} is not a valid date`),
  function validateDate(value) {
    return !value || isValid(parse(value, ISO_DATE_FORMAT, new Date()))
  }
]

export function schemaWithMultiple<T extends object, V extends object>(
  schemaObject: T
) {
  return Yup.lazy((values: V) =>
    Yup.object(
      mapValues(values, (value, key) => {
        if ((value as any) === MULTIPLE_VALUES) {
          return Yup.mixed()
        }

        return schemaObject[key]
      })
    )
  )
}

export enum SUM_OPERATOR {
  GREATER = '>',
  GREATEREQUAL = '>=',
  LESS = '<',
  LESS_EQUAL = '<=',
  EQUAL = '=',
  NOT_EQUAL = '!='
}

interface ITestSumParams {
  testId: string
  propertyKey: string
  sumPredicate: number
  sumOperator: SUM_OPERATOR
}

/**
 * Yup array validation test to check if current sum validates via
 * `sumPredicate` and `sumOperator`
 */
export const testSum =
  (params: ITestSumParams): YupTestArgs =>
  (errorMessageCreator) => {
    const {testId, propertyKey, sumPredicate, sumOperator} = params
    return [
      testId,
      errorMessageCreator,
      function validateSumTo100() {
        const values = this.parent
          .map((value) => value[propertyKey])
          .filter((value: number) => !!value)

        const currentSum = values.reduce(
          (prev: number, curr: number) => prev + curr,
          0
        )
        switch (sumOperator) {
          case SUM_OPERATOR.EQUAL:
            return currentSum === sumPredicate
          case SUM_OPERATOR.GREATER:
            return currentSum > sumPredicate
          case SUM_OPERATOR.GREATEREQUAL:
            return currentSum >= sumPredicate
          case SUM_OPERATOR.LESS:
            return currentSum < sumPredicate
          case SUM_OPERATOR.LESS_EQUAL:
            return currentSum <= sumPredicate
          case SUM_OPERATOR.NOT_EQUAL:
            return currentSum !== sumPredicate
        }
      }
    ]
  }

/**
 * Yup validation that only makes the field required when defined
 */
export const requiredWhenDefinedSchema = (
  label: string,
  type: 'number' | 'string'
) =>
  Yup.lazy((value) => {
    const schemaBase = type === 'string' ? Yup.string() : Yup.number()

    return value === undefined
      ? schemaBase.notRequired().nullable().label(label)
      : schemaBase.required().nullable().label(label)
  })
