import './reducers'

import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {SnackbarOrigin} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {classNames} from '@d1g1t/lib/class-names'

import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Snackbar} from '@d1g1t/shared/components/mui/snackbar'
import {SnackbarContent} from '@d1g1t/shared/components/mui/snackbar-content'

import {snackbarActions} from './actions'
import {getSnackbarState} from './getters'

import css from './styles.scss'

export * from './hook'

interface ISnackbarProviderProps {
  children: React.ReactNode
}

const ANCHOR_ORIGIN: SnackbarOrigin = {vertical: 'top', horizontal: 'right'}

export const SnackbarProvider: React.FC<ISnackbarProviderProps> =
  function SnackbarProvider(props) {
    const {open, params} = useSelector(getSnackbarState)
    const dispatch = useDispatch()

    const handleClose = () => {
      dispatch(snackbarActions.close())
    }

    return (
      <>
        <Snackbar
          open={open}
          key={params?.key}
          anchorOrigin={ANCHOR_ORIGIN}
          onClose={(_, reason) => {
            if (reason === 'clickaway') {
              return
            }

            handleClose()
          }}
          autoHideDuration={params?.autoHideDuration}
          classes={{
            anchorOriginTopRight: css.snackbarAnchorTop,
            anchorOriginTopLeft: css.snackbarAnchorTop
          }}
        >
          <SnackbarContent
            classes={{
              root: classNames({
                [css.snackbarSuccess]: params?.variant === 'success',
                [css.snackbarError]: params?.variant === 'error',
                [css.snackbarInfo]: params?.variant === 'info',
                [css.snackbarWarning]: params?.variant === 'warning'
              })
            }}
            message={params?.message}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                onClick={handleClose}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            }
            style={{
              whiteSpace: 'pre-line'
            }}
          />
        </Snackbar>
        {props.children}
      </>
    )
  }

export {snackbarActions}
