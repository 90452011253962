import {IInstrumentStrategy} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class InstrumentStrategyEndpoints extends BaseRestEndpoints {
  static basePath = '/instrument-strategies'

  static list() {
    return super._list<IApiListResponse<IInstrumentStrategy>>()
  }
}

export class InstrumentStrategyVectorsEndpoints extends BaseRestEndpoints {
  static basePath = '/strategy-vectors'

  static chart(body: {instrument: string; date: string}) {
    return super._chart(body, JSON.stringify(body))
  }
}
