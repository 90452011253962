import {addMinutes, format, isDate, isValid, parseISO, toDate} from 'date-fns'
import produce from 'immer'

import {EXTERNALPROFILE_DATE_FORMAT} from '@d1g1t/api/models'

import {FNS_DATE_FORMATS} from '@d1g1t/lib/constants'

import {Formatter, IFormatterOptions} from './base-formatter'

interface IFormatDateOptions {
  style?: 'date' | 'datetime' | 'quarter' | 'monthyear' | 'year'
}

export class DateFormatter extends Formatter<IFormatDateOptions> {
  constructor(options: IFormatDateOptions & IFormatterOptions = {}) {
    super(
      produce(options, (draft) => {
        draft.style = draft.style || 'date'
      })
    )
  }

  format = (value, options?: IFormatDateOptions) => {
    const date = parseDate(value)

    if (!isValid(date)) {
      return this.renderEmpty()
    }

    const utcDate = addMinutes(date, date.getTimezoneOffset())

    switch (this.options.style) {
      case 'quarter':
        return this.formatQuarter(utcDate)
      case 'monthyear':
        return this.formatMonthYear(utcDate)
      case 'year':
        return this.formatYear(utcDate)
      case 'datetime':
        return this.formatDateTime(date)
      default:
        return this.formatDate(utcDate, options)
    }
  }

  private formatQuarter(date: Date) {
    const q = Math.floor((date.getMonth() + 3) / 3)
    return `Q${q} ${date.getFullYear()}`
  }

  private formatMonthYear(date: Date) {
    return format(date, "MMM ''yy")
  }

  private formatYear(date: Date) {
    return format(date, 'yyyy')
  }

  private formatDateTime(date: Date) {
    const time = format(date, 'p')

    const dateStr = format(date, this.getDateFormat())

    return `${dateStr} ${time}`
  }

  private getDateFormat() {
    const dateFormat =
      this.options?.dateFormat || EXTERNALPROFILE_DATE_FORMAT.YMD

    return FNS_DATE_FORMATS[dateFormat]
  }

  private formatDate(date: Date, options: IFormatDateOptions) {
    return format(date, this.getDateFormat())
  }
}

const getIsoDate = (value: any): string => {
  if (isValid(value)) {
    return new Date(value).toISOString()
  }
  return value
}

export function parseDate(value: any): Date {
  if (isDate(value)) {
    return value
  }
  if (typeof value === 'string') {
    return parseISO(getIsoDate(value))
  }
  return toDate(value)
}
