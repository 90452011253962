import {ApiParam} from 'fairlight'

import {IProfileGroup} from '@d1g1t/api/models'

import {BaseRestEndpoints} from './base'
import {IApiListResponse} from './typings'

export class UserAccountProfileGroupEndpoints extends BaseRestEndpoints {
  static basePath = '/user-accounts/profile-groups'

  static list() {
    return super._list<IApiListResponse<IProfileGroup>>()
  }

  static findById(id: ApiParam) {
    return super._findById<IProfileGroup>(id)
  }

  static create(body: IProfileGroup) {
    return super._create<IProfileGroup>(body)
  }

  static destroy(id: ApiParam) {
    return super._destroy(id)
  }

  static chart(body: {profileGroupId?: string} = {}) {
    return super._chart(body, body.profileGroupId)
  }
}
