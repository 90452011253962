import {useRef, useState} from 'react'

/**
 * Returns whether or not a component is controlled.
 * The initial booelan value passed to this hook is always returned,
 * so it will never change from controlled to uncontrolled.
 *
 * If the boolean value changes between controlled and uncontrolled,
 * it will log a warning in development.
 *
 * @param controlled - Whether or not the state is controlled.
 */
export function useIsControlled(controlled: boolean) {
  const loggedWarning = useRef(false)
  const [initialControlled] = useState(controlled)

  if (
    __DEVELOPMENT__ &&
    controlled !== initialControlled &&
    !loggedWarning.current
  ) {
    loggedWarning.current = true
    console.warn('Can not change between controlled and uncontrolled.')
  }

  return initialControlled
}
